export const StatusColors = {
    SENT: {
        backgroundColor: '#CAF5C9',
        textColor: '#297A5D'
    },
    PAID: {
        backgroundColor: '#CAF5C9',
        textColor: '#297A5D'
    },
    IN_TRANSIT: {
        backgroundColor: '#3c87fb',
        textColor: 'white'
    },
    RECEIVED: {
        backgroundColor: '#CAF5C9',
        textColor: '#297A5D'
    },
    PENDING: {
        backgroundColor: '#ffdc9d',
        textColor: '#e67e00'
    },
    CANCELLED: {
        backgroundColor: '#FFCCCB',
        textColor: '#e60000'
    },
    FAILED: {
        backgroundColor: '#FFCCCB',
        textColor: '#e60000'
    },
    UNPAID: {
        backgroundColor: '#FFCCCB',
        textColor: '#e60000'
    },
    COMPLETE: {
        backgroundColor: '#CAF5C9',
        textColor: '#297A5D'
    },
    DELETED: {
        backgroundColor: '#FFCCCB',
        textColor: '#e60000'
    },
    REFUNDED: {
        backgroundColor: '#ffdc9d',
        textColor: '#e67e00'
    },
    UNCAPTURED: {
        backgroundColor: '#f44336',
        textColor: 'white'
    },
    DEFAULT_SCHEME: {
        backgroundColor: 'gray',
        textColor: 'white'
    },
    SUCCESS: {
        backgroundColor: '#CAF5C9',
        textColor: '#297A5D'
    },
    UNSUCCESSFUL: {
        backgroundColor: '#FFCCCB',
        textColor: '#e60000'
    },
    ACTIVE: {
        backgroundColor: '#caf5c9',
        textColor: '#12263f'
    },
    INACTIVE: {
        backgroundColor: '#9CA3AF',
        textColor: 'white'
    },
    CLOSED: {
        backgroundColor: '#f05150',
        textColor: '#f8faff'
    },
    'NOT RECEIVED': {
        backgroundColor: '#E8C354 ',
        textColor: 'white'
    },
    VERIFIED: {
        backgroundColor: '#CAF5C9',
        textColor: '#297A5D'
    }
};
