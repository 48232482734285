import React from 'react';
import style from './account-verify-upload-documents.module.scss';
import { Container, Card, Box, Typography } from '@material-ui/core';
import { UploadDocuments } from 'components/onboarding/onboarding-ui-components';
import { DocumentFlag } from 'utils/enums/DocumentFlag';
import PropTypes from 'prop-types';
import { BankSortCodeDetails, FinishUploadButton, PendingDocsList, SnackBarAndAlert } from 'components';
import LoadingScreen from 'components/loading-screen/loading-screen.container';

function AccountVerifyUploadDocumentsComponent(props) {
    const {
        merchantId,
        setValidForm,
        setFormattedDocumentsStats,
        formattedDocumentsStats,
        setUploadSuccess,
        businessTypeId,
        isFinishButtonDisabled,
        setIsFinishButtonDisabled,
        loading,
        uploadSuccess,
        bankUpload,
        setBankUpload,
        country,
        source,
        isAdmin,
        csrfToken,
        formType,
        cardNumber,
        setCardNumber,
        setIdNumber,
        idNumber
    } = props;

    return (
        <React.Fragment>
            {/* {!isVerifiedBank && (
                <div
                    className={`${style.listItemContainer}
                                ${!isVerifiedBank && style.alert_background__color}`}
                >
                    <div className={style.iconUnverified}>
                        <ErrorOutline className={style.error_icon} />
                    </div>
                    <Typography color="primary">
                        <React.Fragment>
                            <div>
                                You must verify your account before making a withdrawal. To verify your account, please
                                upload the required documents.&nbsp;
                            </div>
                        </React.Fragment>
                    </Typography>
                </div>
            )} */}

            <Container className={style.uploadDocumentContainer}>
                <Card className={style.container}>
                    <Box>
                        <Typography color="primary">
                            <h1 className={style.paddingHeader}>Account Verify Documents</h1>
                            <div className={style.subHeaderBackground}>
                                {formattedDocumentsStats?.pendingDocumentCategories?.length ? (
                                    <>
                                        {!formattedDocumentsStats.needsOnlyGenericCategoryDocs ? (
                                            <h3>Documents that are required for identification are:</h3>
                                        ) : (
                                            ''
                                        )}
                                        <PendingDocsList
                                            formattedDocumentsStats={formattedDocumentsStats}
                                            businessTypeId={businessTypeId}
                                        ></PendingDocsList>
                                        {!formattedDocumentsStats.needsOnlyGenericCategoryDocs ? (
                                            <h3>Kindly upload the above documents</h3>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                ) : (
                                    ''
                                )}

                                <span>The required formats are: .png, .jpeg, .jpg, .pdf</span>
                            </div>
                        </Typography>
                    </Box>
                    <div className={style.upload}>
                        <div className={style.documents}>
                            <UploadDocuments
                                entity={'merchant'}
                                entityId={merchantId}
                                flag={DocumentFlag.ACCOUNT_VERIFY}
                                setValidForm={setValidForm}
                                setFormattedDocumentsStats={setFormattedDocumentsStats}
                                setUploadSuccess={setUploadSuccess}
                                uploadBtnName={'Submit'}
                                businessTypeId={businessTypeId}
                                setIsFinishButtonDisabled={setIsFinishButtonDisabled}
                                bankUpload={bankUpload}
                                setBankUpload={setBankUpload}
                                source={source}
                                csrfToken={csrfToken}
                                formType={formType}
                                setIdNumber={setIdNumber}
                                idNumber={idNumber}
                                cardNumber={cardNumber}
                                setCardNumber={setCardNumber}
                            ></UploadDocuments>
                        </div>
                    </div>
                    <SnackBarAndAlert
                        open={uploadSuccess}
                        onClose={() => {
                            setUploadSuccess(false);
                        }}
                        type="success"
                    >
                        {isAdmin
                            ? 'File Uploaded Successfully'
                            : 'Our support team will review your documents and get back to you within 48 hours.'}
                    </SnackBarAndAlert>
                </Card>
                <FinishUploadButton disabled={isFinishButtonDisabled} />
                {country !== 'Australia' ? <BankSortCodeDetails /> : null}
            </Container>
            <LoadingScreen open={loading}></LoadingScreen>
        </React.Fragment>
    );
}

AccountVerifyUploadDocumentsComponent.propTypes = {
    merchantId: PropTypes.string,
    setValidForm: PropTypes.func,
    setUploadSuccess: PropTypes.func,
    isFinishButtonDisabled: PropTypes.bool,
    loading: PropTypes.bool,
    setIsFinishButtonDisabled: PropTypes.func,
    uploadSuccess: PropTypes.bool,
    bankUpload: PropTypes.bool,
    setBankUpload: PropTypes.func,
    source: PropTypes.string
};

export default AccountVerifyUploadDocumentsComponent;
