import { ValidationMessage } from 'models';

export function validateRefundData(refundAmount, amount) {
    if (refundAmount > amount) {
        return new ValidationMessage('Amount is greater than paid amount', true);
    }

    if (!refundAmount) {
        return new ValidationMessage('Please enter amount', true);
    }

    if (refundAmount <= 0) {
        return new ValidationMessage('Please enter correct amount', true);
    }

    return new ValidationMessage('', false);
}

export function validateRefund(account, bank, override) {
    if (override) {
        return new ValidationMessage('', true);
    } else {
        if (!account && !bank) {
            return new ValidationMessage(
                'You cannot refund because account is unverified and bank details are unverified.',
                false
            );
        }
        if (!account) {
            return new ValidationMessage('You cannot refund because account is unverified.', false);
        }
        if (!bank) {
            return new ValidationMessage('You cannot refund because bank details are unverified.', false);
        }
        if (account && bank) {
            return new ValidationMessage('', true);
        }
    }
}
