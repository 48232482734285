import React from 'react';
import styles from './appCTA.module.scss';
import Button from '@mui/material/Button';
import { useGlobalStateContext } from 'contexts';

function AppCTAComponent(props) {
    const { redirection_link } = props;
    const { setRoute } = useGlobalStateContext();

    return (
        <React.Fragment>
            <div className={styles.wrapper}>
                <div className={styles.logo__container}>
                    <img
                        src="https://cp-migr-docs-dev.s3.eu-central-1.amazonaws.com/reseller-logos/datman.png"
                        alt="Logo"
                    />
                </div>
                <div className={styles.content__container}>
                    <div className={styles.content__container__text}>
                        <h1 className={styles.content__container__text__main}>
                            Hey{' '}
                            <span role="img" aria-label="Wave">
                                👋
                            </span>
                            , Welcome to Datman
                        </h1>
                        <p className={styles.content__container__text__para}>
                            Upgrade to Our New App for Enhanced Features and Performance.
                        </p>
                    </div>
                    <div className={styles.content__container__cta}>
                        <Button
                            onClick={() => (window.location.href = redirection_link)}
                            variant="contained"
                            className={styles.content__container__cta__button}
                        >
                            Use the App
                        </Button>
                        <p onClick={() => setRoute('/login')}>Continue on the web</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default AppCTAComponent;
