import { useGlobalStateContext } from 'contexts';
import React, { useEffect, useState } from 'react';
import {
    BusinessDetailsService,
    PaymentsService,
    AuthService,
    NonResellerMerchantService,
    WithdrawalService,
    AdyenService
} from 'services';
import DashboardComponent from './dashboard.component';
import moment from 'moment';
import { ResellerType } from 'utils/enums/ResellerType';
import { DocumentStatusToName } from 'utils/enums/DocumentReviewStatus';
import clevertap from 'clevertap-web-sdk';
import { UserType } from 'utils/enums/UserType';
import { triggerDays, MAX_PASSWORD_CHANGE_DAYS } from 'utils/enums/ChangePasswordTriggerDays';
import { ProductStatus } from 'utils/enums/ProductsRequired';
import { SnackbarMessages } from 'utils/text enums/snackbar-text';

function Dashboard(props) {
    const { globalState, setGlobalState, setRoute } = useGlobalStateContext();
    const [isVerifiedBank, setIsVerifiedBank] = useState(false);
    const [businessType, setBusinessType] = useState('');
    const [error, setError] = useState(false);
    const [payments, setPayments] = useState([]);
    const [total, setTotal] = useState(0);
    const [dateFormat, setDateFormat] = useState(null);
    const [showMerchantCreationMessage, setShowMerchantCreationMessage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [pageLoading, setIsPageLoading] = useState(true);
    const [bankUpload, setBankUpload] = useState(false);
    const [accountUpdated, setAccountUpdated] = useState(false);
    const [querySubmitted, setQuerySubmitted] = useState(false);
    const [accountStatus, setAccountStatus] = useState('');
    const [isAutoWithdrawalEnabled, setIsAutoWithdrawalEnabled] = useState(globalState.merchant?.autoWithdraw);
    const [showMessage, setShowMessage] = useState(false);
    const [verificationEmailMessage, setVerificationEmailMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [openAdyen, setOpenAdyen] = useState(false);
    const [nextPayoutDay, setNextpayoutDay] = useState('Tuesday 5PM');
    const [bankDocStatus, setBankDocStatus] = useState('');
    const [accountDocStatus, setAccountDocStatus] = useState('');
    const [rejectedDoc, setRejectedDoc] = useState([]);
    const [snackbar, setSnackbar] = useState('');
    const [adyenAccount, setAdyenAccount] = useState({});
    let userHasSeenSnackbar;

    useEffect(() => {
        // Checking if the user has already seen the Snackbar
        const userHasSeenSnackbar = localStorage.getItem('userHasSeenSnackbar');

        // Calculating the difference in days since the password was updated
        const diffeneceInTime = moment().diff(
            moment(globalState.user.updatedPasswordAt, 'YYYY-MM-DD HH:mm:ss'),
            'days'
        );

        // Checking if the user hasn't seen the Snackbar and if the difference in days matches a trigger day
        if (!userHasSeenSnackbar && triggerDays.includes(diffeneceInTime)) {
            setSnackbar(SnackbarMessages.PASSWORD_UPCOMING_EXPIRY_MESSAGE(MAX_PASSWORD_CHANGE_DAYS - diffeneceInTime));

            // Setting a timeout to mark the Snackbar as seen and clear the message after a delay of 10 seconds
            const timeout = setTimeout(() => {
                localStorage.setItem('userHasSeenSnackbar', 'true');
                setSnackbar('');
            }, 10000);

            // Cleaning up the timeout when the component unmounts or when the effect runs again
            return () => clearTimeout(timeout);
        } else setSnackbar('');
    }, [globalState.user.updatedPasswordAt]);

    // For adding userHasSeenSnackbar if url changes. It will help us in not showing the snackbar only once
    useEffect(() => {
        localStorage.setItem('userHasSeenSnackbar', 'true');
        // eslint-disable-next-line
    }, [window.location.href]);
    const [link, setLink] = useState('');

    const getBalancePlatformOnboardingUrl = async () => {
        setLoading(true);
        setOpenAdyen(false);
        const response = await AdyenService.getBalancePlatformOnboardingUrl(globalState.merchant?.id);
        if (response.isSuccesfully) {
            window.open(response.data.url);
            setLink(response.data.url);
        } else {
            setErrorMessage('An error occurred. Try again !');
        }
        setLoading(false);
    };

    const getAdyenAccounts = async () => {
        const response = await AdyenService.getAdyenAccounts(globalState.merchant?.id);
        if (response.isSuccesfully) {
            setAdyenAccount(response.data);
        } else {
            setAdyenAccount({});
        }
    };

    useEffect(() => {
        const getBankStatus = async () => {
            setLoading(true);
            setIsPageLoading(true);
            setIsAutoWithdrawalEnabled(globalState.merchant.autoWithdraw);
            const response = await BusinessDetailsService.getMerchantBusinessTypeAndBankStatus(
                globalState.merchant?.id
            );
            if (response.isSuccesfully) {
                setBusinessType(response.data.typeName);
                setIsVerifiedBank(response.data.isBankAccountVerified && response.data.isAccountVerified);
                setAccountStatus(response.data.accountStatus);
                setBankDocStatus(response.data.bankDocStatus);
                if (
                    response.data.idDocStatus === DocumentStatusToName.REJECTED ||
                    response.data.addressDocStatus === DocumentStatusToName.REJECTED
                ) {
                    setAccountDocStatus(DocumentStatusToName.REJECTED);
                } else if (
                    response.data.idDocStatus === DocumentStatusToName.NEED_APPROVAL ||
                    response.data.addressDocStatus === DocumentStatusToName.NEED_APPROVAL
                ) {
                    setAccountDocStatus(DocumentStatusToName.NEED_APPROVAL);
                } else {
                    setAccountDocStatus(DocumentStatusToName.ACTIVE);
                }
                setRejectedDoc(response.data.rejectedDoc);
            } else {
                setIsVerifiedBank(false);
            }

            setLoading(false);
            setIsPageLoading(false);
        };

        if (globalState.merchant?.id) {
            getBankStatus();
            getAdyenAccounts();
        }
        // eslint-disable-next-line
    }, [globalState.merchant]);

    useEffect(() => {
        const fetchStatus = async () => {
            let notifyCount = sessionStorage.getItem('uploadSucess');
            if (notifyCount >= 1) {
                setBankUpload(true);
                const timeout = setTimeout(() => {
                    setBankUpload(false);
                }, 15000);
                sessionStorage.removeItem('uploadSucess');

                return () => clearTimeout(timeout);
            } else {
                sessionStorage.removeItem('uploadSucess');
                setBankUpload(false);
            }
        };

        if (globalState.merchant?.id && globalState.reseller?.name === ResellerType.OMNIPAY) {
            fetchStatus();
        }
    }, [globalState.merchant, globalState.reseller]);

    useEffect(() => {
        const fetchStatus = async () => {
            const updatedAccount = sessionStorage.getItem('accountUpdated');
            if (updatedAccount) {
                setAccountUpdated(true);
                const timeout = setTimeout(() => {
                    setAccountUpdated(false);
                }, 15000);
                sessionStorage.removeItem('accountUpdated');
                return () => clearTimeout(timeout);
            }
        };

        if (globalState.merchant?.id && globalState.reseller?.name === ResellerType.OMNIPAY) {
            fetchStatus();
            const products = globalState.merchant?.merchantProductRequired.filter(
                (product) => product.status === ProductStatus.ACTIVE
            );
            let productsAcquired;
            if (products) {
                productsAcquired = products.map(({ productName }) => productName).toString();
            }
            const login =
                (sessionStorage.getItem('login') || sessionStorage.getItem('signUp')) &&
                globalState.user?.UserType?.name === UserType.Merchant;
            if (login) {
                sessionStorage.removeItem('login', 'signUp');

                clevertap.onUserLogin.push({
                    Site: {
                        Name: globalState.merchant.name,
                        Merchant_name: globalState.merchant.name,
                        Identity: globalState.merchant.id,
                        Phone: globalState.merchant?.phoneNumber,
                        Product_type: productsAcquired,
                        Country: globalState.merchant?.country,
                        // optional fields. controls whether the user will be sent email, push etc.
                        'MSG-email': true, // Enable email notifications
                        'MSG-push': true, // Enable push notifications
                        'MSG-sms': true, // Enable sms notifications
                        'MSG-whatsapp': true
                    }
                });
                setLoading(false);
            }
        }
        //eslint-disable-next-line
    }, [globalState.merchant, globalState.reseller]);

    useEffect(() => {
        const getPaymentsList = async () => {
            const date = new Date();
            setDateFormat(moment(date).format('YYYY-MM-DD'));
            const via = 'day';
            const from = moment(date).format('YYYY-MM-DD');

            const paymentsList = await PaymentsService.GetPayments(globalState.merchant?.id, {
                //merchantId,
                from,
                via: via.toUpperCase()
            });

            if (paymentsList.isSuccesfully) {
                setPayments(paymentsList.data.payments);
                setTotal(paymentsList.data.total);
                setError(false);
            } else {
                setPayments([]);
                setError(true);
            }
            setLoading(false);
        };

        setLoading(true);

        if (globalState.merchant?.id) {
            getPaymentsList();
        }
    }, [globalState.merchant]);

    useEffect(() => {
        const showMessage = sessionStorage.getItem('querySubmitted');
        if (showMessage) {
            sessionStorage.removeItem('querySubmitted');
            setQuerySubmitted(true);
        }
        const getNextPayoutDay = async () => {
            const response = await WithdrawalService.getNextWithdrawalDay();
            if (response.isSuccesfully) {
                setNextpayoutDay(response?.data?.data);
            }
        };
        getNextPayoutDay();
    }, []);

    useEffect(() => {
        try {
            //this redirection is for linkQR only.
            //forlinkQR queryParameter will be like ?data=71068e7c-0bc8-4e66-b19f-a38331069fa6
            //for signup queryParameter will be like ?q=YToyOntzOjE6ImkiO3M6NjoiODA4NTk4IjtzOjE6ImYiO3M6MjoiTVMiO30=
            if (props.location.search && props.location.search.includes('data=')) {
                setRoute(`/linkQr${props.location.search}`);
            }
        } catch (e) {
            console.log({ e });
        }
        // eslint-disable-next-line
    }, [props.location.search]);

    const sendVerificationEmail = async () => {
        setLoading(true);
        const response = await AuthService.sendVerificationEmail({
            email: globalState.user.email,
            resellerUrl: globalState.reseller.portalURL
        });
        setShowMessage(true);
        if (response.isSuccesfully) {
            setMessageType('success');
            setVerificationEmailMessage('Verification email has been sent successfully. Please check inbox.');
        } else {
            setMessageType('error');
            setVerificationEmailMessage('An error occurred. Try again !');
        }
        setLoading(false);
    };

    const enableAutoWithdrawals = async () => {
        setOpenModal(false);
        setLoading(true);
        const data = {
            resellerId: globalState.reseller.id,
            enable: 1
        };
        setIsAutoWithdrawalEnabled(true);
        const response = await NonResellerMerchantService.enableAutoWithdrawals(globalState.merchant.id, data);
        if (!response.isSuccesfully) {
            setIsAutoWithdrawalEnabled(false);
            setErrorMessage('An error occurred. Try again !');
        } else {
            setGlobalState((globalState) => ({
                ...globalState,
                merchant: {
                    ...globalState.merchant,
                    autoWithdraw: true
                }
            }));
        }
        setLoading(false);
    };

    return (
        <DashboardComponent
            isVerifiedBank={isVerifiedBank}
            businessType={businessType}
            error={error}
            loading={loading}
            payments={payments}
            total={total}
            dateFormat={dateFormat}
            showMerchantCreationMessage={showMerchantCreationMessage}
            setShowMerchantCreationMessage={setShowMerchantCreationMessage}
            bankUpload={bankUpload}
            setBankUpload={setBankUpload}
            accountUpdated={accountUpdated}
            querySubmitted={querySubmitted}
            setQuerySubmitted={setQuerySubmitted}
            accountStatus={accountStatus}
            isAutoWithdrawalEnabled={isAutoWithdrawalEnabled}
            sendVerificationEmail={sendVerificationEmail}
            showMessage={showMessage}
            setShowMessage={setShowMessage}
            verificationEmailMessage={verificationEmailMessage}
            messageType={messageType}
            enableAutoWithdrawals={enableAutoWithdrawals}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            openModal={openModal}
            setOpenModal={setOpenModal}
            pageLoading={pageLoading}
            bankDocStatus={bankDocStatus}
            accountDocStatus={accountDocStatus}
            rejectedDoc={rejectedDoc}
            setOpenAdyen={setOpenAdyen}
            openAdyen={openAdyen}
            nextPayoutDay={nextPayoutDay}
            snackbar={snackbar}
            userHasSeenSnackbar={userHasSeenSnackbar}
            getBalancePlatformOnboardingUrl={getBalancePlatformOnboardingUrl}
            link={link}
            adyenAccount={adyenAccount}
        />
    );
}

export default Dashboard;
