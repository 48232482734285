import React, { useState, useEffect } from 'react';
import PrintAgreementComponent from './print-agreement.component';
import { AdminService, NonResellerMerchantService } from 'services';
import { useGlobalStateContext } from 'contexts';

const PrintAgreementContainer = () => {
    const [contractDetails, setContractDetails] = useState({});
    const [merchantTradingAddress, setMerchantTradingAddress] = useState({});
    const [merchantMobile, setMerchantMobile] = useState(null);
    const { globalState } = useGlobalStateContext();
    const [isLoading, setIsLoading] = useState(false);
    const [companyAddress, setCompanyAddress] = useState({});
    const [ownerName, setOwnerName] = useState('');

    useEffect(() => {
        const getContract = async () => {
            setIsLoading(true);
            const response = await AdminService.getContractValues();
            const merchantResponse = await NonResellerMerchantService.getOnboardingFullData(globalState.merchant?.id);
            if (response.isSuccesfully || merchantResponse.isSuccesfully) {
                setContractDetails(response.data.data);
            }
            if (merchantResponse.isSuccesfully) {
                setMerchantTradingAddress(merchantResponse?.data?.merchantData?.tradingAddress);
                if (merchantResponse?.data?.merchantData?.businessDetails?.phoneNumber) {
                    const isPlusExist = merchantResponse?.data?.merchantData?.businessDetails?.phoneNumber?.includes(
                        '+'
                    );
                    const includedPlusSign = '+' + merchantResponse?.data?.merchantData?.businessDetails?.phoneNumber;
                    const mobileNumber = isPlusExist
                        ? merchantResponse?.data?.merchantData?.businessDetails.phoneNumber
                        : includedPlusSign;
                    setMerchantMobile(mobileNumber);
                } else {
                    setMerchantMobile('__');
                }
                setOwnerName(merchantResponse?.data?.merchantData?.ownersDetails?.fullName);
            }
            setIsLoading(false);
        };
        setCompanyAddress(globalState.reseller.address);
        getContract();
    }, [globalState.merchant, globalState.reseller.address]);

    return (
        <PrintAgreementComponent
            isLoading={isLoading}
            merchant={globalState.merchant}
            contractDetails={contractDetails}
            merchantTradingAddress={merchantTradingAddress}
            merchantMobile={merchantMobile}
            companyAddress={companyAddress}
            ownerName={ownerName}
        />
    );
};

export default PrintAgreementContainer;
