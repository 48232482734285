import React from 'react';
// import styles from './missing-merchant-document.module.scss';
import { AuditData } from 'components';

export default function AuditLogDataComponent() {
    return (
        <div>
            <AuditData />
        </div>
    );
}
