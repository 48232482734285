export const BusinessType = {
    Limited: 'ltd',
    PLC: 'plc',
    LLP: 'llp',
    Partnership: 'limited-partnership',
    Sole_Trader: 'Sole Trader'
};

export const BusinessTypeName = {
    Limited: 'Limited',
    PLC: 'PLC',
    LLP: 'LLP',
    Partnership: 'Partnership',
    Sole_Trader: 'Sole Trader'
};

export const BusinessTypeEnumId = {
    Limited: 1,
    Sole_Trader: 2,
    LLP: 3,
    PLC: 4,
    Partnership: 5,
    Charity: 6,
    Corporate_Entity: 7,
    Discretionary_Trading_Trust: 8,
    Other: 9,
    Corporation: 10,
    Limited_LLC: 11,
    Trust: 12
};

export const BusinessTypeNameAndID = [
    { id: 1, name: 'Limited' },
    { id: 2, name: 'Sole Trader' },
    { id: 3, name: 'LLP' },
    { id: 4, name: 'PLC' },
    { id: 5, name: 'Partnership' },
    { id: 6, name: 'Charity' },
    { id: 7, name: 'Corporate Entity' },
    { id: 8, name: 'Discretionary Trading Trust' },
    { id: 9, name: 'Other' }
];
