import React from 'react';
import AppCTA from 'pages/appCTA/appCTA.container';
import Login from '../pages/login/login.container';
import ForgotPassword from '../pages/forgot-password/forgot-password.container';
import Onboarding from '../pages/onboarding/onboarding.container';
import SignUp from '../pages/signup/signup.container';
import ExternalSignUp from 'pages/external-signup/signup.container';
import Help from '../pages/help/help.container';
import { Route } from 'react-router-dom';
import NotLoggedRoute from './not-logged-route';
import AdminUserTypeRoute from './admin-user-type-route.js';
import MypayOnlyNotLoggedRoute from './mypay-only-not-logged-route';
import OwnerOrAdminRoute from './owner-or-admin-route';
import SuperAdminUserTypeRoute from './super-admin-user-type-route';
import ResetPassword from '../pages/reset-password/reset-password.container';
import SupportUtilities from '../pages/support-utilities/support-utilities.container';
import TermsAndConditionsPage from '../pages/terms-and-conditions/terms-and-conditions.container';
import NotAuthorizedPage from '../pages/not-authorized/not-authorized.container';
import RequestSupportForm from '../pages/request-support-form/request-support-form.container';
import AdyenResponse from '../pages/adyen-response/adyen-response.container';
import LinkQr from 'pages/link-qr/link-qr.container';
import AuditLogData from 'pages/admin/audit-log-data/audit-log-data.container';
import Maintainance from 'pages/under-maintainance/under-maintainance.container';
import AdyenMerchantList from 'pages/admin/adyen-merchants-list/adyen-merchant-list.container';

export default [
    <NotLoggedRoute exact key="appCTA" path="/:resellerUrl" component={AppCTA} />,
    <NotLoggedRoute key="login" path="/:resellerUrl/login" component={Login} />,

    <MypayOnlyNotLoggedRoute key="signup" path="/:resellerUrl/signup" component={SignUp} />,
    <MypayOnlyNotLoggedRoute key="externalSignup" path="/:resellerUrl/signup-form" component={ExternalSignUp} />,
    <NotLoggedRoute key="forgotpassword" path="/:resellerUrl/forgot-password" component={ForgotPassword} />,
    <NotLoggedRoute key="resetpassword" path="/:resellerUrl/reset-password" component={ResetPassword} />,
    <OwnerOrAdminRoute key="onboarding" path="/:resellerUrl/onboarding" component={Onboarding} />,
    <AdminUserTypeRoute
        key="supportUtilities"
        path="/:resellerUrl/support-utilities/:name"
        component={SupportUtilities}
    />,
    <AdminUserTypeRoute
        key="adyenResponse"
        path="/:resellerUrl/adyen-response/:merchantId"
        component={AdyenResponse}
    />,
    <Route key="help" path="/:resellerUrl/help" component={Help} />,
    <Route key="conditions" path="/:resellerUrl/terms-and-conditions" component={TermsAndConditionsPage} />,
    <Route
        exact
        key="notAuthorized"
        path="/:resellerUrl/not-authorized"
        component={() => <NotAuthorizedPage autoRedirect={true} />}
    />,
    <Route key="maintenance" path="/:resellerUrl/maintenance" component={Maintainance} />,
    <Route key="requestSupportForm" path="/:resellerUrl/request-support-form" component={RequestSupportForm} />,
    <Route key="linkQr" path="/:resellerUrl/linkQr" component={LinkQr} />,
    <SuperAdminUserTypeRoute key="audit-log-data" path="/:resellerUrl/auditlogs" component={AuditLogData} />,
    <AdminUserTypeRoute
        key="adyen-blocked-merchants-list"
        path="/:resellerUrl/adyen-blocked-merchants-list"
        component={AdyenMerchantList}
    />
];
