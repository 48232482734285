import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useGlobalStateContext } from 'contexts';
import styles from './not-authorized.module.scss';

function NotAuthorizedPageComponent(props) {
    const { isAutoRedirect, progress } = props;
    const { globalState, setRoute } = useGlobalStateContext();

    return (
        <Grid container direction="row" alignItems="center" justify="center" className={styles.container}>
            <Grid container item xs={12} justify="center" alignItems="center">
                <img
                    src={globalState.reseller?.logo}
                    alt="Logo"
                    onClick={() => {
                        setRoute('/home');
                    }}
                    className={styles.container__img}
                />
            </Grid>
            {isAutoRedirect && (
                <Grid container item xs={12} justify="center" alignItems="center">
                    <div className={styles.container__redirect}>
                        <Typography className={styles.container__redirect__text} paragraph>
                            Redirecting in:
                        </Typography>
                    </div>
                    <Box position="relative" display="inline-flex">
                        <CircularProgress
                            variant="determinate"
                            value={progress}
                            thickness="5"
                            size={30}
                            className={styles.container__spinner}
                        />
                        <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            position="absolute"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography className={styles.container__redirect__text} component="div">{`${Math.round(
                                (100 - progress) / 20
                            )}`}</Typography>
                        </Box>
                    </Box>
                </Grid>
            )}
            <Grid container item xs={12} justify="center">
                <Typography className={styles.container__text} paragraph>
                    You are not authorised to access this page.
                    <span
                        onClick={() => {
                            setRoute('/home');
                        }}
                        className={styles.container__anchor}
                    >
                        Click here
                    </span>
                </Typography>
            </Grid>
        </Grid>
    );
}

NotAuthorizedPageComponent.propTypes = {
    isAutoRedirect: PropTypes.bool,
    progress: PropTypes.number
};

export default NotAuthorizedPageComponent;
