import React from 'react';

import {
    Kitchen as KitchenIcon,
    Person as PersonIcon,
    Business as BusinessIcon,
    Clear as ClearIcon
} from '@material-ui/icons';
import PropTypes from 'prop-types';

import { Box, Typography, Collapse, Grid, Avatar } from '@material-ui/core';

import styles from './chargebacks-expanded-row.module.scss';

const ChargebacksExpandedRowComponent = (props) => {
    return (
        <Collapse in={props.open} timeout="auto" unmountOnExit>
            <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                    Chargeback note
                </Typography>
            </Box>
            <Grid className={styles.chargebackNoteDetailsWrapper}>
                <Avatar
                    className={styles.avatar}
                    alt="Remy Sharp"
                    src="https://material-ui.com//static/images/avatar/1.jpg"
                />
                <Grid item xs={12}>
                    <Grid container direction="row">
                        <span>
                            <PersonIcon /> John{' '}
                        </span>
                        <span className={styles.reporterOrg}>
                            <BusinessIcon />
                            Super dry PLC{' '}
                        </span>
                        <span>22/01/2020 </span>
                    </Grid>
                    <Grid item className={styles.chargebackNoteHolder} xs={11}>
                        <Typography className={styles.chargebackNote}>
                            The item was damaged. Take it back and give me my money back.
                        </Typography>
                        <Grid container direction="row">
                            <Grid className={styles.proofOfDamageWrapper}>
                                <KitchenIcon />
                                <Grid container direction="column" alignItems="center">
                                    <Typography className={styles.proofOfDamageLabel}>Proof of damage</Typography>
                                    <Typography className={styles.proofStats}>JPEG 215kb</Typography>
                                </Grid>
                                <ClearIcon />
                            </Grid>
                            <Grid className={styles.proofOfDamageWrapper}>
                                <KitchenIcon />
                                <Grid container direction="column" alignItems="center">
                                    <Typography className={styles.proofOfDamageLabel}>Proof of damage</Typography>
                                    <Typography className={styles.proofStats}>JPEG 215kb</Typography>
                                </Grid>
                                <ClearIcon />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Collapse>
    );
};

export default ChargebacksExpandedRowComponent;

ChargebacksExpandedRowComponent.propTypes = {
    open: PropTypes.bool.isRequired
};
