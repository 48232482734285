import React from 'react';
import styles from './link-qr.module.scss';
import { Button } from '@material-ui/core';
import { Spinner } from 'react-bootstrap';

function LinkQrComponent(props) {
    const { onProceed, message, loading, merchantsList, error } = props;

    return (
        <div className={styles.messageView}>
            <span className={styles.message}>{message}</span>
            {loading ? (
                <div className={styles.loading}>
                    <Spinner animation="border" variant="primary" />
                </div>
            ) : null}

            {error ? <p className={styles.errorMessage}>{error}</p> : null}

            {merchantsList && !loading
                ? merchantsList.map((item) => {
                      return (
                          <div className={styles.merchantList}>
                              <p className={styles.merchantName}>{item.name}</p>
                              <div className={styles.btnView}>
                                  <Button
                                      id="LinkQr"
                                      type="button"
                                      variant="contained"
                                      color="primary"
                                      className={styles.passwordLessButton}
                                      onClick={() => onProceed(item.id)}
                                  >
                                      link
                                  </Button>
                              </div>
                          </div>
                      );
                  })
                : null}
        </div>
    );
}
export default LinkQrComponent;
