import { ValidationMessage } from 'models';
import { MerchantService } from 'services';

/**
 *
 * @param {string} merchantName
 */
export function validateMerchantName(merchantName) {
    if (!validateMerchantNameUniqueness) {
        return new ValidationMessage('Please provide a Merchant name.', false);
    }

    if (merchantName.length > 250) {
        return new ValidationMessage('Merchant name should have less then 250 characters.', false);
    }

    if (merchantName.length < 3 || merchantName.replace(/\s/g, '').length < 3) {
        return new ValidationMessage('Merchant name should have at least 3 characters.', false);
    }

    return new ValidationMessage('', true);
}

/**
 *
 * @param {string} businessId
 * @param {string} clientId
 * @param {string} clientName
 */
export async function validateMerchantNameUniqueness(businessId, clientId, clientName) {
    const result = await MerchantService.checkMerchantNameIsUnique(businessId, clientId, clientName.trim());
    if (!result.data.isMerchantNameUnique) {
        return new ValidationMessage('Merchant name already exist', false);
    }
    return new ValidationMessage('', true);
}
