import React from 'react';
import styles from './user-management.module.scss';
import PropTypes from 'prop-types';
import { Button, FormCheck, FormControl } from 'react-bootstrap';
import { TableProject, ModalUserManagement, ModalConfirmation, CreateUserForm } from 'components';
import { Roles } from 'utils/enums/Roles';

function UserManagementComponent(props) {
    const {
        users,
        showAssignModal,
        setShowAssignModal,
        showConfirmationModal,
        setShowConfirmationModal,
        confirmationMessage,
        setConfirmationMessage,
        loading,
        setShowCreateUser,
        showCreateUser,
        selectedUser,
        setSelectedUser,
        onSubmitConfirmation,
        setIsForDeleting,
        setIsForUnassign,
        loadUsersAfterOperation,
        setRelationId,
        userEmail
    } = props;

    const columns = ['Name', 'Email', 'Entities', 'Actions', 'Enable'];

    const dataList = [];
    users.forEach((user, index) => {
        const isLoggedInUser = user.email === userEmail;
        const assignedEntity = React.createRef();
        const enableDisableCheck = React.createRef();
        const unassignButton = React.createRef();
        const row = [
            <td key={index + '_' + user.id}>
                <span className={styles.username}>
                    {user.firstName} {user.lastName}
                </span>
            </td>,
            <td key={index + '_' + user.id}>{user.email}</td>,
            <td key={index + '_' + user.id} className={styles.entitiesColumn}>
                {
                    <FormControl
                        id={`selectAssignedEntities-${user.id}`}
                        ref={assignedEntity}
                        as="select"
                        className={styles.entitiesSelect}
                        onChange={() => {
                            if (JSON.parse(assignedEntity.current.value).Role.name === Roles.Owner) {
                                enableDisableCheck.current.disabled = true;
                                unassignButton.current.hidden = true;
                            } else {
                                enableDisableCheck.current.disabled = false;
                                unassignButton.current.hidden = false;
                            }
                        }}
                    >
                        {user.Relationships.length > 0 ? (
                            user.Relationships.map((relationship) =>
                                relationship.businessId ? (
                                    <option key={relationship.Business.id} value={JSON.stringify(relationship)}>
                                        Business: {relationship.Business.name} ({relationship.Role.name})
                                    </option>
                                ) : relationship.clientId ? (
                                    <option key={relationship.Client.id} value={JSON.stringify(relationship)}>
                                        Client: {relationship.Client.name} ({relationship.Role.name})
                                    </option>
                                ) : (
                                    relationship.merchantId && (
                                        <option key={relationship.Merchant.id} value={JSON.stringify(relationship)}>
                                            Merchant: {relationship.Merchant.name} ({relationship.Role.name})
                                        </option>
                                    )
                                )
                            )
                        ) : (
                            <option> - </option>
                        )}
                    </FormControl>
                }
            </td>,
            <td key={index + '_' + user.id} className={styles.actionsColumn}>
                {user.isDisable && !isLoggedInUser ? (
                    <Button
                        id={`buttonDeleteUser-${user.id}`}
                        className={styles.assignButton}
                        size="sm"
                        variant="outline-danger"
                        onClick={() => {
                            setShowConfirmationModal(true);
                            setConfirmationMessage(`Are you sure you want to DELETE user ${user.email} ?`);
                            setSelectedUser(user);
                            setIsForDeleting(true);
                            setIsForUnassign(false);
                        }}
                    >
                        Delete
                    </Button>
                ) : (
                    !isLoggedInUser && (
                        <React.Fragment>
                            <Button
                                id={`buttonAssignEntity-${user.id}`}
                                className={styles.assignButton}
                                size="sm"
                                variant="outline-info"
                                onClick={() => {
                                    setShowAssignModal(true);
                                    setSelectedUser(user);
                                }}
                            >
                                Assign
                            </Button>
                            {user.Relationships.length > 0 && (
                                <Button
                                    id={`buttonUnassignEntity-${user.id}`}
                                    className={styles.assignButton}
                                    size="sm"
                                    variant="outline-danger"
                                    ref={unassignButton}
                                    hidden={user.Relationships && user.Relationships[0].Role.name === Roles.Owner}
                                    onClick={() => {
                                        const selectedRelation = JSON.parse(assignedEntity.current.value);
                                        var entityAndName = null;
                                        if (selectedRelation.businessId) {
                                            entityAndName = "Business '" + selectedRelation.Business.name + "'";
                                        } else if (selectedRelation.clientId) {
                                            entityAndName = "Client '" + selectedRelation.Client.name + "'";
                                        } else if (selectedRelation.merchantId) {
                                            entityAndName = "Merchant '" + selectedRelation.Merchant.name + "'";
                                        }
                                        const role = selectedRelation.Role.name;
                                        setShowConfirmationModal(true);
                                        setConfirmationMessage(
                                            `Are you sure you want to UNASSIGN ${entityAndName} with Role '${role}' for User '${user.email}' ?`
                                        );
                                        setIsForDeleting(false);
                                        setIsForUnassign(true);
                                        setRelationId(selectedRelation.id);
                                    }}
                                >
                                    Unassign
                                </Button>
                            )}
                        </React.Fragment>
                    )
                )}
            </td>,
            <td key={index + '_' + user.id} className={styles.switchDisableEnableUser}>
                <div>
                    <FormCheck
                        id={`switch-${user.id}`}
                        ref={enableDisableCheck}
                        type="switch"
                        checked={!user.isDisable}
                        disabled={user.Relationships && user.Relationships[0].Role.name === Roles.Owner}
                        onChange={() => {
                            setShowConfirmationModal(true);
                            setConfirmationMessage(
                                `Are you sure you want to ${user.isDisable ? 'ENABLE' : 'DISABLE'} user ${user.email} ?`
                            );
                            setSelectedUser(user);
                            setIsForDeleting(false);
                            setIsForUnassign(false);
                        }}
                        label=""
                    />
                </div>
            </td>
        ];
        dataList.push(row);
    });

    const classesForRow = styles.rowUser;

    return (
        <React.Fragment>
            <div className={styles.userManagement}>
                <div className={styles.headerPage}>
                    <h3 className={styles.textHeader}>User management</h3>
                </div>
                <div>
                    <Button id={styles.createUserBtn} onClick={() => setShowCreateUser(true)}>
                        Create user
                    </Button>
                </div>
                <TableProject
                    columns={columns}
                    dataList={dataList}
                    indexColumn={true}
                    classesForRow={classesForRow}
                    size="sm"
                    loading={loading}
                />
            </div>
            {showAssignModal && (
                <ModalUserManagement
                    show={showAssignModal}
                    setShow={setShowAssignModal}
                    selectedUser={selectedUser}
                    loadUsersAfterOperation={loadUsersAfterOperation}
                />
            )}
            {showConfirmationModal && (
                <ModalConfirmation
                    show={showConfirmationModal}
                    setShow={setShowConfirmationModal}
                    onSubmit={onSubmitConfirmation}
                >
                    {confirmationMessage}
                </ModalConfirmation>
            )}
            {showCreateUser && (
                <CreateUserForm
                    show={showCreateUser}
                    setShow={setShowCreateUser}
                    loadUsersAfterOperation={loadUsersAfterOperation}
                />
            )}
        </React.Fragment>
    );
}

UserManagementComponent.propTypes = {
    users: PropTypes.array,
    showAssignModal: PropTypes.bool,
    setShowAssignModal: PropTypes.func,
    showConfirmationModal: PropTypes.bool,
    setShowConfirmationModal: PropTypes.func,
    confirmationMessage: PropTypes.string,
    setConfirmationMessage: PropTypes.func,
    showCreateUser: PropTypes.bool,
    setShowCreateUser: PropTypes.func,
    loading: PropTypes.bool,
    selectedUser: PropTypes.object,
    setSelectedUser: PropTypes.func,
    onSubmitConfirmation: PropTypes.func,
    setIsForDeleting: PropTypes.func,
    setIsForUnassign: PropTypes.func,
    loadUsersAfterOperation: PropTypes.func,
    setRelationId: PropTypes.func
};

export default UserManagementComponent;
