import React, { useState, useEffect } from 'react';
import LinkQrComponent from './link-qr.component';
import { useGlobalStateContext } from 'contexts';
import { NonResellerMerchantService, AuthService } from 'services';
import AuthHelper from 'utils/authHelper';

function LinkQr(props) {
    const {
        globalState: { reseller },
        setRoute
    } = useGlobalStateContext();
    const [message, setMessage] = useState(`Would you like to link your QR Code?`);
    const [loading, setLoading] = useState(false);
    const [merchantsList, setMerchantsList] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        const getMerchants = async () => {
            try {
                setLoading(true);
                const response = await NonResellerMerchantService.getUserMerchants(reseller.id);
                setLoading(false);
                if (response !== null && response.isSuccesfully) {
                    let merchants = response.data.merchantsDto;
                    setMerchantsList(merchants);
                } else {
                    setError('Something went wrong!');
                }
            } catch (e) {
                setLoading(false);
                setError('Something went wrong!');
            }
        };
        getMerchants();
        // eslint-disable-next-line
    }, []);

    const onProceed = async (id) => {
        setError('');
        setLoading(true);
        try {
            if (!AuthService.isLoggedUser()) {
                setRoute('/login');
                return;
            }

            await AuthHelper.refreshCurrentSession();
            let uuid = props.location.search;
            uuid = uuid.replace('?data=', '');

            let data = {
                id: uuid
            };
            const response = await NonResellerMerchantService.linkQrCode(data, id);

            setLoading(false);

            if (response.isSuccesfully) {
                setMessage(response.data.message);
                setMerchantsList([]);
                let timer = setTimeout(() => {
                    setRoute('/home');
                    clearTimeout(timer);
                    timer = null;
                }, 3000);
            } else {
                setError(response.data.message);
                let errorTimer = setTimeout(() => {
                    setError('');
                    clearTimeout(errorTimer);
                    errorTimer = null;
                }, 5000);
            }
        } catch (e) {
            setLoading(false);

            if (e.code === 'NotAuthorizedException') setError('Session timed out!!!');
            else setError('Something went wrong!');
        }
    };

    return (
        <LinkQrComponent
            onProceed={onProceed}
            message={message}
            loading={loading}
            merchantsList={merchantsList}
            error={error}
        />
    );
}

export default LinkQr;
