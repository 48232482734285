import React from 'react';
import PropTypes from 'prop-types';
import { toTitleCase } from '../../../../utils/helper';
import styles from './transaction-status-label.module.scss';

const TransactionStatusLabelComponent = (props) => {
    const { statusColorMap, status, containerStyle = {}, containerClass = '', isPdq } = props;

    return (
        <span
            className={containerClass + ' ' + styles.transactionStatusHolder}
            style={{
                backgroundColor: statusColorMap?.backgroundColor,
                ...containerStyle
            }}
        >
            <span
                style={{
                    color: statusColorMap?.textColor
                }}
                className={styles.textStyle}
            >
                {isPdq ? status : toTitleCase(status)}
            </span>
        </span>
    );
};

export default TransactionStatusLabelComponent;

TransactionStatusLabelComponent.propTypes = {
    statusColorMap: PropTypes.shape({
        backgroundColor: PropTypes.string.isRequired,
        textColor: PropTypes.string.isRequired
    }),
    status: PropTypes.string.isRequired,
    containerStyle: PropTypes.object,
    containerClass: PropTypes.string
};
