import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';

import { formatCurrency, formatDateTimeByFormatString } from '../../../../utils/helper';
import { getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry';
import { useGlobalStateContext } from 'contexts';

const useStyles = makeStyles({
    table: {}
});

export default function PayoutsReferenceTable(props) {
    const { globalState } = useGlobalStateContext();
    const classes = useStyles();
    const { rows, type } = props;

    const dateLabel = type === 'IN-BATCH' ? 'Issued' : 'Date requested';
    const dateField = type === 'IN-BATCH' ? 'date_issued' : 'date_requested';
    const rowKeyField = type === 'IN-BATCH' ? 'batch_item_id' : 'rowKeyIndex';
    const provider = 'provider';
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Amount</TableCell>
                        <TableCell align="left">{dateLabel}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row[rowKeyField]}>
                            <TableCell align="left">
                                {formatCurrency(row.total, getCurrencyThreeAlpha(globalState.merchant?.country))}
                            </TableCell>
                            <TableCell align="left">
                                {formatDateTimeByFormatString(row[dateField], 'DD MMM HH:mm')}
                            </TableCell>
                            <TableCell align="left">{row[provider]}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

PayoutsReferenceTable.propTypes = {
    rows: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired
};
