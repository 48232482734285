import axios from 'axios';
import { Auth } from 'aws-amplify';
import base64 from 'base-64';
import utf8 from 'utf8';
import * as Sentry from '@sentry/react';
import browserHistory from 'browserHistory';
import { PORTAL_API_BASE_URL } from 'utils/constants';
import { ErrorCodes } from 'utils/enums/ErrorCode';

// axios.interceptors.request.use((config) => {
//     return Auth.currentAuthenticatedUser() //AuthService.logoutAndRedirect();
//         .then((res) => {
//             console.log(res);
//             const token = localStorage.getItem(
//                 'MyPay_CognitoIdentityServiceProvider.79gtf0alkr1mbif839puvvja5u.6e827986-cac6-4481-af21-4503adc1bd37.idToken'
//             );
//             // const token =
//             //     'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX3JlZiI6InVzZXJfamtoZ2poZ2hrIiwicm9sZSI6ImFkbWluIiwiaWF0IjoxNTk2NTQwNjk4LCJleHAiOjE1OTY1NDE1OTh9.82vprdD3zh-aDu6fZQs4R7ez264kJH7tPAaSUcuQogg';

//             if (token) {
//                 config.headers['Authorization'] = token.replace(/['"]+/g, '');
//                 // config.headers['Authorization'] = token;
//             }
//             return config;
//         })
//         .catch((err) => {
//             const token = localStorage.getItem(
//                 'MyPay_CognitoIdentityServiceProvider.79gtf0alkr1mbif839puvvja5u.6e827986-cac6-4481-af21-4503adc1bd37.idToken'
//             );
//             // const token =
//             //     'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX3JlZiI6InVzZXJfamtoZ2poZ2hrIiwicm9sZSI6ImFkbWluIiwiaWF0IjoxNTk2NTQwNjk4LCJleHAiOjE1OTY1NDE1OTh9.82vprdD3zh-aDu6fZQs4R7ez264kJH7tPAaSUcuQogg';

//             if (token) {
//                 config.headers['Authorization'] = token.replace(/['"]+/g, '');
//                 // config.headers['Authorization'] = token;
//             }
//             console.log(err);
//             return config;
//         });
// });

// axios.interceptors.request.use(
//     (config) => {
//         const token = localStorage.getItem(
//             'CognitoIdentityServiceProvider.79gtf0alkr1mbif839puvvja5u.6e827986-cac6-4481-af21-4503adc1bd37.idToken'
//         );
//         // const token =
//         //     'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX3JlZiI6InVzZXJfamtoZ2poZ2hrIiwicm9sZSI6ImFkbWluIiwiaWF0IjoxNTk2NTQwNjk4LCJleHAiOjE1OTY1NDE1OTh9.82vprdD3zh-aDu6fZQs4R7ez264kJH7tPAaSUcuQogg';

//         if (token) {
//             config.headers['Authorization'] = token.replace(/['"]+/g, '');
//             // config.headers['Authorization'] = token;
//         }
//         return config;
//     },
//     (error) => {
//         Promise.reject(error);
//     }
// );

const AuthAxios = axios.create();

AuthAxios.interceptors.request.use((config) => {
    // console.log('config', config);
    return Auth.currentAuthenticatedUser()
        .then((res) => {
            config.headers['Authorization'] = res.signInUserSession.idToken.jwtToken; //token.replace(/['"]+/g, '');
            return config;
        })
        .catch(() => {
            let userTokenIdName = null;
            for (let key in localStorage) {
                if (key.endsWith('idToken')) {
                    userTokenIdName = key;
                    break;
                }
            }
            const token = localStorage.getItem(userTokenIdName);

            if (token) {
                config.headers['Authorization'] = token.replace(/['"]+/g, '');
            }
            return config;
        });
});

AuthAxios.interceptors.response.use(
    (response) => {
        return response;
    },
    /**
     * @param {{message:string}} error
     */
    (error) => {
        Sentry.withScope((scope) => {
            scope.setTag('apiFail', 'mypay');
            scope.setUser({
                email: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).email : ''
            });
            Sentry.captureException(error);
        });
        //return Error object with Promise
        // eslint-disable-next-line
        console.log(error?.response);
        if (
            error?.response?.config?.url?.includes(PORTAL_API_BASE_URL) &&
            (error?.response?.status === 401 || error?.response?.status === 403)
        ) {
            signOut();
        }
        return Promise.reject(updateErrorMessage(error));
    }
);

const BaseAxios = axios.create();

const DatmanAxios = axios.create();

DatmanAxios.interceptors.request.use((config) => {
    let pathname = browserHistory.location.pathname;
    let merchant = sessionStorage.getItem('merchant') ? JSON.parse(sessionStorage.getItem('merchant')) : null;
    if (!merchant && pathname && (pathname.includes('merchant-details') || pathname.includes('pdq-order-merchant'))) {
        merchant = { id: pathname.substr(pathname.lastIndexOf('/') + 1) };
    }

    const { id } = merchant;

    const bytes = utf8.encode(id.toString());
    var encodedMerchantId = base64.encode(bytes);
    return Auth.currentAuthenticatedUser()
        .then((res) => {
            config.headers['Authorization'] =
                'Bearer ' + res.signInUserSession.idToken.jwtToken + '!!!' + encodedMerchantId; //token.replace(/['"]+/g, '');
            return config;
        })
        .catch(() => {
            let userTokenIdName = null;
            for (let key in localStorage) {
                if (key.endsWith('idToken')) {
                    userTokenIdName = key;
                    break;
                }
            }
            const token = localStorage.getItem(userTokenIdName);

            if (token) {
                config.headers['Authorization'] = ('Bearer ' + token + '!!!' + encodedMerchantId).replace(/['"]+/g, '');
            }
            return config;
        });
});

DatmanAxios.interceptors.response.use(
    (response) => {
        return response;
    },
    /**
     * @param {{message:string}} error
     */
    (error) => {
        Sentry.withScope((scope) => {
            scope.setTag('apiFail', 'datman');
            scope.setUser({
                email: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).email : ''
            });
            Sentry.captureException(error);
        });

        console.log(error?.response);
        if (error?.response?.status === 401 || error?.response?.status === 403) {
            signOut();
        }
        // return Error object with Promise
        return Promise.reject(updateErrorMessage(error));
    }
);

const updateErrorMessage = (error) => {
    // incase of csrf validation failure error replacing error message with generic message
    if (error?.response?.data?.errorCode === ErrorCodes.CSRF_VALIDATION_FAILURE.errorCode) {
        error.response.data.message = ErrorCodes.CSRF_VALIDATION_FAILURE.message;
    }
    return error;
};

function signOut() {
    if (window.location.origin.includes('amplifyapp.com')) {
        const localDomainParametrs = window.location.pathname.split('/');
        localStorage.clear();
        Auth.signOut();
        let urlForRedirect = `/${localDomainParametrs[1]}/login`;
        window.location.pathname = urlForRedirect;
    } else {
        localStorage.clear();
        Auth.signOut();
        window.location.pathname = '/login';
    }
}

export { AuthAxios, BaseAxios, DatmanAxios };
