import React from 'react';
import { ListChargebacks } from 'components';
import { Grid, Box, Paper } from '@material-ui/core';
import { Calendar, PageHeader } from 'components';
const ChargebacksComponent = (props) => {
    const { date: dateFromUrlParams, via: viaFromUrlParams } = props.match.params;
    const { offrange, setOffrange } = props;
    const { pathname } = props.location;
    return (
        <Grid item xs={12}>
            <Box mt={3}>
                <Calendar
                    path="list-chargebacks"
                    range={viaFromUrlParams}
                    current={pathname}
                    date={dateFromUrlParams}
                    offrange={offrange}
                    setOffrange={setOffrange}
                    via={viaFromUrlParams}
                />
            </Box>
            <Paper>
                <PageHeader
                    title={`Chargeback List`}
                    via={viaFromUrlParams}
                    path="list-chargebacks"
                    date={dateFromUrlParams}
                    showFilterButton={false}
                    offrange={offrange}
                    setOffrange={setOffrange}
                />
                <ListChargebacks {...props} />
            </Paper>
        </Grid>
    );
};
export default ChargebacksComponent;
