import React from 'react';
import styles from './roles-and-permissions.module.scss';
import { TableProject } from 'components';

function RolesAndPermissionsComponent() {
    const columns = [
        'Role',
        'Level',
        'Capabilities',
        'Create user',
        'Disable/Enable user',
        'Add/Remove user',
        'Setup/Modify bank account details',
        'Refund',
        'View transactions',
        'CRUD B/C/M level entities'
    ];

    const dataList = [
        [
            <td>Admin</td>,
            <td>Super user for the business entity</td>,
            <td>Everything humanly possible</td>,
            <td>Yes</td>,
            <td>Yes</td>,
            <td>Yes</td>,
            <td>Yes</td>,
            <td>Yes</td>,
            <td>Yes</td>,
            <td>Yes</td>
        ],

        [
            <td>Owner</td>,
            <td>User that create his own business</td>,
            <td>Everything humanly possible for his business</td>,
            <td>Yes</td>,
            <td>Yes</td>,
            <td>Yes</td>,
            <td>Yes</td>,
            <td>Yes</td>,
            <td>Yes</td>,
            <td>Yes</td>
        ],

        [
            <td>Manager</td>,
            <td>Manager</td>,
            <td>Add users to these businesses, top level view of business, drill down to detail transactions</td>,
            <td>Yes</td>,
            <td>Yes</td>,
            <td>Yes</td>,
            <td>Yes</td>,
            <td>Yes</td>,
            <td>Yes</td>,
            <td>No</td>
        ],

        [
            <td>User</td>,
            <td>User</td>,
            <td>View business data + Refunds?</td>,
            <td>No</td>,
            <td>No</td>,
            <td>No</td>,
            <td>No</td>,
            <td>No</td>,
            <td>Yes</td>,
            <td>No</td>
        ]
    ];

    return (
        <div className={styles.table}>
            <div className={styles.pageTitle}>
                <h3 className={styles.textHeader}>Roles And Permissions</h3>
            </div>
            <TableProject columns={columns} dataList={dataList} indexColumn={true} size="sm" />
        </div>
    );
}

export default RolesAndPermissionsComponent;
