import React from 'react';
import styles from './special-rent-table.module.scss';
import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import { formatCurrency } from '../../utils/helper';
import { useGlobalStateContext } from 'contexts/index.js';
import { getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry.js';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TableHeader from '../payments/transaction-tables/table-header/table-header.container';
import TransactionStatusLabel from '../payments/transaction-tables/transaction-status-label/transaction-status-label.container';
import PropTypes from 'prop-types';
import moment from 'moment';
import { UserType } from 'utils/enums/UserType';

export default function SpecialRentTableComponent(props) {
    const { globalState } = useGlobalStateContext();

    const { specialRentList, setSpecialRentDelete, editSpecialRent, setDeleteRentId, headCells } = props;
    return (
        <div className={styles.root}>
            <div>
                <Grid container xs={12} md={12}>
                    <React.Fragment>
                        {!specialRentList || specialRentList.length === 0 ? (
                            <div className={styles.noRent}>No Rents.</div>
                        ) : (
                            <TableContainer>
                                <Table>
                                    <TableHeader headCells={headCells} />
                                    <TableBody>
                                        {specialRentList.map((specialRent) => {
                                            return (
                                                <TableRow>
                                                    <TableCell align={'left'}>
                                                        <Typography>{specialRent.description}</Typography>
                                                    </TableCell>
                                                    <TableCell align={'left'}>
                                                        <React.Fragment>
                                                            <Typography>
                                                                {' '}
                                                                {formatCurrency(
                                                                    specialRent.rent_amount,
                                                                    getCurrencyThreeAlpha(globalState.merchant?.country)
                                                                )}
                                                            </Typography>
                                                        </React.Fragment>
                                                    </TableCell>
                                                    <TableCell align={'left'}>
                                                        <Typography>
                                                            {moment(specialRent.start_date).format('DD MMM YY')}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align={'left'}>
                                                        <Typography>
                                                            {moment(specialRent.end_date).format('DD MMM YY')}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align={'left'}>
                                                        <TransactionStatusLabel status={specialRent.status} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className={styles.icons}>
                                                            <span>
                                                                <EditIcon
                                                                    className={styles.editIcon}
                                                                    onClick={() => editSpecialRent(specialRent.rent_id)}
                                                                />
                                                            </span>

                                                            {globalState.user?.UserType?.name ===
                                                                UserType.SuperAdmin && (
                                                                <span>
                                                                    <DeleteIcon
                                                                        className={styles.specialRentTableDeleteIcon}
                                                                        onClick={() => {
                                                                            setDeleteRentId(specialRent.rent_id);
                                                                            setSpecialRentDelete(true);
                                                                        }}
                                                                    />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </React.Fragment>
                </Grid>
            </div>
        </div>
    );
}

SpecialRentTableComponent.propTypes = {
    editSpecialRent: PropTypes.func,
    setDeleteRentId: PropTypes.func
};
