import React from 'react';
import { ResetPasswordForm, SnackBarAndAlert } from 'components';
import styles from './reset-password.module.scss';
import { useGlobalStateContext } from 'contexts';
import { SnackbarMessages } from 'utils/text enums/snackbar-text';

function ResetPasswordComponent(props) {
    const { snackbar, setShowSnackbar } = props;
    const { globalState, setRoute } = useGlobalStateContext();
    const resetPasswordUrl = window.location.href;
    const userOnPasswordUpdatePageAfterExpiry = resetPasswordUrl.includes('passwordRotation');

    return (
        <div>
            <div className={styles.homeLogo} onClick={() => setRoute('/login')}>
                <img src={globalState.reseller?.logo} alt="Logo"></img>
            </div>
            <ResetPasswordForm userOnPasswordUpdatePageAfterExpiry={userOnPasswordUpdatePageAfterExpiry} />
            {userOnPasswordUpdatePageAfterExpiry && (
                <SnackBarAndAlert open={snackbar} onClose={() => setShowSnackbar(false)} type={'success'}>
                    {SnackbarMessages.PASSWORD_EXPIRED_MESSAGE}
                </SnackBarAndAlert>
            )}
        </div>
    );
}

export default ResetPasswordComponent;
