import React, { useState, useEffect } from 'react';
import { SyncData, AWSConfiguraion } from '../../services/amplify-storage.service';
import Amplify from 'aws-amplify';
import PropTypes from 'prop-types';
import browserHistory from 'browserHistory';
import { useGlobalStateContext } from 'contexts/global-state/global-state.context';

function AuthProvider(props) {
    const { setRoute } = useGlobalStateContext();
    const [isSyncState, setIsSyncState] = useState(false);

    useEffect(() => {
        async function syncData() {
            //need refresh user for refresh page
            try {
                await SyncData();
                Amplify.configure(AWSConfiguraion);
                setIsSyncState(true);
            } catch (err) {
                Amplify.configure(AWSConfiguraion);
                setIsSyncState(true);
                if (browserHistory.location.hash) {
                    setRoute(`/login${browserHistory.location.hash}`);
                } else {
                    setRoute('/login');
                }
            }
        }

        syncData();

        // eslint-disable-next-line
    }, []);

    return <React.Fragment> {isSyncState && props.children}</React.Fragment>;
}

AuthProvider.propTypes = {
    children: PropTypes.any
};

export { AuthProvider };
