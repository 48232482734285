import React from 'react';
import { RequestSupportFormContainer } from 'components';
import { useGlobalStateContext } from 'contexts';
import styles from './request-support-form.module.scss';
const RequestSupportFormComponent = () => {
    const { globalState } = useGlobalStateContext();
    return (
        <div>
            <div className={styles.homeLogo}>
                <img src={globalState.reseller?.logo} alt="Logo"></img>
            </div>
            <RequestSupportFormContainer />
        </div>
    );
};

export default RequestSupportFormComponent;
