import React, { useState, useEffect } from 'react';
import BankErrorComponent from './bank-error-list.component';
import { BankService } from 'services';
import { useGlobalStateContext } from 'contexts';

const BankErrorContainer = (props) => {
    const [bankErrorList, setBankErrorList] = useState([]);
    const { globalState, getRoute } = useGlobalStateContext();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [count, setCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    let headCells = [
        {
            id: 'merchantId',
            numeric: false,
            disablePadding: false,
            label: 'Merchant ID',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: 'Merchant name',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'country',
            numeric: false,
            disablePadding: false,
            label: 'Country',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'message',
            numeric: false,
            disablePadding: false,
            label: 'Message',
            hideSortingIcon: true,
            alignCenter: true
        }
    ];

    useEffect(() => {
        const fetchBankErrorData = async () => {
            setLoading(true);
            const response = await BankService.getBankErrorList();
            if (response.isSuccesfully) {
                const errorList = response.data.data;
                setBankErrorList(errorList);
                setCount(errorList.length);
            } else {
                setBankErrorList([]);
                setCount(0);
            }
            setLoading(false);
        };
        fetchBankErrorData();
    }, [globalState.merchant.id]);

    const onGoToAccount = (merchantId) => {
        let routeToGo = getRoute(`/admin-functions/${merchantId}`);
        window.open(routeToGo, '_blank');
    };

    return (
        <BankErrorComponent
            bankErrorList={bankErrorList}
            setBankErrorList={setBankErrorList}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            count={count}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            loading={loading}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            headCells={headCells}
            onGoToAccount={onGoToAccount}
        />
    );
};

export default BankErrorContainer;
