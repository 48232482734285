import React from 'react';
import AppCTA from 'pages/appCTA/appCTA.container';
import Login from '../pages/login/login.container';
import ForgotPassword from '../pages/forgot-password/forgot-password.container';
import Onboarding from '../pages/onboarding/onboarding.container';
import SignUp from '../pages/signup/signup.container';
import ExternalSignUpForm from '../pages/external-signup/signup.container';
import Help from '../pages/help/help.container';
import ResetPassword from '../pages/reset-password/reset-password.container';
import SupportUtilities from '../pages/support-utilities/support-utilities.container';
import TermsAndConditionsPage from '../pages/terms-and-conditions/terms-and-conditions.container';
import NotAuthorizedPage from '../pages/not-authorized/not-authorized.container';
import { Route } from 'react-router-dom';
import RequestSupportForm from '../pages/request-support-form/request-support-form.container';
import AdyenResponse from '../pages/adyen-response/adyen-response.container';
import LinkQr from 'pages/link-qr/link-qr.container';

import NotLoggedRoute from './not-logged-route';
import MypayOnlyNotLoggedRoute from './mypay-only-not-logged-route';
import OwnerOrAdminRoute from './owner-or-admin-route';
import AdminUserTypeRoute from './admin-user-type-route.js';
import AuditLogData from 'pages/admin/audit-log-data/audit-log-data.container';
import SuperAdminUserTypeRoute from './super-admin-user-type-route';
import Maintainance from 'pages/under-maintainance/under-maintainance.container';
import AdyenMerchantList from 'pages/admin/adyen-merchants-list/adyen-merchant-list.container';

export default [
    <NotLoggedRoute exact key="appCTA" path="/" component={AppCTA} />,

    <NotLoggedRoute exact key="loginCustomDomain" path="/login" component={Login} />,
    <MypayOnlyNotLoggedRoute exact key="signupCustomDomain" path="/signup" component={SignUp} />,
    <MypayOnlyNotLoggedRoute
        exact
        key="externalSignupCustomDomain"
        path="/signup-form"
        component={ExternalSignUpForm}
    />,
    <NotLoggedRoute exact key="forgotpasswordCustomDomain" path="/forgot-password" component={ForgotPassword} />,
    <NotLoggedRoute key="resetPasswordCustomDomain" path="/reset-password" component={ResetPassword} />,
    <OwnerOrAdminRoute exact key="onboardingCustomDomain" path="/onboarding" component={Onboarding} />,
    <AdminUserTypeRoute
        key="supportUtilitiesCustomDomain"
        path="/support-utilities/:name"
        component={SupportUtilities}
    />,
    <AdminUserTypeRoute key="adyenResponse" path="/adyen-response/:merchantId" component={AdyenResponse} />,
    <Route exact key="helpCustomDomain" path="/help" component={Help} />,
    <Route exact key="conditionsCustomDomain" path="/terms-and-conditions" component={TermsAndConditionsPage} />,
    <Route key="requestSupportForm" path="/request-support-form" component={RequestSupportForm} />,
    <Route
        exact
        key="notAuthorized"
        path="/not-authorized"
        component={() => <NotAuthorizedPage autoRedirect={true} />}
    />,
    <Route key="maintenance" path="/maintenance" component={Maintainance} />,
    <Route key="linkQr" path="/linkQr" component={LinkQr} />,
    <SuperAdminUserTypeRoute key="auditLogCustomDomain" path="/auditlogs" component={AuditLogData} />,
    <AdminUserTypeRoute
        key="adyen-blocked-merchants-list"
        path="/adyen-blocked-merchants-list"
        component={AdyenMerchantList}
    />
];
