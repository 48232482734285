import React from 'react';
import AppCTAComponent from './appCTA.component';
import { REACT_APP_DOWNLOAD_LINK } from 'utils/constants';

function AppCTA() {
    const userAgent = navigator.userAgent;
    let redirection_link = REACT_APP_DOWNLOAD_LINK;
    const mobileDevices = ['ipad', 'iphone', 'android']; // It is used to check if the the device via which this page is being accessed is a mobile view-port or not
    redirection_link = mobileDevices.some((device) => userAgent.toLowerCase().includes(device))
        ? 'https://datman-alternate.app.link/'
        : redirection_link;

    return <AppCTAComponent redirection_link={redirection_link} />;
}

export default AppCTA;
