import React from 'react';
import { ExternalSignUpForm } from 'components';
import styles from './signup.module.scss';
import { useGlobalStateContext } from 'contexts';

function SignUpComponent() {
    const { globalState } = useGlobalStateContext();

    return (
        <div>
            <ExternalSignUpForm />
        </div>
    );
}

export default SignUpComponent;
