export const chatBotScript = (url, id, data_handle_attribute) => {
    window.$zoho = window.$zoho || {};
    window.$zoho.salesiq = window.$zoho.salesiq || {
        widgetcode: '545376e93d010b1823ca879a10fb8a95956de54b2a71fe6b473059900bc9e219',
        values: {},
        ready: function () {}
    };
    var scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.id = id;
    scriptTag.defer = true;
    scriptTag.setAttribute('data-handle', data_handle_attribute);
    scriptTag.src = url;
    var firstTagOfPage = document.getElementsByTagName('script')[0];
    firstTagOfPage.parentNode.insertBefore(scriptTag, firstTagOfPage);
};
