export const ImposedBy = [
    { name: 'Brand', creator: 'reseller' },
    { name: 'Reseller', creator: 'canonical_reseller' },
    { name: 'Acquirer', creator: 'acquirer' }
];

export const ImposedByName = {
    RESELLER: 'reseller',
    CANONICAL_RESELLER: 'canonical_reseller',
    ACQUIRER: 'acquirer'
};
