import React, { useState } from 'react';
import DocumentManagementComponent from './document-management.component';
import { AdminService } from 'services';

const DocumentManagement = (props) => {
    const [disable, setDisable] = useState(true);
    const [download, setDownload] = useState(false);
    const pathMerchantId = props?.match?.params?.merchantId;
    return (
        <DocumentManagementComponent
            disable={disable}
            setDisable={setDisable}
            setDownload={setDownload}
            download={download}
            pathMerchantId={pathMerchantId}
        />
    );
};

export default DocumentManagement;
