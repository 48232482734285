import React, { useEffect, useState } from 'react';
import UserManagementComponent from './user-management.component';
import { UserService, RelationshipService } from 'services';
import { useGlobalStateContext } from 'contexts';

function UserManagement() {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [showCreateUser, setShowCreateUser] = useState(false);
    const [isForDeleting, setIsForDeleting] = useState(false);
    const [isForUnassign, setIsForUnassign] = useState(false);
    const [relationId, setRelationId] = useState(false);
    const { globalState } = useGlobalStateContext();

    useEffect(() => {
        setLoading(true);
        async function fetchData() {
            await loadUsers();
            setLoading(false);
        }
        fetchData();
    }, []);

    async function loadUsers() {
        await UserService.getUsers().then((response) => {
            if (response.isSuccesfully) {
                setUsers(response.data.users);
            } else {
                setUsers([]);
                // error
            }
        });
    }

    const onSubmitConfirmation = () => {
        if (isForDeleting) {
            onDeleteUser();
        } else if (isForUnassign) {
            onUnassignEntityForUser();
        } else {
            onDisableEnableUser();
        }
    };

    const onDisableEnableUser = () => {
        setLoading(true);
        const user = {
            email: selectedUser.email,
            isDisable: selectedUser.isDisable,
        };
        UserService.toggleDisableEnableUser(user).then((response) => {
            if (response.isSuccesfully) {
                loadUsers();
                setLoading(false);
            } else {
                // error
                setLoading(false);
            }
        });
    };

    const onDeleteUser = () => {
        setLoading(true);
        UserService.deleteUser(selectedUser.email).then((response) => {
            if (response.isSuccesfully) {
                loadUsers();
                setLoading(false);
            } else {
                // error
            }
        });
    };

    const onUnassignEntityForUser = () => {
        setLoading(true);
        RelationshipService.deleteRelationship(relationId).then((response) => {
            if (response.isSuccesfully) {
                loadUsers();
                setLoading(false);
            } else {
                // error
            }
        });
    };

    const loadUsersAfterOperation = async () => {
        setLoading(true);
        await loadUsers();
        setLoading(false);
    };

    return (
        <UserManagementComponent
            users={users}
            showAssignModal={showAssignModal}
            setShowAssignModal={setShowAssignModal}
            showConfirmationModal={showConfirmationModal}
            setShowConfirmationModal={setShowConfirmationModal}
            confirmationMessage={confirmationMessage}
            setConfirmationMessage={setConfirmationMessage}
            loading={loading}
            showCreateUser={showCreateUser}
            setShowCreateUser={setShowCreateUser}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            onSubmitConfirmation={onSubmitConfirmation}
            setIsForDeleting={setIsForDeleting}
            setIsForUnassign={setIsForUnassign}
            loadUsersAfterOperation={loadUsersAfterOperation}
            setRelationId={setRelationId}
            userEmail={globalState.user.email}
        />
    );
}

export default UserManagement;
