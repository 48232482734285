import React, { useState, useEffect } from 'react';
import { NonResellerMerchantService, AdminService } from 'services';
import DialogViewProductsComponent from './dialog-view-manage-products.component';
import { useGlobalStateContext } from 'contexts';
import { ProductStatus } from 'utils/enums/ProductsRequired';
import PropTypes from 'prop-types';

export default function DialogViewProducts(props) {
    const {
        isModalOpen,
        setIsModalOpen,
        productId,
        approvedProducts,
        approve,
        reject,
        setReject,
        setApprove,
        value,
        setSelectedProducts
    } = props;

    const [onReject, setOnReject] = useState(false);
    const [reason, setReason] = useState('');
    const { globalState, setGlobalState } = useGlobalStateContext();
    const [isLoading, setIsLoading] = useState(false);
    const [permissionEntities, setPermissionEntitites] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);

    useEffect(() => {
        setPermissionEntitites(value ? value : []);
        setSelectedProduct(value ? { ...value[productId] } : {});
    }, [value, productId]);

    const index = productId;

    const onApprove = async () => {
        setApprove(true);
        setIsLoading(true);
        const previousStatus = permissionEntities[index].status;
        permissionEntities[index].status =
            permissionEntities[index].status === ProductStatus.ADDITION_PENDING
                ? ProductStatus.ACTIVE
                : ProductStatus.INACTIVE;

        const data = {
            productsRequiredIds: permissionEntities,
            resellerId: globalState.reseller.id
        };
        await NonResellerMerchantService.completeOnboardingStep(globalState.merchant?.id, 7, data);

        await AdminService.SendProductStatusEmail(globalState.merchant?.id, {
            productId: permissionEntities[index].id,
            status: permissionEntities[index].status,
            resellerId: globalState.reseller.id,
            prevStatus: previousStatus
        });

        setGlobalState((globalState) => ({
            ...globalState,
            merchant: {
                ...globalState.merchant,
                merchantProductRequired: [...permissionEntities]
            }
        }));

        setIsModalOpen(false);
        setIsLoading(false);
        setApprove(false);
    };

    const onSubmit = async () => {
        setIsLoading(true);
        const prevStatus = permissionEntities[index].status;
        permissionEntities[index].status =
            permissionEntities[index].status === ProductStatus.ADDITION_PENDING
                ? ProductStatus.REJECTED
                : permissionEntities[index].status === ProductStatus.DELETION_PENDING
                ? ProductStatus.ACTIVE
                : ProductStatus.INACTIVE;
        permissionEntities[index].additionalInfo = reason;
        setSelectedProducts([...permissionEntities]);

        const data = {
            productsRequiredIds: permissionEntities,
            resellerId: globalState.reseller.id
        };
        await NonResellerMerchantService.completeOnboardingStep(globalState.merchant?.id, 7, data);

        setGlobalState((globalState) => ({
            ...globalState,
            merchant: {
                ...globalState.merchant,
                merchantProductRequired: [...permissionEntities]
            }
        }));

        await AdminService.SendProductStatusEmail(globalState.merchant?.id, {
            productId: permissionEntities[index].id,
            status: permissionEntities[index].status,
            reason: permissionEntities[index].additionalInfo,
            resellerId: globalState.reseller.id,
            prevStatus: prevStatus
        });

        setSelectedProduct({ ...permissionEntities[productId] });
        setIsModalOpen(false);
        setOnReject(false);
        setIsLoading(false);
    };

    return (
        <DialogViewProductsComponent
            {...props}
            approve={approve}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            productId={productId}
            onReject={onReject}
            setOnReject={setOnReject}
            setReason={setReason}
            approvedProducts={approvedProducts}
            setApprove={setApprove}
            reject={reject}
            setReject={setReject}
            isLoading={isLoading}
            onSubmit={onSubmit}
            onApprove={onApprove}
            selectedProduct={selectedProduct}
        />
    );
}
DialogViewProducts.propTypes = {
    approvedProducts: PropTypes.array,
    setApprovedProducts: PropTypes.func,
    isModalOpen: PropTypes.bool,
    setIsModelOpen: PropTypes.func,
    productId: PropTypes.number,
    rejectedProducts: PropTypes.array,
    setRejectedProducts: PropTypes.func,
    isLoading: PropTypes,
    approve: PropTypes.bool,
    reject: PropTypes.bool,
    value: PropTypes.array
};
