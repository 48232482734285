import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'react-bootstrap';
import styles from './profile.module.scss';

function ProfileComponent(props) {
    const { user } = props;

    return (
        <Card xs={3} className={styles.profileCard}>
            <div>
                <Row>
                    <Col>Username:</Col>
                    <Col>
                        {user?.firstName} {user?.lastName}
                    </Col>
                </Row>
                <Row>
                    <Col>Email:</Col>
                    <Col>{user?.email}</Col>
                </Row>
            </div>
        </Card>
    );
}

ProfileComponent.propTypes = {
    user: PropTypes.object
};

export default ProfileComponent;
