import React from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, makeStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const styles = makeStyles((theme) => ({
    card: {
        height: '100px',
        marginBottom: '20px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f2f2f2'
        }
    },
    container: {
        height: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        '& img': {
            width: '50px',
            height: '50px'
        }
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: '1.3em'
    },
    description: {
        color: 'gray',
        fontSize: '1em'
    }
}));

function CardMultiSelectDatmanComponent(props) {
    const classes = new styles();
    const { selectedEntities, setSelectedEntitites, list, onChange } = props;

    const onCardClick = (element) => {
        const elementId = element.id;

        if (selectedEntities.includes(elementId)) {
            const index = selectedEntities.indexOf(elementId);

            if (index > -1) {
                selectedEntities.splice(index, 1);
            }
        } else {
            selectedEntities.push(elementId);
        }

        onChange([...selectedEntities]);
        setSelectedEntitites([...selectedEntities]);
    };

    return (
        <React.Fragment>
            {list.map((element) => {
                return (
                    <Card key={element.id} className={classes.card} onClick={() => onCardClick(element)}>
                        <Grid container className={classes.container}>
                            <Grid item xs={4} className={classes.iconContainer}>
                                <img alt="" src={element.imgPath}></img>
                            </Grid>
                            <Grid item xs={6}>
                                <span className={classes.title}>{element.name}</span>
                            </Grid>
                            {selectedEntities.includes(element.id) && (
                                <Grid item xs={2}>
                                    <CheckCircleIcon color="primary" />
                                </Grid>
                            )}
                        </Grid>
                    </Card>
                );
            })}
        </React.Fragment>
    );
}

CardMultiSelectDatmanComponent.propTypes = {
    selectedEntities: PropTypes.array,
    setSelectedEntitites: PropTypes.func,
    list: PropTypes.array,
    onChange: PropTypes.func
};

export default CardMultiSelectDatmanComponent;
