import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './filter-tabs.module.scss';

const StyledTab = withStyles(() => ({
    root: {
        textTransform: 'initial',
        color: '#8783b5',
        fontWeight: '500',
        fontSize: '14px',
        padding: '0px 20px',
        minWidth: '100px'
    }
}))((props) => <Tab {...props} />);

const FilterTabsComponent = (props) => {
    const { tabFilterValue, setTabFilterValue, tabList } = props;
    return (
        <div>
            <Tabs
                value={tabFilterValue}
                indicatorColor="primary"
                className={styles.tabsDiv}
                onChange={(event, newValue) => setTabFilterValue(newValue)}
            >
                {tabList &&
                    Object.entries(tabList).map(([key, value], i) => <StyledTab key={i} value={value} label={key} />)}
            </Tabs>
        </div>
    );
};

export default FilterTabsComponent;

FilterTabsComponent.propTypes = {
    tabFilterValue: PropTypes.string.isRequired,
    setTabFilterValue: PropTypes.func.isRequired,
    tabList: PropTypes.object.isRequired
};
