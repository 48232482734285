import React from 'react';
import { useGlobalStateContext } from 'contexts';
import { UserType } from 'utils/enums/UserType';
import { ResellerType } from 'utils/enums/ResellerType';
import LoggedRoute from './logged-route';

export default function RoutingManager(props, { ...rest }) {
    const { globalState } = useGlobalStateContext();
    const isReseller = globalState.user?.UserType?.name === UserType.Reseller;
    const datmanReseller = globalState.reseller?.id === ResellerType.DATMAN;
    const resellerComponent = datmanReseller && isReseller ? props.resellerComponent : props.pdqComponent;

    return (
        <LoggedRoute {...rest} exact component={isReseller ? resellerComponent : props.merchantComponent}></LoggedRoute>
    );
}
