import React from 'react';
import styles from './header.module.scss';
import { useGlobalStateContext } from 'contexts';
import moment from 'moment';
import { useMediaQuery, Typography } from '@material-ui/core';

// import ReactAvatar from 'react-avatar';
// import logo from '../../assets/images/logo.png';
// import { Image } from 'react-bootstrap';
// import { ModalUserIcon } from 'components';
// import { UserType } from 'utils/enums/UserType';
import { ResellerType } from 'utils/enums/ResellerType';

function HeaderComponent(props) {
    const { globalState, setGlobalState, setRoute } = useGlobalStateContext();
    // const [show, setShow] = useState(false);
    // const isReseller = globalState.user?.UserType?.name === UserType.Reseller;

    const isUserLoggedIn = globalState.user;
    const isOmniPayReseller = globalState.reseller?.name === ResellerType.OMNIPAY;
    const timeOut = props.timeOut;
    const showBanner = props.showBanner;
    const mediaLessThan960px = useMediaQuery('(max-width:959px)');

    if (isUserLoggedIn) {
        return (
            <React.Fragment>
                {/* {isReseller ? (
                    <div className={styles.header}>
                        <div className={styles.logo}>
                            <Image
                                src={logo}
                                onClick={() => {
                                    setRoute('/home');
                                }}
                            />
                        </div>
                        {globalState.user && (
                            <div className={styles.iconUser}>
                                <ReactAvatar
                                    src={globalState.user.pictureUrl}
                                    name={globalState.user.firstName + ' ' + globalState.user.lastName}
                                    size={40}
                                    round="50%"
                                    onClick={() => setShow(true)}
                                />
                                <ModalUserIcon show={show} onClose={() => setShow(false)} />
                            </div>
                        )}
                    </div>
                ) : ( */}
                <div className={styles.merchantHeader}>
                    <div
                        className={styles.homeLogo}
                        onClick={() => {
                            setGlobalState({
                                ...globalState,
                                shouldCheckForUrgentMessages: !globalState.shouldCheckForUrgentMessages
                            });
                            if (timeOut) {
                                localStorage.setItem('skip_clicked', moment().format('DD-MM-YYYY hh:mm:ss'));
                            }
                            setRoute('/home');
                        }}
                    >
                        <img
                            src={globalState.reseller?.logo}
                            className={isOmniPayReseller && styles.omniPayLogoStyle}
                            alt="logo"
                        ></img>
                    </div>
                    <div className={styles.banner}>
                        {showBanner && !mediaLessThan960px && (
                            <div className={styles.listItemContainer}>
                                <Typography color="primary" className={styles.bubbleMessage}>
                                    <div>
                                        To make sure your details are up to date, please complete the details
                                        highlighted in red
                                    </div>
                                </Typography>
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    } else {
        return <div></div>;
    }
}

export default HeaderComponent;
