import React from 'react';
import { Box, Paper, Grid } from '@material-ui/core';
import { PageHeader, Calendar, PayoutsList, Banner } from 'components';
import WithdrawalContainer from '../withdrawal/withdrawal.container';
import style from './payouts.module.scss';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { useGlobalStateContext } from 'contexts';
import { bannerMessage } from 'utils/banner-message';

const PayoutsComponent = (props) => {
    const { globalState } = useGlobalStateContext();
    const { date: dateFromUrlParams, via: viaFromUrlParams } = props.match.params;
    const { pathname } = props.location;
    const { setSelectedProvider, selectedProvider } = props;
    const isMerchantAccountClosed = globalState.merchant?.status === MerchantStatus.CLOSED;

    return (
        <div>
            <Grid item xs={12}>
                {isMerchantAccountClosed && <Banner message={bannerMessage.CLOSED_MERCHANT_ACCOUNT} />}
                <WithdrawalContainer />
                <Box mt={3}>
                    <Calendar
                        {...props}
                        path="payouts"
                        range={viaFromUrlParams}
                        current={pathname}
                        date={dateFromUrlParams}
                    />
                </Box>
                <Paper className={style.payoutsContainer}>
                    <PageHeader
                        {...props}
                        title={`Payouts`}
                        path="payouts"
                        date={dateFromUrlParams}
                        setSelectedProvider={setSelectedProvider}
                        selectedProvider={selectedProvider}
                        payoutAcquireFilter={true}
                    />
                    <PayoutsList {...props} selectedProvider={selectedProvider} />
                </Paper>
            </Grid>
        </div>
    );
};

export default PayoutsComponent;
