import React from 'react';
import PropTypes from 'prop-types';
import style from './onboarding-title.module.scss';
import { Typography } from '@material-ui/core';
import { translateText } from 'utils/languageTranslater';
import { DotFlashingLoading } from 'components';

function OnbordingTitleComponent(props) {
    return (
        <React.Fragment>
            {props.isLoading && (
                <div className={style.dots}>
                    <DotFlashingLoading />
                </div>
            )}
            <Typography component="span" color="primary" className={style.formTitle}>
                {props.transalterEnabled === true ? translateText({ id: props.title, text: props.title }) : props.title}
            </Typography>
            <Typography component="span" color="primary" className={style.formSubTitle}>
                {props.subTransalterEnabled === true
                    ? translateText({ id: props.subTitle, text: props.subTitle })
                    : props.subTitle}
            </Typography>
        </React.Fragment>
    );
}

OnbordingTitleComponent.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    isLoading: PropTypes.bool
};

export default OnbordingTitleComponent;
