import React, { useState } from 'react';

const SelectedElementContext = React.createContext();

function SelectedElementProvider(props) {
    const [selectedElement, setSelectedElement] = useState();

    return (
        <SelectedElementContext.Provider value={{ selectedElement, setSelectedElement }}>
            {props.children}
        </SelectedElementContext.Provider>
    );
}

function useSelectedElementContext() {
    const selectedElementContext = React.useContext(SelectedElementContext);
    if (!selectedElementContext) {
        throw new Error(`useSelectedElementContext must be used within a SelectedElementProvider`);
    }
    return selectedElementContext;
}

export { SelectedElementProvider, useSelectedElementContext };
