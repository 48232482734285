import React, { useEffect, useState } from 'react';
import ProductAddRemoveDialogBoxComponent from './product-add-remove-dialog-box.component';
import { ProductStatus } from 'utils/enums/ProductsRequired';

export default function ProductAddRemoveDialogBox(props) {
    const { selectedEntity, selectedProducts, setSelectedProducts, setOpenDialog, onSubmit } = props;
    const [dialogBoxTitle, setDialogBoxTitle] = useState('');
    const [dialogBoxContentText, setDialogBoxContentText] = useState('');

    useEffect(() => {
        if (selectedEntity?.status === ProductStatus.INACTIVE) {
            setDialogBoxTitle('Add Product');
            setDialogBoxContentText('You selected a new product. Do you wish to add new product?');
        } else {
            setDialogBoxTitle('Remove Product');
            setDialogBoxContentText('Are you sure you want to remove this product?');
        }
    }, [selectedEntity]);

    const confirmAction = () => {
        setOpenDialog(false);
        const elementId = selectedEntity.id;
        const index = selectedProducts.indexOf(selectedProducts.find((e) => e.id === elementId));
        selectedProducts[index].status =
            selectedProducts[index].status === ProductStatus.INACTIVE
                ? ProductStatus.ADDITION_PENDING
                : ProductStatus.DELETION_PENDING;
        setSelectedProducts([...selectedProducts]);
        onSubmit();
    };

    return (
        <ProductAddRemoveDialogBoxComponent
            {...props}
            dialogBoxTitle={dialogBoxTitle}
            dialogBoxContentText={dialogBoxContentText}
            confirmAction={confirmAction}
        />
    );
}
