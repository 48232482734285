import React, { useState } from 'react';
import PayoutsComponent from './payouts.component';

const PayoutsList = (props) => {
    const [loading, setLoading] = useState(false);
    const provider = sessionStorage.getItem('selectedProvider') ? sessionStorage.getItem('selectedProvider') : 'All';
    const [selectedProvider, setSelectedProvider] = useState(provider);
    return (
        <PayoutsComponent
            {...props}
            loading={loading}
            setLoading={setLoading}
            setSelectedProvider={setSelectedProvider}
            selectedProvider={selectedProvider}
        />
    );
};

export default PayoutsList;
