import React from 'react';
import {
    Grid,
    Button,
    NativeSelect,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Typography,
    Box,
    LinearProgress
} from '@material-ui/core';
import TableHeader from '../../payments/transaction-tables/table-header/table-header.container';
import { Input, FormItem, SelectedDropDown } from 'components/onboarding/onboarding-ui-components';
import { SnackBarAndAlert } from 'components';
import PropTypes from 'prop-types';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import styles from './upload-terms-and-conditions.module.scss';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { stableSort, getComparator, formatDateTimeByFormatString } from 'utils/helper';
import { TermsAndConditionsStatusName } from 'utils/enums/TermsAndConditionStatus';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { LoadingScreen } from 'components';
import { ImposedBy } from 'utils/enums/TermsAndConditions';
import { useGlobalStateContext } from 'contexts';
import { AuthService } from 'services';
import { Redirect } from 'react-router-dom';

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

const linerProgressUseStyles = makeStyles({
    root: {
        width: '420px'
    }
});

const useStyles = makeStyles(() => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    }
}));

const UploadTermsAndConditionsComponent = (props) => {
    const classes = useStyles();
    const linerProgressClasses = linerProgressUseStyles();
    const {
        displayList,
        isLoading,
        selectedEntityValue,
        progress,
        selectFile,
        uploadFile,
        fileName,
        disableInput,
        selectEntityValue,
        termsAndConditionsList,
        order,
        orderBy,
        uploadingFailed,
        uploadSuccess,
        setUploadingFailed,
        fileExistError,
        setFileExistError,
        removeSelectedFile,
        setUploadSuccess,
        disableUpload,
        imposedBy,
        selectEntity,
        disableEntityInput
    } = props;

    const mediaLessThan600px = useMediaQuery('(max-width:599px)');
    const { getRoute } = useGlobalStateContext();

    const userIsLoggedIn = AuthService.isLoggedUser();
    console.log('userIsLoggedIn', userIsLoggedIn);

    return { userIsLoggedIn } ? (
        <Paper className={styles.uploadTermsAndConditionContainer}>
            <Grid>
                <Grid className={styles.pageTitle}>
                    <Typography className={styles.termsAndConditionHeader}>Terms and Conditions</Typography>
                </Grid>
                <Grid container justify="center" style={mediaLessThan600px ? { marginLeft: 20 } : {}}>
                    <Grid item xs={12} md={12}>
                        <Grid style={{ marginTop: mediaLessThan600px ? 0 : 40 }}>
                            <Typography color="error">
                                On successful upload, all the previous Terms & conditions will be deactivated
                            </Typography>
                        </Grid>
                        <Grid style={{ marginTop: '10px' }}>
                            <Grid item xs={11} md={9}>
                                <FormItem>
                                    <Grid container spacing={2}>
                                        <Grid item xs={3} md={3}>
                                            <Typography style={{ fontWeight: '700' }} color="primary">
                                                T&C Imposed by:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={7} md={7}>
                                            <Grid container>
                                                <Grid item container xs={12} md={12} justify="space-between">
                                                    <Grid item xs={5} md={5}>
                                                        <NativeSelect
                                                            input={
                                                                <Input
                                                                    id="select-imposed-by"
                                                                    value={imposedBy}
                                                                    onChange={(e) => {
                                                                        selectEntity(e.target.value);
                                                                    }}
                                                                />
                                                            }
                                                        >
                                                            {ImposedBy.map((item) => (
                                                                <option key={item.creator} value={item.creator}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                        </NativeSelect>
                                                    </Grid>
                                                    <Grid item xs={6} md={6}>
                                                        <SelectedDropDown
                                                            id="select-entity-value"
                                                            value={selectedEntityValue}
                                                            hideNoOptionsTextSelect={true}
                                                            options={displayList}
                                                            getOptionLabel={(option) => option.name}
                                                            onChange={(event, newValue) => {
                                                                selectEntityValue(newValue);
                                                            }}
                                                            customListRender={(option) => (
                                                                <React.Fragment>
                                                                    <span>{option.name}</span>
                                                                </React.Fragment>
                                                            )}
                                                            disabled={disableEntityInput}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid>
                                                    <Grid
                                                        className={
                                                            disableInput
                                                                ? `${styles.selectTermsAndConditionInput} ${styles.disableInputClass}`
                                                                : `${styles.selectTermsAndConditionInput}`
                                                        }
                                                        container
                                                        onClick={() =>
                                                            document
                                                                .getElementById('select-terms-and-condition-file')
                                                                .click()
                                                        }
                                                    >
                                                        <DescriptionOutlinedIcon className={styles.pdfIcon} />
                                                        <Typography style={{ color: '#43425d' }}>
                                                            Select file
                                                        </Typography>
                                                    </Grid>
                                                    <Typography color="error" className={styles.allowedFormateStyle}>
                                                        Allowed format: PDF
                                                    </Typography>
                                                    {fileName && (
                                                        <div className={linerProgressClasses.root}>
                                                            <LinearProgressWithLabel value={progress} />
                                                            <Grid
                                                                item
                                                                container
                                                                justify="space-between"
                                                                alignItems="center"
                                                                className={styles.tableContainer}
                                                                xs={12}
                                                                md={12}
                                                            >
                                                                <Grid
                                                                    container
                                                                    item
                                                                    xs={6}
                                                                    ms={6}
                                                                    justify="space-between"
                                                                    alignItems="center"
                                                                >
                                                                    <Typography style={{ color: '#43425d' }}>
                                                                        {fileName}
                                                                    </Typography>
                                                                    <CancelOutlinedIcon
                                                                        style={{ fontSize: '17px' }}
                                                                        onClick={removeSelectedFile}
                                                                        disabled={disableUpload}
                                                                    />
                                                                </Grid>
                                                                <Grid className={styles.buttonContainer}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        className={styles.uploadButton}
                                                                        onClick={uploadFile}
                                                                        disabled={disableUpload}
                                                                    >
                                                                        Upload
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    )}
                                                    <input
                                                        type="file"
                                                        placeholder="select file"
                                                        id="select-terms-and-condition-file"
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => selectFile(e.target.files[0])}
                                                        accept="application/pdf"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </FormItem>
                            </Grid>
                            <Grid style={{ marginTop: '30px' }}>
                                <Typography style={{ fontWeight: '700' }} color="primary">
                                    File history
                                </Typography>
                                <TableContainer component={Paper} className={styles.tableContainer}>
                                    {termsAndConditionsList.length === 0 ? (
                                        <div className={styles.noFiles}>No files found</div>
                                    ) : (
                                        <Table
                                            aria-label="simple table"
                                            aria-labelledby="tableTitle"
                                            size="small"
                                            stickyHeader
                                        >
                                            <TableHeader classes={classes} {...props} />

                                            <TableBody>
                                                {stableSort(termsAndConditionsList, getComparator(order, orderBy)).map(
                                                    (termsAndCondition) => (
                                                        <TableRow key={termsAndCondition.id}>
                                                            <TableCell align="center">
                                                                <a
                                                                    href={termsAndCondition.link}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    {termsAndCondition.fileName}
                                                                </a>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {TermsAndConditionsStatusName[termsAndCondition.status]}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {formatDateTimeByFormatString(
                                                                    termsAndCondition.activatedAt,
                                                                    'DD MMM HH:mm'
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {termsAndCondition.deactivatedAt
                                                                    ? formatDateTimeByFormatString(
                                                                          termsAndCondition.deactivatedAt,
                                                                          'DD MMM HH:mm'
                                                                      )
                                                                    : 'N/A'}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                    )}
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                    {uploadingFailed && (
                        <SnackBarAndAlert open={uploadingFailed} onClose={() => setUploadingFailed(false)} type="error">
                            Uploading failed.
                        </SnackBarAndAlert>
                    )}
                    {fileExistError && (
                        <SnackBarAndAlert open={fileExistError} onClose={() => setFileExistError(false)} type="error">
                            File with name {fileName} already exist.
                        </SnackBarAndAlert>
                    )}
                    {uploadSuccess && (
                        <SnackBarAndAlert open={uploadSuccess} onClose={() => setUploadSuccess(false)} type="success">
                            File uploaded successfully.
                        </SnackBarAndAlert>
                    )}
                    <LoadingScreen open={isLoading} />
                </Grid>
            </Grid>
        </Paper>
    ) : (
        <Redirect to={getRoute(`/login`)} />
    );
};

export default UploadTermsAndConditionsComponent;

UploadTermsAndConditionsComponent.propTypes = {
    message: PropTypes.string.isRequired,
    setMessage: PropTypes.func.isRequired,
    canonicalResellerList: PropTypes.array,
    isLoading: PropTypes.bool,
    progress: PropTypes.bool,
    selectFile: PropTypes.func,
    uploadFile: PropTypes.func,
    fileName: PropTypes.string,
    disableInput: PropTypes.bool,
    selectCanonicalReseller: PropTypes.func,
    termsAndConditionsList: PropTypes.array,
    order: PropTypes.string,
    orderBy: PropTypes.string,
    uploadingFailed: PropTypes.bool,
    uploadSuccess: PropTypes.bool,
    setUploadingFailed: PropTypes.func,
    fileExistError: PropTypes.bool,
    setFileExistError: PropTypes.func,
    removeSelectedFile: PropTypes.func,
    setUploadSuccess: PropTypes.func,
    disableUpload: PropTypes.bool
};
