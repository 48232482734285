import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, Button, DialogContentText, Typography } from '@material-ui/core';
import styles from './invoice-toggle-modes-dialog.module.scss';
import Divider from '@material-ui/core/Divider';

export default function InvoiceToggleModesModalComponent(props) {
    const {
        showInvoiceUpdateDialogBox,
        setShowInvoiceUpdateDialogBox,
        showInvoiceStatus,
        showInvoiceUpdate,
        showInvoiceUpdateInitiated
    } = props;
    return (
        <React.Fragment>
            <Dialog
                open={showInvoiceUpdateDialogBox}
                onClose={() => setShowInvoiceUpdateDialogBox(false)}
                className={{
                    paper: styles.paper
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                max-width="false"
            >
                {showInvoiceUpdate ? (
                    <div>
                        <DialogContent
                            classes={{
                                root: styles.dialogContent
                            }}
                        >
                            <DialogContentText id="alert-dialog-description">
                                {showInvoiceStatus ? (
                                    <Typography>Do you want to enable Invoice for this merchant?</Typography>
                                ) : (
                                    <Typography>Do you want to disable Invoice for this merchant?</Typography>
                                )}
                            </DialogContentText>
                            <Divider />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    setShowInvoiceUpdateDialogBox(false);
                                }}
                                color="primary"
                                variant="outlined"
                                className={styles.leavebtn}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => showInvoiceUpdateInitiated(showInvoiceStatus)}
                                color="primary"
                                className={styles.dilogbtn}
                            >
                                Continue
                            </Button>
                        </DialogActions>
                    </div>
                ) : null}
            </Dialog>
        </React.Fragment>
    );
}

InvoiceToggleModesModalComponent.propTypes = {
    showInvoiceUpdateDialogBox: PropTypes.bool,
    setShowInvoiceUpdateDialogBox: PropTypes.func,
    showInvoiceStatus: PropTypes.bool,
    showInvoiceUpdate: PropTypes.bool,
    showInvoiceUpdateInitiated: PropTypes.func
};
