import React from 'react';
import PropTypes from 'prop-types';
import styles from './table-header.module.scss';
import { TableHead, TableRow, TableSortLabel, TableCell, makeStyles } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const TableHeaderComponent = (props) => {
    const { classes, order, orderBy, handleRequestSort, headCells, handleFilter } = props;
    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };
    const useStyles = makeStyles({
        icon: {
            color: '#034f85 !important',
            transform: 'rotate(360deg)'
        }
    });
    const tableSortClasses = useStyles();

    return (
        <TableHead>
            <TableRow className={styles.tableRow}>
                {headCells.map(
                    (headCell) =>
                        !headCell.notShow && (
                            <TableCell
                                key={headCell.id}
                                align={headCell.centerAlignment ? 'center' : headCell.rightAlign ? 'right' : 'left'}
                                className={(headCell.alignCenter && styles.statusColumn) || classes.headWidth}
                                padding={headCell.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === headCell.id ? order : false}
                                style={{ zIndex: '0' }}
                            >
                                {headCell.showFilterIcon && !headCell.hideSortingIcon ? (
                                    <span>
                                        <div className={styles.buttonText} onClick={() => handleFilter(headCell.id)}>
                                            <TableSortLabel
                                                IconComponent={
                                                    headCell.showFilterIcon && headCell.isFilterActive
                                                        ? FilterAltIcon
                                                        : ArrowDropDownIcon
                                                }
                                                classes={{ icon: tableSortClasses.icon }}
                                                active={true}
                                                key={headCell.id}
                                            >
                                                <span>
                                                    {headCell.label}
                                                    {headCell.childLabel ? (
                                                        <div className={styles.childLabel}>{headCell.childLabel}</div>
                                                    ) : null}
                                                </span>
                                            </TableSortLabel>
                                        </div>
                                    </span>
                                ) : !headCell.hideSortingIcon ? (
                                    <span>
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                            IconComponent={ArrowDropDownIcon}
                                        >
                                            <span>
                                                {headCell.label}
                                                {headCell.childLabel ? (
                                                    <div className={styles.childLabel}>{headCell.childLabel}</div>
                                                ) : null}
                                            </span>
                                            {orderBy === headCell.id ? (
                                                <span className={classes.visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </span>
                                ) : (
                                    <span>
                                        {headCell.label}
                                        {headCell.childLabel ? <div>{headCell.childLabel}</div> : null}
                                    </span>
                                )}
                            </TableCell>
                        )
                )}
            </TableRow>
        </TableHead>
    );
};

export default TableHeaderComponent;

TableHeaderComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    handleRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    headCells: PropTypes.array.isRequired
};
