import { MerchantCountries } from 'utils/enums/MerchantCountries';

export function validateVatNumber(country, vatNumber) {
    var isValid;
    switch (country) {
        case MerchantCountries.UNITED_KINGDOM:
            if (vatNumber === '' || vatNumber === null) {
                isValid = true;
                break;
            }
            isValid = vatNumber;
            break;
        default:
            isValid = true;
            break;
    }

    return isValid;
}
