import React, { useState } from 'react';

const AdminFunctionsContext = React.createContext();

function AdminFunctionsProvider(props) {
    const [adminFunctions, setAdminFunctions] = useState({});

    return (
        <AdminFunctionsContext.Provider value={{ adminFunctions, setAdminFunctions }}>
            {props.children}
        </AdminFunctionsContext.Provider>
    );
}

function useAdminFunctionsContext() {
    const adminFunctionsContext = React.useContext(AdminFunctionsContext);
    if (!adminFunctionsContext) {
        throw new Error(`AdminFunctionsContext must be used within a AdminFunctionsProvider`);
    }
    return adminFunctionsContext;
}

export { AdminFunctionsProvider, useAdminFunctionsContext };
