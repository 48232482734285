import { AuthAxios } from './interceptor.service.js';
import { CustomerServiceEndpoint } from '../utils/constants';
import { SuccesResponse, ErrorResponse } from 'models';

async function getBusinesses() {
    try {
        const requestResult = await AuthAxios.get(`${CustomerServiceEndpoint}/businesses`);
        return new SuccesResponse(requestResult.data);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function getBusiness(businessId) {
    try {
        const requestResult = await AuthAxios.get(`${CustomerServiceEndpoint}/businesses/${businessId}`);
        return new SuccesResponse(requestResult.data);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function createBusiness(business) {
    try {
        const responce = await AuthAxios.post(`${CustomerServiceEndpoint}/businesses`, { business: business });
        return new SuccesResponse(responce.data);
    } catch (error) {
        const message = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(message);
    }
}

async function updateBusiness(business) {
    try {
        const responce = await AuthAxios.put(`${CustomerServiceEndpoint}/businesses/${business.id}`, { business });
        return new SuccesResponse(responce.data);
    } catch (error) {
        const message = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(message);
    }
}

async function deleteBusiness(businessId) {
    try {
        const response = await AuthAxios.delete(`${CustomerServiceEndpoint}/businesses/${businessId}`);

        return new SuccesResponse(response.data);
    } catch (error) {
        const message = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(message);
    }
}

async function checkBusinessName(businessName) {
    try {
        const response = await AuthAxios.post(`${CustomerServiceEndpoint}/businesses/check-name`, {
            name: businessName
        });
        return new SuccesResponse(response.data);
    } catch (error) {
        const message = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(message);
    }
}

export default {
    getBusinesses,
    getBusiness,
    updateBusiness,
    deleteBusiness,
    createBusiness,
    checkBusinessName
};
