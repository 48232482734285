import { SuccesResponse, ErrorResponse } from 'models';
import { DatmanAxios } from './interceptor.service.js';
import { DatmanAPIEndpoint } from 'utils/constants';

async function TransactionRefund(data, csrfToken) {
    try {
        const response = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/refund`, JSON.stringify(data), {
            headers: { 'X-CSRF-TOKEN': csrfToken }
        });
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        const errMessage = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

export default {
    TransactionRefund
};
