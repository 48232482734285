export const DocumentReviewStatus = {
    ACTIVE: 'Active',
    NEED_APPROVAL: 'New',
    REJECTED: 'Rejected',
    DELETED: 'Deleted',
    OLD: 'Old',
    ALL: 'All',
    OVERRIDDEN: 'Overridden'
};

export const DocumentStatusToName = {
    ACTIVE: 'ACTIVE',
    NEED_APPROVAL: 'NEED_APPROVAL',
    REJECTED: 'REJECTED',
    DELETED: 'DELETED',
    OLD: 'OLD',
    OVERRIDDEN: 'OVERRIDDEN',
    MISSING: 'MISSING'
};
