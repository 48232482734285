import React, { useState, useEffect } from 'react';
import TransactionListComponent from './payments.component';
import moment from 'moment';
import { UserType } from 'utils/enums/UserType';
import { ResellerType } from 'utils/enums/ResellerType';
import { MerchantCountries } from 'utils/enums/MerchantCountries.js';
import { useGlobalStateContext } from 'contexts';
import { cleverTapClick } from 'utils/helper';
import { REACT_APP_CARD_PAYMENT_ARCHIVE_DATE } from 'utils/constants';
import { Messages } from 'utils/enums/messages';

const TransactionList = (props) => {
    const selectedAcquirers = sessionStorage.getItem('selectedAcquirer');
    const [channelFilter, setChannelFilter] = useState('All');
    const { globalState } = useGlobalStateContext();

    const [daterange, setDateRange] = useState({ start: moment().subtract(1, 'days'), end: moment() });
    const [dates, setDates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [offrange, setOffrange] = useState(true);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [daysRange, setDaysRange] = useState({});
    const [acquirer, setAcquirer] = useState(JSON.parse(selectedAcquirers));
    const [snackbar, setSnackbar] = useState({ type: '', message: '' });
    const isAdmin =
        globalState.user?.UserType?.name === UserType.Admin || globalState.user?.UserType?.name === UserType.SuperAdmin;
    const isDatmanReseller = globalState.reseller?.name === ResellerType.DATMAN;
    const merchantCountry = globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM;
    const showAcquirer = isAdmin && isDatmanReseller && merchantCountry;
    const acquirerName = globalState.merchant.paymentProviders;

    const onChangeDateRange = (startDate, endDate) => {
        setDateRange({ start: startDate, end: endDate });
        setOffrange(true);
        const initialDate = moment(startDate);
        const finalDate = moment(endDate).add(1, 'days');
        let includedMonths = [];
        let rangeDates;

        if (initialDate.isBefore(finalDate)) {
            rangeDates = {
                from: initialDate.format('YYYY-MM-DD'),
                to: finalDate.format('YYYY-MM-DD')
            };
            includedMonths.push(rangeDates);
            // initialDate.add(1, 'month');
            setDates(includedMonths);
        }
        setStartDate(initialDate.format('DD-MM-YYYY'));
        setEndDate(moment(endDate).format('DD-MM-YYYY'));
        const data = {
            date_start: initialDate.format('DD-MM-YYYY'),
            date_end: moment(endDate).format('DD-MM-YYYY')
        };
        cleverTapClick('Date_selector_click', globalState, data);
    };
    useEffect(() => {
        const fetchPaymentProviders = async () => {
            setLoading(true);
            const currentAcquirer = selectedAcquirers ? JSON.parse(selectedAcquirers) : acquirerName;
            setAcquirer(currentAcquirer);
            setLoading(false);
        };
        fetchPaymentProviders();
        //eslint-disable-next-line
    }, [globalState.merchant.paymentProviders]);

    const isDateBeforeCutOffDate = (date) => {
        const inputDate = moment(date, 'DD-MM-YYYY');
        const thresholdDate = moment(REACT_APP_CARD_PAYMENT_ARCHIVE_DATE, 'DD-MM-YYYY');
        return inputDate.isBefore(thresholdDate);
    };

    const dateRangeArchived = (startDate, endDate) =>
        isDateBeforeCutOffDate(startDate) && isDateBeforeCutOffDate(endDate);

    const isDateRangeArchived = dateRangeArchived(startDate, endDate);

    const snackbarVisibility = (fromDate) => {
        if (isDateBeforeCutOffDate(fromDate)) {
            setSnackbar({
                type: 'success',
                message: Messages.PAYMENT_VISIBILITY(moment(REACT_APP_CARD_PAYMENT_ARCHIVE_DATE).format('MMMM D, YYYY'))
            });
        } else {
            setSnackbar({
                type: '',
                message: ''
            });
        }
    };

    useEffect(() => {
        snackbarVisibility(startDate);
        //eslint-disable-next-line
    }, [startDate, endDate]);

    return (
        <TransactionListComponent
            {...props}
            channelFilter={channelFilter}
            setChannelFilter={setChannelFilter}
            onChangeDateRange={onChangeDateRange}
            dates={dates}
            daterange={daterange}
            offrange={offrange}
            setOffrange={setOffrange}
            showAcquirer={showAcquirer}
            setLoading={setLoading}
            loading={loading}
            setStartDate={setStartDate}
            startDate={startDate}
            endDate={endDate}
            setEndDate={setEndDate}
            daysRange={daysRange}
            setDaysRange={setDaysRange}
            acquirer={acquirer}
            setAcquirer={setAcquirer}
            acquirerName={acquirerName}
            snackbar={snackbar}
            setSnackbar={setSnackbar}
            isDateRangeArchived={isDateRangeArchived}
        />
    );
};

export default TransactionList;
