import React from 'react';
import { Grid, Box, Paper } from '@material-ui/core';
import { PageHeader, Calendar, GfoPaymentsList, Banner } from 'components';
import WithdrawalContainer from '../withdrawal/withdrawal.container';
import style from './gfo-payments.module.scss';
import PropTypes from 'prop-types';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { useGlobalStateContext } from 'contexts';
import { bannerMessage } from 'utils/banner-message';

const GfoPaymentsListComponent = (props) => {
    const { globalState } = useGlobalStateContext();
    const { date: dateFromUrlParams, via: viaFromUrlParams } = props.match.params;
    const { pathname } = props.location;
    const { onChangeDateRange, daterange, offrange, setOffrange, daysRange, setDaysRange } = props;
    const isMerchantAccountClosed = globalState.merchant?.status === MerchantStatus.CLOSED;

    return (
        <div>
            {isMerchantAccountClosed && <Banner message={bannerMessage.CLOSED_MERCHANT_ACCOUNT} />}
            <WithdrawalContainer />
            <Grid item xs={12}>
                <Box mt={3}>
                    <Calendar
                        {...props}
                        path="gfo-payments"
                        range={viaFromUrlParams}
                        current={pathname}
                        date={dateFromUrlParams}
                        onChangeDateRange={onChangeDateRange}
                        daterange={daterange}
                        offrange={offrange}
                        setOffrange={setOffrange}
                        setDaysRange={setDaysRange}
                    />
                </Box>
                <Paper className={style.gfoPaymentsContainer}>
                    <PageHeader
                        title={`Google Food Orders`}
                        via={viaFromUrlParams}
                        path="gfo-payments"
                        date={dateFromUrlParams}
                        showFilterButton={false}
                        channelFilter={props.channelFilter}
                        setChannelFilter={props.setChannelFilter}
                        onChangeDateRange={onChangeDateRange}
                        offrange={offrange}
                        setOffrange={setOffrange}
                        daysRange={daysRange}
                    />
                    <GfoPaymentsList {...props} daterange={daterange} />
                </Paper>
            </Grid>
        </div>
    );
};

export default GfoPaymentsListComponent;

GfoPaymentsListComponent.propTypes = {
    onChangeDateRange: PropTypes.func,
    dates: PropTypes.array,
    daterange: PropTypes.object,
    setClear: PropTypes.func,
    offrange: PropTypes.bool,
    setOffrange: PropTypes.func,
    setDaysRange: PropTypes.func
};
