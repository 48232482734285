import React from 'react';
import { Redirect } from 'react-router-dom';
import { useGlobalStateContext } from 'contexts';
import { Roles } from 'utils/enums/Roles';
import LoggedRoute from './logged-route';
import { UserType } from 'utils/enums/UserType';
import { MerchantStatus } from 'utils/enums/MerchantStatus';

const OwnerOrAdminRoute = ({ component: Component, ...rest }) => {
    const { globalState } = useGlobalStateContext();
    const isAdmin =
        globalState.user?.UserType?.name === UserType.Admin || globalState.user?.UserType?.name === UserType.SuperAdmin;
    const isSuperAdmin = globalState.user?.UserType?.name === UserType.SuperAdmin;
    const isMerchantClosed = globalState.merchant?.status === MerchantStatus.CLOSED;
    const isMerchantUser = globalState.user?.UserType?.name === UserType.Merchant;
    const isSuperAdminAndMerchantClosed = ((isAdmin || isMerchantUser) && !isMerchantClosed) || isSuperAdmin;

    const canNotAccessThePage = (merchant, transactionPagePath) => {
        if (transactionPagePath.includes('onboarding')) {
            return (
                [MerchantStatus.ACTIVE, MerchantStatus.WATCHLIST, MerchantStatus.CLOSED].includes(merchant?.status) &&
                globalState.merchant?.userRole === Roles.Owner
            );
        }
        return false;
    };

    return (
        <LoggedRoute
            {...rest}
            render={(props) =>
                ((!canNotAccessThePage(globalState.merchant, rest.path) &&
                    globalState.merchant?.userRole === Roles.Owner) ||
                    isAdmin) &&
                isSuperAdminAndMerchantClosed ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={
                            globalState.customDomain === false
                                ? `/${globalState.reseller?.portalURL}/not-authorized`
                                : '/not-authorized'
                        }
                    />
                )
            }
        />
    );
};

export default OwnerOrAdminRoute;
