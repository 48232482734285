import { DatmanAxios } from './interceptor.service.js';
import { SuccesResponse, ErrorResponse } from 'models';
import { DatmanAPIEndpoint } from 'utils/constants';

async function getChargebacksList(chargebacksRequest) {
    const { year, month } = chargebacksRequest;
    /* const requestResult = {
        status: '200',
        data: {
            data: {
                chargebacks: [
                    {
                        id: '6693463830841786368',
                        amount: 300,
                        chargebackDate: '2020-09-06T16:35:35.000Z',
                        transactionDate: '2020-08-06T16:35:35.000Z',
                        chargebackReason: 'Some chargebackReason1',
                        description: 'Some description1',
                        status: 'won'
                    },
                    {
                        id: '6693463830841786369',
                        amount: 300,
                        chargebackDate: '2020-09-06T16:35:35.000Z',
                        transactionDate: '2020-08-06T16:35:35.000Z',
                        chargebackReason: 'Some chargebackReason2',
                        description: 'Some description2',
                        status: 'need response'
                    },
                    {
                        id: '6693463830841786367',
                        amount: 300,
                        chargebackDate: '2020-09-06T16:35:35.000Z',
                        transactionDate: '2020-08-06T16:35:35.000Z',
                        chargebackReason: 'Some chargebackReason3',
                        description: 'Some description3',
                        status: 'need response'
                    },
                    {
                        id: '6693463830841786366',
                        amount: 300,
                        chargebackDate: '2020-09-06T16:35:35.000Z',
                        transactionDate: '2020-08-06T16:35:35.000Z',
                        chargebackReason: 'Some chargebackReason4',
                        description: 'Some description4',
                        status: 'lost'
                    },
                    {
                        id: '6693463830841786365',
                        amount: 300,
                        chargebackDate: '2020-09-06T16:35:35.000Z',
                        transactionDate: '2020-08-06T16:35:35.000Z',
                        chargebackReason: 'Some chargebackReason5',
                        description: 'Some description5',
                        status: 'won'
                    },
                    {
                        id: '6693463830841786364',
                        amount: 300,
                        chargebackDate: '2020-09-06T16:35:35.000Z',
                        transactionDate: '2020-08-06T16:35:35.000Z',
                        chargebackReason: 'Some chargebackReason6',
                        description: 'Some description6',
                        status: 'won'
                    },
                    {
                        id: '6693463830841786363',
                        amount: 300,
                        chargebackDate: '2020-09-06T16:35:35.000Z',
                        transactionDate: '2020-08-06T16:35:35.000Z',
                        chargebackReason: 'Some chargebackReason7',
                        description: 'Some description7',
                        status: 'lost'
                    },
                    {
                        id: '6693463830841786361',
                        amount: 300,
                        chargebackDate: '2020-09-06T16:35:35.000Z',
                        transactionDate: '2020-08-06T16:35:35.000Z',
                        chargebackReason: 'Some chargebackReason8',
                        description: 'Some description8',
                        status: 'need response'
                    }
                ]
            }
        }
    }; */
    try {
        const response = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/chargebacks`, { year, month });
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        const errMessage = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

export default {
    getChargebacksList
};
