import React from 'react';
import svg from '../../assets/images/maintenance.svg';
import { ResellerLogoMap } from 'utils/enums/ResellerLogoMap';
import { useGlobalStateContext } from 'contexts';
import { ResellerType } from 'utils/enums/ResellerType';
import styles from './under-maintainance.module.scss';
function MaintainanceComponent(props) {
    const { globalState } = useGlobalStateContext();
    return (
        <div className={styles.mainDiv}>
            <img
                src={ResellerLogoMap[globalState.reseller?.id]}
                alt={globalState.reseller.name}
                className={globalState.reseller?.name === ResellerType.DATMAN ? styles.logoImage : styles.omniLogo}
            />
            <div className={styles.imageDiv}>
                <img src={svg} alt="under-maintenance" className={styles.svg} />
                <span className={styles.maintainenceMessage}>{globalState?.maintainanceMessage}</span>
            </div>
            <div className={styles.copyRigthDiv}>
                Image by
                <a href="https://www.freepik.com/free-vector/hand-drawn-construction-background_1583772.htm#query=maintenance&position=4&from_view=keyword">
                    Freepik
                </a>
            </div>
        </div>
    );
}

export default MaintainanceComponent;
