import React from 'react';
import { Typography, OutlinedInput } from '@material-ui/core';
import { Label, FormItem } from 'components/onboarding/onboarding-ui-components';
import styles from './valid-url-input.module.scss';
import InputAdornment from '@material-ui/core/InputAdornment';

const ValidDomainInputComponent = (props) => {
    const {
        onChange,
        disabled,
        id,
        labelText,
        labelId,
        doValidation,
        showDomainValidationMessage,
        setShowDomainValidationMessage
    } = props;

    return (
        <FormItem>
            <Label text={labelText} id={labelId}></Label>
            <OutlinedInput
                autoComplete="off"
                className={styles.customOutlineInput}
                startAdornment={
                    <InputAdornment position="start" style={{ marginRight: '0px' }}>
                        https://
                    </InputAdornment>
                }
                id={id}
                onChange={(e) => {
                    const url = e.target.value ? `https://${e.target.value}` : '';
                    doValidation(url);
                    onChange(url);
                    setShowDomainValidationMessage(false);
                }}
                disabled={disabled}
            />
            {showDomainValidationMessage && (
                <div className={styles.notificationMessageContainer}>
                    <Typography color="error" style={{ marginLeft: '10px', fontSize: '14px' }}>
                        Link is invalid
                    </Typography>
                </div>
            )}
        </FormItem>
    );
};

export default ValidDomainInputComponent;
