import React, { useState } from 'react';
import GfoPaymentsListComponent from './gfo-payments.component';
import moment from 'moment';

const GfoPaymentsList = (props) => {
    const [channelFilter, setChannelFilter] = useState('All');
    const [daterange, setDateRange] = useState({ start: moment().subtract(1, 'days'), end: moment() });
    const [dates, setDates] = useState([]);
    const [offrange, setOffrange] = useState(true);
    const [loading, setLoading] = useState(false);
    const [daysRange, setDaysRange] = useState({});

    const onChangeDateRange = (startDate, endDate) => {
        setDateRange({ start: startDate, end: endDate });
        setOffrange(true);
        const initialDate = moment(startDate);
        const finalDate = moment(endDate).add(1, 'days');
        let includedMonths = [];
        let rangeDates;

        if (initialDate.isBefore(finalDate)) {
            rangeDates = {
                from: initialDate.format('YYYY-MM-DD'),
                to: finalDate.format('YYYY-MM-DD')
            };
            includedMonths.push(rangeDates);
            // initialDate.add(1, 'month');
            setDates(includedMonths);
        }
    };
    return (
        <GfoPaymentsListComponent
            {...props}
            channelFilter={channelFilter}
            setChannelFilter={setChannelFilter}
            onChangeDateRange={onChangeDateRange}
            dates={dates}
            daterange={daterange}
            offrange={offrange}
            setOffrange={setOffrange}
            loading={loading}
            setLoading={setLoading}
            daysRange={daysRange}
            setDaysRange={setDaysRange}
        />
    );
};

export default GfoPaymentsList;
