import React from 'react';
import { MerchantResellerList } from 'components';
import { MerchantPdqList } from 'components';
import { useGlobalStateContext } from 'contexts';
import { ResellerType } from 'utils/enums/ResellerType';

function DashboardResellerComponent() {
    const { globalState } = useGlobalStateContext();
    const isOmniPay = globalState.reseller?.name === ResellerType.OMNIPAY;
    return isOmniPay ? <MerchantResellerList /> : <MerchantPdqList />;
}

export default DashboardResellerComponent;
