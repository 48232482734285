import React, { useState, useEffect } from 'react';
import CanonicalResellerUpdateAccountComponennt from './canonical-reseller-update-account.component';
import { CanonicalResellerService } from 'services';
import { useGlobalStateContext } from 'contexts';
import { InputValidators } from 'validators';
import { difference, updateAccountMapKeyWord } from 'utils/helper';

function CanonicalResellerUpdateAccount() {
    const { globalState, setRoute } = useGlobalStateContext();
    const [disabled, setDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [canonicalResellerDetails, setCanonicalResellerDetails] = useState({});
    const [oldCanonicalResellerDetails, setOldCanonicalResellerDetails] = useState({});
    const [phoneCountryDialCode, setPhoneCountryDialCode] = useState('');
    const [snackbarDetails, setSnackbarDetails] = useState({ type: '', message: '' });

    useEffect(() => {
        const allFieldsCompleted =
            InputValidators.isWebsiteUrlValid(canonicalResellerDetails.websiteUrl) &&
            canonicalResellerDetails.postCode &&
            canonicalResellerDetails.addressLine1 &&
            canonicalResellerDetails.city &&
            canonicalResellerDetails.telNumber &&
            canonicalResellerDetails.country &&
            canonicalResellerDetails.telNumber !== phoneCountryDialCode;

        const updatedAccount =
            canonicalResellerDetails.websiteUrl !== oldCanonicalResellerDetails.websiteUrl ||
            canonicalResellerDetails.postCode !== oldCanonicalResellerDetails.postCode ||
            canonicalResellerDetails.addressLine1 !== oldCanonicalResellerDetails.addressLine1 ||
            canonicalResellerDetails.city !== oldCanonicalResellerDetails.city ||
            canonicalResellerDetails.telNumber !== oldCanonicalResellerDetails.telNumber ||
            canonicalResellerDetails.addressLine2 !== oldCanonicalResellerDetails.addressLine2 ||
            canonicalResellerDetails.country !== oldCanonicalResellerDetails.country;

        if (allFieldsCompleted && updatedAccount) setDisabled(false);
        else setDisabled(true);
        // eslint-disable-next-line
    }, [canonicalResellerDetails, oldCanonicalResellerDetails]);

    useEffect(() => {
        const getCanonicalResellerData = async () => {
            setIsLoading(true);
            const result = await CanonicalResellerService.getCanonicalResellerData(globalState.canonicalReseller?.id);

            if (result.isSuccesfully) {
                setCanonicalResellerDetails(result.data.canonicalResellerDto);
                setOldCanonicalResellerDetails(result.data.canonicalResellerDto);
            }

            setIsLoading(false);
        };

        getCanonicalResellerData();
        // eslint-disable-next-line
    }, []);

    const selectAddressData = (addressData, setObject, object) => {
        if (addressData) {
            setObject({
                ...object,
                addressLine1: addressData.line_1,
                addressLine2: addressData.line_2,
                city: addressData.town_or_city,
                country: addressData.country
            });
        }
    };

    const onSubmit = async () => {
        const updateCanonicalResellerDetails = updateAccountMapKeyWord(
            difference(canonicalResellerDetails, oldCanonicalResellerDetails),
            oldCanonicalResellerDetails
        );

        setIsLoading(true);
        const response = await CanonicalResellerService.updateCanonicalResellerData(globalState.canonicalReseller?.id, {
            canonicalResellerDetails,
            resellerId: globalState.reseller.id,
            updateCanonicalResellerDetails
        });
        setIsLoading(false);
        if (response.isSuccesfully) {
            setSnackbarDetails({ type: 'success', message: 'Your account details have been updated successfully' });
            sessionStorage.setItem('accountUpdated', true);
        } else {
            setShowModal(true);
            setModalMessage('An error occurred. Please contact support or try again.');
        }
    };

    return (
        <CanonicalResellerUpdateAccountComponennt
            disabled={disabled}
            phoneNumberValidator={InputValidators.validatePhoneNumber}
            onSubmit={onSubmit}
            selectAddressData={selectAddressData}
            showModal={showModal}
            setShowModal={setShowModal}
            modalMessage={modalMessage}
            isLoading={isLoading}
            canonicalResellerDetails={canonicalResellerDetails}
            setCanonicalResellerDetails={setCanonicalResellerDetails}
            setPhoneCountryDialCode={setPhoneCountryDialCode}
            snackbarDetails={snackbarDetails}
            setSnackbarDetails={setSnackbarDetails}
            setRoute={setRoute}
        />
    );
}

export default CanonicalResellerUpdateAccount;
