import React, { useEffect, useState } from 'react';
import DatmanResellerPaymentDetailsComponent from './datman-reseller-payment-details.component';
import { PaymentStatus } from 'utils/enums/PaymentStatus';

function DatmanResellerPaymentDetailsContainer(props) {
    const { transactionId } = props;
    const [payment, setPayment] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [status, setStatus] = useState(null);
    const [isCopyAlertOpen, setIsCopyAlertOpen] = useState(false);
    useEffect(() => {
        const getPayment = async () => {
            //const response = await TransactionsService.getPayemntDetails(referenceId);
            if (props.payment) {
                //const details = response.data.data;
                setPayment(props.payment);
                setStatus(
                    props.payment.payment_status
                        ? PaymentStatus[props.payment.payment_status]
                        : props.payment.status && 'Success'
                );
            } else {
                setError(true);
            }
            setLoading(false);
        };
        getPayment();
    });
    const handleCopyPaymentId = () => {
        navigator.clipboard.writeText(payment.id);
        setIsCopyAlertOpen(true);
    };

    const closeCopyAlert = () => {
        setIsCopyAlertOpen(false);
    };

    return (
        <DatmanResellerPaymentDetailsComponent
            {...props}
            payment={payment}
            error={error}
            loading={loading}
            show={show}
            setShow={setShow}
            transactionId={transactionId}
            handleCopyPaymentId={handleCopyPaymentId}
            closeCopyAlert={closeCopyAlert}
            isCopyAlertOpen={isCopyAlertOpen}
            status={status}
        ></DatmanResellerPaymentDetailsComponent>
    );
}

export default DatmanResellerPaymentDetailsContainer;
