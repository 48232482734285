import React from 'react';
import styles from './canonical-reseller-onboarding.module.scss';
import {
    Paper,
    Button,
    Typography,
    Box,
    Grid,
    TextField,
    makeStyles,
    CircularProgress,
    NativeSelect,
    InputAdornment,
    OutlinedInput
} from '@material-ui/core';
import {
    Input,
    Label,
    FormItem,
    PostCodeSelector,
    SelectedDropDown
} from 'components/onboarding/onboarding-ui-components';
import PhoneInput from 'react-phone-input-2';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { checkEmptyValue } from 'validators/onboarding/onboarding-check-value.validator';
import { InputValidators } from 'validators';
import NumberFormat from 'react-number-format';
import { getCurrencySymbol } from 'utils/getCurrencyByCountry';
import { SnackBarAndAlert, LoadingScreen, ValidURLInput, ValidEmailInput } from 'components';
import { emailValidationPattern } from 'utils/enums/EmailValidationPattern';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

const useStyles = makeStyles(() => ({
    noOptions: {
        color: 'red !important'
    }
}));

const CanonicalResellerOnboardingComponent = (props) => {
    const classes = useStyles();

    const {
        canonicalResellerDetails,
        orderDetails,
        canonicalResellerCharges,
        setCanonicalResellerDetails,
        setOrderDetails,
        setCanonicalResellerCharges,
        value,
        setValue,
        handleSelectedCompany,
        inputValue,
        setInputValue,
        openDropdown,
        setOpenDropdown,
        getCompanyDetails,
        isLoading,
        companyTypes,
        isNotLimited,
        setIsNotLimited,
        disabled,
        companies,
        initialTermOptions,
        submitOnboarding,
        message,
        showMessage,
        messageType,
        setShowMessage,
        maxAmount,
        disableInput,
        fileName,
        removeSelectedFile,
        selectFile
    } = props;

    return (
        <Paper className={styles.registerContainer}>
            <Box className={styles.divTitle}>
                <Typography color="primary" component="span">
                    <span className={styles.registrationTitle}>Reseller Onboarding</span>
                </Typography>
            </Box>
            <Grid className={styles.resellerInfoContainer}>
                <Grid className={styles.subTitle}>
                    <Typography component="span" color="primary" className={styles.formTitle}>
                        Reseller details
                    </Typography>
                </Grid>
                <Grid container className={styles.Container} justify="space-around">
                    <Grid item xs={10} sm={5}>
                        <FormItem>
                            <Label text="Company type*" id="contact-company-type"></Label>
                            <NativeSelect
                                input={
                                    <Input
                                        placeholder="Legal Entity/Business Type"
                                        autoFocus
                                        id="business-type"
                                        value={canonicalResellerDetails.companyType}
                                        onChange={(e) => {
                                            setIsNotLimited(e.target.value?.toString() !== '1');
                                            setCanonicalResellerDetails({
                                                ...canonicalResellerDetails,
                                                companyType: e.target.value
                                            });
                                        }}
                                    ></Input>
                                }
                            >
                                <option key={'--Please select--'} value={'--Please select--'}>
                                    --please select--
                                </option>
                                {companyTypes.map((type) => {
                                    return (
                                        <option key={type.id} value={type.id}>
                                            {type.name}
                                        </option>
                                    );
                                })}
                            </NativeSelect>
                        </FormItem>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                        {!isNotLimited && (
                            <FormItem>
                                <Label
                                    text="Company look-up"
                                    id="contact-company-lookup-lebel"
                                    transalterEnabled="true"
                                ></Label>
                                <Grid container className={styles.companyContainer}>
                                    <Grid xs={12} sm={8}>
                                        <Autocomplete
                                            autoComplete="off"
                                            noOptionsText={'Company not found'}
                                            id="contact-name-address-input-company-lookup-name"
                                            size="small"
                                            open={openDropdown}
                                            options={companies || []}
                                            getOptionLabel={(option) =>
                                                `${option.title ?? ''}
                                        ${option.company_number ?? ''}
                                        ${option.address_snippet ?? ''}`
                                            }
                                            classes={{ noOptions: classes.noOptions }}
                                            className={`${styles.withoutBorders} ${styles.customInput}`}
                                            value={value}
                                            onChange={(event, newValue) => {
                                                setValue(newValue);
                                                handleSelectedCompany(newValue);
                                                setOpenDropdown(false);
                                            }}
                                            inputValue={inputValue}
                                            onInputChange={(event, newInputValue) => {
                                                setInputValue(newInputValue);
                                            }}
                                            renderOption={(option) => {
                                                return (
                                                    <div>
                                                        <strong>{option.title ?? ''}</strong>
                                                        <br />
                                                        {`${option.company_number ?? ''}
                                                                    ${option.address_snippet ?? ''}`}
                                                    </div>
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className={styles.inputLookupCompany}
                                                    placeholder="Company number/name"
                                                    variant="outlined"
                                                />
                                            )}
                                            onBlur={getCompanyDetails}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={4}>
                                        <React.Fragment>
                                            <Button
                                                id="lookup-company-button"
                                                className={styles.lookupCompanyButton}
                                                color="primary"
                                                variant="contained"
                                                onClick={getCompanyDetails}
                                            >
                                                {isLoading ? (
                                                    <CircularProgress color="white" size={20} />
                                                ) : (
                                                    <span className={styles.textButton}>Look-up</span>
                                                )}
                                            </Button>
                                        </React.Fragment>
                                    </Grid>
                                </Grid>
                            </FormItem>
                        )}
                    </Grid>
                </Grid>
                <Grid container className={styles.Container} justify="space-around">
                    <Grid item xs={10} sm={5}>
                        <FormItem>
                            <Label text="Company name*" id="contact-company-name"></Label>
                            <Input
                                id="contact-company-name-input"
                                fullWidth={true}
                                value={checkEmptyValue(canonicalResellerDetails.companyName)}
                                inputProps={{ maxLength: 150 }}
                                onChange={(e) => {
                                    setCanonicalResellerDetails({
                                        ...canonicalResellerDetails,
                                        companyName: e.target.value
                                    });
                                }}
                            />
                        </FormItem>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                        <FormItem>
                            <Label text="Company number*" id="contact-company-number"></Label>
                            <NumberFormat
                                className={styles.companyNumber}
                                customInput={OutlinedInput}
                                inputProps={{ inputMode: 'numeric', maxLength: 20 }}
                                pattern="\d*"
                                allowLeadingZeros={true}
                                allowNegative={false}
                                isNumericString={true}
                                value={checkEmptyValue(canonicalResellerDetails.companyNumber)}
                                id="contact-company-number-input"
                                onValueChange={(values) => {
                                    const { value } = values;
                                    setCanonicalResellerDetails({
                                        ...canonicalResellerDetails,
                                        companyNumber: value
                                    });
                                }}
                                aria-describedby="outlined-weight-helper-text"
                                labelWidth={0}
                            />
                        </FormItem>
                    </Grid>
                </Grid>
                <Grid container className={styles.Container} justify="space-around">
                    <Grid item xs={10} sm={5}>
                        <div className={styles.formContainer}>
                            <FormItem>
                                <Label text="Post Code*" id="post-code"></Label>
                                <PostCodeSelector
                                    postCodeValue={checkEmptyValue(canonicalResellerDetails.postCode)}
                                    onPostCodeChange={(postCode) => {
                                        setCanonicalResellerDetails({
                                            ...canonicalResellerDetails,
                                            postCode: postCode
                                        });
                                    }}
                                />
                            </FormItem>
                            <FormItem>
                                <Label text="Address line 1*" id="address"></Label>
                                <Input
                                    id="address-input"
                                    value={canonicalResellerDetails.addressLine1}
                                    inputProps={{ maxLength: 150 }}
                                    onChange={(e) => {
                                        setCanonicalResellerDetails({
                                            ...canonicalResellerDetails,
                                            addressLine1: e.target.value
                                        });
                                    }}
                                />
                            </FormItem>
                            <FormItem>
                                <Label text="Address line 2" id="address"></Label>
                                <Input
                                    id="address-input"
                                    value={canonicalResellerDetails.addressLine2}
                                    inputProps={{ maxLength: 150 }}
                                    onChange={(e) => {
                                        setCanonicalResellerDetails({
                                            ...canonicalResellerDetails,
                                            addressLine2: e.target.value
                                        });
                                    }}
                                />
                            </FormItem>
                            <FormItem>
                                <Label text="Town/City*" id="city"></Label>
                                <Input
                                    id="city-input"
                                    value={canonicalResellerDetails.city}
                                    inputProps={{ maxLength: 150 }}
                                    onChange={(e) => {
                                        setCanonicalResellerDetails({
                                            ...canonicalResellerDetails,
                                            city: e.target.value
                                        });
                                    }}
                                />
                            </FormItem>
                            <FormItem>
                                <Label text="Country*" id="country"></Label>
                                <NativeSelect
                                    input={
                                        <Input
                                            placeholder="country"
                                            id="country-input"
                                            value={canonicalResellerDetails.country}
                                            onChange={(e) => {
                                                setCanonicalResellerDetails({
                                                    ...canonicalResellerDetails,
                                                    country: e.target.value
                                                });
                                            }}
                                        ></Input>
                                    }
                                    disabled
                                >
                                    {Object.keys(MerchantCountries).map((key) => (
                                        <option key={MerchantCountries[key]} value={MerchantCountries[key]}>
                                            {MerchantCountries[key]}
                                        </option>
                                    ))}
                                </NativeSelect>
                            </FormItem>
                            <FormItem>
                                <Label text="Support telephone number" id="support-number"></Label>
                                <PhoneInput
                                    country={'gb'}
                                    name="phone"
                                    inputClass={styles.phoneInput}
                                    enableLongNumbers="true"
                                    inputProps={{ maxLength: 28 }}
                                    value={checkEmptyValue(canonicalResellerDetails.supportNumber)}
                                    alwaysDefaultMask={true}
                                    countryCodeEditable={false}
                                    onChange={(phone) => {
                                        setCanonicalResellerDetails({
                                            ...canonicalResellerDetails,
                                            supportNumber: InputValidators.validatePhoneNumber(phone)
                                        });
                                    }}
                                />
                            </FormItem>
                            {/* <ValidURLInput
                                labelText="Logo url"
                                labelId="logo-url"
                                id="Logo-url-input"
                                fullWidth={true}
                                inputProps={{ maxLength: 250 }}
                                value={canonicalResellerDetails.logoUrl}
                                onChange={(e) => {
                                    setCanonicalResellerDetails({
                                        ...canonicalResellerDetails,
                                        logoUrl: e.target.value
                                    });
                                }}
                            /> */}
                        </div>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                        <div className={styles.formContainer}>
                            <FormItem>
                                <Label text="Owner name*" id="Owner-name"></Label>
                                <Input
                                    id="Owner-name-input"
                                    fullWidth={true}
                                    inputProps={{ maxLength: 150 }}
                                    value={checkEmptyValue(canonicalResellerDetails.primaryContactName)}
                                    onChange={(e) => {
                                        setCanonicalResellerDetails({
                                            ...canonicalResellerDetails,
                                            primaryContactName: e.target.value.replace(/[^A-Za-z0-9\s]/gi, '')
                                        });
                                    }}
                                />
                            </FormItem>
                            <ValidEmailInput
                                labelText="Owner email address*"
                                labelId="owner-email-address"
                                id="owner-email-address-input"
                                fullWidth={true}
                                type="email"
                                pattern={emailValidationPattern.regexString}
                                value={checkEmptyValue(canonicalResellerDetails.primaryContactEmail)}
                                onChange={(e) => {
                                    setCanonicalResellerDetails({
                                        ...canonicalResellerDetails,
                                        primaryContactEmail: e.target.value.toLowerCase()
                                    });
                                }}
                                autoComplete="off"
                            />

                            <FormItem>
                                <Label text="Telephone number*" id="contact-telephone-number"></Label>
                                <PhoneInput
                                    country={'gb'}
                                    name="phone"
                                    inputClass={styles.phoneInput}
                                    value={checkEmptyValue(canonicalResellerDetails.telNumber)}
                                    alwaysDefaultMask={true}
                                    countryCodeEditable={false}
                                    onChange={(phone) => {
                                        setCanonicalResellerDetails({
                                            ...canonicalResellerDetails,
                                            telNumber: InputValidators.validatePhoneNumber(phone)
                                        });
                                    }}
                                />
                            </FormItem>
                            <ValidURLInput
                                labelText="Business website*"
                                labelId="website-url"
                                id="website-url-input"
                                value={canonicalResellerDetails.websiteUrl}
                                onChange={(value) => {
                                    setCanonicalResellerDetails({
                                        ...canonicalResellerDetails,
                                        websiteUrl: value
                                    });
                                }}
                            />
                            <FormItem>
                                <Label text="Trading name*" id="Trading-name"></Label>
                                <Input
                                    id="Trading-name-input"
                                    fullWidth={true}
                                    value={checkEmptyValue(canonicalResellerDetails.tradingName)}
                                    inputProps={{ maxLength: 150 }}
                                    onChange={(e) => {
                                        setCanonicalResellerDetails({
                                            ...canonicalResellerDetails,
                                            tradingName: e.target.value.replace(/[^A-Za-z0-9\s]/gi, '')
                                        });
                                    }}
                                />
                            </FormItem>
                            <ValidEmailInput
                                labelText="Support email"
                                labelId="Support-email"
                                inputProps={{ maxlength: 128 }}
                                id="Support-email-input"
                                fullWidth={true}
                                value={checkEmptyValue(canonicalResellerDetails.supportEmail)}
                                onChange={(e) => {
                                    setCanonicalResellerDetails({
                                        ...canonicalResellerDetails,
                                        supportEmail: e.target.value.toLowerCase()
                                    });
                                }}
                                autoComplete="off"
                            />
                            {/* <ValidURLInput
                                labelText="Css url"
                                labelId="Css-url"
                                id="Css-url-input"
                                fullWidth={true}
                                value={canonicalResellerDetails.cssUrl}
                                onChange={(e) => {
                                    setCanonicalResellerDetails({
                                        ...canonicalResellerDetails,
                                        cssUrl: e.target.value
                                    });
                                }}
                            /> */}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={styles.resellerInfoContainer}>
                <Grid className={styles.subTitle}>
                    <Typography component="span" color="primary" className={styles.formTitle}>
                        Order details
                    </Typography>
                </Grid>
                <Grid container xs={12} sm={12} className={styles.Container} justify="space-around">
                    <Grid item xs={10} sm={5}>
                        <div className={styles.formContainer}>
                            {/* <div>
                             <FormItem>
                                <Label  type="hidden" text="Order number" id="order-number"></Label>
                                <Input
                                    id="order-number-input"
                                    type="hidden"
                                    fullWidth={true}
                                    value={checkEmptyValue(orderDetails.orderNumber)}
                                    onChange={(e) => {
                                        setOrderDetails({
                                            ...orderDetails,
                                            orderNumber: e.target.value
                                        });
                                    }}
                                />
                                </FormItem>
                            </div> */}
                            <FormItem>
                                <Label text="Commencement date*" id="order-commencement-date"></Label>
                                <Input
                                    id="order-commencement-date-input"
                                    type="date"
                                    fullWidth={true}
                                    inputProps={{
                                        max: '9999-12-31',
                                        min: orderDetails.orderDate
                                    }}
                                    value={checkEmptyValue(orderDetails.commencementDate)}
                                    onChange={(e) => {
                                        setOrderDetails({
                                            ...orderDetails,
                                            commencementDate: e.target.value
                                        });
                                    }}
                                />
                            </FormItem>
                            <FormItem>
                                <Label text="Order date*" id="order-date"></Label>
                                <Input
                                    id="order-date-input"
                                    type="date"
                                    fullWidth={true}
                                    inputProps={{
                                        max: '9999-12-31'
                                    }}
                                    value={checkEmptyValue(orderDetails.orderDate)}
                                    onChange={(e) => {
                                        setOrderDetails({
                                            ...orderDetails,
                                            orderDate: e.target.value
                                        });
                                    }}
                                />
                            </FormItem>
                        </div>
                    </Grid>
                    <Grid item xs={10} sm={5} md={5}>
                        <div className={styles.formContainer}>
                            <FormItem>
                                <Label text="Initial term (Months)*" id="order-initial-term"></Label>
                                <SelectedDropDown
                                    id="initial_term"
                                    placeholder="Initial term"
                                    value={orderDetails.initialTerm}
                                    hideNoOptionsTextSelect={true}
                                    options={initialTermOptions}
                                    getOptionLabel={(option) => option}
                                    onChange={(event, newValue) => {
                                        setOrderDetails({
                                            ...orderDetails,
                                            initialTerm: newValue
                                        });
                                    }}
                                    customListRender={(option) => (
                                        <React.Fragment>
                                            <span>{option}</span>
                                        </React.Fragment>
                                    )}
                                />
                            </FormItem>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={styles.resellerInfoContainer}>
                <Grid className={styles.subTitle}>
                    <Typography component="span" color="primary" className={styles.formTitle}>
                        Reseller charges
                    </Typography>
                </Grid>
                <Grid container className={styles.Container} justify="space-around">
                    <Grid item xs={10} sm={5}>
                        <div className={styles.formContainer}>
                            <FormItem>
                                <Label text="Service charge*" id="service-charges"></Label>
                                <NumberFormat
                                    id="service-charges-input"
                                    customInput={OutlinedInput}
                                    inputProps={{ inputMode: 'numeric' }}
                                    pattern="\d*"
                                    thousandSeparator={true}
                                    allowLeadingZeros={true}
                                    allowNegative={false}
                                    isNumericString={true}
                                    decimalScale={2}
                                    value={checkEmptyValue(canonicalResellerCharges.serviceCharge)}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <span className={styles.action_items__input__balance}>
                                                {getCurrencySymbol('United Kingdom')}
                                            </span>
                                        </InputAdornment>
                                    }
                                    onValueChange={(values) => {
                                        const { value } = values;
                                        setCanonicalResellerCharges({
                                            ...canonicalResellerCharges,
                                            serviceCharge: value
                                        });
                                    }}
                                    isAllowed={maxAmount}
                                    aria-describedby="outlined-weight-helper-text"
                                    labelWidth={0}
                                    required
                                    className={styles.amountInput}
                                />
                            </FormItem>
                            <FormItem>
                                <Label text="Merchant setup charge*" id="merchant-setup-charge"></Label>
                                <NumberFormat
                                    id="merchant-setup-charge-input"
                                    customInput={OutlinedInput}
                                    inputProps={{ inputMode: 'numeric' }}
                                    pattern="\d*"
                                    thousandSeparator={true}
                                    allowLeadingZeros={true}
                                    allowNegative={false}
                                    isNumericString={true}
                                    decimalScale={2}
                                    value={checkEmptyValue(canonicalResellerCharges.merchantSetupCharge)}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <span className={styles.action_items__input__balance}>
                                                {getCurrencySymbol('United Kingdom')}
                                            </span>
                                        </InputAdornment>
                                    }
                                    onValueChange={(values) => {
                                        const { value } = values;
                                        setCanonicalResellerCharges({
                                            ...canonicalResellerCharges,
                                            merchantSetupCharge: value
                                        });
                                    }}
                                    isAllowed={maxAmount}
                                    aria-describedby="outlined-weight-helper-text"
                                    labelWidth={0}
                                    required
                                    className={styles.amountInput}
                                />
                            </FormItem>
                        </div>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                        <div className={styles.formContainer}>
                            <FormItem>
                                <Label text="Fixed charge*" id="fixed-charges"></Label>
                                <NumberFormat
                                    id="fixed-charges-input"
                                    customInput={OutlinedInput}
                                    inputProps={{ inputMode: 'numeric' }}
                                    pattern="\d*"
                                    thousandSeparator={true}
                                    allowLeadingZeros={true}
                                    allowNegative={false}
                                    isNumericString={true}
                                    decimalScale={2}
                                    value={checkEmptyValue(canonicalResellerCharges.fixedCharge)}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <span className={styles.action_items__input__balance}>
                                                {getCurrencySymbol('United Kingdom')}
                                            </span>
                                        </InputAdornment>
                                    }
                                    onValueChange={(values) => {
                                        const { value } = values;
                                        setCanonicalResellerCharges({
                                            ...canonicalResellerCharges,
                                            fixedCharge: value
                                        });
                                    }}
                                    isAllowed={maxAmount}
                                    aria-describedby="outlined-weight-helper-text"
                                    labelWidth={0}
                                    required
                                    className={styles.amountInput}
                                />
                            </FormItem>
                            <FormItem>
                                <Label text="Rate per transaction*" id="rate-per-transaction"></Label>
                                <NumberFormat
                                    customInput={OutlinedInput}
                                    id="rate-per-transaction-input"
                                    inputProps={{ inputMode: 'numeric' }}
                                    pattern="\d*"
                                    maxLength="2"
                                    thousandSeparator={false}
                                    allowLeadingZeros={true}
                                    allowNegative={false}
                                    isNumericString={true}
                                    decimalScale={2}
                                    value={checkEmptyValue(canonicalResellerCharges.ratePerTransaction)}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <span className={styles.action_items__input__balance}>
                                                {getCurrencySymbol('United Kingdom')}
                                            </span>
                                        </InputAdornment>
                                    }
                                    onValueChange={(values) => {
                                        const { value } = values;
                                        setCanonicalResellerCharges({
                                            ...canonicalResellerCharges,
                                            ratePerTransaction: value
                                        });
                                    }}
                                    isAllowed={maxAmount}
                                    aria-describedby="outlined-weight-helper-text"
                                    labelWidth={0}
                                    required
                                    className={styles.amountInput}
                                />
                            </FormItem>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <Grid className={styles.subTitle}>
                    <Typography component="span" color="primary" className={styles.formTitle}>
                        Terms & Conditions
                    </Typography>
                </Grid>
                <Grid className={styles.termsAndConditionsContainer} item xs={5} sm={5}>
                    <Grid
                        className={
                            disableInput
                                ? `${styles.selectTermsAndConditionInput} ${styles.disableInputClass}`
                                : `${styles.selectTermsAndConditionInput}`
                        }
                        container
                        onClick={() => document.getElementById('select-terms-and-condition-file').click()}
                    >
                        <DescriptionOutlinedIcon className={styles.pdfIcon} />
                        <Typography style={{ color: '#43425d' }}>Select file</Typography>
                    </Grid>
                    <Typography color="error" className={styles.allowedFormateStyle}>
                        Allowed format: PDF
                    </Typography>
                    {fileName && (
                        <div>
                            <Grid
                                item
                                container
                                justify="space-between"
                                alignItems="center"
                                className={styles.tableContainer}
                                xs={12}
                                md={12}
                            >
                                <Grid container item xs={10} ms={10} justify="space-between" alignItems="center">
                                    <Typography style={{ color: '#43425d' }}>{fileName}</Typography>
                                    <CancelOutlinedIcon style={{ fontSize: '17px' }} onClick={removeSelectedFile} />
                                </Grid>
                            </Grid>
                        </div>
                    )}
                    <input
                        type="file"
                        placeholder="select file"
                        id="select-terms-and-condition-file"
                        style={{ display: 'none' }}
                        onChange={(e) => selectFile(e.target.files[0])}
                        accept="application/pdf"
                    />
                </Grid>
            </Grid>
            <Grid container direction="column" alignItems="center" className={styles.activateReseller}>
                <Button
                    disabled={disabled || isLoading}
                    id="activate-reseller-btn"
                    color="primary"
                    variant="contained"
                    className={styles.buttonStyle}
                    onClick={submitOnboarding}
                >
                    Activate reseller
                </Button>
            </Grid>
            {message && (
                <SnackBarAndAlert open={showMessage} onClose={() => setShowMessage(false)} type={messageType}>
                    {message}
                </SnackBarAndAlert>
            )}
            <LoadingScreen open={isLoading} />
        </Paper>
    );
};

export default CanonicalResellerOnboardingComponent;
