import { AuthAxios } from './interceptor.service.js';
import { NotificationsServiceEndpoint } from '../utils/constants';
import { SuccesResponse, ErrorResponse } from 'models';

const getUrgentMessages = async (resellerId) => {
    try {
        const response = await AuthAxios.get(`${NotificationsServiceEndpoint}/admin/urgent-messages/${resellerId}`);
        return new SuccesResponse(response.data);
    } catch (error) {
        const message = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(message);
    }
};

const addUrgentMessage = async (resellerId, data) => {
    try {
        const response = await AuthAxios.post(`${NotificationsServiceEndpoint}/admin/urgent-messages/${resellerId}`, {
            data
        });
        return new SuccesResponse(response.data);
    } catch (error) {
        const message = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(message);
    }
};

const updateUrgentMessage = async (urgentMessageId, data) => {
    try {
        const response = await AuthAxios.put(
            `${NotificationsServiceEndpoint}/admin/urgent-messages/update/${urgentMessageId}`,
            {
                data
            }
        );
        return new SuccesResponse(response.data);
    } catch (error) {
        const message = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(message);
    }
};

const deleteUrgentMessage = async (urgentMessageId) => {
    try {
        const response = await AuthAxios.delete(
            `${NotificationsServiceEndpoint}/admin/urgent-messages/delete/${urgentMessageId}`
        );
        return new SuccesResponse(response.data);
    } catch (error) {
        const message = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(message);
    }
};

const requestForSupport = async (data) => {
    try {
        await AuthAxios.post(`${NotificationsServiceEndpoint}/request-for-support`, data);
        return new SuccesResponse();
    } catch (error) {
        const message = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(message);
    }
};

const notifyApplicationFailures = async (data) => {
    try {
        await AuthAxios.post(`${NotificationsServiceEndpoint}/notify-application-failures`, data);
        return new SuccesResponse();
    } catch (error) {
        const message = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(message);
    }
};

export default {
    getUrgentMessages,
    addUrgentMessage,
    updateUrgentMessage,
    deleteUrgentMessage,
    requestForSupport,
    notifyApplicationFailures
};
