import { MerchantCountries } from 'utils/enums/MerchantCountries';

export const BankAccountLengthConstaints = {
    [MerchantCountries.UNITED_STATES]: {
        minLength: 3,
        maxLength: 17
    },
    [MerchantCountries.IRELAND]: {
        minLength: 22,
        maxLength: 22
    },
    [MerchantCountries.NEW_ZEALAND]: {
        minLength: 15,
        maxLength: 16
    },
    [MerchantCountries.AUSTRALIA]: {
        minLength: 5,
        maxLength: 9
    },
    [MerchantCountries.MEXICO]: {
        minLength: 18,
        maxLength: 18
    },
    [MerchantCountries.CANADA]: {
        minLength: 7,
        maxLength: 12
    },
    [MerchantCountries.UNITED_KINGDOM]: {
        minLength: 8,
        maxLength: 8
    }
};
