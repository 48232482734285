import { AuthAxios, BaseAxios } from './interceptor.service.js';
import { DocumentServiceEndpoint, SettingsServiceEndpoint } from '../utils/constants';
import { SuccesResponse, ErrorResponse } from 'models';
import { DocumentStatus } from 'utils/enums/DocumentStatus';

const upload = async (entity, entityId, file, fileProgress) => {
    const response = await executeUploading(entity, entityId, file, fileProgress);

    const hasErrorOnUpload = response.status === DocumentStatus.ERROR;

    if (hasErrorOnUpload) {
        return getError('Uploading error');
    } else {
        return new SuccesResponse(response.data);
    }
};

const getError = (error) => {
    const message = error.response ? error.response.data : 'An error occurred';
    return new ErrorResponse(message);
};

const getTermsAndConditions = async (entity, entityId) => {
    try {
        console.log(entity, entityId);
        const response = await AuthAxios.get(
            `${DocumentServiceEndpoint}/terms-and-condition/get/${entity}/${entityId}`
        );
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

async function executeUploading(entity, entityId, file, fileProgress) {
    let response;
    try {
        response = await AuthAxios.post(
            `${DocumentServiceEndpoint}/terms-and-conditions/presigned-url-put-object/${entity}/${entityId}`,
            {
                filename: file.name,
                fileType: file.type
            }
        );

        await BaseAxios.put(response.data.presignedUrl, file, {
            headers: {
                'Content-Type': file.type
            },
            onUploadProgress: (progress) => {
                fileProgress(file.name, progress);
            }
        });
        const querySeparator = response.data.presignedUrl.lastIndexOf('?Content-Type');
        const link = response.data.presignedUrl.substring(0, querySeparator);
        const linkType = 'pdf';

        const saveResponse = await AuthAxios.post(
            `${DocumentServiceEndpoint}/terms-and-condition/save/${entity}/${entityId}/${response.data.documentId}`,
            {
                link: link,
                linkType: linkType
            }
        );

        return { data: saveResponse.data, documentId: response.data.documentId, status: DocumentStatus.SUCCESS };
    } catch (error) {
        if (response) {
            return { documentId: response.data.documentId, status: DocumentStatus.ERROR };
        } else {
            return { status: DocumentStatus.ERROR };
        }
    }
}

const getSignedTermsAndConditionsData = async (entity, entityId) => {
    try {
        const response = await AuthAxios.get(
            `${DocumentServiceEndpoint}/terms-and-condition/get-signed-terms-and-conditions/${entity}/${entityId}`
        );
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const acceptTermsAndConditions = async (entity, entityId, data) => {
    try {
        const response = await AuthAxios.post(
            `${SettingsServiceEndpoint}/accept-terms-and-conditions/${entity}/${entityId} `,
            data
        );
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

export default {
    upload,
    getTermsAndConditions,
    acceptTermsAndConditions,
    getSignedTermsAndConditionsData
};
