import React from 'react';
import PropTypes from 'prop-types';
import { NativeSelect, Grid } from '@material-ui/core';
import { FormItem, Label, Input } from '../../../onboarding/onboarding-ui-components';
import { useGlobalStateContext, useAdminFunctionsContext } from 'contexts';
import { UserType } from 'utils/enums/UserType';
import { SnackBarAndAlert, LoadingScreen, ModalFormConfirmation } from 'components';
import { MerchantAccountStatus } from 'utils/enums/MerchantAccountStatus';
import { MerchantStatus } from 'utils/enums/MerchantStatus';

export default function AutoWithdrawalComponent(props) {
    const { globalState } = useGlobalStateContext();
    const { adminFunctions } = useAdminFunctionsContext();

    const {
        onChange,
        message,
        setMessage,
        messageType,
        loading,
        openModal,
        setOpenModal,
        autoWithdrawStatus,
        autoWithdrawalStatusChangeHandler
    } = props;
    const isReseller = globalState.user?.UserType?.name === UserType.Reseller;
    const onOffStatus = autoWithdrawStatus ? 'on' : 'off';
    const isMerchantAccountClosed = globalState.merchant?.status === MerchantStatus.CLOSED;

    return (
        <div>
            <FormItem>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={5}>
                        <Label>Auto Withdrawals</Label>
                    </Grid>
                    <Grid item xs={5} md={4}>
                        <NativeSelect
                            disabled={
                                isReseller ||
                                adminFunctions.accountStatus === MerchantAccountStatus.WATCHLIST ||
                                isMerchantAccountClosed
                            }
                            input={
                                <Input
                                    id="auto-withdrawal-id"
                                    value={adminFunctions.autoWithdraw ? '1' : '0'}
                                    onChange={(e) => {
                                        autoWithdrawalStatusChangeHandler(e.target.value);
                                    }}
                                />
                            }
                        >
                            <option value="1">On</option>
                            <option value="0">Off</option>
                        </NativeSelect>
                    </Grid>
                </Grid>
            </FormItem>
            <ModalFormConfirmation
                id="update-auto-withdrawal"
                isModalOpen={openModal}
                setIsModalOpen={setOpenModal}
                confirmationAction={onChange}
                buttonText={'Continue'}
                modalText={`Are you sure you want to turn ${onOffStatus} Automatic withdrawals?`}
            />
            {message && (
                <SnackBarAndAlert open={message} type={messageType} onClose={() => setMessage('')}>
                    {message}
                </SnackBarAndAlert>
            )}
            <LoadingScreen open={loading} />
        </div>
    );
}

AutoWithdrawalComponent.propTypes = {
    onChange: PropTypes.func
};
