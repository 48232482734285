import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useGlobalStateContext } from 'contexts';
import { AuthService } from 'services';
import { UNDERMAINTAINANCE } from 'utils/constants';

export default function LoggedRoute({ ...rest }) {
    const { getRoute, globalState } = useGlobalStateContext();

    if (UNDERMAINTAINANCE && globalState.customDomain) {
        return <Redirect to={getRoute(`/maintenance`)} />;
    }

    if (!AuthService.isLoggedUser()) {
        return <Redirect to={getRoute(`/login`)} />;
    }

    return <Route {...rest}></Route>;
}
