import React, { useState, useEffect } from 'react';
import AddCredit from './add-credit.component';
import { AdminService } from 'services';
import { roundOfInteger } from 'utils/helper';

export default function AddCreditFunc(props) {
    const { updateFeeTier } = props;
    const [disabled, setDisabled] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [feesAmount, setFeesAmount] = useState('');
    const [netAmount, setNetAmount] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [refundReason, setRefundReason] = useState('');
    const [address, setAddress] = useState('');
    const [isCredited, setIsCredited] = useState(false);
    const [grossAmount, setGrossAmount] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);

    const handleOnClose = () => {
        setOpen(false);
        setFeesAmount('');
        setGrossAmount('');
        setFirstName('');
        setLastName('');
        setRefundReason('');
        setAddress('');
    };

    const percentage_fee = Number(updateFeeTier.percentage_fee) / 100;
    const fixed_fee = Number(updateFeeTier.fixed_fee);
    const onChange = (netAmount) => {
        const { value } = netAmount;
        setNetAmount(value);
        let gross = roundOfInteger((Number(value) + fixed_fee) / (1 - percentage_fee));
        let fees = roundOfInteger(gross * percentage_fee + fixed_fee);
        setFeesAmount(parseFloat(value) > 0 ? fees.toFixed(2) : '');
        setGrossAmount(parseFloat(value) > 0 ? parseFloat(gross).toFixed(2) : '');
    };

    useEffect(() => {
        const isDisable = !netAmount || !parseFloat(netAmount) > 0 || !refundReason;

        setDisabled(isDisable);
    }, [netAmount, refundReason]);

    const onSubmit = async () => {
        let data = {
            credit_amount: parseFloat(netAmount),
            customer_name: firstName + ' ' + lastName,
            address: address,
            credit_reason: refundReason
        };
        setIsLoading(true);

        const result = await AdminService.AddCredit(data);

        if (result.isSuccesfully) {
            setIsCredited(true);
            setSuccessMessage('Amount has been credited successfully.');
        } else {
            setIsCredited(true);
            setSuccessMessage(false);
        }

        handleOnClose();
        setIsLoading(false);
    };
    const isMaxAmount = (values) => {
        const { value, floatValue } = values;
        const MAX_AMT = 2500.0;

        if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
            return true;
        }
        if (value.charAt(0) === '0') {
            if (value.charAt(1) && value.charAt(1) !== '.') {
                return false;
            }
        }
        if (value > MAX_AMT) {
            return false;
        }
        return true;
    };
    return (
        <AddCredit
            disabled={disabled}
            setDisabled={setDisabled}
            grossAmount={grossAmount}
            feesAmount={feesAmount}
            open={open}
            setOpen={setOpen}
            handleOnClose={handleOnClose}
            isLoading={isLoading}
            onSubmit={onSubmit}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            refundReason={refundReason}
            setRefundReason={setRefundReason}
            address={address}
            setAddress={setAddress}
            setNetAmount={setNetAmount}
            isCredited={isCredited}
            onChange={onChange}
            successMessage={successMessage}
            setIsCredited={setIsCredited}
            isMaxAmount={isMaxAmount}
        />
    );
}
