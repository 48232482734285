import React from 'react';
import { PendingCustomerInfo, AdminDashboardTopBar, AdminDashBoardNavigationHeader, AdminHomeMenu } from 'components';
import { Grid } from '@material-ui/core';
import { Dashboard } from '@material-ui/icons';
import style from './pending-customers.module.scss';
export default function PendingCustomersComponent() {
    return (
        <div className={style.backgroundColor}>
            <Grid container className={style.container}>
                <Grid item xs={3}>
                    <Grid item xs={12} className={style.adminDashboardMenu}>
                        <AdminHomeMenu></AdminHomeMenu>
                    </Grid>
                </Grid>
                <Grid className={style.dashboardContentContainer} item xs={9}>
                    <Grid item xs={11}>
                        <AdminDashBoardNavigationHeader header_title="Dashboard">
                            <Dashboard />
                        </AdminDashBoardNavigationHeader>
                    </Grid>
                    <Grid item xs={11}>
                        <AdminDashboardTopBar />
                    </Grid>
                    {/* <Grid item xs={11}>
                        <AdminDashboardHome />
                    </Grid> */}
                    <br></br>

                    <Grid item xs={11}>
                        <PendingCustomerInfo />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
