import React, { useState, useEffect } from 'react';
import UploadTermsAndConditionsComponent from './upload-terms-and-conditions.component';
import { TaxonomyService, TermsAndConditionsService, CanonicalResellerService } from 'services';
import { ResellerNameAndId } from 'utils/enums/ResellerType';
import { useGlobalStateContext } from 'contexts';
import { ImposedByName } from 'utils/enums/TermsAndConditions';
import { ImposedBy } from 'utils/enums/TermsAndConditions';
import { AuthService } from 'services';

const UploadTermsAnsConditions = () => {
    const { globalState } = useGlobalStateContext();
    const [message, setMessage] = useState('');
    const [displayList, setDisplayList] = useState([]);
    const [termsAndConditionsList, setTermsAndConditionsList] = useState([]);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [selectedEntityValue, setSelectedEntityValue] = useState('');
    const [disableInput, setDisableInput] = useState(false);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [entityId, setEntityId] = useState('');
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploadingFailed, setUploadingFailed] = useState(false);
    const [fileExistError, setFileExistError] = useState(false);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('activatedAt');
    const [disableUpload, setDisableUpload] = useState(false);
    const [disableEntityInput, setDisableEntityInput] = useState(true);
    const [imposedBy, setImposedBy] = useState('');
    const [entity, setEntity] = useState('');
    const { setRoute } = useGlobalStateContext();
    const userIsLoggedIn = AuthService.isLoggedUser();

    useEffect(() => {
        if (!userIsLoggedIn) {
            setRoute('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userIsLoggedIn]);

    const getCanonicalResellerList = async () => {
        setDisableEntityInput(true);
        setIsLoading(true);
        const response = await CanonicalResellerService.getAllCanonicalResellers(globalState.reseller?.id, {
            searchValue: ''
        });

        if (response.isSuccesfully) {
            setDisplayList(response.data.canonicalResellers);
            selectEntityValue(response.data.canonicalResellers[0]);
            setCount(response.data.count);
        } else {
            setDisplayList([]);
            setCount(0);
        }

        setIsLoading(false);
        setDisableEntityInput(false);
    };

    const getAllAcquirer = async () => {
        setDisableEntityInput(true);
        setIsLoading(true);
        const response = await TaxonomyService.getAllAcquirer();

        if (response.isSuccesfully) {
            setDisplayList(response.data);
            selectEntityValue(response.data[0]);
            setCount(response.data.length);
        } else {
            setDisplayList([]);
            setCount(0);
        }

        setIsLoading(false);
        setDisableEntityInput(false);
    };

    const getResellers = async () => {
        setDisableEntityInput(true);
        setIsLoading(true);
        const response = await TaxonomyService.getResellers();

        if (response.isSuccesfully) {
            const resellers = response.data;
            resellers.pop();
            setDisplayList(resellers);
            selectEntityValue(resellers[0]);
            setCount(resellers.length);
        } else {
            setDisplayList([]);
            setCount(0);
        }

        setIsLoading(false);
        setDisableEntityInput(false);
    };

    useEffect(() => {
        selectEntity(ImposedBy[0].creator);
        selectEntityValue(ResellerNameAndId[0]);
        // eslint-disable-next-line
    }, []);

    const selectEntity = (imposedBy) => {
        setEntity(imposedBy);
        setImposedBy(imposedBy);
        setEntityId('');
        setFile(null);
        setFileName('');
        setProgress(0);
        setTermsAndConditionsList([]);
        let fileUploadInput = document.getElementById('select-terms-and-condition-file');
        if (fileUploadInput) {
            fileUploadInput.value = '';
        }

        if (imposedBy === ImposedByName.RESELLER) {
            getResellers();
        } else if (imposedBy === ImposedByName.CANONICAL_RESELLER) {
            getCanonicalResellerList();
        } else if (imposedBy === ImposedByName.ACQUIRER) {
            getAllAcquirer();
        }

        // eslint-disable-next-line
    };

    const isFileUnique = (newFile) => {
        setFileExistError(false);
        let isUnique = true;
        const nonUnique = termsAndConditionsList.find((existingItem) => existingItem.fileName === newFile.name);
        if (nonUnique) {
            isUnique = false;
            setFileExistError(true);
        }
        return isUnique;
    };

    const addFileName = (allTermsAndConditions) => {
        const updatedList = allTermsAndConditions.map((termsAndCondition) => {
            const termsAndConditionUrl = termsAndCondition.link;
            const urlComponent = termsAndConditionUrl.split('/');
            const fileName = decodeURIComponent(urlComponent[urlComponent.length - 1]);
            return {
                ...termsAndCondition,
                fileName: fileName
            };
        });
        return updatedList;
    };

    useEffect(() => {
        const getAllTermsAndConditions = async () => {
            setIsLoading(true);
            const response = await TermsAndConditionsService.getTermsAndConditions(entity, entityId);

            if (response.isSuccesfully) {
                const allTermsAndConditions = response.data;
                const updatedList = addFileName(allTermsAndConditions);
                setTermsAndConditionsList(updatedList);
            }
            setIsLoading(false);
        };

        if (entityId) {
            getAllTermsAndConditions();
        }

        // eslint-disable-next-line
    }, [entityId]);

    const fileProgress = (filename, progress) => {
        setIsLoading(false);
        const { loaded, total } = progress;
        const percentageProgress = Math.floor((loaded / total) * 100);

        setFileName(filename);
        setProgress(percentageProgress);
    };

    const selectFile = (newFile) => {
        if (newFile) {
            setFile(newFile);
            setFileName(newFile.name);
            setDisableInput(true);
            setDisableUpload(false);
        }
    };

    const selectEntityValue = (value) => {
        setSelectedEntityValue(value);
        setEntityId(value?.id);
        setFile(null);
        setFileName('');
        let fileUploadInput = document.getElementById('select-terms-and-condition-file');
        if (fileUploadInput) {
            fileUploadInput.value = '';
        }
        setProgress(0);
        if (!value || !value?.id) {
            setDisableInput(true);
        } else {
            setDisableInput(false);
        }
    };

    const uploadFile = async () => {
        setDisableUpload(true);
        const isUnique = isFileUnique(file);

        if (isUnique) {
            setIsLoading(true);
            const uploadedFileResponse = await TermsAndConditionsService.upload(entity, entityId, file, fileProgress);
            if (uploadedFileResponse.isSuccesfully) {
                const allTermsAndConditions = uploadedFileResponse.data;
                const updatedList = addFileName(allTermsAndConditions);
                setTermsAndConditionsList(updatedList);
                setUploadSuccess(true);
                removeSelectedFile();
            } else {
                setUploadingFailed(true);
            }
            setIsLoading(false);
            setDisableUpload(false);
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const removeSelectedFile = () => {
        let fileUploadInput = document.getElementById('select-terms-and-condition-file');
        if (fileUploadInput) {
            fileUploadInput.value = '';
        }
        setFile(null);
        setFileName('');
        setDisableInput(false);
    };

    const headCells = [
        { id: 'file_url', numeric: false, disablePadding: false, label: 'File URL', centerAlignment: true },
        { id: 'status', numeric: false, disablePadding: false, label: 'Status', centerAlignment: true },
        {
            id: 'activatedAt',
            numeric: false,
            disablePadding: false,
            label: 'Activation Date',
            centerAlignment: true
        },
        {
            id: 'deactivatedAt',
            numeric: false,
            disablePadding: false,
            label: 'Deactivation Date',
            centerAlignment: true
        }
    ];

    return (
        <UploadTermsAndConditionsComponent
            displayList={displayList}
            selectedEntityValue={selectedEntityValue}
            setSelectedEntityValue={setSelectedEntityValue}
            message={message}
            setMessage={setMessage}
            headCells={headCells}
            count={count}
            isLoading={isLoading}
            progress={progress}
            setProgress={setProgress}
            selectFile={selectFile}
            setEntityId={setEntityId}
            uploadFile={uploadFile}
            fileName={fileName}
            setDisableInput={setDisableInput}
            disableInput={disableInput}
            file={file}
            selectEntityValue={selectEntityValue}
            termsAndConditionsList={termsAndConditionsList}
            order={order}
            orderBy={orderBy}
            handleRequestSort={handleRequestSort}
            uploadSuccess={uploadSuccess}
            uploadingFailed={uploadingFailed}
            setUploadingFailed={setUploadingFailed}
            fileExistError={fileExistError}
            setFileExistError={setFileExistError}
            removeSelectedFile={removeSelectedFile}
            setUploadSuccess={setUploadSuccess}
            disableUpload={disableUpload}
            imposedBy={imposedBy}
            setImposedBy={setImposedBy}
            selectEntity={selectEntity}
            disableEntityInput={disableEntityInput}
        />
    );
};

export default UploadTermsAnsConditions;
