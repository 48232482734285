import React from 'react';
import PropTypes from 'prop-types';
import {
    Paper,
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TablePagination,
    TableFooter,
    useMediaQuery,
    Button
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import TransactionStatusLabel from '../transaction-status-label/transaction-status-label.container';
import TableFooterComponent from '../table-footer/table-footer.component';
import TableHeader from '../table-header/table-header.container';
import { UserType } from 'utils/enums/UserType';

import {
    formatCurrency,
    formatDateTimeByFormatString,
    getComparator,
    stableSort,
    formatAmount
} from '../../../../utils/helper';
import styles from './merchant-charges-list-table.module.scss';
import { getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry';
import { useGlobalStateContext } from 'contexts';
import DeleteIcon from '@material-ui/icons/Delete';
import { SnackBarAndAlert, ModalFormConfirmation, MerchantChargeExport, ModalMerchantCharge } from 'components';
import { ResellerLogoMap } from 'utils/enums/ResellerLogoMap';
import paidImage from '../../../../../src/assets/images/paidImage.png';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        'border-top': '1px solid #ddd',
        'margin-top': '10px'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        boxShadow: 'none'
    },
    table: {
        minWidth: '100%'
    },
    tableRow: {
        '&$selected, &$selected:hover': {
            backgroundColor: '#e4e1fe'
        }
    },
    selected: {},
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    checkbox: {
        color: '#8783b5'
    }
}));

export default function MerchantChargesListTableComponent(props) {
    const { globalState } = useGlobalStateContext();
    const classes = useStyles();
    const {
        rows,
        order,
        orderBy,
        loading,
        error,
        totalMerchantCharges,
        page,
        setPage,
        payMerchantChargesBtn,
        merchantChargeResponse,
        showSnackbarStatus,
        setShowSnackbarStatus,
        snackbarMessage,
        deleteMerchantChargesBtn,
        openMerchantDeleteModal,
        setOpenMerchantDeleteModal,
        handleMerchantDeleteModal,
        rowId,
        rowAmount,
        openMerchantPayModal,
        setOpenMerchantPayModal,
        handleMerchantPayModal,
        companyAddress,
        merchantTradingAddress,
        rowsPerPage,
        setRowsPerPage,
        getMerchantChargesList,
        count,
        columnStyles,
        openMerchantModal,
        setOpenMerchantModal,
        setMerchantChargeResponse,
        alertCloseMessage,
        setSnackbarMessage,
        formatDescription
    } = props;
    const isSuperAdmin = globalState.user?.UserType?.name === UserType.SuperAdmin;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getMerchantChargesList(newPage);
    };
    const alertMessage = () => {
        setShowSnackbarStatus(false);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        getMerchantChargesList(0, parseInt(event.target.value, 10));
    };

    const mediaLessThan600px = useMediaQuery('(max-width:599px)');

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                {showSnackbarStatus && merchantChargeResponse && (
                    <>
                        <SnackBarAndAlert open={showSnackbarStatus} type={'success'} onClose={alertMessage}>
                            {snackbarMessage}
                        </SnackBarAndAlert>
                    </>
                )}
                {showSnackbarStatus && !merchantChargeResponse && (
                    <SnackBarAndAlert open={showSnackbarStatus} type={'error'} onClose={alertMessage}>
                        {snackbarMessage}
                    </SnackBarAndAlert>
                )}
                {loading ? (
                    <Box textAlign="center" py={5}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <div className={styles.paymentsNone}>An error occurred, please try reloading your browser.</div>
                ) : (
                    <React.Fragment>
                        {!rows || rows.length === 0 ? (
                            <div className={styles.paymentsNone}>There aren't any results for that query.</div>
                        ) : (
                            <React.Fragment>
                                <TableContainer>
                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size="small"
                                        stickyHeader
                                        aria-label="enhanced table"
                                    >
                                        <TableHeader classes={classes} {...props} />
                                        <TableBody>
                                            {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                let paidStatus = row.paid_status ? 'Paid' : 'Unpaid';
                                                let currenySymbol = getCurrencyThreeAlpha(
                                                    globalState?.merchant?.country
                                                );
                                                let csvData = [];
                                                let reportData = {
                                                    'Invoice Ref': row.id,
                                                    'Service provided': row.service_description
                                                        ? row.service_description
                                                        : '--',
                                                    Date: formatDateTimeByFormatString(row.date_sent, 'DD MMM YYYY'),
                                                    Status: paidStatus,
                                                    Amount: `${currenySymbol.toString()} ${formatAmount(row.amount)}`,
                                                    Total: `${currenySymbol.toString()} ${formatAmount(row.amount)}`
                                                };
                                                csvData.push(reportData);

                                                return (
                                                    <React.Fragment>
                                                        <TableRow
                                                            classes={{ selected: classes.selected }}
                                                            className={classes.tableRow + ' ' + styles.tableRow}
                                                            hover
                                                            tabIndex={-1}
                                                            key={row.id}
                                                        >
                                                            <TableCell
                                                                align="left"
                                                                component="th"
                                                                id={labelId}
                                                                scope="row"
                                                            >
                                                                {formatCurrency(
                                                                    row.amount,
                                                                    getCurrencyThreeAlpha(globalState.merchant?.country)
                                                                )}
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                size="medium"
                                                                className={styles.dateColumn}
                                                            >
                                                                {formatDateTimeByFormatString(
                                                                    row.date_sent,
                                                                    'DD MMM YY'
                                                                )}
                                                            </TableCell>
                                                            {!mediaLessThan600px && (
                                                                <TableCell align="left">
                                                                    {formatDescription(row.service_description) || '-'}
                                                                </TableCell>
                                                            )}
                                                            <TableCell align="left">
                                                                {row.paid_status ? (
                                                                    <TransactionStatusLabel status="PAID" />
                                                                ) : isSuperAdmin ? (
                                                                    <Button
                                                                        variant="contained"
                                                                        onClick={() =>
                                                                            handleMerchantPayModal(row.id, row.amount)
                                                                        }
                                                                        color="primary"
                                                                        size="small"
                                                                    >
                                                                        Pay Now
                                                                    </Button>
                                                                ) : (
                                                                    <TransactionStatusLabel status="Pending" />
                                                                )}
                                                            </TableCell>
                                                            {isSuperAdmin && (
                                                                <TableCell align="left">
                                                                    <DeleteIcon
                                                                        className={styles.deleteIcon}
                                                                        onClick={() =>
                                                                            handleMerchantDeleteModal(
                                                                                row.id,
                                                                                row.amount
                                                                            )
                                                                        }
                                                                    />
                                                                </TableCell>
                                                            )}

                                                            <TableCell>
                                                                <MerchantChargeExport
                                                                    merchant={globalState?.merchant}
                                                                    logo={ResellerLogoMap[globalState.reseller?.id]}
                                                                    pdfTitle={'INVOICE'}
                                                                    address={merchantTradingAddress}
                                                                    companyAddress={companyAddress}
                                                                    csvData={csvData}
                                                                    paidImage={paidImage}
                                                                    isPaid={row.paid_status === 0 ? false : true}
                                                                    transactionId={row.id}
                                                                    columnStyles={columnStyles}
                                                                />
                                                            </TableCell>
                                                        </TableRow>

                                                        {mediaLessThan600px && row.service_description && (
                                                            <TableRow>
                                                                <TableCell colSpan={3} align="left">
                                                                    {row.service_description}
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })}
                                            <TableRow>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        </TableBody>
                                        {count > 20 && (
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        rowsPerPageOptions={[20, 50, 100]}
                                                        colSpan={6}
                                                        count={count}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        SelectProps={{
                                                            inputProps: { 'aria-label': 'rows per page' },
                                                            native: true
                                                        }}
                                                        onChangePage={handleChangePage}
                                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        )}
                                    </Table>
                                </TableContainer>

                                <TableFooterComponent total={totalMerchantCharges()} resultsCount={count} />
                            </React.Fragment>
                        )}
                        {openMerchantModal && (
                            <ModalMerchantCharge
                                setOpenMerchantModal={setOpenMerchantModal}
                                openMerchantModal={openMerchantModal}
                                snackbarMessage={snackbarMessage}
                                setSnackbarMessage={setSnackbarMessage}
                                showSnackbarStatus={showSnackbarStatus}
                                setShowSnackbarStatus={setShowSnackbarStatus}
                                merchantChargeResponse={merchantChargeResponse}
                                setMerchantChargeResponse={setMerchantChargeResponse}
                                getMerchantChargesList={getMerchantChargesList}
                            />
                        )}
                        {openMerchantPayModal && (
                            <ModalFormConfirmation
                                id="submit-confirmation"
                                isModalOpen={openMerchantPayModal}
                                setIsModalOpen={setOpenMerchantPayModal}
                                confirmationAction={() => payMerchantChargesBtn(rowId, rowAmount)}
                                buttonText={'Confirm'}
                                modalText={`Pay for invoice number ${rowId} and ${formatCurrency(
                                    rowAmount,
                                    getCurrencyThreeAlpha(globalState.merchant?.country)
                                )} ?`}
                            />
                        )}
                        {openMerchantDeleteModal && (
                            <ModalFormConfirmation
                                id="submit-confirmation"
                                isModalOpen={openMerchantDeleteModal}
                                setIsModalOpen={setOpenMerchantDeleteModal}
                                confirmationAction={() => deleteMerchantChargesBtn(rowId, rowAmount)}
                                buttonText={'Confirm'}
                                modalText={`Delete invoice number ${rowId} and ${formatCurrency(
                                    rowAmount,
                                    getCurrencyThreeAlpha(globalState.merchant?.country)
                                )} ?`}
                            />
                        )}
                        {showSnackbarStatus && merchantChargeResponse && (
                            <SnackBarAndAlert open={showSnackbarStatus} type={'success'} onClose={alertCloseMessage}>
                                {snackbarMessage}
                            </SnackBarAndAlert>
                        )}
                        {showSnackbarStatus && !merchantChargeResponse && (
                            <SnackBarAndAlert open={showSnackbarStatus} type={'error'} onClose={alertCloseMessage}>
                                {snackbarMessage}
                            </SnackBarAndAlert>
                        )}
                    </React.Fragment>
                )}
            </Paper>
        </div>
    );
}

MerchantChargesListTableComponent.propTypes = {
    rows: PropTypes.array.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    totalMerchantCharges: PropTypes.func
};
