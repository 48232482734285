import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { PaymentAction } from 'components';
import { PaymentType } from '../../utils/enums/PaymentType';
import styles from './withdrawal.module.scss';
import { CheckMerchantCountries } from '../../components/onboarding/onboarding-ui-components/check-merchant-countries/check-merchant-countries';
import { MerchantCountries } from '../../utils/enums/MerchantCountries';

function WithdrawalComponent(props) {
    const { show, setShow } = props;

    return (
        <React.Fragment>
            <Grid container className={styles.container}>
                <CheckMerchantCountries
                    includedCountries={[MerchantCountries.UNITED_KINGDOM, MerchantCountries.IRELAND]}
                    hideIfStripe={true}
                >
                    <PaymentAction
                        show={show}
                        setShow={setShow}
                        btnText="Withdraw"
                        paymentType={PaymentType.Withdrawal}
                    />
                </CheckMerchantCountries>
            </Grid>
        </React.Fragment>
    );
}

WithdrawalComponent.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func
};

export default WithdrawalComponent;
