import React, { useState, useEffect, useRef, useCallback } from 'react';
import T2SOnboardingOwnerDetailsComponent from './t2s-onboarding-owner-details.component';
import { useOnboardingFormContext, useGlobalStateContext } from 'contexts';
import { NonResellerMerchantService, AuthService } from 'services';
import moment from 'moment';
import _ from 'lodash';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { InputValidators, getInvalidDobFields, validateSocialSecurity } from 'validators';
import { CountryCodeOfPhoneNumber } from 'utils/enums/CountryCodeToName';
import { UserType } from 'utils/enums/UserType';
import { debounce } from 'lodash';
import { formTypes } from 'utils/enums/FormTypes';
import { isTokenGoingToExpire } from 'utils/helper';
import { months, monthEnum, reverseMonthEnum } from 'utils/onboarding-helper';
import { isvalidAbn, validateEIN } from 'validators/onboarding/onboarding-check-value.validator';

function T2SOnboardingOwnerDetails(props) {
    const { globalState } = useGlobalStateContext();

    const [showUrlValidationMessage, setShowUrlValidationMessage] = useState(false);
    const { onboardingForm, setOnboardingForm } = useOnboardingFormContext();
    const [isDisabled, setIsDisabled] = useState(true);
    const [dobValidationFailed, setDobValidationFailed] = useState(false);
    const [validationMap, setValidationMap] = useState({});
    const [dobInvalidFields, setDobInvalidFields] = useState([]);
    const [firstName, setFirstName] = useState(
        onboardingForm?.ownersDetails?.fullName?.split(' ').slice(0, -1).join(' ')
    );
    const [lastName, setLastName] = useState(onboardingForm?.ownersDetails?.fullName?.split(' ').slice(-1).join(' '));

    const oneHundredValues = [...Array(100).keys()].map((item) => item + 1);
    const monthInputElementRef = useRef(null);
    const yearInputElementRef = useRef(null);
    const emailInputElementRef = useRef(null);
    const isMexicanmerchant = globalState.merchant?.country === MerchantCountries.MEXICO;
    const isAdyenMerchant = globalState?.merchant.isAdyenMerchant;
    const isAdmin =
        globalState.user?.UserType?.name === UserType.Admin || globalState.user?.UserType?.name === UserType.SuperAdmin;

    let country;
    if (!isMexicanmerchant) {
        country = onboardingForm.ownerPrimaryAddress.country;
    } else {
        country = MerchantCountries.MEXICO;
    }
    const [dialCode, setDialCode] = useState('');
    const [csrfToken, setCSRFToken] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [isABNValid, setIsABNValid] = useState(false);
    const [abnErrors, setAbnErrors] = useState('');
    const [fistTime, setFirstTime] = useState(true);
    const [isEINValid, setIsEINValid] = useState(true);
    const [EinErrors, setEinErrors] = useState('');
    const [EinNumberFirstTime, setEinNumberFirstTime] = useState(true);
    const [maskedSSNValue, setMaskedSSNValue] = useState('');
    const [ssnValue, setSsnValue] = useState('');
    const isUSAMerchant = globalState.merchant?.country === MerchantCountries.UNITED_STATES;
    const isAUSMerchant = globalState.merchant?.country === MerchantCountries.AUSTRALIA;

    // State for selecting onboarding month number on the basis of dropdown
    const onboardingMonth = monthEnum[Number(onboardingForm?.ownersDetails?.dateOfBirth?.month) || 0];
    const [selectedMonth, setSelectedMonth] = useState(onboardingMonth);
    //Use effect to re-render the component in order to make the selected dropdown value visible
    useEffect(() => {
        setSelectedMonth(onboardingMonth);
    }, [onboardingMonth]);

    const generateCSRFToken = async () => {
        setIsLoading(true);
        const response = await AuthService.generateCSRFToken(
            formTypes.ONBOARDING_OWNER_DETAILS,
            globalState.merchant?.id
        );
        if (response.isSuccesfully) {
            setCSRFToken(response.data.csrfToken);
        }
        setIsLoading(false);
        return response?.data?.csrfToken;
    };

    useEffect(() => {
        generateCSRFToken();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let {
            fullName,
            email,
            contactTel,
            businessTitle,
            personalId,
            nationality,
            dateOfBirth,
            ownership,
            tinNumber
        } = onboardingForm.ownersDetails;

        const { postCode, addressLine1, state, city } = onboardingForm.ownerPrimaryAddress;
        const dateOfBirthMoment =
            dateOfBirth && moment([dateOfBirth.day, dateOfBirth.month, dateOfBirth.year].join('-'), 'DD-MM-YYYY');
        const isDateOfBirthProper =
            dateOfBirth &&
            dateOfBirthMoment.isValid() &&
            +dateOfBirth.day >= 0 &&
            +dateOfBirth.month >= 0 &&
            +dateOfBirth.year >= 1900 &&
            dateOfBirthMoment.valueOf() <= moment().valueOf();

        const personalIdCheck = globalState.merchant.country === MerchantCountries.MEXICO ? personalId : true;
        const tinValidationMessage =
            globalState.merchant.country === MerchantCountries.UNITED_STATES
                ? validateEIN(tinNumber)
                : globalState.merchant.country === MerchantCountries.AUSTRALIA
                ? isvalidAbn(tinNumber)
                : '';

        const einCheck =
            globalState.merchant.country === MerchantCountries.UNITED_STATES ? tinValidationMessage === '' : true;
        const abnCheck =
            globalState.merchant.country === MerchantCountries.AUSTRALIA ? tinValidationMessage === '' : true;

        fullName = `${firstName.trim()}  ${lastName.trim()}`;

        let nameCheck = true;
        if (!firstName || !lastName) {
            nameCheck = false;
        }

        const dobFieldsWithIssues = getInvalidDobFields(dateOfBirth);
        setDobInvalidFields(dobFieldsWithIssues);
        setValidationMap({ ...validationMap, nationality: nationality === '--Please select--' ? 'FAILED' : 'PASSED' });

        const shouldEnableButton = () => {
            const tinNumberExists = onboardingForm?.ownersDetails?.tinNumber;
            const ssnExists = Boolean(ssnValue);
            // United States-specific logic
            if (globalState.merchant.country === MerchantCountries.UNITED_STATES) {
                const isSSNValid = ssnExists && socialSecurityValidator; // Validates SSN if provided
                const isEINValid = tinNumberExists && einCheck; // Validates TIN if provided
                // If both SSN and TIN are provided, both must be valid
                if (ssnExists && tinNumberExists) {
                    return isSSNValid && isEINValid;
                }
                // If only SSN is provided, it must be valid
                if (ssnExists) {
                    return isSSNValid;
                }
                // If only TIN is provided, it must be valid
                if (tinNumberExists) {
                    return isEINValid;
                }
                // If neither SSN nor TIN is provided, disable the button
                return true;
            }
            // Australia-specific logic
            if (globalState.merchant.country === MerchantCountries.AUSTRALIA) {
                if (!tinNumberExists) {
                    // If TIN doesn't exist, disable the button
                    return true;
                }
                // If TIN exists, validate ABN
                return abnCheck;
            }
            // Default case for other countries or unhandled scenarios
            return true;
        };

        let checkValues =
            fullName &&
            nameCheck &&
            InputValidators.isEmailValid(email) &&
            contactTel &&
            businessTitle &&
            nationality !== '--Please select--' &&
            isDateOfBirthProper &&
            ownership &&
            _.isEmpty(dobFieldsWithIssues) &&
            postCode &&
            personalIdCheck &&
            addressLine1 &&
            city &&
            country &&
            shouldEnableButton();

        if (isAUSMerchant || isUSAMerchant) {
            checkValues = checkValues && state;
        }

        setDobValidationFailed(!isDateOfBirthProper);
        setIsDisabled(!checkValues);

        // eslint-disable-next-line
    }, [
        onboardingForm.ownersDetails,
        globalState.merchant.country,
        onboardingForm.ownerPrimaryAddress,
        firstName,
        lastName,
        ssnValue
    ]);

    useEffect(() => {
        setOnboardingForm((onboardingForm) => ({
            ...onboardingForm,
            completedSteps: {
                ...onboardingForm.completedSteps,
                ownerDetails: !isDisabled ? true : false
            }
        }));
    }, [isDisabled, setOnboardingForm]);

    useEffect(() => {
        const { businessDetails, ownerPrimaryAddress, tradingAddress, ownersDetails } = onboardingForm;
        const isSameAsTradingAddress =
            ownerPrimaryAddress.postCode === tradingAddress.postCode &&
            ownerPrimaryAddress.addressLine1 === tradingAddress.addressLine1 &&
            ownerPrimaryAddress.addressLine2 === tradingAddress.addressLine2 &&
            ownerPrimaryAddress.city === tradingAddress.city &&
            ownerPrimaryAddress.state === tradingAddress.state &&
            ownerPrimaryAddress.country === tradingAddress.country;
        const isSameAsBusinessPhoneNumber = ownersDetails.contactTel === businessDetails.phoneNumber;
        const isSameAsBusinessEmail = ownersDetails.email === businessDetails.email;

        if (onboardingForm.ownerPrimaryAddress.isSameAsTradingAddress !== isSameAsTradingAddress) {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                ownerPrimaryAddress: {
                    ...onboardingForm.ownerPrimaryAddress,
                    isSameAsTradingAddress: isSameAsTradingAddress
                }
            }));
        }

        if (onboardingForm.ownersDetails.isSameAsBusinessEmail !== isSameAsBusinessEmail) {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                ownersDetails: {
                    ...onboardingForm.ownersDetails,
                    isSameAsBusinessEmail: isSameAsBusinessEmail
                }
            }));
        }
        if (onboardingForm.ownersDetails.isSameAsBusinessPhoneNumber !== isSameAsBusinessPhoneNumber) {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                ownersDetails: {
                    ...onboardingForm.ownersDetails,
                    isSameAsBusinessPhoneNumber: isSameAsBusinessPhoneNumber
                }
            }));
        }
    }, [onboardingForm, setOnboardingForm]);
    const removeHyphens = (input) => {
        return input?.replace(/-/g, '');
    };

    const onSubmit = async () => {
        const isCSRFGoingToExpire = isTokenGoingToExpire(csrfToken);
        let latestCSRFToken = csrfToken;
        if (isCSRFGoingToExpire) {
            latestCSRFToken = await generateCSRFToken();
        }
        let ownersDetails = { ...onboardingForm.ownersDetails };

        const fullName = `${firstName.trim()} ${lastName.trim()}`;
        if (fullName !== onboardingForm.ownerDetails?.fullName) {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                ownersDetails: {
                    ...onboardingForm.ownersDetails,
                    fullName: fullName,
                    contactTel: ownersDetails?.contactTel
                }
            }));
            ownersDetails = {
                ...ownersDetails,
                fullName: fullName,
                contactTel: ownersDetails?.contactTel,
                tinNumber: ownersDetails?.tinNumber ? removeHyphens(ownersDetails.tinNumber) : ''
            };
        }

        //Cloning to not impact onBoardingForm in case of dateOfBirth change
        const ownerPrimaryAddress = { ...onboardingForm.ownerPrimaryAddress };
        ownersDetails.dateOfBirth = { ...ownersDetails.dateOfBirth };
        if (isMexicanmerchant) {
            ownerPrimaryAddress.country = MerchantCountries.MEXICO;
        }
        if (ssnValue) {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                ownersDetails: {
                    ...onboardingForm.ownersDetails,
                    ssnLastDigits: ssnValue
                }
            }));
        }

        const fullData = {
            ownersDetails: ownersDetails,
            subStepNumber: 1,
            formType: formTypes.ONBOARDING_OWNER_DETAILS
        };

        const data = {
            addressData: ownerPrimaryAddress,
            subStepNumber: 2,
            businessTypeId: onboardingForm?.businessDetails?.businessTypeId,
            firstName: firstName,
            lastName: lastName,
            fullName: fullName,
            formType: formTypes.ONBOARDING_OWNER_DETAILS
        };

        const adyenFullName = `${firstName} ${lastName}`;

        if (isAdyenMerchant && isAdmin && adyenFullName !== onboardingForm.ownerDetails?.fullName) {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                ownersDetails: {
                    ...onboardingForm.ownersDetails,
                    fullName: `${firstName} ${lastName}`,
                    contactTel: ownersDetails?.contactTel
                }
            }));
        }

        fullData.ownersDetails.dateOfBirth = { ...fullData.ownersDetails.dateOfBirth };
        const dateOfBirth = fullData.ownersDetails.dateOfBirth;
        fullData.ownersDetails.dateOfBirth = moment(
            [dateOfBirth.day, dateOfBirth.month, dateOfBirth.year].join('-'),
            'DD-MM-YYYY'
        ).format('YYYY-MM-DD');
        console.log('fullData', fullData);
        await NonResellerMerchantService.completeOnboardingStep(globalState.merchant?.id, 4, fullData, latestCSRFToken);
        await NonResellerMerchantService.completeOnboardingStep(globalState.merchant?.id, 4, data, latestCSRFToken);
    };

    const setPersonDetails = (name) => {
        const personDetails = onboardingForm.ownersDetails.persons.filter(
            (person) => person.names.fullName === name
        )[0];
        const dateOfBirth = personDetails.date_of_birth;

        setOnboardingForm((onboardingForm) => ({
            ...onboardingForm,
            ownersDetails: {
                ...onboardingForm.ownersDetails,
                title: personDetails.names.title,
                fullName: personDetails.names.fullName,
                dateOfBirth: dateOfBirth,
                nationality: personDetails.nationality,
                ownership: personDetails.ownership
            }
        }));
    };

    const dayChangeHandler = ({ value }) => {
        if (value.length > 2) {
            value = onboardingForm.ownersDetails.dateOfBirth?.day || '';
        }

        if (value.length > 1 || (value.length === 1 && value > 3)) {
            setTimeout(() => {
                monthInputElementRef.current.focus();
            });
        }

        setOnboardingForm({
            ...onboardingForm,
            ownersDetails: {
                ...onboardingForm.ownersDetails,
                dateOfBirth: {
                    ...onboardingForm.ownersDetails.dateOfBirth,
                    day: value
                }
            }
        });
    };

    const monthChangeHandler = (event) => {
        setOnboardingForm({
            ...onboardingForm,
            ownersDetails: {
                ...onboardingForm.ownersDetails,
                dateOfBirth: {
                    ...onboardingForm.ownersDetails.dateOfBirth,
                    month: event.target.value
                }
            }
        });
    };

    const yearChangeHandler = ({ value }) => {
        if (value.length > 4) {
            value = onboardingForm.ownersDetails.dateOfBirth?.year || '';
        }

        if (value.length > 3) {
            setTimeout(() => {
                emailInputElementRef.current && emailInputElementRef.current.focus();
            });
        }

        setOnboardingForm({
            ...onboardingForm,
            ownersDetails: {
                ...onboardingForm.ownersDetails,
                dateOfBirth: {
                    ...onboardingForm.ownersDetails.dateOfBirth,
                    year: value
                }
            }
        });
    };

    const onChangeSameAddress = (checked) => {
        if (checked) {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                ownerPrimaryAddress: {
                    ...onboardingForm.ownerPrimaryAddress,
                    addressLine1: onboardingForm.tradingAddress.addressLine1,
                    addressLine2: onboardingForm.tradingAddress.addressLine2,
                    postCode: onboardingForm.tradingAddress.postCode,
                    city: onboardingForm.tradingAddress.city,
                    state: onboardingForm.tradingAddress.state,
                    country: onboardingForm.tradingAddress.country,
                    isSameAsTradingAddress: checked
                }
            }));
        } else {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                ownerPrimaryAddress: {
                    ...onboardingForm.ownerPrimaryAddress,
                    addressLine1: '',
                    addressLine2: '',
                    postCode: '',
                    city: '',
                    state: '',
                    country: '',
                    isSameAsTradingAddress: checked
                }
            }));
        }
    };

    const onChangeSameEmail = (checked) => {
        if (checked) {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                ownersDetails: {
                    ...onboardingForm.ownersDetails,
                    email: onboardingForm.businessDetails.email,
                    isSameAsBusinessEmail: checked
                }
            }));
        } else {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                ownersDetails: {
                    ...onboardingForm.ownersDetails,
                    email: '',
                    isSameAsBusinessEmail: checked
                }
            }));
        }
    };

    const onChangeSamePhoneNumber = (checked) => {
        if (checked) {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                ownersDetails: {
                    ...onboardingForm.ownersDetails,
                    contactTel: onboardingForm.businessDetails.phoneNumber,
                    isSameAsBusinessPhoneNumber: checked
                }
            }));
        } else {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                ownersDetails: {
                    ...onboardingForm.ownersDetails,
                    contactTel: CountryCodeOfPhoneNumber[globalState.merchant?.country],
                    isSameAsBusinessPhoneNumber: checked
                }
            }));
        }
    };

    const selectAddressData = (addressData) => {
        if (addressData) {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                ownerPrimaryAddress: {
                    ...onboardingForm.ownerPrimaryAddress,
                    addressLine1: addressData.line_1,
                    addressLine2: addressData.line_2,
                    city: addressData.town_or_city,
                    country: addressData.country
                }
            }));
        }
    };

    const validation = (inputValue) => {
        if (inputValue.length < 9) {
            setShowUrlValidationMessage(true);
        } else {
            setShowUrlValidationMessage(false);
        }
    };

    // trigger validation function when user stop typing
    const doValidation = useCallback(debounce(validation, 1000), []);
    const validateName = (name) => {
        let result = '';
        if (name.trim()) {
            result = name?.replace(/[^A-Za-z ]/gi, '');
        }
        return result;
    };
    const validateABN = (inputEmail) => {
        const errorMessage = isvalidAbn(inputEmail);
        setAbnErrors(errorMessage);

        if (!errorMessage) {
            setIsABNValid(false);
        } else {
            setIsABNValid(true);
        }
    };
    useEffect(() => {
        if (onboardingForm.ownersDetails.tinNumber && fistTime) {
            setFirstTime(false);
            validateABN(onboardingForm.ownersDetails.tinNumber);
        }
        // eslint-disable-next-line
    }, [props.value]);
    const doValidationABN = useCallback(debounce(validateABN, 1000), []);

    const formatEIN = (value) => {
        const cleanValue = value?.replace(/\D/g, '');
        if (cleanValue?.length <= 2) {
            return cleanValue;
        } else if (cleanValue) {
            return cleanValue?.substring(0, 2) + '-' + cleanValue?.substring(2);
        }
    };
    const validatEinNumber = (inputEmail) => {
        const errorMessage = validateEIN(inputEmail);
        setEinErrors(errorMessage);

        if (!errorMessage) {
            setIsEINValid(false);
        } else {
            setIsEINValid(true);
        }
    };
    useEffect(() => {
        if (onboardingForm.ownersDetails.tinNumber && EinNumberFirstTime) {
            setEinNumberFirstTime(false);
            validatEinNumber(onboardingForm.ownersDetails.tinNumber);
        }
        // eslint-disable-next-line
    }, [props.value]);

    const doValidationEIN = useCallback(debounce(validatEinNumber, 1000), []);

    const handleChange = (e) => {
        const rawValue = e.target.value.replace(/\D/g, '');
        const formattedValue = formatEIN(rawValue);
        setOnboardingForm({
            ...onboardingForm,
            ownersDetails: {
                ...onboardingForm.ownersDetails,
                tinNumber: formattedValue
            }
        });
        doValidationEIN(e.target.value);
    };

    const addHyphens = (input) => {
        const addHyphenValue = input?.replace(/\D/g, '');
        const formattedValue = formatEIN(addHyphenValue);
        return formattedValue;
    };

    useEffect(() => {
        const ssnLastDigits = onboardingForm.ownersDetails.ssnLastDigits;
        let maskedValue = '';
        for (let i = 0; i < ssnLastDigits.length; i++) {
            maskedValue = `${maskedValue}*`;
        }
        setMaskedSSNValue(maskedValue);
        setSsnValue(ssnLastDigits);
    }, [onboardingForm.ownersDetails.ssnLastDigits]);

    const socialSecurityValidator = validateSocialSecurity(globalState.merchant?.country, ssnValue);

    const onSSNChange = (event) => {
        let { value } = event.target;

        let newValue = '';

        const ssnLastDigits = ssnValue;
        for (let i = 0; i < value.length; i++) {
            if (value.charAt(i) === '*') {
                newValue = newValue.concat(ssnLastDigits.charAt(i));
            } else {
                newValue = newValue.concat(value.charAt(i));
            }
        }
        let cardNumber = newValue;
        newValue = newValue.replace(/\D/g, '');
        if (!/^\d$/.test(newValue)) {
            cardNumber = newValue.replace(/[^0-9]/g, '');
        }

        const formattedCard = cardNumber.trimRight();
        setSsnValue(formattedCard);

        var strArray = [];
        for (let i = 0; i < formattedCard.length; i++) {
            if (i !== formattedCard.length - 1) {
                strArray.push('*');
            } else {
                if (formattedCard.length > ssnValue.length) {
                    strArray.push(formattedCard[i]);
                } else {
                    strArray.push('*');
                }
            }
        }

        setMaskedSSNValue(strArray.join(''));
        doMasking(formattedCard);
    };

    const maskSSN = (ssnValue) => {
        var strArray = [];
        for (let i = 0; i < ssnValue.length; i++) {
            strArray.push('*');
        }
        setMaskedSSNValue(strArray.join(''));
        setOnboardingForm((onboardingForm) => ({
            ...onboardingForm,
            ownersDetails: {
                ...onboardingForm.ownersDetails,
                ssnLastDigits: ssnValue
            }
        }));
    };

    const doMasking = useCallback(debounce(maskSSN, 500), []);

    return (
        <T2SOnboardingOwnerDetailsComponent
            {...props}
            phoneNumberValidator={InputValidators.validatePhoneNumber}
            onboardingForm={onboardingForm}
            isABNValid={isABNValid}
            validateABN={validateABN}
            handleChange={handleChange}
            addHyphens={addHyphens}
            doValidationABN={doValidationABN}
            abnErrors={abnErrors}
            isEINValid={isEINValid}
            EinErrors={EinErrors}
            setOnboardingForm={setOnboardingForm}
            isDisabled={isDisabled}
            onSubmit={onSubmit}
            setPersonDetails={setPersonDetails}
            oneHundredValues={oneHundredValues}
            dobValidationFailed={dobValidationFailed}
            dobInvalidFields={dobInvalidFields}
            monthInputElementRef={monthInputElementRef}
            yearInputElementRef={yearInputElementRef}
            emailInputElementRef={emailInputElementRef}
            dayChangeHandler={dayChangeHandler}
            monthChangeHandler={monthChangeHandler}
            yearChangeHandler={yearChangeHandler}
            onChangeSameAddress={onChangeSameAddress}
            selectAddressData={selectAddressData}
            onChangeSameEmail={onChangeSameEmail}
            onChangeSamePhoneNumber={onChangeSamePhoneNumber}
            validationMap={validationMap}
            firstName={firstName}
            lastName={lastName}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setDialCode={setDialCode}
            dialCode={dialCode}
            doValidation={doValidation}
            showUrlValidationMessage={showUrlValidationMessage}
            setShowUrlValidationMessage={setShowUrlValidationMessage}
            validateName={validateName}
            isLoading={isLoading}
            selectedMonth={selectedMonth}
            months={months}
            reverseMonthEnum={reverseMonthEnum}
            onSSNChange={onSSNChange}
            maskedSSNValue={maskedSSNValue}
        />
    );
}

export default T2SOnboardingOwnerDetails;
