import React from 'react';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import {
    Button,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Collapse,
    IconButton,
    Tooltip,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useGlobalStateContext } from 'contexts';
import styles from './pdq-order-status.module.scss';
import { SnackBarAndAlert, TransactionStatusLabel, DnaFormModal } from 'components';
import PropTypes from 'prop-types';
import { DNAOnboardStatus } from '../../utils/enums/dnaOnboardStatus';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FilterTabs from '../payments/transaction-tables/filter-tabs/filter-tabs.container';
import { formatDateTimeByFormatString } from '../../utils/helper';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { PdqFilterTabs } from '../../utils/enums/PdqPortalTab';
import { DNAEventStatus } from 'utils/enums/pdqOrderStatus';
import CancelIcon from '@mui/icons-material/Cancel';
import ModalPDQConfirmation from 'components/modal-pdq-confirmation/modal-pdq-confirmation.container';
import { PdqOrderStatus } from 'utils/enums/pdqOrderStatus';
import Loader from 'components/loader/loader.container';
import { MerchantStatus } from 'utils/enums/MerchantStatus';

const useStyles = makeStyles(() => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    tableCellSmallSize: {
        '& td': {
            minWidth: '230px',
            maxWidth: '230px'
        },
        '& th': {
            minWidth: '230px',
            maxWidth: '230px'
        }
    },
    noData: {
        textAlign: 'center',
        paddingBottom: '100px',
        paddingTop: '100px'
    },
    tableRow: {
        '&$selected, &$selected:hover': {
            backgroundColor: '#e4e1fe'
        }
    },
    headWidth: {
        minWidth: '210px'
    },
    noBorders: {
        '& td': {
            border: 0
        }
    },
    cellWidth: {
        minWidth: '80px !important',
        maxWidth: '80px !important'
    },
    cellWidthCancelIcon: {
        minWidth: '80px !important',
        maxWidth: '80px !important'
    }
}));

export default function PdqOrderStatusComp(props) {
    const {
        isLoading,
        merchantData,
        pdqOrderDetails,
        onConfirm,
        snackMessage,
        messageType,
        setSnackMessage,
        onboardedMerchant,
        setDnaRequest,
        dnaOnboardCurrentRequest,
        dnaProductType,
        handleCopyTerminalId,
        statusFilter,
        setStatusFilter,
        show,
        setShow,
        openCollapse,
        setOpenCollapse,
        pdqRequests,
        overFlowText,
        openForm,
        setOpenForm,
        data,
        setData,
        pdqFee,
        setPdqFee,
        modalLoading,
        agentName,
        showCancelDialog,
        setShowCancelDialog,
        onPDQRemovalRequest,
        actionButtonSnackbarMessage,
        setActionButtonSnackbarMessage,
        setTerminalIdToBeRemoved,
        setProductTypeToBeRemoved,
        productTypeToBeRemoved,
        terminalIdToBeRemoved,
        applicationStatus,
        openFormModal,
        pdqData
    } = props;

    const { setRoute } = useGlobalStateContext();
    const pdqDevices = pdqOrderDetails?.length ? pdqOrderDetails : null;
    const classes = useStyles();
    return (
        <React.Fragment>
            {isLoading ? (
                <div className={styles.loading}>
                    <Loader />
                </div>
            ) : (
                <div className={styles.container}>
                    <Grid
                        container
                        alignItems="center"
                        spacing={1}
                        className={styles.leftAlign}
                        xs={12}
                        sm={12}
                        md={12}
                    >
                        <Grid item xs={3} sm={2} md={2}>
                            <Button
                                color="primary"
                                variant="outlined"
                                size="small"
                                startIcon={<NavigateBeforeIcon />}
                                onClick={() => {
                                    setRoute('/merchantpdq');
                                    localStorage.removeItem('onBoardedMerchant');
                                }}
                            >
                                <span>Back</span>
                            </Button>
                        </Grid>

                        <Grid item sm={10} md={10} xs={12}>
                            {(applicationStatus === DNAEventStatus.REQUEST_RAISED ||
                                applicationStatus === DNAEventStatus.BACKGROUND_CHECK) && (
                                <div className={styles.listItemContainer}>
                                    <Typography color="primary">
                                        <React.Fragment>
                                            <div className={`${styles.bannerMessage} ${styles.automaticPayout} `}>
                                                Merchant Onboarding process has already started. Please wait for further
                                                update
                                            </div>
                                        </React.Fragment>
                                    </Typography>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                    <div className={styles.merchantBlock}>
                        <span className={styles.merchantName}> {merchantData?.legalName}</span>
                        <span className={styles.verifyBadge}>
                            {merchantData.isAccountVerified &&
                                merchantData.isBankAccountVerified &&
                                merchantData.status === MerchantStatus.ACTIVE && (
                                    <TransactionStatusLabel status={'Verified'} />
                                )}
                        </span>
                    </div>
                    <div className={styles.detailsView}>
                        <Grid item xs={12} sm={6}>
                            <Grid item xs={12} className={styles.thirdId}>
                                <span className={styles.merchantLabelText}>Merchant ID</span>
                                <span>:</span>
                                <span style={{ display: 'flex' }} className={styles.merchantData}>
                                    {merchantData?.MerchantId ? merchantData?.MerchantId : <>&mdash;</>}
                                    <span>
                                        <ContentCopyIcon
                                            className={styles.copyIcon}
                                            onClick={() =>
                                                handleCopyTerminalId(merchantData?.MerchantId, 'merchant ID')
                                            }
                                        />
                                    </span>
                                </span>
                            </Grid>
                            <Grid item xs={12} className={styles.thirdId}>
                                <span className={styles.merchantLabelText}>Store ID</span>
                                <span>:</span>

                                <span className={styles.merchantData}>
                                    {merchantData?.thirdPartyCustomer ? merchantData?.thirdPartyCustomer : <>&mdash;</>}
                                </span>
                            </Grid>
                            <Grid item xs={12} className={styles.thirdId}>
                                <span className={styles.merchantLabelText}>Store Key</span>
                                <span>:</span>

                                <span className={styles.merchantData}>&mdash;</span>
                            </Grid>
                            <Grid item xs={12} className={styles.thirdId}>
                                <span className={styles.merchantLabelText}>License Key</span>
                                <span>:</span>
                                <span className={styles.merchantData}>
                                    {pdqData?.licenceKey ? pdqData?.licenceKey : <>&mdash;</>}
                                </span>
                            </Grid>
                            <Grid item xs={12} className={styles.thirdId}>
                                <span className={styles.merchantLabelText}>Acquirer MID</span>
                                <span>:</span>
                                <span className={styles.merchantData}>
                                    {pdqData?.acquirerMID ? pdqData?.acquirerMID : <>&mdash;</>}
                                </span>
                            </Grid>
                        </Grid>
                        {(!dnaOnboardCurrentRequest ||
                            dnaOnboardCurrentRequest === DNAOnboardStatus.COMPLETED_ONBOARDING ||
                            dnaOnboardCurrentRequest === DNAOnboardStatus.CANCEL_DNA_ONBOARD ||
                            (applicationStatus === DNAEventStatus.ECOM_ISSUED &&
                                dnaOnboardCurrentRequest !== DNAOnboardStatus.EXTRA_PDQ)) && (
                            <Grid item xs={12} sm={6} style={{ 'text-align': 'right' }}>
                                <Button variant="contained" className={styles.reqBtn} onClick={() => openFormModal()}>
                                    Raise Request
                                </Button>
                            </Grid>
                        )}
                    </div>
                    <div className={styles.tabView}>
                        <FilterTabs
                            path={'pdq-portal'}
                            tabFilterValue={statusFilter}
                            setTabFilterValue={setStatusFilter}
                            tabList={PdqFilterTabs}
                        />
                        {statusFilter === PdqFilterTabs.Products ? (
                            <Grid container xs={12} sm={12}>
                                <TableContainer component={Paper} className={styles.TablesPaper}>
                                    <Table
                                        aria-label="simple table"
                                        aria-labelledby="tableTitle"
                                        size="small"
                                        stickyHeader
                                        className={classes.tableCellSmallSize}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Type</TableCell>
                                                <TableCell>Date Live</TableCell>
                                                <TableCell>Terminal ID</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {pdqDevices ? (
                                                pdqDevices.map((row, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell>{dnaProductType}</TableCell>
                                                            {setProductTypeToBeRemoved(dnaProductType)}
                                                            <TableCell>
                                                                {formatDateTimeByFormatString(
                                                                    row.created_at,
                                                                    'DD MMM YYYY'
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Tooltip
                                                                    title={row.terminal_id}
                                                                    placement="bottom-start"
                                                                    arrow
                                                                >
                                                                    <span className={styles.overFlowText}>
                                                                        {overFlowText(row.terminal_id)}
                                                                    </span>
                                                                </Tooltip>
                                                                <span>
                                                                    <ContentCopyIcon
                                                                        className={styles.copyIcon}
                                                                        onClick={() =>
                                                                            handleCopyTerminalId(
                                                                                row.terminal_id,
                                                                                'terminal ID'
                                                                            )
                                                                        }
                                                                    />
                                                                </span>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Button
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    onClick={() => {
                                                                        setShow(true);
                                                                        setTerminalIdToBeRemoved(row.terminal_id);
                                                                    }}
                                                                    disabled={row.is_removed}
                                                                >
                                                                    Remove
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            ) : (
                                                <TableRow className={styles.rowData}>
                                                    <TableCell colSpan={8} align="center">
                                                        No records found
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        ) : (
                            <Grid container xs={12} sm={12}>
                                <TableContainer component={Paper} className={styles.TablesPaper}>
                                    <Table
                                        aria-label="simple table"
                                        aria-labelledby="tableTitle"
                                        size="small"
                                        stickyHeader
                                        className={classes.tableCellSmallSize}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.cellWidth}></TableCell>
                                                <TableCell>Application ID</TableCell>
                                                <TableCell>Date</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Agent Email</TableCell>
                                                <TableCell>Reason</TableCell>
                                                <TableCell className={classes.cellWidthCancelIcon}> Action</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {pdqRequests.length > 0 ? (
                                                pdqRequests.map((row, index) => {
                                                    return (
                                                        <>
                                                            <TableRow key={index}>
                                                                <TableCell className={classes.cellWidth}>
                                                                    <IconButton
                                                                        aria-label="expand row"
                                                                        size="small"
                                                                        className={styles.icon}
                                                                        onClick={() =>
                                                                            setOpenCollapse(
                                                                                openCollapse === index ? '' : index
                                                                            )
                                                                        }
                                                                        disabled={row.statuses.length === 0}
                                                                    >
                                                                        {openCollapse === index ? (
                                                                            <RemoveCircleOutlineIcon />
                                                                        ) : (
                                                                            <AddCircleOutlineIcon />
                                                                        )}
                                                                    </IconButton>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {row.applicationId && (
                                                                        <div className={styles.dialogText}>
                                                                            <Tooltip
                                                                                title={row.applicationId}
                                                                                className={styles.overFlowText}
                                                                                placement="bottom-start"
                                                                                arrow
                                                                            >
                                                                                <span className={styles.overflowSpan}>
                                                                                    {overFlowText(row.applicationId)}
                                                                                </span>
                                                                            </Tooltip>
                                                                            <span>
                                                                                <ContentCopyIcon
                                                                                    className={styles.copyIcon}
                                                                                    onClick={() =>
                                                                                        handleCopyTerminalId(
                                                                                            row?.applicationId,
                                                                                            'application ID'
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {formatDateTimeByFormatString(
                                                                        row?.updated_at,
                                                                        'DD MMM YYYY'
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TransactionStatusLabel
                                                                        status={PdqOrderStatus[row?.applicationStatus]}
                                                                        isPdq={true}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Tooltip
                                                                        title={agentName}
                                                                        className={styles.overFlowText}
                                                                        placement="bottom-start"
                                                                        arrow
                                                                    >
                                                                        <span className={styles.overflowSpan}>
                                                                            {agentName}
                                                                        </span>
                                                                    </Tooltip>
                                                                </TableCell>
                                                                <TableCell>{row?.applicationReason}</TableCell>
                                                                <TableCell className={classes.cellWidthCancelIcon}>
                                                                    {row?.applicationStatus ===
                                                                    DNAEventStatus.REQUEST_RAISED ? (
                                                                        <CancelIcon
                                                                            style={{
                                                                                color: '#eb8096',
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            onClick={() => {
                                                                                setDnaRequest(
                                                                                    DNAOnboardStatus.CANCEL_DNA_ONBOARD
                                                                                );
                                                                                setShowCancelDialog(true);
                                                                            }}
                                                                        />
                                                                    ) : null}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell
                                                                    style={{ padding: 0, border: 0 }}
                                                                    colSpan={6}
                                                                >
                                                                    <Collapse
                                                                        in={openCollapse === index}
                                                                        timeout="auto"
                                                                        unmountOnExit
                                                                        style={{
                                                                            'border-bottom':
                                                                                '1px solid rgba(224, 224, 224, 1)'
                                                                        }}
                                                                    >
                                                                        <Table
                                                                            aria-label="simple table"
                                                                            aria-labelledby="tableTitle"
                                                                            size="small"
                                                                            stickyHeader
                                                                            className={classes.tableCellSmallSize}
                                                                        >
                                                                            <TableBody>
                                                                                {row.statuses.map((data) => (
                                                                                    <TableRow
                                                                                        className={classes.noBorders}
                                                                                    >
                                                                                        <TableCell
                                                                                            className={
                                                                                                classes.cellWidth
                                                                                            }
                                                                                        ></TableCell>
                                                                                        <TableCell></TableCell>
                                                                                        <TableCell>
                                                                                            {formatDateTimeByFormatString(
                                                                                                data.created_at,
                                                                                                'DD MMM YYYY'
                                                                                            )}
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            <TransactionStatusLabel
                                                                                                status={
                                                                                                    PdqOrderStatus[
                                                                                                        data
                                                                                                            ?.applicationStatus
                                                                                                    ]
                                                                                                }
                                                                                                isPdq={true}
                                                                                            />
                                                                                        </TableCell>
                                                                                        <TableCell></TableCell>
                                                                                        <TableCell>
                                                                                            {data?.applicationReason}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </Collapse>
                                                                </TableCell>
                                                            </TableRow>
                                                        </>
                                                    );
                                                })
                                            ) : (
                                                <TableRow className={styles.rowData}>
                                                    <TableCell colSpan={8} align="center">
                                                        No records found
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        )}
                    </div>
                    <ModalPDQConfirmation
                        setShow={setShow}
                        show={show}
                        confirm={onPDQRemovalRequest}
                        dialogText={`Are you sure you want to remove ${productTypeToBeRemoved}, ${terminalIdToBeRemoved}?`}
                        modalLoading={modalLoading}
                    />
                    <ModalPDQConfirmation
                        setShow={setShowCancelDialog}
                        show={showCancelDialog}
                        confirm={onConfirm}
                        modalLoading={modalLoading}
                        dialogText={`Are you sure you want to cancel?`}
                    />
                    <div>
                        {openForm && (
                            <DnaFormModal
                                open={openForm}
                                setOpen={setOpenForm}
                                merchantId={merchantData?.MerchantId}
                                businessTypeId={merchantData?.BusinessDetail?.businessTypeId}
                                data={data}
                                setData={setData}
                                pdqFee={pdqFee}
                                setPdqFee={setPdqFee}
                                onConfirm={onConfirm}
                                onboardedMerchant={onboardedMerchant}
                                isLoading={modalLoading}
                                isAdmin={false}
                            />
                        )}
                        {snackMessage && (
                            <SnackBarAndAlert
                                open={snackMessage}
                                type={messageType}
                                onClose={() => setSnackMessage('')}
                            >
                                {snackMessage}
                            </SnackBarAndAlert>
                        )}
                        {actionButtonSnackbarMessage && (
                            <SnackBarAndAlert
                                open={actionButtonSnackbarMessage.message}
                                type={actionButtonSnackbarMessage.type}
                                onClose={() => setActionButtonSnackbarMessage({ type: '', message: '' })}
                            >
                                {actionButtonSnackbarMessage.message}
                            </SnackBarAndAlert>
                        )}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

PdqOrderStatusComp.propTypes = {
    dnaOnboardCurrentRequest: PropTypes.string,
    setDnaOnboardCurrentRequest: PropTypes.func,
    dialogMessage: PropTypes.string,
    setDialogMessage: PropTypes.func
};
