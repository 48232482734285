import React from 'react';
import PropTypes from 'prop-types';
import style from './next-btn.module.scss';
import { Button, Grid } from '@material-ui/core';
import { useGlobalStateContext } from 'contexts';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { translateText } from 'utils/languageTranslater';
import { UserType } from 'utils/enums/UserType';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import moment from 'moment';
function NextButtonComponent(props) {
    const { globalState, setGlobalState, setRoute } = useGlobalStateContext();
    const isMerchantUserType = globalState.user?.UserType?.name === UserType.Merchant;
    const isActiveMerchant = globalState.merchant?.status === MerchantStatus.ACTIVE;
    const isUkMerchant = globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM;

    return (
        <React.Fragment>
            {globalState.merchant?.status === MerchantStatus.MERCHANT_PENDING ? (
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <Button
                            id="cancel-btn"
                            color="primary"
                            variant="contained"
                            disabled={props.disabled}
                            className={style.nextBtn + ' ' + style.pendingButton + ' ' + style.firstButton}
                            onClick={(e) => setRoute('/home')}
                        >
                            {translateText({ id: 'cancel-button', text: 'Cancel' })}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            id="save-and-close-btn"
                            color="primary"
                            variant="contained"
                            disabled={props.disabled}
                            className={style.nextBtn + ' ' + style.pendingButton}
                            onClick={(e) => {
                                props.onSubmit(e);
                                setRoute('/home');
                            }}
                        >
                            {translateText({ id: 'save-and-close-button', text: 'Save' })}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            id="next-btn"
                            color="primary"
                            variant="contained"
                            disabled={props.disabled}
                            className={style.nextBtn + ' ' + style.pendingButton + ' ' + style.primaryButtonColor}
                            onClick={(e) => props.onSubmit(e)}
                        >
                            {translateText({ id: 'next-button', text: 'Next' })}
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <div className={style.buttonsWrapper}>
                    <Button
                        id="nextExit-btn"
                        color="primary"
                        variant="contained"
                        disabled={props.disabled}
                        className={style.saveExitBtn}
                        onClick={(e) => {
                            if (isMerchantUserType && isActiveMerchant && isUkMerchant) {
                                localStorage.setItem('skip_clicked', moment().format('DD-MM-YYYY hh:mm:ss'));
                            }
                            setGlobalState({
                                ...globalState,
                                exitFromOnboarding: true
                            });
                            props.onSubmit(e);
                            setRoute('/home');
                        }}
                    >
                        {translateText({ id: 'save-and-close-button', text: 'Save' })}
                    </Button>
                    <Button
                        id="next-btn"
                        color="primary"
                        variant="contained"
                        disabled={props.disabled}
                        className={style.saveNextBtn + ' ' + style.primaryButtonColor}
                        onClick={(e) => props.onSubmit(e)}
                    >
                        {translateText({ id: 'next-button', text: 'Next' })}
                    </Button>
                </div>
            )}
            {isMerchantUserType && isActiveMerchant && isUkMerchant && (
                <div
                    className={style.remindMeLater}
                    onClick={() => {
                        localStorage.setItem('skip_clicked', moment().format('DD-MM-YYYY hh:mm:ss'));
                        setRoute('/home');
                    }}
                >
                    Remind me later
                </div>
            )}
        </React.Fragment>
    );
}

NextButtonComponent.propTypes = {
    onSubmit: PropTypes.func,
    disabled: PropTypes.bool
};

export default NextButtonComponent;
