import { AuthAxios } from './interceptor.service.js';
import { NonResellerMerchantServiceEndpoint } from '../utils/constants';
import { SuccesResponse, ErrorResponse } from 'models';
import { validateMerchantName } from 'validators';
import { getT2SReferralLinkPayload } from 'utils/getT2SReferralLinkPayload';
import { MerchantCountries } from 'utils/enums/MerchantCountries';

const getUserMerchants = async (resellerId) => {
    try {
        const response = await AuthAxios.get(`${NonResellerMerchantServiceEndpoint}/reseller/${resellerId}`);

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getMerchantAcquirer = async (merchandId) => {
    try {
        const response = await AuthAxios.post(`${NonResellerMerchantServiceEndpoint}/get-merchant-acquirer`, {
            merchant_id: merchandId
        });

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getAllMerchantsForReseller = async (resellerId, data) => {
    try {
        const response = await AuthAxios.post(
            `${NonResellerMerchantServiceEndpoint}/get-all-merchants-for-reseller/${resellerId}`,
            data
        );

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getAdminMerchants = async (resellerId, searchedString, includeMerchantId) => {
    try {
        const data = { searchedString, includeMerchantId };
        const response = await AuthAxios.post(`${NonResellerMerchantServiceEndpoint}/admin/${resellerId}`, data);

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getMerchant = async (id) => {
    try {
        const response = await AuthAxios.get(`${NonResellerMerchantServiceEndpoint}/merchant/${id}`);

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const createUserMerchant = async (data) => {
    try {
        const response = await AuthAxios.post(`${NonResellerMerchantServiceEndpoint}`, data);

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const createUserMerchantUsingThirdPartyCustomerKey = async (thirdPartyCustomerKey, resellerId = 2) => {
    let referralData = getT2SReferralLinkPayload(thirdPartyCustomerKey, {
        noRedirect: true
    });

    if (
        referralData &&
        referralData.TradingName &&
        referralData.BusinessAddressCountry &&
        Object.values(MerchantCountries).includes(referralData.BusinessAddressCountry)
    ) {
        try {
            const validationResult = validateMerchantName(referralData.TradingName);
            if (validationResult.isValid) {
                let data = {
                    name: referralData.TradingName,
                    country: referralData.BusinessAddressCountry,
                    resellerId: resellerId,
                    thirdPartyCustomerId: referralData.i || null,
                    host: referralData.f || null,
                    refferalDataValue: thirdPartyCustomerKey || null
                };
                const response = await AuthAxios.post(`${NonResellerMerchantServiceEndpoint}`, data);
                return new SuccesResponse(response.data);
            }
        } catch (error) {
            return getError(error);
        }
    }

    return getError({ message: 'Could not create merchant' });
};

const updateMerchantInfo = async (merchantId, data) => {
    try {
        const response = await AuthAxios.post(
            `${NonResellerMerchantServiceEndpoint}/update-merchant-info/${merchantId}`,
            data
        );
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const deleteMerchantAccount = async (id) => {
    try {
        const response = await AuthAxios.delete(`${NonResellerMerchantServiceEndpoint}/delete/${id}`);

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const requestCloseMerchantAccount = async (id, data) => {
    try {
        const response = await AuthAxios.put(`${NonResellerMerchantServiceEndpoint}/request-close/${id}`, data);

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const completeOnboardingStep = async (merchantId, onboardingStep, data, csrfToken) => {
    try {
        const response = await AuthAxios.post(
            `${NonResellerMerchantServiceEndpoint}/save/${merchantId}/onboarding/${onboardingStep}`,
            data,
            { headers: { 'X-CSRF-TOKEN': csrfToken } }
        );

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const updateProducts = async (merchantId, data) => {
    try {
        const response = await AuthAxios.post(
            `${NonResellerMerchantServiceEndpoint}/send-updated-products-list/${merchantId}`,
            data
        );

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getOnboardingStepDetails = async (merchantId, onboardingStep) => {
    try {
        const response = await AuthAxios.get(
            `${NonResellerMerchantServiceEndpoint}/get/${merchantId}/onboarding/${onboardingStep}`
        );

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getOnboardingFullData = async (merchantId) => {
    try {
        const response = await AuthAxios.get(`${NonResellerMerchantServiceEndpoint}/get/${merchantId}/onboarding`);

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const completeOnboarding = async (merchantId, csrfToken) => {
    try {
        const response = await AuthAxios.get(`${NonResellerMerchantServiceEndpoint}/complete/${merchantId}`, {
            headers: { 'X-CSRF-TOKEN': csrfToken }
        });

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const incompleteBoardingFormCheck = async (merchantId) => {
    try {
        const response = await AuthAxios.get(`${NonResellerMerchantServiceEndpoint}/incomplete/${merchantId}`);
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const t2sIncompleteOnBoardingFormCheck = async (merchantId) => {
    try {
        const response = await AuthAxios.get(`${NonResellerMerchantServiceEndpoint}/t2s-incomplete/${merchantId}`);
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getError = (error) => {
    const message = error.response ? error.response.data : 'An error occurred';
    return new ErrorResponse(message);
};
const updateMerchantData = async (merchantId, data) => {
    try {
        const response = await AuthAxios.post(
            `${NonResellerMerchantServiceEndpoint}/update-merchant-data/${merchantId}`,
            data
        );
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getCanonicalResellerMerchant = async (merchantId) => {
    try {
        const response = await AuthAxios.get(
            `${NonResellerMerchantServiceEndpoint}/get-canonical-reseller-merchant/${merchantId}`
        );
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getTermsAndConditionsForMerchant = async (merchantId, modalTermsAndConditions) => {
    try {
        const response = await AuthAxios.get(
            `${NonResellerMerchantServiceEndpoint}/get-merchants-terms-and-conditions/${merchantId}/${modalTermsAndConditions}`
        );
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};
const merchantAcceptTermsAndConditions = async (merchantId) => {
    try {
        const response = await AuthAxios.post(
            `${NonResellerMerchantServiceEndpoint}/merchant-accept-terms-and-conditions/${merchantId} `
        );
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const enableAutoWithdrawals = async (merchantId, data) => {
    try {
        const response = await AuthAxios.post(
            `${NonResellerMerchantServiceEndpoint}/merchant-enable-auto-withdrawal/${merchantId}`,
            data
        );
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const linkQrCode = async (data, id) => {
    try {
        const response = await AuthAxios.post(`${NonResellerMerchantServiceEndpoint}/link-merchant-qr/${id}`, data);
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getAllPdqOrders = async (canonicalResellerId) => {
    try {
        const response = await AuthAxios.get(
            `${NonResellerMerchantServiceEndpoint}/get-pdq-orders/${canonicalResellerId}`
        );

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};
const RenewSignupLink = async (id) => {
    try {
        const response = await AuthAxios.post(
            `${NonResellerMerchantServiceEndpoint}/reactive-merchant-signup-link/${id}`
        );
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        return getError(error);
    }
};

export default {
    getUserMerchants,
    getAdminMerchants,
    getAllMerchantsForReseller,
    createUserMerchant,
    updateMerchantInfo,
    deleteMerchantAccount,
    getMerchant,
    completeOnboardingStep,
    getOnboardingStepDetails,
    getOnboardingFullData,
    completeOnboarding,
    incompleteBoardingFormCheck,
    updateProducts,
    requestCloseMerchantAccount,
    updateMerchantData,
    t2sIncompleteOnBoardingFormCheck,
    getCanonicalResellerMerchant,
    createUserMerchantUsingThirdPartyCustomerKey,
    getTermsAndConditionsForMerchant,
    merchantAcceptTermsAndConditions,
    enableAutoWithdrawals,
    linkQrCode,
    getMerchantAcquirer,
    // getDnaForm
    getAllPdqOrders,
    RenewSignupLink
};
