import { AuthAxios } from './interceptor.service.js';
import { TaxonomyServiceEndpoint } from '../utils/constants';
import { SuccesResponse, ErrorResponse } from 'models';
import { Cache } from 'aws-amplify';
import axios from 'axios';
const axiosInstance = axios.create();

const getDocumentTypes = async () => {
    try {
        let documentTypeCache = null; //Cache.getItem('documentTypes');
        if (!documentTypeCache) {
            documentTypeCache = await getDocumentTypesAPI();
            Cache.setItem('documentTypes', documentTypeCache, { priority: 5 });
        }

        return new SuccesResponse(documentTypeCache);
    } catch (error) {
        return getError(error);
    }
};

const getDocumentTypesAPI = async () => {
    const response = await AuthAxios.get(`${TaxonomyServiceEndpoint}/document-types`);
    return response.data;
};

const getDescriptions = async () => {
    try {
        const response = await AuthAxios.get(`${TaxonomyServiceEndpoint}/descriptions`);

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getBusinessTypes = async () => {
    try {
        const response = await AuthAxios.get(`${TaxonomyServiceEndpoint}/business-types`);

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

async function getMotoRenewalReasonList() {
    try {
        const response = await AuthAxios.get(`${TaxonomyServiceEndpoint}/moto-renewal-reason`);
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
}

const getProductsRequired = async () => {
    try {
        const response = await AuthAxios.get(`${TaxonomyServiceEndpoint}/products-required`);

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getResellerByPortalURL = async (portalURL) => {
    try {
        const response = await getResellers();
        let resellers;
        if (response.isSuccesfully) {
            resellers = response.data;
        }

        const resData = resellers?.find((r) => r.portalURL === portalURL);
        if (!resData) {
            return getError('No reseller found');
        }
        return new SuccesResponse(resData);
    } catch (error) {
        return getError(error);
    }
};

const getMaintainenceMode = async () => {
    try {
        const response = await axiosInstance.get(`https://maintenance-mode.datmancrm.com/maintenance_mode`);
        return response?.data;
    } catch (error) {
        console.log('error getting maintainence mode', error);
        return false;
    }
};

const getResellers = async () => {
    try {
        let resellersCache = Cache.getItem('resellers');
        if (!resellersCache) {
            resellersCache = await getResellersAPI();
            Cache.setItem('resellers', resellersCache, { priority: 1 });
        }
        return new SuccesResponse(resellersCache);
    } catch (error) {
        return getError(error);
    }
};

const getResellersAPI = async () => {
    try {
        // const response = await AuthAxios.get(`${TaxonomyServiceEndpoint}/resellers`);
        const response = {
            data: JSON.parse(process.env.REACT_APP_CONFIG_SERVICE_ENDPOINT)
        };
        return response.data;
    } catch (error) {
        return getError(error);
    }
};

const getAllAcquirer = async () => {
    try {
        const response = await AuthAxios.get(`${TaxonomyServiceEndpoint}/get-acquirers`);

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getError = (error) => {
    const message = error.response ? error.response.data : 'An error occurred';
    return new ErrorResponse(message);
};

export default {
    getDocumentTypes,
    getDescriptions,
    getBusinessTypes,
    getMotoRenewalReasonList,
    getProductsRequired,
    getResellerByPortalURL,
    getResellers,
    getAllAcquirer,
    getResellersAPI,
    getMaintainenceMode
};
