import React, { useState, useEffect, useRef } from 'react';
import MissingDocument from './missing-merchant-document.component';
import { useGlobalStateContext } from 'contexts';
import { AdminService, DocumentsService } from 'services';
import { DocumentTypesToName } from 'utils/enums/DocumentType';
import moment from 'moment';
import _ from 'lodash';
import { getInvalidDobFields } from 'validators';

function MissingDocumentContainer(props) {
    const [loading, setLoading] = useState(false);
    const { globalState, setRoute } = useGlobalStateContext();
    const [merchantData, setMerchantData] = useState({});
    const [documentToView, setDocumentToView] = useState([]);
    const [dobInvalidFields, setDobInvalidFields] = useState([]);
    const [dobValidationFailed, setDobValidationFailed] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [error, setError] = useState(false);
    const monthInputElementRef = useRef(null);
    const yearInputElementRef = useRef(null);

    useEffect(() => {
        const getMerchantData = async () => {
            const merchantId = props?.match?.params?.merchantId;
            setLoading(true);

            const result = await AdminService.GetMissingData(merchantId);

            if (result.isSuccesfully) {
                const listOfParams = [];
                const DocumentData =
                    result.data?.data[0]?.Documents.length !== 0 ? [result.data?.data[0]?.Documents[0]] : [];
                if (DocumentData.length !== 0) {
                    DocumentData.forEach((document) => {
                        const fileObj = {};
                        fileObj.entity = 'merchant';
                        fileObj.entityId = merchantId;
                        fileObj.documentId = document.id;
                        fileObj.filename = document?.filename;
                        fileObj.documentTypeName = DocumentTypesToName[document.documentTypeId];
                        fileObj.createdAt = document.created_at;
                        fileObj.documentStatus = document.status;
                        fileObj.flag = document.flag;
                        fileObj.docTypeId = document.documentTypeId;

                        listOfParams.push(fileObj);
                    });
                    const listOfUrls = await DocumentsService.getMerchantFilesUrls(listOfParams);
                    setDocumentToView(listOfUrls);
                } else {
                    setDocumentToView([]);
                }
                let tempMerchantData = { ...result.data.data[0] };
                if (tempMerchantData?.OwnersDetail?.birthDate) {
                    const birthDate = tempMerchantData?.OwnersDetail?.birthDate?.split('-');
                    tempMerchantData.OwnersDetail.birthDate = {
                        day: birthDate[2],
                        month: birthDate[1],
                        year: birthDate[0]
                    };
                } else {
                    tempMerchantData = {
                        ...tempMerchantData,
                        OwnersDetail: {
                            ...tempMerchantData?.OwnersDetail,
                            birthDate: { day: '', month: '', year: '' }
                        }
                    };
                }
                setMerchantData(tempMerchantData);
            } else {
                setError(true);
            }
            setLoading(false);
        };

        getMerchantData();
        //eslint-disable-next-line
    }, [props?.match?.params?.merchantId]);

    useEffect(() => {
        const getDate = async () => {
            const dateOfBirth = { ...merchantData.OwnersDetail?.birthDate };
            const nationality = merchantData.OwnersDetail?.nationality;
            const dateOfBirthMoment =
                dateOfBirth && moment([dateOfBirth.day, dateOfBirth.month, dateOfBirth.year].join('-'), 'DD-MM-YYYY');
            const isDateOfBirthProper =
                dateOfBirth &&
                dateOfBirthMoment.isValid() &&
                +dateOfBirth.day >= 0 &&
                +dateOfBirth.month >= 0 &&
                +dateOfBirth.year >= 1900 &&
                +dateOfBirthMoment.valueOf() <= moment().valueOf();

            const dobFieldsWithIssues = getInvalidDobFields(dateOfBirth);
            const checkValues =
                nationality !== null &&
                nationality !== '' &&
                nationality !== '--Please select--' &&
                isDateOfBirthProper &&
                _.isEmpty(dobFieldsWithIssues);

            setDobInvalidFields(dobFieldsWithIssues);
            setDobValidationFailed(!isDateOfBirthProper);
            setIsDisabled(!checkValues);
            //filterDocuments(selectedValue);
        };
        getDate();
        //eslint-disable-next-line
    }, [merchantData.OwnersDetail?.birthDate, merchantData.OwnersDetail?.nationality]);
    const dayChangeHandler = ({ value }) => {
        if (value.length > 2) {
            value = merchantData?.OwnersDetail?.birthDate?.day || '';
        }

        if (value.length > 1 || (value.length === 1 && value > 3)) {
            setTimeout(() => {
                monthInputElementRef.current.focus();
            });
        }
        setMerchantData({
            ...merchantData,
            OwnersDetail: {
                ...merchantData?.OwnersDetail,
                birthDate: {
                    ...merchantData?.OwnersDetail.birthDate,
                    day: value
                }
            }
        });
    };

    const monthChangeHandler = ({ value }) => {
        if (value.length > 2) {
            value = merchantData?.OwnersDetail.birthDate?.month || '';
        }

        if (value.length > 1 || (value.length === 1 && value > 1)) {
            setTimeout(() => {
                yearInputElementRef.current && yearInputElementRef.current.focus();
            });
        }
        setMerchantData({
            ...merchantData,
            OwnersDetail: {
                ...merchantData?.OwnersDetail,
                birthDate: {
                    ...merchantData?.OwnersDetail.birthDate,
                    month: value
                }
            }
        });
    };

    const yearChangeHandler = ({ value }) => {
        if (value.length > 4) {
            value = merchantData.OwnersDetail.dateOfBirth?.year || '';
        }

        setMerchantData({
            ...merchantData,
            OwnersDetail: {
                ...merchantData?.OwnersDetail,
                birthDate: {
                    ...merchantData?.OwnersDetail.birthDate,
                    year: value
                }
            }
        });
    };

    const onSubmit = async () => {
        setLoading(true);
        //Cloning to not impact onBoardingForm in case of dateOfBirth change
        const details = { ...merchantData.OwnersDetail };
        const dateOfBirth = details.birthDate;
        details.dateOfBirth = moment(
            [dateOfBirth.day, dateOfBirth.month, dateOfBirth.year].join('-'),
            'DD-MM-YYYY'
        ).format('YYYY-MM-DD');
        const fullData = {
            merchantId: globalState.merchant?.id,
            nationality: details.nationality,
            DOB: details.dateOfBirth
        };

        const submit = await AdminService.UpdateMissingData(fullData);
        if (submit.isSuccesfully) {
            setError('Data has been Updated Successfully');
            setRoute('/admin-missing-merchant');
        } else {
            setError('An error Occured');
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            setError(false);
        }
    };

    return (
        <MissingDocument
            {...props}
            loading={loading}
            merchantData={merchantData}
            error={error}
            setMerchantData={setMerchantData}
            dayChangeHandler={dayChangeHandler}
            monthChangeHandler={monthChangeHandler}
            yearChangeHandler={yearChangeHandler}
            monthInputElementRef={monthInputElementRef}
            yearInputElementRef={yearInputElementRef}
            onSubmit={onSubmit}
            documentToView={documentToView}
            dobInvalidFields={dobInvalidFields}
            dobValidationFailed={dobValidationFailed}
            isDisabled={isDisabled}
            handleClose={handleClose}
        />
    );
}

MissingDocumentContainer.propTypes = {};

export default MissingDocumentContainer;
