import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import {
    Paper,
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Toolbar,
    Typography,
    IconButton,
    Tooltip,
    TableFooter,
    TablePagination
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import ChargebacksExpandedRow from './chargebacks-expanded-row/chargebacks-expanded-row.container';
import TableFooterComponent from '../table-footer/table-footer.component';
import TableHeader from '../table-header/table-header.container';
import FilterTabs from '../filter-tabs/filter-tabs.container';
import { ChargebacksFilterTabs } from '../../../../utils/enums/ChargebacksFilterTabs';
import { formatCurrency, formatDateTimeByFormatString, getComparator, stableSort } from '../../../../utils/helper';
import styles from './chargebacks-list-table.module.scss';
import { getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry';
import { useGlobalStateContext } from 'contexts';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1)
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85)
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: '#8783b5'
              },
    title: {
        flex: '1 1 100%'
    }
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : null}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton aria-label="filter list">
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        boxShadow: 'none'
    },
    table: {
        minWidth: '100%'
    },
    tableRow: {
        '&$selected, &$selected:hover': {
            backgroundColor: '#e4e1fe'
        }
    },
    selected: {},
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    checkbox: {
        color: '#8783b5'
    },
    representAction: {
        'font-size': '8px',
        'margin-right': '5px',
        color: 'cornflowerblue',
        textTransform: 'none'
    },
    acceptAction: {
        'font-size': '8px',
        color: 'green',
        textTransform: 'none'
    }
}));

export default function ChargebacksListTableComponent(props) {
    const { globalState } = useGlobalStateContext();
    const classes = useStyles();
    const {
        rows,
        statusFilter,
        setStatusFilter,
        checkIsExpanded,
        toggleExpandTheRow,
        order,
        orderBy,
        loading,
        error,
        totalChargeback,
        page,
        setPage
    } = props;

    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className={classes.root}>
            <FilterTabs
                tabFilterValue={statusFilter}
                setTabFilterValue={setStatusFilter}
                tabList={ChargebacksFilterTabs}
            />
            <Paper className={classes.paper}>
                {loading ? (
                    <Box textAlign="center" py={5}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <div className={styles.paymentsNone}>An error occurred, please try reloading your browser.</div>
                ) : (
                    <React.Fragment>
                        {!rows || rows.length === 0 ? (
                            <div className={styles.paymentsNone}>There aren't any results for that query.</div>
                        ) : (
                            <React.Fragment>
                                <TableContainer>
                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size="small"
                                        stickyHeader
                                        aria-label="enhanced table"
                                    >
                                        <TableHeader
                                            classes={classes}
                                            numSelected={0}
                                            rowCount={rows.length}
                                            hideSelectAll={true}
                                            {...props}
                                        />
                                        <TableBody>
                                            {stableSort(
                                                rowsPerPage > 0
                                                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : rows,
                                                getComparator(order, orderBy)
                                            ).map((row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                const isExpanded = checkIsExpanded(row.id);
                                                return (
                                                    <React.Fragment key={index + '-fragment'}>
                                                        <TableRow
                                                            classes={{ selected: classes.selected }}
                                                            className={classes.tableRow + ' ' + styles.tableRow}
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={row.id}
                                                            onClick={(e) => toggleExpandTheRow(e, row.id)}
                                                        >
                                                            <TableCell
                                                                align="left"
                                                                component="th"
                                                                id={labelId}
                                                                scope="row"
                                                            >
                                                                {formatCurrency(
                                                                    row.total,
                                                                    getCurrencyThreeAlpha(globalState.merchant?.country)
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {formatDateTimeByFormatString(row.time, 'DD MMM HH:mm')}
                                                            </TableCell>
                                                            <TableCell>{row.address || '-'}</TableCell>
                                                            <TableCell>{row.cb_reason}</TableCell>
                                                        </TableRow>
                                                        {isExpanded && (
                                                            <TableRow
                                                                key={row.id + '-expanded-row'}
                                                                className={classes.tableRow}
                                                                classes={{ selected: classes.selected }}
                                                                onClick={(e) => toggleExpandTheRow(e, row.id)}
                                                            >
                                                                <TableCell colSpan={12}>
                                                                    <ChargebacksExpandedRow open={isExpanded} />
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })}
                                            <TableRow>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                {rows.length > rowsPerPage && (
                                                    <TablePagination
                                                        rowsPerPageOptions={[20, 50, 100, { label: 'All', value: -1 }]}
                                                        colSpan={6}
                                                        count={rows.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        SelectProps={{
                                                            inputProps: { 'aria-label': 'rows per page' },
                                                            native: true
                                                        }}
                                                        onChangePage={handleChangePage}
                                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                                    />
                                                )}
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                                <TableFooterComponent total={totalChargeback()} resultsCount={rows.length} />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </Paper>
        </div>
    );
}

ChargebacksListTableComponent.propTypes = {
    rows: PropTypes.array.isRequired,
    statusFilter: PropTypes.string.isRequired,
    setStatusFilter: PropTypes.func.isRequired,
    checkIsExpanded: PropTypes.func.isRequired,
    toggleExpandTheRow: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    totalChargeback: PropTypes.func
};
