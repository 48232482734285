import { useGlobalStateContext } from 'contexts';
import React, { useEffect, useState } from 'react';
import { NonResellerMerchantService, TaxonomyService } from 'services';
import ManageProductsOmniPayComponent from './manage-products-omnipay.component';
import { ProductStatus } from 'utils/enums/ProductsRequired';
import _ from 'lodash';
import { UserType } from 'utils/enums/UserType';

export default function ManageProductsOmniPay() {
    const { globalState, setGlobalState } = useGlobalStateContext();
    const [products, setProducts] = useState([]);
    const [hasUpdatedProducts, setHasUpdatedProducts] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [productId, setProductId] = useState(0);
    const [approve, setApprove] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState({});
    const [errorMessage, setErrorMessage] = useState('');

    const merchantSelectedProducts = globalState.merchant?.merchantProductRequired;
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [isLoading, setIsLoading] = useState([]);
    const isAdmin =
        globalState.user?.UserType?.name === UserType.Admin || globalState.user?.UserType?.name === UserType.SuperAdmin;
    const imgPaths = [
        require('../../../assets/icons/gateway.svg'),
        require('../../../assets/icons/my-pay-app.svg'),
        require('../../../assets/icons/virtual-terminal.png'),
        require('../../../assets/icons/my-ecomm.svg'),
        require('../../../assets/icons/card-machine.svg')
    ];

    const merchantProducts = async (items) => {
        setProducts(JSON.parse(JSON.stringify(items)));
    };

    useEffect(() => {
        const getProducts = async () => {
            setIsLoading(true);
            const productsRequest = await TaxonomyService.getProductsRequired();
            if (productsRequest.isSuccesfully) {
                const allProducts = productsRequest.data;
                allProducts.forEach((element, index) => {
                    element.imgPath = imgPaths[index];
                    let product = merchantSelectedProducts.find((p) => p.id === element.id);

                    if (product) {
                        element.status = product.status;
                        element.additionalInfo = product.additionalInfo;
                    } else {
                        element.status = ProductStatus.INACTIVE;
                        element.additionalInfo = null;
                    }
                });

                const mypayProducts = _.pullAt(allProducts, [0, 1, 2, 4]);
                await merchantProducts(mypayProducts);
                setSelectedProducts(mypayProducts);
            }

            setIsLoading(false);
        };

        getProducts();
        // eslint-disable-next-line
    }, [globalState.merchant]);

    useEffect(() => {
        const hasSelectedProducts = async () => {
            setHasUpdatedProducts(true);
        };
        hasSelectedProducts();
    }, [selectedProducts]);

    const onSubmit = async () => {
        const updatedProduct = selectedProducts.filter((el) => {
            return products.some((f) => {
                return f.status !== el.status && f.id === el.id;
            });
        });

        if (hasUpdatedProducts || updatedProduct.length === 0) {
            if (updatedProduct) {
                setIsLoading(true);
                if (!isAdmin) {
                    await NonResellerMerchantService.updateProducts(globalState.merchant?.id, {
                        updatedProduct,
                        resellerId: globalState.reseller.id
                    });
                }
                const data = {
                    productsRequiredIds: selectedProducts,
                    resellerId: globalState.reseller.id
                };
                const response = await NonResellerMerchantService.completeOnboardingStep(
                    globalState.merchant?.id,
                    7,
                    data
                );

                if (response.isSuccesfully) {
                    const updated = [...selectedProducts];
                    await merchantProducts(updated);
                } else {
                    const notUpdated = [...products];
                    setSelectedProducts(JSON.parse(JSON.stringify(notUpdated)));
                    setErrorMessage('An error occurred. Try again !');
                }

                setGlobalState((globalState) => ({
                    ...globalState,
                    shouldLoadMerchants: !globalState.shouldLoadMerchants
                }));

                setIsLoading(false);
            }
        }
    };

    return (
        <ManageProductsOmniPayComponent
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            onSubmit={onSubmit}
            isLoading={isLoading}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            productId={productId}
            setProductId={setProductId}
            approve={approve}
            setApprove={setApprove}
            selectedEntity={selectedEntity}
            setSelectedEntity={setSelectedEntity}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
        />
    );
}
