import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { ValidationMessage } from 'models';
import { BankAccountLengthConstaints } from 'utils/enums/BankAccountLength';

export function bankValidationMap(country, newAccountNumber) {
    var minAccountNumberDigits;
    var maxAccountNumberDigits;
    var isMandatory = true;
    var isNumeric = false;

    switch (country) {
        case MerchantCountries.UNITED_KINGDOM:
            minAccountNumberDigits = 8;
            maxAccountNumberDigits = 8;
            isNumeric = true;

            break;

        case MerchantCountries.NEW_ZEALAND:
            minAccountNumberDigits = 15;
            maxAccountNumberDigits = 16;
            isNumeric = true;

            break;

        case MerchantCountries.AUSTRALIA:
            minAccountNumberDigits = 5;
            maxAccountNumberDigits = 9;
            isNumeric = false;
            break;

        case MerchantCountries.IRELAND:
            minAccountNumberDigits = 22;
            maxAccountNumberDigits = 22;
            isNumeric = false;
            break;

        case MerchantCountries.UNITED_STATES:
            minAccountNumberDigits = BankAccountLengthConstaints[MerchantCountries.UNITED_STATES].minLength;
            maxAccountNumberDigits = BankAccountLengthConstaints[MerchantCountries.UNITED_STATES].maxLength;
            isNumeric = true;
            break;

        case MerchantCountries.CANADA:
            minAccountNumberDigits = 7;
            maxAccountNumberDigits = 12;
            isNumeric = true;
            break;

        case MerchantCountries.MEXICO:
            minAccountNumberDigits = 18;
            maxAccountNumberDigits = 18;
            isNumeric = false;
            break;
        default:
            return new ValidationMessage('', true);
    }

    if (newAccountNumber && newAccountNumber.length > 0) {
        if (
            !isMandatory ||
            (isMandatory &&
                newAccountNumber &&
                newAccountNumber.length >= minAccountNumberDigits &&
                newAccountNumber.length <= maxAccountNumberDigits &&
                (isNumeric || !isNumeric))
        ) {
            return new ValidationMessage('', true);
        } else {
            let outOfNumberOfCharactersLabel =
                minAccountNumberDigits === maxAccountNumberDigits
                    ? maxAccountNumberDigits
                    : `${minAccountNumberDigits} to ${maxAccountNumberDigits}`;
            return new ValidationMessage(
                'Please Enter ' +
                    outOfNumberOfCharactersLabel +
                    (isNumeric ? ' digit' : ' characters') +
                    '  Account Number',
                false
            );
        }
    } else {
        if (isMandatory) {
            return new ValidationMessage('', false);
        }
        return new ValidationMessage('', true);
    }
}
