import { AuthAxios, DatmanAxios } from './interceptor.service.js';
import { VirtualTerminalEndpoint, DatmanAPIEndpoint } from 'utils/constants';
import moment from 'moment';
import { SuccesResponse, ErrorResponse } from 'models';

async function sendPayByLinkEmail({
    email,
    amount,
    description,
    portalURL,
    merchantName,
    currencySymbol,
    currencyCode,
    payvia,
    sessionId
}) {
    try {
        if (sessionId) {
            //that means it is DNA Client
            const result = await AuthAxios.post(`${VirtualTerminalEndpoint}/send-pay-by-link-email`, {
                email,
                amount,
                description,
                portalURL,
                merchantName,
                currencySymbol,
                requestSendAt: moment().format('DD/MMM/YYYY'),
                payvia,
                sessionId
            });
            return {
                isSuccessfully: true,
                message: result.message
            };
        }

        const sessionResult = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/session/create`, {
            amount: amount * 100, //Datman Portal API divides the value by 100 so multiplying here
            currency_code: currencyCode,
            user_order_ref: '',
            description: description,
            items: [],
            shoppers: {
                first_name: '',
                last_name: '',
                email: email,
                address: ''
            }
        });

        if (!sessionResult?.data?.data?.session_id) {
            throw new Error('No session id. Something went wrong. Please contact support.');
        }

        const result = await AuthAxios.post(`${VirtualTerminalEndpoint}/send-pay-by-link-email`, {
            email,
            amount,
            description,
            portalURL,
            merchantName,
            sessionId: sessionResult.data.data.session_id,
            currencySymbol,
            requestSendAt: moment().format('DD/MMM/YYYY'),
            payvia
        });

        return {
            isSuccessfully: true,
            message: result.message
        };
    } catch (error) {
        return {
            isSuccessfully: false,
            message: error.response?.data?.message || error.message
        };
    }
}

async function sendInvoiceViaEmailPhone(payload) {
    try {
        const result = await AuthAxios.post(`${VirtualTerminalEndpoint}/send-payment-invoice/${payload.merchantId}`, {
            ...payload
        });
        return new SuccesResponse(result?.data?.message || 'Invoice Sent Successfully');
    } catch (error) {
        return new ErrorResponse(error.response?.data?.message || error.message);
    }
}

export default {
    sendPayByLinkEmail,
    sendInvoiceViaEmailPhone
};
