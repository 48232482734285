import { AuthAxios } from './interceptor.service.js';
import { UserServiceEndpoint } from 'utils/constants';
import { SuccesResponse, ErrorResponse } from 'models';

async function getUsers() {
    try {
        const requestResult = await AuthAxios.get(`${UserServiceEndpoint}`);
        return new SuccesResponse(requestResult.data);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function createUser(user) {
    try {
        await AuthAxios.post(`${UserServiceEndpoint}`, user);
        return new SuccesResponse();
    } catch (error) {
        return new ErrorResponse(error.response.data);
    }
}

async function getUserByEmail(email) {
    try {
        const result = await AuthAxios.get(`${UserServiceEndpoint}/email/${email}`);
        return {
            isSuccessfully: true,
            user: result.data[0]
        };
    } catch (error) {
        if (error.response && error.response.status === 404) {
            return {
                isSuccessfully: true,
                user: null
            };
        }
        return {
            isSuccessfully: false,
            message: error.message
        };
    }
}

async function toggleDisableEnableUser(user) {
    try {
        const requestResult = await AuthAxios.patch(`${UserServiceEndpoint}/toggleDisableEnable/${user.email}`, {
            isDisable: user.isDisable
        });
        return new SuccesResponse(requestResult.data);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function deleteUser(email) {
    try {
        const requestResult = await AuthAxios.delete(`${UserServiceEndpoint}/delete/${email}`);
        return new SuccesResponse(requestResult.data);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function supportUpdateUserAttributes(params) {
    try {
        const requestResult = await AuthAxios.post(`${UserServiceEndpoint}/update`, params);
        return new SuccesResponse(requestResult.data);
    } catch (err) {
        const errMessage = err.response && err.response.data ? err.response.data.error : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function saveReferralDataString(data) {
    try {
        await AuthAxios.post(`${UserServiceEndpoint}/save-user-referral-data`, data);
        return new SuccesResponse();
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function getUserByCognitoUserName(cognitoUuid) {
    try {
        const result = await AuthAxios.get(`${UserServiceEndpoint}/get-user/${cognitoUuid}`);
        return {
            isSuccessfully: true,
            user: result.data[0]
        };
    } catch (error) {
        if (error.response && error.response.status === 404) {
            return {
                isSuccessfully: true,
                user: null
            };
        }
        return {
            isSuccessfully: false,
            message: error.message
        };
    }
}

async function updateUserDetails(params) {
    try {
        const result = await AuthAxios.post(`${UserServiceEndpoint}/update-user-details`, params);
        return {
            isSuccessfully: true,
            user: result
        };
    } catch (error) {
        if (error.response && error.response.status === 404) {
            return {
                isSuccessfully: true,
                user: null
            };
        }
        return {
            isSuccessfully: false,
            message: error.message
        };
    }
}

export default {
    getUsers,
    getUserByEmail,
    createUser,
    toggleDisableEnableUser,
    deleteUser,
    supportUpdateUserAttributes,
    saveReferralDataString,
    getUserByCognitoUserName,
    updateUserDetails
};
