import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CardMultiSelectDatmanComponent from './card-multi-select-datman.component';

/**
 *
 * @param {{
 * list: array;
 * onChange: func;
 * value: array;
 * }} props
 */
function CardMultiSelectDatman(props) {
    const [selectedEntities, setSelectedEntitites] = useState([]);

    useEffect(() => {
        setSelectedEntitites(props.value ? props.value : []);
    }, [props.value]);

    return (
        <CardMultiSelectDatmanComponent
            {...props}
            selectedEntities={selectedEntities}
            setSelectedEntitites={setSelectedEntitites}
        />
    );
}

CardMultiSelectDatman.propTypes = {
    value: PropTypes.array
};

export default CardMultiSelectDatman;
