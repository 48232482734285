import React, { useState, useEffect } from 'react';
import ModalMoveTransaction from './modal-move-transaction.compnent';
import PaymentsService from 'services/payments.service';
import moment from 'moment';

const ModalMoveTxns = (props) => {
    const { openModal, setOpenModal, setSnackbar } = props;
    const [loading, setLoading] = useState(false);
    const [showCharges, setShowCharges] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [disable, setDisable] = useState(true);
    const [newMid, setNewMid] = useState('');
    const handleClose = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        toDate !== null && fromDate !== null && toDate !== '' && fromDate !== '' && fromDate < toDate && newMid
            ? setDisable(true)
            : setDisable(false);
    }, [newMid, toDate, fromDate]);

    const onConfirm = async () => {
        const payload = {
            start_date: moment(fromDate).format('YYYY-MM-DD HH:mm:ss'),
            end_date: moment(toDate).format('YYYY-MM-DD HH:mm:ss'),
            new_mid: newMid
        };
        setLoading(true);
        const response = await PaymentsService.MoveTransactions(payload);
        if (response.isSuccesfully) {
            setSnackbar({ type: 'success', message: response.data?.message });
        } else {
            setSnackbar({ type: 'error', message: response.data?.message });
        }

        setLoading(false);
        handleClose();
    };

    return (
        <ModalMoveTransaction
            {...props}
            loading={loading}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            handleClose={handleClose}
            disable={disable}
            setDisable={setDisable}
            onConfirm={onConfirm}
            showCharges={showCharges}
            setShowCharges={setShowCharges}
            openModal={openModal}
            newMid={newMid}
            setNewMid={setNewMid}
        />
    );
};

export default ModalMoveTxns;
