import { Auth, Hub } from 'aws-amplify';
import browserHistory from 'browserHistory';
import React, { useEffect, useState, useRef } from 'react';
import { AuthService, CanonicalResellerService, TaxonomyService } from 'services';
import regexRoutes from '../../routing/regex-routes';
import { parse } from 'query-string';
import { Cache } from 'aws-amplify';
import { UserType } from 'utils/enums/UserType';
import { UNDERMAINTAINANCE, AdminLogoutTime } from 'utils/constants';
const GlobalStateContext = React.createContext();
const userStore = localStorage.getItem('user');
const merchantStore = sessionStorage.getItem('merchant');
const resellerStore = localStorage.getItem('reseller');
const customDomainStore = localStorage.getItem('customDomain');
let user, merchant, reseller, customDomain;

user = userStore && JSON.parse(userStore);
merchant = merchantStore && JSON.parse(merchantStore);
reseller = resellerStore && JSON.parse(resellerStore);
customDomain = customDomainStore;

/**
 * @type {{
 * user: {
 * id: number,
 * email: string,
 * firstName: string,
 * lastName: string,
 * isDeleted: boolean
 * isDisable: boolean,
 * isEmailConfirmed: boolean,
 * phoneNumber: string,
 * pictureUrl: string,
 * typeId: number,
 * UserType: {
 *  name: string
 * }
 * },
 * merchant: {
 * country: string
 * createdAt: string
 * id: number
 * internalTransferStatus: boolean
 * isClosureRequested: boolean
 * label: string
 * merchantProductRequired: []
 * name: string
 * nameWithAddress: string
 * onboardingStep: number
 * postCode: string
 * status: number
 * userRole: string
 * },
 * reseller: {
 * faviconLink: string
 * helpPageURL: string
 * id: number
 * logo: string
 * name: string
 * portalTitle: string
 * portalURL: string
 * termAndCondPageURL: string
 * },
 * areLoadingMerchants: boolean,
 * isT2SMerchant: bool
 * }}
 */
const initialState = {
    user: user,
    merchant: merchant,
    reseller: window.location.href.includes(process.env.REACT_APP_CRM_NAME)
        ? JSON.parse(process.env.REACT_APP_CONFIG_SERVICE_ENDPOINT)[1]
        : JSON.parse(process.env.REACT_APP_CONFIG_SERVICE_ENDPOINT)[0],
    areLoadingMerchants: false,
    isT2SMerchant: window.location.href.includes(process.env.REACT_APP_CRM_NAME) ? true : false,
    customDomain: customDomain === 'true' ? true : false,
    merchantAcquirer: { acquirer: null, updatedAt: new Date() }
};

const nonLoggedInRoute = ['terms-and-conditions', 'maintenance', 'signup', 'forgot-password'];

function GlobalStateProvider(props) {
    const [globalState, setGlobalState] = useState(initialState);
    const storedUser = localStorage.user;
    const userType = storedUser ? JSON.parse(storedUser)?.UserType?.name : null;
    const isAdmin = userType === UserType.Admin || userType === UserType.SuperAdmin;
    const timeoutRef = useRef(null);
    console.log('window');

    // Function to logout the user after inactivity timeout
    function logoutUser() {
        if (isAdmin) {
            AuthService.logout();
            Auth.signOut({ global: true }).then(() => {
                setGlobalState({ ...globalState, user: null, merchant: null });
            });
        }
        // Perform logout action here (e.g., redirect to logout page)
    }

    // Function to handle user activity (e.g., called on mousemove, keydown, etc.)
    function handleUserActivity() {
        if (isAdmin) {
            clearTimeout(timeoutRef.current); // Clear existing timeout
            timeoutRef.current = setTimeout(logoutUser, AdminLogoutTime); // Set new timeout
            // Update localStorage to communicate with other tabs
            localStorage.setItem('lastActivity', Date.now());
        }
    }

    function storageChange(event) {
        if (
            !localStorage.user &&
            !window.location.pathname.includes('/login') &&
            !nonLoggedInRoute.some((pathname) => window.location.pathname.includes(pathname))
        ) {
            setReplaceRoute('/login');
        }

        if (localStorage.lastActivity && isAdmin) {
            handleUserActivity();
        }
    }
    window.addEventListener('storage', storageChange, false);

    useEffect(() => {
        if (isAdmin) {
            window.addEventListener('mousemove', handleUserActivity);
            window.addEventListener('keydown', handleUserActivity);

            handleUserActivity();
            return () => {
                clearTimeout(timeoutRef.current);
                window.removeEventListener('mousemove', handleUserActivity);
                window.removeEventListener('keydown', handleUserActivity);
            };
        }
    });

    useEffect(() => {
        async function getReseller() {
            var url = window.location.href;
            const resellerUrl = await getResellerUrl();

            const urlParams = parse(url);
            //Google or Facebook -  do nothing
            if (urlParams && urlParams.state) {
                return;
            }
            if (resellerUrl !== globalState.reseller?.portalURL) {
                if (resellerUrl) {
                    const responseReseller = await TaxonomyService.getResellerByPortalURL(resellerUrl);
                    if (responseReseller.isSuccesfully) {
                        setTitleAndIcon(responseReseller.data);
                        localStorage.setItem('reseller', JSON.stringify(responseReseller.data));
                        setGlobalState({
                            ...globalState,
                            reseller: responseReseller.data,
                            isT2SMerchant: window.location.href.includes(process.env.REACT_APP_CRM_NAME) ? true : false
                        });
                        if (globalState.user) {
                            AuthService.logout();
                            Auth.signOut({ global: true }).then(() => {
                                setRoute('/');
                            });
                        }
                    }
                } else {
                    setTitleAndIcon(globalState.reseller);
                    setRoute('/');
                }
            } else if (!globalState.reseller?.portalURL) {
                const response = await TaxonomyService.getResellers();

                if (response.isSuccesfully) {
                    const responseReseller = response.data[0];
                    setTitleAndIcon(responseReseller);
                    localStorage.setItem('reseller', JSON.stringify(responseReseller));
                    setGlobalState({
                        ...globalState,
                        reseller: responseReseller,
                        isT2SMerchant: window.location.href.includes(process.env.REACT_APP_CRM_NAME) ? true : false
                    });
                    setRoute('/');
                }
            } else {
                setTitleAndIcon(globalState.reseller);
            }
        }

        getReseller();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        async function getCanonicalResellers() {
            const isCanonicalResellerUser = globalState.user?.UserType?.name === UserType.Reseller;
            setGlobalState({
                ...globalState,
                canonicalReseller: null
            });
            if (isCanonicalResellerUser && globalState.user) {
                let canonicalResellers = await CanonicalResellerService.getCanonicalResellers();

                setGlobalState({
                    ...globalState,
                    canonicalReseller: canonicalResellers[0]
                });
            }
        }

        getCanonicalResellers();
        // eslint-disable-next-line
    }, [globalState.user]);

    useEffect(() => {
        Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) {
                case 'cognitoHostedUI': {
                    if (data.username.startsWith('Google') || data.username.startsWith('Facebook')) {
                        setTimeout(() => {
                            Hub.dispatch('SignupSocial', {
                                event: 'start',
                                data: data,
                                message: ''
                            });
                        }, 1000);
                    }
                    break;
                }
                case 'customOAuthState': {
                    const decodedURI = decodeURIComponent(data);
                    const portalUrl = decodedURI.includes('&&&')
                        ? decodedURI.substring(0, decodedURI.indexOf('&&&'))
                        : decodedURI;
                    if (decodedURI.includes('&&&')) {
                        Cache.setItem(
                            'thirdPartyResellerUrlParameter',
                            decodedURI.substring(decodedURI.indexOf('&&&') + 3),
                            { priority: 5 }
                        );
                    }

                    TaxonomyService.getResellerByPortalURL(portalUrl).then((response) => {
                        localStorage.setItem('reseller', JSON.stringify(response.data));
                        setGlobalState({ ...globalState, reseller: response.data });
                        setRoute('/');
                    });

                    break;
                }
                default: {
                }
            }
        });
        // eslint-disable-next-line
    }, []);

    const setRoute = (url, state) => {
        url = getRoute(url);
        browserHistory.push(url, state);
    };

    const setReplaceRoute = (url, state) => {
        url = getRoute(url);
        browserHistory.replace(url, state);
    };

    const getRoute = (url) => {
        const regex = regexRoutes;
        const isRoute = regex.test(url);
        if (!isRoute) {
            url = '/';
        }
        if (JSON.parse(localStorage.getItem('customDomain')) === false) {
            url = '/' + JSON.parse(localStorage.getItem('reseller'))?.portalURL + url;
        }
        return url;
    };

    const getResellerUrl = async () => {
        const response = await TaxonomyService.getResellers();
        let resellers;
        if (response.isSuccesfully) {
            resellers = response.data;
        }

        const resDomain = resellers.find((res) => {
            return window.location.host.includes(res.portalURL);
        });
        if (resDomain) {
            localStorage.setItem('customDomain', true);
            setGlobalState({
                ...globalState,
                customDomain: true,
                maintainance: UNDERMAINTAINANCE ? UNDERMAINTAINANCE : false
            });
            return resDomain.portalURL;
        } else {
            const reseller = resellers.find((res) => {
                if (window.location.pathname.length > 1) {
                    //Exclude first / from parameter
                    if (window.location.pathname.substring(1).startsWith(res.portalURL)) {
                        return res;
                    }
                }
                return null;
            });
            localStorage.setItem('customDomain', false);
            setGlobalState({ ...globalState, customDomain: false });
            return reseller?.portalURL;
        }
    };

    const onGoToAccount = (merchantId) => {
        let routeToGo = getRoute(`/admin-functions/${merchantId}`);
        window.open(routeToGo, '_blank');
    };

    return (
        <GlobalStateContext.Provider
            value={{ globalState, setGlobalState, setRoute, getRoute, setReplaceRoute, onGoToAccount }}
        >
            {props.children}
        </GlobalStateContext.Provider>
    );
}

/**
 * @returns { {globalState: initialState, setGlobalState: (state: initialState) => {}} } [globalState, setGlobalStete]
 */
function useGlobalStateContext() {
    const globalStateContext = React.useContext(GlobalStateContext);
    if (!globalStateContext) {
        throw new Error('useGlobalStateContext must be used within a GlobalStateContext');
    }
    return globalStateContext;
}

/**
 * @param {{
 * portalURL: string
 * }} reseller
 */
function setTitleAndIcon(reseller) {
    document.title = reseller.portalTitle ?? 'Customer portal';

    if (reseller.faviconLink) {
        let link = document.querySelector('link[rel="shortcut icon"]') || document.querySelector('link[rel="icon"]');
        link.href = reseller.faviconLink;
    }
}

export { GlobalStateProvider, useGlobalStateContext };
