import React from 'react';
import PropTypes from 'prop-types';
import style from './adyen-merchant-list.module.scss';
import { getComparator, stableSort } from '../../../utils/helper';
import { AuthService } from 'services';
import { Redirect } from 'react-router-dom';
import { useGlobalStateContext } from 'contexts';
import { AdyenAccountStatus, AdyenAccountCategory, AdyenTier } from 'utils/enums/AdyenVariables';
import TableHeader from '../../payments/transaction-tables/table-header/table-header.container.js';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { SnackBarAndAlert } from 'components';
import {
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableContainer,
    Paper,
    CircularProgress,
    TableFooter,
    TablePagination,
    Box,
    makeStyles,
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    Input
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    tableCellSmallSize: {
        '& td': {
            minWidth: '230px',
            maxWidth: '230px'
        },
        '& th': {
            minWidth: '230px',
            maxWidth: '230px'
        }
    },
    noData: {
        textAlign: 'center',
        paddingBottom: '100px',
        paddingTop: '100px'
    },
    tableRow: {
        '&$selected, &$selected:hover': {
            backgroundColor: '#e4e1fe'
        }
    },
    headWidth: {
        minWidth: '210px'
    }
}));

function AdyenMerchantListComponent(props) {
    const {
        loading,
        error,
        page,
        rowsPerPage,
        rows,
        handleChangePage,
        handleChangeRowsPerPage,
        count,
        order,
        orderBy,
        setInputValue,
        onSearchMerchants,
        open,
        setOpen,
        adyenAccountStatus,
        setAdyenAccountStatus,
        adyenCategory,
        setAdyenCategory,
        handleCopyMerchantId,
        closeCopyAlert,
        isCopyAlertOpen,
        filteredTheAdyenMerchants
    } = props;

    const classes = useStyles();
    const { getRoute } = useGlobalStateContext();
    return (
        <React.Fragment>
            {AuthService.isLoggedUser() ? (
                <div className={style.container}>
                    <form className={style.search_form} onSubmit={onSearchMerchants}>
                        <TextField
                            className={style.searchMerchants}
                            id="merchants-search"
                            size="small"
                            label="Search"
                            type="search"
                            variant="outlined"
                            autoComplete="off"
                            onChange={(e) => setInputValue(e.target.value)}
                        />
                        <Button
                            id="merchants-search-button"
                            className={style.searchButton}
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={onSearchMerchants}
                        >
                            Search
                        </Button>
                        <FormControl variant="outlined" className={style.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label" className={style.inputlabel}>
                                Adyen account status
                            </InputLabel>
                            <Select
                                className={style.adeynFilter}
                                inputProps={{
                                    className: style.adyenFilterInputProps
                                }}
                                label="adyenAccountStatus"
                                value={adyenAccountStatus}
                                onChange={(e) => {
                                    setAdyenAccountStatus(e.target.value);
                                    filteredTheAdyenMerchants(e.target.value, adyenCategory);
                                }}
                                disableUnderline
                                id="adyen-account-status-filter"
                            >
                                {Object.keys(AdyenAccountStatus).map((accountStatus) => (
                                    <MenuItem value={accountStatus}>{AdyenAccountStatus[accountStatus]}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" className={style.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label" className={style.inputlabel}>
                                Adyen category
                            </InputLabel>
                            <Select
                                className={style.adeynFilter}
                                inputProps={{
                                    className: style.adyenFilterInputProps
                                }}
                                label="adyenCategory"
                                value={adyenCategory}
                                onChange={(e) => {
                                    setAdyenCategory(e.target.value);
                                    filteredTheAdyenMerchants(adyenAccountStatus, e.target.value);
                                }}
                                id="adyen-category-filter"
                            >
                                {Object.keys(AdyenAccountCategory).map((category) => (
                                    <MenuItem value={category}>{AdyenAccountCategory[category]}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </form>
                    <Paper className={style.TableContainer}>
                        {loading ? (
                            <Box textAlign="center" py={5}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <React.Fragment>
                                <TableContainer>
                                    <Table
                                        aria-label="simple table"
                                        aria-labelledby="tableTitle"
                                        size="small"
                                        stickyHeader
                                        component={Paper}
                                        sx={{ minWidth: 650 }}
                                        className={classes.tableCellSmallSize}
                                    >
                                        <TableHeader classes={classes} {...props} />
                                        <React.Fragment>
                                            {error ? (
                                                <div className={style.noneData}>
                                                    An error occurred, please try reloading your browser.
                                                </div>
                                            ) : count === 0 ? (
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell
                                                            className={classes.noData}
                                                            colSpan={8}
                                                            align="center"
                                                        >
                                                            No Records found
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            ) : (
                                                <TableBody>
                                                    {stableSort(
                                                        rowsPerPage > 0
                                                            ? rows.slice(
                                                                  page * rowsPerPage,
                                                                  page * rowsPerPage + rowsPerPage
                                                              )
                                                            : rows,
                                                        getComparator(order, orderBy)
                                                    ).map((row, index) => {
                                                        return (
                                                            <React.Fragment>
                                                                <TableRow
                                                                    classes={{ selected: classes.selected }}
                                                                    style={{
                                                                        backgroundColor:
                                                                            open === index ? 'rgb(244, 244, 244)' : ''
                                                                    }}
                                                                    onClick={(e) => {
                                                                        setOpen(open === index ? '' : index);
                                                                    }}
                                                                    key={row.id}
                                                                    hover
                                                                    className={style.rowstyle}
                                                                >
                                                                    <TableCell className={style.tableDataCell}>
                                                                        <Grid
                                                                            container
                                                                            spacing={2}
                                                                            className={style.idContainer}
                                                                        >
                                                                            <Grid item xs={9}>
                                                                                <Input
                                                                                    inputProps={{
                                                                                        className: style.textInput
                                                                                    }}
                                                                                    value={row.merchantId}
                                                                                    disableUnderline
                                                                                />
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                xs={3}
                                                                                container
                                                                                alignItems="center"
                                                                                className={style.copyIconContainer}
                                                                            >
                                                                                <FileCopyIcon
                                                                                    className={style.copyIcon}
                                                                                    onClick={() =>
                                                                                        handleCopyMerchantId(
                                                                                            row.merchantId
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </TableCell>
                                                                    <TableCell className={style.tableDataCell}>
                                                                        {AdyenAccountStatus[row.accountStatus]}
                                                                    </TableCell>
                                                                    {/* <TableCell className={style.tableDataCell}>
                                                                        {AdyenPayoutStatus[row.payoutStatus]}
                                                                    </TableCell> */}
                                                                    <TableCell className={style.tableDataCell}>
                                                                        {AdyenTier[row.adyenTier]}
                                                                    </TableCell>
                                                                    <TableCell>{row.totalPayout}</TableCell>
                                                                </TableRow>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </TableBody>
                                            )}
                                        </React.Fragment>
                                        <TableFooter>
                                            {count > 0 && (
                                                <TableRow>
                                                    <TablePagination
                                                        rowsPerPageOptions={[20, 50, 100]}
                                                        colSpan={8}
                                                        count={count}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        SelectProps={{
                                                            inputProps: { 'aria-label': 'rows per page' },
                                                            native: true
                                                        }}
                                                        onChangePage={handleChangePage}
                                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                                    />
                                                </TableRow>
                                            )}
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </React.Fragment>
                        )}
                    </Paper>
                    {isCopyAlertOpen && (
                        <SnackBarAndAlert open={isCopyAlertOpen} onClose={closeCopyAlert} type={'success'}>
                            Successfully copied the merchant id.
                        </SnackBarAndAlert>
                    )}
                </div>
            ) : (
                <Redirect to={getRoute(`/login`)} />
            )}
        </React.Fragment>
    );
}

AdyenMerchantListComponent.propTypes = {
    loading: PropTypes.bool,
    rows: PropTypes.array.isRequired,
    error: PropTypes.bool,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    count: PropTypes.number,
    setInputValue: PropTypes.func,
    onSearchMerchants: PropTypes.func,
    setOpen: PropTypes.func
};

export default AdyenMerchantListComponent;
