import React from 'react';
import { Grid, Box, Paper } from '@material-ui/core';
import { PageHeader, Calendar, PaymentsList, DatmanResellerPaymentsList, SnackBarAndAlert, Banner } from 'components';
import WithdrawalContainer from '../withdrawal/withdrawal.container';
import style from './payments.module.scss';
import PropTypes from 'prop-types';
import { merchantTypes } from 'utils/helper.js';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { useGlobalStateContext } from 'contexts';
import { bannerMessage } from 'utils/banner-message';

const TransactionListComponent = (props) => {
    const { globalState } = useGlobalStateContext();

    const { date: dateFromUrlParams, via: viaFromUrlParams } = props.match.params;
    const { pathname } = props.location;
    const showMerchantName = merchantTypes.includes(globalState.merchant?.merchantType);
    const isMerchantAccountClosed = globalState.merchant?.status === MerchantStatus.CLOSED;

    const {
        dates,
        onChangeDateRange,
        daterange,
        offrange,
        setOffrange,
        showAcquirer,
        startDate,
        endDate,
        daysRange,
        setDaysRange,
        acquirer,
        setAcquirer,
        acquirerName,
        snackbar,
        setSnackbar,
        isDateRangeArchived
    } = props;

    return (
        <div>
            <Grid item xs={12}>
                {isMerchantAccountClosed && <Banner message={bannerMessage.CLOSED_MERCHANT_ACCOUNT} />}
                <WithdrawalContainer />
                <Box mt={3}>
                    <Calendar
                        {...props}
                        path="payments"
                        range={viaFromUrlParams}
                        current={pathname}
                        date={dateFromUrlParams}
                        onChangeDateRange={onChangeDateRange}
                        daterange={daterange}
                        offrange={offrange}
                        setOffrange={setOffrange}
                        setDaysRange={setDaysRange}
                    />
                </Box>
                <Paper className={style.paymentsContainer}>
                    <PageHeader
                        {...props}
                        title={`Payments`}
                        via={viaFromUrlParams}
                        path="payments"
                        date={dateFromUrlParams}
                        showFilterButton={false}
                        onAcquirerChange={setAcquirer}
                        channelFilter={props.channelFilter}
                        setChannelFilter={props.setChannelFilter}
                        onChangeDateRange={onChangeDateRange}
                        offrange={offrange}
                        setOffrange={setOffrange}
                        startDate={startDate}
                        endDate={endDate}
                        daysRange={daysRange}
                        selectedAcquirer={acquirer}
                    />
                    {showMerchantName ? (
                        <DatmanResellerPaymentsList
                            {...props}
                            path="payments"
                            selectedAcquirer={acquirer}
                            dates={dates}
                            daterange={daterange}
                            showAcquirer={showAcquirer}
                            acquirerName={acquirerName}
                            onAcquirerChange={setAcquirer}
                        />
                    ) : (
                        <PaymentsList
                            {...props}
                            path="payments"
                            selectedAcquirer={acquirer}
                            dates={dates}
                            daterange={daterange}
                            showAcquirer={showAcquirer}
                            onAcquirerChange={setAcquirer}
                            acquirerName={acquirerName}
                            isDateRangeArchived={isDateRangeArchived}
                        />
                    )}
                </Paper>
                <SnackBarAndAlert
                    onClose={() => setSnackbar({ type: '', message: '' })}
                    open={snackbar.message}
                    type={snackbar.type}
                >
                    {snackbar.message}
                </SnackBarAndAlert>
            </Grid>
        </div>
    );
};

export default TransactionListComponent;

TransactionListComponent.propTypes = {
    onChangeDateRange: PropTypes.func,
    dates: PropTypes.array,
    daterange: PropTypes.object,
    setClear: PropTypes.func,
    offrange: PropTypes.bool,
    setOffrange: PropTypes.func,
    setDaysRange: PropTypes.func
};
