import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { GlobalStateProvider } from 'contexts';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { LanguageContext } from './contexts/language-switch-context/language-switch-context';
import clevertap from 'clevertap-web-sdk';
import { CLEVERTAP_ID } from 'utils/constants';
import { Cache } from 'aws-amplify';

clevertap.init(CLEVERTAP_ID);
if (
    process.env.REACT_APP_ENV &&
    (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'staging')
) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_ENV
    });
}
Cache.setItem('resellers', JSON.parse(process.env.REACT_APP_CONFIG_SERVICE_ENDPOINT), { priority: 1 });
localStorage.setItem(
    'reseller',
    window.location.href.includes(process.env.REACT_APP_CRM_NAME)
        ? JSON.stringify(JSON.parse(process.env.REACT_APP_CONFIG_SERVICE_ENDPOINT)[1])
        : JSON.stringify(JSON.parse(process.env.REACT_APP_CONFIG_SERVICE_ENDPOINT)[0])
);
ReactDOM.render(
    <React.StrictMode>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <GlobalStateProvider>
            <LanguageContext>
                <App />
            </LanguageContext>
        </GlobalStateProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
