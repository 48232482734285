import React from 'react';
import AddSpecialRentComponent from './modal-add-special-rent.component';
import PropTypes from 'prop-types';

function AddSpecialRent(props) {
    const {
        addSpecialRent,
        setAddSpecialRent,
        specialRentDetails,
        setSpecialRentDetails,
        specialRentDelete,
        setSpecialRentDelete,
        rentDeleteConfirm,
        submitSpecialRent,
        updateSpecialRent,
        setUpdateSpecialRent,
        updateSpecialRentData,
        setDeleteRentId,
        disableBtn,
        deleteSpecialRent
    } = props;

    const isMaxRentValue = (values) => {
        const { value, floatValue } = values;
        const MAX_AMOUNT = 999.0;

        if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
            return true;
        }
        if (value.charAt(0) === '0') {
            if (value.charAt(1) && value.charAt(1) !== '.') {
                return false;
            }
        }
        if (value > MAX_AMOUNT) {
            return false;
        }
        return true;
    };

    return (
        <AddSpecialRentComponent
            addSpecialRent={addSpecialRent}
            setAddSpecialRent={setAddSpecialRent}
            specialRentDetails={specialRentDetails}
            setSpecialRentDetails={setSpecialRentDetails}
            specialRentDelete={specialRentDelete}
            setSpecialRentDelete={setSpecialRentDelete}
            rentDeleteConfirm={rentDeleteConfirm}
            submitSpecialRent={submitSpecialRent}
            updateSpecialRent={updateSpecialRent}
            updateSpecialRentData={updateSpecialRentData}
            setUpdateSpecialRent={setUpdateSpecialRent}
            setDeleteRentId={setDeleteRentId}
            disableBtn={disableBtn}
            deleteSpecialRent={deleteSpecialRent}
            isMaxRentValue={isMaxRentValue}
        />
    );
}

export default AddSpecialRent;

AddSpecialRent.propTypes = {
    addSpecialRent: PropTypes.bool,
    setAddSpecialRent: PropTypes.func,
    specialRentDetails: PropTypes.bool,
    setSpecialRentDetails: PropTypes.func,
    specialRentDelete: PropTypes.bool,
    setSpecialRentDelete: PropTypes.func,
    rentDeleteConfirm: PropTypes.bool,
    setUpdateSpecialRent: PropTypes.func,
    setDeleteRentId: PropTypes.func,
    deleteSpecialRent: PropTypes.func
};
