import React from 'react';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { Box, Button, CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import { useAdminFunctionsContext, useGlobalStateContext } from 'contexts';
import styles from './merchant-details.module.scss';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import WithdrawalContainer from 'pages/withdrawal/withdrawal.container';
import { AccountStatus, ThirdPartyMerchantIdAndStoreId, PageHeader, Calendar, PaymentsList } from 'components';
import { Label } from 'components';
import { formatDateTimeByFormatString } from 'utils/helper';

function MerchantDetailsComponent(props) {
    const { setRoute } = useGlobalStateContext();
    const { adminFunctions } = useAdminFunctionsContext();
    const { pathname } = props.location;
    const {
        error,
        setError,
        isLoading,
        dates,
        onChangeDateRange,
        daterange,
        offrange,
        setOffrange,
        transactionsListingOptions,
        setTransactionsListingOptions,
        activatedAt,
        signedBy
    } = props;

    return (
        <div className={styles.container}>
            <Box pt={2} className={styles.backButtonBox}>
                <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    startIcon={<NavigateBeforeIcon />}
                    onClick={() => setRoute('/home')}
                >
                    <span>Back</span>
                </Button>
            </Box>
            <div className={styles.merchantName}>{adminFunctions.name}</div>
            <div>
                {error ? (
                    <Alert className={styles.alertError} onClose={() => setError(false)} severity="error">
                        An error occurred. Please contact support or try again.
                    </Alert>
                ) : (
                    <React.Fragment>
                        {isLoading ? (
                            <div className={styles.loading}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <div className={styles.detailsView}>
                                <WithdrawalContainer />
                                <Paper className={styles.mainContainer}>
                                    <Box className={styles.divSubTitle}>
                                        <Typography color="primary" component="span">
                                            <span className={styles.subTitle}>Merchant Details</span>
                                        </Typography>
                                    </Box>
                                    <Box m={2}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} sm={6}>
                                                <AccountStatus />
                                                <Grid item xs={12} className={styles.thirdId}>
                                                    <ThirdPartyMerchantIdAndStoreId />
                                                </Grid>
                                            </Grid>
                                            {signedBy && (
                                                <Grid item xs={12} sm={6}>
                                                    <Grid>
                                                        <Typography color="primary" component="span">
                                                            <span className={styles.subTitle}>Terms & conditions</span>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container style={{ marginTop: '10px' }}>
                                                        <Grid item xs={3} md={3}>
                                                            <Label>Signed by:&nbsp;</Label>
                                                        </Grid>
                                                        <Grid item xs={5} md={4}>
                                                            {signedBy}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container>
                                                        <Grid item xs={3} md={3}>
                                                            <Label>Date & time:&nbsp;</Label>
                                                        </Grid>
                                                        <Grid item xs={5} md={4}>
                                                            {formatDateTimeByFormatString(
                                                                activatedAt,
                                                                'DD MMM YY HH:mm'
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Box>
                                </Paper>
                                <Grid item xs={12}>
                                    <Box mt={3}>
                                        <Calendar
                                            {...props}
                                            path="payments"
                                            range={transactionsListingOptions.via}
                                            current={pathname}
                                            date={transactionsListingOptions.date}
                                            onChangeDateRange={onChangeDateRange}
                                            daterange={daterange}
                                            offrange={offrange}
                                            setOffrange={setOffrange}
                                            locationInApp={'merchant-details'}
                                            transactionsListingOptions={transactionsListingOptions}
                                            setTransactionsListingOptions={setTransactionsListingOptions}
                                        />
                                    </Box>
                                    <Paper className={styles.paymentsContainer}>
                                        <PageHeader
                                            {...props}
                                            title={`Payments`}
                                            via={transactionsListingOptions.via}
                                            path="payments"
                                            date={transactionsListingOptions.date}
                                            showFilterButton={false}
                                            channelFilter={props.channelFilter}
                                            setChannelFilter={props.setChannelFilter}
                                            onChangeDateRange={onChangeDateRange}
                                            offrange={offrange}
                                            setOffrange={setOffrange}
                                            locationInApp={'merchant-details'}
                                            transactionsListingOptions={transactionsListingOptions}
                                            setTransactionsListingOptions={setTransactionsListingOptions}
                                        />
                                        <PaymentsList
                                            {...props}
                                            dates={dates}
                                            daterange={daterange}
                                            transactionsListingOptions={transactionsListingOptions}
                                        />
                                    </Paper>
                                </Grid>
                            </div>
                        )}
                    </React.Fragment>
                )}
            </div>
        </div>
    );
}

MerchantDetailsComponent.propTypes = {
    error: PropTypes.bool,
    isLoading: PropTypes.bool,
    setError: PropTypes.func
};

export default MerchantDetailsComponent;
