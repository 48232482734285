import { useGlobalStateContext } from 'contexts';
import React, { useEffect, useState } from 'react';
import { NonResellerMerchantService, TaxonomyService } from 'services';
import ManageProductsDatmanComponent from './manage-products-datman.component';
import { ResellerType } from 'utils/enums/ResellerType';
import { ProductsRequired } from 'utils/enums/ProductsRequired';

export default function ManageProductsDatman() {
    const { globalState, setGlobalState, setRoute } = useGlobalStateContext();
    const [products, setProducts] = useState([]);
    const merchantSelectedProducts = globalState.merchant?.merchantProductRequired.map((product) => {
        return product.id;
    });
    const [selectedProducts, setSelectedProducts] = useState(merchantSelectedProducts);
    const [isLoading, setIsLoading] = useState([]);
    const imgPaths = [
        require('../../../assets/icons/gateway.svg'),
        require('../../../assets/icons/my-pay-app.svg'),
        require('../../../assets/icons/virtual-terminal.png'),
        require('../../../assets/icons/my-ecomm.svg'),
        require('../../../assets/icons/card-machine.svg')
    ];

    useEffect(() => {
        const getProducts = async () => {
            setIsLoading(true);
            const productsRequest = await TaxonomyService.getProductsRequired();
            if (productsRequest.isSuccesfully) {
                const products = productsRequest.data;
                products.forEach((element, index) => {
                    element.imgPath = imgPaths[index];
                });
                if (globalState.reseller?.name === ResellerType.DATMAN) {
                    const datmanProducts = products.filter(function (product) {
                        return product.name === ProductsRequired.PDQ || product.name === ProductsRequired.Gateway;
                    });
                    setProducts(datmanProducts);
                } else {
                    setProducts(products);
                }
            }
            setIsLoading(false);
        };

        getProducts();
        // eslint-disable-next-line
    }, []);

    const onSubmit = async () => {
        setIsLoading(true);
        const data = {
            productsRequiredIds: selectedProducts,
            resellerId: globalState.reseller.id
        };
        await NonResellerMerchantService.completeOnboardingStep(globalState.merchant?.id, 7, data);
        setGlobalState((globalState) => ({
            ...globalState,
            shouldLoadMerchants: !globalState.shouldLoadMerchants,
            shouldLoadThisMerchant: globalState.merchant?.id
        }));

        setIsLoading(false);
        setRoute('/settings');
    };

    return (
        <ManageProductsDatmanComponent
            products={products}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            onSubmit={onSubmit}
            isLoading={isLoading}
        />
    );
}
