import React from 'react';
import { SupportChangeUserAttributes, SupportUploadTermsAnsConditions } from 'components';
import { Grid } from '@material-ui/core';
import { useGlobalStateContext } from 'contexts';
import styles from './support-utilities.module.scss';
import { UserType } from 'utils/enums/UserType';
import NotAuthorizedPage from 'pages/not-authorized/not-authorized.container';
const SupportUtilitiesComponent = (props) => {
    const { globalState, setRoute } = useGlobalStateContext();
    const { name: utilityName } = props.match.params;
    const isSuperAdmin = globalState.user?.UserType?.name === UserType.SuperAdmin;
    const utilityNameToSupportUtilityComponent = {
        'update-user-attributes': <SupportChangeUserAttributes />,
        'add-terms-and-conditions': <SupportUploadTermsAnsConditions />
    };

    return (
        <React.Fragment>
            {utilityName === 'update-user-attributes' && !isSuperAdmin ? (
                <NotAuthorizedPage autoRedirect={true} />
            ) : (
                <>
                    <div className={styles.merchantHeader}>
                        <div className={styles.homeLogo} onClick={() => setRoute('/home')}>
                            <img src={globalState.reseller?.logo} alt="logo"></img>
                        </div>
                    </div>
                    <Grid container justify="center">
                        {utilityNameToSupportUtilityComponent[utilityName]}
                    </Grid>
                </>
            )}
        </React.Fragment>
    );
};

export default SupportUtilitiesComponent;
