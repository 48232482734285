import React from 'react';
import LoginComponent from './login.component';
import { useGlobalStateContext } from 'contexts';
import { Redirect } from 'react-router-dom';
import { AuthService } from 'services';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';

function Login(props) {
    const { globalState } = useGlobalStateContext();
    const location = useLocation();
    const urlParams = parse(location.hash);
    sessionStorage.setItem('login', true);
    if (globalState.user && globalState.user.email === urlParams.email) {
        globalState.user.isEmailConfirmed =
            urlParams.email_verification && urlParams.email_verification === 'success' ? true : false;
        const currentUser = JSON.parse(localStorage.getItem('user'));
        if (currentUser) {
            currentUser.isEmailConfirmed = true;
        }
        localStorage.setItem('user', JSON.stringify(currentUser));
    }
    (props.history.action === 'REPLACE' || props.history.action === 'PUSH') && window.location.reload();

    window.addEventListener('popstate', (e) => {
        window.location.reload(true);
    });

    if (AuthService.isLoggedUser()) {
        if (props.location.search && props.location.search?.includes('data='))
            return (
                <Redirect
                    to={
                        globalState.customDomain === false
                            ? `/${globalState.reseller?.portalURL}/home${props.location.search}`
                            : `/home${props.location.search}`
                    }
                />
            );
        else
            return (
                <Redirect
                    to={globalState.customDomain === false ? `/${globalState.reseller?.portalURL}/home` : '/home'}
                />
            );
    }

    return <LoginComponent linkQrData={props.location.search} />;
}

export default Login;
