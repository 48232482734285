import { DatmanAxios } from './interceptor.service.js';
import { DatmanAPIEndpoint } from 'utils/constants';
import { SuccesResponse, ErrorResponse } from 'models';

async function getMerchantChargesList({ month, year, via, offset, limit }) {
    try {
        const response = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/invoice`, {
            year,
            month,
            via,
            offset,
            limit
        });
        return new SuccesResponse(response, response.status);
    } catch (error) {
        const errMessage = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

export default {
    getMerchantChargesList
};
