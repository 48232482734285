import React from 'react';
import PropTypes from 'prop-types';
import style from './bank-verification-upload-documents.module.scss';
import { Card, Box, Typography } from '@material-ui/core';
import { UploadDocuments } from 'components/onboarding/onboarding-ui-components';
import { DocumentFlag } from 'utils/enums/DocumentFlag';
import { FinishUploadButton, PendingDocsList, LoadingScreen } from 'components';

function BunkAccountUploadingDocumentsComponent(props) {
    const {
        merchantId,
        setValidForm,
        setFormattedDocumentsStats,
        formattedDocumentsStats,
        setUploadSuccess,
        businessTypeId,
        isFinishButtonDisabled,
        setIsFinishButtonDisabled,
        bankUpload,
        setBankUpload,
        source,
        loading,
        csrfToken,
        formType
    } = props;

    return (
        <React.Fragment>
            <Card className={style.container}>
                <Box>
                    <Typography color="primary">
                        <h1 className={style.paddingHeader}>Bank verification documents</h1>
                        <div className={style.subHeaderBackground}>
                            {formattedDocumentsStats?.pendingDocumentCategories?.length ? (
                                <>
                                    {!formattedDocumentsStats.needsOnlyGenericCategoryDocs ? (
                                        <h3>Documents that are required for identification are:</h3>
                                    ) : (
                                        ''
                                    )}
                                    <PendingDocsList
                                        formattedDocumentsStats={formattedDocumentsStats}
                                        businessTypeId={businessTypeId}
                                    ></PendingDocsList>
                                    {!formattedDocumentsStats.needsOnlyGenericCategoryDocs ? (
                                        <h3>Kindly upload the above documents.</h3>
                                    ) : (
                                        ''
                                    )}
                                </>
                            ) : (
                                ''
                            )}
                            <span>The required formats are: .png, .jpeg, .jpg, .pdf.</span>
                        </div>
                    </Typography>
                </Box>
                <div className={style.docContainer}>
                    <div className={style.documents}>
                        <UploadDocuments
                            entity={'merchant'}
                            entityId={merchantId}
                            flag={DocumentFlag.BANK_VERIFY}
                            setValidForm={setValidForm}
                            setFormattedDocumentsStats={setFormattedDocumentsStats}
                            setUploadSuccess={setUploadSuccess}
                            uploadBtnName={'Submit'}
                            businessTypeId={businessTypeId}
                            setIsFinishButtonDisabled={setIsFinishButtonDisabled}
                            bankUpload={bankUpload}
                            setBankUpload={setBankUpload}
                            source={source}
                            csrfToken={csrfToken}
                            formType={formType}
                        ></UploadDocuments>
                    </div>
                </div>
            </Card>
            <FinishUploadButton disabled={isFinishButtonDisabled} />
            <LoadingScreen open={loading} />
        </React.Fragment>
    );
}

BunkAccountUploadingDocumentsComponent.propTypes = {
    isFinishButtonDisabled: PropTypes.bool,
    setIsFinishButtonDisabled: PropTypes.func,
    bankUpload: PropTypes.bool,
    setBankUpload: PropTypes.func,
    source: PropTypes.string
};
export default BunkAccountUploadingDocumentsComponent;
