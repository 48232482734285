import React from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Button,
    Grid,
    Box,
    CircularProgress,
    InputAdornment,
    OutlinedInput,
    useMediaQuery
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import styles from './transaction-refund.module.scss';
import { FormItem, Label, Input } from '../../onboarding/onboarding-ui-components';
import { getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry';
import { useGlobalStateContext } from 'contexts';
import { SnackBarAndAlert } from 'components';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { formatAmount } from 'utils/helper';
import { LoadingScreen } from 'components';

export default function TransactinRefundComponent(props) {
    const { globalState } = useGlobalStateContext();
    const {
        handleOnSubmitRefund,
        error,
        errorMessage,
        refundAmount,
        reason,
        setRefundAmount,
        setReason,
        setOtherReason,
        otherReason,
        isRefunded,
        loading,
        handleOnClose,
        show,
        setShow,
        successMessage,
        onInput,
        disableSubmitButton,
        refundReason,
        isLoading,
        refundFailureMessage
    } = props;
    const mediaLessThan600px = useMediaQuery('(max-width:599px)');

    return (
        <React.Fragment>
            <Dialog
                open={show}
                onClose={() => setShow(false)}
                fullWidth={true}
                maxWidth="sm"
                className={styles.refundDialog}
            >
                {!loading ? (
                    <div className={styles.refundDialogDiv}>
                        <DialogTitle>
                            <span className={styles.title}>Refund Transaction</span>
                            <div className={styles.headerDivider}></div>
                        </DialogTitle>
                        {error ? (
                            <Alert className={styles.alertError} severity="error">
                                {errorMessage}
                            </Alert>
                        ) : null}
                        <DialogContent className={styles.dialogContent}>
                            <span className={styles.withdrawalArrivingText}>
                                Refunds will take 3-5 days to reach customer&apos;s account.
                            </span>
                            <div>
                                <FormItem>
                                    <Grid
                                        container
                                        xs={12}
                                        md={12}
                                        style={{ marginBottom: 10 }}
                                        spacing={!mediaLessThan600px ? 3 : 2}
                                    >
                                        <Grid item xs={4} md="auto">
                                            <Label>Amount</Label>
                                        </Grid>
                                        <Grid item xs={8} md="auto">
                                            <OutlinedInput
                                                className={styles.customOutlineInput}
                                                value={formatAmount(refundAmount)}
                                                onChange={(e) => setRefundAmount(e.target.value)}
                                                onInput={onInput}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        {getCurrencyThreeAlpha(globalState.merchant?.country)}
                                                    </InputAdornment>
                                                }
                                                inputMode="numeric"
                                                type="number"
                                                min="0"
                                                required
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        xs={12}
                                        md={12}
                                        spacing={!mediaLessThan600px ? 3 : 0}
                                        className={styles.refundReason}
                                    >
                                        <Grid item xs={4} md="auto">
                                            <Label>Reason</Label>
                                        </Grid>
                                        <Grid item xs={8} md="auto" className={styles.refundReasonOptions}>
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    id="transaction-refund-id"
                                                    value={reason}
                                                    onChange={(e) => setReason(e.target.value)}
                                                    row
                                                    className={styles.radioButtons}
                                                >
                                                    {refundReason.map((reason) => {
                                                        return (
                                                            <FormControlLabel
                                                                value={reason}
                                                                control={<Radio size="small" />}
                                                                label={reason}
                                                            />
                                                        );
                                                    })}
                                                </RadioGroup>
                                            </FormControl>
                                            <Grid item className={styles.topPadding}>
                                                {reason === 'Other' ? (
                                                    <Input
                                                        rowsMax={1}
                                                        inputProps={{
                                                            maxlength: 60
                                                        }}
                                                        value={otherReason}
                                                        placeholder={'Please Enter Reason'}
                                                        onChange={(e) => setOtherReason(e.target.value)}
                                                        variant="outlined"
                                                    />
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </FormItem>
                            </div>
                        </DialogContent>
                        <DialogActions className={styles.buttonsContainer}>
                            <Grid container className={styles.grid}>
                                <Grid item xs={6}>
                                    <Button
                                        className={styles.button}
                                        onClick={handleOnClose}
                                        color="primary"
                                        variant="outlined"
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        className={styles.button}
                                        variant="contained"
                                        onClick={handleOnSubmitRefund}
                                        color="primary"
                                        disabled={disableSubmitButton || isLoading}
                                    >
                                        Refund
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </div>
                ) : (
                    <Box textAlign="center" py={5}>
                        <CircularProgress />
                    </Box>
                )}
            </Dialog>
            {isRefunded !== null && (
                <SnackBarAndAlert open={true} onClose={handleOnClose} type={isRefunded ? 'success' : 'error'}>
                    {isRefunded
                        ? successMessage
                            ? successMessage
                            : 'Your refund request has been sent successfully.'
                        : refundFailureMessage}
                </SnackBarAndAlert>
            )}
            <LoadingScreen open={isLoading} />
        </React.Fragment>
    );
}

TransactinRefundComponent.propTypes = {
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    refundAmount: PropTypes.string,
    reason: PropTypes.string,
    setRefundAmount: PropTypes.func,
    setReason: PropTypes.func,
    setOtherReason: PropTypes.func,
    otherReason: PropTypes.string,
    isRefunded: PropTypes.bool,
    loading: PropTypes.bool,
    handleOnClose: PropTypes.func,
    show: PropTypes.bool,
    setShow: PropTypes.func,
    handleOnSubmitRefund: PropTypes.func,
    successMessage: PropTypes.string,
    onInput: PropTypes.func
};
