import React, { useState, useEffect } from 'react';
import { useOnboardingFormContext } from 'contexts';
import { CompaniesHouseService } from 'services';
import CompaniesHouseLookupComponent from './companies-house-lookup.component';

function CompaniesHouseLookup() {
    const { onboardingForm, setOnboardingForm } = useOnboardingFormContext();
    const [disabled, setDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const { companyName } = onboardingForm.nameAndAddress;

    const getCompanyDetails = async () => {
        setIsLoading(true);
        setDisabled(true);

        const result = await CompaniesHouseService.getCompaniesDetail(companyName, 10);
        if (result.isSuccesfully) {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                nameAndAddress: {
                    ...onboardingForm.nameAndAddress,
                    companies: result.data.items
                }
            }));
        } else {
            setError(true);
        }

        setIsLoading(false);
        setDisabled(false);
    };

    useEffect(() => {
        setError(false);
        if (companyName && companyName.length >= 3) setDisabled(false);
        else setDisabled(true);
    }, [companyName]);

    return (
        <CompaniesHouseLookupComponent
            getCompanyDetails={getCompanyDetails}
            isLoading={isLoading}
            disabled={disabled}
            error={error}
        />
    );
}

CompaniesHouseLookup.propTypes = {};

export default CompaniesHouseLookup;
