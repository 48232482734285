export const PdqStatusColors = {
    SUCCESS: {
        backgroundColor: ' #005896',
        textColor: '#FFFFFF'
    },
    REJECTED: {
        backgroundColor: ' #E36985',
        textColor: '#FFFFFF'
    },
    'MSA SENT': {
        backgroundColor: ' rgba(232, 195, 84, 0.2)',
        textColor: '#E1B01E'
    },
    'IN REVIEW': {
        backgroundColor: ' #F6F7FA',
        textColor: '#414E5A'
    },
    'SEND BACK': {
        backgroundColor: ' #B8D2EA',
        textColor: ' #1D1D1F'
    },
    'REQUEST RAISED': { backgroundColor: ' #F6F7FA' },
    'MSA SIGNED': {
        backgroundColor: '#E8C354',
        textColor: '#FFFFFF'
    }
};
