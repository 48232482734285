import React from 'react';
import {
    Box,
    CircularProgress,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    useMediaQuery
} from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import AddIcon from '@material-ui/icons/Add';
import FiberManualRecordTwoToneIcon from '@material-ui/icons/FiberManualRecordTwoTone';
import TransactionStatusLabel from '../transaction-tables/transaction-status-label/transaction-status-label.container';
import { filterRefundDescription, formatCurrency, formatDateTimeByFormatString } from '../../../utils/helper';
import styles from './datman-reseller-payment-details.module.scss';
import { getCurrencySymbol, getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry';
import { useGlobalStateContext } from 'contexts';
import { SnackBarAndAlert } from 'components';
import { UserType } from 'utils/enums/UserType';
import { ResellerType } from 'utils/enums/ResellerType';

const DatmanResellerPaymentsDetailsComponent = (props) => {
    const { globalState } = useGlobalStateContext();
    const { loading, error, payment, handleCopyPaymentId, isCopyAlertOpen, closeCopyAlert, status } = props;
    const mediaLessThan600px = useMediaQuery('(max-width:599px)');
    const isAdmin =
        globalState.user?.UserType?.name === UserType.Admin || globalState.user?.UserType?.name === UserType.SuperAdmin;
    const isDatmanReseller = globalState.reseller?.name === ResellerType.DATMAN;
    const showAcquirer = isAdmin && isDatmanReseller;
    return (
        <React.Fragment>
            {loading ? (
                <Box textAlign="center" py={5}>
                    <CircularProgress />
                </Box>
            ) : (
                <React.Fragment>
                    {error ? (
                        <Box textAlign="center" py={5}>
                            An error occurred, please try reloading your browser.
                        </Box>
                    ) : (
                        <React.Fragment>
                            <div className={styles.containerDiv}>
                                <div className={styles.headerDetails}>
                                    <div className={styles.contentBlock}>
                                        <div>
                                            <Typography color={'primary'}>
                                                <span className={styles.pageTitle}>Payment</span>
                                                <span className={styles.spanRefId}>
                                                    {payment.id}
                                                    <sup>
                                                        <FileCopyOutlinedIcon
                                                            fontSize="inherit"
                                                            onClick={handleCopyPaymentId}
                                                            className={styles.copyIcon}
                                                        />
                                                    </sup>
                                                </span>
                                            </Typography>
                                            {isCopyAlertOpen && (
                                                <SnackBarAndAlert
                                                    open={isCopyAlertOpen}
                                                    onClose={closeCopyAlert}
                                                    type={'success'}
                                                >
                                                    Successfully copied the payment reference id.
                                                </SnackBarAndAlert>
                                            )}
                                        </div>
                                        <div className={styles.amount_items}>
                                            <span className={styles.amount_items__currency}>
                                                {getCurrencySymbol(globalState.merchant?.country)}
                                            </span>
                                            <span>{payment.total}</span>
                                            <span className={styles.spnaCurrency}>
                                                {getCurrencyThreeAlpha(globalState.merchant?.country)}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={styles.content}>
                                        {
                                            //remove false when modify api to get payment method
                                            false && (
                                                <Box px={2}>
                                                    <div>
                                                        <span>Payment method</span>
                                                    </div>
                                                    <div>
                                                        <span>{payment.method}</span>
                                                    </div>
                                                </Box>
                                            )
                                        }
                                        {
                                            //remove false when modify api to get risk parameter
                                            false && (
                                                <Box px={2}>
                                                    <div>
                                                        <span>Risk</span>
                                                    </div>
                                                    <div>
                                                        <span>{payment.risk}</span>
                                                    </div>
                                                </Box>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                //remove false when modify api to get timeline details method
                                false && (
                                    <div className={styles.timelineDiv}>
                                        <Box width="100%">
                                            <div className={styles.headerDetails}>
                                                <Typography color={'primary'}>
                                                    <span className={styles.pageTitle}>Timeline</span>
                                                </Typography>
                                                <Box pr={2}>
                                                    <Button color="primary" size="small" startIcon={<AddIcon />}>
                                                        Add note
                                                    </Button>
                                                </Box>
                                            </div>
                                            <div>
                                                {payment.timeline &&
                                                    payment.timeline.map((timeline) => {
                                                        return (
                                                            <div className={styles.timeline}>
                                                                <div>
                                                                    <FiberManualRecordTwoToneIcon
                                                                        fontSize="inherit"
                                                                        htmlColor="#04dc04"
                                                                    />
                                                                    <span> {timeline.description}</span>
                                                                </div>
                                                                <Box pl={2}>
                                                                    {formatDateTimeByFormatString(
                                                                        timeline.created_at,
                                                                        'DD MMM HH:mm'
                                                                    )}
                                                                </Box>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </Box>
                                    </div>
                                )
                            }
                            {mediaLessThan600px ? (
                                <div className={styles.responsiveDiv}>
                                    <Box width="100%">
                                        <div>
                                            <Typography color={'primary'}>
                                                <span className={styles.pageTitle}>Payment details</span>
                                            </Typography>
                                        </div>
                                        <Box mt={2} className={styles.overflowX}>
                                            <Table>
                                                <TableRow>
                                                    <TableCell
                                                        style={{ border: 'none', paddingRight: '0px' }}
                                                        variant="head"
                                                    >
                                                        Amount
                                                    </TableCell>
                                                    <TableCell style={{ border: 'none', paddingLeft: '0px' }}>
                                                        {formatCurrency(
                                                            payment.total,
                                                            getCurrencyThreeAlpha(globalState.merchant?.country)
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ border: 'none' }} variant="head">
                                                        Date
                                                    </TableCell>
                                                    <TableCell style={{ border: 'none', paddingLeft: '0px' }}>
                                                        {formatDateTimeByFormatString(payment.time, 'DD MMM HH:mm')}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ border: 'none' }} variant="head">
                                                        Card
                                                    </TableCell>
                                                    <TableCell style={{ border: 'none', paddingLeft: '0px' }}>
                                                        {payment.last_four_digits ||
                                                        payment.card_last_four_digits ||
                                                        payment.last_4_digits
                                                            ? `********${
                                                                  payment.last_four_digits ||
                                                                  payment.card_last_four_digits ||
                                                                  payment.last_4_digits
                                                              }`
                                                            : `-`}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{ border: 'none', paddingRight: '0px' }}
                                                        variant="head"
                                                    >
                                                        Description
                                                    </TableCell>
                                                    <TableCell style={{ border: 'none', paddingLeft: '0px' }}>
                                                        {+payment.total < 0 ? (
                                                            <span>
                                                                {filterRefundDescription(payment.refund) || '-'}
                                                            </span>
                                                        ) : payment.more_info ? (
                                                            payment.more_info
                                                        ) : (
                                                            <span>&mdash;</span>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{ border: 'none', paddingRight: '0px' }}
                                                        variant="head"
                                                    >
                                                        Status
                                                    </TableCell>
                                                    <TableCell style={{ border: 'none', paddingLeft: '0px' }}>
                                                        {payment.total <= 0 ? (
                                                            <TransactionStatusLabel status={'REFUNDED'} />
                                                        ) : (
                                                            <TransactionStatusLabel status={status} />
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            </Table>
                                        </Box>
                                    </Box>
                                </div>
                            ) : (
                                <div className={styles.paymentDetailsDiv}>
                                    <Box width="100%">
                                        <div>
                                            <Typography color={'primary'}>
                                                <span className={styles.pageTitle}>Payment details</span>
                                            </Typography>
                                        </div>
                                        <Box mt={2} className={styles.overflowX}>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow className={styles.tableHeader}>
                                                        {/* <TableCell>Statement description</TableCell> */}
                                                        <TableCell>Amount</TableCell>
                                                        <TableCell>Date</TableCell>
                                                        <TableCell>Card</TableCell>
                                                        <TableCell>Description</TableCell>
                                                        <TableCell>Status</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        {/* <TableCell>{payment.statement_description || '-'}</TableCell> */}
                                                        <TableCell>
                                                            {formatCurrency(
                                                                payment.total,
                                                                getCurrencyThreeAlpha(globalState.merchant?.country)
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {formatDateTimeByFormatString(payment.time, 'DD MMM HH:mm')}
                                                        </TableCell>
                                                        <TableCell>
                                                            {payment.last_four_digits ||
                                                            payment.card_last_four_digits ||
                                                            payment.last_4_digits
                                                                ? `********${
                                                                      payment.last_four_digits ||
                                                                      payment.card_last_four_digits ||
                                                                      payment.last_4_digits
                                                                  }`
                                                                : `-`}
                                                        </TableCell>
                                                        <TableCell>
                                                            {+payment.total < 0 ? (
                                                                <span>
                                                                    {filterRefundDescription(payment.refund) || '-'}
                                                                </span>
                                                            ) : payment.more_info ? (
                                                                payment.more_info
                                                            ) : (
                                                                <span>&mdash;</span>
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {payment.total <= 0 ? (
                                                                <TransactionStatusLabel status={'REFUNDED'} />
                                                            ) : (
                                                                <TransactionStatusLabel status={status} />
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </Box>
                                </div>
                            )}
                            {mediaLessThan600px ? (
                                <div className={styles.responsiveDiv}>
                                    <Box width="100%">
                                        <div>
                                            <Typography color={'primary'}>
                                                <span className={styles.pageTitle}>Customer Details</span>
                                            </Typography>
                                        </div>
                                        <Box mt={2} className={styles.overflowX}>
                                            <Table>
                                                <TableRow>
                                                    <TableCell
                                                        style={{ border: 'none', paddingRight: '0px' }}
                                                        variant="head"
                                                    >
                                                        Customer Name
                                                    </TableCell>
                                                    <TableCell style={{ border: 'none', paddingLeft: '0px' }}>
                                                        {payment.firstname}&nbsp;{payment.lastname}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{ border: 'none', paddingRight: '40px' }}
                                                        variant="head"
                                                    >
                                                        Customer Address
                                                    </TableCell>
                                                    <TableCell style={{ border: 'none', paddingLeft: '0px' }}>
                                                        {payment.address}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow style={{ display: 'none' }}>
                                                    <TableCell style={{ border: 'none' }} variant="head">
                                                        Customer Email
                                                    </TableCell>
                                                    <TableCell style={{ border: 'none' }}>
                                                        {payment.email || '-'}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow style={{ display: 'none' }}>
                                                    <TableCell style={{ border: 'none' }} variant="head">
                                                        Customer Phone Number
                                                    </TableCell>
                                                    <TableCell style={{ border: 'none' }}>
                                                        {payment.phone_number || '-'}
                                                    </TableCell>
                                                </TableRow>
                                            </Table>
                                        </Box>
                                    </Box>
                                </div>
                            ) : (
                                <div className={styles.paymentDetailsDiv}>
                                    <Box width="100%">
                                        <div>
                                            <Typography color={'primary'}>
                                                <span className={styles.pageTitle}>Customer Details</span>
                                            </Typography>
                                        </div>
                                        <Box mt={2} className={styles.overflowX}>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow className={styles.tableHeader}>
                                                        {/* <TableCell>Statement description</TableCell> */}
                                                        <TableCell>Customer Name</TableCell>
                                                        <TableCell>Customer Address</TableCell>
                                                        <TableCell>Customer Email</TableCell>
                                                        <TableCell>Customer Phone Number</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        {/* <TableCell>{payment.statement_description || '-'}</TableCell> */}
                                                        <TableCell>
                                                            {payment.firstname}&nbsp;{payment.lastname}
                                                        </TableCell>
                                                        <TableCell>{payment.address}</TableCell>

                                                        <TableCell>{payment.email || '-'}</TableCell>
                                                        <TableCell>{payment.phone_number || '-'}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </Box>
                                </div>
                            )}
                            {isAdmin &&
                                (mediaLessThan600px ? (
                                    <div className={styles.responsiveDiv}>
                                        <Box width="100%">
                                            <div>
                                                <Typography color={'primary'}>
                                                    <span className={styles.pageTitle}>Additional Information</span>
                                                </Typography>
                                            </div>
                                            <Box mt={2} className={styles.overflowX}>
                                                <Table>
                                                    <TableRow>
                                                        <TableCell
                                                            style={{ border: 'none', paddingRight: '0px' }}
                                                            variant="head"
                                                        >
                                                            Transaction Reference
                                                        </TableCell>
                                                        <TableCell style={{ border: 'none', paddingLeft: '0px' }}>
                                                            {payment.TxnReference ? payment.TxnReference : '-'}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            style={{ border: 'none', paddingRight: '0px' }}
                                                            variant="head"
                                                        >
                                                            3DS status
                                                        </TableCell>
                                                        <TableCell style={{ border: 'none', paddingLeft: '0px' }}>
                                                            {payment.ThreeDsStatus ? payment.ThreeDsStatus : '-'}
                                                        </TableCell>
                                                    </TableRow>
                                                </Table>
                                            </Box>
                                        </Box>
                                    </div>
                                ) : (
                                    <div className={styles.paymentDetailsDiv}>
                                        <Box width="100%">
                                            <div>
                                                <Typography color={'primary'}>
                                                    <span className={styles.pageTitle}>Additional Information</span>
                                                </Typography>
                                            </div>
                                            <Box mt={2} className={styles.overflowX}>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow className={styles.tableHeader}>
                                                            {/* <TableCell>Statement description</TableCell> */}
                                                            <TableCell>Transaction Reference</TableCell>
                                                            {payment.payment_provider !== 'ADYEN' && showAcquirer && (
                                                                <TableCell>Acquirer ID</TableCell>
                                                            )}
                                                            <TableCell>3DS Status</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            {/* <TableCell>{payment.statement_description || '-'}</TableCell> */}
                                                            <TableCell>
                                                                {payment.TxnReference ? payment.TxnReference : '-'}
                                                            </TableCell>

                                                            {/* HardCoding Temporarily */}
                                                            {payment.payment_provider !== 'ADYEN' && showAcquirer && (
                                                                <TableCell>{payment.payment_provider}</TableCell>
                                                            )}
                                                            <TableCell>
                                                                {payment.ThreeDsStatus ? payment.ThreeDsStatus : '-'}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </Box>
                                    </div>
                                ))}
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default DatmanResellerPaymentsDetailsComponent;
