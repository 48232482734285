import React, { useState } from 'react';
import MerchantChargesListTableComponent from './merchant-charges-list-table.component.jsx';
import PropTypes from 'prop-types';
import { UserType } from 'utils/enums/UserType';

import { formatAmount } from 'utils/helper';
import { useMediaQuery } from '@material-ui/core';
import { useGlobalStateContext, useBalanceContext } from 'contexts';

import { AdminService } from 'services';

const MerchantChargesListTable = (props) => {
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('date_sent');
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showSnackbarStatus, setShowSnackbarStatus] = useState(false);
    const [openMerchantDeleteModal, setOpenMerchantDeleteModal] = useState(false);
    const [openMerchantPayModal, setOpenMerchantPayModal] = useState(false);
    const [rowId, setRowId] = useState(null);
    const [rowAmount, setRowAmount] = useState(null);
    const { refreshBalance } = useBalanceContext();

    const {
        merchantCharges,
        error,
        page,
        setPage,
        loading,
        setLoading,
        merchantChargeResponse,
        setMerchantChargeResponse,
        setMerchantChargeResponseFlag,
        merchantChargeResponseFlag,
        companyAddress,
        merchantTradingAddress,
        rowsPerPage,
        setRowsPerPage,
        getMerchantChargesList,
        count,
        setOpenMerchantModal,
        openMerchantModal
    } = props;
    const mediaLessThan600px = useMediaQuery('(max-width:599px)');
    const [merchantChargeReportData, setMerchantChargeReportData] = useState([]);
    const { globalState } = useGlobalStateContext();
    const isSuperAdmin = globalState.user?.UserType?.name === UserType.SuperAdmin;
    const isMerchant = globalState.user.UserType.name === UserType.Merchant;
    const columnStyles = {
        0: {
            columnWidth: 30
        }
    };

    const handleMerchantDeleteModal = (rowId, rowAmount) => {
        setOpenMerchantDeleteModal(true);
        setRowId(rowId);
        setRowAmount(rowAmount);
    };

    const handleMerchantPayModal = (rowId, rowAmount) => {
        setOpenMerchantPayModal(true);
        setRowId(rowId);
        setRowAmount(rowAmount);
    };
    const alertCloseMessage = () => {
        setShowSnackbarStatus(false);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const totalMerchantCharges = () => {
        let total = merchantCharges.reduce((accumulator, charge) => {
            return accumulator + charge.amount;
        }, 0);

        // return Math.floor(total * 100) / 100;

        return formatAmount(total);
    };
    const payMerchantChargesBtn = async (id) => {
        const response = await AdminService.payMerchantCharges({
            id: id
        });

        if (response.isSuccesfully) {
            setLoading(true);
            setShowSnackbarStatus(true);
            setSnackbarMessage(response.data.message);
            setMerchantChargeResponse(true);
            setMerchantChargeResponseFlag(true);
            refreshBalance();
        } else {
            setLoading(true);
            setShowSnackbarStatus(true);
            setSnackbarMessage(response.data.message);
            setMerchantChargeResponse(false);
            setMerchantChargeResponseFlag(false);
        }
        setLoading(false);
        setOpenMerchantPayModal(false);
    };
    const deleteMerchantChargesBtn = async (id) => {
        const response = await AdminService.deleteMerchantCharges({
            id: id
        });

        if (response.isSuccesfully) {
            setLoading(true);
            setShowSnackbarStatus(true);
            setSnackbarMessage(response.data.message);
            setMerchantChargeResponse(true);
            setMerchantChargeResponseFlag(true);
        } else {
            setLoading(true);
            setShowSnackbarStatus(true);
            setSnackbarMessage(response.data.message);
            setMerchantChargeResponse(false);
            setMerchantChargeResponseFlag(false);
        }
        setLoading(false);
        setOpenMerchantDeleteModal(false);
    };
    const headCells = [
        {
            id: 'amount',
            numeric: true,
            disablePadding: false,
            label: 'Amount'
        },
        {
            id: 'date_sent',
            numeric: false,
            disablePadding: false,
            label: 'Date'
        },
        {
            id: 'service_description',
            numeric: false,
            disablePadding: false,
            label: 'Description',
            notShow: mediaLessThan600px
        },
        { id: 'paid_status', numeric: false, disablePadding: false, label: 'Status' },

        {
            id: 'action',
            numeric: false,
            disablePadding: false,
            label: 'Action',
            notShow: !isSuperAdmin
        },
        { id: 'download', numeric: false, disablePadding: false, label: 'Download' }
    ];
    const formatDescription = (str) => {
        const regex = /[^a-zA-Z0-9 ]/g;
        str = str.replace(regex, ' ');
        return str;
    };
    return (
        <MerchantChargesListTableComponent
            rows={merchantCharges}
            headCells={headCells}
            loading={loading}
            setLoading={setLoading}
            order={order}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            handleRequestSort={handleRequestSort}
            error={error}
            totalMerchantCharges={totalMerchantCharges}
            page={page}
            setPage={setPage}
            payMerchantChargesBtn={payMerchantChargesBtn}
            snackbarMessage={snackbarMessage}
            setSnackbarMessage={setSnackbarMessage}
            showSnackbarStatus={showSnackbarStatus}
            setShowSnackbarStatus={setShowSnackbarStatus}
            merchantChargeResponse={merchantChargeResponse}
            setMerchantChargeResponse={setMerchantChargeResponse}
            merchantChargeResponseFlag={merchantChargeResponseFlag}
            setMerchantChargeResponseFlag={setMerchantChargeResponseFlag}
            deleteMerchantChargesBtn={deleteMerchantChargesBtn}
            openMerchantDeleteModal={openMerchantDeleteModal}
            setOpenMerchantDeleteModal={setOpenMerchantDeleteModal}
            handleMerchantDeleteModal={handleMerchantDeleteModal}
            openMerchantPayModal={openMerchantPayModal}
            setOpenMerchantPayModal={setOpenMerchantPayModal}
            handleMerchantPayModal={handleMerchantPayModal}
            rowId={rowId}
            rowAmount={rowAmount}
            companyAddress={companyAddress}
            merchantTradingAddress={merchantTradingAddress}
            merchantChargeReportData={merchantChargeReportData}
            setMerchantChargeReportData={setMerchantChargeReportData}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            getMerchantChargesList={getMerchantChargesList}
            count={count}
            columnStyles={columnStyles}
            openMerchantModal={openMerchantModal}
            setOpenMerchantModal={setOpenMerchantModal}
            alertCloseMessage={alertCloseMessage}
            isMerchant={isMerchant}
            formatDescription={formatDescription}
        />
    );
};

export default MerchantChargesListTable;

MerchantChargesListTable.propTypes = {
    merchantCharges: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    getMerchantChargesList: PropTypes.func,
    formatDescription: PropTypes.func
};
