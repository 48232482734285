import { AuthAxios, DatmanAxios } from './interceptor.service.js';
import { SuccesResponse, ErrorResponse } from 'models';
import { DatmanAPIEndpoint, RefundPaymentEndpoint, GetPaymentsEndpoint } from 'utils/constants';

async function GetPayments(merchantId, paymentsRequest) {
    try {
        const requestResult = await AuthAxios.post(
            `${GetPaymentsEndpoint}/get-payments/${merchantId}`,
            paymentsRequest
        );
        const { data, status } = requestResult;
        let payments = data.data.transactions.map((payment) => {
            if (!('key' in payment)) payment.status = 'Sent';
            return payment;
        });
        return new SuccesResponse({ payments: payments, total: data.data.total }, status);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function GetPaymentsTotal(paymentsRequest) {
    try {
        const requestResult = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/payments`, paymentsRequest);
        const { data, status } = requestResult;

        return new SuccesResponse(data.data.total, status);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function RefundPayment(refundPaymentRequest) {
    try {
        const requestResult = await AuthAxios.post(RefundPaymentEndpoint, JSON.stringify(refundPaymentRequest));

        const { data, status } = requestResult;

        return new SuccesResponse(data, status);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function runStripePaymentReport(merchantId, reportRequest) {
    try {
        const response = await AuthAxios.post(
            `${GetPaymentsEndpoint}/run-stripe-payment-report/${merchantId}`,
            reportRequest
        );
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        const errMessage = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}
async function fetchStripePaymentReport(merchantId, reportId) {
    try {
        const response = await AuthAxios.get(
            `${GetPaymentsEndpoint}/fetch-stripe-payment-report/${merchantId}/${reportId}`
        );
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        const errMessage = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}
async function fetchAccountBalance(merchantId) {
    try {
        const response = await AuthAxios.get(`${GetPaymentsEndpoint}/account/balance/${merchantId}`);

        //total balance and convertion to decimal
        let data = { balance: (response.data.availableBalance + response.data.pendingBalance) / 100 };
        return new SuccesResponse(data, response.status);
    } catch (error) {
        const errMessage = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function schedulePaymentsExport(data) {
    try {
        const requestResult = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/reports/schedule/payment`, data);

        return new SuccesResponse(requestResult);
    } catch (err) {
        console.log(err);
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function sentExportReport(merchantId, payload) {
    try {
        const response = await AuthAxios.post(`${GetPaymentsEndpoint}/generate-payment-report/${merchantId}`, payload);

        return new SuccesResponse(response.data, response.status);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function GetSplitFee(payLoad) {
    try {
        const response = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/payment/fee-details`, payLoad);
        return new SuccesResponse(response.data, response.status);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}
async function MoveTransactions(payload) {
    try {
        const response = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/move-transaction`, payload);

        return new SuccesResponse(response.data, response.status);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function GetPaymentProvider() {
    try {
        const response = await DatmanAxios.get(`${DatmanAPIEndpoint}/portal/get-payments-providers`);
        return new SuccesResponse(response.data);
    } catch (err) {
        console.log('error--', err);
        return new ErrorResponse('Error');
    }
}

async function VoidIncorrectTransaction(paymentId) {
    try {
        const response = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/void-incorrect-transaction/${paymentId}`);
        return new SuccesResponse(response.data);
    } catch (err) {
        console.log('error--', err);
        return new ErrorResponse('Error');
    }
}

export default {
    GetPayments,
    RefundPayment,
    GetPaymentsTotal,
    runStripePaymentReport,
    fetchStripePaymentReport,
    fetchAccountBalance,
    schedulePaymentsExport,
    sentExportReport,
    GetSplitFee,
    MoveTransactions,
    GetPaymentProvider,
    VoidIncorrectTransaction
};
