import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatmanResellerPaymentsListTableComponent from './datman-reseller-payments-list-table.component.jsx';
import { DatmanResellerPaymentsFilterTabs } from '../../../../utils/enums/DatmanResellerPaymentsFilterTabs';
import { useGlobalStateContext } from 'contexts';
import { useMediaQuery } from '@material-ui/core';
import { cleverTapClick } from 'utils/helper.js';

const DatmanResellerPaymentsListTable = (props) => {
    const {
        payments,
        loading,
        channelFilter,
        error,
        noTabs,
        setIsRefundHappen,
        showAcquirer,
        acquirerName,
        selectedAcquirer,
        onAcquirerChange
    } = props;
    const [rows, setRows] = useState([]);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('time');
    const defaultFilter = noTabs ? DatmanResellerPaymentsFilterTabs.All : DatmanResellerPaymentsFilterTabs.Successful;
    const [statusFilter, setStatusFilter] = useState(defaultFilter);
    const [show, setShow] = useState(false);
    const [payment, setPayment] = useState({});
    const [total, setTotal] = useState();
    const { globalState, setRoute } = useGlobalStateContext();
    const [page, setPage] = useState(0);
    const [refundValue, setRefundValue] = useState();
    const mediaLessThan600px = useMediaQuery('(max-width:599px)');
    useEffect(() => {
        let dataRows = payments;
        dataRows =
            channelFilter !== 'All'
                ? payments.filter(
                      (payment) => payment.channel.replace(/\s/g, '').toLowerCase() === channelFilter.toLowerCase()
                  )
                : payments;

        const allTransactions = dataRows.filter((row) => row.payment_provider !== 'ADYEN');
        setRows(allTransactions);

        let totalPaymentValue = 0;
        let refund = 0;

        for (let i = 0; i < payments.length; i += 1) {
            if (payments[i].refund && payments[i].total < 0) {
                const splitRefundReason = payments[i].refund?.split(' - Refunded ');
                // eslint-disable-next-line no-unused-vars
                const [refundDescription, refundAmount] = splitRefundReason;
                // eslint-disable-next-line no-unused-vars
                const [partA, partB] = payments[i].refund?.split('because');
                // eslint-disable-next-line no-unused-vars
                const [transaction_partA, transaction_amount] = partA?.split('&pound; ');
                const formatedAmount = transaction_amount ? transaction_amount.trim() : 0.0;
                if (refundAmount) refund = parseFloat(refund) + parseFloat(refundAmount);
                else refund = parseFloat(refund) + parseFloat(formatedAmount);
            } else if (payments[i].total > 0) {
                totalPaymentValue += parseFloat(payments[i].total);
            }
        }

        setTotal(totalPaymentValue);
        setRefundValue(refund);
        setPage(0);
        // eslint-disable-next-line
    }, [payments, statusFilter, channelFilter]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const showTransactionRefund = (rowData) => {
        const data = {
            refund_amount: rowData.total,
            time_stamp: new Date()
        };
        cleverTapClick('Refund_list_click', globalState, data);
        setShow(true);
        setPayment(rowData);
    };
    const formatRefundReasonAndAmountString = (str) => {
        str = str.replace('&pound;', '');
        str = str.replace('\\n', '');
        str = str.replace('\\n', '');
        str = str.replace('<hr>', '');
        str = str.replace('because', ' - ');
        let arr = str.split('.');
        let str2 = `${arr[0]}.${arr[1] ? arr[1] : ''}`;
        return str2;
    };

    let headCells = [
        {
            id: 'total',
            numeric: true,
            disablePadding: false,
            label: 'Amount'
        },
        {
            id: 'merchant_name',
            numeric: false,
            disablePadding: false,
            label: 'Merchant name'
        },
        {
            id: 'transaction details',
            numeric: false,
            disablePadding: false,
            label: 'Transaction details',
            hideSortingIcon: true
        },
        {
            id: 'description',
            numeric: false,
            disablePadding: false,
            label: 'Description',
            hideSortingIcon: true,
            notShow: mediaLessThan600px
        },
        {
            id: 'payment_provider',
            numeric: false,
            disablePadding: false,
            label: 'Acquirer ID',
            notShow: !showAcquirer
        },
        { id: 'time', numeric: false, disablePadding: false, label: 'Date' },
        // { id: 'refund', numeric: false, disablePadding: false, label: 'Refund', hideSortingIcon: true },
        {
            id: 'payment_status',
            numeric: false,
            disablePadding: false,
            label: 'Status',
            hideSortingIcon: true,
            notShow: statusFilter !== DatmanResellerPaymentsFilterTabs.All
        }
    ];

    if (globalState.merchant?.userRole === 'User') {
        headCells = headCells.filter((headCellObj) => headCellObj.id !== 'refund');
    }
    const handleClick = (value) => {
        setRoute(`/payment-details/${value.id}`, {
            payment: value
        });
        const data = {
            time_stamp: new Date(),
            transactin_id: value.id
        };
        cleverTapClick('Txn_click', globalState, data);
    };
    const clearFilter = () => {
        onAcquirerChange(acquirerName);
        sessionStorage.setItem('selectedAcquirer', JSON.stringify([]));
    };
    return (
        <DatmanResellerPaymentsListTableComponent
            rows={rows}
            setStatusFilter={setStatusFilter}
            statusFilter={statusFilter}
            headCells={headCells}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            handleRequestSort={handleRequestSort}
            loading={loading}
            show={show}
            setShow={setShow}
            showTransactionRefund={showTransactionRefund}
            payment={payment}
            error={error}
            total={total}
            setPayment={setPayment}
            formatRefundReasonAndAmountString={formatRefundReasonAndAmountString}
            noTabs={noTabs}
            setIsRefundHappen={setIsRefundHappen}
            showAcquirer={showAcquirer}
            page={page}
            setPage={setPage}
            refundValue={refundValue}
            handleClick={handleClick}
            clearFilter={clearFilter}
            acquirerName={acquirerName}
            selectedAcquirer={selectedAcquirer}
            onAcquirerChange={onAcquirerChange}
        />
    );
};

export default DatmanResellerPaymentsListTable;

DatmanResellerPaymentsListTable.propTypes = {
    payments: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    channelFilter: PropTypes.string.isRequired,
    error: PropTypes.bool.isRequired
};
