import React from 'react';
import { MerchantSettings, CanonicalResellerSettings, Banner } from 'components';
import { useGlobalStateContext } from 'contexts';
import { UserType } from 'utils/enums/UserType';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { bannerMessage } from 'utils/banner-message';

const TeamComponent = (props) => {
    const { globalState } = useGlobalStateContext();
    const isCanonicalResellerUser = globalState.user?.UserType?.name === UserType.Reseller;
    const isMerchantAccountClosed = globalState.merchant?.status === MerchantStatus.CLOSED;

    return (
        <React.Fragment>
            {isMerchantAccountClosed && <Banner message={bannerMessage.CLOSED_MERCHANT_ACCOUNT} />}
            {isCanonicalResellerUser ? <CanonicalResellerSettings /> : <MerchantSettings {...props} />}
        </React.Fragment>
    );
};

export default TeamComponent;
