import React from 'react';
import { Redirect } from 'react-router-dom';
import { useGlobalStateContext } from 'contexts';
import { UserType } from 'utils/enums/UserType';
import LoggedRoute from './logged-route';
import { MerchantStatus } from 'utils/enums/MerchantStatus';

const RestrictedAdminUserTypeRoute = ({ component: Component, ...rest }) => {
    const { globalState } = useGlobalStateContext();
    const merchantStatus = globalState.merchant?.status;
    const isAdmin = globalState.user?.UserType?.name === UserType.Admin;
    const isSuperAdmin = globalState.user?.UserType?.name === UserType.SuperAdmin;
    const isClosedMerchant = merchantStatus === MerchantStatus.CLOSED;

    return (
        <LoggedRoute
            {...rest}
            render={(props) => {
                if ((isAdmin && !isClosedMerchant) || isSuperAdmin) {
                    return <Component {...props} />;
                } else {
                    return (
                        <Redirect
                            to={
                                globalState.customDomain === false
                                    ? `/${globalState.reseller?.portalURL}/not-authorized`
                                    : '/not-authorized'
                            }
                        />
                    );
                }
            }}
        />
    );
};

export default RestrictedAdminUserTypeRoute;
