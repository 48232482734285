import React, { useState, useEffect } from 'react';
import OnboardingComponent from './onboarding.component';
import { useGlobalStateContext } from 'contexts';

import {
    OnboardingNameAdress,
    OnboardingAdressStep,
    T2SOnboardingAdressStep,
    OnboardingOwnerDetails,
    OnboardingBusinessDetailsStep,
    OnboardingBusinessProfile,
    ProductsRequired,
    OnboardingTransactionProfile,
    OnboardingDocuments,
    OnboardingSummary,
    T2SOnboardingBankDetailsStep,
    T2SOnboardingOwnerDetails,
    T2SOnboardingNameAddress
} from 'components';
import { OnboardingFormProvider } from 'contexts/onboarding-form-context/onboardingForm.context';

//Importing enums
import { OnboardingSteps } from 'utils/enums/OnboardingStepsMapping';

function Onboarding() {
    const { globalState } = useGlobalStateContext();
    const isT2SMerchant = globalState.isT2SMerchant;
    const [step, setStep] = useState(1);

    useEffect(() => {
        setStep(globalState.merchant?.onboardingStep);
    }, [globalState.merchant]);

    let steps = [];

    switch (true) {
        case isT2SMerchant:
            steps = [
                OnboardingSteps.NAME_AND_ADDRESS,
                OnboardingSteps.BANK_DETAILS,
                OnboardingSteps.TRADING_ADDRESS,
                OnboardingSteps.OWNER_DETAILS,
                OnboardingSteps.UPLOAD_DOCUMENTS,
                OnboardingSteps.SUMMARY
            ];
            break;

        default:
            steps = [
                OnboardingSteps.NAME_AND_ADDRESS,
                OnboardingSteps.BUSINESS_DETAILS,
                OnboardingSteps.TRADING_ADDRESS,
                OnboardingSteps.OWNER_DETAILS,
                OnboardingSteps.BUSINESS_PROFILE,
                OnboardingSteps.TRANSACTION_PROFILE,
                OnboardingSteps.PRODUCTS_REQUIRED,
                OnboardingSteps.UPLOAD_DOCUMENTS,
                OnboardingSteps.SUMMARY
            ];
            break;
    }

    const nextStep = (stepNumber) => {
        if (stepNumber) {
            setStep(stepNumber);
        } else {
            setStep(step + 1);
        }
    };
    const getComponent = (step) => {
        switch (step) {
            case 1:
                if (!isT2SMerchant) {
                    return <OnboardingNameAdress nextStep={nextStep} currentStep={step} />;
                } else {
                    return <T2SOnboardingNameAddress nextStep={nextStep} currentStep={step} />;
                }
            case 2:
                if (!isT2SMerchant) {
                    return <OnboardingBusinessDetailsStep nextStep={nextStep} currentStep={step} />;
                } else {
                    return <T2SOnboardingBankDetailsStep nextStep={nextStep} currentStep={step} />;
                }
            case 3:
                if (!isT2SMerchant) {
                    return <OnboardingAdressStep nextStep={nextStep} currentStep={step} />;
                } else {
                    return <T2SOnboardingAdressStep nextStep={nextStep} currentStep={step} />;
                }
            case 4:
                if (!isT2SMerchant) {
                    return <OnboardingOwnerDetails nextStep={nextStep} currentStep={step} />;
                } else {
                    return <T2SOnboardingOwnerDetails nextStep={nextStep} currentStep={step} />;
                }
            case 5:
                if (!isT2SMerchant) {
                    return <OnboardingBusinessProfile nextStep={nextStep} currentStep={step} />;
                } else {
                    return <OnboardingDocuments nextStep={nextStep} currentStep={step} />;
                }
            case 6:
                if (!isT2SMerchant) {
                    return <OnboardingTransactionProfile nextStep={nextStep} currentStep={step} />;
                } else {
                    return <OnboardingSummary nextStep={nextStep} currentStep={step} />;
                }
            case 7:
                if (!isT2SMerchant) {
                    return <ProductsRequired nextStep={nextStep} currentStep={step}></ProductsRequired>;
                } else {
                    setStep(step + 1);
                    break;
                }
            case 8:
                return <OnboardingDocuments nextStep={nextStep} currentStep={step} />;
            case 9:
                return <OnboardingSummary nextStep={nextStep} currentStep={step} />;
            default:
                return <div>Component nr. {step}</div>;
        }
    };

    return (
        <OnboardingFormProvider>
            <OnboardingComponent steps={steps} getComponent={getComponent} step={step} setStep={setStep} />
        </OnboardingFormProvider>
    );
}

export default Onboarding;
