import React from 'react';
import { getComparator, stableSort, formatDateTimeByFormatString } from 'utils/helper';
import { Link } from 'react-router-dom';
import TableHeader from 'components/payments/transaction-tables/table-header/table-header.container';
import {
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableContainer,
    Paper,
    CircularProgress,
    TableFooter,
    TablePagination,
    Box,
    makeStyles,
    Button
} from '@material-ui/core';
import style from './payout-not-received.module.scss';
import { payoutStatus } from 'utils/enums/payoutStatusToDate';
import { ModalPayoutsMarkNotReceived, SnackBarAndAlert } from 'components';
import { useGlobalStateContext } from 'contexts';
import { formatCurrency } from 'utils/helper';
import { getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry';
const useStyles = makeStyles(() => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    root: {
        width: '100%',
        'border-top': '1px solid #ddd',
        'margin-top': '10px'
    },
    paper: {
        width: '100%',
        boxShadow: 'none'
    },
    container: {
        maxHeight: '100%'
    },
    table: {
        minWidth: '100%'
    },
    tableRow: {
        '&$selected, &$selected:hover': {
            backgroundColor: '#e4e1fe'
        }
    }
}));
const PayoutsNotReceivedsComponent = (props) => {
    const {
        loading,
        error,
        page,
        rowsPerPage,
        rows,
        handleChangePage,
        handleChangeRowsPerPage,
        onGoToAccount,
        count,
        order,
        orderBy,
        markPayout,
        setMarkPayout,
        open,
        setOpen,
        onMarkConfirm,
        message,
        snackbar,
        handleClose
    } = props;
    const classes = useStyles();
    const { globalState } = useGlobalStateContext();
    return (
        <React.Fragment>
            <Paper className={style.paper}>
                {loading ? (
                    <Box textAlign="center" py={5}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <div className={style.refundsNone}>An error occurred, please try reloading your browser.</div>
                ) : (
                    <React.Fragment>
                        {!rows || rows.length === 0 ? (
                            <div className={style.refundsNone}>No Records</div>
                        ) : (
                            <React.Fragment>
                                <TableContainer className={style.TableContainer}>
                                    <Table
                                        aria-labelledby="tableTitle"
                                        size="small"
                                        stickyHeader
                                        component={Paper}
                                        sx={{ minWidth: 650 }}
                                        aria-label="enhanced table"
                                    >
                                        <TableHeader classes={classes} {...props} />

                                        <TableBody>
                                            {stableSort(
                                                rowsPerPage > 0
                                                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : rows,
                                                getComparator(order, orderBy)
                                            ).map((row) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell className={style.nameCell && style.tableDataCell}>
                                                            <Link onClick={() => onGoToAccount(row.merchant_id)}>
                                                                <span>{row.merchant_id}</span>
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell className={style.nameCell && style.tableDataCell}>
                                                            {row.business_name}
                                                        </TableCell>
                                                        <TableCell className={style.tableDataCell}>
                                                            {formatDateTimeByFormatString(
                                                                row.not_received_date,
                                                                'DD MMM YYYY'
                                                            )}
                                                        </TableCell>
                                                        <TableCell className={style.tableDataCell}>
                                                            {formatCurrency(
                                                                row.total,
                                                                getCurrencyThreeAlpha(globalState.merchant?.country)
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className={style.buttons}>
                                                                <Button
                                                                    color="primary"
                                                                    variant="contained"
                                                                    size="medium"
                                                                    onClick={() => {
                                                                        setMarkPayout({
                                                                            batch_id: row.batch_id,
                                                                            status: payoutStatus.RESEND
                                                                        });
                                                                        setOpen(true);
                                                                    }}
                                                                    className={style.resendBtns}
                                                                >
                                                                    Resend Payout
                                                                </Button>
                                                                <Button
                                                                    color="primary"
                                                                    variant="outlined"
                                                                    size="medium"
                                                                    onClick={() => {
                                                                        setMarkPayout({
                                                                            batch_id: row.batch_id,
                                                                            status: payoutStatus.CANCEL
                                                                        });
                                                                        setOpen(true);
                                                                    }}
                                                                    className={style.resendBtns}
                                                                >
                                                                    Cancel Payout
                                                                </Button>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[20, 50, 100]}
                                                    colSpan={8}
                                                    count={count}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        inputProps: { 'aria-label': 'rows per page' },
                                                        native: true
                                                    }}
                                                    onChangePage={handleChangePage}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>

                                {message && (
                                    <SnackBarAndAlert open={message} onClose={handleClose} type={snackbar.type}>
                                        {snackbar.message}
                                    </SnackBarAndAlert>
                                )}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </Paper>
            <ModalPayoutsMarkNotReceived
                markPayout={markPayout}
                open={open}
                setmarkPayout={setMarkPayout}
                setOpen={setOpen}
                error={error}
                onmarkConfirm={onMarkConfirm}
                isLoading={loading}
            />
        </React.Fragment>
    );
};

export default PayoutsNotReceivedsComponent;
