import React, { useState } from 'react';
import PayoutsListTableComponent from './payouts-list-table.component.jsx';
import PropTypes from 'prop-types';
import { useGlobalStateContext } from 'contexts';
import { UserType } from 'utils/enums/UserType';
import { PayoutsService } from 'services';
import { MerchantCountries } from 'utils/enums/MerchantCountries';

const PayoutsListTable = (props) => {
    const [order, setOrder] = useState('desc');
    const { globalState } = useGlobalStateContext();
    const [orderBy, setOrderBy] = useState('payoutExpectedDate');
    const [selected, setSelected] = useState([]);
    const { payouts, loading, isOwner, error, notInBatchPayouts, date, page, setPage, refresh, setRefresh } = props;

    const [openedBatchTabs, setOpenedBatchTabs] = useState([]);
    const [openedNotBatchTabs, setOpenedNotBatchTabs] = useState([]);

    const [markPayout, setmarkPayout] = useState('');
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(false);
    const [message, setMessage] = useState(false);
    const [snackbar, SetSnackbar] = useState({ type: '', message: '' });

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const isAdminUserType =
        globalState.user?.UserType?.name === UserType.Admin || globalState.user?.UserType?.name === UserType.SuperAdmin;
    const isValidMerchant =
        globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM ||
        globalState.merchant?.country === MerchantCountries.IRELAND;
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = props.payouts.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const totalPayouts = () => {
        let total =
            payouts.reduce((accumulator, payout) => {
                return accumulator + Number(payout.total);
            }, 0) +
            notInBatchPayouts.reduce((accumulator, payout) => {
                return accumulator + Number(payout.total);
            }, 0);

        return total;
    };

    const headCells = [
        { id: 'amount', numeric: true, disablePadding: false, label: 'Amount' },

        {
            id: 'payoutExpectedDate',
            numeric: false,
            disablePadding: false,
            label: 'Date expected'
        },
        { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
        { id: 'SourceId', numeric: false, disablePadding: false, label: 'Source ID', notShow: !isAdminUserType },
        {
            id: 'More Options',
            numeric: false,
            disablePadding: false,
            label: 'More options',
            notShow: !isAdminUserType || !isValidMerchant
        }
    ];
    function handleOpenTableRow(openedTabsState, setOpenedTabsState, index) {
        const openedTabIndex = openedTabsState.findIndex((item) => item.tab === index);

        if (openedTabIndex !== -1) {
            openedTabsState[openedTabIndex].isOpen = !openedTabsState[openedTabIndex].isOpen;
        } else {
            openedTabsState.push({
                tab: index,
                isOpen: true
            });
        }
        setOpenedTabsState((oldState) => [...oldState, openedTabsState]);
    }

    function isTabOpen(openedTabsState, index) {
        return openedTabsState.some((item) => item.tab === index && item.isOpen);
    }
    const onmarkConfirm = async (data) => {
        setIsLoading(true);
        const response = await PayoutsService.markPayoutNotReceived(data);

        if (response.isSuccesfully) {
            setOpen(false);
            setRefresh(!refresh);
            SetSnackbar({ type: 'success', message: response.data?.message });
        } else {
            setErrors(true);
            SetSnackbar({ type: 'error', message: response.data?.message });
        }
        setMessage(true);
        setIsLoading(false);
        setOpen(false);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            setMessage(false);
        }
    };
    return (
        <PayoutsListTableComponent
            page={page}
            setPage={setPage}
            date={date}
            rows={payouts}
            headCells={headCells}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            selected={selected}
            setSelected={setSelected}
            isSelected={isSelected}
            handleClick={handleClick}
            handleSelectAllClick={handleSelectAllClick}
            handleRequestSort={handleRequestSort}
            loading={loading}
            isAdminUserType={isAdminUserType}
            isOwner={isOwner}
            error={error}
            totalPayouts={totalPayouts}
            notInBatchPayouts={notInBatchPayouts}
            openedBatchTabs={openedBatchTabs}
            setOpenedBatchTabs={setOpenedBatchTabs}
            openedNotBatchTabs={openedNotBatchTabs}
            setOpenedNotBatchTabs={setOpenedNotBatchTabs}
            handleOpenTableRow={handleOpenTableRow}
            isTabOpen={isTabOpen}
            markPayout={markPayout}
            setmarkPayout={setmarkPayout}
            open={open}
            setOpen={setOpen}
            providerCode={props?.provider}
            onmarkConfirm={onmarkConfirm}
            isLoading={isLoading}
            errors={errors}
            isValidMerchant={isValidMerchant}
            message={message}
            snackbar={snackbar}
            handleClose={handleClose}
        />
    );
};

export default PayoutsListTable;

PayoutsListTable.propTypes = {
    payouts: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    notInBatchPayouts: PropTypes.array.isRequired
};
