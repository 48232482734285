import { ValidationMessage } from 'models';
import { ClientService } from 'services';

/**
 *
 * @param {string} clientName
 */
export function validateClientName(clientName) {
    if (clientName.length > 250) {
        return new ValidationMessage('Client name should have less then 250 characters', false);
    }

    if (clientName.length < 3) {
        return new ValidationMessage('Client name should have at least 3 characters', false);
    }
    return new ValidationMessage('', true);
}

/**
 *
 * @param {string} businessId
 * @param {string} clientName
 */
export async function validateClientNameUniqueness(businessId, clientName) {
    const result = await ClientService.checkClientNameIsUnique(businessId, clientName.trim());
    if (!result.data.isClientNameUnique) {
        return new ValidationMessage('Client name already exist', false);
    }
    return new ValidationMessage('', true);
}
