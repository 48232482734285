import React from 'react';
import { Dialog, DialogContent, Button, Typography, Box } from '@material-ui/core';
import styles from './modal-otp-verification.module.scss';
import { Form } from 'react-bootstrap';
import LinkMaterial from '@material-ui/core/Link';

export default function ModalOTPVerificationComponent(props) {
    const {
        onCancel,
        onVerify,
        onResend,
        openModalOtpVerification,
        phoneNumber,
        otpText,
        setOtpText,
        isLoading,
        error,
        seconds,
        isActiveTimer
    } = props;

    return (
        <Dialog
            open={openModalOtpVerification}
            className={{
                paper: styles.paper
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent className={styles.mainContainer}>
                <div className={styles.actionFormContainer}>
                    <Box>
                        <Typography color="primary">
                            <p>
                                <span className={styles.verificationTitle}>
                                    A verification code has been sent to this&nbsp;
                                    <span>
                                        <span className={styles.bold}>mobile number {phoneNumber}</span>. Please check
                                        if you don’t have this number anymore please contact support at
                                        +44(0)3330165548.&nbsp;
                                    </span>
                                </span>
                            </p>
                            <p className={styles.verificationSubtitle}>Code is valid for 5 minutes.</p>
                        </Typography>
                    </Box>
                    <div>
                        <Form.Group controlId="verificationCode" className="m-0">
                            <Form.Label>Verification code</Form.Label>
                            <Form.Control
                                type="number"
                                required
                                className={`${styles.otpField} ${otpText.length ? styles.active : ''}`}
                                autoFocus
                                disabled={isLoading}
                                placeholder="Enter verification code"
                                value={otpText}
                                onChange={(e) => {
                                    if (e.target.value.length <= 6) {
                                        setOtpText(e.target.value);
                                    }
                                }}
                            />
                            <Form.Control.Feedback className={styles.invalidFeedback} type="invalid">
                                {error ? error : <>&nbsp;</>}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <div className={styles.buttonContainer}>
                            <div className={styles.resendBox}>
                                <Button
                                    className={styles.resendButton}
                                    disabled={isLoading || isActiveTimer}
                                    onClick={onResend}
                                    variant="contained"
                                >
                                    RESEND
                                </Button>
                            </div>
                            <div className={styles.submitBox}>
                                <Button
                                    className={styles.submitButton}
                                    id="confirmVeirifcationCode"
                                    type="button"
                                    variant="contained"
                                    color="danger"
                                    disabled={isLoading || otpText.length !== 6}
                                    onClick={onVerify}
                                >
                                    Verify code{'   '}
                                </Button>
                            </div>
                        </div>
                        <p className={styles.resendButtonText}>
                            {isActiveTimer ? <>Retry after {seconds} seconds</> : <>&nbsp;</>}
                        </p>
                        <div>
                            <Typography color="primary">
                                <LinkMaterial component="button" onClick={onCancel}>
                                    Cancel
                                </LinkMaterial>
                            </Typography>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
