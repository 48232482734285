import React from 'react';
import ListRefundComponent from './list-refund.component';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { AdminService } from 'services';
import { RefundStatus } from '../../utils/enums/RefundStatus';

const RefundList = (props) => {
    const [offrange, setOffrange] = useState(true);
    const [refundStatusFilter, setRefundStatusFilter] = useState(RefundStatus.All);
    const [listRefunds, setListRefunds] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { path, range, date, via } = props;
    const [message, setMessage] = useState('');
    const [refundStatus, setRefundStatus] = useState(RefundStatus.All);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [refSearchTerm, setRefSearchTerm] = useState('');
    const [searchMerchant, setSearchMerchant] = useState('');
    const [refundProcessConfirmation, setRefundProcessConfirmation] = useState(false);
    const [refundPaymentId, setRefundPaymentId] = useState({});
    const [count, setCount] = useState(0);

    useEffect(() => {
        getRefundList(0);
        // eslint-disable-next-line
    }, [date, via, refundStatusFilter]);
    const getRefundList = async (newPage, newRowsPerPage) => {
        setLoading(true);
        const thePage = newPage ?? page;
        const theRowsPerPage = newRowsPerPage ?? rowsPerPage;
        const day = moment(date, 'YYYY-MM-DD').date();
        const month = moment(date, 'YYYY-MM-DD').month() + 1;
        const year = moment(date, 'YYYY-MM-DD').year();

        let payload = {
            month,
            year,
            status: refundStatusFilter,
            offset: thePage * theRowsPerPage,
            limit: theRowsPerPage
        };
        searchMerchant && (payload = { ...payload, name: searchMerchant });
        refSearchTerm && (payload = { ...payload, crossReference: refSearchTerm });
        if (via === 'day') {
            payload = {
                ...payload,
                day
            };
        }
        const responseList = await AdminService.getRefundList(payload);
        if (responseList.isSuccesfully) {
            setListRefunds(responseList.data.data);
            setCount(responseList.data.count);
        } else {
            setError(true);
        }

        setLoading(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getRefundList(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        getRefundList(0, parseInt(event.target.value, 10));
        setPage(0);
    };

    const onCancelRefund = () => {
        setRefundProcessConfirmation(false);
        setRefundPaymentId({});
    };

    const onRefundProcess = async (payment_id, status) => {
        setLoading(true);
        const response = await AdminService.UpdateRefundStatus({
            payment_id,
            status
        });
        if (response.isSuccesfully) {
            setRefundStatus(response.data.status);
            setMessage(response.data.message);
        } else {
            setRefundStatus('error');
            setMessage('An error occured. Please contact support or try again');
        }
        await getRefundList(page, rowsPerPage);
        setLoading(false);
        setRefundProcessConfirmation(false);
        setRefundPaymentId({});
    };

    const onCombinedSearch = () => {
        getRefundList(0);
    };
    useEffect(() => {
        const urls = {
            next: `/${path}/${range}/`,
            previous: `/${path}/${range}/`,
            range: date
        };

        switch (range) {
            case 'day': {
                urls.range = moment(date).format('DD MMM YY');
                urls.previous += moment(date).subtract(1, 'day').format('YYYY-MM-DD');
                urls.next += moment(date).add(1, 'day').format('YYYY-MM-DD');
                break;
            }
            case 'month': {
                urls.range = moment(date).format('MMMM / YYYY');
                urls.previous += moment(date).subtract(1, 'month').format('YYYY-MM-DD');
                urls.next += moment(date).add(1, 'month').format('YYYY-MM-DD');
                break;
            }
            default:
                break;
        }
    }, [path, range, date, offrange]);

    let headCells = [
        {
            id: 'business',
            numeric: false,
            disablePadding: false,
            label: 'Business',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'paymentreference',
            numeric: false,
            disablePadding: false,
            label: 'Payment reference',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'firstName',
            numeric: false,
            disablePadding: false,
            label: 'First name',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'lastName',
            numeric: false,
            disablePadding: false,
            label: 'Last name',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'address',
            numeric: false,
            disablePadding: false,
            label: 'Address',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'refund',
            numeric: false,
            disablePadding: false,
            label: 'Refund',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'date',
            numeric: false,
            disablePadding: false,
            label: 'Date',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'refundStatus',
            numeric: false,
            disablePadding: false,
            label: 'Refund status',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'view',
            numeric: false,
            disablePadding: false,
            label: 'View',
            hideSortingIcon: true,
            alignCenter: true
        }
    ];

    return (
        <ListRefundComponent
            via={via}
            date={date}
            setOffrange={setOffrange}
            rows={listRefunds}
            loading={loading}
            error={error}
            onRefundProcess={onRefundProcess}
            refundStatusFilter={refundStatusFilter}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            message={message}
            refundStatus={refundStatus}
            setRefSearchTerm={setRefSearchTerm}
            setSearchMerchant={setSearchMerchant}
            setRefundStatus={setRefundStatus}
            headCells={headCells}
            refSearchTerm={refSearchTerm}
            searchMerchant={searchMerchant}
            refundProcessConfirmation={refundProcessConfirmation}
            setRefundProcessConfirmation={setRefundProcessConfirmation}
            setRefundPaymentId={setRefundPaymentId}
            refundPaymentId={refundPaymentId}
            onCombinedSearch={onCombinedSearch}
            setRefundStatusFilter={setRefundStatusFilter}
            onCancelRefund={onCancelRefund}
            count={count}
        />
    );
};
export default RefundList;

RefundList.propTypes = {
    onChangeDateRange: PropTypes.func,
    path: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    offrange: PropTypes.bool,
    setOffrange: PropTypes.func,
    locationInApp: PropTypes.string
};
