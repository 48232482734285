import React, { useState } from 'react';
import MerchantChargesComponent from './merchant-charges.component';

const MerchantChargesList = (props) => {
    const [loading, setLoading] = useState(true);
    const [openMerchantModal, setOpenMerchantModal] = useState(false);
    return (
        <MerchantChargesComponent
            loading={loading}
            setLoading={setLoading}
            {...props}
            openMerchantModal={openMerchantModal}
            setOpenMerchantModal={setOpenMerchantModal}
        />
    );
};

export default MerchantChargesList;
