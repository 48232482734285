import React, { useState, useEffect } from 'react';
import ContractDetailsComponent from './contract-details.component';
import { useGlobalStateContext } from 'contexts';
import { AdminService } from 'services';

function ContractDetails() {
    const [addSpecialRent, setAddSpecialRent] = useState(false);
    const [specialRentDetails, setSpecialRentDetails] = useState({});
    const [specialRentDelete, setSpecialRentDelete] = useState(false);
    const [rentDeleteConfirm, setRentDeleteConfirm] = useState(false);
    const [deleteRentId, setDeleteRentId] = useState(0);
    const [contractRentDetails, setContractRentDetails] = useState({
        progress_status: 'Dormant',
        setup_charged: false
    });
    const [updateSpecialRent, setUpdateSpecialRent] = useState(false);
    const { globalState } = useGlobalStateContext();
    const [specialRentList, setSpecialRentList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [disableBtn, setDisableBtn] = useState(true);
    const [contractBtn, setContractBtn] = useState(true);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const { start_date, end_date, rent_amount, description } = specialRentDetails;
        let checkValues = start_date && end_date && rent_amount && description;
        setDisableBtn(!checkValues);

        const { contract_rent, contract_length, notice_period, setup_fee } = contractRentDetails;

        let checkContract = contract_rent && contract_length && notice_period && setup_fee;
        setContractBtn(!checkContract);
    }, [specialRentDetails, globalState.merchant.id, contractRentDetails]);
    const fetchSpecialRentList = async () => {
        const response = await AdminService.GetSpecialRentList();
        if (response.isSuccesfully) {
            setSpecialRentList(response.data);
        }
        setLoading(false);
    };
    useEffect(() => {
        setLoading(true);
        fetchSpecialRentList();
        // eslint-disable-next-line
    }, [globalState.merchant?.id]);

    useEffect(() => {
        const fetchContractRent = async () => {
            const response = await AdminService.GetContractRent();
            if (response.isSuccesfully) {
                const data = { ...response.data.data, setup_charged: false };
                setContractRentDetails(data);
            }
        };
        fetchContractRent();
    }, [globalState.merchant.id]);

    const submitSpecialRent = async () => {
        setAddSpecialRent(false);
        setLoading(true);
        const { start_date, end_date, rent_amount, description } = specialRentDetails;
        const response = await AdminService.AddSpecialRent({
            start_date,
            end_date,
            rent_amount,
            description
        });
        if (response.isSuccesfully) {
            await fetchSpecialRentList();
            setSuccess(true);
            setMessage('Successfully added the rent');
        } else {
            setError(true);
            setMessage(response.data.message);
        }
        setLoading(false);
    };
    const editSpecialRent = (rent_id) => {
        setUpdateSpecialRent(true);
        const response = specialRentList.find((list) => list.rent_id === rent_id);
        setSpecialRentDetails(response);
    };

    const updateSpecialRentData = async () => {
        setUpdateSpecialRent(false);
        setLoading(true);
        const { rent_id, start_date, end_date, rent_amount, description } = specialRentDetails;
        const response = await AdminService.UpdateSpecialRent({
            rent_id,
            start_date,
            end_date,
            rent_amount,
            description
        });
        if (response.isSuccesfully) {
            await fetchSpecialRentList();
            setSuccess(true);
            setMessage('Successfully updated the rent');
        } else {
            setError(true);
            setMessage(response.data.message);
        }
        setSpecialRentDetails({});
        setLoading(false);
    };

    const contractRentUpdate = async () => {
        setLoading(true);
        const {
            progress_status,
            contract_rent,
            contract_length,
            notice_period,
            setup_charged,
            setup_fee,
            extra_comments,
            services_description
        } = contractRentDetails;
        const response = await AdminService.UpdateContractRent({
            progress_status: progress_status,
            contract_rent: parseFloat(contract_rent),
            contract_length: parseInt(contract_length),
            notice_period: parseInt(notice_period),
            setup_charged: setup_charged,
            setup_fee: parseFloat(setup_fee),
            extra_comments: extra_comments,
            services_description: services_description
        });
        if (response.isSuccesfully) {
            setSuccess(true);
            setMessage('Information updated successfully');
            contractRentDetails.setup_charged &&
                setContractRentDetails({ ...contractRentDetails, setup_charged: false });
        } else {
            setError(true);
            setMessage(response.data.message);
        }
        setLoading(false);
    };

    const deleteSpecialRent = async () => {
        setLoading(true);
        const deleteRent = await AdminService.DeleteSpecialRent({ rent_id: deleteRentId });
        if (deleteRent.isSuccesfully) {
            setSuccess(true);
            setMessage('Special rent deleted successfully');
            await fetchSpecialRentList();
        } else {
            setError(true);
            setMessage('An error has occurred. Please try again later.');
        }
        setDeleteRentId(0);
        setLoading(false);
    };
    return (
        <ContractDetailsComponent
            deleteRentId={deleteRentId}
            addSpecialRent={addSpecialRent}
            setAddSpecialRent={setAddSpecialRent}
            specialRentDetails={specialRentDetails}
            setSpecialRentDetails={setSpecialRentDetails}
            specialRentDelete={specialRentDelete}
            setSpecialRentDelete={setSpecialRentDelete}
            rentDeleteConfirm={rentDeleteConfirm}
            setRentDeleteConfirm={setRentDeleteConfirm}
            contractRentDetails={contractRentDetails}
            setContractRentDetails={setContractRentDetails}
            submitSpecialRent={submitSpecialRent}
            updateSpecialRent={updateSpecialRent}
            editSpecialRent={editSpecialRent}
            specialRentList={specialRentList}
            setUpdateSpecialRent={setUpdateSpecialRent}
            updateSpecialRentData={updateSpecialRentData}
            setDeleteRentId={setDeleteRentId}
            loading={loading}
            disableBtn={disableBtn}
            contractBtn={contractBtn}
            contractRentUpdate={contractRentUpdate}
            success={success}
            error={error}
            message={message}
            setMessage={setMessage}
            setError={setError}
            setSuccess={setSuccess}
            deleteSpecialRent={deleteSpecialRent}
        />
    );
}

export default ContractDetails;
