import moment from 'moment-timezone';

import React from 'react';
import {
    MerchantDashboardOptions,
    MerchantDashboardPreview,
    BalanceCard,
    PayoutCard,
    LoadingScreen,
    SnackBarAndAlert,
    ModalFormConfirmation,
    Banner
} from 'components';
import WithdrawalContainer from '../withdrawal/withdrawal.container';
import { MerchantCountries } from '../../utils/enums/MerchantCountries';
import style from './dashboard.module.scss';
import { useGlobalStateContext } from 'contexts';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { ErrorOutline } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Grid, Typography, useMediaQuery, Link } from '@material-ui/core';
import { PaymentsListTable } from 'components/payments/transaction-tables';
import { ResellerType } from 'utils/enums/ResellerType';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green } from '@material-ui/core/colors';
import { UserType } from 'utils/enums/UserType';
import { CheckMerchantCountries } from '../../components/onboarding/onboarding-ui-components/check-merchant-countries/check-merchant-countries';
import { DocumentStatusToName } from 'utils/enums/DocumentReviewStatus';
import { DocumentTypesToName } from 'utils/enums/DocumentType';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { cleverTapClick } from 'utils/helper';
import { bannerMessage } from 'utils/banner-message';
import _ from 'lodash';

function DashboardComponent(props) {
    const { globalState, setGlobalState, setRoute } = useGlobalStateContext();
    const areLoadedMerchants = globalState.areLoadedMerchants;
    const merchantStatus = globalState.merchant?.status;
    const {
        isVerifiedBank,
        businessType,
        error,
        loading,
        payments,
        total,
        dateFormat,
        bankUpload,
        accountUpdated,
        querySubmitted,
        setQuerySubmitted,
        sendVerificationEmail,
        showMessage,
        setShowMessage,
        verificationEmailMessage,
        messageType,
        enableAutoWithdrawals,
        errorMessage,
        setErrorMessage,
        openModal,
        setOpenModal,
        pageLoading,
        bankDocStatus,
        accountDocStatus,
        rejectedDoc,
        nextPayoutDay,
        snackbar,
        userHasSeenSnackbar,
        openAdyen,
        setOpenAdyen,
        getBalancePlatformOnboardingUrl,
        adyenAccount
    } = props;
    const mediaLessThan450px = useMediaQuery('(max-width:449px)');
    const mediaLessThan650px = useMediaQuery('(max-width:649px)');
    const datmanMerchant = globalState.reseller?.name === ResellerType.DATMAN;
    const isEmailConfirmed = globalState?.user?.isEmailConfirmed;
    const userEmail = globalState?.user?.email;
    const isAdmin =
        globalState.user?.UserType?.name === UserType.Admin || globalState.user?.UserType?.name === UserType.SuperAdmin;
    const isMerchant = globalState.user?.UserType?.name === UserType.Merchant;
    const autoWithdrawStatus = globalState.merchant?.autoWithdraw && globalState.reseller?.id === 2;
    const isUkMerchant = globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM;
    const passwordAge = moment().diff(moment(globalState.user.updatedPasswordAt), 'days');
    const passwordExpiryDate = moment(globalState.user.updatedPasswordAt).add(90, 'days').format('DD-MM-YYYY');
    const passwordExpiryBannerVisibility = 75; // After 75 days of last password update we will show the banner
    const isMerchantAccountClosed = merchantStatus === MerchantStatus.CLOSED;

    const calculateFontSize = (balanceLength) => {
        let fontSizeBalance =
            balanceLength < 6
                ? 40
                : 40 -
                  (balanceLength < 8
                      ? (balanceLength - 5) * 4
                      : balanceLength < 10
                      ? 8 + (balanceLength - 7) * 3
                      : balanceLength < 13
                      ? 14 + (balanceLength - 9) * 2
                      : 20 + balanceLength - 12);

        if (mediaLessThan450px) {
            fontSizeBalance = fontSizeBalance < 37 ? fontSizeBalance + 3 : 40;
        } else if (mediaLessThan650px) {
            fontSizeBalance = fontSizeBalance < 30 ? fontSizeBalance + 10 : 40;
        }

        return {
            fontSize: fontSizeBalance
        };
    };

    const showBanner =
        (datmanMerchant &&
            !isVerifiedBank &&
            !pageLoading &&
            (bankDocStatus === DocumentStatusToName.NEED_APPROVAL ||
                accountDocStatus === DocumentStatusToName.NEED_APPROVAL ||
                bankDocStatus === DocumentStatusToName.REJECTED ||
                accountDocStatus === DocumentStatusToName.REJECTED)) ||
        (autoWithdrawStatus && !isVerifiedBank && datmanMerchant && isUkMerchant) ||
        (!autoWithdrawStatus &&
            datmanMerchant &&
            isMerchant &&
            globalState.merchant?.status !== MerchantStatus.WATCHLIST &&
            isUkMerchant) ||
        !isEmailConfirmed ||
        passwordAge >= passwordExpiryBannerVisibility ||
        (globalState.merchant?.isAdyenBalancePlatformAccount && !globalState.merchant?.isAllCapabilitiesAllowed);

    return (
        <div>
            {areLoadedMerchants && (
                <Grid container>
                    {isMerchantAccountClosed && <Banner message={bannerMessage.CLOSED_MERCHANT_ACCOUNT} />}
                    {merchantStatus !== MerchantStatus.ACTIVE && merchantStatus !== MerchantStatus.WATCHLIST ? (
                        <Grid className={style.dashboardContentContainer} item xs={12}>
                            <Grid item xs={12}>
                                <MerchantDashboardPreview />
                            </Grid>
                            <Grid item xs={12}>
                                <MerchantDashboardOptions />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <WithdrawalContainer />
                            </Grid>
                            {isAdmin && datmanMerchant && (
                                <Grid className={style.dashboardContentContainer} item xs={12}>
                                    <MerchantDashboardPreview />
                                </Grid>
                            )}
                            {/* <Grid item xs={12}>
                                <ApiKeys />
                            </Grid> */}
                            {!pageLoading && (
                                <>
                                    {bankUpload && (
                                        <div className={style.listItemContainer}>
                                            <div className={style.iconVerified}>
                                                <CheckCircleIcon style={{ color: green[500], fontSize: 22 }} />
                                            </div>
                                            <Typography color="primary">
                                                <React.Fragment>
                                                    <div>
                                                        Your Update Bank Details request has been submitted. This
                                                        process can take up to 14 days..&nbsp;
                                                    </div>
                                                </React.Fragment>
                                            </Typography>
                                        </div>
                                    )}
                                    {accountUpdated && (
                                        <div className={style.listItemContainer}>
                                            <div className={style.iconVerified}>
                                                <CheckCircleIcon style={{ color: green[500], fontSize: 22 }} />
                                            </div>
                                            <Typography color="primary">
                                                <div>
                                                    Your 'Update Account Details' request has been submitted. This
                                                    process can take up to 14 days.&nbsp;
                                                </div>
                                            </Typography>
                                        </div>
                                    )}
                                    {showBanner && (
                                        <div className={`${style.bannerStyle}`}>
                                            {passwordAge >= passwordExpiryBannerVisibility && (
                                                <div className={`${style.bannerMessageStyle}`}>
                                                    <div className={style.iconUnverified}>
                                                        <ErrorOutline className={style.error_icon} />
                                                    </div>
                                                    <Typography color="primary">
                                                        <div className={style.bannerMessage}>
                                                            Your password will expire on {passwordExpiryDate}, please{' '}
                                                            <span
                                                                className={style.passwordExpiryBanner}
                                                                onClick={() => setRoute('/update-password')}
                                                            >
                                                                update your password.
                                                            </span>
                                                            &nbsp;
                                                        </div>
                                                    </Typography>
                                                </div>
                                            )}
                                            {datmanMerchant && !isVerifiedBank && !pageLoading && (
                                                <>
                                                    {(bankDocStatus === DocumentStatusToName.NEED_APPROVAL ||
                                                        accountDocStatus === DocumentStatusToName.NEED_APPROVAL) && (
                                                        <div className={`${style.bannerMessageStyle}`}>
                                                            <div className={style.iconUnverified}>
                                                                <ErrorOutline className={style.error_icon} />
                                                            </div>
                                                            <Typography color="primary">
                                                                <React.Fragment>
                                                                    <div className={style.bannerMessage}>
                                                                        We’ve received your documents for verification.
                                                                        Our team will review and verify the documents.
                                                                    </div>
                                                                </React.Fragment>
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    {(accountDocStatus === DocumentStatusToName.REJECTED ||
                                                        bankDocStatus === DocumentStatusToName.REJECTED) && (
                                                        <div className={`${style.bannerMessageStyle}`}>
                                                            <div className={style.iconUnverified}>
                                                                <ErrorOutline className={style.error_icon} />
                                                            </div>
                                                            <Typography color="primary">
                                                                <div className={style.bannerMessage}>
                                                                    Your account is not yet fully verified, please
                                                                    upload the correct{' '}
                                                                    {rejectedDoc.map((doc, index) => {
                                                                        return `${DocumentTypesToName[doc]}${
                                                                            rejectedDoc.length - 1 === index
                                                                                ? '.'
                                                                                : rejectedDoc.length - 2 === index
                                                                                ? ' and '
                                                                                : ', '
                                                                        }`;
                                                                    })}
                                                                    &nbsp;
                                                                    <span
                                                                        className={style.click__here}
                                                                        onClick={() =>
                                                                            setRoute('/upload-documents', {
                                                                                businessType: businessType
                                                                            })
                                                                        }
                                                                    >
                                                                        Click here
                                                                    </span>
                                                                </div>
                                                            </Typography>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                            {autoWithdrawStatus && !isVerifiedBank && datmanMerchant && (
                                                <CheckMerchantCountries
                                                    includedCountries={[MerchantCountries.UNITED_KINGDOM]}
                                                >
                                                    <div className={`${style.bannerMessageStyle}`}>
                                                        <div className={style.iconUnverified}>
                                                            <ErrorOutline className={style.error_icon} />
                                                        </div>
                                                        <Typography color="primary">
                                                            <React.Fragment>
                                                                <div className={style.bannerMessage}>
                                                                    You have automatic payouts enabled. Once your
                                                                    accounts is verified, available funds will be sent
                                                                    to your account on {nextPayoutDay}
                                                                </div>
                                                            </React.Fragment>
                                                        </Typography>
                                                    </div>
                                                </CheckMerchantCountries>
                                            )}
                                            {!isEmailConfirmed && (
                                                <div className={style.bannerMessageStyle}>
                                                    <div className={style.iconUnverified}>
                                                        <ErrorOutline className={style.error_icon} />
                                                    </div>
                                                    <Typography color="primary" style={{ fontSize: '15px' }}>
                                                        The email&nbsp;
                                                        <Link
                                                            className={`${style.textBold} ${style.linkFont}`}
                                                            href="#"
                                                            variant="body2"
                                                        >
                                                            {userEmail}
                                                        </Link>
                                                        {' is not verified. Click '}
                                                        <span
                                                            className={`${style.textBold} ${style.linkFont}`}
                                                            onClick={sendVerificationEmail}
                                                        >
                                                            here
                                                        </span>
                                                        {' to receive verification email.'}
                                                    </Typography>
                                                </div>
                                            )}
                                            {!autoWithdrawStatus &&
                                                datmanMerchant &&
                                                isMerchant &&
                                                globalState.merchant?.status !== MerchantStatus.WATCHLIST && (
                                                    <CheckMerchantCountries
                                                        includedCountries={[MerchantCountries.UNITED_KINGDOM]}
                                                    >
                                                        <div
                                                            className={style.bannerMessageStyle}
                                                            // style={{ padding: '0 20px' }}
                                                        >
                                                            <div className={style.iconUnverified}>
                                                                <InfoOutlinedIcon className={style.info_icon} />
                                                            </div>
                                                            <Typography color="primary">
                                                                <React.Fragment>
                                                                    <div className={style.bannerMessage}>
                                                                        Click&nbsp;
                                                                        <span
                                                                            className={`${style.textBold} ${style.linkFont}`}
                                                                            onClick={() => setOpenModal(true)}
                                                                        >
                                                                            here
                                                                        </span>
                                                                        &nbsp;to enable the auto withdrawals.
                                                                    </div>
                                                                </React.Fragment>
                                                            </Typography>
                                                        </div>
                                                    </CheckMerchantCountries>
                                                )}
                                            {globalState.merchant?.isAdyenBalancePlatformAccount &&
                                                !globalState.merchant?.isAllCapabilitiesAllowed &&
                                                !_.isEmpty(adyenAccount.reason) && (
                                                    <div
                                                        className={`${style.bannerMessageStyle}`}
                                                        style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                                                    >
                                                        <div style={{ display: 'flex' }}>
                                                            <div className={style.iconUnverified}>
                                                                <ErrorOutline className={style.error_icon} />
                                                            </div>
                                                            <Typography color="primary">
                                                                <React.Fragment>
                                                                    <div className={style.bannerMessage}>
                                                                        <span
                                                                            onClick={() => setOpenAdyen(true)}
                                                                            className={style.click__here}
                                                                        >
                                                                            Click here
                                                                        </span>{' '}
                                                                        To Provide below details to activate payouts for
                                                                        Google and terminal payments
                                                                    </div>
                                                                </React.Fragment>
                                                            </Typography>
                                                        </div>
                                                        <ul style={{ color: '#F44336', marginLeft: '32px' }}>
                                                            {Object.values(adyenAccount.reason).map((el) => {
                                                                return el.map((e) => {
                                                                    return (
                                                                        <li>
                                                                            <Typography
                                                                                color="error"
                                                                                style={{
                                                                                    marginLeft: '5px',
                                                                                    fontSize: '14px'
                                                                                }}
                                                                            >
                                                                                {e.messages}
                                                                            </Typography>
                                                                        </li>
                                                                    );
                                                                });
                                                            })}
                                                        </ul>
                                                    </div>
                                                )}
                                        </div>
                                    )}
                                </>
                            )}

                            {autoWithdrawStatus && isVerifiedBank && datmanMerchant && (
                                <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_KINGDOM]}>
                                    <div
                                        className={`${style.listItemContainer}
                                ${style.automaticPayout}`}
                                    >
                                        <Typography color="primary">
                                            <React.Fragment>
                                                <div className={style.bannerMessage} style={{ marginLeft: '20px' }}>
                                                    You have automatic Payouts enabled, available funds will be paid to
                                                    you {nextPayoutDay}
                                                </div>
                                            </React.Fragment>
                                        </Typography>
                                    </div>
                                </CheckMerchantCountries>
                            )}
                            <div>
                                <Grid container item xs={12} className={style.graphContainer}>
                                    <div className={style.paymentsContainer}>
                                        <div className={style.titlePaymentsTable}>
                                            <span className={style.title}>Payments</span>
                                            <span
                                                className={style.clickPayments}
                                                onClick={() => {
                                                    setRoute(`/payments/day/${dateFormat}`);
                                                    cleverTapClick('See_all_click', globalState, {});
                                                }}
                                            >
                                                See all
                                            </span>
                                        </div>
                                        <PaymentsListTable
                                            error={error}
                                            payments={payments}
                                            total={total}
                                            noTabs={true}
                                            channelFilter="All"
                                            pagePath="dashboard"
                                            showCancelBtn="false"
                                        />
                                    </div>

                                    {datmanMerchant && (
                                        <div className={style.rightCardContainer}>
                                            <BalanceCard calculateFontSize={calculateFontSize} />
                                            <PayoutCard calculateFontSize={calculateFontSize} />
                                            {/* <NetVolumeCard calculateFontSize={calculateFontSize} /> */}
                                        </div>
                                    )}
                                </Grid>
                            </div>
                        </Grid>
                    )}
                </Grid>
            )}
            <LoadingScreen open={!areLoadedMerchants} />
            {globalState.wasDeletedMerchantName && (
                <SnackBarAndAlert
                    open={globalState.wasDeletedMerchantName}
                    onClose={() =>
                        setGlobalState({
                            ...globalState,
                            wasDeletedMerchantName: null
                        })
                    }
                    type="success"
                >
                    Account {globalState.wasDeletedMerchantName} was deleted successfully.
                </SnackBarAndAlert>
            )}
            {globalState.exitFromOnboarding && (
                <SnackBarAndAlert
                    open={globalState.exitFromOnboarding}
                    onClose={() =>
                        setGlobalState({
                            ...globalState,
                            exitFromOnboarding: false
                        })
                    }
                    type="success"
                >
                    Your submission has been saved and you can complete it at a later date.
                </SnackBarAndAlert>
            )}
            {querySubmitted && (
                <SnackBarAndAlert open={querySubmitted} onClose={() => setQuerySubmitted(false)} type="success">
                    Your query has been sent to our support team. We will get back to you shortly !
                </SnackBarAndAlert>
            )}
            {showMessage && (
                <SnackBarAndAlert open={showMessage} onClose={() => setShowMessage(false)} type={messageType}>
                    {verificationEmailMessage}
                </SnackBarAndAlert>
            )}
            {errorMessage && (
                <SnackBarAndAlert open={errorMessage} onClose={() => setErrorMessage(null)} type="error">
                    {errorMessage ? <h3>{errorMessage}</h3> : ''}
                </SnackBarAndAlert>
            )}
            {snackbar && !userHasSeenSnackbar && (
                <SnackBarAndAlert open={snackbar.length > 0} type="success">
                    {snackbar}
                </SnackBarAndAlert>
            )}
            <ModalFormConfirmation
                id="enable-auto-withdrawal"
                isModalOpen={openModal}
                setIsModalOpen={setOpenModal}
                confirmationAction={enableAutoWithdrawals}
                buttonText={'confirm'}
                modalText={`Are you sure you want to turn On Automatic withdrawals? This action cannot be reversed`}
            />
            <ModalFormConfirmation
                id="adyen-form"
                isModalOpen={openAdyen}
                setIsModalOpen={setOpenAdyen}
                confirmationAction={getBalancePlatformOnboardingUrl}
                buttonText={'continue'}
                modalText={`This link expires in 30 minutes, please return to the Datman Portal if you require more time to complete your application.`}
            />
            <LoadingScreen open={loading}></LoadingScreen>
        </div>
    );
}

DashboardComponent.propTypes = {
    isVerifiedBank: PropTypes.bool,
    businessType: PropTypes.string,
    error: PropTypes.bool,
    loading: PropTypes.bool,
    payments: PropTypes.array,
    total: PropTypes.number,
    dateFormat: PropTypes.string,
    accountUpdated: PropTypes.bool,
    bankUpload: PropTypes.bool,
    querySubmitted: PropTypes.bool,
    setQuerySubmitted: PropTypes.func,
    accountStatus: PropTypes.string
};

export default DashboardComponent;
