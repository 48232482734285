import React from 'react';
import { LinkQr } from 'components';
import styles from './link-qr.module.scss';
import { useGlobalStateContext } from 'contexts';

function LinkQrComponent(props) {
    const { globalState } = useGlobalStateContext();

    return (
        <div>
            <div className={styles.homeLogo}>
                <img src={globalState.reseller?.logo} alt="Logo"></img>
            </div>
            <LinkQr {...props} />
        </div>
    );
}
export default LinkQrComponent;
