import React from 'react';
import LinkQrComponent from './link-qr.component';
import { Redirect } from 'react-router-dom';
import { useGlobalStateContext } from 'contexts';
import { AuthService } from 'services';

function LinkQr(props) {
    const { getRoute } = useGlobalStateContext();

    if (!AuthService.isLoggedUser()) {
        return <Redirect to={getRoute(`/login${props.location.search}`)} />;
    }

    return <LinkQrComponent {...props} />;
}

export default LinkQr;
