import React from 'react';
import { Redirect } from 'react-router-dom';
import { useGlobalStateContext } from 'contexts';
import { UserType } from 'utils/enums/UserType';
import LoggedRoute from './logged-route';
import { MerchantStatus } from 'utils/enums/MerchantStatus';

const WorkingAccountRoute = ({ component: Component, ...rest }) => {
    const { globalState } = useGlobalStateContext();
    const user = globalState.user;
    const merchantStatus = globalState.merchant?.status;
    const allowedUser = user?.UserType?.name === UserType.SuperAdmin || merchantStatus !== MerchantStatus.CLOSED;
    return (
        <LoggedRoute
            {...rest}
            render={(props) =>
                allowedUser ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={
                            globalState.customDomain === false
                                ? `/${globalState.reseller?.portalURL}/not-authorized`
                                : '/not-authorized'
                        }
                    />
                )
            }
        />
    );
};

export default WorkingAccountRoute;
