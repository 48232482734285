import { unserialize } from 'php-serialize';
import { UrgentMessagesService } from 'services';

export const getT2SReferralLinkPayload = (encodedBase64, { globalState, setRoute, noRedirect }) => {
    let payload;
    try {
        payload = JSON.parse(atob(encodedBase64));
    } catch (ex) {
        try {
            payload = unserialize(atob(encodedBase64));
        } catch (ex) {
            if (noRedirect) {
                return {};
            }

            sessionStorage.setItem('generalPageError', 'Referral Link is invalid.');
            UrgentMessagesService.notifyApplicationFailures({
                resellerId: globalState?.reseller?.id,
                type: 'INVALID_FOODHUB_REFERRAL_LINK',
                details: {
                    location: window.location.href,
                    encodedBase64
                }
            });
            setRoute(`/login`);
        }
    }
    return fixReferralLinkPayload(payload);
};

function fixReferralLinkPayload(payload) {
    if (payload) {
        if (payload.OwnerEmail) {
            payload.OwnerEmail = payload.OwnerEmail.toLowerCase().replace(/ /g, '');
            payload.TradingName = payload.TradingName?.toString().trim() || '';
        }
    }
    return payload;
}
