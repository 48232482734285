import React from 'react';
import {
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    CircularProgress,
    TableFooter,
    Tooltip
} from '@material-ui/core';
import style from './bank-error-list.module.scss';
import { getComparator, stableSort } from '../../../utils/helper';
import TableHeader from '../../payments/transaction-tables/table-header/table-header.container';
import { Link } from 'react-router-dom';

const BankErrorComponent = (props) => {
    const {
        bankErrorList,
        page,
        rowsPerPage,
        count,
        loading,
        handleChangePage,
        handleChangeRowsPerPage,
        headCells,
        onGoToAccount
    } = props;

    return (
        <React.Fragment>
            <TableContainer className={style.tableContainer}>
                <Table stickyHeader>
                    <TableHeader headCells={headCells} />
                    {loading ? (
                        <TableBody>
                            <TableRow>
                                <TableCell colspan="4" className={style.noDataContainer}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ) : (
                        <React.Fragment>
                            {!bankErrorList || bankErrorList.length === 0 ? (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colspan="4" className={style.noDataContainer}>
                                            <div>No Data.</div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {stableSort(
                                        rowsPerPage > 0
                                            ? bankErrorList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : bankErrorList,
                                        getComparator()
                                    ).map((list, index) => {
                                        return (
                                            <TableRow>
                                                <TableCell align="left" className={style.tableHeader}>
                                                    {list.merchant_id}
                                                </TableCell>
                                                <TableCell className={style.nameColumn}>
                                                    <Tooltip
                                                        title={list.business_name}
                                                        className={style.merchantName}
                                                        placement="bottom-start"
                                                    >
                                                        <Link onClick={() => onGoToAccount(list.merchant_id)}>
                                                            <span>{list.business_name}</span>
                                                        </Link>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="left" className={style.tableHeader}>
                                                    {list.country_name}
                                                </TableCell>
                                                <TableCell align="left" className={style.tableHeader}>
                                                    {list.errors}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            )}
                        </React.Fragment>
                    )}

                    <TableFooter>
                        <TableRow>
                            {count > 20 && (
                                <TablePagination
                                    rowsPerPageOptions={[20, 50, 100, { label: 'All', value: -1 }]}
                                    count={bankErrorList.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            )}
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
};

export default BankErrorComponent;
