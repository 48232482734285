import { ValidationMessage } from 'models';

export function validateWithdraw(account, bank, override) {
    if (override) {
        return new ValidationMessage('', true);
    } else {
        if (!account && !bank) {
            return new ValidationMessage(
                'You cannot withdraw because account is unverified and bank details are unverified.',
                false
            );
        }
        if (!account) {
            return new ValidationMessage('You cannot withdraw because account is unverified.', false);
        }
        if (!bank) {
            return new ValidationMessage('You cannot withdraw because bank details are unverified.', false);
        }
        if (account && bank) {
            return new ValidationMessage('', true);
        }
    }
}
