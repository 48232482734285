import React from 'react';
import styles from './contract-details.module.scss';
import { Paper, Button, Typography, Box, Grid } from '@material-ui/core';
import SpecialRentTable from 'components/special-rent-table/special-rent-table.container';
import ContractRent from 'components/contract-rent/contract-rent.container';
import PropTypes from 'prop-types';
import AddSpecialRent from '../../components/modal-add-special-rent/modal-add-special-rent.container';
import LoadingScreen from 'components/loading-screen/loading-screen.container';
import { SnackBarAndAlert } from 'components';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { useGlobalStateContext } from 'contexts';

function ContractDetailsComponent(props) {
    const {
        addSpecialRent,
        setAddSpecialRent,
        specialRentDetails,
        setSpecialRentDetails,
        specialRentDelete,
        setSpecialRentDelete,
        rentDeleteConfirm,
        setRentDeleteConfirm,
        contractRentDetails,
        setContractRentDetails,
        contractRentUpdate,
        submitSpecialRent,
        editSpecialRent,
        specialRentList,
        updateSpecialRent,
        setUpdateSpecialRent,
        updateSpecialRentData,
        setDeleteRentId,
        loading,
        disableBtn,
        contractBtn,
        success,
        error,
        message,
        setSuccess,
        setError,
        deleteSpecialRent,
        oldContractRentDetails,
        setOldContractRentDetails,
        isMaxSetupFee
    } = props;
    const { globalState } = useGlobalStateContext();
    const isMerchantAccountClosed = globalState.merchant?.status === MerchantStatus.CLOSED;
    return (
        <Paper className={styles.contractContainer}>
            <Box className={styles.divTitle}>
                <Typography color="primary" component="span">
                    <span className={styles.contractTitle}>Contract details</span>
                </Typography>
            </Box>
            <Grid container xs={12} className={styles.graphContainer}>
                <div className={styles.specialRentContainer}>
                    <div className={styles.specialRentTable}>
                        <span className={styles.title}>Special Rent</span>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setAddSpecialRent(true);
                                setSpecialRentDetails({});
                            }}
                            color="primary"
                            disabled={specialRentList.length >= 3 || isMerchantAccountClosed}
                            className={styles.addSpecialRentBtn}
                        >
                            + Add new Special Rent
                        </Button>
                    </div>
                    <Grid item className={styles.rentTable}>
                        <SpecialRentTable
                            setAddSpecialRent={setAddSpecialRent}
                            specialRentDetails={specialRentDetails}
                            setSpecialRentDelete={setSpecialRentDelete}
                            editSpecialRent={editSpecialRent}
                            specialRentList={specialRentList}
                            setDeleteRentId={setDeleteRentId}
                        />
                    </Grid>
                </div>
            </Grid>
            <Grid container xs={12} className={styles.graphContainer}>
                <div className={styles.specialRentContainer}>
                    <div className={styles.specialRentTable}>
                        <span className={styles.title}>Contract Rent</span>
                    </div>
                    <Grid item className={styles.contractRent}>
                        <ContractRent
                            contractRentDetails={contractRentDetails}
                            setContractRentDetails={setContractRentDetails}
                            contractRentUpdate={contractRentUpdate}
                            contractBtn={contractBtn}
                            oldContractRentDetails={oldContractRentDetails}
                            setOldContractRentDetails={setOldContractRentDetails}
                            isMaxSetupFee={isMaxSetupFee}
                        />
                    </Grid>
                    <div>
                        <AddSpecialRent
                            addSpecialRent={addSpecialRent}
                            setAddSpecialRent={setAddSpecialRent}
                            specialRentDetails={specialRentDetails}
                            setSpecialRentDetails={setSpecialRentDetails}
                            specialRentDelete={specialRentDelete}
                            setSpecialRentDelete={setSpecialRentDelete}
                            rentDeleteConfirm={rentDeleteConfirm}
                            setRentDeleteConfirm={setRentDeleteConfirm}
                            submitSpecialRent={submitSpecialRent}
                            updateSpecialRent={updateSpecialRent}
                            setUpdateSpecialRent={setUpdateSpecialRent}
                            updateSpecialRentData={updateSpecialRentData}
                            disableBtn={disableBtn}
                            deleteSpecialRent={deleteSpecialRent}
                        />
                    </div>
                </div>
            </Grid>
            <LoadingScreen open={loading} />
            <SnackBarAndAlert
                open={success}
                onClose={() => {
                    setSuccess(false);
                }}
                type="success"
            >
                {message}
            </SnackBarAndAlert>
            <SnackBarAndAlert
                open={error}
                onClose={() => {
                    setError(false);
                }}
                type="error"
            >
                {message}
            </SnackBarAndAlert>
        </Paper>
    );
}
export default ContractDetailsComponent;

ContractDetailsComponent.propTypes = {
    addSpecialRent: PropTypes.bool,
    setAddSpecialRent: PropTypes.func,
    specialRentDetails: PropTypes.bool,
    setSpecialRentDetails: PropTypes.func,
    specialRentDelete: PropTypes.bool,
    setSpecialRentDelete: PropTypes.func,
    rentDeleteConfirm: PropTypes.bool,
    setRentDeleteConfirm: PropTypes.func,
    submitSpecialRent: PropTypes.func,
    updateSpecialRent: PropTypes.func,
    editSpecialRent: PropTypes.func,
    specialRentList: PropTypes.func,
    setUpdateSpecialRent: PropTypes.func,
    updateSpecialRentData: PropTypes.func,
    setError: PropTypes.func,
    setSuccess: PropTypes.func,
    success: PropTypes.bool,
    error: PropTypes.bool,
    loading: PropTypes.bool
};
