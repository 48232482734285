import React from 'react';
import { Redirect } from 'react-router-dom';
import { useGlobalStateContext } from 'contexts';
import LoggedRoute from './logged-route';
import { merchantTypes } from 'utils/helper.js';

const TransactionPageAccessibilityRoute = ({ component: Component, ...rest }) => {
    const { globalState } = useGlobalStateContext();

    function canAccessTheTransactionPage(merchant, transactionPagePath) {
        if (transactionPagePath.includes('internal-transfers')) {
            return merchant.internalTransferStatus && !merchantTypes.includes(globalState.merchant?.merchantType);
        }
        return true;
    }

    return (
        <LoggedRoute
            {...rest}
            render={(props) =>
                canAccessTheTransactionPage(globalState.merchant, rest.path) ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={
                            globalState.customDomain === false
                                ? `/${globalState.reseller?.portalURL}/not-authorized`
                                : '/not-authorized'
                        }
                    />
                )
            }
        />
    );
};

export default TransactionPageAccessibilityRoute;
