import { useGlobalStateContext } from 'contexts';
import React, { useState, useEffect } from 'react';
import CanonicalResellerMerchantManagementComponent from './canonical-reseller-merchant-management.component';
import { NonResellerMerchantService, TermsAndConditionsService, CanonicalResellerService } from 'services';

function CanonicalResellerManagementContainer(props) {
    const { canonicalResellerId } = props;
    const { globalState } = useGlobalStateContext();
    const [merchantList, setMerchantList] = useState([]);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [activatedAt, setActivatedAt] = useState('');
    const [signedBy, setSignedBy] = useState('');
    const [canonicalResellerDetails, setCanonicalResellerDetails] = useState({});
    const [resellerNameDialogBox, setResellerNameDialogBox] = useState(false);
    const [updatedResellerName, setUpdatedResellerName] = useState('');
    const [pageLoading, setPageLoading] = useState(false);
    const entity = 'canonical_reseller';
    const entityId = canonicalResellerId;
    const productFilter = 'all';

    const updateResellerName = async () => {
        setResellerNameDialogBox(false);
        setPageLoading(true);
        const data = { companyName: updatedResellerName };
        const response = await CanonicalResellerService.updateCanonicalResellerData(canonicalResellerId, {
            canonicalResellerDetails: { ...data }
        });
        if (response.isSuccesfully) {
            setCanonicalResellerDetails({
                ...canonicalResellerDetails,
                companyName: updatedResellerName
            });
        }
        setPageLoading(false);
    };

    const getAllMerchantsForReseller = async (newPage, newRowsPerPage) => {
        setIsLoading(true);
        const thePage = newPage ?? page;
        const theRowsPerPage = newRowsPerPage ?? rowsPerPage;
        const response = await NonResellerMerchantService.getAllMerchantsForReseller(globalState.reseller?.id, {
            searchValue: 'no',
            offset: thePage * theRowsPerPage,
            limit: theRowsPerPage,
            canonicalResellerId: canonicalResellerId,
            productFilter: productFilter
        });
        if (response.isSuccesfully) {
            setMerchantList(response.data.merchants);
            setCount(response.data.count);
        } else {
            setMerchantList([]);
            setCount(0);
        }
        setIsLoading(false);
    };

    const fetchTermsAndConditionsData = async (entity, entityId) => {
        setIsLoading(true);

        const response = await TermsAndConditionsService.getSignedTermsAndConditionsData(entity, entityId);
        if (response.isSuccesfully) {
            setSignedBy(response.data.signedTermsAncConditionInfo.signedBy);
            setActivatedAt(response.data.signedTermsAncConditionInfo.activatedAt);
        }
        setIsLoading(false);
    };

    const getCanonicalResellerData = async (canonicalResellerId) => {
        setIsLoading(true);
        const result = await CanonicalResellerService.getCanonicalResellerData(canonicalResellerId);
        if (result.isSuccesfully) {
            setCanonicalResellerDetails(result.data.canonicalResellerDto);
            setUpdatedResellerName(result.data.canonicalResellerDto?.companyName);
        }

        setIsLoading(false);
    };
    useEffect(() => {
        async function fetchDetails() {
            await getCanonicalResellerData(canonicalResellerId);
            await fetchTermsAndConditionsData(entity, entityId);
            await getAllMerchantsForReseller(0);
        }
        if (canonicalResellerId) {
            fetchDetails();
        }

        // eslint-disable-next-line
    }, [globalState.canonicalReseller, entity, entityId]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getAllMerchantsForReseller(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        getAllMerchantsForReseller(0, parseInt(event.target.value, 10));
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const headCells = [
        { id: 'name', numeric: false, disablePadding: false, label: 'Merchant Name' },
        { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
        { id: 'mid', numeric: false, disablePadding: false, label: 'MID', hideSortingIcon: true },
        { id: 'acquirer', numeric: false, disablePadding: false, label: 'Acquirer', hideSortingIcon: true },
        {
            id: 'reseller-monthly-bill',
            numeric: false,
            disablePadding: false,
            label: 'Monthly Bill',
            childLabel: 'OmniPay to Reseller',
            centerAlignment: true
        },
        {
            id: 'merchant-monthly-bill',
            numeric: false,
            disablePadding: false,
            label: 'Monthly Bill',
            childLabel: 'Reseller to Merchant',
            centerAlignment: true
        }
    ];

    return (
        <CanonicalResellerMerchantManagementComponent
            {...props}
            merchantList={merchantList}
            isLoading={isLoading}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            handleRequestSort={handleRequestSort}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            count={count}
            signedBy={signedBy}
            activatedAt={activatedAt}
            canonicalResellerDetails={canonicalResellerDetails}
            rowsPerPage={rowsPerPage}
            page={page}
            resellerNameDialogBox={resellerNameDialogBox}
            setResellerNameDialogBox={setResellerNameDialogBox}
            updateResellerName={updateResellerName}
            setCanonicalResellerDetails={setCanonicalResellerDetails}
            updatedResellerName={updatedResellerName}
            setUpdatedResellerName={setUpdatedResellerName}
            pageLoading={pageLoading}
        />
    );
}

export default CanonicalResellerManagementContainer;
