import { useGlobalStateContext } from 'contexts';
import React from 'react';
import ManageProductsDatman from './mange-products-datman/manage-products-datman.container';
import ManageProductsOmniPay from './mange-product-omnipay/manage-products-omnipay.container';

export default function ManageProducts() {
    const { globalState } = useGlobalStateContext();
    const isDatmanReseller = globalState.reseller.id === 2;

    return isDatmanReseller ? <ManageProductsDatman /> : <ManageProductsOmniPay />;
}
