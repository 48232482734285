import React from 'react';
import styles from './content.module.scss';
import { Switch } from 'react-router-dom';
import { useGlobalStateContext } from 'contexts';
import sideBarMerchantRoutes from '../../routing/with-sidebar.routes';
import sideBarMerchantCustomDomainRoutes from '../../routing/with-sidebar.custom-domain.routes';
import { UserType } from 'utils/enums/UserType';

function ContentComponent() {
    const { globalState } = useGlobalStateContext();
    return (
        <div className={globalState.user?.UserType?.name === UserType.Reseller ? '' : styles.merchantContent}>
            <Switch>
                {JSON.parse(localStorage.getItem('customDomain')) === false
                    ? sideBarMerchantRoutes
                    : sideBarMerchantCustomDomainRoutes}
            </Switch>
        </div>
    );
}

export default ContentComponent;
