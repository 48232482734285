import React, { useState, useEffect } from 'react';
import CanonicalResellerNewMerchantComponent from './canonical-reseller-new-merchant.component';
import { InputValidators } from 'validators';
import { TaxonomyService, canonicalMerchantRegistrationService, NonResellerMerchantService } from 'services';
import { useGlobalStateContext } from 'contexts';
import { AcquirerNames } from 'utils/enums/AcquirerName';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { MidTypes } from 'utils/enums/MidType';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import _ from 'lodash';

function CanonicalResellerNewMerchant(props) {
    const { merchantId } = props;
    const [canonicalMerchantDetails, setCanonicalMerchantdetails] = useState({
        externalLegalName: '',
        externalMerchantName: '',
        externalMidCounty: '',
        selectedMidType: [],
        externalMID: null
    });
    const { globalState, setRoute } = useGlobalStateContext();
    const [businessDescriptions, setBusinessDescriptions] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [acquiredName, setAcquiredName] = useState(AcquirerNames);
    const [selectedAcquirer, setSelectedAcquirer] = useState([]);
    const [selectedMidType, setSelectedMidType] = useState([]);
    const [productTypes, setProductTypes] = useState(MidTypes);
    const [message, setMessage] = useState('');
    const [confirmMessage, setConfirmMessage] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [isLegalNameSameAsTradingName, setIsLegalNameSameAsTradingName] = useState(false);
    const [businessDescriptionSearchString, setBusinessDescriptionSearchString] = useState({ name: '' });
    const [isBackButtonClicked, setIsBackButtonClicked] = useState(false);
    const [isDataEntered, setIsDataEntered] = useState(false);
    const [existingCanonicalResellerData, setExistingCanonicalResellerData] = useState({
        externalLegalName: '',
        externalMerchantName: '',
        externalMidCounty: '',
        selectedMidType: []
    });

    useEffect(() => {
        const existingData = { ...existingCanonicalResellerData };
        for (const property in canonicalMerchantDetails) {
            if (!_.has(existingData, property)) {
                if (property === 'selectedAcquirer' || property === 'selectedMidType') {
                    existingData[property] = [];
                } else {
                    existingData[property] = '';
                }
            }
        }
        setIsDataEntered(JSON.stringify(existingData) !== JSON.stringify(canonicalMerchantDetails));
    }, [canonicalMerchantDetails, existingCanonicalResellerData]);

    useEffect(() => {}, []);

    useEffect(() => {
        const {
            externalLegalName,
            businessDescription,
            websiteUrl,
            externalMidPostCode,
            externalMidCounty,
            externalMidCity,
            externalMidAddressLine1,
            externalMerchantEmail,
            externalMerchantPhone,
            externalMerchantName,
            externalContactName,
            selectedMidType,
            selectedAcquirer,
            externalMID
        } = canonicalMerchantDetails;

        const checkValues =
            InputValidators.isWebsiteUrlValid(websiteUrl) &&
            externalMerchantName &&
            externalMerchantName.trim() &&
            externalMidPostCode &&
            externalMidCounty &&
            externalMidCity &&
            externalMidAddressLine1 &&
            InputValidators.isEmailValid(externalMerchantEmail) &&
            externalMerchantPhone &&
            externalContactName &&
            externalContactName.trim() &&
            selectedAcquirer.length > 0 &&
            selectedMidType.length > 0 &&
            externalLegalName &&
            businessDescription &&
            externalMID &&
            businessDescription !== '--Please select--';

        setIsDisabled(!checkValues);

        const checkValidation =
            externalLegalName &&
            externalLegalName.trim() &&
            selectedAcquirer &&
            selectedAcquirer.length > 0 &&
            externalMerchantName &&
            externalMerchantName.trim();

        const webSideUrlCheck = websiteUrl ? InputValidators.isWebsiteUrlValid(websiteUrl) : true;
        setIsFormValid(!(checkValidation && webSideUrlCheck));
        setIsLegalNameSameAsTradingName(externalLegalName === externalMerchantName);

        // eslint-disable-next-line
    }, [canonicalMerchantDetails]);

    const selectAddressData = (addressData, setObject, object) => {
        if (addressData) {
            setObject({
                ...object,
                externalMidAddressLine1: addressData.line_1,
                externalMidAddressLine2: addressData.line_2,
                externalMidCity: addressData.town_or_city,
                externalMidCounty: addressData.county
            });
        }
    };

    const onMidTypeSelect = (addedMidType) => {
        let selectedMidType = addedMidType.map((product) => product.id);
        setCanonicalMerchantdetails({
            ...canonicalMerchantDetails,
            selectedMidType: selectedMidType
        });
    };
    const onMidTypeRemove = (removedMidType) => {
        let selectedMidType = removedMidType.map((product) => product.id);
        setCanonicalMerchantdetails({
            ...canonicalMerchantDetails,
            selectedMidType: selectedMidType
        });
    };
    const onAcquirerSelect = (addedAcquirer) => {
        let selectedAcquirer = addedAcquirer.map((bankName) => bankName.name);
        setCanonicalMerchantdetails({
            ...canonicalMerchantDetails,
            selectedAcquirer: selectedAcquirer
        });
    };
    const onAcquirerRemove = (removedAcquirer) => {
        let selectedAcquirer = removedAcquirer.map((bankName) => bankName.name);
        setCanonicalMerchantdetails({
            ...canonicalMerchantDetails,
            selectedAcquirer: selectedAcquirer
        });
    };

    const onCreateMerchant = async (isSave = false) => {
        setIsLoading(true);
        setConfirmMessage(false);
        let data = {
            ...canonicalMerchantDetails,
            canonicalResellerId: globalState.canonicalReseller?.id,
            merchantStatus: isSave ? MerchantStatus.MERCHANT_PENDING : MerchantStatus.ACTIVE,
            country: MerchantCountries.UNITED_KINGDOM,
            merchantId: merchantId,
            resellerId: globalState.reseller.id,
            businessDescription:
                canonicalMerchantDetails.businessDescription !== '--Please select--' &&
                canonicalMerchantDetails.businessDescription
        };
        setCanonicalMerchantdetails(data);
        const response = await canonicalMerchantRegistrationService.canonicalResellerMerchantRegistration(data);
        if (response.isSuccesfully) {
            if (isSave) {
                sessionStorage.setItem('savedDraft', true);
            }
            if (canonicalMerchantDetails.merchantStatus === MerchantStatus.ACTIVE) {
                sessionStorage.setItem('merchantCreationSuccess', true);
            }
            setRoute('/home');
        } else {
            setCanonicalMerchantdetails({
                ...canonicalMerchantDetails,
                merchantStatus: MerchantStatus.MERCHANT_PENDING
            });
            setShowMessage(true);
            setMessage(response?.error);
        }
        setIsLoading(false);
    };
    useEffect(() => {
        setIsLoading(true);

        const Descriptions = async () => {
            let businessDescriptions;
            const descriptionsFetchResponse = await TaxonomyService.getDescriptions();
            if (descriptionsFetchResponse.isSuccesfully) {
                businessDescriptions = descriptionsFetchResponse.data.businessDescriptions;
                businessDescriptions.push({ name: '' });
                setBusinessDescriptions(businessDescriptions);
            }
            setIsLoading(false);
            return businessDescriptions;
        };
        Descriptions();
    }, []);

    useEffect(() => {
        const getCanonicalResellerMerchant = async () => {
            setIsLoading(true);
            const response = await NonResellerMerchantService.getCanonicalResellerMerchant(merchantId);

            if (response.isSuccesfully) {
                if (response.data?.websiteUrl) {
                    response.data.websiteUrl = response.data?.websiteUrl.replace('https://', '');
                }
                setCanonicalMerchantdetails(response.data);
                setExistingCanonicalResellerData(response.data);
                const selectedAcquirerValues = response.data.selectedAcquirer;
                const selectedMidValues = response.data.selectedMidType;
                const businessDescriptionId = response.data.businessDescription;
                const acquirer = AcquirerNames.filter((acquire) => selectedAcquirerValues.includes(acquire.name));
                const midTypes = MidTypes.filter((item) => selectedMidValues.includes(item.id));
                const selectedBusiness = businessDescriptions.filter(
                    (business) => business.id === businessDescriptionId
                );
                setSelectedAcquirer(acquirer);
                setSelectedMidType(midTypes);
                setBusinessDescriptionSearchString(selectedBusiness[0]);

                if (response.data.merchantStatus === MerchantStatus.ACTIVE) {
                    setProductTypes([]);
                    setAcquiredName([]);
                }
            }
            setIsLoading(false);
        };

        if (merchantId && businessDescriptions.length !== 0) {
            getCanonicalResellerMerchant();
        }
    }, [merchantId, businessDescriptions]);

    return (
        <CanonicalResellerNewMerchantComponent
            isDisabled={isDisabled}
            isLoading={isLoading}
            onMidTypeSelect={onMidTypeSelect}
            onMidTypeRemove={onMidTypeRemove}
            onCreateMerchant={onCreateMerchant}
            onAcquirerSelect={onAcquirerSelect}
            onAcquirerRemove={onAcquirerRemove}
            businessDescriptions={businessDescriptions}
            canonicalMerchantDetails={canonicalMerchantDetails}
            setCanonicalMerchantdetails={setCanonicalMerchantdetails}
            phoneNumberValidator={InputValidators.validatePhoneNumber}
            acquiredName={acquiredName}
            isFormValid={isFormValid}
            selectedAcquirer={selectedAcquirer}
            selectedMidType={selectedMidType}
            productTypes={productTypes}
            message={message}
            showMessage={showMessage}
            setShowMessage={setShowMessage}
            confirmMessage={confirmMessage}
            setConfirmMessage={setConfirmMessage}
            selectAddressData={selectAddressData}
            isLegalNameSameAsTradingName={isLegalNameSameAsTradingName}
            setIsLegalNameSameAsTradingName={setIsLegalNameSameAsTradingName}
            setBusinessDescriptionSearchString={setBusinessDescriptionSearchString}
            businessDescriptionSearchString={businessDescriptionSearchString}
            setIsBackButtonClicked={setIsBackButtonClicked}
            isBackButtonClicked={isBackButtonClicked}
            isDataEntered={isDataEntered}
        />
    );
}

export default CanonicalResellerNewMerchant;
