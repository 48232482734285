import React, { useState } from 'react';
import InvoiceToggleModesComponent from './invoice-toggle-modes.component';

export default function InvoiceToggleModes() {
    const [showInvoiceUpdateDialogBox, setShowInvoiceUpdateDialogBox] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showInvoiceStatus, setShowInvoiceStatus] = useState(false);
    const [showInvoiceUpdate, setShowInvoiceUpdate] = useState(false);
    return (
        <InvoiceToggleModesComponent
            showInvoiceUpdateDialogBox={showInvoiceUpdateDialogBox}
            setShowInvoiceUpdateDialogBox={setShowInvoiceUpdateDialogBox}
            loading={loading}
            setLoading={setLoading}
            showInvoiceStatus={showInvoiceStatus}
            setShowInvoiceStatus={setShowInvoiceStatus}
            showInvoiceUpdate={showInvoiceUpdate}
            setShowInvoiceUpdate={setShowInvoiceUpdate}
        />
    );
}
