import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalStateContext } from 'contexts';

export function CheckMerchantCountries(props) {
    const { globalState } = useGlobalStateContext();
    let { setIsChildReturned } = props;
    setIsChildReturned = setIsChildReturned ? setIsChildReturned : () => {};
    const country = globalState.merchant?.country;
    const isStripe = globalState.merchant?.isStripe;
    const hideBecauseOfStripe = props.showIfStripe ? !isStripe : props.hideIfStripe ? isStripe : false;

    if (props.includedCountries) {
        if (props.includedCountries.includes(country)) {
            if (hideBecauseOfStripe) return <React.Fragment></React.Fragment>;
            else {
                setIsChildReturned(true);
                return props.children;
            }
        } else {
            return <React.Fragment></React.Fragment>;
        }
    } else if (props.excludedCountries) {
        if (!props.excludedCountries.includes(country)) {
            if (hideBecauseOfStripe) {
                return <React.Fragment></React.Fragment>;
            } else {
                setIsChildReturned(true);
                return props.children;
            }
        } else {
            return <React.Fragment></React.Fragment>;
        }
    } else {
        return <React.Fragment></React.Fragment>;
    }
}

CheckMerchantCountries.propTypes = {
    includedCountries: PropTypes.array,
    excludedCountries: PropTypes.array,
    isStripeCheck: PropTypes.bool
};
