import React from 'react';
import SignUpComponent from './signup.component';
import { AuthService } from 'services';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { useGlobalStateContext } from 'contexts';

function ExternalSignUp() {
    const { getRoute } = useGlobalStateContext();
    const location = useLocation();

    const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
    const userIsLoggedIn = AuthService.isLoggedUser();

    if (userIsLoggedIn && queryString.email && queryString.oldPassword) {
        AuthService.logout();
        return <SignUpComponent />;
    }

    if (userIsLoggedIn) {
        return <Redirect to={getRoute(`/home`)} />;
    }

    return <SignUpComponent />;
}

export default ExternalSignUp;
