import React from 'react';

//Styles
import styles from './banner.module.scss';

//Icons
import { ErrorOutline } from '@material-ui/icons';

//External Components
import { Typography } from '@material-ui/core';

const BannerComponent = (props) => {
    const { message } = props;
    return (
        <>
            <div className={styles.bannerContainer}>
                <div className={styles.bannerIconContainer}>
                    <ErrorOutline className={styles.bannerIcon} />
                </div>
                <Typography color="primary">
                    <div className={styles.bannerText}>{message}</div>
                </Typography>
            </div>
        </>
    );
};

export default BannerComponent;
