import React from 'react';
import { Box, Paper, Grid } from '@material-ui/core';
import { PageHeader, Calendar, PdqTransactionList, Banner } from 'components';
import WithdrawalContainer from '../withdrawal/withdrawal.container';
import style from './pdq-transactions.module.scss';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { useGlobalStateContext } from 'contexts';
import { bannerMessage } from 'utils/banner-message';

const PdqTransactionsComponent = (props) => {
    const { globalState } = useGlobalStateContext();
    const { date: dateFromUrlParams, via: viaFromUrlParams } = props.match.params;
    const { pathname } = props.location;
    const isMerchantAccountClosed = globalState.merchant?.status === MerchantStatus.CLOSED;

    return (
        <div>
            <Grid item xs={12}>
                {isMerchantAccountClosed && <Banner message={bannerMessage.CLOSED_MERCHANT_ACCOUNT} />}
                <WithdrawalContainer />
                <Box mt={3}>
                    <Calendar
                        {...props}
                        path="pdq-transactions"
                        range={viaFromUrlParams}
                        current={pathname}
                        date={dateFromUrlParams}
                    />
                </Box>
                <Paper className={style.pdqsContainer}>
                    <PageHeader
                        {...props}
                        title={`PDQ Transactions`}
                        via={viaFromUrlParams}
                        path="pdq-transactions"
                        date={dateFromUrlParams}
                    />

                    <PdqTransactionList {...props} />
                </Paper>
            </Grid>
        </div>
    );
};

export default PdqTransactionsComponent;
