import React from 'react';
import { Box, Paper, Grid } from '@material-ui/core';
import { MerchantLabel } from 'utils/enums/MerchantLabel';
import { Calendar, InternalTransfersList, PageHeader, PaymentAction } from 'components';
import { PaymentType } from 'utils/enums/PaymentType';
import style from './internal-transfers.module.scss';
import PropTypes from 'prop-types';
import { CheckMerchantCountries } from '../../components/onboarding/onboarding-ui-components/check-merchant-countries/check-merchant-countries';
import { MerchantCountries } from '../../utils/enums/MerchantCountries';
import WithdrawalContainer from '../withdrawal/withdrawal.container';

const InternalTransfersComponent = (props) => {
    const { date: dateFromUrlParams, via: viaFromUrlParams } = props.match.params;
    const { pathname } = props.location;
    const { merchant, enableWithdrawal, totalPendingAmount, show, setShow } = props;

    return (
        <div>
            <Grid item xs={12}>
                {merchant && merchant.label !== MerchantLabel.SUPPLIER && !enableWithdrawal && (
                    <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_KINGDOM]}>
                        <PaymentAction
                            show={show}
                            setShow={setShow}
                            btnText="Pay supplier"
                            paymentType={PaymentType.InternalTransfer}
                        />
                    </CheckMerchantCountries>
                )}
                {enableWithdrawal && <WithdrawalContainer />}
                <Box mt={3}>
                    <Calendar
                        {...props}
                        path="internal-transfers"
                        range={viaFromUrlParams}
                        current={pathname}
                        date={dateFromUrlParams}
                    />
                </Box>
                <Paper className={style.internalTransfersContainer}>
                    <PageHeader
                        title={`Internal Transfers`}
                        //via={viaFromUrlParams}
                        path="internal-transfers"
                        date={dateFromUrlParams}
                        totalPendingInternalTransfersAmount={totalPendingAmount}
                    />
                    <InternalTransfersList {...props} />
                </Paper>
            </Grid>
        </div>
    );
};

InternalTransfersComponent.propTypes = {
    merchant: PropTypes.object,
    totalPendingAmount: PropTypes.number,
    setTotalPendingAmount: PropTypes.func,
    show: PropTypes.bool,
    setShow: PropTypes.func,
    enableWithdrawal: PropTypes.bool,
    setEnableWithdrawal: PropTypes.func
};

export default InternalTransfersComponent;
