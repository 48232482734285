import React from 'react';
import { StripePayoutDetailsContainer } from 'components';
import { Grid, Box, Button } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import browserHistory from 'browserHistory';

const StripePayoutDetailsComponent = (props) => {
    const { batchId, payouts, date, provider } = props;
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Box my={3}>
                        <Button
                            color="primary"
                            variant="outlined"
                            size="small"
                            startIcon={<NavigateBeforeIcon />}
                            onClick={() => browserHistory.goBack()}
                        >
                            <span>Back</span>
                        </Button>
                    </Box>
                    <StripePayoutDetailsContainer
                        {...props}
                        batchId={batchId}
                        payouts={payouts}
                        date={date}
                        provider={provider}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default StripePayoutDetailsComponent;
