import { useGlobalStateContext, useAdminFunctionsContext } from 'contexts';
import React, { useState, useEffect } from 'react';
import { AdminService } from 'services';
import AutoWithdrawalComponent from './auto-withdrawal-status.component';

export default function AutoWithdrawal() {
    const { globalState } = useGlobalStateContext();
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [loading, setLoading] = useState(false);
    const { adminFunctions, setAdminFunctions } = useAdminFunctionsContext();
    const [autoWithdrawStatus, setAutoWithdrawStatus] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        setAutoWithdrawStatus(adminFunctions.autoWithdraw);
    }, [adminFunctions]);

    const autoWithdrawalStatusChangeHandler = (value) => {
        const status = value === '1' ? true : false;
        setAutoWithdrawStatus(status);
        setOpenModal(true);
    };

    const onChange = async () => {
        setOpenModal(false);
        setLoading(true);
        const statusValue = autoWithdrawStatus ? '1' : '0';
        const response = await AdminService.UpdateMerchantDataForAdmin(globalState.merchant?.id, {
            autoWithdraw: statusValue,
            resellerId: globalState.reseller?.id
        });

        setLoading(false);
        if (response.isSuccesfully) {
            setAdminFunctions({
                ...adminFunctions,
                autoWithdraw: autoWithdrawStatus
            });
            setMessageType('success');
            setMessage('Email successfully sent to notify client !');
        } else {
            setMessageType('error');
            setMessage('An error occurred to notify client via email. Try again !');
        }
    };

    return (
        <AutoWithdrawalComponent
            onChange={onChange}
            message={message}
            setMessage={setMessage}
            messageType={messageType}
            loading={loading}
            openModal={openModal}
            setOpenModal={setOpenModal}
            autoWithdrawStatus={autoWithdrawStatus}
            autoWithdrawalStatusChangeHandler={autoWithdrawalStatusChangeHandler}
        />
    );
}
