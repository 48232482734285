import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CardMultiSelectComponent from './card-multi-select.component';
import { useGlobalStateContext } from 'contexts';
import { UserType } from 'utils/enums/UserType';
import { ProductStatus } from 'utils/enums/ProductsRequired';
/**
 *
 * @param {{
 * list: array;
 * onChange: func;
 * value: array;
 * }} props
 */
function CardMultiSelect(props) {
    const {
        onChange,
        list,
        value,
        setIsModalOpen,
        setProductId,
        setOpenDialog,
        manageMyProductPage,
        setSelectedEntity
    } = props;
    const { globalState } = useGlobalStateContext();
    const isAdmin =
        globalState.user?.UserType?.name === UserType.Admin || globalState.user?.UserType?.name === UserType.SuperAdmin;
    const [selectedEntities, setSelectedEntitites] = useState([]);

    useEffect(() => {
        setSelectedEntitites(value ? value : []);
    }, [value]);

    const onCardClick = (element) => {
        const elementId = element.id;
        const index = selectedEntities.indexOf(selectedEntities.find((e) => e.id === elementId));
        if (index > -1) {
            if (!isAdmin && selectedEntities[index].status !== ProductStatus.REJECTED) {
                const shouldOpenDialogBox =
                    manageMyProductPage &&
                    (selectedEntities[index].status === ProductStatus.INACTIVE ||
                        selectedEntities[index].status === ProductStatus.ACTIVE);
                if (shouldOpenDialogBox) {
                    const selectedProduct = { ...selectedEntities[index] };
                    setSelectedEntity(selectedProduct);
                    setOpenDialog(true);
                } else {
                    selectedEntities[index].status =
                        selectedEntities[index].status === ProductStatus.INACTIVE
                            ? ProductStatus.ADDITION_PENDING
                            : ProductStatus.DELETION_PENDING;
                }
            }
            if (isAdmin) {
                if (
                    selectedEntities[index].status === ProductStatus.ADDITION_PENDING ||
                    selectedEntities[index].status === ProductStatus.DELETION_PENDING
                ) {
                    setIsModalOpen(true);
                    setProductId(index);
                }
            }
        } else {
            element.status = ProductStatus.ADDITION_PENDING;
            selectedEntities.push(element);
        }

        onChange([...selectedEntities]);
        setSelectedEntitites([...selectedEntities]);
    };

    return (
        <CardMultiSelectComponent
            list={list}
            onCardClick={onCardClick}
            selectedEntities={selectedEntities}
            setSelectedEntitites={setSelectedEntitites}
        />
    );
}

CardMultiSelect.propTypes = {
    value: PropTypes.array,
    setIsModelOpen: PropTypes.func
};

export default CardMultiSelect;
