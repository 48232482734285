import React from 'react';
// import styles from './missing-merchant-document.module.scss';
import { MissingMerchant } from 'components';

export default function AdminMissingMerchant() {
    return (
        <div>
            <MissingMerchant />
        </div>
    );
}
