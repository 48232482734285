import { MerchantCountries } from 'utils/enums/MerchantCountries';

export function validateSocialSecurity(country, socialSecurity) {
    var isValid;
    const sortCodeLength = socialSecurity ? socialSecurity.toString().length : 0;

    switch (country) {
        case MerchantCountries.UNITED_STATES:
            isValid = socialSecurity && sortCodeLength === 9;
            break;
        default:
            isValid = true;
            break;
    }

    return isValid;
}
