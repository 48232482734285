import React, { useState } from 'react';
import ChargebackListComponent from './chargebacks.component';

const ChargebackList = (props) => {
    const [loading, setLoading] = useState(false);

    return <ChargebackListComponent {...props} loading={loading} setLoading={setLoading} />;
};

export default ChargebackList;
