import { useGlobalStateContext } from 'contexts';
import React, { useEffect, useState } from 'react';
import { UrgentMessagesService } from 'services';
import LayoutComponent from './layout.component';

function Layout(props) {
    const { globalState } = useGlobalStateContext();
    const [urgentMessages, setUrgentMessages] = useState([]);
    let pathname = props?.location?.pathname;
    let forcedMerchantIdThroughParam;

    if (
        pathname?.includes('/admin-functions/') ||
        pathname?.includes('/missing-merchant-document/') ||
        pathname?.includes('/document-management/')
    ) {
        forcedMerchantIdThroughParam = +pathname.substr(pathname.lastIndexOf('/') + 1);
    }

    useEffect(() => {
        const getUrgentMessages = async () => {
            if (localStorage.getItem('user')) {
                const response = await UrgentMessagesService.getUrgentMessages(globalState.reseller?.id);
                if (response.isSuccesfully) {
                    setUrgentMessages(response.data.urgentMessages);
                }
            }
        };

        getUrgentMessages();
    }, [globalState.shouldCheckForUrgentMessages, globalState.reseller]);

    return (
        <LayoutComponent urgentMessages={urgentMessages} forcedMerchantIdThroughParam={forcedMerchantIdThroughParam} />
    );
}

export default Layout;
