export const AcquirersNameMap = {
    DATMAN: 'DATMAN',
    ADYEN: 'ADYEN',
    'CARDSTREAM-CH': 'CARDSTREAM-CH',
    STRIPE: 'STRIPE',
    VOUCHER_CREDIT: 'VOUCHER-CREDIT'
};

export const AcquirersToAcquirerName = {
    ALL: 'All',
    DATMAN: 'Datman',
    ADYEN: 'Adyen',
    'CARDSTREAM-CH': 'Cardstream-CH',
    STRIPE: 'Stripe'
};

export const ReverseAcquirersToAcquirerName = {
    All: 'ALL',
    Datman: 'DATMAN',
    Adyen: 'ADYEN',
    'Cardstream-CH': 'CARDSTREAM-CH',
    Stripe: 'STRIPE'
};
