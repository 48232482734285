import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ModalMerchantChargeComponent from './modal-merchant-charges.component';
import { AdminService } from 'services';

export default function ModalMerchantCharge(props) {
    const {
        snackbarMessage,
        setSnackbarMessage,
        showSnackbarStatus,
        setShowSnackbarStatus,
        setMerchantChargeResponse,
        getMerchantChargesList,
        setOpenMerchantModal
    } = props;

    const [disabled, setDisabled] = useState(true);
    const [merchantDate, setMerchantDate] = useState(new Date().toISOString().slice(0, 10));
    const [merchantDescription, setMerchantDescription] = useState('');
    const [merchantAmount, setMerchantAmount] = useState('');
    const [merchantBtn, setMerchantBtn] = useState(true);
    const [merchantReceipt, setMerchantReceipt] = useState(true);
    const [loading, setLoading] = useState(false);
    const isMaxAmount = (values) => {
        const { value, floatValue } = values;
        const MAX = 9999999.99;
        const MIN = 0;

        if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
            return true;
        }
        if (value.charAt(0) === '0') {
            if (value.charAt(1) && value.charAt(1) !== '.') {
                return false;
            }
        }
        if (value > MAX) {
            return false;
        }
        if (value < MIN) {
            return false;
        }
        return true;
    };

    const handleClose = () => {
        setOpenMerchantModal(false);
        if (!merchantReceipt) {
            getMerchantChargesList();
        }
    };

    const wrapperRef = useRef(null);

    let headCells = [
        {
            id: 'client',
            numeric: false,
            disablePadding: false,
            label: 'Client',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'invoice_date',
            numeric: false,
            disablePadding: false,
            label: 'Invoice date',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'description',
            numeric: false,
            disablePadding: false,
            label: 'Description',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'amount',
            numeric: false,
            disablePadding: false,
            label: 'Amount',
            hideSortingIcon: true,
            alignCenter: true
        }
    ];

    useEffect(() => {
        if (merchantDate === '' || merchantDescription === '' || merchantAmount === '') {
            setMerchantBtn(true);
        } else {
            setMerchantBtn(false);
        }
    }, [merchantDate, merchantDescription, merchantAmount]);
    const regex = /(<([^>]+)>)/gi;

    const merchantBtnHandler = async () => {
        setLoading(true);
        const response = await AdminService.updateMerchantCharges({
            date: merchantDate,
            description: `Invoice ${merchantDescription.replace(regex, '')}`,
            amount: merchantAmount
        });
        if (response.isSuccesfully) {
            setLoading(true);
            setSnackbarMessage('Invoice has been created successfully!');
            setShowSnackbarStatus(true);
            setMerchantReceipt(false);
            setMerchantChargeResponse(true);
        } else {
            setLoading(true);
            setSnackbarMessage(response.error.message);
            setShowSnackbarStatus(true);
            setMerchantChargeResponse(false);
            setOpenMerchantModal(false);
        }
        setLoading(false);
    };

    return (
        <ModalMerchantChargeComponent
            {...props}
            disabled={disabled}
            wrapperRef={wrapperRef}
            headCells={headCells}
            showSnackbarStatus={showSnackbarStatus}
            setShowSnackbarStatus={setShowSnackbarStatus}
            snackbarMessage={snackbarMessage}
            setSnackbarMessage={setSnackbarMessage}
            loading={loading}
            setDisabled={setDisabled}
            merchantDate={merchantDate}
            merchantDescription={merchantDescription}
            merchantAmount={merchantAmount}
            merchantBtn={merchantBtn}
            setMerchantDate={setMerchantDate}
            setMerchantDescription={setMerchantDescription}
            setMerchantAmount={setMerchantAmount}
            merchantReceipt={merchantReceipt}
            setMerchantReceipt={setMerchantReceipt}
            merchantBtnHandler={merchantBtnHandler}
            isMaxAmount={isMaxAmount}
            handleClose={handleClose}
            regex={regex}
        ></ModalMerchantChargeComponent>
    );
}

ModalMerchantCharge.propTypes = {
    setLoading: PropTypes.func,
    setShow: PropTypes.func,
    getTeamMembers: PropTypes.func,
    doValidation: PropTypes.func
};
