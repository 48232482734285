import React from 'react';
import PropTypes from 'prop-types';

import { SelectedDropDown, Input } from 'components/onboarding/onboarding-ui-components';
import style from './post-code-selector.module.scss';
import { FormatMessage } from 'utils/languageTranslater';

function PostCodeSelectorComponent(props) {
    const {
        postCodeValue,
        onPostCodeChange,
        onAddressObjectChange,
        isPostCodeLoading,
        setIsPostCodeLoading,
        listPostCodeAddresses,
        setListPostCodeAddresses,
        postCodeValueBuffer,
        postCodeOfSearchResults,
        showLookupView,
        disabled,
        emptyField,
        placeholder
    } = props;

    return showLookupView ? (
        <SelectedDropDown
            id="post_code_input"
            placeholder={
                placeholder ? placeholder : FormatMessage({ id: 'post_code_input', text: 'Please enter post code' })
            }
            value={postCodeValue}
            loading={isPostCodeLoading}
            hideNoOptionsTextSelect={!postCodeValue}
            options={listPostCodeAddresses}
            getOptionLabel={() => postCodeValue}
            inputValue={postCodeValue}
            disabled={disabled}
            emptyField={emptyField}
            onInputChange={(event, postCode) => {
                if (!postCode) {
                    setIsPostCodeLoading(false);
                    setListPostCodeAddresses([]);
                }
                onPostCodeChange(postCode);
            }}
            onChange={(event, newValue) => {
                if (postCodeValue.length > 1 && newValue) {
                    newValue.postCode = postCodeOfSearchResults;
                    onPostCodeChange(postCodeOfSearchResults);
                    onAddressObjectChange(newValue);
                }
            }}
            customListRender={(option) => (
                <React.Fragment>
                    <span className={style.postCodeSearchResultBuilding}>{`${
                        option.building_number ||
                        option.building_name ||
                        option.sub_building_number ||
                        option.sub_building_name
                    },  ${option.thoroughfare} `}</span>{' '}
                    &nbsp;
                    <span
                        style={{ color: '#a0a0a0', fontSize: '15px' }}
                    >{` ${option.town_or_city}, ${postCodeValueBuffer}`}</span>
                </React.Fragment>
            )}
        />
    ) : (
        <Input
            placeholder={
                placeholder ? placeholder : FormatMessage({ id: 'post_code_input', text: 'Please enter post code' })
            }
            id="post-code-plain-input"
            value={postCodeValue}
            onChange={(e) => {
                onPostCodeChange(e.target.value);
            }}
        ></Input>
    );
}

PostCodeSelectorComponent.propTypes = {
    postCodeValue: PropTypes.string,
    postCodeValueBuffer: PropTypes.string,
    onPostCodeChange: PropTypes.func,
    isPostCodeLoading: PropTypes.bool,
    listPostCodeAddresses: PropTypes.array,
    onChange: PropTypes.func,
    onAddressObjectChange: PropTypes.func,
    disabled: PropTypes.bool,
    postCodeOfSearchResults: PropTypes.string
};

export default PostCodeSelectorComponent;
