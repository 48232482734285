import React from 'react';
import styles from './canonical-reseller-new-merchant.module.scss';
import {
    Input,
    Label,
    FormItem,
    PostCodeSelector,
    SelectedDropDown
} from 'components/onboarding/onboarding-ui-components';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import PhoneInput from 'react-phone-input-2';
import { FormControlLabel } from '@material-ui/core';
import { Button, Checkbox, useMediaQuery, Grid, Container, Card, Typography } from '@material-ui/core';
import Multiselect from 'multiselect-react-dropdown';
import {
    SnackBarAndAlert,
    LoadingScreen,
    ValidURLInput,
    ValidEmailInput,
    CountyInput,
    ModalFormConfirmation
} from 'components';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { checkEmptyValue } from 'validators/onboarding/onboarding-check-value.validator';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useGlobalStateContext } from 'contexts';

function CanonicalResellerNewMerchantComponent(props) {
    const mediaLessThan600px = useMediaQuery('(max-width:599px)');
    const { setRoute } = useGlobalStateContext();
    const {
        canonicalMerchantDetails,
        setCanonicalMerchantdetails,
        phoneNumberValidator,
        onCreateMerchant,
        onAcquirerSelect,
        onAcquirerRemove,
        businessDescriptions,
        isDisabled,
        onMidTypeRemove,
        onMidTypeSelect,
        isLoading,
        acquiredName,
        isFormValid,
        selectedAcquirer,
        selectedMidType,
        productTypes,
        message,
        showMessage,
        setShowMessage,
        confirmMessage,
        setConfirmMessage,
        selectAddressData,
        isLegalNameSameAsTradingName,
        setIsLegalNameSameAsTradingName,
        businessDescriptionSearchString,
        setBusinessDescriptionSearchString,
        setIsBackButtonClicked,
        isBackButtonClicked,
        isDataEntered
    } = props;

    const merchantStatus = canonicalMerchantDetails?.merchantStatus;
    const pageHeader = merchantStatus === MerchantStatus.ACTIVE ? 'Merchant details' : 'Create new merchant';

    return (
        <React.Fragment>
            <Container className={styles.newMerchantContainer}>
                <Card>
                    <div className={styles.headerDiv}>
                        <ArrowBackIosIcon
                            className={styles.closeButtonStyle}
                            onClick={() =>
                                isDataEntered && !(merchantStatus === MerchantStatus.ACTIVE)
                                    ? setIsBackButtonClicked(true)
                                    : setRoute('home')
                            }
                        />
                        <Typography color={'primary'}>
                            <h1 className={styles.pageTitle}>{pageHeader}</h1>
                        </Typography>
                    </div>
                    <Grid container spacing={mediaLessThan600px ? 0 : 2} xs={12} md={12}>
                        <Grid item xs={12} sm={6}>
                            <div className={styles.formContainer}>
                                <FormItem>
                                    <Label text="Acquirer's Name" id="acquirer-name"></Label>
                                    <Multiselect
                                        style={{
                                            searchBox: { height: '34px', display: 'flex', alignItems: 'center' },
                                            chips: { marginBottom: '0', fontSize: '12px', padding: '2px 5px' },
                                            inputField: { marginTop: '0', fontSize: '14px' }
                                        }}
                                        placeholder="Acquirer's Name"
                                        options={acquiredName}
                                        selectedValues={selectedAcquirer}
                                        onSelect={onAcquirerSelect}
                                        onRemove={onAcquirerRemove}
                                        displayValue="name"
                                        selectionLimit={1}
                                        disablePreSelectedValues={merchantStatus === MerchantStatus.ACTIVE}
                                    />
                                </FormItem>
                                <FormItem>
                                    <Label text="Acquirer MID" id="acquirer-mid"></Label>
                                    <NumberFormat
                                        className={styles.textFieldMid}
                                        variant="outlined"
                                        inputProps={{
                                            inputMode: 'numeric',
                                            className: styles.codeInput__input,
                                            maxLength: 25
                                        }}
                                        value={canonicalMerchantDetails.externalMID}
                                        pattern="\d*"
                                        allowLeadingZeros={true}
                                        decimalSeparator={false}
                                        allowNegative={false}
                                        isNumericString={true}
                                        onChange={(e) => {
                                            setCanonicalMerchantdetails({
                                                ...canonicalMerchantDetails,
                                                externalMID: parseInt(e.target.value)
                                            });
                                        }}
                                        labelWidth={0}
                                        disabled={merchantStatus === MerchantStatus.ACTIVE}
                                    />
                                </FormItem>
                                <FormItem>
                                    <Label text="MID Type" id="mid-type"></Label>
                                    <Multiselect
                                        style={{
                                            searchBox: { flexWrap: 'wrap', alignItems: 'center', display: 'flex' },
                                            chips: {
                                                marginBottom: '0',
                                                fontSize: '12px',
                                                padding: '2px 5px',
                                                margin: '2px'
                                            },
                                            inputField: {
                                                marginTop: '0',
                                                fontSize:
                                                    canonicalMerchantDetails?.selectedMidType?.length <= 0
                                                        ? '14px'
                                                        : '0px',
                                                display: 'flex',
                                                height: '22px'
                                            }
                                        }}
                                        placeholder={
                                            canonicalMerchantDetails?.selectedMidType?.length <= 0 ? 'MID Type' : ''
                                        }
                                        options={productTypes}
                                        selectedValues={selectedMidType}
                                        onSelect={onMidTypeSelect}
                                        onRemove={onMidTypeRemove}
                                        displayValue="name"
                                        disablePreSelectedValues={merchantStatus === MerchantStatus.ACTIVE}
                                    />
                                </FormItem>
                                <FormItem>
                                    <Label text="Contact Name" id="merchant-name"></Label>
                                    <Input
                                        id="contact-name-input"
                                        inputProps={{ maxLength: 150 }}
                                        value={canonicalMerchantDetails.externalContactName}
                                        onChange={(e) => {
                                            setCanonicalMerchantdetails({
                                                ...canonicalMerchantDetails,
                                                externalContactName: e.target.value
                                            });
                                        }}
                                        disabled={merchantStatus === MerchantStatus.ACTIVE}
                                    />
                                </FormItem>
                                <FormItem>
                                    <Label text="Phone Number" id="phone-number"></Label>
                                    <PhoneInput
                                        country={'gb'}
                                        inputClass={
                                            merchantStatus === MerchantStatus.ACTIVE
                                                ? `${styles.phoneInput} ${styles.disabledPhoneInput}`
                                                : styles.phoneInput
                                        }
                                        value={checkEmptyValue(canonicalMerchantDetails.externalMerchantPhone)}
                                        placeholder="Phone number"
                                        id="owner-input-phone-number"
                                        alwaysDefaultMask={true}
                                        countryCodeEditable={false}
                                        onChange={(mobileNumber) => {
                                            setCanonicalMerchantdetails({
                                                ...canonicalMerchantDetails,
                                                externalMerchantPhone: phoneNumberValidator(mobileNumber)
                                            });
                                        }}
                                        name="Mobile Number"
                                        disabled={merchantStatus === MerchantStatus.ACTIVE}
                                    />
                                </FormItem>
                                <ValidEmailInput
                                    labelText="Email Address"
                                    labelId="email-address"
                                    id="email-input"
                                    value={canonicalMerchantDetails.externalMerchantEmail}
                                    onChange={(e) => {
                                        setCanonicalMerchantdetails({
                                            ...canonicalMerchantDetails,
                                            externalMerchantEmail: e.target.value.toLowerCase()
                                        });
                                    }}
                                    disabled={merchantStatus === MerchantStatus.ACTIVE}
                                />
                                <ValidURLInput
                                    labelText="Business website"
                                    labelId="website-url"
                                    id="website-url-input"
                                    value={canonicalMerchantDetails.websiteUrl}
                                    onChange={(value) => {
                                        setCanonicalMerchantdetails({
                                            ...canonicalMerchantDetails,
                                            websiteUrl: value
                                        });
                                    }}
                                    disabled={merchantStatus === MerchantStatus.ACTIVE}
                                />
                                <FormItem>
                                    <Label text="MCC Code" id="mcc-code"></Label>
                                    <SelectedDropDown
                                        id="business-descriptions"
                                        placeholder="Search by code or description"
                                        hideNoOptionsTextSelect={true}
                                        value={businessDescriptionSearchString}
                                        getOptionSelected={(option, value) => option?.name === value?.name}
                                        options={businessDescriptions}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setCanonicalMerchantdetails({
                                                    ...canonicalMerchantDetails,
                                                    businessDescription: newValue.id,
                                                    mccCode: newValue.mccCode
                                                });
                                                setBusinessDescriptionSearchString(newValue);
                                            } else {
                                                setCanonicalMerchantdetails({
                                                    ...canonicalMerchantDetails,
                                                    businessDescription: '',
                                                    mccCode: ''
                                                });
                                                setBusinessDescriptionSearchString({ name: '' });
                                            }
                                        }}
                                        customListRender={(option) => {
                                            return (
                                                <React.Fragment>
                                                    <span>{option.name}</span>
                                                </React.Fragment>
                                            );
                                        }}
                                        disabled={merchantStatus === MerchantStatus.ACTIVE}
                                    />
                                </FormItem>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className={styles.formContainer}>
                                <FormItem>
                                    <Label text="Legal Name" id="company-name"></Label>
                                    <Input
                                        id="merchant-name-input"
                                        value={canonicalMerchantDetails.externalLegalName}
                                        inputProps={{ maxLength: 150 }}
                                        onChange={(e) => {
                                            setCanonicalMerchantdetails({
                                                ...canonicalMerchantDetails,
                                                externalLegalName: e.target.value.replace(/[^\w\s]/gi, ''),
                                                externalMerchantName: isLegalNameSameAsTradingName
                                                    ? e.target.value.replace(/[^\w\s]/gi, '')
                                                    : ' '
                                            });
                                        }}
                                        disabled={merchantStatus === MerchantStatus.ACTIVE}
                                    />
                                </FormItem>
                                <FormItem>
                                    <FormControlLabel
                                        className={styles.checkBoxLabel1}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={isLegalNameSameAsTradingName}
                                                onChange={(e) => {
                                                    setIsLegalNameSameAsTradingName(e.target.checked);
                                                    if (e.target.checked) {
                                                        setCanonicalMerchantdetails({
                                                            ...canonicalMerchantDetails,
                                                            externalMerchantName:
                                                                canonicalMerchantDetails.externalLegalName
                                                        });
                                                    } else {
                                                        setCanonicalMerchantdetails({
                                                            ...canonicalMerchantDetails,
                                                            externalMerchantName: ''
                                                        });
                                                    }
                                                }}
                                                disabled={merchantStatus === MerchantStatus.ACTIVE}
                                            />
                                        }
                                        label="Trading name is the same as legal name"
                                        labelPlacement="end"
                                    />
                                </FormItem>

                                <FormItem>
                                    <Label text="Trading Name" id="company-name"></Label>
                                    <Input
                                        id="merchant-name-input"
                                        value={canonicalMerchantDetails.externalMerchantName}
                                        inputProps={{ maxLength: 150 }}
                                        onChange={(e) => {
                                            setCanonicalMerchantdetails({
                                                ...canonicalMerchantDetails,
                                                externalMerchantName: e.target.value.replace(/[^\w\s]/gi, '')
                                            });
                                        }}
                                        disabled={
                                            merchantStatus === MerchantStatus.ACTIVE || isLegalNameSameAsTradingName
                                        }
                                    />
                                </FormItem>

                                <FormItem>
                                    <Label text="Postcode" id="post-code"></Label>
                                    <PostCodeSelector
                                        postCodeValue={checkEmptyValue(canonicalMerchantDetails.externalMidPostCode)}
                                        postCodeLookup={true}
                                        onPostCodeChange={(postCode) => {
                                            setCanonicalMerchantdetails({
                                                ...canonicalMerchantDetails,
                                                externalMidPostCode: postCode
                                            });
                                        }}
                                        disabled={merchantStatus === MerchantStatus.ACTIVE}
                                        onAddressObjectChange={(addressData) =>
                                            selectAddressData(
                                                addressData,
                                                setCanonicalMerchantdetails,
                                                canonicalMerchantDetails
                                            )
                                        }
                                    />
                                </FormItem>
                                <FormItem>
                                    <Label text="Address Line 1" id="address-line1"></Label>
                                    <Input
                                        id="address-line1-input"
                                        value={canonicalMerchantDetails.externalMidAddressLine1}
                                        inputProps={{ maxLength: 150 }}
                                        onChange={(e) => {
                                            setCanonicalMerchantdetails({
                                                ...canonicalMerchantDetails,
                                                externalMidAddressLine1: e.target.value
                                            });
                                        }}
                                        disabled={merchantStatus === MerchantStatus.ACTIVE}
                                    />
                                </FormItem>
                                <FormItem>
                                    <Label text="Address Line 2" id="address-line2"></Label>
                                    <Input
                                        id="address-line2-input"
                                        value={canonicalMerchantDetails.externalMidAddressLine2}
                                        inputProps={{ maxLength: 150 }}
                                        onChange={(e) => {
                                            setCanonicalMerchantdetails({
                                                ...canonicalMerchantDetails,
                                                externalMidAddressLine2: e.target.value
                                            });
                                        }}
                                        disabled={merchantStatus === MerchantStatus.ACTIVE}
                                    />
                                </FormItem>

                                <FormItem>
                                    <Label text="Town/City" id="city"></Label>
                                    <Input
                                        id="city-input"
                                        inputProps={{ maxLength: 150 }}
                                        value={canonicalMerchantDetails.externalMidCity}
                                        onChange={(e) => {
                                            setCanonicalMerchantdetails({
                                                ...canonicalMerchantDetails,
                                                externalMidCity: e.target.value
                                            });
                                        }}
                                        disabled={merchantStatus === MerchantStatus.ACTIVE}
                                    />
                                </FormItem>
                                <CountyInput
                                    labelText="County/Region"
                                    labelId="county-region"
                                    id="county-input"
                                    inputProps={{ maxLength: 150 }}
                                    value={canonicalMerchantDetails.externalMidCounty}
                                    onChange={(value) => {
                                        setCanonicalMerchantdetails({
                                            ...canonicalMerchantDetails,
                                            externalMidCounty: value
                                        });
                                    }}
                                    disabled={merchantStatus === MerchantStatus.ACTIVE}
                                />
                                <FormItem>
                                    <Label text="Currency" id="currency"></Label>
                                    <span className={styles.currencyStyle}>GBP</span>
                                </FormItem>
                            </div>
                        </Grid>

                        {merchantStatus !== MerchantStatus.ACTIVE && (
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                className={styles.btnContainer}
                                item
                                xs={12}
                            >
                                <Grid item xs={6} container justify="center">
                                    <Grid item xs={6}>
                                        <Button
                                            disabled={isFormValid || merchantStatus === MerchantStatus.ACTIVE}
                                            id="update-btn"
                                            variant="contained"
                                            onClick={() => {
                                                onCreateMerchant(true);
                                            }}
                                            className={
                                                isFormValid || merchantStatus === MerchantStatus.ACTIVE
                                                    ? `${styles.submitBtn}`
                                                    : `${styles.secondaryButtonColor} ${styles.submitBtn}`
                                            }
                                        >
                                            Save draft
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} container justify="center">
                                    <Grid item xs={6}>
                                        <Button
                                            disabled={isDisabled || merchantStatus === MerchantStatus.ACTIVE}
                                            id="update-btn"
                                            color="primary"
                                            variant="contained"
                                            onClick={() => {
                                                setConfirmMessage(true);
                                            }}
                                            className={
                                                isDisabled || merchantStatus === MerchantStatus.ACTIVE
                                                    ? `${styles.submitBtn}`
                                                    : `${styles.primaryButtonColor} ${styles.submitBtn}`
                                            }
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Card>
                <ModalFormConfirmation
                    id="submit-confirmation"
                    isModalOpen={confirmMessage}
                    setIsModalOpen={setConfirmMessage}
                    confirmationAction={() => onCreateMerchant(false)}
                    buttonText={'Continue'}
                    modalText="Please confirm all the information is correct. This information cannot be
                                changed once submitted."
                />
                <ModalFormConfirmation
                    id="back-confirmation"
                    isModalOpen={isBackButtonClicked}
                    setIsModalOpen={setIsBackButtonClicked}
                    confirmationAction={() => setRoute('/home')}
                    buttonText={'Continue'}
                    modalText="Please confirm you wish to go back. Data that has been entered will not be saved."
                />
                {message && (
                    <SnackBarAndAlert open={showMessage} onClose={() => setShowMessage(false)} type="error">
                        {message}
                    </SnackBarAndAlert>
                )}
                <LoadingScreen open={isLoading} />
            </Container>
        </React.Fragment>
    );
}

CanonicalResellerNewMerchantComponent.propTypes = {
    canonicalMerchantDetails: PropTypes.object,
    onboardingForm: PropTypes.object,
    setCanonicalMerchantdetails: PropTypes.func,
    onCreateMerchant: PropTypes.func,
    isLoading: PropTypes.bool,
    isFormValid: PropTypes.bool,
    selectAddressData: PropTypes.func
};

export default CanonicalResellerNewMerchantComponent;
