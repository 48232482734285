import React from 'react';
import { MerchantListContainer, MerchantDashBoardMenu } from 'components';
import { Grid, makeStyles } from '@material-ui/core';
import style from './side-bar-merchant.module.scss';
import Header from '../header/header.container';
import { useGlobalStateContext } from 'contexts';
import { UserType } from 'utils/enums/UserType';

const useStyles = makeStyles((theme) => ({
    root: {
        '&.MuiGrid-grid-md-3': {
            'max-width': 'none'
        }
    }
}));

function SideBarMerchantComponent(props) {
    const classes = useStyles();
    const { globalState } = useGlobalStateContext();
    const { forcedMerchantIdThroughParam } = props;
    const isCanonicalResellerUser = globalState.user?.UserType?.name === UserType.Reseller;

    return (
        <React.Fragment>
            <Grid item md={3} className={classes.root}>
                <Grid item xs={12} className={style.headerContainer}>
                    <Header />
                </Grid>
                <Grid item xs={12} className={style.merchantListContainer}>
                    {!isCanonicalResellerUser && (
                        <MerchantListContainer forcedMerchantIdThroughParam={forcedMerchantIdThroughParam} />
                    )}
                </Grid>
                <Grid item xs={12} className={style.merchantDashBoardMenu}>
                    <MerchantDashBoardMenu isDynamicMenu={false}></MerchantDashBoardMenu>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default SideBarMerchantComponent;
