import React from 'react';
import { Button } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import style from './calender-shuffle.module.scss';
import { useGlobalStateContext } from 'contexts';
import RangePicker from 'react-range-picker';
import Grid from '@material-ui/core/Grid';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import PropTypes from 'prop-types';
import { blue } from '@material-ui/core/colors';
import moment from 'moment';

const CalendarComponent = (props) => {
    const { setRoute } = useGlobalStateContext();
    const {
        next,
        previous,
        range,
        onChangeDateRange,
        showRangePicker,
        setOffrange,
        locationInApp,
        offrange,
        transactionsListingOptions,
        setTransactionsListingOptions,
        loading,
        handleCleverClick,
        daysRange,
        setDaysRange,
        path
    } = props;
    const placeholder = ({ startDate, endDate }) => {
        if ((!startDate, !endDate)) {
            return (
                <div className={style.contentwrap}>
                    <Grid container xs={12}>
                        <Grid item xs={10}>
                            <div className={style.rangeDiv}>
                                <strong>{range}</strong>
                            </div>
                        </Grid>
                        <Grid item xs={1}>
                            <DateRangeOutlinedIcon style={{ color: blue[400] }} />
                        </Grid>
                    </Grid>
                </div>
            );
        } else {
            return (
                <div className={style.contentwrap}>
                    <Grid container xs={12}>
                        <Grid item xs={10}>
                            {offrange ? (
                                <div className={style.datepicker}>
                                    <span>
                                        {startDate.toLocaleDateString('en-GB', {
                                            year: '2-digit',
                                            month: 'short',
                                            day: '2-digit'
                                        })}
                                    </span>
                                    <span>
                                        &nbsp;{'-'}&nbsp;
                                        <span>
                                            {endDate.toLocaleDateString('en-GB', {
                                                year: '2-digit',
                                                month: 'short',
                                                day: '2-digit'
                                            })}
                                        </span>
                                    </span>
                                </div>
                            ) : (
                                <div className={style.rangeDiv}>
                                    <strong>{range}</strong>
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={1}>
                            <DateRangeOutlinedIcon style={{ color: blue[400] }} />
                        </Grid>
                    </Grid>
                </div>
            );
        }
    };

    return (
        <React.Fragment>
            <div className={style.calenderDiv}>
                <div className={style.leftAlign}>
                    <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        className={style.navButton}
                        startIcon={<NavigateBeforeIcon />}
                        disabled={loading}
                        onClick={() => {
                            if (locationInApp === 'merchant-details') {
                                setTransactionsListingOptions({
                                    ...transactionsListingOptions,
                                    date: previous.substr(previous.lastIndexOf('/') + 1)
                                });
                            } else {
                                setRoute(previous);
                            }
                            handleCleverClick('Prev_nav_click');

                            setOffrange(false);
                            if (setDaysRange) setDaysRange({});
                        }}
                    >
                        <span>Prev</span>
                    </Button>
                </div>

                {showRangePicker ? (
                    <div className={style.rangeDiv}>
                        <RangePicker
                            disableRange={path === 'refund-list' || path === 'list-chargebacks' ? true : false}
                            defaultValue={{ startDate: null, endDate: null }}
                            placeholder={placeholder}
                            onDateSelected={(startDate, endDate) => {
                                if (path === 'refund-list' || path === 'list-chargebacks') {
                                    let date = moment(startDate).format('YYYY-MM-DD');
                                    setRoute(`/${path}/day/${date}`);
                                }
                                if (startDate && endDate) {
                                    onChangeDateRange(startDate, endDate);
                                    setDaysRange({
                                        ...daysRange,
                                        startDate: startDate,
                                        endDate: endDate
                                    });
                                } else {
                                    if (path === 'refund-list') {
                                        let date = moment(startDate).format('YYYY-MM-DD');
                                        setRoute(`/${path}/day/${date}`);
                                    }
                                }
                            }}
                            closeOnSelect
                        />
                    </div>
                ) : (
                    <div className={style.rangeDiv}>
                        <strong>{range}</strong>
                    </div>
                )}
                <div className={style.rightAlign}>
                    <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        className={style.navButton}
                        disabled={loading}
                        endIcon={<NavigateNextIcon />}
                        onClick={() => {
                            if (locationInApp === 'merchant-details') {
                                setTransactionsListingOptions({
                                    ...transactionsListingOptions,
                                    date: next.substr(next.lastIndexOf('/') + 1)
                                });
                            } else {
                                setRoute(next);
                            }
                            handleCleverClick('Next_nav_click');
                            setOffrange(false);
                            if (setDaysRange) setDaysRange({});
                        }}
                    >
                        <span>Next</span>
                    </Button>
                </div>
            </div>
            <br />
        </React.Fragment>
    );
};

export default CalendarComponent;

CalendarComponent.propTypes = {
    onChangeDateRange: PropTypes.func,
    showRangePicker: PropTypes.bool,
    path: PropTypes.string.isRequired,
    setOffrange: PropTypes.func,
    offrange: PropTypes.bool,
    setDaysRange: PropTypes.func
};
