import { AuthAxios } from './interceptor.service.js';
import { SettingsServiceEndpoint } from '../utils/constants';
import { SuccesResponse, ErrorResponse } from 'models';

async function createOtp(merchantId, data) {
    try {
        const requestResult = await AuthAxios.post(`${SettingsServiceEndpoint}/otp/generate/${merchantId}`, data);
        return new SuccesResponse(requestResult.data);
    } catch (err) {
        const errMessage =
            err.response?.data?.data ||
            'Unfortunately there was an issue and your request is taking longer than expected. Please try again later.';
        return new ErrorResponse(errMessage, err.response?.status);
    }
}
async function verifyOtp(merchantId, data) {
    try {
        const requestResult = await AuthAxios.post(`${SettingsServiceEndpoint}/otp/verify/${merchantId}`, data);
        return new SuccesResponse(requestResult.data);
    } catch (err) {
        const errMessage =
            err.response?.data?.data ||
            'Unfortunately there was an issue and your request is taking longer than expected. Please try again later.';
        return new ErrorResponse(errMessage, err.response?.status);
    }
}
async function resendOtp(merchantId, data) {
    try {
        const requestResult = await AuthAxios.post(`${SettingsServiceEndpoint}/otp/resend/${merchantId}`, data);
        return new SuccesResponse(requestResult.data);
    } catch (err) {
        const errMessage =
            err.response?.data?.data ||
            'Unfortunately there was an issue and your request is taking longer than expected. Please try again later.';
        return new ErrorResponse(errMessage, err.response?.status);
    }
}

export default {
    createOtp,
    verifyOtp,
    resendOtp
};
