import React, { useState, useCallback, useEffect } from 'react';
import ValidEmailInputComponent from './valid-email-input.component';
import { debounce } from 'lodash';
import { validateEmailAddress } from 'utils/helper';

const ValidEmailInput = (props) => {
    const [emailValidationMessage, setEmailValidationMessage] = useState('');
    const [showEmailValidationMessage, setShowEmailValidationMessage] = useState(false);
    const [fistTime, setFirstTime] = useState(true);

    useEffect(() => {
        if (props.value && fistTime) {
            setFirstTime(false);
            validation(props.value);
        }
        // eslint-disable-next-line
    }, [props.value]);

    const validation = (inputEmail) => {
        const errorMessage = validateEmailAddress(inputEmail);
        setEmailValidationMessage(errorMessage);

        if (!errorMessage) {
            setShowEmailValidationMessage(false);
        } else {
            setShowEmailValidationMessage(true);
        }
    };

    // trigger validation function when user stop typing
    const doValidation = useCallback(debounce(validation, 1000), []);

    return (
        <ValidEmailInputComponent
            {...props}
            doValidation={doValidation}
            showEmailValidationMessage={showEmailValidationMessage}
            setShowEmailValidationMessage={setShowEmailValidationMessage}
            emailValidationMessage={emailValidationMessage}
        />
    );
};

export default ValidEmailInput;
