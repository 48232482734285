import React from 'react';
import StripePayoutDetailsComponent from './stripe-payout-details.component';

const StripePayoutDetails = (props) => {
    const { id: batchId } = props.match.params;
    const { payouts, date } = props.location.state;

    return <StripePayoutDetailsComponent {...props} batchId={batchId} payouts={payouts} date={date} />;
};

export default StripePayoutDetails;
