import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import styles from './product-add-remove-dialog-box.module.scss';
import { Button } from '@material-ui/core';

export default function ProductAddRemoveDialogBoxComponent(props) {
    const { openDialog, setOpenDialog, dialogBoxTitle, dialogBoxContentText, confirmAction } = props;

    return (
        <Dialog
            open={openDialog}
            classes={{
                paper: styles.paper
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{dialogBoxTitle}</DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-description">{dialogBoxContentText}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => setOpenDialog(false)} className={styles.secondaryActionBtn}>
                    Cancel
                </Button>
                <Button color="primary" autoFocus onClick={confirmAction} className={styles.primaryActionBtn}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}
