import { BaseAxios } from './interceptor.service.js';
import {
    AuthServiceEndpoint,
    Region,
    UserPollWebClientId,
    UserPollId,
    IdentityPollId,
    Domain
} from '../utils/constants';
import jwt from 'jsonwebtoken';
let dataMemory = {};
export class MyStorage {
    static syncPromise = null;
    static idTokn = null;

    /**
     *
     * @param {string} key
     * @param {string} value
     */
    static setItem(key, value) {
        if (key.endsWith('refreshToken')) {
            const idToken = jwt.decode(MyStorage.idTokn);
            if (idToken['myPayUserId']) {
                BaseAxios.post(
                    `${AuthServiceEndpoint}/save-refresh-token`,
                    {
                        refreshToken: `${key}:${value}`
                    },
                    {
                        headers: {
                            Authorization: MyStorage.idTokn
                        }
                    }
                )
                    .then(() => console.log('Success'))
                    .catch(() => console.log('Error'));
            }
        } else {
            if (key.endsWith('idToken')) {
                MyStorage.idTokn = value;
            }
            localStorage.setItem('MyPay_' + key, value);
        }
        dataMemory[key] = value;

        return dataMemory[key];
    }

    /**
     * This is used to get a specific key from storage
     */
    static getItem(key) {
        return Object.prototype.hasOwnProperty.call(dataMemory, key) ? dataMemory[key] : undefined;
    }

    /**
     * This is used to remove an item from storage
     * @param {string} key
     */
    static removeItem(key) {
        if (key.endsWith('refreshToken') && MyStorage.idTokn) {
            BaseAxios.delete(`${AuthServiceEndpoint}/delete-refresh-token`, {
                headers: {
                    Authorization: MyStorage.idTokn
                }
            });
        }

        localStorage.removeItem(key);
        return delete dataMemory[key];
    }

    /**
     * This is used to clear the storage
     */
    static clear() {
        dataMemory = {};
        return dataMemory;
    }

    static sync() {
        if (!MyStorage.syncPromise) {
            MyStorage.syncPromise = new Promise((res, rej) => {
                SyncData()
                    .then(() => res())
                    .catch((err) => rej(err));
            });
            return MyStorage.syncPromise;
        }
    }
}
export async function SyncData() {
    try {
        const token = getExistIdToken();

        if (token && !window.location.href.includes('maintenance')) {
            const response = await BaseAxios.get(`${AuthServiceEndpoint}/get-refresh-token`, {
                headers: {
                    Authorization: token
                }
            });

            const data = response.data;
            refreshDataMemory(data.refreshToken);
        } else {
            console.warn('No token found');
        }
    } catch (err) {
        throw err;
    }
}

let redirectSignIn = `${window.location.protocol}//${window.location.host}`;
let redirectSignOut = `${window.location.protocol}//${window.location.host}`;

export const AWSConfiguraion = {
    Auth: {
        region: Region,
        userPoolWebClientId: UserPollWebClientId,
        userPoolId: UserPollId,
        identityPoolId: IdentityPollId,
        //    authenticationFlowType: 'CUSTOM_AUTH',
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        storage: MyStorage,
        oauth: {
            domain: Domain,

            redirectSignIn: redirectSignIn,
            redirectSignOut: redirectSignOut,
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    }
};
//
/**
 *
 * @param {string} refreshToken
 */
function refreshDataMemory(refreshToken) {
    let parsedToken;
    if (refreshToken) {
        parsedToken = refreshToken.split(':');
    } else {
        console.warn('No refresh token found');

        for (let key in localStorage) {
            if (key.startsWith('MyPay_')) {
                MyStorage.removeItem(key);
            }
        }
        return;
    }

    let [refreshTokenIdentificator, refreshTokenValue] = parsedToken;
    dataMemory[refreshTokenIdentificator] = refreshTokenValue;

    for (let key in localStorage) {
        if (key.startsWith('MyPay_')) {
            const memoryKey = key.replace('MyPay_', '');
            dataMemory[memoryKey] = localStorage.getItem(key);
        }
    }
}

function getExistIdToken() {
    for (let key in localStorage) {
        if (key.startsWith('MyPay_') && key.endsWith('idToken')) {
            return localStorage[key].replace(/['"]+/g, '');
        }
    }
}
