import React from 'react';
import CanonicalResellerSettingsComponent from './canonical-reseller-settings.component';

function CanonicalResellerSettings() {
    return <CanonicalResellerSettingsComponent />;
}

CanonicalResellerSettings.propTypes = {};

export default CanonicalResellerSettings;
