import { MerchantCountries } from 'utils/enums/MerchantCountries';

export function validateBankAccountFields(country, tradingAddress, confirmAccountNumber) {
    const {
        sortCode,
        bsb,
        newAccountNumber,
        nameOfBank,
        bankAddress1,
        bankAddress2,
        routingNumber,
        transitNumber,
        financialInstitutionNumber
    } = tradingAddress;
    const sortCodeLength = sortCode ? sortCode.toString().length : 0;
    const bsbLength = bsb ? bsb.toString().length : 0;
    const newAccountNumberLength = newAccountNumber ? newAccountNumber.toString().length : 0;
    const routingNumberLength = routingNumber ? routingNumber.toString().length : 0;

    const transitNumberLength = transitNumber ? transitNumber.toString().length : 0;
    const financialInstitutionNumberLength = financialInstitutionNumber
        ? financialInstitutionNumber.toString().length
        : 0;
    const confirmAccountNumberLength = confirmAccountNumber ? confirmAccountNumber.length : 0;
    var isValid;
    switch (country) {
        case MerchantCountries.UNITED_KINGDOM:
            isValid =
                sortCode &&
                sortCodeLength === 6 &&
                newAccountNumber &&
                newAccountNumberLength === 8 &&
                confirmAccountNumber &&
                confirmAccountNumberLength === 8 &&
                newAccountNumber === confirmAccountNumber;
            break;
        case MerchantCountries.IRELAND:
            isValid = newAccountNumber && newAccountNumberLength === 22 && nameOfBank && bankAddress1 && bankAddress2;
            break;
        case MerchantCountries.AUSTRALIA:
            isValid =
                bsb &&
                bsbLength === 6 &&
                newAccountNumber &&
                newAccountNumberLength >= 5 &&
                confirmAccountNumber &&
                confirmAccountNumberLength >= 5 &&
                newAccountNumber === confirmAccountNumber &&
                nameOfBank;
            break;
        case MerchantCountries.UNITED_STATES:
            isValid =
                newAccountNumber &&
                nameOfBank &&
                routingNumber &&
                routingNumberLength === 9 &&
                newAccountNumber === confirmAccountNumber;
            break;
        case MerchantCountries.CANADA:
            isValid =
                newAccountNumber &&
                newAccountNumberLength >= 7 &&
                transitNumber &&
                transitNumberLength === 5 &&
                financialInstitutionNumber &&
                financialInstitutionNumberLength === 3 &&
                newAccountNumber === confirmAccountNumber;
            break;
        case MerchantCountries.NEW_ZEALAND:
            isValid =
                newAccountNumber &&
                newAccountNumberLength >= 15 &&
                nameOfBank &&
                newAccountNumber === confirmAccountNumber;
            break;
        case MerchantCountries.MEXICO:
            isValid = newAccountNumber && newAccountNumberLength === 18 && newAccountNumber === confirmAccountNumber;
            break;
        default:
            isValid = true;
            break;
    }

    return isValid;
}
