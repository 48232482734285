import React from 'react';
import styles from './modal-add-special-rent.module.scss';
import {
    Button,
    DialogTitle,
    Typography,
    Grid,
    OutlinedInput,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    TextField,
    RadioGroup,
    Radio,
    FormControlLabel
} from '@material-ui/core';
import { Label } from 'components/onboarding/onboarding-ui-components';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import NumberFormat from 'react-number-format';
import { checkEmptyValue } from 'validators/onboarding/onboarding-check-value.validator';

function AddSpecialRentComponent(props) {
    const {
        addSpecialRent,
        setAddSpecialRent,
        specialRentDetails,
        setSpecialRentDetails,
        specialRentDelete,
        setSpecialRentDelete,
        submitSpecialRent,
        updateSpecialRent,
        setUpdateSpecialRent,
        updateSpecialRentData,
        disableBtn,
        deleteSpecialRent,
        isMaxRentValue
    } = props;

    return (
        <React.Fragment>
            {(addSpecialRent || updateSpecialRent) && (
                <Dialog
                    open={addSpecialRent || updateSpecialRent}
                    onClose={() => {
                        setAddSpecialRent(false);
                        setUpdateSpecialRent(false);
                        setSpecialRentDetails({});
                    }}
                    className={{
                        paper: styles.specialRentDialog
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div>
                        <DialogTitle sx={{ m: 0, p: 1 }} className={styles.title}>
                            <div className={styles.dialogTilte}>
                                {addSpecialRent && <Typography>Add New Special Rent</Typography>}
                                {updateSpecialRent && <Typography> Update special rent</Typography>}
                                <CloseIcon
                                    className={styles.closeIcon}
                                    onClick={() => {
                                        setAddSpecialRent(false);
                                        setUpdateSpecialRent(false);
                                        setSpecialRentDetails({});
                                    }}
                                />
                            </div>
                        </DialogTitle>
                        <DialogContent>
                            <Divider />
                        </DialogContent>
                        <DialogActions>
                            <div>
                                <Grid
                                    container
                                    justifyContent="space-evenly"
                                    direction="row"
                                    alignItems="center"
                                    xs={12}
                                    md={12}
                                >
                                    <Grid container xs={12} md={12} spacing={2} className={styles.formContainer}>
                                        <Grid item xs={12} md={6}>
                                            <Label text="Start date"></Label>
                                            <TextField
                                                id="date"
                                                type="date"
                                                variant="outlined"
                                                className={styles.datePicker}
                                                value={specialRentDetails.start_date}
                                                inputProps={{ min: new Date() }}
                                                onChange={(e) =>
                                                    setSpecialRentDetails({
                                                        ...specialRentDetails,
                                                        start_date: e.target.value
                                                    })
                                                }
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Label text="End date"></Label>
                                            <TextField
                                                id="date"
                                                type="date"
                                                variant="outlined"
                                                className={styles.datePicker}
                                                value={specialRentDetails.end_date}
                                                inputProps={{ min: specialRentDetails.start_date }}
                                                disabled={specialRentDetails.start_date ? false : true}
                                                onChange={(e) =>
                                                    setSpecialRentDetails({
                                                        ...specialRentDetails,
                                                        end_date: e.target.value
                                                    })
                                                }
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid container>
                                            <Label
                                                className={styles.rentValueTitle}
                                                text="&nbsp;&nbsp;Rent value"
                                            ></Label>
                                            <Grid container xs={12} sm={12}>
                                                <Grid item xs={0} md={0}></Grid>
                                                <Grid item xs={12} md={12}>
                                                    <NumberFormat
                                                        customInput={OutlinedInput}
                                                        className={styles.rentValue}
                                                        inputMode="numeric"
                                                        pattern="\d*"
                                                        thousandSeparator={true}
                                                        allowLeadingZeros={true}
                                                        allowNegative={true}
                                                        isNumericString={true}
                                                        decimalScale={2}
                                                        fixedDecimalScale={2}
                                                        isAllowed={isMaxRentValue}
                                                        placeholder={`0.00`}
                                                        value={checkEmptyValue(specialRentDetails.rent_amount)}
                                                        onValueChange={(values) => {
                                                            setSpecialRentDetails({
                                                                ...specialRentDetails,
                                                                rent_amount: values.value
                                                            });
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            className={styles.rentfields}
                                            direction="column"
                                            alignItems="flex-start"
                                        >
                                            <Label text="Rent description(select one of the below)"></Label>
                                            <RadioGroup
                                                aria-label="rent-description"
                                                name="rent-description"
                                                value={specialRentDetails.description}
                                                onChange={(e) =>
                                                    setSpecialRentDetails({
                                                        ...specialRentDetails,
                                                        description: e.target.value
                                                    })
                                                }
                                            >
                                                <FormControlLabel
                                                    value="Trial period"
                                                    control={<Radio />}
                                                    label="Trial Period"
                                                />
                                                <FormControlLabel
                                                    value="Special Offer"
                                                    control={<Radio />}
                                                    label="Special offers"
                                                />
                                                <FormControlLabel value="Others" control={<Radio />} label="Others" />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        {addSpecialRent && (
                                            <Button
                                                variant="contained"
                                                onClick={submitSpecialRent}
                                                color="primary"
                                                className={styles.addbtn}
                                                disabled={disableBtn}
                                            >
                                                Add
                                            </Button>
                                        )}
                                        {updateSpecialRent && (
                                            <Button
                                                variant="contained"
                                                onClick={updateSpecialRentData}
                                                color="primary"
                                                className={styles.addbtn}
                                                disabled={disableBtn}
                                            >
                                                Update
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </DialogActions>
                    </div>
                </Dialog>
            )}

            {specialRentDelete && (
                <Dialog
                    open={specialRentDelete}
                    onClose={() => setSpecialRentDelete(false)}
                    className={{
                        paper: styles.specialRentDialog
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Typography>Are you sure you want to delete ?</Typography>
                            </DialogContentText>
                            <Divider />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    setSpecialRentDelete(false);
                                }}
                                color="primary"
                                variant="outlined"
                                className={styles.leavebtn}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setSpecialRentDelete(false);
                                    deleteSpecialRent();
                                }}
                                color="primary"
                                className={styles.dilogbtn}
                            >
                                Continue
                            </Button>
                        </DialogActions>
                    </div>
                </Dialog>
            )}
        </React.Fragment>
    );
}
export default AddSpecialRentComponent;

AddSpecialRentComponent.propTypes = {
    addSpecialRent: PropTypes.bool,
    setAddSpecialRent: PropTypes.func,
    setUpdateSpecialRent: PropTypes.func,
    deleteSpecialRent: PropTypes.func
};
