import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { LoadingScreen } from 'components';
import styles from './adyen-response.module.scss';

const AdyenResponseComponent = (props) => {
    const { adyenResponse, isLoading } = props;

    return (
        <Grid>
            <Grid className={styles.pageTitle}>
                <Typography className={styles.adyenHeader}>Adyen Response</Typography>
            </Grid>
            <Grid style={{ margin: '0px 20px' }}>
                <pre>{adyenResponse}</pre>
            </Grid>
            <LoadingScreen open={isLoading} />
        </Grid>
    );
};

export default AdyenResponseComponent;
