import { ValidationMessage } from 'models';
import { BankAccountLengthConstaints } from 'utils/enums/BankAccountLength';
import { MerchantCountries } from 'utils/enums/MerchantCountries';

export function validateBankDetailsData(bankDetailsData, bankData) {
    const validData =
        bankDetailsData.confirmExistingAcountNumber &&
        bankDetailsData.confirmExistingAcountNumber.length === 8 &&
        bankDetailsData.confirmExistingSortCode &&
        bankDetailsData.confirmExistingSortCode.length === 6 &&
        bankDetailsData.newAcountNumber &&
        bankDetailsData.newAcountNumber.length === 8 &&
        bankDetailsData.newSortCode &&
        bankDetailsData.newSortCode.length === 6 &&
        bankDetailsData.newAcountHolder &&
        bankDetailsData.newAcountHolder.trim().length > 0;

    if (!validData) {
        return new ValidationMessage('Please fill all data.', false);
    }

    if (bankData.sortCode && bankDetailsData.confirmExistingSortCode !== bankData.sortCode) {
        return new ValidationMessage(
            ` Sortcode  ${bankDetailsData.confirmExistingSortCode} does not match the existing one.`,
            false
        );
    }
    if (bankData.newAccountNumber && bankDetailsData.confirmExistingAcountNumber !== bankData.newAccountNumber) {
        return new ValidationMessage(
            ` Bank account number  ${bankDetailsData.confirmExistingAcountNumber} does not match the existing one.`,
            false
        );
    }

    if (bankDetailsData.newAcountNumber !== bankDetailsData.newAcountNumberConfirm) {
        return new ValidationMessage('New account number does not match.', false);
    }
    return new ValidationMessage('', true);
}
export function validateAustraliaBankDetails(bankDetailsData, bankData) {
    const validData =
        bankDetailsData.confirmAustraliaAcountNum &&
        bankDetailsData.confirmAustraliaAcountNum.length >= 5 &&
        bankDetailsData.confirmExistingBsbCode &&
        bankDetailsData.confirmExistingBsbCode.length === 6 &&
        bankDetailsData.newAustraliaAccountNum &&
        bankDetailsData.newAustraliaAccountNum.length >= 5 &&
        bankDetailsData.newBsbCode &&
        bankDetailsData.newBsbCode.length === 6 &&
        bankDetailsData.newAcountHolder &&
        bankDetailsData.newAcountHolder.trim().length > 0;

    if (!validData) {
        return new ValidationMessage('Please fill all data.', false);
    }

    if (bankData.bsb && bankDetailsData.confirmExistingBsbCode !== bankData.bsb) {
        return new ValidationMessage(
            ` Bsbcode  ${bankDetailsData.confirmExistingBsbCode} does not match the existing one.`,
            false
        );
    }
    if (bankData.newAccountNumber && bankDetailsData.confirmAustraliaAcountNum !== bankData.newAccountNumber) {
        return new ValidationMessage(
            ` Bank account number  ${bankDetailsData.confirmAustraliaAcountNum} does not match the existing one.`,

            false
        );
    }

    if (bankDetailsData.newAustraliaAccountNum !== bankDetailsData.newAustraliaAcountNumConfirm) {
        return new ValidationMessage('New account number does not match.', false);
    }
    return new ValidationMessage('', true);
}

export function validateIrelandBankDetailsData(bankDetailsData, bankData) {
    let IBANStatusCode = 0;

    const validData =
        bankDetailsData.confirmExistingIBAN &&
        bankDetailsData.confirmExistingIBAN.length === 22 &&
        bankDetailsData.newIBAN &&
        bankDetailsData.newIBAN.length === 22 &&
        bankDetailsData.newAcountHolder &&
        bankDetailsData.newAcountHolder.trim().length > 0;

    if (!validData) {
        IBANStatusCode = 1;
        return new ValidationMessage('Please fill all data.', false);
    }

    if (
        bankData.newAccountNumber &&
        bankDetailsData.confirmExistingIBAN.toUpperCase() !== bankData.newAccountNumber.toUpperCase()
    ) {
        IBANStatusCode = 2;
        return IBANStatusCode;
    }

    if (bankDetailsData.newIBAN !== bankDetailsData.newIBANConfirm) {
        IBANStatusCode = 3;
        return IBANStatusCode;
    }
    return new ValidationMessage('', true);
}

export function validateMypayBankDetailsData(bankDetailsData, bankData) {
    const validData =
        bankDetailsData.newAcountNumber &&
        bankDetailsData.newAcountNumber.length === 8 &&
        bankDetailsData.newSortCode &&
        bankDetailsData.newSortCode.length === 6 &&
        bankDetailsData.newAcountHolder &&
        bankDetailsData.newAcountHolder.trim().length > 0;

    if (!validData) {
        return new ValidationMessage('Please fill all data.', false);
    }
    if (bankDetailsData.newAcountNumber !== bankDetailsData.newAcountNumberConfirm) {
        return new ValidationMessage('New account number does not match.', false);
    }
    return new ValidationMessage('', true);
}

export function validateNZBankDetailsData(bankDetailsData, bankData) {
    const validData =
        bankDetailsData.confirmExistingNZAcountNumber &&
        bankDetailsData.confirmExistingNZAcountNumber.length >= 15 &&
        bankDetailsData.newNZAcountNumber &&
        bankDetailsData.newNZAcountNumber.length >= 15 &&
        bankDetailsData.newAcountHolder &&
        bankDetailsData.newAcountHolder.trim().length > 0;
    let finalVal = bankDetailsData.confirmExistingNZAcountNumber.toString();
    var myRegexp = /^([^\s]{6})([^\s]{7})([^\s]{2,3})$/g;
    var match = myRegexp.exec(finalVal);
    if (match) {
        match.shift();
        finalVal = match.join('-');
    }
    if (!validData) {
        return new ValidationMessage('Please fill all data.', false);
    }

    if (bankData.newAccountNumber && bankDetailsData.confirmExistingNZAcountNumber !== bankData.newAccountNumber) {
        return new ValidationMessage(` Bank account number  ${finalVal} does not match the existing one.`, false);
    }

    if (bankDetailsData.newNZAcountNumber !== bankDetailsData.newNZAcountNumberConfirm) {
        return new ValidationMessage('New account number does not match .', false);
    }
    return new ValidationMessage('', true);
}

export function validateUnitedStatesBankDetails(bankDetailsData, bankData) {
    const validData =
        bankDetailsData.confirmUnitedStatesAcountNum &&
        bankDetailsData.confirmUnitedStatesAcountNum.length >=
            BankAccountLengthConstaints[MerchantCountries.UNITED_STATES].minLength &&
        bankDetailsData.confirmExistingRouteNum &&
        bankDetailsData.confirmExistingRouteNum.length === 9 &&
        bankDetailsData.newUnitedStatesAccountNum &&
        bankDetailsData.newUnitedStatesAccountNum.length >=
            BankAccountLengthConstaints[MerchantCountries.UNITED_STATES].minLength &&
        bankDetailsData.newRouteNum &&
        bankDetailsData.newRouteNum.length === 9 &&
        bankDetailsData.newAcountHolder &&
        bankDetailsData.newAcountHolder.trim().length > 0;

    if (!validData) {
        return new ValidationMessage('Please fill all data.', false);
    }

    if (bankData.routingNumber && bankDetailsData.confirmExistingRouteNum !== bankData.routingNumber) {
        return new ValidationMessage(
            ` Routing Number  ${bankDetailsData.confirmExistingRouteNum} does not match the existing one.`,
            false
        );
    }
    if (bankData.newAccountNumber && bankDetailsData.confirmUnitedStatesAcountNum !== bankData.newAccountNumber) {
        return new ValidationMessage(
            ` Bank account number  ${bankDetailsData.confirmUnitedStatesAcountNum} does not match the existing one.`,

            false
        );
    }

    if (bankDetailsData.newUnitedStatesAccountNum !== bankDetailsData.newUnitedStatesAcountNumConfirm) {
        return new ValidationMessage('New account number does not match.', false);
    }
    return new ValidationMessage('', true);
}
