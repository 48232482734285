import { SettingsServiceEndpoint } from '../utils/constants';
import { ErrorResponse, SuccesResponse } from 'models';
import {AuthAxios} from "./interceptor.service";

/**
 *
 * @param {String} companyRegistrationNumber
 */
async function getCompanyProfile(companyRegistrationNumber) {
    try {
        const result = await AuthAxios.get(`${SettingsServiceEndpoint}/company/${companyRegistrationNumber}`);

        return new SuccesResponse(result.data);
    } catch (err) {
        return new ErrorResponse('Companies house error.');
    }
}

/**
 *
 * @param {String} companyRegistrationNumber
 */
async function getPersonsWithSignificantControl(companyRegistrationNumber) {
    try {
        const result = await AuthAxios.get(`${SettingsServiceEndpoint}/company/${companyRegistrationNumber}/persons-with-significant-control`);

        return new SuccesResponse(result.data);
    } catch (err) {
        return new ErrorResponse('Companies house error.');
    }
}

/**
 *
 * @param {String} companyRegisteredName
 * @param {Number} items
 */
async function getCompaniesDetail(companyRegisteredName, items) {
    try {
        const result = await AuthAxios.get(`${SettingsServiceEndpoint}/company/search?q=${companyRegisteredName}&items_per_page=${items}`);

        return new SuccesResponse(result.data);
    } catch (err) {
        return new ErrorResponse('Companies house error.');
    }
}

export default {
    getCompanyProfile,
    getPersonsWithSignificantControl,
    getCompaniesDetail
};
