import { DatmanAxios } from './interceptor.service.js';
import { SuccesResponse, ErrorResponse } from 'models';
import { DatmanAPIEndpoint } from 'utils/constants';

async function GetBalance() {
    try {
        const result = await DatmanAxios.get(`${DatmanAPIEndpoint}/portal/balance`);
        return new SuccesResponse(result.data.data);
    } catch (err) {
        return new ErrorResponse('Error');
    }
}

export default {
    GetBalance
};
