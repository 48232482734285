import React, { useState, useEffect } from 'react';
import { useGlobalStateContext } from 'contexts';
import PdqOrderStatusComp from './pdq-order-status.component';
import { ResellerNameAndId, ResellerType } from 'utils/enums/ResellerType';
import { DnaService } from 'services';
import { DNAOnboardStatus } from '../../utils/enums/dnaOnboardStatus';
import { PdqFilterTabs } from '../../utils/enums/PdqPortalTab';
import { formatDateTimeByFormatString } from '../../utils/helper';
import moment from 'moment';

export default function PdqOrderStatus(props) {
    const { globalState } = useGlobalStateContext();
    const [disabled, setDisabled] = useState(true);
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [pdqOrderDetails, setPdqOrderDetails] = useState([]);
    const [merchantData, setMerchantData] = useState('');
    const [messageType, setMessageType] = useState('');
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [dnaOnboardCurrentRequest, setDnaOnboardCurrentRequest] = useState('');
    const [dnaRequest, setDnaRequest] = useState('');
    const merchantId = props.match?.params?.merchantId;
    const resellerId = ResellerNameAndId.find((reseller) => reseller.name === ResellerType.DATMAN).id;
    const result = localStorage.getItem('onBoardedMerchant');
    const onboardedMerchant = result === merchantId;
    const [merchantLegalName, setMerchantLegalName] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');
    const [dnaProductType, setDnaProductType] = useState('');
    const [terminalIdGenerated, setTerminalIdGenerated] = useState(false);
    const [actionButtonSnackbarMessage, setActionButtonSnackbarMessage] = useState({ message: '', type: '' });

    const [statusFilter, setStatusFilter] = useState(PdqFilterTabs.Products);
    const [show, setShow] = useState(false);
    const [showCancelDialog, setShowCancelDialog] = useState(false);
    const [openCollapse, setOpenCollapse] = useState(false);
    const [pdqRequests, setPdqRequests] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [data, setData] = useState({});
    const [modalLoading, setModalLoading] = useState(false);
    const [terminalIdToBeRemoved, setTerminalIdToBeRemoved] = useState('');
    const [productTypeToBeRemoved, setProductTypeToBeRemoved] = useState('');
    const [applicationStatus, setApplicationStatus] = useState({});
    const [agentName, setagentName] = useState(props.history?.location?.state?.agentName);
    const [pdqData, setPdqData] = useState({ licenceKey: '-', acquirerMID: '-' });
    const userEmail = globalState?.user?.email;
    const [pdqFee, setPdqFee] = useState({
        visaDebitFee: 0.5,
        visaDebitAuth: 0.04,
        masterDebitFee: 0.6,
        masterDebitAuth: 0.04,
        visaCreditFee: 0.85,
        visaCreditAuth: 0.04,
        masterCreditFee: 0.9,
        masterCreditAuth: 0.04,
        pdqQty: 1,
        pdqPrice: 0.0
    });
    const canonicalResellerId = globalState.canonicalReseller.id;
    const agentId = globalState.user?.id;
    const businessDescription = {
        businessPrinciple: 1,
        businessDescription: 'FASTFOOD AND TAKEAWAY',
        mcc: 5814,
        b2b: 0,
        b2c: 0,
        outlets: 1,
        additionalOutlets: null
    };
    const allowedBusinessId = [1, 2];
    useEffect(() => {
        const getMerchantData = async () => {
            setIsLoading(true);
            const result = await DnaService.getMerchatDataPdq(merchantId);
            if (result.isSuccesfully) {
                setMerchantData(result.data);
                setMerchantLegalName(result.data.legalName);
                const response = result.data;

                let newData;
                onboardedMerchant
                    ? (newData = {
                          fullName: response.legalName,
                          merchantId: response.MerchantId,
                          email: response.OwnersDetail?.email,
                          contactPhone: response.OwnersDetail?.contactPhone,
                          addressLine1: response.OwnersDetail?.OwnerAddress?.addressLine1,
                          addressLine2: response.OwnersDetail?.OwnerAddress?.addressLine2,
                          city: response.OwnersDetail?.OwnerAddress?.city,
                          postCode: response.OwnersDetail?.OwnerAddress?.postCode
                      })
                    : (newData = {
                          fullName: response.legalName,
                          merchantId: response.MerchantId,
                          email: response.OwnersDetail?.email,
                          birthDate: response.OwnersDetail?.birthDate
                              ? moment(response.OwnersDetail?.birthDate, 'DD-MM-YYYY')?.format('D MMM YYYY')
                              : null,
                          ownership: response.OwnersDetail?.ownership,
                          contactPhone: response.OwnersDetail?.contactPhone,
                          addressLine1: response.OwnersDetail?.OwnerAddress?.addressLine1,
                          addressLine2: response.OwnersDetail?.OwnerAddress?.addressLine2,
                          city: response.OwnersDetail?.OwnerAddress?.city,
                          postCode: response.OwnersDetail?.OwnerAddress?.postCode,
                          ...businessDescription,
                          accountHolderName: response?.BusinessBankDetail?.accountHolderName,
                          nameOfBank: response?.BusinessBankDetail?.nameOfBank,
                          newAccountNumber: response?.BusinessBankDetail?.newAccountNumber?.toString(),
                          sortCode: response?.BusinessBankDetail?.sortCode?.toString(),
                          merchantCreatedAt: formatDateTimeByFormatString(response.createdAt, 'DD MMM YYYY HH:mm'),
                          thirdPartyCustomer: response?.thirdPartyCustomer,
                          registeredNumber: response?.BusinessDetail?.registeredNumber,
                          monthlyTurnOver: 0,
                          annualTurnOver: 0,
                          monthlyCount: 0,
                          annualCount: 0,
                          avgTransaction: 0
                      });
                setData(newData);
            }
            setIsLoading(false);
        };
        getMerchantData();
        // eslint-disable-next-line
    }, [merchantId, onboardedMerchant]);
    useEffect(() => {
        const terminalPayload = { merchantId: merchantId };
        setIsLoading(true);
        getOrderData(terminalPayload);
    }, [merchantId]);

    const getOrderData = async (terminalPayload) => {
        setTerminalIdGenerated(true);
        const response = await DnaService.getPdqDevices(terminalPayload);

        if (response.isSuccesfully) {
            const result = response.data.dnaTerminals.length > 0;

            const groupedObjects = response.data.dnaRequests.map((obj2) => {
                const object =
                    response.data.applicationStatus.length > 0 &&
                    response.data.applicationStatus.filter(
                        (obj1) =>
                            obj1.applicationId === obj2.applicationId &&
                            obj1.applicationStatus !== obj2.applicationStatus
                    );

                return {
                    productType: obj2.productType,
                    merchant_id: obj2.merchant_id,
                    applicationStatus: obj2.applicationStatus,
                    applicationReason: obj2.applicationReason,
                    created_at: obj2.created_at,
                    updated_at: obj2.updated_at,
                    applicationId: obj2.applicationId,
                    statuses: object ? object : []
                };
            });
            setApplicationStatus(response.data?.dnaRequests[0]?.applicationStatus);
            setPdqRequests(groupedObjects);
            setPdqOrderDetails(response.data.dnaTerminals);
            setPdqData({
                licenceKey: response?.data?.dnaTerminals[0]?.licence_key,
                acquirerMID: response?.data?.dnaRequests[0]?.dnaMid
            });
            setDnaOnboardCurrentRequest(response.data.dnaRequestStatus.onBoardRequest);
            setDnaProductType(response.data.dnaProductType);
            if (!terminalPayload.searchValue) {
                setTerminalIdGenerated(result);
            }
        }
        setIsLoading(false);
    };

    const onConfirm = async () => {
        setModalLoading(true);
        const payLoad =
            dnaRequest !== DNAOnboardStatus.CANCEL_DNA_ONBOARD
                ? {
                      merchantData: {
                          ...data,
                          birthDate: moment(data.birthDate).format('DD-MM-YYYY'),
                          monthlyTurnOver: data.monthlyTurnOver * 100,
                          annualTurnOver: data.annualTurnOver * 100,
                          avgTransaction: data.avgTransaction * 100
                      },
                      pdqFee
                  }
                : null;
        const response = await DnaService.dnaOnboardRequest({
            merchantId,
            resellerId,
            merchantLegalName,
            agentId,
            userEmail,
            dnaRequest: dnaRequest,
            payLoad,
            canonicalResellerId: canonicalResellerId
        });
        if (response.isSuccesfully && dnaRequest === DNAOnboardStatus.ONBOARD_DNA_MERCHANT) {
            localStorage.setItem('onBoardedMerchant', merchantId);
            setDnaOnboardCurrentRequest(dnaRequest);
            setMessageType('success');
            setSnackMessage('Successfully raised onboard request');
            setagentName(userEmail);
        } else if (response.isSuccesfully) {
            const message =
                dnaRequest === DNAOnboardStatus.CANCEL_DNA_ONBOARD ? 'cancelled onboard request' : 'raised request';
            DNAOnboardStatus.CANCEL_DNA_ONBOARD && localStorage.removeItem('onBoardedMerchant', merchantId);
            setDnaOnboardCurrentRequest(dnaRequest);
            setMessageType('success');
            setSnackMessage(`Successfully ${message}  `);
        } else {
            setMessageType('error');
            setSnackMessage('An error occurred to raise request. Try again !');
        }
        setOpenForm(false);
        setDnaRequest('');
        setModalLoading(false);
        getOrderData({ merchantId: merchantId });
        setShowCancelDialog(false);
    };

    const handleSearch = async (e) => {
        e.preventDefault();
        const terminalPayload = {
            merchantId: merchantId,
            searchValue: searchValue
        };
        setIsLoading(true);
        setTerminalIdGenerated(false);
        getOrderData(terminalPayload);
    };
    const handleCopyTerminalId = (id, message) => {
        navigator.clipboard.writeText(id);
        setMessageType('success');
        setSnackMessage(`Successfully copied the ${message}`);
    };

    const overFlowText = (text) => {
        return text?.length > 15 ? text?.slice(0, 22) + '...' : text;
    };

    const onPDQRemovalRequest = async () => {
        setModalLoading(true);

        const result = await DnaService.requestPDQRemoval({
            merchantId: merchantId,
            terminalId: terminalIdToBeRemoved
        });

        if (result.isSuccesfully) {
            setActionButtonSnackbarMessage({
                message: result?.data?.message,
                type: 'success'
            });
            getOrderData({ merchantId: merchantId });
        } else {
            setActionButtonSnackbarMessage({
                message: result?.data?.message,
                type: 'error'
            });
        }
        setModalLoading(false);
        setShow(false);
    };

    const openFormModal = async () => {
        if (allowedBusinessId.includes(merchantData?.BusinessDetail?.businessTypeId)) {
            setDnaRequest(onboardedMerchant ? 'extraPdqRequest' : DNAOnboardStatus.ONBOARD_DNA_MERCHANT);
            setOpenForm(true);
        } else {
            setMessageType('warning');
            setSnackMessage(
                `DNA does not currently support partnership merchant account onboarding. Please contact our support team`
            );
        }
    };
    return (
        <PdqOrderStatusComp
            {...props}
            disabled={disabled}
            setDisabled={setDisabled}
            open={open}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            handleSearch={handleSearch}
            isLoading={isLoading}
            merchantData={merchantData}
            setOpen={setOpen}
            pdqOrderDetails={pdqOrderDetails}
            confirmationModal={confirmationModal}
            setConfirmationModal={setConfirmationModal}
            onConfirm={onConfirm}
            snackMessage={snackMessage}
            messageType={messageType}
            setSnackMessage={setSnackMessage}
            onboardedMerchant={onboardedMerchant}
            dnaOnboardCurrentRequest={dnaOnboardCurrentRequest}
            setDnaRequest={setDnaRequest}
            dnaRequest={dnaRequest}
            dialogMessage={dialogMessage}
            setDialogMessage={setDialogMessage}
            dnaProductType={dnaProductType}
            terminalIdGenerated={terminalIdGenerated}
            handleCopyTerminalId={handleCopyTerminalId}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            show={show}
            setShow={setShow}
            showCancelDialog={showCancelDialog}
            setShowCancelDialog={setShowCancelDialog}
            openCollapse={openCollapse}
            setOpenCollapse={setOpenCollapse}
            pdqRequests={pdqRequests}
            overFlowText={overFlowText}
            openForm={openForm}
            setOpenForm={setOpenForm}
            data={data}
            setData={setData}
            pdqFee={pdqFee}
            setPdqFee={setPdqFee}
            modalLoading={modalLoading}
            agentName={agentName}
            onPDQRemovalRequest={onPDQRemovalRequest}
            actionButtonSnackbarMessage={actionButtonSnackbarMessage}
            setActionButtonSnackbarMessage={setActionButtonSnackbarMessage}
            terminalIdToBeRemoved={terminalIdToBeRemoved}
            setTerminalIdToBeRemoved={setTerminalIdToBeRemoved}
            setProductTypeToBeRemoved={setProductTypeToBeRemoved}
            productTypeToBeRemoved={productTypeToBeRemoved}
            applicationStatus={applicationStatus}
            openFormModal={openFormModal}
            pdqData={pdqData}
        />
    );
}
