export const ProductsRequired = {
    Virtual_Terminal: 'Virtual Terminal',
    MyMoto_App: 'MyMoto App',
    MyEcomm: 'MyEcomm',
    Gateway: 'Gateway',
    PDQ: 'PDQ'
};
export const ProductStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    ADDITION_PENDING: 'ADDITION-PENDING',
    DELETION_PENDING: 'DELETION-PENDING',
    REJECTED: 'REJECTED'
};
