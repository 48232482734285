import React, { useState, useEffect } from 'react';
import BunkAccountUploadingDocumentsComponent from './bank-verification-upload-documents.component';
import { useGlobalStateContext } from 'contexts';
import { DocumentsService, BusinessDetailsService, AuthService } from 'services';
import { formTypes } from 'utils/enums/FormTypes';

function BunkAccountUploadingDocumentsContainer(props) {
    const { globalState } = useGlobalStateContext();

    const [validForm, setValidForm] = useState(false);
    const [formattedDocumentsStats, setFormattedDocumentsStats] = useState({});
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [isFinishButtonDisabled, setIsFinishButtonDisabled] = useState(true);
    const [bankUpload, setBankUpload] = useState(false);
    const [businessDetails, setBusinessDetails] = useState({});
    const [count, setCount] = useState(1);
    const [loading, setLoading] = useState(true);

    const isBankDetailsUpdated = props.location.state?.isBankDetailsUpdated;

    const [csrfToken, setCSRFToken] = useState('');

    useEffect(() => {
        const generateCSRFToken = async () => {
            setLoading(true);
            const response = await AuthService.generateCSRFToken(
                formTypes.BANK_DOCUMENT_UPLOAD,
                globalState.merchant?.id
            );
            if (response.isSuccesfully) {
                setCSRFToken(response.data.csrfToken);
            }
            setLoading(false);
        };
        generateCSRFToken();
        // eslint-disable-next-line
    }, [globalState.merchant.id]);

    useEffect(() => {
        const retrieveBusinessDetails = async () => {
            const businessDetailsResult = await BusinessDetailsService.getBusinessDetails(globalState.merchant?.id);
            if (businessDetailsResult.isSuccesfully) {
                setBusinessDetails(businessDetailsResult.data.businessDetails || {});
            }
            setLoading(false);
        };

        if (globalState.merchant?.id) {
            retrieveBusinessDetails();
        }
    }, [globalState.merchant]);

    useEffect(() => {
        async function sendAsyncRequest() {
            if (uploadSuccess) {
                const response = await DocumentsService.notifyAdminAboutDocumentUploading(
                    globalState.merchant.id,
                    globalState.reseller.id,
                    isBankDetailsUpdated
                );
                if (response.isSuccesfully) {
                    setCount(count + 1);
                    sessionStorage.setItem('uploadSucess', count);
                }
            }
        }
        sendAsyncRequest();
        // eslint-disable-next-line
    }, [uploadSuccess]);

    return (
        <BunkAccountUploadingDocumentsComponent
            {...props}
            merchantId={globalState.merchant?.id}
            validForm={validForm}
            setValidForm={setValidForm}
            setFormattedDocumentsStats={setFormattedDocumentsStats}
            formattedDocumentsStats={formattedDocumentsStats}
            setUploadSuccess={setUploadSuccess}
            businessTypeId={businessDetails.businessTypeId}
            isFinishButtonDisabled={isFinishButtonDisabled}
            setIsFinishButtonDisabled={setIsFinishButtonDisabled}
            setBankUpload={setBankUpload}
            bankUpload={bankUpload}
            source={'BANK_UPDATE'}
            loading={loading}
            csrfToken={csrfToken}
            formType={formTypes.BANK_DOCUMENT_UPLOAD}
        />
    );
}

BunkAccountUploadingDocumentsContainer.propTypes = {};

export default BunkAccountUploadingDocumentsContainer;
