import { DocumentTypesToName } from 'utils/enums/DocumentType';
import { DocumentStatusToName } from 'utils/enums/DocumentReviewStatus';
const status = {
    0: 'Not Submitted',
    1: 'Pending',
    2: 'Verified',
    3: 'Active',
    4: 'Closed',
    5: 'Rejected',
    6: 'Pending Stripe',
    12: 'Watch List'
};
const isAccountVerified = {
    0: 'Unverified',
    1: 'Verified'
};
const isBankAccountVerified = {
    0: 'Unverified',
    1: 'Verified'
};
const BusinessTypeEnumId = {
    1: 'Limited',
    2: 'PLC',
    3: 'LLP',
    4: 'Partnership',
    5: 'Sole_Trader',
    6: 'Charity',
    7: 'Corporate_Entity',
    8: 'Discretionary_Trading_Trust',
    9: 'Other'
};
const autoWithrawStatus = {
    0: 'Close',
    1: 'Open'
};
const allowWithdrawals = {
    0: 'Allow for verified account',
    1: 'Allow all (override verification status)'
};
const internalTransferStatus = {
    0: 'Disabled',
    1: 'Enabled'
};
export const merchantsUpdate = {
    status: { ...status },
    isAccountVerified: { ...isAccountVerified },
    isBankAccountVerified: { ...isBankAccountVerified },
    documentTypeId: { ...DocumentTypesToName },
    businessTypeId: { ...BusinessTypeEnumId },
    autoWithdraw: { ...autoWithrawStatus },
    allowWithdrawals: { ...allowWithdrawals },
    internalTransferStatus: { ...internalTransferStatus }
};
const documentStatus = { documentTypeId: { ...DocumentTypesToName }, status: { ...DocumentStatusToName } };
export const auditDataEnum = {
    merchants: { ...merchantsUpdate },
    documents: { ...documentStatus }
};
export const auditIncludedField = Object.keys(auditDataEnum);
export const auditDocumentIncludedField = Object.keys(documentStatus);
export const auditMerchantIncludedField = Object.keys(merchantsUpdate);
