import React from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import { Input, Label } from 'components/onboarding/onboarding-ui-components';
import { SnackBarAndAlert } from 'components';
import { useGlobalStateContext } from 'contexts';
import { AuthService } from 'services';
import { Redirect } from 'react-router-dom';

import PropTypes from 'prop-types';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

import styles from './change-user-attributes.module.scss';
import { useMediaQuery } from '@material-ui/core';
import { InputValidators } from 'validators';
import { UserType } from 'utils/enums/UserType';

const SupportChangeUserAttributes = (props) => {
    const {
        emailToSearch,
        setEmailToSearch,
        newEmail,
        setNewEmail,
        newPhoneNumber,
        setNewPhoneNumber,
        showUpdateAttributeView,
        setShowUpdateAttributeView,
        handleSearchUserByEmail,
        handleUpdatePhoneNumber,
        handleUpdateEmail,
        userToBeModified,
        message,
        setMessage,
        isSuccess,
        updateFirstName,
        updateLastName,
        setUpdateLastName,
        setUpdateFirstName,
        handleUpdateUserName,
        disabled
    } = props;

    const mediaLessThan600px = useMediaQuery('(max-width:599px)');
    const { getRoute } = useGlobalStateContext();
    const { globalState } = useGlobalStateContext();

    const userIsLoggedIn = AuthService.isLoggedUser();
    const isSuperAdmin = globalState.user?.UserType?.name === UserType.SuperAdmin;

    return { userIsLoggedIn } ? (
        <Grid container justify="center" style={mediaLessThan600px ? { marginLeft: 0 } : {}}>
            <Grid item xs={12} md={6} style={{ justifyContent: 'center' }} className={styles.newContainer}>
                <Grid
                    style={{ marginTop: mediaLessThan600px ? 20 : 150, justifyContent: 'center' }}
                    className={styles.attributes}
                >
                    <Label>Change user attributes (search by email)</Label>
                </Grid>
                <Grid container className={styles.newContainer}>
                    <Grid item xs={11} md={8}>
                        <Input
                            placeholder="Enter email to search the user"
                            value={emailToSearch}
                            onChange={(e) => {
                                setEmailToSearch(e.target.value.toLowerCase().trim());
                                setShowUpdateAttributeView(false);
                            }}
                        />
                    </Grid>
                    <Grid item xs={3} className={styles.actionButtonHolder}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSearchUserByEmail}
                            disabled={!emailToSearch || !InputValidators.isEmailValid(emailToSearch)}
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>

                {message && (
                    <SnackBarAndAlert
                        open={true}
                        type={isSuccess ? 'success' : 'error'}
                        onClose={() => {
                            setMessage('');
                        }}
                    >
                        {message}
                    </SnackBarAndAlert>
                )}

                {showUpdateAttributeView && (
                    <>
                        <Grid container className={styles.updateAttributeContainer}>
                            <Grid container className={styles.newContainer}>
                                <Typography>User's name:&nbsp;</Typography>
                                <Typography>
                                    {[userToBeModified.firstName, userToBeModified.lastName].join(' ')}
                                </Typography>
                            </Grid>
                            <Grid container className={styles.newContainer}>
                                <Grid item xs={11} md={8}>
                                    <Input
                                        placeholder="Enter the new email"
                                        value={newEmail}
                                        onChange={(e) => setNewEmail(e.target.value.toLowerCase().trim())}
                                    />
                                </Grid>
                                <Grid item xs={6} md={3} className={styles.actionButtonHolder}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleUpdateEmail}
                                        disabled={
                                            !newEmail ||
                                            !InputValidators.isEmailValid(newEmail) ||
                                            newEmail === emailToSearch
                                        }
                                    >
                                        Update email
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container className={styles.newContainer}>
                                <Grid item xs={11} md={8}>
                                    <PhoneInput
                                        country={'gb'}
                                        value={newPhoneNumber}
                                        placeholder={'Phone'}
                                        alwaysDefaultMask={true}
                                        countryCodeEditable={true}
                                        onChange={(phone) => {
                                            setNewPhoneNumber(phone);
                                        }}
                                        inputClass={styles.phoneInput}
                                        name="phone"
                                    />
                                </Grid>
                                <Grid item xs={6} md={3} className={styles.actionButtonHolderOk}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleUpdatePhoneNumber}
                                        disabled={!newPhoneNumber}
                                    >
                                        Update phone
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        {isSuperAdmin && (
                            <Grid container spacing={2} className={styles.userName}>
                                <Grid item xs={11} md={4}>
                                    <Input
                                        placeholder="First Name"
                                        value={updateFirstName}
                                        onChange={(e) => setUpdateFirstName(e.target.value.trimStart())}
                                        inputProps={{ maxLength: 255 }}
                                    />
                                </Grid>
                                <Grid item xs={11} md={4}>
                                    <Input
                                        placeholder="Last Name"
                                        value={updateLastName}
                                        onChange={(e) => setUpdateLastName(e.target.value.trimStart())}
                                        inputProps={{ maxLength: 255 }}
                                    />
                                </Grid>
                                <Grid item xs={6} md={3} className={styles.actionButtonHolderUpdate}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleUpdateUserName}
                                        disabled={disabled}
                                    >
                                        Update name
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
        </Grid>
    ) : (
        <Redirect to={getRoute(`/login`)} />
    );
};

export default SupportChangeUserAttributes;

SupportChangeUserAttributes.propTypes = {
    emailToSearch: PropTypes.string.isRequired,
    setEmailToSearch: PropTypes.func.isRequired,
    newEmail: PropTypes.string.isRequired,
    setNewEmail: PropTypes.func.isRequired,
    newPhoneNumber: PropTypes.string.isRequired,
    setNewPhoneNumber: PropTypes.func.isRequired,
    showUpdateAttributeView: PropTypes.bool.isRequired,
    setShowUpdateAttributeView: PropTypes.func.isRequired,
    handleSearchUserByEmail: PropTypes.func.isRequired,
    handleUpdatePhoneNumber: PropTypes.func.isRequired,
    handleUpdateEmail: PropTypes.func.isRequired,
    userToBeModified: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        phoneNumber: PropTypes.string
    }),
    message: PropTypes.string.isRequired,
    setMessage: PropTypes.func.isRequired,
    isSuccess: PropTypes.bool.isRequired
};
