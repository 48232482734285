import React from 'react';
import { Box, Paper, Grid } from '@material-ui/core';
import { PageHeader, Calendar, MerchantChargesList, Banner } from 'components';
import WithdrawalContainer from '../withdrawal/withdrawal.container';
import style from './merchant-charges.module.scss';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { useGlobalStateContext } from 'contexts';
import { bannerMessage } from 'utils/banner-message';

const MerchantChargesComponent = (props) => {
    const { globalState } = useGlobalStateContext();
    const { date: dateFromUrlParams, via: viaFromUrlParams } = props.match.params;
    const { pathname } = props.location;
    const { openMerchantModal, setOpenMerchantModal } = props;
    const isMerchantAccountClosed = globalState.merchant?.status === MerchantStatus.CLOSED;

    return (
        <div>
            <Grid item xs={12}>
                {isMerchantAccountClosed && <Banner message={bannerMessage.CLOSED_MERCHANT_ACCOUNT} />}
                <WithdrawalContainer />
                <Box mt={3}>
                    <Calendar
                        {...props}
                        path="merchant-charges"
                        range={viaFromUrlParams}
                        current={pathname}
                        date={dateFromUrlParams}
                    />
                </Box>
                <Paper className={style.merchantChargesContainer}>
                    <PageHeader
                        title={`Merchant Charges`}
                        via={viaFromUrlParams}
                        path="merchant-charges"
                        date={dateFromUrlParams}
                        openMerchantModal={openMerchantModal}
                        setOpenMerchantModal={setOpenMerchantModal}
                    />
                    <MerchantChargesList {...props} />
                </Paper>
            </Grid>
        </div>
    );
};

export default MerchantChargesComponent;
