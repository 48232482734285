import { AuthAxios } from './interceptor.service.js';
import { NonResellerMerchantServiceEndpoint, SettingsServiceEndpoint } from 'utils/constants';
import { SuccesResponse, ErrorResponse } from 'models';

async function inviteMembers(emailsAndRoles, merchantId, resellerId) {
    try {
        await AuthAxios.post(`${NonResellerMerchantServiceEndpoint}/settings/team/invite-members`, {
            emailsAndRoles: emailsAndRoles,
            resellerId: resellerId,
            merchantId: merchantId
        });
        return new SuccesResponse();
    } catch (error) {
        return new ErrorResponse(error.response.data);
    }
}

async function inviteCanonicalResellerMembers(emailsAndRoles, canonicalResellerId, resellerId) {
    try {
        await AuthAxios.post(`${SettingsServiceEndpoint}/team/invite-canonical-reseller-members`, {
            emailsAndRoles: emailsAndRoles,
            canonicalResellerId: canonicalResellerId,
            resellerId: resellerId
        });
        return new SuccesResponse();
    } catch (error) {
        return new ErrorResponse(error.response.data);
    }
}

async function getTeamMembers(id) {
    try {
        const requestResult = await AuthAxios.get(`${SettingsServiceEndpoint}/team/${id}`);

        return new SuccesResponse(requestResult.data);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function getCanonicalResellerTeamMembers(id) {
    try {
        const requestResult = await AuthAxios.get(`${SettingsServiceEndpoint}/canonical-reseller-team/${id}`);

        return new SuccesResponse(requestResult.data);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function removeTeamMember(id, canonicalResellerId) {
    try {
        const requestResult = await AuthAxios.delete(`${SettingsServiceEndpoint}/team/${id}`, {
            data: { canonicalResellerId }
        });

        return new SuccesResponse(requestResult.data);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

export default {
    inviteMembers,
    inviteCanonicalResellerMembers,
    getTeamMembers,
    getCanonicalResellerTeamMembers,
    removeTeamMember
};
