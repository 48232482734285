import React, { useState, useEffect } from 'react';
import AuditDataComponent from './audit-data.component';
import AdminService from 'services/admin.service';
import PropTypes from 'prop-types';
import { useGlobalStateContext } from 'contexts';
const AuditData = (props) => {
    const { globalState } = useGlobalStateContext();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [auditList, setAuditData] = useState([]);
    const [count, setCount] = useState(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('createdDate');
    const [inputValue, setInputValue] = useState('');
    const [open, setOpen] = useState('');

    const headCells = [
        { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
        { id: 'mid', numeric: true, disablePadding: false, label: 'Merchant ID' },
        { id: 'userName', numeric: false, disablePadding: false, label: 'Updated By' },
        { id: 'ip_address', numeric: false, disablePadding: false, label: 'IP Address', hideSortingIcon: true },
        { id: 'updated_date', numeric: false, disablePadding: false, label: 'Date' },
        { id: 'function_name', numeric: false, disablePadding: false, label: 'Function Name', hideSortingIcon: true }
    ];
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getAuditLogData(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        getAuditLogData(0, parseInt(event.target.value, 10));
    };

    useEffect(() => {
        setLoading(true);
        getAuditLogData(0);
        // eslint-disable-next-line
    }, [globalState.reseller?.id]);

    const onSearchMerchants = (e) => {
        setPage(0);
        getAuditLogData(0);
        e.preventDefault();
    };
    const getAuditLogData = async (newPage, newRowsPerPage) => {
        setLoading(true);
        const thePage = newPage ?? page;
        const theRowsPerPage = newRowsPerPage ?? rowsPerPage;

        const response = await AdminService.auditLogData(globalState.reseller?.id, {
            offset: thePage * theRowsPerPage,
            limit: theRowsPerPage,

            searchValue: inputValue.length > 2 ? inputValue.trim() : 'no'
        });
        if (response.isSuccesfully) {
            setOpen(false);
            setAuditData(response.data.data);
            setCount(response.data.count);
        } else {
            setError(true);
        }
        setLoading(false);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setOpen(false);
    };
    return (
        <AuditDataComponent
            {...props}
            rows={auditList}
            error={error}
            loading={loading}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={count}
            order={order}
            orderBy={orderBy}
            handleRequestSort={handleRequestSort}
            setInputValue={setInputValue}
            onSearchMerchants={onSearchMerchants}
            inputValue={inputValue}
            open={open}
            setOpen={setOpen}
            headCells={headCells}
        />
    );
};

AuditData.propTypes = {
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.bool,
    headCells: PropTypes.array,
    setLoading: PropTypes.func,
    rowsPerPage: PropTypes.number,
    setError: PropTypes.func,
    setOpen: PropTypes.func,
    auditData: PropTypes.array,
    setAuditData: PropTypes.func
};

export default AuditData;
