import React from 'react';
import { Grid, useMediaQuery, Typography } from '@material-ui/core';
import { OnboardingSteps } from 'components';
import styles from './onboarding.module.scss';
import Header from 'layouts/header/header.container';
import { LoadingScreen } from 'components';
import { useOnboardingFormContext } from 'contexts';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { useGlobalStateContext } from 'contexts';
import { UserType } from 'utils/enums/UserType';

function OnboardingComponent(props) {
    const { getComponent, steps, step, setStep } = props;
    const { globalState } = useGlobalStateContext();
    const { onboardingForm } = useOnboardingFormContext();
    const mediaLessThan960px = useMediaQuery('(max-width:959px)');
    const isMerchantUserType = globalState.user?.UserType?.name === UserType.Merchant;
    const isActiveMerchant = globalState.merchant?.status === MerchantStatus.ACTIVE;
    const isUkMerchant = globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM;

    return (
        <div>
            <Grid container>
                <Grid item xs={12} className={styles.headerContainer}>
                    <Header
                        timeOut={isMerchantUserType && isActiveMerchant && isUkMerchant}
                        showBanner={isMerchantUserType && isActiveMerchant && isUkMerchant}
                    ></Header>
                </Grid>
                {!mediaLessThan960px && <Grid item xs={1} />}
                {isMerchantUserType && isActiveMerchant && isUkMerchant && mediaLessThan960px && (
                    <div className={styles.listItemContainer}>
                        <Typography color="primary" className={styles.bubbleMessage}>
                            <div>
                                To make sure your details are up to date, please complete the details highlighted in red
                            </div>
                        </Typography>
                    </div>
                )}
                <Grid item xs={12} md={3} className={styles.steps}>
                    <OnboardingSteps
                        getComponent={getComponent}
                        currentStep={step}
                        steps={steps}
                        setStep={setStep}
                        mediaLessThan960px={mediaLessThan960px}
                    />
                </Grid>
                {!mediaLessThan960px && (
                    <React.Fragment>
                        <Grid item xs={1} />
                        <Grid item xs={6} className={styles.formContainer}>
                            {getComponent(step)}
                        </Grid>
                        <Grid item xs={1} />
                    </React.Fragment>
                )}
                <LoadingScreen open={onboardingForm.loading}></LoadingScreen>
            </Grid>
        </div>
    );
}

export default OnboardingComponent;
