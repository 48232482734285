import React from 'react';
import { Grid, Paper, Box } from '@material-ui/core';
import { PageHeader, ChargebackList, Calendar, Banner } from 'components';
import WithdrawalContainer from '../withdrawal/withdrawal.container';
import style from './chargebacks.module.scss';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { useGlobalStateContext } from 'contexts';
import { bannerMessage } from 'utils/banner-message';

const ChargebackListComponent = (props) => {
    const { globalState } = useGlobalStateContext();
    const { date: dateFromUrlParams, via: viaFromUrlParams } = props.match.params;
    const { offrange, setOffrange } = props;
    const { pathname } = props.location;
    const isMerchantAccountClosed = globalState.merchant?.status === MerchantStatus.CLOSED;

    return (
        <React.Fragment>
            <Grid item xs={12}>
                {isMerchantAccountClosed && <Banner message={bannerMessage.CLOSED_MERCHANT_ACCOUNT} />}
                <Box mt={3}>
                    <Calendar
                        path="chargebacks"
                        range={viaFromUrlParams}
                        current={pathname}
                        date={dateFromUrlParams}
                        offrange={offrange}
                        setOffrange={setOffrange}
                        via={viaFromUrlParams}
                    />
                </Box>
                <WithdrawalContainer />
                <Paper className={style.chargebackContainer}>
                    <PageHeader title={`Chargebacks`} path="chargebacks" date={dateFromUrlParams} />
                    <ChargebackList {...props} />
                </Paper>
            </Grid>
        </React.Fragment>
    );
};

export default ChargebackListComponent;
