import React from 'react';
import { Grid, Box, Paper, Button } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import browserHistory from 'browserHistory';
import { PayoutDetailsContainer } from 'components';
import WithdrawalContainer from '../withdrawal/withdrawal.container';
import style from './payout-details.module.scss';

const PayoutDetailsComponent = (props) => {
    const { id: batchId } = props.match.params;
    const { payouts, nextExpectedDate, type, provider, date } = props.location.state;

    return (
        <React.Fragment>
            <Grid container className={style.container}>
                <Grid item xs={12}>
                    <WithdrawalContainer />
                    <Box my={3} pt={5}>
                        <Button
                            color="primary"
                            variant="outlined"
                            size="small"
                            startIcon={<NavigateBeforeIcon />}
                            onClick={() => browserHistory.goBack()}
                        >
                            <span>Back</span>
                        </Button>
                    </Box>
                    <Paper className={style.payoutsContainer}>
                        <PayoutDetailsContainer
                            batchId={batchId}
                            payouts={payouts}
                            date={date}
                            nextExpectedDate={nextExpectedDate}
                            type={type}
                            provider={provider}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default PayoutDetailsComponent;
