import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Header from './header/header.container';
import Content from './content/content.container';
import SideBarMerchant from './side-bar-mechant/side-bar-merchant.container';
import style from './layout.module.scss';
import { Drawer, Grid, useMediaQuery } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { MerchantDashBoardMenu, MerchantListContainer, UrgentMessage } from 'components';
import { AuthService } from 'services';
import { useGlobalStateContext } from 'contexts';
import { UserType } from 'utils/enums/UserType';
function LayoutComponent(props) {
    const mediaLessThan960px = useMediaQuery('(max-width:959px)');
    const mediaLessThan600px = useMediaQuery('(max-width:599px)');
    const { globalState } = useGlobalStateContext();
    const isCanonicalResellerUser = globalState.user?.UserType?.name === UserType.Reseller;
    const [open, setOpen] = useState(false);
    const { urgentMessages, forcedMerchantIdThroughParam } = props;

    return (
        <React.Fragment>
            {AuthService.isLoggedUser() ? (
                <Grid container className={style.container}>
                    {urgentMessages?.length !== 0 && (
                        <div className={style.messageWidth}>
                            {urgentMessages.map((data) => {
                                if (data.merchantIds !== null && JSON.parse(data.merchantIds)?.length !== 0) {
                                    if (JSON.parse(data.merchantIds)?.includes(globalState.merchant?.id?.toString())) {
                                        return <UrgentMessage urgentMessage={data} />;
                                    } else {
                                        if (data.merchantIds === 'null') {
                                            return <UrgentMessage urgentMessage={data} />;
                                        } else {
                                            return null;
                                        }
                                    }
                                } else {
                                    return <UrgentMessage urgentMessage={data} />;
                                }
                            })}
                        </div>
                    )}

                    {!mediaLessThan960px ? (
                        <div className={style.sideNavContainer}>
                            <SideBarMerchant forcedMerchantIdThroughParam={forcedMerchantIdThroughParam} />
                        </div>
                    ) : (
                        <Grid item xs={12} className={style.mobileHeader}>
                            <Grid container>
                                <Grid item xs={2} sm={2} className={style.menuIconContainer}>
                                    <Menu className={style.menuIcon} onClick={() => setOpen(true)} />
                                </Grid>
                                {!mediaLessThan960px && (
                                    <Grid item sm={3}>
                                        {isCanonicalResellerUser && <Header />}
                                        {!mediaLessThan600px && !isCanonicalResellerUser && <Header />}
                                    </Grid>
                                )}
                                <Grid item xs={9} sm={6} className={style.listMerchantsContainer}>
                                    {!isCanonicalResellerUser && (
                                        <MerchantListContainer
                                            forcedMerchantIdThroughParam={forcedMerchantIdThroughParam}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Drawer open={open} onClose={() => setOpen(false)}>
                                <div className={style.drawerMenu}>
                                    <MerchantDashBoardMenu
                                        isDynamicMenu={true}
                                        setOpen={setOpen}
                                    ></MerchantDashBoardMenu>
                                </div>
                            </Drawer>
                        </Grid>
                    )}
                    <Grid item xs={12} md={9} className={style.contentContainer}>
                        <Content />
                    </Grid>
                </Grid>
            ) : (
                <Content />
            )}
        </React.Fragment>
    );
}

LayoutComponent.propTypes = {
    urgentMessages: PropTypes.array
};

export default LayoutComponent;
