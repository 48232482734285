import React from 'react';
import PropTypes from 'prop-types';
import { InputLabel, Typography } from '@material-ui/core';
import style from './label.module.scss';
import { translateText } from '../../../../utils/languageTranslater';

function LabelComponent(props) {
    return (
        <InputLabel className={style.label} id={props.id}>
            <Typography color="primary" className={`${style.labelText} ${props.bold ? style.bold : ''}`}>
                {props.text ? (props.transalterEnabled ? translateText(props) : props.text) : props.children}
            </Typography>
        </InputLabel>
    );
}

LabelComponent.propTypes = {
    text: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]),
    id: PropTypes.string,
    bold: PropTypes.bool
};

export default LabelComponent;
