import React, { useState, useEffect } from 'react';
import CanonicalResellerOnboardingComponent from './canonical-reseller-onboarding.component';
import { CompaniesHouseService, TermsAndConditionsService, CanonicalResellerService } from 'services';
import { BusinessTypeNameAndID } from 'utils/enums/BusinessType';
import { InputValidators } from 'validators';
import { useGlobalStateContext } from 'contexts';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { ImposedByName } from 'utils/enums/TermsAndConditions';
import moment from 'moment';

function CanonicalResellerOnboardingForm() {
    const { globalState, setRoute } = useGlobalStateContext();
    const [canonicalResellerDetails, setCanonicalResellerDetails] = useState({
        tradingName: '',
        primaryContactName: '',
        country: MerchantCountries.UNITED_KINGDOM
    });
    const [orderDetails, setOrderDetails] = useState({});
    const [canonicalResellerCharges, setCanonicalResellerCharges] = useState({});
    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(false);
    const [isNotLimited, setIsNotLimited] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [companies, setCompanies] = useState(true);
    const [message, setMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [messageType, setMessageType] = useState('');
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [disableInput, setDisableInput] = useState(false);
    const [progress, setProgress] = useState(0);
    const initialTermOptions = [...Array(101).keys()];
    initialTermOptions.shift();
    const entity = ImposedByName.CANONICAL_RESELLER;
    const today = moment().format('YYYY-MM-DD');

    useEffect(() => {
        const canonicalResellerDetailCheck =
            canonicalResellerDetails.primaryContactName &&
            InputValidators.isEmailValid(canonicalResellerDetails.primaryContactEmail) &&
            canonicalResellerDetails.telNumber &&
            canonicalResellerDetails.companyName &&
            canonicalResellerDetails.companyNumber &&
            canonicalResellerDetails.companyType !== '--Please select--' &&
            canonicalResellerDetails.postCode &&
            canonicalResellerDetails.city &&
            canonicalResellerDetails.addressLine1 &&
            canonicalResellerDetails.country &&
            InputValidators.isWebsiteUrlValid(canonicalResellerDetails.websiteUrl);
        const orderDetailCheck = orderDetails.orderDate && orderDetails.commencementDate && orderDetails.initialTerm;

        const canonicalResellerChargesCheck =
            canonicalResellerCharges.serviceCharge &&
            canonicalResellerCharges.fixedCharge &&
            canonicalResellerCharges.merchantSetupCharge &&
            canonicalResellerCharges.ratePerTransaction;

        const disabled =
            canonicalResellerDetailCheck && orderDetailCheck && canonicalResellerChargesCheck && disableInput;
        setDisabled(!disabled);

        // eslint-disable-next-line
    }, [canonicalResellerDetails, orderDetails, canonicalResellerCharges, disableInput]);

    const companyTypes = BusinessTypeNameAndID.filter(
        (business) => business.id === 1 || business.id === 2 || business.id === 5
    );

    useEffect(() => {
        setOrderDetails({
            ...orderDetails,
            orderDate: today,
            commencementDate: today
        });
        // eslint-disable-next-line
    }, []);

    const handleSelectedCompany = async (selectedValue) => {
        if (selectedValue && Object.keys(selectedValue).length) {
            setCanonicalResellerDetails({
                ...canonicalResellerDetails,
                companyName: selectedValue.title,
                companyNumber: selectedValue.company_number,
                postCode: selectedValue.address.postal_code,
                addressLine1: `${selectedValue.address.address_line_1}`,
                city: selectedValue.address.locality,
                country: selectedValue.address.country || MerchantCountries.UNITED_KINGDOM
            });
        }
    };

    const getCompanyDetails = async () => {
        const companyName = inputValue;
        setIsLoading(true);
        const result = await CompaniesHouseService.getCompaniesDetail(companyName, 10);

        if (result.isSuccesfully) {
            setCompanies(result.data.items);
            if (result.data.items.length) setOpenDropdown(true);
        }

        setIsLoading(false);
    };

    const selectFile = (newFile) => {
        if (newFile) {
            setFile(newFile);
            setFileName(newFile.name);
            setDisableInput(true);
        }
    };

    const removeSelectedFile = () => {
        let fileUploadInput = document.getElementById('select-terms-and-condition-file');
        if (fileUploadInput) {
            fileUploadInput.value = '';
        }
        setFile(null);
        setFileName('');
        setDisableInput(false);
    };

    const fileProgress = (filename, progress) => {
        const { loaded, total } = progress;
        const percentageProgress = Math.floor((loaded / total) * 100);

        setFileName(filename);
        setProgress(percentageProgress);
    };

    const submitOnboarding = () => {
        setIsLoading(true);
        const data = {
            canonicalResellerDetails: { ...canonicalResellerDetails },
            orderDetails: { ...orderDetails },
            canonicalResellerCharges: { ...canonicalResellerCharges }
        };
        CanonicalResellerService.createNewCanonicalReseller(globalState.reseller?.id, data).then((response) => {
            setShowMessage(true);
            if (response.isSuccesfully) {
                setMessageType('');
                setMessage('');
                const entityId = response.data.canonicalResellerId;
                sessionStorage.setItem('resellerCreationSuccess', true);
                TermsAndConditionsService.upload(entity, entityId, file, fileProgress).then((response) => {
                    setIsLoading(false);
                    if (response.isSuccesfully) {
                        setRoute(`/admin-functions/${globalState.merchant.id}`);
                        removeSelectedFile();
                    } else {
                        setMessageType('error');
                        setMessage('Uploading failed. Please use admin utility to upload T&C.');
                    }
                });
            } else {
                setMessageType('error');
                setMessage(response?.error);
                setIsLoading(false);
            }
        });
    };

    const maxAmount = (values) => {
        const { value, floatValue } = values;
        const MAX_AMT = 99999.99;

        if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
            return true;
        }
        if (value.charAt(0) === '0') {
            if (value.charAt(1) && value.charAt(1) !== '.') {
                return false;
            }
        }
        if (value > MAX_AMT) {
            return false;
        }
        return true;
    };

    return (
        <CanonicalResellerOnboardingComponent
            canonicalResellerDetails={canonicalResellerDetails}
            orderDetails={orderDetails}
            canonicalResellerCharges={canonicalResellerCharges}
            setCanonicalResellerDetails={setCanonicalResellerDetails}
            setOrderDetails={setOrderDetails}
            setCanonicalResellerCharges={setCanonicalResellerCharges}
            inputValue={inputValue}
            setInputValue={setInputValue}
            value={value}
            setValue={setValue}
            getCompanyDetails={getCompanyDetails}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
            isLoading={isLoading}
            handleSelectedCompany={handleSelectedCompany}
            companyTypes={companyTypes}
            isNotLimited={isNotLimited}
            setIsNotLimited={setIsNotLimited}
            disabled={disabled}
            companies={companies}
            message={message}
            initialTermOptions={initialTermOptions}
            submitOnboarding={submitOnboarding}
            showMessage={showMessage}
            messageType={messageType}
            setShowMessage={setShowMessage}
            maxAmount={maxAmount}
            disableInput={disableInput}
            fileName={fileName}
            progress={progress}
            removeSelectedFile={removeSelectedFile}
            selectFile={selectFile}
        />
    );
}

export default CanonicalResellerOnboardingForm;
