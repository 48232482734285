import React, { useState, useEffect } from 'react';
import MissingMerchantComponent from './missing-merchant.component';
import AdminService from 'services/admin.service';
import PropTypes from 'prop-types';
import { useGlobalStateContext } from 'contexts';
const MissingMerchant = (props) => {
    const { getRoute, globalState } = useGlobalStateContext();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [missingList, setMissingList] = useState([]);
    const [count, setCount] = useState(0);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('createdDate');
    const [showAdyen, setShowAdyen] = useState(false);
    const [inputValue, setInputValue] = useState('');

    // const redirected = props.location.state?.redirected;
    const headCells = [
        { id: 'MerchantName', numeric: false, disablePadding: false, label: 'Name' },
        { id: 'MerchantId', numeric: false, disablePadding: false, label: 'Merchant ID', hideSortingIcon: true },
        { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
        { id: 'postcode', numeric: false, disablePadding: false, label: 'Postcode', hideSortingIcon: true },
        { id: 'nationality', numeric: false, disablePadding: false, label: 'Nationality', hideSortingIcon: true },
        { id: 'dob', numeric: false, disablePadding: false, label: 'Date Of Birth', hideSortingIcon: true },
        { id: 'account', numeric: false, disablePadding: false, label: 'Account', hideSortingIcon: true }
    ];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getMissingMerchant(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        getMissingMerchant(0, parseInt(event.target.value, 10));

        setPage(0);
    };

    useEffect(() => {
        setLoading(true);
        getMissingMerchant(0);
        setPage(0);
        // eslint-disable-next-line
    }, [globalState.reseller?.id, showAdyen]);

    const onSearchMerchants = (e) => {
        setPage(0);
        getMissingMerchant(0);
        e.preventDefault();
    };
    const getMissingMerchant = async (newPage, newRowsPerPage) => {
        setLoading(true);
        const thePage = newPage ?? page;
        const theRowsPerPage = newRowsPerPage ?? rowsPerPage;

        const response = await AdminService.GetMissingMerchant(globalState.reseller?.id, {
            offset: thePage * theRowsPerPage,
            limit: theRowsPerPage,
            isAdyen: showAdyen,
            searchValue: inputValue.length > 2 ? inputValue.trim() : 'no'
        });

        if (response.isSuccesfully) {
            setMissingList(response.data.data.ownerId);
            setCount(response.data.data.count);
        } else {
            setError(true);
        }
        setLoading(false);
    };
    const onGoToAccount = (merchantId) => {
        let routeToGo = getRoute(`/missing-merchant-document/${merchantId}`);
        window.open(routeToGo, '_blank');
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            setError(false);
        }
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    return (
        <MissingMerchantComponent
            onGoToAccount={onGoToAccount}
            rows={missingList}
            error={error}
            loading={loading}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={count}
            handleClose={handleClose}
            order={order}
            orderBy={orderBy}
            handleRequestSort={handleRequestSort}
            headCells={headCells}
            showAdyen={showAdyen}
            setShowAdyen={setShowAdyen}
            setInputValue={setInputValue}
            onSearchMerchants={onSearchMerchants}
            inputValue={inputValue}
        />
    );
};

MissingMerchant.propTypes = {
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    loading: PropTypes.bool,
    rows: PropTypes.array,
    error: PropTypes.bool
};

export default MissingMerchant;
