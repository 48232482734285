import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import GfoPaymentsListTableComponent from './gfo-payment-list-table.component';
import { useGlobalStateContext } from 'contexts';
import { useMediaQuery } from '@material-ui/core';
import { GfoPaymentsFilterTabs } from '../../../../utils/enums/GfoPaymentsFilterTabs';
export default function GfoPaymentsListTable(props) {
    const { gfoPayments, loading, channelFilter, error, noTabs, setIsRefundHappen } = props;
    const [rows, setRows] = useState([]);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('time');
    const [statusFilter, setStatusFilter] = useState(
        noTabs ? GfoPaymentsFilterTabs.All : GfoPaymentsFilterTabs.Successful
    );
    const paymentProvider = ['CHECKOUT-GFO', 'ADYEN'];
    const [show, setShow] = useState(false);
    const [payment, setPayment] = useState({});
    const [total, setTotal] = useState();
    const { globalState } = useGlobalStateContext();
    const mediaLessThan600px = useMediaQuery('(max-width:599px)');
    const [isRefundedTransaction, setIsRefundedTransaction] = useState(false);
    const [page, setPage] = useState(0);
    const [refundValue, setRefundValue] = useState();
    useEffect(() => {
        setPage(0);
        let dataRows = gfoPayments;
        dataRows =
            channelFilter !== 'All'
                ? gfoPayments.filter(
                      (payment) => payment.channel.replace(/\s/g, '').toLowerCase() === channelFilter.toLowerCase()
                  )
                : gfoPayments;
        let totalPayment = 0;
        let refund = 0;
        if (statusFilter === GfoPaymentsFilterTabs.Successful) {
            dataRows = dataRows.filter((payment) => !(Number(payment.total) <= 0 && payment.refund));
            setIsRefundedTransaction(false);
        } else if (statusFilter === GfoPaymentsFilterTabs.Refunded) {
            dataRows = dataRows.filter((payment) => Number(payment.total) <= 0 && payment.refund);
            setIsRefundedTransaction(true);
        } else {
            setIsRefundedTransaction(false);
        }
        const gfoTransactions = dataRows.filter((row) => paymentProvider.includes(row.payment_provider));
        gfoTransactions.filter((payment) => {
            if (!(Number(payment.total) <= 0 && payment.refund))
                return (totalPayment = totalPayment + Number(payment.total));
            else {
                return (refund = refund + Number(payment.total));
            }
        }, 0);
        setRows(gfoTransactions);
        setTotal(totalPayment);
        setRefundValue(refund);
        //eslint-disable-next-line
    }, [gfoPayments, statusFilter, channelFilter]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const showTransactionRefund = (rowData) => {
        setShow(true);
        setPayment(rowData);
    };
    const formatRefundReasonAndAmountString = (str) => {
        str = str.replace('&pound;', '');
        str = str.replace('\\n', '');
        str = str.replace('\\n', '');
        str = str.replace('<hr>', '');
        str = str.replace('because', ' - ');
        let arr = str.split('.');
        let str2 = `${arr[0]}.${arr[1] ? arr[1] : ''}`;
        return str2;
    };

    let headCells = [
        {
            id: 'total',
            numeric: true,
            disablePadding: false,
            label: isRefundedTransaction ? 'Refunded amount' : 'Amount'
        },
        // { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
        {
            id: 'transaction details',
            numeric: false,
            disablePadding: false,
            label: 'Transaction details',
            hideSortingIcon: true,
            notShow: mediaLessThan600px
        },
        { id: 'time', numeric: false, disablePadding: false, label: 'Date' },
        { id: 'refund', numeric: false, disablePadding: false, label: 'Refund', hideSortingIcon: true }
    ];
    if (isRefundedTransaction) {
        headCells = headCells.filter((headCellObj) => headCellObj.id !== 'refund');
    }
    if (globalState.merchant?.userRole === 'User') {
        headCells = headCells.filter((headCellObj) => headCellObj.id !== 'refund');
    }

    return (
        <GfoPaymentsListTableComponent
            rows={rows}
            setStatusFilter={setStatusFilter}
            statusFilter={statusFilter}
            headCells={headCells}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            handleRequestSort={handleRequestSort}
            loading={loading}
            isRefundedTransaction={isRefundedTransaction}
            show={show}
            setShow={setShow}
            showTransactionRefund={showTransactionRefund}
            payment={payment}
            error={error}
            total={total}
            setPayment={setPayment}
            formatRefundReasonAndAmountString={formatRefundReasonAndAmountString}
            noTabs={noTabs}
            setIsRefundHappen={setIsRefundHappen}
            GfoPaymentsFilterTabs={GfoPaymentsFilterTabs}
            page={page}
            setPage={setPage}
            refundValue={refundValue}
        />
    );
}

// export default GfoPaymentsListTable;

GfoPaymentsListTable.propTypes = {
    payments: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    channelFilter: PropTypes.string.isRequired,
    error: PropTypes.bool.isRequired
};
