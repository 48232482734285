import React from 'react';
import { useGlobalStateContext } from 'contexts';
import { UserType } from 'utils/enums/UserType';
import LoggedRoute from './logged-route';
import { Redirect } from 'react-router-dom';

export default function MerchantManager({ ...rest }) {
    const { globalState } = useGlobalStateContext();
    const isReseller = globalState.user?.UserType?.name === UserType.Reseller;

    if (!isReseller) {
        return (
            <Redirect
                to={
                    globalState.customDomain === false
                        ? `/${globalState.reseller?.portalURL}/not-authorized`
                        : '/not-authorized'
                }
            />
        );
    }

    return <LoggedRoute {...rest} />;
}
