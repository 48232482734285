import { AuthAxios, DatmanAxios } from './interceptor.service.js';
import {
    AdminServiceEndpoint,
    NonResellerMerchantServiceEndpoint,
    AuthServiceEndpoint,
    DocumentServiceEndpoint,
    DatmanAPIEndpoint
} from '../utils/constants';

import { SuccesResponse, ErrorResponse } from 'models';

const GetPendingCustomers = async () => {
    try {
        const requestResult = await AuthAxios.get(`${AdminServiceEndpoint}/pending-customers`);

        return new SuccesResponse(requestResult.data.data);
    } catch (error) {
        return getError(error);
    }
};

const getError = (error) => {
    const message = error.response ? error.response.data : 'An error occurred';
    return new ErrorResponse(message);
};

const GetMerchantDataForAdmin = async (id) => {
    try {
        const requestResult = await AuthAxios.get(
            `${NonResellerMerchantServiceEndpoint}/admin-get-merchant-data/${id}`
        );

        return new SuccesResponse(requestResult.data);
    } catch (error) {
        return getError(error);
    }
};

const UpdateMerchantDataForAdmin = async (id, data) => {
    try {
        const requestResult = await AuthAxios.put(
            `${NonResellerMerchantServiceEndpoint}/admin-update-merchant-data/${id}`,
            data
        );

        return new SuccesResponse(requestResult.data);
    } catch (error) {
        return getError(error);
    }
};

const adminRejectMerchantEmailNotification = async (data) => {
    try {
        await AuthAxios.post(`${NonResellerMerchantServiceEndpoint}/admin-reject-merchant`, data);

        return new SuccesResponse();
    } catch (error) {
        return getError(error);
    }
};

const sendResetPasswordLink = async (email, portalURL) => {
    try {
        const requestResult = await AuthAxios.post(`${AuthServiceEndpoint}/admin/send-reset-password-link`, {
            email,
            portalURL
        });

        return new SuccesResponse(requestResult.data);
    } catch (error) {
        return getError(error);
    }
};

const GetPendingMerchants = async (id, data) => {
    try {
        const requestResult = await AuthAxios.post(
            `${NonResellerMerchantServiceEndpoint}/admin-get-pending-merchants/${id}`,
            data
        );

        return new SuccesResponse(requestResult.data);
    } catch (error) {
        return getError(error);
    }
};
const GetMissingMerchant = async (id, data) => {
    try {
        const requestResult = await AuthAxios.post(
            `${NonResellerMerchantServiceEndpoint}/admin-get-missing-merchant-info/${id}`,
            data
        );
        return new SuccesResponse(requestResult);
    } catch (error) {
        return getError(error);
    }
};
const GetMissingData = async (id) => {
    try {
        const requestResult = await AuthAxios.get(`${NonResellerMerchantServiceEndpoint}/admin-merchant-data/${id}`);
        return new SuccesResponse(requestResult);
    } catch (error) {
        return getError(error);
    }
};
const UpdateMissingData = async (data) => {
    try {
        const requestResult = await AuthAxios.put(
            `${NonResellerMerchantServiceEndpoint}/admin-update-missing-merchant-info`,
            data
        );
        return new SuccesResponse(requestResult);
    } catch (error) {
        return getError(error);
    }
};

const CountPendingMerchants = async (id) => {
    try {
        const requestResult = await AuthAxios.get(
            `${NonResellerMerchantServiceEndpoint}/admin-count-pending-merchants/${id}`
        );

        return new SuccesResponse(requestResult.data);
    } catch (error) {
        return getError(error);
    }
};

const saveUserKycLogs = async (merchantId, data) => {
    try {
        const requestResult = await AuthAxios.post(
            `${DocumentServiceEndpoint}/save-user-account-kyc-logs-handler/${merchantId}`,
            data
        );

        return new SuccesResponse(requestResult.data);
    } catch (error) {
        return getError(error);
    }
};

const getDocumentById = async (documentId) => {
    try {
        const requestResult = await AuthAxios.get(`${DocumentServiceEndpoint}/get-document-by-id/${documentId}`);

        return new SuccesResponse(requestResult.data);
    } catch (error) {
        return getError(error);
    }
};
const SetUpFees = async (data) => {
    try {
        const response = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/setupFee `, data);
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        return getError(error);
    }
};
const SendProductStatusEmail = async (merchantId, data) => {
    try {
        const requestResult = await AuthAxios.post(
            `${NonResellerMerchantServiceEndpoint}/send-product-status-email/${merchantId}`,
            data
        );

        return new SuccesResponse(requestResult.data);
    } catch (error) {
        return getError(error);
    }
};

const CreateMerchantStripeId = async (merchantId) => {
    try {
        const requestResult = await AuthAxios.post(
            `${AdminServiceEndpoint}/merchants/create-stripe-account/${merchantId}`
        );
        return new SuccesResponse(requestResult.data);
    } catch (error) {
        return getError(error);
    }
};

const acceptTermsAndConditions = async (stripeId, merchantId) => {
    try {
        const requestResult = await AuthAxios.post(
            `${AdminServiceEndpoint}/merchants/accept-terms-and-conditions/${stripeId}/${merchantId}`
        );
        return new SuccesResponse(requestResult.data);
    } catch (error) {
        return getError(error);
    }
};
const getPdqMerchantData = async (merchantId) => {
    try {
        const requestResult = await AuthAxios.post(
            `${AdminServiceEndpoint}/merchants/get-pdq-merchant-data/${merchantId} `
        );
        return new SuccesResponse(requestResult.data);
    } catch (error) {
        return getError(error);
    }
};

const UpdateMerchantStripeId = async (merchantId, data) => {
    try {
        const requestResult = await AuthAxios.post(
            `${AdminServiceEndpoint}/merchants/update-stripe-account/${merchantId}`,
            data
        );
        return new SuccesResponse(requestResult.data);
    } catch (error) {
        return getError(error);
    }
};
const getRefundList = async (data) => {
    try {
        const response = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/refund/status/search`, data);
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        return getError(error);
    }
};
const UpdateRefundStatus = async (data) => {
    try {
        const requestResult = await DatmanAxios.put(`${DatmanAPIEndpoint}/portal/refund/status`, data);

        return new SuccesResponse(requestResult.data);
    } catch (error) {
        return getError(error);
    }
};

const getContractValues = async () => {
    try {
        const response = await DatmanAxios.get(`${DatmanAPIEndpoint}/portal/contracts`);
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        return getError(error);
    }
};

const GetSpecialRentList = async () => {
    try {
        const response = await DatmanAxios.get(`${DatmanAPIEndpoint}/portal/contracts/specialRent`);
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        return getError(error);
    }
};
const getFeeTierNames = async (merchantId) => {
    try {
        const response = await DatmanAxios.get(`${DatmanAPIEndpoint}/portal/customer/fee-tier`, {
            merchantId
        });
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const AddSpecialRent = async (data) => {
    try {
        const response = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/contracts/specialRent `, data);
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        return getError(error);
    }
};
const updateFeeTierId = async (merchantId, data) => {
    try {
        const response = await DatmanAxios.put(`${DatmanAPIEndpoint}/portal/customer/fee-tier`, {
            merchantId,
            data
        });
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        return getError(error);
    }
};
const UpdateSpecialRent = async (data) => {
    try {
        const response = await DatmanAxios.put(`${DatmanAPIEndpoint}/portal/contracts/specialRent `, data);
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        return getError(error);
    }
};
const createFeeTier = async (merchantId, data) => {
    try {
        const response = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/fee-tier`, {
            merchantId,
            percentageFee: data.percentageFee,
            fixedFee: data.fixedFee
        });
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        return getError(error);
    }
};
const DeleteSpecialRent = async (data) => {
    try {
        const response = await DatmanAxios.delete(`${DatmanAPIEndpoint}/portal/contracts/specialRent `, { data });
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        return getError(error);
    }
};

const auditLogData = async (resellerId, data) => {
    try {
        const requestResult = await AuthAxios.post(`${AdminServiceEndpoint}/merchants/audit-data/${resellerId}`, data);

        return new SuccesResponse(requestResult.data);
    } catch (error) {
        return getError(error);
    }
};

const UpdateContractRent = async (data) => {
    try {
        const response = await DatmanAxios.put(`${DatmanAPIEndpoint}/portal/contracts`, data);
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        return getError(error);
    }
};
const GetContractRent = async () => {
    try {
        const response = await DatmanAxios.get(`${DatmanAPIEndpoint}/portal/contracts`);
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        return getError(error);
    }
};
const updateMerchantCharges = async (data) => {
    try {
        const requestResult = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/invoice/create`, data);

        return new SuccesResponse(requestResult.data);
    } catch (error) {
        return getError(error);
    }
};

const payMerchantCharges = async (data) => {
    try {
        const requestResult = await DatmanAxios.put(`${DatmanAPIEndpoint}/portal/invoice`, data);

        return new SuccesResponse(requestResult.data);
    } catch (error) {
        return getError(error);
    }
};

const deleteMerchantCharges = async (data) => {
    try {
        const requestResult = await DatmanAxios.delete(`${DatmanAPIEndpoint}/portal/invoice`, { data });

        return new SuccesResponse(requestResult.data);
    } catch (error) {
        return getError(error);
    }
};
const GetNotRecievedPayouts = async () => {
    try {
        const requestResult = await DatmanAxios.get(`${DatmanAPIEndpoint}/portal/withdrawals/notReceived`);
        return new SuccesResponse(requestResult.data);
    } catch (error) {
        const errMessage = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
};

const AddCredit = async (data) => {
    try {
        const response = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/addCredit`, data);
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        return new getError(error);
    }
};
const GetBalanceReport = async (data) => {
    try {
        const response = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/reports/balance`, data);
        return new SuccesResponse(response.data);
    } catch (error) {
        return new getError(error);
    }
};

const getChargebackList = async (data) => {
    try {
        const response = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/chargebacks/searchTxn`, data);
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        return getError(error);
    }
};
const UpdateChargebackList = async (data) => {
    try {
        const response = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/chargebacks/create`, data);
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        return getError(error);
    }
};
const fetchChargeback = async (data) => {
    try {
        const response = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/chargebacks/fetchDetails`, data);
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        return getError(error);
    }
};

const ChargebackList = async (data) => {
    try {
        const response = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/chargebacks`, data);
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        return getError(error);
    }
};

const GetMerchantDetails = async () => {
    try {
        const response = await DatmanAxios.get(`${DatmanAPIEndpoint}/portal/customer-config`);
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        return getError(error);
    }
};
const UpdateCustomerConfig = async (data) => {
    try {
        const response = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/update-merchant-config`, data);
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        return getError(error);
    }
};
export default {
    GetPendingCustomers,
    GetMerchantDataForAdmin,
    UpdateMerchantDataForAdmin,
    GetPendingMerchants,
    CountPendingMerchants,
    sendResetPasswordLink,
    saveUserKycLogs,
    getDocumentById,
    adminRejectMerchantEmailNotification,
    SetUpFees,
    SendProductStatusEmail,
    CreateMerchantStripeId,
    acceptTermsAndConditions,
    UpdateMerchantStripeId,
    GetMissingMerchant,
    GetMissingData,
    UpdateMissingData,
    updateFeeTierId,
    getFeeTierNames,
    createFeeTier,
    auditLogData,
    getContractValues,
    getRefundList,
    UpdateRefundStatus,
    GetSpecialRentList,
    AddSpecialRent,
    UpdateSpecialRent,
    DeleteSpecialRent,
    UpdateContractRent,
    GetContractRent,
    getChargebackList,
    UpdateChargebackList,
    ChargebackList,
    updateMerchantCharges,
    payMerchantCharges,
    deleteMerchantCharges,
    GetNotRecievedPayouts,
    AddCredit,
    GetBalanceReport,
    fetchChargeback,
    getPdqMerchantData,
    GetMerchantDetails,
    UpdateCustomerConfig
};
