import React, { useState, useEffect } from 'react';
import UpdateTxnLimitComponent from './update-txn-limit.component';
import adminService from 'services/admin.service';

export default function UpdateTxnLimitContainer(props) {
    const { keyValue, setSnackbarDetails, setLoading, setPaymentConfig, paymentConfig } = props;
    const [isInputEnabled, setIsInputEnabled] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [disable, setDisable] = useState(false);
    const [value, setValue] = useState(props?.value);
    const handleButtonClick = () => {
        if (!isInputEnabled) {
            // First click enables input field
            setIsInputEnabled(true);
        } else {
            // Second click opens the popup
            setIsModalOpen(true);
        }
    };

    useEffect(() => {
        value === props.value || !value ? setDisable(true) : setDisable(false);
        //eslint-disable-next-line
    }, [value]);

    const isMaxAmount = (values) => {
        const { value, floatValue } = values;

        if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
            return true;
        }
        if (value.charAt(0) === '0') {
            if (value.charAt(1) && value.charAt(1) !== '.') {
                return false;
            }
        }
        if (value > props.maxValue) {
            return false;
        }
        return true;
    };
    const updateCustomerConfig = async () => {
        setLoading(true);
        const response = await adminService.UpdateCustomerConfig({
            [keyValue]: value
        });
        if (response.isSuccesfully) {
            setSnackbarDetails({ type: 'success', message: 'transaction limit updated successfully' });
            setPaymentConfig({
                ...paymentConfig,
                [keyValue]: value
            });
        } else {
            setSnackbarDetails({ type: 'error', message: 'transaction limit update failed' });
        }
        setIsInputEnabled(false);
        setLoading(false);
    };

    return (
        <UpdateTxnLimitComponent
            {...props}
            isInputEnable={isInputEnabled}
            isModalOpen={isModalOpen}
            handleButtonClick={handleButtonClick}
            setIsModalOpen={setIsModalOpen}
            setIsInputEnabled={setIsInputEnabled}
            value={value}
            setValue={setValue}
            isMaxAmount={isMaxAmount}
            disable={disable}
            updateCustomerConfig={updateCustomerConfig}
        />
    );
}
