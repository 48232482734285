import React from 'react';
import PropTypes from 'prop-types';
import SpecialRentTableComponent from './special-rent-table.component';

const SpecialRentTable = (props) => {
    const {
        setAddSpecialRent,
        specialRentDetails,
        setSpecialRentDelete,
        editSpecialRent,
        deleteSpecialRent,
        specialRentList,
        setDeleteRentId
    } = props;

    let headCells = [
        {
            id: 'description',
            numeric: false,
            disablePadding: false,
            label: 'Description',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'rentAmount',
            numeric: false,
            disablePadding: false,
            label: 'Rent amount',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'start_date',
            numeric: false,
            disablePadding: false,
            label: 'Start date',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'start_date',
            numeric: false,
            disablePadding: false,
            label: 'End date',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Status',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'actions',
            numeric: false,
            disablePadding: false,
            label: 'Actions',
            hideSortingIcon: true,
            alignCenter: true
        }
    ];

    return (
        <SpecialRentTableComponent
            setAddSpecialRent={setAddSpecialRent}
            specialRentDetails={specialRentDetails}
            setSpecialRentDelete={setSpecialRentDelete}
            editSpecialRent={editSpecialRent}
            deleteSpecialRent={deleteSpecialRent}
            specialRentList={specialRentList}
            setDeleteRentId={setDeleteRentId}
            headCells={headCells}
        />
    );
};

export default SpecialRentTable;

SpecialRentTable.propTypes = {
    setAddSpecialRent: PropTypes.func,
    setSpecialRentDelete: PropTypes.func,
    editSpecialRent: PropTypes.func,
    specialRentList: PropTypes.object
};
