import { AuthAxios } from './interceptor.service.js';
import { UserServiceEndpoint } from '../utils/constants';
import { SuccesResponse, ErrorResponse } from 'models';

async function getRoles() {
    try {
        const requestResult = await AuthAxios.get(`${UserServiceEndpoint}/roles`);
        return new SuccesResponse(requestResult.data.roles);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

export default {
    getRoles
};
