import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalStateContext } from 'contexts';
import { Paper, List, ListItem, Typography, Container, Box, Grid, Badge } from '@material-ui/core';
import style from './canonical-reseller-settings.module.scss';
import { withStyles } from '@material-ui/core/styles';
import { Roles } from 'utils/enums/Roles';
import _ from 'lodash';

const StyledBadge = withStyles({
    badge: {
        background: '#8783b5',
        color: '#f6f7fa',
        transform: 'scale(1) translate(85%, -45%)'
    }
})(Badge);
function CanonicalResellerSettingsComponent() {
    const { setRoute, globalState, getRoute } = useGlobalStateContext();

    const signingInfo = globalState.canonicalReseller?.termsAndConditionInfo?.signingInfo;
    const noOfSignedTermsAndConditions = signingInfo?.noOfSignedTermsAndConditions;
    const isAgent = _.isArray(globalState.user?.Relationships)
        ? globalState.user?.Relationships[0]?.Role.name === Roles.Agent
        : false;

    const isUser = _.isArray(globalState.user?.Relationships)
        ? globalState.user?.Relationships[0]?.Role.name === Roles.User
        : false;

    return (
        <Container className={style.mainContainer}>
            <Paper className={style.settingsContainer}>
                <Box className={style.divTitle}>
                    <Typography color="primary" component="span">
                        <span className={style.settingsTitle}> {isAgent ? 'Settings' : 'Reseller settings'} </span>
                    </Typography>
                </Box>
                <Grid className={style.container}>
                    {!isUser && !isAgent && (
                        <Grid item xs={12} sm={6}>
                            <Box className={style.navContainer}>
                                <h3 className="font-weight-bold">Your business</h3>
                                <List component="nav">
                                    <ListItem button onClick={() => setRoute('/update-account')}>
                                        <Box>
                                            <Typography color="primary" component="span">
                                                Update account details
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                </List>
                            </Box>
                        </Grid>
                    )}

                    <Grid item xs={12} sm={6}>
                        <Box className={style.navContainer}>
                            <h3 className="font-weight-bold">Team and security</h3>

                            <List component="nav" className={style.listContainer}>
                                <ListItem className={style.settings__buttons}>
                                    <Box>
                                        <Typography color="primary" component="span" onClick={() => setRoute('/team')}>
                                            User management
                                        </Typography>
                                    </Box>
                                </ListItem>

                                <ListItem className={style.settings__buttons}>
                                    <Box>
                                        <Typography
                                            color="primary"
                                            component="span"
                                            onClick={() => setRoute('/update-password')}
                                        >
                                            Update password
                                        </Typography>
                                    </Box>
                                </ListItem>
                                <ListItem className={style.settings__buttons}>
                                    <StyledBadge
                                        id="badge"
                                        color="default"
                                        badgeContent={noOfSignedTermsAndConditions}
                                        data-tooltip="hello"
                                    >
                                        <Box>
                                            <Typography
                                                color="primary"
                                                component="span"
                                                onClick={() => window.open(getRoute(`/terms-and-conditions`), '_blank')}
                                            >
                                                Terms and conditions
                                            </Typography>
                                        </Box>
                                    </StyledBadge>
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

CanonicalResellerSettingsComponent.propTypes = {
    canViewAndEditTeam: PropTypes.bool,
    isOwner: PropTypes.bool,
    isNotSubmitted: PropTypes.bool,
    isActive: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isMerchant: PropTypes.bool
};

export default CanonicalResellerSettingsComponent;
