import React, { Suspense } from 'react';
import './styles/global.scss';
import './assets/css/theme.css';
import LayoutContainer from './layouts/layout.container';
import { Switch, Router } from 'react-router-dom';
import browserHistory from './browserHistory';
import {
    AuthProvider,
    BalanceProvider,
    ChatBotProvider,
    useGlobalStateContext,
    MerchantCreationProvider
} from 'contexts';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import withoutSideBarMerchantRoutes from './routing/without-sidebar.routes';
import withoutSideBarMerchantCustomDomainRoutes from './routing/without-sidebar.custom-domain.routes';
import Maintainance from 'pages/under-maintainance/under-maintainance.container';
import { TaxonomyService } from 'services';

//
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#43425d',
            light: '#73729a'
        },
        error: {
            main: '#f44336',
            light: '#f44336'
        },
        success: {
            main: '#4caf50',
            light: '#f44336'
        }
    }
});

function App() {
    React.useEffect(() => {
        getMaintainceMode();
    }, []);

    const getMaintainceMode = async () => {
        try {
            const response = await TaxonomyService.getMaintainenceMode(globalState.maintainance);
            const maintainance_mode = response?.maintainence_mode == '1' ? true : false;
            setGlobalState({
                ...globalState,
                customDomain: true,
                maintainance: maintainance_mode,
                maintainanceMessage: response?.maintainence_mode_message
            });
            const maintainanceLink = window.location.href.split('.com')[0] + '.com/maintenance';
            if (!maintainance_mode && window.location.href.includes('maintenance')) {
                window.location.replace(window.location.href.replace('maintenance', 'home'));
            } else if (maintainance_mode && !window.location.href.includes('maintenance')) {
                window.location.replace(maintainanceLink);
            }
        } catch (error) {
            return false;
        }
    };
    // eslint-disable-next-line
    const { globalState, setGlobalState } = useGlobalStateContext();
    return (
        <AuthProvider>
            <ChatBotProvider>
                <BalanceProvider>
                    <MerchantCreationProvider>
                        <MuiThemeProvider theme={theme}>
                            <Router history={browserHistory}>
                                <Suspense fallback={null}>
                                    <Switch>
                                        {JSON.parse(localStorage.getItem('customDomain')) === false ? (
                                            withoutSideBarMerchantRoutes
                                        ) : globalState.maintainance ? (
                                            <Maintainance />
                                        ) : (
                                            withoutSideBarMerchantCustomDomainRoutes
                                        )}
                                        <LayoutContainer />
                                    </Switch>
                                </Suspense>
                            </Router>
                        </MuiThemeProvider>
                    </MerchantCreationProvider>
                </BalanceProvider>
            </ChatBotProvider>
        </AuthProvider>
    );
}

export default App;
