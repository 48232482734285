import React, { useState, useEffect } from 'react';
import PayoutsNotReceivedsComponent from './payout-not-received.component';
import AdminService from 'services/admin.service';
import PayoutsService from 'services/payouts.service';
import { getComparator, stableSort } from 'utils/helper';

import { useGlobalStateContext } from 'contexts';
const PayoutsNotReceived = (props) => {
    const { onGoToAccount, globalState } = useGlobalStateContext();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [notRecievedList, setNotRecievedList] = useState([]);
    const [count, setCount] = useState(0);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('not_received_date');
    const [open, setOpen] = useState(false);
    const [markPayout, setMarkPayout] = useState({});
    const [message, setMessage] = useState(false);
    const [snackbar, SetSnackbar] = useState({ type: '', message: '' });

    const headCells = [
        { id: 'merchant_id', numeric: true, disablePadding: false, label: 'Merchant ID', hideSortingIcon: true },
        { id: 'business_name', numeric: false, disablePadding: false, label: 'Business name', hideSortingIcon: true },
        {
            id: 'not_received_date',
            disablePadding: false,
            label: 'Flagged date',
            hideSortingIcon: true
        },
        { id: 'amount', numeric: false, disablePadding: false, label: 'Amount', hideSortingIcon: true },
        { id: 'options', numeric: false, disablePadding: false, label: 'More options', hideSortingIcon: true }
    ];
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));

        setPage(0);
    };

    useEffect(() => {
        setLoading(true);
        getpayoutNotRecieved();
        setPage(0);
        // eslint-disable-next-line
    }, [globalState.merchant?.id]);

    const getpayoutNotRecieved = async () => {
        setLoading(true);

        const response = await AdminService.GetNotRecievedPayouts();
        if (response.isSuccesfully) {
            const data = stableSort(response.data.data, getComparator(order, orderBy));
            setNotRecievedList(data);
            setCount(response.data.data.length);
        } else {
            setError(true);
        }
        setLoading(false);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            setError(false);
            setMessage(false);
        }
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const onMarkConfirm = async (data) => {
        setLoading(true);

        const response = await PayoutsService.markPayoutNotReceived(data);
        if (response.isSuccesfully) {
            SetSnackbar({ type: 'success', message: response.data?.message });
            getpayoutNotRecieved();
        } else {
            SetSnackbar({ type: 'error', message: response.data?.message });
        }
        setMessage(true);
        setOpen(false);
        setLoading(false);
    };
    return (
        <PayoutsNotReceivedsComponent
            {...props}
            headCells={headCells}
            onGoToAccount={onGoToAccount}
            rows={notRecievedList}
            error={error}
            loading={loading}
            page={page}
            rowsPerPage={rowsPerPage}
            count={count}
            handleClose={handleClose}
            order={order}
            orderBy={orderBy}
            handleRequestSort={handleRequestSort}
            open={open}
            setOpen={setOpen}
            markPayout={markPayout}
            setMarkPayout={setMarkPayout}
            onMarkConfirm={onMarkConfirm}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            message={message}
            snackbar={snackbar}
        />
    );
};

export default PayoutsNotReceived;
