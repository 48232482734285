export const MerchantStatusNames = {
    0: 'Not Submitted',
    1: 'Pending',
    2: 'Verified',
    3: 'Active',
    4: 'Closed',
    5: 'Rejected',
    6: 'Pending Stripe',
    12: 'Watch List'
};

export const OmnipayMerchantStatusNames = {
    1: 'Pending',
    3: 'Active'
};
