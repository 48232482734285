import React from 'react';

import PropTypes from 'prop-types';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Button,
    Typography,
    Divider,
    TextField,
    TableCell,
    TableRow,
    TableBody,
    TableContainer,
    Paper,
    Table,
    OutlinedInput,
    InputAdornment,
    Box,
    Tooltip,
    Link
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { getCurrencySymbol } from 'utils/getCurrencyByCountry';
import { formatDateTimeByFormatString } from '../../utils/helper';

import { Label } from 'components/onboarding/onboarding-ui-components';
import TableHeader from '../payments/transaction-tables/table-header/table-header.container';

import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import styles from './modal-merchant-charges.module.scss';

import { useGlobalStateContext } from 'contexts';
import { LoadingScreen } from 'components';

export default function ModalMerchantChargeComponent(props) {
    const {
        wrapperRef,
        openMerchantModal,
        merchantDate,
        setMerchantDate,
        merchantDescription,
        setMerchantDescription,
        merchantAmount,
        setMerchantAmount,
        merchantBtn,
        merchantReceipt,
        headCells,
        merchantBtnHandler,
        loading,
        isMaxAmount,
        handleClose,
        regex
    } = props;
    const { globalState } = useGlobalStateContext();
    let merchantName = globalState.merchant.name;
    const alertMessage = merchantAmount === '0' || merchantAmount === '0.0' || merchantAmount === '0.00';
    return (
        <>
            <Dialog
                ref={wrapperRef}
                open={openMerchantModal}
                aria-labelledby="form-dialog-title"
                maxWidth="lg"
                className={styles.dialog}
            >
                <LoadingScreen open={loading} />

                <div className={styles.modalContainer}>
                    <DialogTitle sx={{ m: 0, p: 1 }} className={styles.title}>
                        <div className={styles.dialogTitle}>
                            {merchantReceipt === false ? (
                                <Typography>Invoice has been created successfully! </Typography>
                            ) : (
                                <>
                                    <Typography>Add new invoice for</Typography>
                                    <Box className={styles.nameColumn}>
                                        <Tooltip title={merchantName} arrow placement="bottom-start">
                                            <Typography className={styles.merchantName} component={Link}>
                                                {merchantName}
                                            </Typography>
                                        </Tooltip>
                                    </Box>
                                </>
                            )}

                            <CloseIcon className={styles.closeIcon} onClick={handleClose} />
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <Divider />
                    </DialogContent>
                    {merchantReceipt ? (
                        <DialogContent className={styles.dialogContent}>
                            <div>
                                <Grid
                                    container
                                    justifyContent="space-evenly"
                                    direction="column"
                                    alignItems="left"
                                    xs={12}
                                    md={12}
                                >
                                    <Grid container xs={12} md={12} spacing={2} direction="column">
                                        <Grid item xs={12} md={12}>
                                            <Label text="Date"></Label>
                                            <TextField
                                                id="date"
                                                type="date"
                                                variant="outlined"
                                                className={styles.datePicker}
                                                value={merchantDate}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    style: {
                                                        padding: '10px'
                                                    },
                                                    min: new Date().toISOString().slice(0, 10)
                                                }}
                                                onChange={(e) => setMerchantDate(e.target.value)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12}>
                                            <Label text="Description"></Label>
                                            <TextField
                                                id="text"
                                                type="text"
                                                variant="outlined"
                                                className={styles.datePicker}
                                                value={merchantDescription}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    style: {
                                                        padding: '10px'
                                                    },
                                                    maxLength: 30
                                                }}
                                                onChange={(e) =>
                                                    setMerchantDescription(e.target.value.replace(regex, ''))
                                                }
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12}>
                                            <Label text="Amount"></Label>

                                            <NumberFormat
                                                id="amount"
                                                className={styles.numberInput}
                                                customInput={OutlinedInput}
                                                value={merchantAmount}
                                                inputProps={{ inputMode: 'numeric' }}
                                                decimalScale={2}
                                                pattern="\d*"
                                                allowLeadingZeros={false}
                                                allowNegative={false}
                                                isNumericString={true}
                                                isAllowed={isMaxAmount}
                                                onChange={(e) => setMerchantAmount(e.target.value)}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <span>{getCurrencySymbol(globalState.merchant?.country)}</span>
                                                    </InputAdornment>
                                                }
                                            />
                                            {alertMessage && (
                                                <Alert severity="error" className={styles.amountAlert}>
                                                    Amount value should be more than zero
                                                </Alert>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} md={12} className={styles.dialogbtn}>
                                            <Button
                                                variant="contained"
                                                onClick={merchantBtnHandler}
                                                disabled={merchantBtn}
                                                className={styles.refundProcess}
                                                size="small"
                                                color="primary"
                                            >
                                                Add Invoice
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </DialogContent>
                    ) : (
                        <Grid container xs={12} md={12} spacing={2} direction="column" className={styles.table}>
                            <Paper className={styles.paper}>
                                <TableContainer className={styles.TableContainer}>
                                    <Table
                                        aria-label="simple table"
                                        aria-labelledby="tableTitle"
                                        size="small"
                                        stickyHeader
                                        component={Paper}
                                        sx={{ minWidth: 650 }}
                                    >
                                        <TableHeader headCells={headCells} />
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className={styles.tableDataCell}>
                                                    <Box className={styles.nameColumnCell}>
                                                        <Tooltip title={merchantName} arrow placement="bottom-start">
                                                            <Typography
                                                                className={styles.merchantName}
                                                                component={Link}
                                                            >
                                                                {merchantName}
                                                            </Typography>
                                                        </Tooltip>
                                                    </Box>
                                                </TableCell>
                                                <TableCell className={styles.tableDataCell}>
                                                    {formatDateTimeByFormatString(merchantDate, 'DD MMM YY')}
                                                </TableCell>
                                                <TableCell className={styles.tableDataCell}>
                                                    {merchantDescription.replace(regex, '')}
                                                </TableCell>
                                                <TableCell className={styles.tableDataCell}>
                                                    {`${getCurrencySymbol(globalState.merchant.country)} `}
                                                    {merchantAmount}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                    )}
                </div>
            </Dialog>
        </>
    );
}

ModalMerchantChargeComponent.propTypes = {
    emailsAndRoles: PropTypes.array,
    show: PropTypes.bool,
    setShow: PropTypes.func,
    inviteMembers: PropTypes.func,
    setRole: PropTypes.func,
    validateEmptyField: PropTypes.func,
    addNewUserEmailAndRole: PropTypes.func,
    setEmail: PropTypes.func,
    removeNewUserEmailAndRole: PropTypes.func,
    doValidation: PropTypes.func,
    handleClose: PropTypes.func
};
