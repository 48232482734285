import { ValidationMessage } from 'models';
import { MerchantCountries } from 'utils/enums/MerchantCountries';

export function validateRegisteredNumber(country, registeredNumber) {
    var minNumberOfCharacters;
    var maxNumberOfCharacters;
    var isNumeric = false;
    var isMandatory = true;
    var pattern = new RegExp('^\\d+$');

    switch (country) {
        case MerchantCountries.UNITED_KINGDOM:
            minNumberOfCharacters = 8;
            maxNumberOfCharacters = 12;
            isNumeric = false;
            break;
        case MerchantCountries.IRELAND:
            minNumberOfCharacters = 6;
            maxNumberOfCharacters = 20;
            isMandatory = false; //untill we finalize the format and length
            break;
        case MerchantCountries.CANADA:
            minNumberOfCharacters = 6;
            maxNumberOfCharacters = 20;
            isMandatory = false;
            break;
        case MerchantCountries.AUSTRALIA:
            minNumberOfCharacters = 6;
            maxNumberOfCharacters = 20;
            isMandatory = false; //untill we finalize the format and length
            break;
        case MerchantCountries.UNITED_STATES:
            minNumberOfCharacters = 6;
            maxNumberOfCharacters = 20;
            isMandatory = false; //untill we finalize the format and length
            break;
        case MerchantCountries.NEW_ZEALAND:
            minNumberOfCharacters = 6;
            maxNumberOfCharacters = 20;
            isNumeric = false;
            isMandatory = true;
            break;
        default:
            return new ValidationMessage('', true);
    }

    if (registeredNumber && registeredNumber.length > 0) {
        if (
            !isMandatory ||
            (isMandatory &&
                registeredNumber &&
                registeredNumber.length >= minNumberOfCharacters &&
                registeredNumber.length <= maxNumberOfCharacters &&
                (!isNumeric || (isNumeric && pattern.test(registeredNumber))))
        ) {
            return new ValidationMessage('', true);
        }

        let outOfNumberOfCharactersLabel =
            minNumberOfCharacters === maxNumberOfCharacters
                ? maxNumberOfCharacters
                : `(${minNumberOfCharacters}-${maxNumberOfCharacters})`;
        return new ValidationMessage(
            registeredNumber?.length +
                '\\' +
                outOfNumberOfCharactersLabel +
                (isNumeric ? ' numerical' : ' alphanumerical'),
            false
        );
    } else {
        if (isMandatory) {
            return new ValidationMessage('', false);
        }
        return new ValidationMessage('', true);
    }
}
