import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ChargebacksListTableComponent from './chargebacks-list-table.component.jsx';
import { ChargebacksFilterTabs } from '../../../../utils/enums/ChargebacksFilterTabs';
import { formatAmount } from 'utils/helper';

const ChargebacksListTable = (props) => {
    const [rows, setRows] = useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('time');
    const [expandedRowIds, setExpandedRowIds] = React.useState([]);
    const [statusFilter, setStatusFilter] = useState(ChargebacksFilterTabs['Not resolved']);
    const [page, setPage] = useState(0);
    const { chargebacks, loading, error } = props;

    useEffect(() => {
        setPage(0);
        let dataRows = [];
        if (statusFilter !== ChargebacksFilterTabs.All) {
            dataRows = chargebacks.filter((Chargeback) => Chargeback.outcome === statusFilter);
        } else {
            dataRows = chargebacks;
        }
        setRows(dataRows);
    }, [chargebacks, statusFilter]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const toggleExpandTheRow = (event, id) => {
        const indexOfClickedRow = expandedRowIds.indexOf(id);
        let newExpandedRowIds = [];

        if (indexOfClickedRow === -1) {
            newExpandedRowIds = newExpandedRowIds.concat(expandedRowIds, id);
        } else if (indexOfClickedRow === 0) {
            newExpandedRowIds = newExpandedRowIds.concat(expandedRowIds.slice(1));
        } else if (indexOfClickedRow === expandedRowIds.length - 1) {
            newExpandedRowIds = newExpandedRowIds.concat(expandedRowIds.slice(0, -1));
        } else if (indexOfClickedRow > 0) {
            newExpandedRowIds = newExpandedRowIds.concat(
                expandedRowIds.slice(0, indexOfClickedRow),
                expandedRowIds.slice(indexOfClickedRow + 1)
            );
        }

        setExpandedRowIds(newExpandedRowIds);
    };

    const totalChargeback = () => {
        let total = chargebacks.reduce((accumulator, charge) => {
            return accumulator + Number(charge.total);
        }, 0);

        return formatAmount(total);
    };

    //const checkIsExpanded = (id) => expandedRowIds.indexOf(id) !== -1;
    const checkIsExpanded = () => false; //Hide for MVP

    const headCells = [
        { id: 'total', numeric: false, disablePadding: false, label: 'Amount' },
        { id: 'time', numeric: true, disablePadding: false, label: 'Date' },
        { id: 'address', numeric: false, disablePadding: false, label: 'Address', hideSortingIcon: true },
        { id: 'cb_reason', numeric: false, disablePadding: false, label: 'Chargeback reason' }
    ];

    return (
        <ChargebacksListTableComponent
            loading={loading}
            rows={rows}
            setStatusFilter={setStatusFilter}
            statusFilter={statusFilter}
            headCells={headCells}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            checkIsExpanded={checkIsExpanded}
            toggleExpandTheRow={toggleExpandTheRow}
            handleRequestSort={handleRequestSort}
            error={error}
            totalChargeback={totalChargeback}
            page={page}
            setPage={setPage}
        />
    );
};

export default ChargebacksListTable;

ChargebacksListTable.propTypes = {
    chargebacks: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired
};
