import React, { useState } from 'react';
import PdqTransactionsComponent from './pdq-transactions.component';

const PdqTransactions = (props) => {
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    return (
        <PdqTransactionsComponent
            {...props}
            loading={loading}
            setLoading={setLoading}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
        />
    );
};

export default PdqTransactions;
