import React from 'react';
import { Redirect } from 'react-router-dom';
import { useGlobalStateContext } from 'contexts';
import { UserType } from 'utils/enums/UserType';
import LoggedRoute from './logged-route';

const AdminUserTypeRoute = ({ component: Component, ...rest }) => {
    const { globalState } = useGlobalStateContext();
    return (
        <LoggedRoute
            {...rest}
            render={(props) =>
                globalState.user?.UserType?.name === UserType.Admin ||
                globalState.user?.UserType?.name === UserType.SuperAdmin ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={
                            globalState.customDomain === false
                                ? `/${globalState.reseller?.portalURL}/not-authorized`
                                : '/not-authorized'
                        }
                    />
                )
            }
        />
    );
};

export default AdminUserTypeRoute;
