import React from 'react';
import { DatmanResellerPaymentsListTable } from '../transaction-tables';
import { SnackBarAndAlert } from 'components';

const DatmanResellerPaymentsListComponent = (props) => {
    const { isRefundHappen, setIsRefundHappen, showAcquirer, acquirerName, selectedAcquirer, onAcquirerChange } = props;
    return (
        <div style={{ borderTop: '1px solid #ddd' }}>
            <DatmanResellerPaymentsListTable
                {...props}
                showAcquirer={showAcquirer}
                acquirerName={acquirerName}
                selectedAcquirer={selectedAcquirer}
                onAcquirerChange={onAcquirerChange}
            />
            {isRefundHappen !== null && (
                <SnackBarAndAlert
                    open={true}
                    onClose={() => setIsRefundHappen(null)}
                    type={isRefundHappen ? 'success' : 'error'}
                >
                    {isRefundHappen
                        ? 'Your refund request has been sent successfully.'
                        : 'Your refund request has failed.'}
                </SnackBarAndAlert>
            )}
        </div>
    );
};

export default DatmanResellerPaymentsListComponent;
