import React from 'react';
import { Redirect } from 'react-router-dom';
import { useGlobalStateContext } from 'contexts';
import { Roles } from 'utils/enums/Roles';
import LoggedRoute from './logged-route';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { UserType } from 'utils/enums/UserType';
import { ResellerType } from 'utils/enums/ResellerType';

const OwnerAndActiveMerchantOrAdminRoute = ({ component: Component, ...rest }) => {
    const { globalState } = useGlobalStateContext();
    const isAdmin =
        globalState.user?.UserType?.name === UserType.Admin || globalState.user?.UserType?.name === UserType.SuperAdmin;
    const isCanonicalResellerUser = globalState.user?.UserType?.name === UserType.Reseller;
    const isMerchantClosed = globalState.merchant?.status === MerchantStatus.CLOSED;
    const isSuperAdmin = globalState.user?.UserType?.name === UserType.SuperAdmin;
    const isMerchantUser = globalState.user?.UserType?.name === UserType.Merchant;
    const isSuperAdminAndMerchantClosed = ((isAdmin || isMerchantUser) && !isMerchantClosed) || isSuperAdmin;

    const canNotAccessThePage = (merchant, transactionPagePath) => {
        if (transactionPagePath.includes('update-bank')) {
            return merchant?.status === MerchantStatus.WATCHLIST && globalState.merchant?.userRole === Roles.Owner;
        }
        return false;
    };

    const canAccessQrPage = (transactionPagePath) => {
        if (transactionPagePath.includes('generic-qr-code') || transactionPagePath.includes('payment-qr-code')) {
            return globalState.reseller.name === ResellerType.OMNIPAY;
        }
        return true;
    };

    return (
        <LoggedRoute
            {...rest}
            render={(props) =>
                canAccessQrPage(rest.path) &&
                (!canNotAccessThePage(globalState?.merchant, rest.path) ||
                    (globalState.merchant?.userRole === Roles.Owner &&
                        globalState.merchant?.status === MerchantStatus.ACTIVE) ||
                    isAdmin ||
                    isCanonicalResellerUser) &&
                isSuperAdminAndMerchantClosed ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={
                            globalState.customDomain === false
                                ? `/${globalState.reseller?.portalURL}/not-authorized`
                                : '/not-authorized'
                        }
                    />
                )
            }
        />
    );
};

export default OwnerAndActiveMerchantOrAdminRoute;
