import moment from 'moment';

export function getInvalidDobFields(dateOfBirth = {}) {
    let { day = 0, month = 0, year = 0 } = dateOfBirth;

    day = +day;
    month = +month;
    year = +year;

    const dateOfBirthMoment = moment([day, month, year].join('-'), 'DD-MM-YYYY');

    const currentMoment = moment();
    const currentYear = +currentMoment.format('YYYY');
    const currentMonth = +currentMoment.format('M');

    let dobFieldsWithIssues = [];

    if (!(year >= 1900 && year <= currentYear)) {
        dobFieldsWithIssues.push('year');
    }

    let isMonthProper = month >= 1 && month <= 12;

    if (currentYear === year) {
        isMonthProper = isMonthProper && month <= currentMonth;
    }

    if (!isMonthProper) {
        dobFieldsWithIssues.push('month');
    }

    let isDayProper = day >= 1 && day <= 31;

    if (dobFieldsWithIssues.length === 0) {
        isDayProper =
            isDayProper && dateOfBirthMoment.isValid() && dateOfBirthMoment.valueOf() <= currentMoment.valueOf();
    }

    if (!isDayProper) {
        dobFieldsWithIssues.push('day');
    }

    return dobFieldsWithIssues;
}
