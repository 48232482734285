import React from 'react';
import PropTypes from 'prop-types';
import { Button, TextField } from '@material-ui/core';
import styles from './dialog-view-manage-products.module.scss';
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import { LoadingScreen } from 'components';
import { ProductStatus } from 'utils/enums/ProductsRequired';

export default function DialogViewProductsComponent(props) {
    const {
        isModalOpen,
        setIsModalOpen,
        onReject,
        setOnReject,
        setReason,
        onSubmit,
        approve,
        reject,
        onApprove,
        isLoading,
        selectedProduct
    } = props;

    return (
        <React.Fragment>
            <Dialog
                open={isModalOpen}
                classes={{
                    paper: styles.paper
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <span className={styles.closeIcon}>
                    <Button onClick={() => setIsModalOpen(false)}>
                        <CloseIcon fontSize="small" />
                    </Button>
                </span>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you approve
                        {selectedProduct?.status === ProductStatus.ADDITION_PENDING ? (
                            <span> addition</span>
                        ) : (
                            <span> deletion</span>
                        )}
                        &nbsp;of this new product?
                    </DialogContentText>
                    <Divider />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        className={styles.secondaryActionBtn}
                        disabled={approve}
                        variant="contained"
                        onClick={() => {
                            setOnReject(true);
                            setIsModalOpen(false);
                        }}
                    >
                        Reject
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={approve}
                        className={styles.primaryActionBtn}
                        onClick={onApprove}
                    >
                        Approve
                    </Button>
                </DialogActions>
                <LoadingScreen open={isLoading} />
            </Dialog>
            <Dialog
                open={onReject}
                classes={{
                    paper: styles.paper
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <span className={styles.closeIcon}>
                    <Button onClick={() => setOnReject(false)}>
                        <CloseIcon fontSize="small" />
                    </Button>
                </span>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please enter the reason to reject
                    </DialogContentText>
                    <TextField
                        id="outlined-multiline-static"
                        variant="outlined"
                        placeholder="Reason"
                        required
                        onChange={(e) => setReason(e.target.value.trim())}
                        className={styles.textfield}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="contained"
                        className={styles.secondaryActionBtn}
                        disabled={reject}
                        onClick={() => {
                            setOnReject(false);
                            setIsModalOpen(true);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        className={styles.primaryActionBtn}
                        disabled={reject}
                        onClick={onSubmit}
                    >
                        Submit
                    </Button>
                    <LoadingScreen open={isLoading} />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

DialogViewProductsComponent.propTypes = {
    isModalOpen: PropTypes.bool,
    setIsModalOpen: PropTypes.func,
    onApprove: PropTypes.func,
    onReject: PropTypes.bool,
    setOnReject: PropTypes.func,
    onSubmit: PropTypes.func,
    setReason: PropTypes.func,
    setApprove: PropTypes.func,
    setReject: PropTypes.func,
    isLoading: PropTypes.bool
};
