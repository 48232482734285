import React from 'react';
import InputComponent from './input.component';
import PropTypes from 'prop-types';
/**
 *
 * @param {{
 * onChange: (event) => {},
 * placeholder: string,
 * id: string
 * error: boolean
 * helperText: string
 * optionalProps: { disableBorders: boolean}
 * type: 'number' | 'tel' | 'text'
 * }} props
 */
function InputButton(props) {
    var propsCopy = Object.assign({}, props);
    const optionalProps = propsCopy.optionalProps;
    propsCopy.optionalProps = null;
    delete propsCopy.optionalProps;
    return <InputComponent baseProps={propsCopy} optionalProps={optionalProps}></InputComponent>;
}

InputButton.propTypes = {
    optionalProps: PropTypes.object
};

export default InputButton;
