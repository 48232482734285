import React from 'react';
import { StatusColors } from '../../../../utils/enums/StatusColors';
import TransactionStatusLabelComponent from './transaction-status-label.component';
import { PdqStatusColors } from 'utils/enums/PdqStatusColors';
const TransactionStatusLabel = (props) => {
    const status = props?.status?.toUpperCase();
    const isPdq = props?.isPdq;
    const statusColorMap = isPdq ? PdqStatusColors[status] : StatusColors[status] || StatusColors['DEFAULT_SCHEME'];

    return <TransactionStatusLabelComponent {...props} statusColorMap={statusColorMap} />;
};

export default TransactionStatusLabel;
