import { AuthAxios } from './interceptor.service.js';
import { CustomerServiceEndpoint, NonResellerMerchantServiceEndpoint } from '../utils/constants';
import { SuccesResponse, ErrorResponse } from 'models';

const getMerchants = async (businessId, clientId) => {
    try {
        const requestResult = await AuthAxios.get(
            `${CustomerServiceEndpoint}/businesses/${businessId}/clients/${clientId}/merchants`
        );
        return new SuccesResponse(requestResult.data.merchants);
    } catch (error) {
        return getError(error);
    }
};

const getMerchant = async (businessId, clientId, merchantId) => {
    try {
        const requestResult = await AuthAxios.get(
            `${CustomerServiceEndpoint}/businesses/${businessId}/clients/${clientId}/merchants/${merchantId}`
        );
        return new SuccesResponse(requestResult.data);
    } catch (error) {
        return getError(error);
    }
};

const createMerchant = async (businessId, clientId, merchant) => {
    try {
        const response = await AuthAxios.post(
            `${CustomerServiceEndpoint}/businesses/${businessId}/clients/${clientId}/merchants`,
            { merchant: merchant }
        );
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const updateMerchant = async (merchant) => {
    try {
        const response = await AuthAxios.put(
            `${CustomerServiceEndpoint}/businesses/${merchant.businessId}/clients/${merchant.clientId}/merchants/${merchant.id}`,
            { merchant }
        );
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const deleteMerchant = async (businessId, clientId, merchantId) => {
    try {
        const response = await AuthAxios.delete(
            `${CustomerServiceEndpoint}/businesses/${businessId}/clients/${clientId}/merchants/${merchantId}`
        );

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const checkMerchantNameIsUnique = async (businessId, clientId, merchantName) => {
    try {
        const response = await AuthAxios.get(
            `${CustomerServiceEndpoint}/businesses/${businessId}/clients/${clientId}/merchants/is-unique?merchantName=${merchantName}`
        );
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getError = (error) => {
    const message = error.response ? error.response.data : 'An error occurred';
    return new ErrorResponse(message);
};

export default {
    getMerchants,
    createMerchant,
    getMerchant,
    updateMerchant,
    deleteMerchant,
    checkMerchantNameIsUnique
};
