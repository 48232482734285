export default RegExp(
    '^/home.*$|' +
        '^/profile$|' +
        '^/roles-permissions$|' +
        '^/users$|' +
        '^/settings$|' +
        '^/print-agreement$|' +
        '^/team$|' +
        '^/payments/(month|week|day)/[0-9]{4}-[0-9]{2}-[0-9]{2}$|' +
        '^/chargebacks/(month|week|day)/[0-9]{4}-[0-9]{2}-[0-9]{2}$|' +
        '^/merchant-charges/(month|week|day)/[0-9]{4}-[0-9]{2}-[0-9]{2}$|' +
        '^/payouts/(month|week|day)/[0-9]{4}-[0-9]{2}-[0-9]{2}$|' +
        '^/internal-transfers/(month|week|day)/[0-9]{4}-[0-9]{2}-[0-9]{2}$|' +
        '^/pdq-transactions/(month|week|day)/[0-9]{4}-[0-9]{2}-[0-9]{2}$|' +
        '^/virtual-terminal$|' +
        '^/card-details$|' +
        '^/accept-invitation$|' +
        '^/update-password$|' +
        '^/upload-documents$|' +
        '^/payment-details/[0-9]+$|' +
        '^/payout-details/[0-9]+$|' +
        '^/admin-functions/[0-9]+$|' +
        '^/update-bank$|' +
        '^/update-account$|' +
        '^/manage-products$|' +
        '^/admin-pending-merchants$|' +
        '^/client-register$|' +
        '^/admin$|' +
        '^/support-utilities$|' +
        '^/pending-customers$|' +
        '^/login.*$|' +
        '^/signup.*$|' +
        '^/signup-form.*$|' +
        '^/forgot-password.*$|' +
        '^/help$|' +
        '^/onboarding$|' +
        '^/terms-and-conditions$|' +
        '^/not-authorized$|' +
        '^/merchant-details/[0-9]+$|' +
        '^/reset-password.*$|' +
        '^/upload-bank-documents$|' +
        '^/request-support-form$|' +
        '^/stripe-payout-details/[0-9]+$|' +
        '^/upload-bank-documents$|' +
        '^/canonical-reseller-onboarding$|' +
        '^/create-merchant$|' +
        '^/canonical-reseller-merchant-management/[0-9]+$|' +
        '^/setting-terms-and-conditions$|' +
        '^/document-management/[0-9]+$|' +
        '^/qr-code$|' +
        '^/payment-qr-code$|' +
        '^/generic-qr-code$|' +
        '^/refund-list/(month|week|day)/[0-9]{4}-[0-9]{2}-[0-9]{2}$|' +
        '^/list-chargebacks/(month|week|day)/[0-9]{4}-[0-9]{2}-[0-9]{2}$|' +
        '^/linkQr.*$|' +
        '^/adyen-response/[0-9]+$|' +
        '^/admin-missing-merchant$|' +
        '^/missing-merchant-document/[0-9]+$|' +
        '^/acquirer-details$|' +
        '^/pdq-order-merchant/[0-9]+$|' +
        '^/auditlogs$|' +
        '^/refund-list/(month|week|day)/[0-9]{4}-[0-9]{2}-[0-9]{2}$|' +
        '^/contract-details$|' +
        '^/bank-error-list$|' +
        '^/payouts-not-received$|' +
        '^/maintenance$|' +
        `^/adyen-blocked-merchants-list$|`
);
