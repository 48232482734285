import React, { useState, useEffect } from 'react';
import NotAuthorizedPageComponent from './not-authorized.component';
import { useGlobalStateContext } from 'contexts';

function NotAuthorizedPage(props) {
    const { autoRedirect } = props;
    const { setRoute } = useGlobalStateContext();
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 20));
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        if (autoRedirect && progress === 100) {
            setRoute('/home');
        }
        // eslint-disable-next-line
    }, [autoRedirect, progress]);

    return <NotAuthorizedPageComponent isAutoRedirect={autoRedirect} progress={progress} />;
}

export default NotAuthorizedPage;
