import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import {
    AccountStatus,
    BankVerification,
    AccountVerification,
    AddMerchantId,
    MerchantStoreId,
    ManagePartners,
    AdminTriggerResetPassword,
    ThirdPartyMerchantIdAndStoreId,
    LoadingScreen,
    CreateUrgentMessage,
    SnackBarAndAlert,
    AllowWithdrawals,
    MerchantStripeId,
    PaymentConfigurationModes,
    AutoWithdrawal,
    AddPricingTier,
    ModalCreateFeeTier,
    ModalFormConfirmation,
    InvoiceToggleModes,
    ModalOTPVerification,
    ModalMoveTxns,
    UpdateTxnLimitContainer
} from 'components';
import { Alert } from '@material-ui/lab';
import WithdrawalContainer from '../../withdrawal/withdrawal.container';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import browserHistory from 'browserHistory';
import styles from './admin-functions.module.scss';
import { useAdminFunctionsContext, useGlobalStateContext } from 'contexts';
import { ResellerType } from 'utils/enums/ResellerType';
import { CheckMerchantCountries } from '../../../components/onboarding/onboarding-ui-components/check-merchant-countries/check-merchant-countries';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { UserType } from 'utils/enums/UserType';
import { merchantTypes } from 'utils/helper.js';
import { MerchantAccountStatus } from 'utils/enums/MerchantAccountStatus';
import AddCreditFunc from 'components/Add-credit/add-credit.container';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
export default function AdminFunctionsComponent(props) {
    const { globalState, setRoute, getRoute } = useGlobalStateContext();
    const { adminFunctions } = useAdminFunctionsContext();
    const {
        isLoading,
        error,
        setError,
        supportUtilitiesLinksMap,
        merchantVerificationAcceptRejectMessage,
        showMessage,
        setShowMessage,
        messageType,
        clientRegistered,
        setClientRegistered,
        resellerCreation,
        setResellerCreation,
        acceptTermsAndConditions,
        setShowAcceptTermsAndConditionsMessage,
        acceptTermsAndConditionsMessage,
        showAcceptTermsAndConditionsMessage,
        orangeMessageStyle,
        setOrangeMessageStyle,
        setIsTestMerchant,
        isTestMerchant,
        submitHandler,
        setShowCreateFeeTier,
        showCreateFeeTier,
        feeTier,
        setFeeTier,
        pricingTiers,
        setPricingTiers,
        message,
        setMessage,
        showFeeTierMessage,
        setShowFeeTierMessage,
        setMessageType,
        updateFeeTier,
        isModalUpdateFeeTier,
        setIsModalUpdateFeeTier,
        handleOnChange,
        setUpdateFeeTierValue,
        updateFeeTierValue,
        showUpdateFeeTierMessage,
        updateFeeTierMessage,
        setShowUpdateFeeTierMessage,
        loading,
        SubmitReport,
        BalanceReportMessage,
        SetBalanceReportMesssage,
        balanceReportModal,
        SetBalanceReportModal,
        setSelectedDate,
        selectedDate,
        setOpenModalOtpVerification,
        openModalOtpVerification,
        phoneNumber,
        sendOtp,
        otpError,
        setOtpError,
        showMerchantVerificationMessage,
        setShowMerchantVerificationMessage,
        cancelAction,
        showModal,
        setShowModal,
        snackbarDetails,
        setSnackbarDetails,
        paymentConfig,
        setLoading,
        setPaymentConfig,
        handleRenewSignupLink,
        showSignupModal,
        setShowSignupModal
    } = props;
    const [showDialogCreateUrgentMessage, setShowDialogCreateUrgentMessage] = useState(false);
    const [successCreateUrgentMessage, setSuccessCreateUrgentMessage] = useState(false);
    const notShowOption = merchantTypes.includes(globalState.merchant?.merchantType);
    const isMerchantClosed = adminFunctions.accountStatus === MerchantAccountStatus.CLOSED;
    // const isInternalTransferEnabled = adminFunctions.internalTransferStatus === true;

    const isAdminOrSuperAdmin =
        globalState.user?.UserType?.name === UserType.SuperAdmin || globalState.user?.UserType?.name === UserType.Admin;
    const isSuperAdmin = globalState.user?.UserType?.name === UserType.SuperAdmin;

    const BalanceReportDatePicker = () => {
        return (
            <div className={styles.dateDiv}>
                <span>Select the month and year</span>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        views={['year', 'month']}
                        label="Month"
                        value={selectedDate}
                        onChange={(value) => setSelectedDate(value)}
                        animateYearScrolling
                        minDate={'01-01-2000'}
                        maxDate={new Date()}
                    />
                </MuiPickersUtilsProvider>
            </div>
        );
    };
    return (
        <div>
            {error ? (
                <Alert className={styles.alertError} onClose={() => setError(false)} severity="error">
                    An error occurred. Please contact support or try again.
                </Alert>
            ) : loading ? (
                <LoadingScreen open={loading} />
            ) : (
                <Grid container>
                    <WithdrawalContainer />
                    <Grid item xs={12}>
                        <Box pt={2}>
                            <Button
                                color="primary"
                                variant="outlined"
                                size="small"
                                startIcon={<NavigateBeforeIcon />}
                                onClick={() => browserHistory.goBack()}
                            >
                                <span>Back</span>
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AccountStatus />
                        <AccountVerification />
                        <BankVerification />
                        <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_KINGDOM]}>
                            {globalState.reseller?.name === ResellerType.DATMAN && !globalState.merchant?.isStripe && (
                                <AutoWithdrawal />
                            )}
                        </CheckMerchantCountries>
                        {/* We might require the below code in future so it is currently commented out        */}
                        {/* <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_KINGDOM]}>
                            {globalState.reseller?.name === ResellerType.DATMAN && !notShowOption && (
                                <InternalTransfer />
                            )}
                        </CheckMerchantCountries>
                        <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_KINGDOM]}>
                            {globalState.reseller?.name === ResellerType.DATMAN &&
                                !notShowOption &&
                                isInternalTransferEnabled &&
                                isAdminOrSuperAdmin && <InternalTransferMode />}
                        </CheckMerchantCountries> */}

                        {globalState.reseller?.name === ResellerType.DATMAN && (
                            <AddPricingTier
                                feeTier={feeTier}
                                setFeeTier={setFeeTier}
                                pricingTiers={pricingTiers}
                                setPricingTiers={setPricingTiers}
                                updateFeeTier={updateFeeTier}
                                setIsModalUpdateFeeTier={setIsModalUpdateFeeTier}
                                setUpdateFeeTierValue={setUpdateFeeTierValue}
                            />
                        )}
                        {(!adminFunctions.accountVerify || !adminFunctions.bankVerify) && !notShowOption && (
                            <AllowWithdrawals />
                        )}
                        <Grid item xs={12} sm={12} container>
                            <Grid item xs={12} sm={12} className={styles.thirdId}>
                                <ThirdPartyMerchantIdAndStoreId />
                            </Grid>
                            <CheckMerchantCountries excludedCountries={[MerchantCountries.UNITED_KINGDOM]}>
                                <Grid item xs={12} sm={12} className={styles.thirdId}>
                                    <MerchantStripeId />
                                </Grid>
                            </CheckMerchantCountries>
                        </Grid>
                        <Grid item sm={12} md={12} xs={12} className={styles.dropDown} direction="row" spacing={1}>
                            <PaymentConfigurationModes />
                        </Grid>
                        <Grid item sm={12} md={12} xs={12} className={styles.dropDown} direction="row" spacing={1}>
                            <UpdateTxnLimitContainer
                                labelText={'Regular transaction limit'}
                                value={paymentConfig?.max_single_txn_limit}
                                maxValue={paymentConfig?.txnUpperLimit}
                                keyValue="max_single_txn_limit"
                                setSnackbarDetails={setSnackbarDetails}
                                setLoading={setLoading}
                                setPaymentConfig={setPaymentConfig}
                                paymentConfig={paymentConfig}
                            />
                        </Grid>
                        <Grid item sm={12} md={12} xs={12} className={styles.dropDown} direction="row" spacing={1}>
                            <UpdateTxnLimitContainer
                                labelText={'XpressPay transaction limit'}
                                value={paymentConfig?.xpress_sale_limit}
                                maxValue={paymentConfig?.xpressSaleUpperLimit}
                                keyValue="xpress_sale_limit"
                                setSnackbarDetails={setSnackbarDetails}
                                setLoading={setLoading}
                                setPaymentConfig={setPaymentConfig}
                                paymentConfig={paymentConfig}
                            />
                        </Grid>
                        {globalState.reseller?.name === ResellerType.OMNIPAY && (
                            <Grid item sm={12} md={12} xs={12} className={styles.dropDown} direction="row" spacing={1}>
                                <InvoiceToggleModes />
                            </Grid>
                        )}

                        {globalState.reseller?.name === ResellerType.DATMAN && (
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={isTestMerchant}
                                            onChange={(e) => {
                                                setIsTestMerchant(e.target.checked);
                                                submitHandler(e.target.checked);
                                            }}
                                        />
                                    }
                                    label="Test Merchant"
                                />
                            </Grid>
                        )}
                        {isModalUpdateFeeTier && (
                            <ModalFormConfirmation
                                id="update-fee-tier"
                                isModalOpen={isModalUpdateFeeTier}
                                setIsModalOpen={setIsModalUpdateFeeTier}
                                confirmationAction={() => {
                                    handleOnChange(updateFeeTierValue);
                                    setIsModalUpdateFeeTier(false);
                                }}
                                buttonText={'Update'}
                                modalText={`Are you sure you want to change Fee Tier to "Tier ${updateFeeTierValue}"`}
                            />
                        )}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        {globalState.reseller?.name === ResellerType.DATMAN && isSuperAdmin && (
                            <>
                                <Grid item xs={12}>
                                    <AddCreditFunc updateFeeTier={updateFeeTier} />
                                </Grid>

                                <Grid item xs={12} className={styles.thirdId}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => SetBalanceReportModal(true)}
                                    >
                                        get report
                                    </Button>
                                </Grid>
                                {balanceReportModal && (
                                    <ModalFormConfirmation
                                        id="get-balance-report"
                                        isModalOpen={balanceReportModal}
                                        setIsModalOpen={SetBalanceReportModal}
                                        confirmationAction={SubmitReport}
                                        setSelectedDate={setSelectedDate}
                                        buttonText={'Submit'}
                                        modalText={BalanceReportDatePicker()}
                                        isLoading={isLoading}
                                        isDisabled={moment(selectedDate).isAfter(moment(new Date()))}
                                        cancelAction={cancelAction}
                                    />
                                )}
                            </>
                        )}

                        <Grid item xs={12} sm={12} className={styles.buttonContainer}>
                            <Grid item xs={12} sm={12}>
                                {/* <ViewDocuments
                                    setMerchantVerificationAcceptRejectMessage={
                                        setMerchantVerificationAcceptRejectMessage
                                    }
                                    setMessageType={setMessageType}
                                    setShowMessage={setShowMessage}
                                    setOrangeMessageStyle={setOrangeMessageStyle}
                                /> */}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setRoute(`/document-management/${globalState.merchant?.id}`)}
                                >
                                    Document management
                                </Button>
                            </Grid>
                            {/* <Grid item xs={12} sm={12} className={styles.thirdId}>
                                <DownloadDocuments />
                            </Grid> */}
                            {isSuperAdmin && (
                                <Grid item xs={12} sm={12} className={styles.thirdId}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            window.open(supportUtilitiesLinksMap.updateUserAttributes, '_blank');
                                        }}
                                    >
                                        Update user email/phone
                                    </Button>
                                </Grid>
                            )}

                            <Grid>
                                <Button
                                    id="adminAddUrgentMessageButton"
                                    className={styles.addUrgentMessageButton}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setShowDialogCreateUrgentMessage(true)}
                                >
                                    Add urgent message
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} className={styles.pendingMerchantsButton}>
                                <Button
                                    id="adminPendingMerchantsButton"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setRoute('/admin-pending-merchants')}
                                >
                                    Pending merchants
                                </Button>
                            </Grid>
                            {globalState.reseller?.name === ResellerType.DATMAN && (
                                <Grid item xs={12} sm={6} className={styles.thirdId}>
                                    <Button
                                        id="payoutNotReceived"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setRoute('/payouts-not-received')}
                                    >
                                        Payout Not Received
                                    </Button>
                                </Grid>
                            )}

                            <CheckMerchantCountries excludedCountries={[MerchantCountries.UNITED_KINGDOM]}>
                                <Grid item xs={12} sm={12} className={styles.pendingMerchantsButton}>
                                    <Button
                                        id="acceptTermsAndCondition"
                                        variant="contained"
                                        color="primary"
                                        onClick={acceptTermsAndConditions}
                                        disabled={!adminFunctions.stripeId}
                                    >
                                        Accept Stripe T&C
                                    </Button>
                                </Grid>
                            </CheckMerchantCountries>

                            {globalState.reseller?.name === ResellerType.OMNIPAY && (
                                <Grid item xs={12} sm={12} className={styles.thirdId} alignItems="flex-end">
                                    <Button
                                        className={styles.newMerchant}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setRoute('/client-register')}
                                    >
                                        + New Merchant
                                    </Button>
                                </Grid>
                            )}

                            <Grid item xs={12} sm={12} className={styles.thirdId} alignItems="flex-end">
                                <Button
                                    className={styles.newMerchant}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setRoute('/canonical-reseller-onboarding')}
                                >
                                    + Create reseller
                                </Button>
                            </Grid>
                        </Grid>
                        {isSuperAdmin && globalState.reseller?.name === ResellerType.DATMAN && (
                            <Grid item xs={12} sm={12}>
                                <Grid className={styles.thirdId}>
                                    <Button
                                        id="adminAddUrgentMessageButton"
                                        className={styles.newMerchant}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setShowCreateFeeTier(true)}
                                    >
                                        + Create Fee Tier
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                        <Grid className={styles.thirdId}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    window.open(supportUtilitiesLinksMap.addResellerTermsAndConditions, '_blank');
                                }}
                            >
                                Terms and conditions
                            </Button>
                        </Grid>

                        {globalState.merchant?.isAdyenMerchant && globalState.reseller?.name === ResellerType.DATMAN && (
                            <Grid item xs={12} sm={12} className={styles.thirdId} alignItems="flex-end">
                                <Button
                                    className={styles.newMerchant}
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                        window.open(getRoute(`/adyen-response/${globalState?.merchant.id}`), '_blank')
                                    }
                                >
                                    Adyen Response
                                </Button>
                            </Grid>
                        )}
                        {isSuperAdmin && (
                            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
                                <Grid>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => window.open(getRoute(`/auditlogs`), '_blank')}
                                    >
                                        Audit Logs
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                        {globalState.reseller?.name === ResellerType.DATMAN && (
                            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
                                <Grid>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => window.open(getRoute(`/adyen-blocked-merchants-list`), '_blank')}
                                    >
                                        Adyen Blocked Merchant List
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                        {isAdminOrSuperAdmin && globalState.reseller?.name === ResellerType.DATMAN && (
                            <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_KINGDOM]}>
                                <Grid item xs={12} sm={12} className={styles.buttonContainer}>
                                    <Grid>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={sendOtp}
                                            disabled={isMerchantClosed}
                                        >
                                            Verify Merchant
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CheckMerchantCountries>
                        )}
                        {isAdminOrSuperAdmin && globalState.reseller?.name === ResellerType.DATMAN && (
                            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
                                <Grid>
                                    <Button variant="contained" color="primary" onClick={() => setShowModal(true)}>
                                        Move Transactions
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                        {isAdminOrSuperAdmin && (
                            <Grid item xs={12} sm={12} className={styles.buttonContainer}>
                                <Grid>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setShowSignupModal(true)}
                                    >
                                        Reactivate Signup Link
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                        {showSignupModal && (
                            <ModalFormConfirmation
                                id="signup-link-modal"
                                isModalOpen={showSignupModal}
                                setIsModalOpen={setShowSignupModal}
                                confirmationAction={() => {
                                    handleRenewSignupLink();
                                    setShowSignupModal(false);
                                }}
                                buttonText={'Reactivate'}
                                modalText={`Are you sure you want to reactivate this merchant's signup link?`}
                            />
                        )}
                        {/* </Grid> */}
                        {showCreateFeeTier && (
                            <ModalCreateFeeTier
                                setShowCreateFeeTier={setShowCreateFeeTier}
                                showCreateFeeTier={showCreateFeeTier}
                                pricingTiers={pricingTiers}
                                setPricingTiers={setPricingTiers}
                                showFeeTierMessage={showFeeTierMessage}
                                setShowFeeTierMessage={setShowFeeTierMessage}
                                message={message}
                                setMessage={setMessage}
                                setMessageType={setMessageType}
                            />
                        )}
                    </Grid>

                    <Grid item xs={12} sm={6} hidden={true}>
                        <AddMerchantId indexId={0} />
                        <AddMerchantId indexId={1} />
                    </Grid>
                    <Grid item xs={12} sm={6} hidden={true}>
                        <MerchantStoreId indexId={0} />
                        <MerchantStoreId indexId={1} />
                    </Grid>

                    <Grid item xs={12} className={showDialogCreateUrgentMessage && styles.borderAddUrgentMessage}>
                        {showDialogCreateUrgentMessage && (
                            <CreateUrgentMessage
                                setShow={setShowDialogCreateUrgentMessage}
                                setSuccess={setSuccessCreateUrgentMessage}
                            />
                        )}

                        {successCreateUrgentMessage && (
                            <SnackBarAndAlert
                                open={successCreateUrgentMessage}
                                onClose={() => setSuccessCreateUrgentMessage(false)}
                                type="success"
                            >
                                Urgent message was added successfully.
                            </SnackBarAndAlert>
                        )}

                        {clientRegistered && (
                            <SnackBarAndAlert
                                open={clientRegistered}
                                onClose={() => setClientRegistered(false)}
                                type="success"
                            >
                                Merchant registered successfully
                            </SnackBarAndAlert>
                        )}

                        {resellerCreation && (
                            <SnackBarAndAlert
                                open={resellerCreation}
                                onClose={() => setResellerCreation(false)}
                                type="success"
                            >
                                Reseller created successfully
                            </SnackBarAndAlert>
                        )}

                        {showMessage && (
                            <SnackBarAndAlert
                                open={showMessage}
                                onClose={() => {
                                    setOrangeMessageStyle(false);
                                    setShowMessage(false);
                                }}
                                type={messageType}
                                orangeColor={orangeMessageStyle}
                            >
                                {merchantVerificationAcceptRejectMessage}
                            </SnackBarAndAlert>
                        )}
                        {showAcceptTermsAndConditionsMessage && (
                            <SnackBarAndAlert
                                open={showAcceptTermsAndConditionsMessage}
                                onClose={() => {
                                    setShowAcceptTermsAndConditionsMessage(false);
                                    setOrangeMessageStyle(false);
                                }}
                                type={messageType}
                                orangeColor={orangeMessageStyle}
                            >
                                {acceptTermsAndConditionsMessage}
                            </SnackBarAndAlert>
                        )}
                        {(showFeeTierMessage || showMerchantVerificationMessage) && (
                            <SnackBarAndAlert
                                open={showFeeTierMessage || showMerchantVerificationMessage}
                                onClose={() => {
                                    setShowFeeTierMessage(false);
                                    setShowMerchantVerificationMessage(false);
                                }}
                                type={messageType}
                            >
                                {message}
                            </SnackBarAndAlert>
                        )}
                        {showUpdateFeeTierMessage && (
                            <SnackBarAndAlert
                                open={showUpdateFeeTierMessage}
                                onClose={() => {
                                    setShowUpdateFeeTierMessage(false);
                                }}
                                type={messageType}
                            >
                                {updateFeeTierMessage}
                            </SnackBarAndAlert>
                        )}
                        {BalanceReportMessage && (
                            <SnackBarAndAlert
                                open={BalanceReportMessage}
                                onClose={() => {
                                    SetBalanceReportMesssage(false);
                                    setMessage('');
                                }}
                                type={messageType}
                            >
                                {message}
                            </SnackBarAndAlert>
                        )}
                    </Grid>

                    {globalState.reseller?.name === ResellerType.DATMAN && (
                        <Grid item xs={12} sm={12} container className={styles.adyenGrid}>
                            <CheckMerchantCountries
                                includedCountries={[
                                    MerchantCountries.UNITED_KINGDOM,
                                    MerchantCountries.UNITED_STATES,
                                    MerchantCountries.AUSTRALIA
                                ]}
                            >
                                <ManagePartners />
                            </CheckMerchantCountries>
                        </Grid>
                    )}
                    {openModalOtpVerification && (
                        <ModalOTPVerification
                            openModalOtpVerification={openModalOtpVerification}
                            setOpenModalOtpVerification={setOpenModalOtpVerification}
                            phoneNumber={phoneNumber}
                            setShowMerchantVerificationMessage={setShowMerchantVerificationMessage}
                            setMessageType={setMessageType}
                            setMessage={setMessage}
                        />
                    )}
                    {showModal && (
                        <ModalMoveTxns
                            openModal={showModal}
                            setOpenModal={setShowModal}
                            snackbar={snackbarDetails}
                            setSnackbar={setSnackbarDetails}
                        />
                    )}
                    {/* {globalState.reseller?.name === ResellerType.DATMAN && (
                        <Grid item xs={12}>
                            <PayoutsNotReceived {...props} />
                        </Grid>
                    )} */}
                    <Grid item xs={12}>
                        <AdminTriggerResetPassword {...props} />
                    </Grid>
                    <SnackBarAndAlert
                        open={!!otpError}
                        type="error"
                        onClose={() => {
                            setOtpError('');
                        }}
                    >
                        {otpError}
                    </SnackBarAndAlert>
                    {snackbarDetails.message && (
                        <SnackBarAndAlert
                            open={snackbarDetails.message}
                            type={snackbarDetails.type}
                            onClose={() => setSnackbarDetails({ type: '', message: '' })}
                        >
                            {snackbarDetails.message}
                        </SnackBarAndAlert>
                    )}
                </Grid>
            )}
            <LoadingScreen open={isLoading} />
        </div>
    );
}

AdminFunctionsComponent.propTypes = {
    isLoading: PropTypes.bool,
    error: PropTypes.bool,
    setError: PropTypes.func,
    message: PropTypes.bool,
    clientRegistered: PropTypes.bool
};
