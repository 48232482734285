import React from 'react';

import NextButtonComponent from './next-btn.component';

/**
 *
 * @param {{
 * onSubmit: (event) => {},
 * disabled: boolean
 * }} props
 */
function NextButton(props) {
    return <NextButtonComponent {...props} />;
}

NextButton.propTypes = {};

export default NextButton;
