import { AuthAxios, BaseAxios } from './interceptor.service';
import { AuthServiceEndpoint, Region, UserPollWebClientId, UserPollId } from 'utils/constants';
import { ErrorResponse, SuccesResponse } from 'models';
import { DatmanAPIEndpoint } from 'utils/constants';
import { DatmanAxios } from './interceptor.service.js';

async function socialSignIn(token) {
    try {
        const response = await AuthAxios.post(
            `${AuthServiceEndpoint}/socialSignIn`,
            {},
            {
                headers: {
                    Authorization: JSON.stringify(token)
                }
            }
        );
        return new SuccesResponse(response.data);
    } catch (error) {
        const message = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(message);
    }
}

async function login(loginRequest) {
    try {
        const response = await AuthAxios.post(`${AuthServiceEndpoint}/login`, loginRequest);
        return new SuccesResponse(response.data);
    } catch (error) {
        const message = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(message);
    }
}

function isLoggedUser() {
    if (localStorage.getItem('user')) {
        return true;
    }
    return false;
}

function logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    sessionStorage.removeItem('merchant');
    sessionStorage.removeItem('balance');
    sessionStorage.removeItem('canonicalResellers');
    localStorage.removeItem('skip_clicked');
    sessionStorage.removeItem('showCanonicalResellersList');
    localStorage.removeItem('userHasSeenSnackbar');

    for (let key in localStorage) {
        if (key.startsWith('MyPay_')) {
            localStorage.removeItem(key);
        }
    }
}

async function signUp(signUpRequest) {
    try {
        const response = await AuthAxios.post(`${AuthServiceEndpoint}/signup`, signUpRequest);
        return new SuccesResponse(response.data);
    } catch (error) {
        const err = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(err);
    }
}

const completeInvite = async (request) => {
    try {
        await BaseAxios.post(`${AuthServiceEndpoint}/completeInvite`, request);
        return new SuccesResponse();
    } catch (error) {
        const err = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(err);
    }
};

async function changePassword(request) {
    try {
        const response = await AuthAxios.post(`${AuthServiceEndpoint}/changePassword`, request);
        return new SuccesResponse(response.data);
    } catch (error) {
        const err = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(err);
    }
}

async function passwordRotation(request) {
    try {
        const response = await AuthAxios.post(`${AuthServiceEndpoint}/password-rotation`, request);
        return new SuccesResponse(response.data);
    } catch (error) {
        const err = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(err);
    }
}

const requestNewToken = async () => {
    try {
        const refreshResponse = await AuthAxios.get(`${AuthServiceEndpoint}/get-refresh-token`);
        const index = refreshResponse.data.refreshToken.indexOf('refreshToken:') + 'refreshToken:'.length;
        const refreshToken = refreshResponse.data.refreshToken.substring(index);

        await callCognitoForNewToken(refreshToken);

        return new SuccesResponse();
    } catch (error) {
        const err = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(err);
    }
};

const requestNewTokenWithRefreshToken = async (refreshToken) => {
    try {
        await callCognitoForNewToken(refreshToken);

        return new SuccesResponse();
    } catch (error) {
        const err = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(err);
    }
};

const newSocialAccount = async (request) => {
    try {
        const result = await BaseAxios.post(`${AuthServiceEndpoint}/new-social-account`, request);
        return new SuccesResponse(result.data);
    } catch (error) {
        const err = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(err);
    }
};

const createNewAuthOtp = async (request) => {
    try {
        const rs = await BaseAxios.post(`${AuthServiceEndpoint}/create-new-auth-otp`, request);
        return new SuccesResponse(rs.data, rs.status);
    } catch (error) {
        const err = error && error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(err);
    }
};

const resetDatmanPassword = async (via, id, password) => {
    var axios = require('axios');
    var data = `{"${via}":"${id}","password":"${password}"}`;

    var config = {
        method: 'post',
        url: `${DatmanAPIEndpoint}/portal/update-password`,
        headers: {
            'Content-Type': 'text/plain'
        },
        data: data
    };
    try {
        await axios(config);
    } catch (e) {
        return new ErrorResponse('Error', e);
    }
};

const updateDatmanPassword = async (current_password, password) => {
    try {
        const result = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/customer/password`, {
            current_password,
            password,
            password_confirm: password
        });
        return new SuccesResponse(result.data.data);
    } catch (err) {
        return new ErrorResponse('Error', err);
    }
};

const callCognitoForNewToken = (refreshToken) => {
    return new Promise((resolve, reject) => {
        let response;
        fetch(`https://cognito-idp.${Region}.amazonaws.com/${UserPollId}`, {
            headers: {
                'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
                'Content-Type': 'application/x-amz-json-1.1'
            },
            mode: 'cors',
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify({
                ClientId: UserPollWebClientId,
                AuthFlow: 'REFRESH_TOKEN_AUTH',
                AuthParameters: {
                    REFRESH_TOKEN: refreshToken
                    //SECRET_HASH: "your_secret", // In case you have configured client secret
                }
            })
        })
            .then(
                (resp) => {
                    response = resp;
                    return resp;
                },
                (err) => {
                    // If error happens here, the request failed
                    // if it is TypeError throw network error
                    if (err instanceof TypeError) {
                        throw new Error('Network error');
                    }
                    throw err;
                }
            )
            .then((resp) => resp.json().catch(() => ({})))
            .then((data) => {
                // return parsed body stream
                if (response.ok) {
                    for (let key in localStorage) {
                        if (key.startsWith('MyPay_') && key.endsWith('idToken')) {
                            localStorage.setItem(key, data.AuthenticationResult.IdToken);
                        } else if (key.startsWith('MyPay_') && key.endsWith('accessToken')) {
                            localStorage.setItem(key, data.AuthenticationResult.AccessToken);
                        }
                    }
                    resolve(data);
                }

                // Taken from aws-sdk-js/lib/protocol/json.js
                // eslint-disable-next-line no-underscore-dangle
                const code = (data.__type || data.code).split('#').pop();
                const error = {
                    code,
                    name: code,
                    message: data.message || data.Message || null
                };
                throw error;
            })
            .catch((err) => {
                // first check if we have a service error
                if (response && response.headers && response.headers.get('x-amzn-errortype')) {
                    try {
                        const code = response.headers.get('x-amzn-errortype').split(':')[0];
                        const error = {
                            code,
                            name: code,
                            statusCode: response.status,
                            message: response.status ? response.status.toString() : null
                        };
                        reject(error);
                    } catch (ex) {
                        reject(err);
                    }
                    // otherwise check if error is Network error
                } else if (err instanceof Error && err.message === 'Network error') {
                    const error = {
                        code: 'NetworkError',
                        name: err.name,
                        message: err.message
                    };
                    reject(error);
                } else {
                    reject(err);
                }
            });
    });
};

async function removeCognitoUser(sub) {
    try {
        await AuthAxios.delete(`${AuthServiceEndpoint}/remove-cognito-user/${sub}`);
        return new SuccesResponse();
    } catch (error) {
        const err = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(err);
    }
}

async function sendVerificationEmail(data) {
    try {
        await AuthAxios.post(`${AuthServiceEndpoint}/send-verification-email`, data);
        return new SuccesResponse();
    } catch (error) {
        const err = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(err);
    }
}

async function updateChangePasswordTime(data) {
    try {
        const response = await AuthAxios.post(`${AuthServiceEndpoint}/update-user-table`, data);
        return new SuccesResponse(response.data);
    } catch (error) {
        const err = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(err);
    }
}
async function generateCSRFToken(data, merchantId) {
    try {
        const response = await AuthAxios.get(`${AuthServiceEndpoint}/generate-csrf/${data}/${merchantId}`);
        return new SuccesResponse(response.data);
    } catch (error) {
        const err = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(err);
    }
}

async function removeCSRFToken(data) {
    try {
        const response = await AuthAxios.delete(`${AuthServiceEndpoint}/remove-csrf/${data}`);
        return new SuccesResponse(response.data);
    } catch (error) {
        const err = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(err);
    }
}

async function getLinkStatus(uuid) {
    try {
        const response = await AuthAxios.get(`${AuthServiceEndpoint}/get-restricted-link-status/${uuid}`);
        return new SuccesResponse(response.data);
    } catch (error) {
        const err = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(err);
    }
}

async function updateOtpLinkStatus(body) {
    try {
        const response = await AuthAxios.post(`${AuthServiceEndpoint}/update-restricted-link-data`, body);
        return new SuccesResponse(response.data);
    } catch (error) {
        const err = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(err);
    }
}

export default {
    socialSignIn,
    login,
    isLoggedUser,
    logout,
    signUp,
    changePassword,
    passwordRotation,
    completeInvite,
    requestNewToken,
    newSocialAccount,
    resetDatmanPassword,
    updateDatmanPassword,
    requestNewTokenWithRefreshToken,
    removeCognitoUser,
    createNewAuthOtp,
    sendVerificationEmail,
    updateChangePasswordTime,
    generateCSRFToken,
    removeCSRFToken,
    getLinkStatus,
    updateOtpLinkStatus
};
