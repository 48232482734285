import React from 'react';

import LabelComponent from './label.component';

/**
 *
 * @param {{
 * text: string;
 * id: string
 * }} props
 */
function Label(props) {
    return <LabelComponent {...props}></LabelComponent>;
}

Label.propspropTypes = {};

export default Label;
