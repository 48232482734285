import React from 'react';
import style from './print-agreement.module.scss';

import { TableContainer, Table, Paper, TableRow, TableCell, Tooltip } from '@material-ui/core';
import { Col, Row } from 'react-bootstrap';
import { LoadingScreen, ExportPDF } from 'components';
import { ResellerLogoMap } from 'utils/enums/ResellerLogoMap';
import { useGlobalStateContext } from 'contexts';
import { getCurrencySymbol } from 'utils/getCurrencyByCountry';
const PrintAgreementComponent = (props) => {
    const {
        merchant,
        contractDetails,
        isLoading,
        merchantTradingAddress,
        merchantMobile,
        companyAddress,
        ownerName
    } = props;
    const { globalState } = useGlobalStateContext();
    const addressLine1 = merchantTradingAddress?.addressLine1 ? merchantTradingAddress?.addressLine1 : '__';
    const addressLine2 = merchantTradingAddress?.addressLine2 ? merchantTradingAddress?.addressLine2 : '__';
    const city = merchantTradingAddress?.city ? merchantTradingAddress?.city : '__';
    const postCode = merchantTradingAddress?.postCode ? merchantTradingAddress?.postCode : '__';
    let merchantName = merchant.name.length > 40 ? merchant.name.slice(0, 40) + '...' : merchant.name;
    let addressLine1Header = addressLine1?.length > 40 ? addressLine1?.slice(0, 40) + '...' : addressLine1;
    let addressLine2Header = addressLine2?.length > 40 ? addressLine2?.slice(0, 40) + '...' : addressLine2;
    let cityHeader = city?.length > 40 ? city?.slice(0, 40) : city;

    return (
        <React.Fragment>
            <Row className={style.topbar}>
                <Col md={8} xs={7}>
                    <p className={style.tableheader}>Engagement of services</p>
                    <div className={style.exportPdfButton}>
                        <ExportPDF
                            merchant={globalState?.merchant}
                            logo={ResellerLogoMap[globalState.reseller?.id]}
                            pdfTitle={'Engagement of services'}
                            data={contractDetails}
                            merchantMobile={merchantMobile}
                            address={merchantTradingAddress}
                            companyAddress={companyAddress}
                            ownerName={ownerName}
                        />
                    </div>
                </Col>
                <Col className={style.topright} md={4} xs={5}>
                    <p className={style.address}>
                        <Tooltip title={merchant.name} arrow placement="bottom-start">
                            <span>{merchantName}</span>
                        </Tooltip>
                    </p>
                    <p className={style.address}>
                        <Tooltip title={addressLine1} arrow placement="bottom-start">
                            <span>{addressLine1Header}</span>
                        </Tooltip>
                    </p>
                    <p className={style.address}>
                        <Tooltip title={addressLine2} arrow placement="bottom-start">
                            <span>{addressLine2Header}</span>
                        </Tooltip>
                    </p>
                    <p className={style.address}>
                        <Tooltip title={city} arrow placement="bottom-start">
                            <span>{cityHeader}</span>
                        </Tooltip>
                    </p>
                    <p className={style.address}>{postCode}</p>
                </Col>
            </Row>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer className={style.paper}>
                    <Table stickyHeader>
                        <TableRow>
                            <TableCell variant="head" className={style.row}>
                                Name
                            </TableCell>
                            <TableCell>{merchant.name ? merchant.name : '__'}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Address</TableCell>
                            <TableCell>{addressLine1.concat(' ', addressLine2)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">City</TableCell>
                            <TableCell>{city}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Postcode</TableCell>
                            <TableCell>{postCode}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Mobile</TableCell>
                            <TableCell>{merchantMobile}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Rent</TableCell>
                            <TableCell>
                                {`${getCurrencySymbol(globalState.merchant.country)}
                                ${contractDetails.contract_rent ? contractDetails.contract_rent : '__'}`}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Contract length</TableCell>
                            <TableCell>
                                {contractDetails.contract_length_print_agreement
                                    ? contractDetails.contract_length_print_agreement
                                    : '__'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Notice period</TableCell>
                            <TableCell>
                                {contractDetails.notice_period ? contractDetails.notice_period : '__'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Extra comments</TableCell>
                            <TableCell>
                                {contractDetails.extra_comments ? contractDetails.extra_comments : '__'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Description of service provided</TableCell>
                            <TableCell>{contractDetails.services_description}</TableCell>
                        </TableRow>
                    </Table>
                </TableContainer>
                <LoadingScreen open={isLoading} />
            </Paper>
        </React.Fragment>
    );
};

export default PrintAgreementComponent;
