import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, TextField, OutlinedInput } from '@material-ui/core';
import { LoadingScreen } from 'components';
import styles from './modal-move-transaction.module.scss';
import NumberFormat from 'react-number-format';
import { Label } from 'components/onboarding/onboarding-ui-components';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers';

const ModalMoveTransaction = (props) => {
    const {
        loading,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        handleClose,
        disable,
        onConfirm,
        openModal,
        newMid,
        setNewMid
    } = props;

    return (
        <React.Fragment>
            <Dialog
                open={openModal}
                className={styles.dialog}
                maxWidth="lg"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        width: '80%',
                        maxWidth: '600px',
                        minWidth: 'fit-content',
                        margin: 'auto',
                        padding: '20px'
                    }
                }}
            >
                <LoadingScreen open={loading} />

                <DialogTitle id="draggable-dialog-title" stickyHeader className={styles.dialogHeader}>
                    Move transactions
                </DialogTitle>
                <DialogContent dividers>
                    <div className={styles.datePicker}>
                        <div>
                            <Label text="To MID" id="merchant-id"></Label>
                            <NumberFormat
                                customInput={OutlinedInput}
                                className={styles.midInput}
                                inputProps={{
                                    inputMode: 'numeric',
                                    maxLength: 12,
                                    className: styles.codeInput__input
                                }}
                                pattern="\d*"
                                allowLeadingZeros={true}
                                decimalSeparator={false}
                                allowNegative={false}
                                isNumericString={true}
                                value={newMid}
                                onChange={(e) => {
                                    setNewMid(e.target.value);
                                }}
                                labelWidth={0}
                                required
                            />
                        </div>
                    </div>
                    <div className={styles.datePicker}>
                        <div>
                            <p className={styles.paraText}>From</p>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDateTimePicker
                                    className={styles.expiryDateInput}
                                    value={fromDate}
                                    onChange={(newValue) => {
                                        if (newValue) setFromDate(newValue['$d']);
                                    }}
                                    onError={console.log}
                                    inputFormat="DD/MM/YYYY HH:mm"
                                    renderInput={(params) => (
                                        <TextField
                                            className={styles.dialogContentDatePicker}
                                            variant="outlined"
                                            size="small"
                                            {...params}
                                            placeholder={'DD/MM/YYYY HH:mm'}
                                        />
                                    )}
                                    minute={false}
                                    showToolbar={false}
                                    ampm={false}
                                />
                            </LocalizationProvider>
                        </div>
                        <div>
                            <p className={styles.paraText}>To</p>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDateTimePicker
                                    className={styles.expiryDateInput}
                                    value={toDate}
                                    onChange={(newValue) => {
                                        if (newValue) setToDate(newValue['$d']);
                                    }}
                                    onError={console.log}
                                    inputFormat="DD/MM/YYYY HH:mm"
                                    renderInput={(params) => (
                                        <TextField
                                            className={styles.dialogContentDatePicker}
                                            variant="outlined"
                                            size="small"
                                            {...params}
                                            placeholder={'DD/MM/YYYY HH:mm'}
                                        />
                                    )}
                                    minute={false}
                                    showToolbar={false}
                                    ampm={false}
                                />
                            </LocalizationProvider>

                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDatePicker
                                    inputFormat="D MMM YYYY HH"
                                    value={toDate}
                                    onChange={(newValue) => {
                                        if (newValue) setToDate(newValue['$d']);
                                    }}
                                    maxDate={new Date()}
                                    minDate={fromDate}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={moment(new Date()).format('D MMM YYYY')}
                                            format="D MM YY"
                                            className={styles.dialogContentDatePicker}
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                            </LocalizationProvider> */}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className={styles.dialogActions}>
                    <Button variant="outlined" onClick={handleClose} color="primary" className={styles.dialogbtn}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            onConfirm();
                        }}
                        color="primary"
                        disabled={!disable}
                        className={styles.dialogbtn}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};
ModalMoveTransaction.propTypes = {
    loading: PropTypes.bool,
    timeFrame: PropTypes.string,
    handleTimeFrameChange: PropTypes.func,
    setFromDate: PropTypes.func,
    setToDate: PropTypes.func,
    handleClose: PropTypes.func,
    disable: PropTypes.bool,
    onSend: PropTypes.func,
    showCharges: PropTypes.bool,
    setShowCharges: PropTypes.func,
    sendReportModal: PropTypes.bool
};
export default ModalMoveTransaction;
