import { DatmanAxios } from './interceptor.service.js';
import { SuccesResponse, ErrorResponse } from 'models';
import { DatmanAPIEndpoint } from 'utils/constants';

async function InitialiseSale(initSaleRequest) {
    try {
        const requestResult = await DatmanAxios.post(
            `${DatmanAPIEndpoint}/portal/vt/sale/create`,
            JSON.stringify(initSaleRequest)
        );
        const { data, status } = requestResult;

        return new SuccesResponse(data, status);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function InitialiseDNASale(params) {
    try {
        const response = await DatmanAxios.get(`${DatmanAPIEndpoint}/portal/dna-hosted-form`, {
            params
        });
        const { data, status } = response;
        const { uuid } = data.data;
        let URL = `${DatmanAPIEndpoint}/portal/dna-redirect/${uuid}`;
        return new SuccesResponse({ url: URL, uuid }, status);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

export default {
    InitialiseSale,
    InitialiseDNASale
};
