import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import styles from './table-footer.module.scss';
import { useGlobalStateContext } from 'contexts';
import { formatCurrency } from '../../../../utils/helper';
import { getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry.js';
import { PaymentsFilterTabs } from '../../../../utils/enums/PaymentsFilterTabs';
import { PdqFilterTabs } from 'utils/enums/PdqFilterTabs';

const TableFooterComponent = (props) => {
    const { path, resultsCount, total, refund, filter } = props;
    const { globalState } = useGlobalStateContext();
    return (
        <React.Fragment>
            <Box className={styles.divTableFooter}>
                <Box>
                    <span className={styles.columnSpan}>Transactions: </span>
                    <span className={styles.amountSpan}>{resultsCount}</span>
                    <Grid className={styles.main}>
                        {(path === 'payments' || path === 'pdq' || path === 'gfo-payments') &&
                            (filter === PaymentsFilterTabs.Successful || filter === PdqFilterTabs.Successful ? (
                                <div>
                                    <span className={styles.columnSpan}>Total Transactions Value: </span>
                                    <span className={styles.amountSpan}>
                                        {formatCurrency(total, getCurrencyThreeAlpha(globalState.merchant?.country))}
                                    </span>
                                </div>
                            ) : filter === PaymentsFilterTabs.Refunded || filter === PdqFilterTabs.Refunded ? (
                                <div>
                                    <span className={styles.columnSpan}>Total Refunds Value: </span>
                                    <span className={styles.amountSpan}>
                                        {formatCurrency(refund, getCurrencyThreeAlpha(globalState.merchant?.country))}
                                    </span>
                                </div>
                            ) : (
                                <div>
                                    <div>
                                        <span className={styles.columnSpan}>Total Transactions Value: </span>
                                        <span className={styles.amountSpan}>
                                            {formatCurrency(
                                                total,
                                                getCurrencyThreeAlpha(globalState.merchant?.country)
                                            )}
                                        </span>
                                    </div>
                                    {(path === 'payments' || path === 'pdq' || path === 'gfo-payments') && (
                                        <div>
                                            <span className={styles.columnSpan}>Total Refunds Value: </span>
                                            <span className={styles.amountSpan}>
                                                {formatCurrency(
                                                    refund,
                                                    getCurrencyThreeAlpha(globalState.merchant?.country)
                                                )}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            ))}
                    </Grid>
                </Box>
            </Box>
        </React.Fragment>
    );
};

export default TableFooterComponent;

TableFooterComponent.propTypes = {
    resultsCount: PropTypes.number.isRequired
};
