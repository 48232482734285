import React from 'react';
import { LoginForm } from 'components';
import styles from './login.module.scss';
import { useGlobalStateContext } from 'contexts';

function LoginComponent(props) {
    const { globalState } = useGlobalStateContext();

    return (
        <div>
            <div className={styles.homeLogo}>
                <img src={globalState.reseller?.logo} alt="Logo"></img>
            </div>
            <LoginForm {...props} />
        </div>
    );
}
export default LoginComponent;
