import React, { useState, useEffect } from 'react';
import AuditDataComponent from './adyen-merchant-list.component';
import AdyenService from 'services/adyen.service';
import PropTypes from 'prop-types';
import _ from 'lodash';

const AdyenMerchantList = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [adyenMerchantList, setAdyenMerchantList] = useState([]);
    const [filteredAdyenMerchantList, setFilteredAdyenMerchantList] = useState([]);
    const [adyenAccountStatus, setAdyenAccountStatus] = useState('ALL');
    const [adyenCategory, setAdyenCategory] = useState('CRITICAL');
    const [count, setCount] = useState(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('createdDate');
    const [inputValue, setInputValue] = useState('');
    const [open, setOpen] = useState('');
    const [isCopyAlertOpen, setIsCopyAlertOpen] = useState(false);

    const headCells = [
        { id: 'mid', numeric: true, disablePadding: false, label: 'Merchant ID' },
        { id: 'account_status', numeric: false, disablePadding: false, label: 'Account status' },
        // { id: 'payout_status', numeric: false, disablePadding: false, label: 'Payout status', hideSortingIcon: true },
        { id: 'tier', numeric: false, disablePadding: false, label: 'Tier', hideSortingIcon: true },
        {
            id: 'totalPayout',
            numeric: false,
            disablePadding: false,
            label: 'Total payout processed'
        }
    ];
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onSearchMerchants = (e) => {
        setPage(0);
        filteredTheAdyenMerchants(adyenAccountStatus, adyenCategory);
        e.preventDefault();
    };

    useEffect(() => {
        getAdyenMerchantList(0);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        filteredTheAdyenMerchants(adyenAccountStatus, 'CRITICAL');
        // eslint-disable-next-line
    }, [adyenMerchantList]);

    const filteredTheAdyenMerchants = (adyenAccountStatus = 'ALL', adyenCategory = 'ALL') => {
        setPage(0);
        let filteredList = [...adyenMerchantList];
        if (adyenCategory === 'CRITICAL') {
            filteredList = adyenMerchantList.filter((merchant) => merchant.totalPayout >= 5000);
        } else if (adyenCategory === 'HIGH') {
            filteredList = adyenMerchantList.filter(
                (merchant) => merchant.totalPayout >= 1000 && merchant.totalPayout < 5000
            );
        } else if (adyenCategory === 'LOW') {
            filteredList = adyenMerchantList.filter((merchant) => merchant.totalPayout < 1000);
        }

        filteredList = filteredList.filter((merchant) =>
            adyenAccountStatus === 'ALL' ? true : merchant.accountStatus === adyenAccountStatus
        );
        if (inputValue.length > 2) {
            filteredList = filteredList.filter((merchant) => merchant.merchantId.toString() === inputValue);
        }
        filteredList = _.orderBy(filteredList, ['totalPayout'], ['desc']);
        setFilteredAdyenMerchantList(filteredList);
        setCount(filteredList.length);
    };

    const getAdyenMerchantList = async () => {
        setLoading(true);
        const response = await AdyenService.getAdyenCriticalMerchantsList();

        if (response.isSuccesfully) {
            setOpen(false);
            setAdyenMerchantList(response.data.data);
            setFilteredAdyenMerchantList(response.data.data);
        } else {
            setError(true);
        }
        setLoading(false);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setOpen(false);
    };

    const handleCopyMerchantId = (merchantId) => {
        navigator.clipboard.writeText(merchantId);
        setIsCopyAlertOpen(true);
    };

    const closeCopyAlert = () => {
        setIsCopyAlertOpen(false);
    };

    return (
        <AuditDataComponent
            {...props}
            rows={filteredAdyenMerchantList}
            error={error}
            loading={loading}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={count}
            order={order}
            orderBy={orderBy}
            handleRequestSort={handleRequestSort}
            setInputValue={setInputValue}
            onSearchMerchants={onSearchMerchants}
            inputValue={inputValue}
            open={open}
            setOpen={setOpen}
            headCells={headCells}
            adyenAccountStatus={adyenAccountStatus}
            setAdyenAccountStatus={setAdyenAccountStatus}
            adyenCategory={adyenCategory}
            setAdyenCategory={setAdyenCategory}
            getAdyenMerchantList={getAdyenMerchantList}
            handleCopyMerchantId={handleCopyMerchantId}
            closeCopyAlert={closeCopyAlert}
            isCopyAlertOpen={isCopyAlertOpen}
            filteredTheAdyenMerchants={filteredTheAdyenMerchants}
        />
    );
};

AdyenMerchantList.propTypes = {
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.bool,
    headCells: PropTypes.array,
    setLoading: PropTypes.func,
    rowsPerPage: PropTypes.number,
    setError: PropTypes.func,
    setOpen: PropTypes.func,
    auditData: PropTypes.array,
    setAuditData: PropTypes.func
};

export default AdyenMerchantList;
