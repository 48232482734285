import React from 'react';
import PropTypes from 'prop-types';
import style from './onboarding-forms-container.module.scss';
import { Grid } from '@material-ui/core';

function OnboardingFormsContainerComponent(props) {
    return (
        <div className={style.container}>
            <Grid item xs={12}>
                <Grid item className={style.titleContainer}>
                    {props.title}
                </Grid>
                <Grid item className={style.formsContainer}>
                    {props.content(props.parentProps)}
                </Grid>
                <Grid className={style.btnContainer}>{props.nextButton}</Grid>
            </Grid>
        </div>
    );
}

OnboardingFormsContainerComponent.propTypes = {
    title: PropTypes.element,
    content: PropTypes.func,
    nextButton: PropTypes.element,
    parentProps: PropTypes.any
};

export default OnboardingFormsContainerComponent;
