import React, { useState, useEffect } from 'react';
import AccountVerifyUploadDocumentsComponent from './account-verify-upload-documents.component';
import { useGlobalStateContext } from 'contexts';
import { DocumentsService, BusinessDetailsService, AuthService } from 'services';
import { UserType } from 'utils/enums/UserType';
import { DocumentTypesId } from 'utils/enums/DocumentType';
import { formTypes } from 'utils/enums/FormTypes';
import { BusinessTypeEnumId } from 'utils/enums/BusinessType';

function AccountVerifyUploadDocuments(props) {
    const { globalState } = useGlobalStateContext();
    const [validForm, setValidForm] = useState(false);
    const [formattedDocumentsStats, setFormattedDocumentsStats] = useState({});
    const [loading, setLoading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [isVerifiedBank, setIsVerifiedBank] = useState(true);
    const [businessDetails, setBusinessDetails] = useState({});
    const [idNumber, setIdNumber] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [isFinishButtonDisabled, setIsFinishButtonDisabled] = useState(true);
    const country = globalState.merchant?.country;
    const isAdmin =
        globalState.user?.UserType?.name === UserType.Admin || globalState.user?.UserType?.name === UserType.SuperAdmin;

    const [csrfToken, setCSRFToken] = useState('');

    useEffect(() => {
        const generateCSRFToken = async () => {
            setLoading(true);
            const response = await AuthService.generateCSRFToken(
                formTypes.ACCOUNT_DOCUMENT_UPLOAD,
                globalState.merchant?.id
            );
            if (response.isSuccesfully) {
                setCSRFToken(response.data.csrfToken);
            }
            setLoading(false);
        };
        generateCSRFToken();
        // eslint-disable-next-line
    }, [globalState.merchant.id]);

    useEffect(() => {
        const getBankStatusAndBusinessDetails = async () => {
            setLoading(true);
            const response = await BusinessDetailsService.getMerchantBusinessTypeAndBankStatus(
                globalState.merchant?.id
            );
            if (response.isSuccesfully) {
                setIsVerifiedBank(response.data.isBankAccountVerified && response.data.isAccountVerified);
            } else {
                setIsVerifiedBank(false);
            }

            const businessDetailsResult = await BusinessDetailsService.getBusinessDetails(globalState.merchant?.id);
            if (businessDetailsResult.isSuccesfully) {
                setBusinessDetails(businessDetailsResult.data.businessDetails || {});
            }
            setLoading(false);
        };

        if (globalState.merchant?.id) {
            getBankStatusAndBusinessDetails();
        }
    }, [globalState.merchant]);
    const [bankUpload, setBankUpload] = useState(false);
    const [count, setCount] = useState(1);

    useEffect(() => {
        async function sendAsyncRequest() {
            let nonMandatoryDocumentIds = [DocumentTypesId.STREET_VIEW, DocumentTypesId.FOOD_HYGIENE_CERTIFICATE];
            if (
                ![BusinessTypeEnumId.Sole_Trader, BusinessTypeEnumId.Partnership].includes(
                    globalState.merchant.businessTypeId
                )
            ) {
                nonMandatoryDocumentIds = [...nonMandatoryDocumentIds, DocumentTypesId.UTILITY_BILL];
            }
            const uploadingDocumentId = sessionStorage.getItem('docTypeId');
            const result = nonMandatoryDocumentIds.includes(parseInt(uploadingDocumentId));
            if (uploadSuccess && !result) {
                const response = await DocumentsService.notifyAdminAboutDocumentUploading(
                    globalState.merchant.id,
                    globalState.reseller.id
                );
                if (response.isSuccesfully) {
                    setCount(count + 1);
                    sessionStorage.setItem('uploadSucess', count);
                }
            }
            sessionStorage.removeItem('docTypeId');
        }
        sendAsyncRequest();
        // eslint-disable-next-line
    }, [uploadSuccess]);

    return (
        <AccountVerifyUploadDocumentsComponent
            merchantId={globalState.merchant?.id}
            setValidForm={setValidForm}
            setFormattedDocumentsStats={setFormattedDocumentsStats}
            formattedDocumentsStats={formattedDocumentsStats}
            setUploadSuccess={setUploadSuccess}
            uploadSuccess={uploadSuccess}
            validForm={validForm}
            businessTypeId={businessDetails.businessTypeId}
            isVerifiedBank={isVerifiedBank}
            isFinishButtonDisabled={isFinishButtonDisabled}
            setIsFinishButtonDisabled={setIsFinishButtonDisabled}
            loading={loading}
            setLoading={setLoading}
            setBankUpload={setBankUpload}
            bankUpload={bankUpload}
            country={country}
            source={'ACCOUNT_VERIFY'}
            isAdmin={isAdmin}
            csrfToken={csrfToken}
            formType={formTypes.ACCOUNT_DOCUMENT_UPLOAD}
            setIdNumber={setIdNumber}
            idNumber={idNumber}
            cardNumber={cardNumber}
            setCardNumber={setCardNumber}
        />
    );
}

AccountVerifyUploadDocuments.propTypes = {};

export default AccountVerifyUploadDocuments;
