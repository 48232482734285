import PropTypes from 'prop-types';
import React from 'react';
import styles from './t2s-onboarding-owner-details.module.scss';
import { Grid, NativeSelect, OutlinedInput, Select, MenuItem, FormControl } from '@material-ui/core';
import {
    Input,
    Label,
    NextButton,
    OnboardingTitle,
    OnboardingFormsContainer,
    FormItem,
    PostCodeSelector
} from 'components/onboarding/onboarding-ui-components';
import { ValidEmailInput } from 'components';
import { checkEmptyValue } from 'validators/onboarding/onboarding-check-value.validator';
import { emailValidationPattern } from 'utils/enums/EmailValidationPattern';
import { OwnerTitle } from 'utils/enums/OwnerTitle';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { CheckMerchantCountries } from '../onboarding-ui-components/check-merchant-countries/check-merchant-countries';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { FormatMessage, translateText } from 'utils/languageTranslater';
import { useGlobalStateContext } from 'contexts';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { LoadingScreen } from 'components';
import { AUSStateCodes, USAStateCodes } from 'utils/onboarding-helper';
import { BusinessTypeEnumId } from 'utils/enums/BusinessType';

function T2SOnboardingOwnerDetailsComponent(props) {
    const {
        nextStep,
        onboardingForm,
        setOnboardingForm,
        isABNValid,
        EinErrors,
        isEINValid,
        addHyphens,
        doValidationABN,
        handleChange,
        abnErrors,
        isDisabled,
        onSubmit,
        dobValidationFailed,
        phoneNumberValidator,
        dobInvalidFields,
        monthInputElementRef,
        yearInputElementRef,
        emailInputElementRef,
        dayChangeHandler,
        monthChangeHandler,
        yearChangeHandler,
        onChangeSameAddress,
        selectAddressData,
        validationMap,
        firstName,
        lastName,
        setFirstName,
        setLastName,
        setDialCode,
        dialCode,
        validateName,
        isLoading,
        selectedMonth,
        months,
        reverseMonthEnum,
        setShowUrlValidationMessage,
        onSSNChange,
        maskedSSNValue,
        doValidation
    } = props;

    const { globalState } = useGlobalStateContext();

    const isActiveMerchant = globalState.merchant?.status === MerchantStatus.ACTIVE;
    const isUkMerchant = globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM;
    const isUSAMerchant = globalState.merchant?.country === MerchantCountries.UNITED_STATES;
    const maxSSNLength = 9;

    const title = (
        <OnboardingTitle
            title="Owner details"
            subTitle="There are your personal contact details"
            transalterEnabled={true}
            subTransalterEnabled={true}
        ></OnboardingTitle>
    );

    function formBody() {
        return (
            <React.Fragment>
                <div className={styles.primaryOwner}>
                    {translateText({ id: 'primary-owner', text: 'Primary owner' })}
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={3} className={styles.formItemGrid}>
                        <FormItem>
                            <Label text="Title" id="owner-label-title" transalterEnabled="true"></Label>
                            <NativeSelect
                                input={
                                    <Input
                                        placeholder="Title"
                                        id="owner-input-title"
                                        value={onboardingForm.ownersDetails.title}
                                        onChange={(e) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                ownersDetails: {
                                                    ...onboardingForm.ownersDetails,
                                                    title: e.target.value
                                                }
                                            })
                                        }
                                        emptyField={
                                            isUkMerchant &&
                                            isActiveMerchant &&
                                            !checkEmptyValue(onboardingForm.ownersDetails.title)
                                                ? true
                                                : false
                                        }
                                    ></Input>
                                }
                            >
                                {Object.keys(OwnerTitle).map((key) => (
                                    <option key={OwnerTitle[key]} value={OwnerTitle[key]}>
                                        {FormatMessage({ id: OwnerTitle[key], text: OwnerTitle[key] })}
                                    </option>
                                ))}
                            </NativeSelect>
                        </FormItem>
                    </Grid>
                    <Grid item xs={12} sm={9} className={styles.formItemGrid}>
                        <FormItem>
                            <div className={styles.nameInput}>
                                <div className={styles.space}>
                                    <Label
                                        text="First name"
                                        id="owner-label-firstname"
                                        transalterEnabled="true"
                                    ></Label>
                                    <Input
                                        placeholder={FormatMessage({
                                            id: 'owner-label-firstname',
                                            text: 'First name'
                                        })}
                                        id="owner-input-firstname"
                                        value={checkEmptyValue(firstName)}
                                        onChange={(e) => setFirstName(validateName(e.target.value))}
                                        emptyField={
                                            isActiveMerchant && !checkEmptyValue(firstName) && isUkMerchant
                                                ? true
                                                : false
                                        }
                                    ></Input>
                                </div>
                                <div className={styles.space}>
                                    <Label text="Last name" id="owner-label-lastname" transalterEnabled="true"></Label>
                                    <Input
                                        className={styles.inputBorder}
                                        placeholder={FormatMessage({
                                            id: 'owner-label-lastname',
                                            text: 'Last name'
                                        })}
                                        id="owner-input-lastname"
                                        value={checkEmptyValue(lastName)}
                                        onChange={(e) => setLastName(validateName(e.target.value))}
                                        emptyField={
                                            isActiveMerchant && !checkEmptyValue(lastName) && isUkMerchant
                                                ? true
                                                : false
                                        }
                                    ></Input>
                                </div>
                            </div>
                        </FormItem>
                    </Grid>

                    <Grid item xs={12} className={styles.formItemGrid}>
                        <CheckMerchantCountries includedCountries={[MerchantCountries.MEXICO]}>
                            <FormItem>
                                <Label text="Personal Id" id="personal-id" transalterEnabled="true"></Label>
                                <Input
                                    placeholder={FormatMessage({ id: 'personal-id', text: 'Personal Id' })}
                                    id="owner-personal-Id"
                                    value={checkEmptyValue(onboardingForm.ownersDetails.personalId)}
                                    onChange={(e) =>
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            ownersDetails: {
                                                ...onboardingForm.ownersDetails,
                                                personalId: e.target.value
                                            }
                                        })
                                    }
                                ></Input>
                            </FormItem>
                        </CheckMerchantCountries>
                    </Grid>
                    <Grid item xs={12} sm={6} className={styles.formItemGrid}>
                        <FormItem>
                            <Label text="Citizenship" id="owner-label-citizenship" transalterEnabled="true"></Label>
                            <NativeSelect
                                inputProps={{
                                    className: validationMap.nationality === 'FAILED' ? styles.invalidNationality : ''
                                }}
                                input={
                                    <Input
                                        placeholder={FormatMessage({
                                            id: 'owner-label-citizenship',
                                            text: 'Citizenship'
                                        })}
                                        id="owner-select-nationality"
                                        value={onboardingForm.ownersDetails.nationality}
                                        onChange={(e) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                ownersDetails: {
                                                    ...onboardingForm.ownersDetails,
                                                    nationality: e.target.value
                                                }
                                            })
                                        }
                                        emptyField={
                                            isUkMerchant &&
                                            isActiveMerchant &&
                                            onboardingForm.ownersDetails.nationality === '--Please select--'
                                                ? true
                                                : false
                                        }
                                    ></Input>
                                }
                                value={onboardingForm.ownersDetails.nationality}
                            >
                                {onboardingForm.nationalities.map((nationality) => (
                                    <option key={nationality} value={nationality}>
                                        {nationality}
                                    </option>
                                ))}
                            </NativeSelect>
                        </FormItem>
                    </Grid>
                    <Grid item xs={12} sm={6} className={styles.formItemGrid}>
                        <FormItem>
                            <Label text="Date of birth" id="owner-label-dateofbirth" transalterEnabled="true"></Label>
                            <div className={styles.dateOfBirthWrapper}>
                                <NumberFormat
                                    className={`${styles.numberFormatInput} ${
                                        dobValidationFailed || dobInvalidFields.includes('day')
                                            ? styles.containsErrorDOB
                                            : ''
                                    }`}
                                    customInput={OutlinedInput}
                                    inputProps={{ inputMode: 'numeric' }}
                                    pattern="\d*"
                                    allowLeadingZeros={true}
                                    decimalSeparator={false}
                                    allowNegative={false}
                                    isNumericString={true}
                                    placeholder="DD"
                                    value={onboardingForm.ownersDetails.dateOfBirth?.day}
                                    id="date-of-birth-day-input"
                                    onValueChange={dayChangeHandler}
                                    aria-describedby="outlined-weight-helper-text"
                                    labelWidth={0}
                                    required
                                />
                                <FormControl variant="outlined" className={styles.fullOuterBox}>
                                    <Select
                                        value={reverseMonthEnum[selectedMonth]}
                                        onChange={monthChangeHandler}
                                        className={styles.monthSelect}
                                        input={<OutlinedInput label="Month" />}
                                        inputProps={{
                                            ref: monthInputElementRef
                                        }}
                                    >
                                        {months.map((month, index) => (
                                            <MenuItem
                                                key={onboardingForm.ownersDetails?.dateOfBirth?.month}
                                                value={index}
                                            >
                                                {month}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <NumberFormat
                                    className={`${styles.numberFormatInput} ${
                                        dobValidationFailed || dobInvalidFields.includes('year')
                                            ? styles.containsErrorDOB
                                            : ''
                                    }`}
                                    customInput={OutlinedInput}
                                    inputProps={{ inputMode: 'numeric', ref: yearInputElementRef }}
                                    pattern="\d*"
                                    allowLeadingZeros={true}
                                    decimalSeparator={false}
                                    allowNegative={false}
                                    isNumericString={true}
                                    placeholder="YYYY"
                                    value={onboardingForm.ownersDetails.dateOfBirth?.year}
                                    id="date-of-birth-year-input"
                                    onValueChange={yearChangeHandler}
                                    aria-describedby="outlined-weight-helper-text"
                                    labelWidth={0}
                                    required
                                />
                            </div>
                        </FormItem>
                    </Grid>
                    <Grid item xs={12} className={styles.formItemGrid}>
                        <ValidEmailInput
                            labelText="Email"
                            labelId="wner-label-email"
                            id="owner-input-email"
                            fullWidth={true}
                            type="email"
                            emailInputElementRef={emailInputElementRef}
                            pattern={emailValidationPattern.regexString}
                            value={checkEmptyValue(onboardingForm.ownersDetails.email)}
                            onChange={(e) => {
                                setOnboardingForm({
                                    ...onboardingForm,
                                    ownersDetails: {
                                        ...onboardingForm.ownersDetails,
                                        email: e.target.value.toLowerCase()
                                    }
                                });
                            }}
                            emptyField={
                                isUkMerchant && isActiveMerchant && !checkEmptyValue(onboardingForm.ownersDetails.email)
                                    ? true
                                    : false
                            }
                        />
                    </Grid>

                    <Grid item xs={12} className={styles.formItemGrid}>
                        <CheckMerchantCountries
                            includedCountries={[MerchantCountries.AUSTRALIA, MerchantCountries.UNITED_STATES]}
                        >
                            {!isUSAMerchant ? (
                                <>
                                    <FormItem>
                                        <Label
                                            text="Enter ABN number"
                                            id="enter-ABN-number"
                                            transalterEnabled="true"
                                        ></Label>
                                        <Input
                                            placeholder={FormatMessage({
                                                id: 'enter-ABN-number',
                                                text: 'Enter ABN number'
                                            })}
                                            id={'enter-ABN-number'}
                                            value={checkEmptyValue(onboardingForm.ownersDetails.tinNumber)}
                                            onChange={(e) => {
                                                setOnboardingForm({
                                                    ...onboardingForm,
                                                    ownersDetails: {
                                                        ...onboardingForm.ownersDetails,
                                                        tinNumber: e.target.value.replace(/[^\d]/g, '')
                                                    }
                                                });
                                                doValidationABN(e.target.value.replace(/[^\d]/g, ''));
                                            }}
                                        ></Input>
                                        {isABNValid && onboardingForm?.ownersDetails?.tinNumber?.length > 0 && (
                                            <p style={{ color: 'red', marginTop: '5px' }}>{abnErrors}</p>
                                        )}
                                    </FormItem>
                                </>
                            ) : onboardingForm.businessDetails.businessTypeId !=
                              BusinessTypeEnumId.Sole_Trader.toString() ? (
                                <>
                                    <FormItem>
                                        <Label
                                            text="Enter EIN number"
                                            id="enter-EIN-number"
                                            transalterEnabled="true"
                                        ></Label>
                                        <Input
                                            placeholder={FormatMessage({
                                                id: 'enter-EIN-number',
                                                text: 'Enter EIN number'
                                            })}
                                            id={'enter-EIN-number'}
                                            value={
                                                addHyphens(onboardingForm.ownersDetails.tinNumber) ||
                                                checkEmptyValue(onboardingForm.ownersDetails.tinNumber)
                                            }
                                            onChange={handleChange}
                                        ></Input>
                                        {isEINValid && onboardingForm?.ownersDetails?.tinNumber?.length > 0 && (
                                            <p style={{ color: 'red', marginTop: '5px' }}>{EinErrors}</p>
                                        )}
                                    </FormItem>
                                    {isUSAMerchant && (
                                        <FormItem>
                                            <Label
                                                text="Social security number (SSN)"
                                                id="enter-SSN-number"
                                                transalterEnabled="true"
                                            />
                                            <Input
                                                autoFocus={false}
                                                type="text"
                                                fields={9}
                                                placeholder={FormatMessage({
                                                    id: 'enter-SSN-number',
                                                    text: 'Enter SSN number'
                                                })}
                                                value={maskedSSNValue}
                                                onChange={(e) => {
                                                    if (e.target.value.length <= maxSSNLength) {
                                                        doValidation(e.target.value);
                                                        setShowUrlValidationMessage(false);
                                                        onSSNChange(e);
                                                    }
                                                }}
                                            />
                                            {onboardingForm?.ownersDetails?.ssnLastDigits?.length !== 9 &&
                                                onboardingForm?.ownersDetails?.ssnLastDigits?.length > 0 && (
                                                    <p style={{ color: 'red', marginTop: '5px' }}>
                                                        Please Enter valid SSN
                                                    </p>
                                                )}
                                        </FormItem>
                                    )}
                                </>
                            ) : (
                                <FormItem>
                                    <Label text="Social security number (SSN)" id="enter-SSN-number" />
                                    <Input
                                        autoFocus={false}
                                        type="text"
                                        fields={9}
                                        value={maskedSSNValue}
                                        placeholder={FormatMessage({
                                            id: 'enter-SSN-number',
                                            text: 'Enter SSN number'
                                        })}
                                        onChange={(e) => {
                                            if (e.target.value.length <= maxSSNLength) {
                                                doValidation(e.target.value);
                                                setShowUrlValidationMessage(false);
                                                onSSNChange(e);
                                            }
                                        }}
                                    />
                                </FormItem>
                            )}
                        </CheckMerchantCountries>
                    </Grid>
                    <Grid item xs={12} className={styles.formItemGrid}>
                        <FormItem>
                            <CheckMerchantCountries excludedCountries={[MerchantCountries.MEXICO]}>
                                <Label
                                    text="Mobile number"
                                    id="owner-label-contacttel"
                                    transalterEnabled="true"
                                ></Label>
                                <PhoneInput
                                    country={'gb'}
                                    placeholder="Mobile number"
                                    id="owner-input-contacttel"
                                    value={checkEmptyValue(onboardingForm.ownersDetails.contactTel)}
                                    alwaysDefaultMask={true}
                                    countryCodeEditable={false}
                                    onChange={(mobileNumber, data) => {
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            ownersDetails: {
                                                ...onboardingForm.ownersDetails,
                                                contactTel: phoneNumberValidator(
                                                    mobileNumber,
                                                    onboardingForm.ownersDetails.contactTel
                                                )
                                            }
                                        });
                                        setDialCode(data.dialCode);
                                    }}
                                    inputClass={
                                        isUkMerchant &&
                                        isActiveMerchant &&
                                        (!checkEmptyValue(onboardingForm.ownersDetails.contactTel) ||
                                            onboardingForm.ownersDetails.contactTel === dialCode)
                                            ? styles.phoneInputEmpty
                                            : styles.phoneInput
                                    }
                                    name="Mobile Number"
                                />
                            </CheckMerchantCountries>
                        </FormItem>

                        <CheckMerchantCountries includedCountries={[MerchantCountries.MEXICO]}>
                            <Label text="Mobile number" id="owner-label-contacttel" transalterEnabled="true"></Label>
                            <PhoneInput
                                country={'mx'}
                                placeholder={FormatMessage({ id: 'owner-label-contacttel', text: 'Mobile number' })}
                                id="owner-input-contacttel"
                                value={checkEmptyValue(onboardingForm.ownersDetails.contactTel)}
                                alwaysDefaultMask={true}
                                countryCodeEditable={false}
                                onChange={(mobileNumber, data) => {
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        ownersDetails: {
                                            ...onboardingForm.ownersDetails,
                                            contactTel: phoneNumberValidator(
                                                mobileNumber,
                                                onboardingForm.ownersDetails.contactTel
                                            )
                                        }
                                    });
                                    setDialCode(data.dialCode);
                                }}
                                inputClass={styles.phoneInput}
                                name="Mobile Number"
                            />
                        </CheckMerchantCountries>
                    </Grid>
                    <Grid item xs={12} className={styles.formItemGrid}>
                        <FormItem>
                            {isUSAMerchant ? (
                                <FormControlLabel
                                    className={styles.checkBoxLabel}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={onboardingForm.ownerPrimaryAddress.isSameAsTradingAddress}
                                            onChange={(e) => onChangeSameAddress(e.target.checked)}
                                        />
                                    }
                                    label={translateText({
                                        id: 'address-checkbox-for-usa',
                                        text: 'Owner address same as business address'
                                    })}
                                    labelPlacement="end"
                                />
                            ) : (
                                <FormControlLabel
                                    className={styles.checkBoxLabel}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={onboardingForm.ownerPrimaryAddress.isSameAsTradingAddress}
                                            onChange={(e) => onChangeSameAddress(e.target.checked)}
                                        />
                                    }
                                    label={translateText({
                                        id: 'address-checkbox',
                                        text: 'Owner address same as trading address'
                                    })}
                                    labelPlacement="end"
                                />
                            )}
                        </FormItem>
                    </Grid>
                    <Grid item xs={12} className={styles.formItemGrid}>
                        <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_KINGDOM]}>
                            <FormItem>
                                <Label text="Postcode" id="address-label-post-code-selector"></Label>
                                <PostCodeSelector
                                    postCodeValue={checkEmptyValue(onboardingForm.ownerPrimaryAddress.postCode)}
                                    onPostCodeChange={(postCode) =>
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            ownerPrimaryAddress: {
                                                ...onboardingForm.ownerPrimaryAddress,
                                                postCode: postCode
                                            }
                                        })
                                    }
                                    onAddressObjectChange={(addressData) => selectAddressData(addressData)}
                                    emptyField={
                                        isUkMerchant &&
                                        isActiveMerchant &&
                                        !checkEmptyValue(onboardingForm.ownerPrimaryAddress.postCode)
                                            ? true
                                            : false
                                    }
                                />
                            </FormItem>
                        </CheckMerchantCountries>
                    </Grid>
                    <Grid item xs={12} className={styles.formItemGrid}>
                        <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_STATES]}>
                            <FormItem>
                                <Label text="ZIP code" id="name-address-label-post-code"></Label>
                                <Input
                                    placeholder="ZIP code"
                                    id="zip-code"
                                    value={checkEmptyValue(onboardingForm.ownerPrimaryAddress.postCode)}
                                    onChange={(e) =>
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            ownerPrimaryAddress: {
                                                ...onboardingForm.ownerPrimaryAddress,
                                                postCode: e.target.value
                                            }
                                        })
                                    }
                                ></Input>
                            </FormItem>
                        </CheckMerchantCountries>
                    </Grid>
                    <Grid item xs={12} className={styles.formItemGrid}>
                        <CheckMerchantCountries
                            excludedCountries={[MerchantCountries.UNITED_KINGDOM, MerchantCountries.UNITED_STATES]}
                        >
                            <FormItem>
                                <Label text="Post code" id="address-label-post-code" transalterEnabled="true"></Label>
                                <Input
                                    placeholder={FormatMessage({ id: 'address-label-post-code', text: 'Post cpde' })}
                                    id="address-input-post-code"
                                    value={checkEmptyValue(onboardingForm.ownerPrimaryAddress.postCode)}
                                    onChange={(e) =>
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            ownerPrimaryAddress: {
                                                ...onboardingForm.ownerPrimaryAddress,
                                                postCode: e.target.value
                                            }
                                        })
                                    }
                                ></Input>
                            </FormItem>
                        </CheckMerchantCountries>
                    </Grid>
                    <Grid item xs={12} className={styles.formItemGrid}>
                        <FormItem>
                            <Label text="Address line 1" id="address-label-address" transalterEnabled="true"></Label>
                            <Input
                                placeholder={FormatMessage({ id: 'address-label-address', text: 'Address line 1' })}
                                id="address-input-address"
                                value={checkEmptyValue(onboardingForm.ownerPrimaryAddress.addressLine1)}
                                onChange={(e) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        ownerPrimaryAddress: {
                                            ...onboardingForm.ownerPrimaryAddress,
                                            addressLine1: e.target.value
                                        }
                                    })
                                }
                                emptyField={
                                    isUkMerchant &&
                                    isActiveMerchant &&
                                    !checkEmptyValue(onboardingForm.ownerPrimaryAddress.addressLine1)
                                        ? true
                                        : false
                                }
                            />
                        </FormItem>
                    </Grid>
                    <Grid item xs={12} className={styles.formItemGrid}>
                        <FormItem>
                            <Label
                                text="Address line 2"
                                id="address-label-address-line-2"
                                transalterEnabled="true"
                            ></Label>
                            <Input
                                placeholder={FormatMessage({
                                    id: 'address-label-address-line-2',
                                    text: 'Address line 2'
                                })}
                                id="address-input-address-line-2"
                                value={checkEmptyValue(onboardingForm.ownerPrimaryAddress.addressLine2)}
                                onChange={(e) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        ownerPrimaryAddress: {
                                            ...onboardingForm.ownerPrimaryAddress,
                                            addressLine2: e.target.value
                                        }
                                    })
                                }
                            />
                        </FormItem>
                    </Grid>
                    <Grid item xs={12} className={styles.formItemGrid}>
                        <FormItem>
                            <Label text="Town/City" id="address-label-town-city" transalterEnabled="true"></Label>
                            <Input
                                placeholder={FormatMessage({ id: 'address-label-town-city', text: 'Town/City' })}
                                id="address-input-town-city"
                                value={checkEmptyValue(onboardingForm.ownerPrimaryAddress.city)}
                                onChange={(e) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        ownerPrimaryAddress: {
                                            ...onboardingForm.ownerPrimaryAddress,
                                            city: e.target.value
                                        }
                                    })
                                }
                                emptyField={
                                    isUkMerchant &&
                                    isActiveMerchant &&
                                    !checkEmptyValue(onboardingForm.ownerPrimaryAddress.city)
                                        ? true
                                        : false
                                }
                            />
                        </FormItem>
                    </Grid>
                    <Grid item xs={12} className={styles.formItemGrid}>
                        <CheckMerchantCountries includedCountries={[MerchantCountries.AUSTRALIA]}>
                            <FormItem>
                                <Label
                                    text="State/Region/Province code"
                                    id="State-Region-province"
                                    transalterEnabled="true"
                                ></Label>
                                <NativeSelect
                                    inputProps={{
                                        className:
                                            onboardingForm.ownerPrimaryAddress.state == '' ||
                                            onboardingForm.ownerPrimaryAddress.state == '--Please select--'
                                                ? styles.unselectedDropdownErrorStyle
                                                : ''
                                    }}
                                    input={
                                        <Input
                                            placeholder="State/Region/Province code"
                                            id="State-Region-province"
                                            value={checkEmptyValue(onboardingForm.ownerPrimaryAddress.state)}
                                            onChange={(e) =>
                                                setOnboardingForm({
                                                    ...onboardingForm,
                                                    ownerPrimaryAddress: {
                                                        ...onboardingForm.ownerPrimaryAddress,
                                                        state: e.target.value
                                                    }
                                                })
                                            }
                                        ></Input>
                                    }
                                >
                                    {AUSStateCodes.map((type) => {
                                        return (
                                            <option key={type.id} value={type.id}>
                                                {FormatMessage({ id: type.name, text: type.name })}
                                            </option>
                                        );
                                    })}
                                </NativeSelect>
                            </FormItem>
                        </CheckMerchantCountries>
                    </Grid>
                    <Grid item xs={12} className={styles.formItemGrid}>
                        <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_STATES]}>
                            <FormItem>
                                <Label
                                    text="State/Region/Province code"
                                    id="State-Region-province"
                                    transalterEnabled="true"
                                ></Label>
                                <NativeSelect
                                    inputProps={{
                                        className:
                                            onboardingForm.ownerPrimaryAddress.state == '' ||
                                            onboardingForm.ownerPrimaryAddress.state == '--Please select--'
                                                ? styles.unselectedDropdownErrorStyle
                                                : ''
                                    }}
                                    input={
                                        <Input
                                            placeholder="State/Region/Province code"
                                            id="State-Region-province"
                                            value={checkEmptyValue(onboardingForm.ownerPrimaryAddress.state)}
                                            onChange={(e) =>
                                                setOnboardingForm({
                                                    ...onboardingForm,
                                                    ownerPrimaryAddress: {
                                                        ...onboardingForm.ownerPrimaryAddress,
                                                        state: e.target.value
                                                    }
                                                })
                                            }
                                        ></Input>
                                    }
                                >
                                    {USAStateCodes.map((type) => {
                                        return (
                                            <option key={type.id} value={type.id}>
                                                {FormatMessage({ id: type.name, text: type.name })}
                                            </option>
                                        );
                                    })}
                                </NativeSelect>
                            </FormItem>
                        </CheckMerchantCountries>
                    </Grid>
                    <Grid item xs={12} className={styles.formItemGrid}>
                        <CheckMerchantCountries includedCountries={[MerchantCountries.MEXICO]}>
                            <FormItem>
                                <Label text="State" id="address-label-state" transalterEnabled="true"></Label>
                                <Input
                                    placeholder={FormatMessage({ id: 'address-label-state', text: 'State' })}
                                    id="address-input-state"
                                    value={checkEmptyValue(onboardingForm.ownerPrimaryAddress.state)}
                                    onChange={(e) =>
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            ownerPrimaryAddress: {
                                                ...onboardingForm.ownerPrimaryAddress,
                                                state: e.target.value
                                            }
                                        })
                                    }
                                />
                            </FormItem>
                        </CheckMerchantCountries>
                    </Grid>
                    <Grid item xs={12} className={styles.formItemGrid}>
                        <CheckMerchantCountries excludedCountries={[MerchantCountries.MEXICO]}>
                            <FormItem>
                                <Label text="Country" id="address-label-country" transalterEnabled="true"></Label>
                                <Input
                                    placeholder="Country"
                                    id="address-input-country"
                                    value={checkEmptyValue(onboardingForm.ownerPrimaryAddress.country)}
                                    onChange={(e) =>
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            ownerPrimaryAddress: {
                                                ...onboardingForm.ownerPrimaryAddress,
                                                country: e.target.value
                                            }
                                        })
                                    }
                                    emptyField={
                                        isUkMerchant &&
                                        isActiveMerchant &&
                                        !checkEmptyValue(onboardingForm.ownerPrimaryAddress.country)
                                            ? true
                                            : false
                                    }
                                />
                            </FormItem>
                        </CheckMerchantCountries>
                        <CheckMerchantCountries includedCountries={[MerchantCountries.MEXICO]}>
                            <FormItem>
                                <Label text="Country" id="address-label-country" transalterEnabled="true"></Label>
                                <Input
                                    placeholder={FormatMessage({ id: 'address-label-country', text: 'Country' })}
                                    id="address-input-country"
                                    value={MerchantCountries.MEXICO}
                                    disabled
                                />
                            </FormItem>
                        </CheckMerchantCountries>
                    </Grid>
                </Grid>
                <LoadingScreen open={isLoading} />
            </React.Fragment>
        );
    }

    const nextBtn = (
        <NextButton
            disabled={isDisabled}
            onSubmit={() => {
                nextStep();
                onSubmit();
            }}
        ></NextButton>
    );

    return (
        <React.Fragment>
            <OnboardingFormsContainer title={title} content={formBody} nextButton={nextBtn} />
        </React.Fragment>
    );
}

T2SOnboardingOwnerDetailsComponent.propTypes = {
    nextStep: PropTypes.func,
    onboardingForm: PropTypes.object,
    setOnboardingForm: PropTypes.func,
    isDisabled: PropTypes.bool,
    shouldNextStep: PropTypes.bool,
    setShouldNextStep: PropTypes.func,
    onSubmit: PropTypes.func,
    oneHundredValues: PropTypes.array,
    setPersonDetails: PropTypes.func,
    nationalities: PropTypes.array,
    phoneNumberValidator: PropTypes.func,
    dayChangeHandler: PropTypes.func,
    monthChangeHandler: PropTypes.func,
    yearChangeHandler: PropTypes.func
};

export default T2SOnboardingOwnerDetailsComponent;
