export const statusCodes = {
    REACTIVATING_SIGNUP_LINK: (day) => ({
        message: `The signup link has been successfully reactivated and will remain active for the next ${day} days.`,
        statusCode: 5006
    }),
    SIGNUP_LINK_REACTIVATION_UNAVAILABLE: {
        message: 'The signup link cannot be reactivated.'
    },
    RESEND_EMAIL_VERIFICATION_CODE: 'A verification code has been sent to your email address.'
};
