import React from 'react';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import styles from './terms-and-conditions.module.scss';
import { useGlobalStateContext } from 'contexts';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import expressionMeaningRows from './expressionMeaningRows';

function TermsAndConditionsPageComponent() {
    const { globalState } = useGlobalStateContext();
    const mediaLessThan600px = useMediaQuery('(max-width:599px)');
    const isDatman = globalState.reseller?.id === 2;

    return (
        <Grid container direction="row" alignItems="center" justify="center" className={styles.container}>
            <Grid container item xs={12} justify="center" alignItems="center">
                <img
                    src={globalState.reseller?.logo}
                    alt="Logo"
                    className={isDatman ? styles.container__datman__img : styles.container__img}
                />
            </Grid>

            <Grid container item xs={12} justify="center">
                <Typography variant={mediaLessThan600px ? 'h5' : 'h4'} paragraph>
                    Terms and conditions
                </Typography>
            </Grid>

            <Grid item xs={mediaLessThan600px ? 12 : 8} className={styles.container__text}>
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <i>Table of contents</i>
                </Typography>
                <ul>
                    <li>
                        <a href="#Basis_of_contract">Basis of contract</a>
                    </li>
                    <li>
                        <a href="#Term">Term</a>
                    </li>
                    <li>
                        <a href="#The_Company’s_Role">The company’s role</a>
                    </li>
                    <li>
                        <a href="#Other_businesses">Other businesses</a>
                    </li>
                    <li>
                        <a href="#Your_Responsibilities_and_Payments">Your responsibilities and payments</a>
                    </li>
                    <li>
                        <a href="#Indemnity">Indemnity</a>
                    </li>
                    <li>
                        <a href="#Condition_Precedent">Condition precedent</a>
                    </li>
                    <li>
                        <a href="#Processing_Data">Processing data</a>
                    </li>
                    <li>
                        <a href="#Provision_and_Disclosure_of_Data">Provision and disclosure of data</a>
                    </li>
                    <li>
                        <a href="#Data_Analysis">Data analysis</a>
                    </li>
                    <li>
                        <a href="#Intellectual_Property">Intellectual property</a>
                    </li>
                    <li>
                        <a href="#Confidential_Information">Confidential information</a>
                    </li>
                    <li>
                        <a href="#Acquiring_Services_and_Merchant_Services">Acquiring services and merchant services</a>
                    </li>
                    <li>
                        <a href="#Receipts">Receipts</a>
                    </li>
                    <li>
                        <a href="#Service_Adjustments_and_Contract_Variations">
                            Service adjustments and contract variations
                        </a>
                    </li>
                    <li>
                        <a href="#Level_of_Services">Level of services</a>
                    </li>
                    <li>
                        <a href="#Exclusion_and_Limitation_of_Liability">Exclusion and limitation of liability</a>
                    </li>
                    <li>
                        <a href="#Term_and_Termination">Term and termination</a>
                    </li>
                    <li>
                        <a href="#Assignment,_Sub-contracting_and_Novation">Assignment, sub-contracting and novation</a>
                    </li>
                    <li>
                        <a href="#Waiver">Waiver</a>
                    </li>
                    <li>
                        <a href="#Notices">Notices</a>
                    </li>
                    <li>
                        <a href="#Entire_Agreement">Entire agreement</a>
                    </li>
                    <li>
                        <a href="#Severability">Severability</a>
                    </li>
                    <li>
                        <a href="#Miscellaneous">Miscellaneous</a>
                    </li>
                    <li>
                        <a href="#Dispute_Resolution_Procedure">Dispute resolution procedure</a>
                    </li>
                    <li>
                        <a href="#Governing_law_and_jurisdiction">Governing law and jurisdiction</a>
                    </li>
                    <li>
                        <a href="#Complaints">Complaints</a>
                    </li>
                    <li>
                        <a href="#Interpretation_and_Construction">Interpretation and construction</a>
                    </li>
                </ul>
            </Grid>

            <Grid item xs={mediaLessThan600px ? 12 : 8} className={styles.container__text} id="Basis_of_contract">
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>1 Basis of contract</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>1.1</b> These Conditions apply to the Contract to the exclusion of any other terms that you seek
                    to impose or incorporate, or which are implied by trade, custom, practice or course of dealing.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>1.2</b> The Contract Form constitutes a Contract by you to accept the Services in accordance with
                    these Conditions. You are responsible for ensuring that the terms set out on the Contract Form are
                    complete and accurate.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>1.3</b> The Contract Form shall only be deemed to be accepted when the Company signs the Contract
                    Form (by a handwritten or electronic signature) at which point the Contract shall come into
                    existence.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>1.4</b> The Contract constitutes the entire Contract between the parties. You acknowledge that
                    you have not relied on any statement, promise or representation made or given by or on behalf of the
                    Company which is not set out in the Contract.
                </Typography>
            </Grid>

            <Grid item xs={mediaLessThan600px ? 12 : 8} className={styles.container__text} id="Term">
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>2 Term</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>2.1</b> The Contract shall start on the Commencement Date and shall run for the Initial Period.
                    If the Contract is not terminated during the Initial Period in accordance with clause 18, the
                    Contract shall continue to run on a rolling twelve (12) calendar month basis, starting on the next
                    day following the expiration of the Initial Period.
                </Typography>
            </Grid>

            <Grid item xs={mediaLessThan600px ? 12 : 8} className={styles.container__text} id="The_Company’s_Role">
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>3 The company’s role</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>3.1</b> The Company assists you, as a third party seller to process E-commerce Transactions in
                    relation to Orders. While the Company as a platform provider helps facilitate Acquiring Services in
                    respect of processing E-commerce Transactions relating to Orders that are carried out on your
                    website, the Company is neither the buyer nor the seller of your goods.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>3.2</b> The Company provides a venue for you and the Customers to complete E-commerce
                    Transactions. Accordingly, the contract formed at the completion of an Order is solely between you
                    and the Customer. The Company is not a party to this contract nor assumes any responsibility arising
                    out of or in connection with it nor is it your agent.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>3.3</b> You are responsible for the sale of the goods and for dealing with any Customer claims or
                    any other issue arising out of or in connection with the contract between you and the Customer.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>3.4</b> Provision of the Services, particularly Merchant and Acquiring Services are dependent
                    upon the actions of Third Parties, including but not limited to Customer Cards, Card issuers and
                    Financial Institutions processing Orders and E-commerce Transactions. Subject to the provisions of
                    clause 17, we will not be held responsible for any delay or failure to comply with our obligations
                    under these Conditions if the delay or failure arises from any cause which is beyond our reasonable
                    control, particularly in respect of any Downtime in providing the Services at anytime, particularly{' '}
                    <b>outside of Office Hours</b>.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>3.5</b> It is expressly agreed that the Company shall not be liable for any delays or Downtime in
                    respect of providing services caused by events beyond our control, such as the failure of Third
                    Party internet providers for either you, Customers, Barclaycard, Smartpay, Financial Institutions,
                    or otherwise, in respect of the Company delivering the Services.
                </Typography>
            </Grid>

            <Grid item xs={mediaLessThan600px ? 12 : 8} className={styles.container__text} id="Other_businesses">
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>4 Other businesses</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>4.1</b> Parties other than the Company and its subsidiaries, provide services on the Website. For
                    example, Barclaycard and/or Smartpay provide the Acquiring Services in respect of processing payment
                    of all Customer Orders via the Website. In addition, we provide links to the websites of affiliated
                    companies and certain other businesses.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>4.2</b> We are not responsible for examining or evaluating, and we do not warrant or endorse the
                    offerings of any of these businesses or individuals, or the content of their websites. The Company
                    does not assume any responsibility or liability for the actions, products, and content of any of
                    these and any Third Parties. You can tell when a Third Party is involved in your transactions, and
                    we may share Customer information related to those transactions with that Third Party. You should
                    carefully review their privacy statements and other conditions of use.
                </Typography>
            </Grid>
            <Grid
                item
                xs={mediaLessThan600px ? 12 : 8}
                className={styles.container__text}
                id="Your_Responsibilities_and_Payments"
            >
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>5 Your responsibilities and payments</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>5.1</b> The first four (4) weeks of the Contract shall be free of charge. You shall make the
                    Payments to the Company on the Weekly Payment Date for the Term. The Payments shall be paid in GBP
                    Sterling by standing order from your Bank Account, or by such other means as may be agreed by the
                    Company, to ensure that the Payments are made on or before the Weekly Payment Date.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>5.2</b> The Payments are inclusive of VAT but exclusive of any other applicable taxes and duties
                    or similar charges, which shall be payable by you at the rate and in the manner from time to time
                    prescribed by Applicable Law.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>5.3</b> All payments to be made by you under this Contract shall be made without withholding or
                    set-off on account of disputes, counterclaims or for any other reason whatsoever.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>5.4</b> You shall at all times comply with:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    5.4.1 the provisions of this Contract;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    5.4.2 all Applicable Laws, including those requirements which apply to the sale of goods by you in
                    connection with the Transactions and the execution and performance by you of your obligations under
                    this Contract; and
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    5.4.3 your obligations relating to the sale and/or supply of goods by you to Customers.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    5.4.4 you will at all times comply with this Agreement, the PCI DSS, and any law or regulation
                    applicable to your activities, Services and/or Transactions, as well as our reasonable instructions
                    from time to time in relation to your use of the Services.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>5.5</b> You shall:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    5.5.1 in relation to Transactions involving Cards, only accept payment from Customers in connection
                    with goods which you have sold and supplied to those Customers;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    5.5.2 only accept payments in respect of goods the sale and supply of which commonly falls within
                    your business as identified to us;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    5.5.3 notify us in writing before you make any change to the nature of the goods the sale and supply
                    of which fall within your business as identified to us;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    5.5.4 only accept payments and submit Data to us in respect of Transactions which a Customer has
                    authorized in accordance with Applicable Law, this Contract and any other information or
                    instructions provided by us to you from time to time;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    5.5.5 ensure that you prominently and unequivocally inform Customers of our identity at all points
                    of interaction with a Customer (including prominently displaying the Company name and any trading
                    name on any website through which Transactions are conducted), so that the Customer can readily
                    identify the Company as the counterpart to the relevant Transaction; and
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    5.5.6 only submit Data to us directly from your own staff or systems, or via a Third Party Product,
                    which has been expressly approved by us in writing as one you are entitled to use to submit Data to
                    us.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>5.6</b> You must notify us promptly and, in any event, no later than twelve (12) calendar months
                    after becoming aware of any unauthorized or incorrectly executed Transactions.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>5.7</b> PCI DSS
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    5.7.1 You will adhere to and taken ownership of current PCI DSS responsibilities, there
                    implementation within the scope of your business, and be able to demonstrate that you are PCI DSS
                    compliant.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    5.7.2 You agree that if you suffer or suspect a data compromise you will inform us as soon as
                    reasonably possible.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    5.7.3 If we suspect a data compromise or you have told us of a data compromise you agree that if we
                    require you to, at your own cost, instruct an industry-approved forensic assessor to carry out a
                    forensic investigation of all relevant infrastructure. You will do so within the timescale we give
                    to you. You also agree that the industry-approved forensic assessor can reveal and give to us any
                    report they produce and will keep to all recommendations suggested to improve your data security.
                </Typography>
            </Grid>

            <Grid item xs={mediaLessThan600px ? 12 : 8} className={styles.container__text} id="Indemnity">
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>6 Indemnity</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>6.1</b> You shall hold us harmless from and against, and indemnify us in respect of any losses,
                    damages, costs or expenses which we may suffer or incur or other amounts for which we are liable
                    (including any penalty, fine, surcharge or costs related thereto) in connection with any Claims
                    brought against us by a Customer, Card Scheme, Financial Institution, regulatory authority or any
                    other Third Party, to the extent such Claims arise out of or in consequence of or in connection
                    with:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    6.1.1 a Transaction;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    6.1.2 the enforcement or attempted enforcement of this Contract;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    6.1.3 a failure by you to comply with the requirements of an Applicable Law; and
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    6.1.4 any reasonable steps taken in the protection of our interests in connection with:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rulesBullets}
                >
                    (a) any breach of the requirements of a Card Scheme or Applicable Law; or
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rulesBullets}
                >
                    (b) any allegation of fraud made in relation to you or your business, except, if and to the extent
                    such Claim is caused by our fraud or any breach of this Contract by us.
                </Typography>
            </Grid>
            <Grid item xs={mediaLessThan600px ? 12 : 8} className={styles.container__text} id="Condition_Precedent">
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>7 Condition precedent</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>7.1</b> It is a condition precedent of this Contract that at all relevant times, particularly at
                    the time of EPOS and prior to Transaction, all Customers provide you with their express and informed
                    consent for the Company to Process Personal Data and Transaction Personal Data in order for the
                    Company to provide you with the Services, particularly in respect of Data Analysis.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>7.2</b> All Customers must verify their personal details using the 3D secure process, or latest
                    industry standard, encompassing acquirer domain, issuer domain and interoperability domain to ensure
                    it is the Customer making the Order with you. To afford greater levels of protection for all parties
                    (including Customers), such online authentication is a condition precedent in respect of all
                    Transactions and E- commerce Transactions to supplement the additional level of security provided by
                    Streamline and Sage Pay.
                </Typography>
            </Grid>

            <Grid item xs={mediaLessThan600px ? 12 : 8} className={styles.container__text} id="Processing_Data">
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>8 Processing data</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>8.1</b> The Company warrants that, to the extent it processes any Personal Data on your behalf,
                    or your Customers:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    8.1.1 it shall act only on instructions from you; and
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    8.1.2 it has in place appropriate technical and organizational security measures against
                    unauthorized or unlawful processing of Personal Data and against accidental loss or destruction of,
                    or damage to, Personal Data.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>8.2</b> Whenever we request it, you shall give us reasonable assistance to facilitate the
                    successful collection and delivery of all Data.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>8.3</b> You represent, warrant and undertake that you have obtained and will continue to obtain
                    the specific and informed consent (and in the case of sensitive Personal Data, the explicit consent)
                    of all and any of the Customers (or any other relevant Persons) to the processing of their Personal
                    Data and Transaction Personal Data in the manner envisaged by this Contract.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>8.4</b> It is agreed that the Company shall be a Data Controller and the Company or its appointed
                    agents, servants, sub-contractors or any third parties used by the Company (as the case may be)
                    shall be a Data Processor in respect of Personal Data and/or Transaction Personal Data, as
                    applicable.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>8.5</b> We shall, to the extent that we are a Data Processor:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    8.5.1 take appropriate technical and organizational measures against unauthorized or unlawful
                    processing of, and accidental loss or destruction of, or damage to Personal Data and/or Transaction
                    Personal Data; and
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    8.5.2 only Process Personal Data and/or Transaction Personal Data as necessary for the purpose of
                    performing our obligations under this Contract and as otherwise envisaged in this Contract.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>8.6</b> We shall not store (as such term is used in the PCI SSC Standards), at any time:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    8.6.1 Card verification value in the magnetic stripe;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    8.6.2 Card verification value printed on the Card in or next to the signature panel;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    8.6.3 Card verification value contained in the magnetic stripe image in a chip application;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    8.6.4 PIN verification value contained in the magnetic stripe;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    8.6.5 the full contents of any track from the magnetic stripe (on a Card, in a chip or elsewhere);
                    or
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    8.6.6 any other Data that the Card Schemes mandate from time to time as Data that cannot be stored.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>8.7</b> In addition to complying with all record retention provisions under Applicable Law, and
                    subject to the requirements of the PCI SSC Standards, the Company shall retain legible copies of
                    Data for a minimum period of eighteen (18) months from the date of each Transaction.
                </Typography>
            </Grid>
            <Grid
                item
                xs={mediaLessThan600px ? 12 : 8}
                className={styles.container__text}
                id="Provision_and_Disclosure_of_Data"
            >
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>9 Provision and disclosure of data</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>9.1</b> During the course of providing the Services, particularly Merchant and Acquiring
                    Services, the Company may upon request from Barclaycard, Smartpay or Financial Institutions, be
                    requested to provide copies of Data:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    9.1.1 relating to the performance of the Services or obligations under this Contract or Applicable
                    Law;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    9.1.2 to assist in handling any Claim or query raised by a Customer, a Card issuer, a Card Scheme or
                    any other Third Party in relation to the Services or any Transaction;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    9.1.3 to co-operate in providing any Financial Institution with all information requested by it in
                    order for Transactions to be accepted by such Financial Institutions or otherwise to enable the
                    Company to provide you with any of the Services (or any part thereof);
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    9.1.4 You hereby authorize us and any Financial Institution (including the credit institution at
                    which the Company maintains the Merchant Bank Account) to use, share and release Data and any other
                    information relating to you, the Services or the Card Schemes (or, if instructed by us, you shall
                    provide such Data or information or procure that such Data or information is provided) to any
                    Person, Card issuers, Card Schemes, Third Parties, regulatory authorities, law enforcement agencies,
                    fraud prevention agencies and credit reference agencies:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rulesBullets}
                >
                    (a) for the purpose of fulfilling our obligations under the Contract or requirements of a Card
                    Scheme or otherwise as required by Applicable Law;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rulesBullets}
                >
                    (b) in relation to any breach of, or to enforce, this Contract;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rulesBullets}
                >
                    (c) to recover debt or in relation to your insolvency;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rulesBullets}
                >
                    (d) to develop customer relationships, services and systems;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rulesBullets}
                >
                    (e) to prevent and detect fraud or crime;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rulesBullets}
                >
                    (f) in the course of any investigation by the Company, or any Financial Institution or any Third
                    Party into any suspected criminal activity;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rulesBullets}
                >
                    (g) regarding information security, the risk of fraud, sector risk and credit risk; and
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rulesBullets}
                >
                    (h) to enable the Card Schemes to assign codes to any undesirable act or omission.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>9.2</b> You shall advise us in writing as soon as you become aware of any other agreement that
                    you enter into concerning your acceptance of Transactions.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>9.3</b> If you contact us electronically, we may collect your electronic identifier (for example,
                    Internet Protocol (IP) address or telephone number) supplied by your service provider.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>9.4</b> We may disclose information concerning you to Third Parties where we aggregate Data to
                    facilitate cross industry analysis and comparisons.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>9.5</b> The information which we and/or any Financial Institution collect from you may be
                    transferred to, processed and stored at, a destination outside the EEA.
                </Typography>
            </Grid>

            <Grid item xs={mediaLessThan600px ? 12 : 8} className={styles.container__text} id="Data_Analysis">
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>10 Data analysis</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>10.1</b> You shall provide the Company with Materials weekly, monthly, or otherwise as agreed.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>10.2</b> The Company shall produce Data Analysis Reports based upon the Materials you provide and
                    stored in the Merchant Data Account, which may entail reconciliation of payments processed by
                    Barclaycard and/or Smartpay into the Merchant Account against Orders and Customer Personal Data
                    and/or Transaction Personal Data.
                </Typography>
            </Grid>

            <Grid item xs={mediaLessThan600px ? 12 : 8} className={styles.container__text} id="Intellectual_Property">
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>11 Intellectual property</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>11.1</b> The Contract does not transfer, and is not intended to transfer, to any party any of the
                    Intellectual Property Rights that any other party owns at the Commencement Date or any Intellectual
                    Property Rights that are created, acquired or developed during the term of the Contract.
                    Notwithstanding the foregoing sentence, you hereby grant the Company a licence to such Intellectual
                    Property Rights in respect of Materials you provide to the extent required for the Company to
                    perform its obligations under this Contract.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>11.2</b> You shall not acquire any Intellectual Property Rights in any Merchant Data Account, any
                    Data, or Data Reports that we make available to you under this Contract. Notwithstanding the
                    foregoing sentence, the Company hereby grants you a non-exclusive licence to such Intellectual
                    Property Rights to the extent required to utilise the Services in accordance with this Contract.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>11.3</b> Each party shall obtain the written consent of the other Parties prior to using or
                    referring to any trade marks, logos, copyrighted materials, business names or other similar
                    Intellectual Property Rights in any promotional materials or literature, agreements or on any
                    website.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>11.4</b> On termination of the Contract, each Party shall remove any reference to the other
                    Parties from any promotional materials or literature, agreements or on any websites.
                </Typography>
            </Grid>
            <Grid
                item
                xs={mediaLessThan600px ? 12 : 8}
                className={styles.container__text}
                id="Confidential_Information"
            >
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>12 Confidential information</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>12.1</b> Except to the extent set out in this clause 12, each Party shall:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    12.1.1 treat as confidential all Confidential Information obtained from the other Parties under the
                    Contract;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    12.1.2 use the other Parties’ Confidential Information solely for the specific purposes for which it
                    was disclosed;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    12.1.3 not publish or otherwise disclose to any person the other Parties’ Confidential Information
                    without the owner’s prior written consent; and
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    12.1.4 take all action reasonably necessary to secure the other Parties’ Confidential Information
                    against theft, loss or unauthorized disclosure.
                </Typography>

                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>12.2</b> Each Party may disclose Confidential Information that would otherwise be subject to
                    clause 12.1 but only if it can demonstrate that the Confidential Information:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    12.2.1 is required to be disclosed by any court of competent jurisdiction, regulatory authority, or
                    by Applicable Law;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    12.2.2 was lawfully in its possession prior to disclosure to it by any other Party without an
                    obligation restricting disclosure;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    12.2.3 is already public knowledge or which becomes so at a future date (otherwise than as a result
                    of breach of this clause 12);
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    12.2.4 is received from a Third Party who is not under an obligation of confidentiality in relation
                    to the information; or
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    12.2.5 is developed independently without access to, or use or knowledge of, the Confidential
                    Information.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>12.3</b> Notwithstanding the provisions of clauses 12.1, 12.2 and 12.4, we and/or any Financial
                    Institution may aggregate and anonymise your Confidential Information (including Data), and disclose
                    it in that form to any Third Party.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>12.4</b> Other than as expressly permitted under this Contract, on termination of the Contract
                    for whatever reason, each Party shall forthwith cease to use any Confidential Information of the
                    other parties and shall return on demand, or at the request of the other, destroy or permanently
                    erase all copies of that Confidential Information in its possession or control, save that a party
                    will be permitted to retain such part of the Confidential Information for the purposes of and for so
                    long as required by any Applicable Law or its legitimate internal compliance requirements.
                </Typography>
            </Grid>

            <Grid
                item
                xs={mediaLessThan600px ? 12 : 8}
                className={styles.container__text}
                id="Acquiring_Services_and_Merchant_Services"
            >
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>13 Acquiring services and merchant services</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>13.1</b> The Company shall provide you the Merchant Services and Acquiring Services in respect of
                    your Customer Orders and processing Transactions and E-commerce Transactions via the Merchant
                    Account. The functions in respect of Acquiring Services being provided by Streamline with processing
                    of Sensitive Authentication Data being provided by Smartpay in compliance with PCI SSC standards.
                </Typography>
            </Grid>

            <Grid item xs={mediaLessThan600px ? 12 : 8} className={styles.container__text} id="Receipts">
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>14 Receipts</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>14.1</b> Following the value dating and crediting of Transactions and E-commerce Transactions by
                    Streamline to the Merchant Bank Account, we shall initiate or procure the initiation of each Receipt
                    by bank transfer to your Bank Account on the later of the following:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    14.1.1 on, or before twenty-one (21) Business Days after the Remittance Date; or
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    14.1.2 the expiry of any period of deferment in respect of relevant Transactions.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>14.2</b> We may defer any amount we are obliged to pay you:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    14.2.1 where we reasonably believe that the Transaction may be fraudulent or involves any criminal
                    activity, until the satisfactory completion of our investigation or that of any Financial
                    Institution or any Third Party; or
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    14.2.2 without limit in amount or time, if we become aware or reasonably believe that you are in
                    breach of or likely to be in breach of your obligations under this Contract.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>14.3</b> We shall notify you of any such action, the reasons for it and the procedure for
                    rectifying any factual errors that led to the refusal, unless we are prohibited from doing so under
                    the Applicable Law. Subject to reasonable security measures and Applicable Law, we will notify you
                    before any suspension of processing such affected Transaction if we are able to do so, or otherwise
                    immediately after such suspension. We may charge you the reasonable costs of any such notification.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>14.4</b> You shall not be entitled to receive interest, if any, paid by our bank service provider
                    in connection with funds held in the Merchant Bank Account and any such interest may be retained by
                    us.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>14.5</b> Receipts shall be paid in the currency or currencies agreed between you and us as set
                    out in the Application Form, or as otherwise agreed in writing by you and us from time to time. In
                    circumstances where it is necessary to apply currency conversion to Receipts, we shall be obliged to
                    apply the prevailing exchange rate based on the standard reference rate of the Royal Bank of
                    Scotland plc or such other reference rate as we notify to you.
                </Typography>
            </Grid>

            <Grid
                item
                xs={mediaLessThan600px ? 12 : 8}
                className={styles.container__text}
                id="Service_Adjustments_and_Contract_Variations"
            >
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>15 Service adjustments and contract variations</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>15.1</b> From time to time, we may adjust the content and interfaces of the Services, make
                    changes to the Services which are necessary to comply with any Applicable Law, or make changes which
                    do not materially affect the nature or quality of the Services. If such adjustments or changes lead
                    to a change in software, interfaces or operating procedures, we shall notify you as soon as
                    reasonably practicable prior to the implementation of such adjustments or changes.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>15.2</b> From time to time we may change the way we use your information (other than Transaction
                    Personal Data). Where we believe you may not reasonably expect such a change we shall write to you.
                    If you do not object to the change within thirty (30) Business Days, you will be deemed to consent
                    to that change.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>15.3</b> We shall be entitled to vary the provisions of the Contract from time to time by giving
                    you at least thirty (30) Business Days’ prior written notice. If we do this, you shall be entitled
                    to terminate the Contract immediately by providing written notice to us, provided that such notice
                    is served upon us within thirty (30) Business Days of you receiving the notice of variation.
                    Otherwise, you will be deemed to have accepted any variation of the provisions of this Contract
                    thirty (30) Business Days from receipt of the notice.
                </Typography>
            </Grid>

            <Grid item xs={mediaLessThan600px ? 12 : 8} className={styles.container__text} id="Level_of_Services">
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>16 Level of services</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>16.1</b> The provisions of this paragraph 16 shall apply to the service levels and support
                    applicable in respect of providing the Services.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>16.2</b> We shall use of best endeavor to provide 99.9% Uptime, with no planned outages, and be
                    responsible for all systems hosted on our servers and data centres that are{' '}
                    <b>within our direct control</b>.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>16.3</b> We will not be responsible for ensuring data is delivered correctly to your acquiring
                    bank or authorized processor in a way acceptable to the acquiring bank or authorized processor, nor
                    for any connection difficulties that are outside our control. We accept no responsibility for
                    service levels of your acquiring bank or any other authorized processors’ networks or any other
                    Third Party Product supplier, such as an ISP or other bandwidth provider of any description.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>
                        16.4 <i>Direct Support:</i>
                    </b>{' '}
                    Only you may contact us directly if you are experiencing any problems with the Services. Under no
                    circumstances must you encourage any of your customers to contact us directly, and we will not be
                    responsible for accepting any such contacts.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>
                        16.5 <i>Communication and Tools:</i>
                    </b>{' '}
                    On receipt of a non-critical incident notification, we will supply the relevant incident resolution
                    service and will use its reasonable endeavour to meet the service levels set out in paragraph 16.9
                    below. Critical incidents will be supported by us automatically in accordance with the service
                    levels set out in paragraph 16.9.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>
                        16.6 <i>Telephone:</i>
                    </b>{' '}
                    You should notify us of incidents initially by telephone. We will determine and notify you whether
                    or not the problem is directly concerned with the Services, determine the severity of the problem
                    and thereafter use our reasonable endeavor to remedy the problem within the timescale relevant to
                    the determined severity of the problem (as detailed in paragraph 16.9 below).
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>
                        16.7 <i>Email:</i>
                    </b>{' '}
                    You may notify us of incidents via e-mail, but not severity 1 incidents (as detailed in paragraph
                    16.9 below), unless follow-up information has been requested.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>
                        16.8 <i>Email:</i>
                    </b>{' '}
                    Severity 1 or 2 incidents (as detailed in paragraph 16.9 below) that have been notified to us by
                    telephone should be followed up with an email notification.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>
                        16.9 <i>Severity and Targets:</i>
                    </b>{' '}
                    The following definitions and targets apply for incident reporting and resolution:
                    <br />
                    <b>Severity Definition Targets</b>
                </Typography>
                <div className={styles.container__text__rules}>
                    <Typography variant="body1" display="block" align="center" paragraph>
                        Severity 1
                    </Typography>
                    <Typography variant="body1" display="block" align="left" paragraph>
                        <b>Critical</b> incident: Business impact on you is critical. You are unable to use any of the
                        Services; productivity is severely impacted. This shall include the ability to authorize and
                        process Transactions, with accuracy, within the part of the authorization process that is within
                        our direct control.
                    </Typography>
                    <ul>
                        <li>Resolution within 2 hours</li>
                        <li>Confirmation of receipt in less than 30 minutes</li>
                        <li>Initial response indicating anticipated resolution time less than 30 minutes</li>
                        <li>Supported 24 x 7 x 365</li>
                    </ul>
                </div>
                <div className={styles.container__text__rules}>
                    <Typography variant="body1" display="block" align="center" paragraph>
                        Severity 2
                    </Typography>
                    <Typography variant="body1" display="block" align="left" paragraph>
                        <b>High</b> incident: You are unable to use certain of the Services (such as processing
                        Transactions); productivity is affected. This shall include any material adverse effect on the
                        speed of transaction response, which is within our direct control.
                    </Typography>
                    <ul>
                        <li>Resolution within 3 to 8 hours following notification.</li>
                        <li>Confirmation of receipt in less than 30 minutes</li>
                        <li>
                            Initial response indicating anticipated resolution time less than one hour and contingency
                            support for affected service
                        </li>
                        <li>Supported 24 x 7 x 365</li>
                    </ul>
                </div>
                <div className={styles.container__text__rules}>
                    <Typography variant="body1" display="block" align="center" paragraph>
                        Severity 3
                    </Typography>
                    <Typography variant="body1" display="block" align="left" paragraph>
                        <b>Normal</b> incident: You are able to use the Services; minor effect on productivity.
                    </Typography>
                    <ul>
                        <li>
                            Resolution / support provided within 4 hours response from first notification. Provided if
                            such notification is <b>not during Office Hours</b> within 4 business hours commencing on
                            the next Business Day following the first notification.
                        </li>
                    </ul>
                </div>
                <div className={styles.container__text__rules}>
                    <Typography variant="body1" display="block" align="center" paragraph>
                        Severity 4
                    </Typography>
                    <Typography variant="body1" display="block" align="left" paragraph>
                        <b>Low</b> incident: You are able to use the Services, no impact on productivity. This also may
                        include features that may or may not be fixed within the next revision.
                    </Typography>
                    <ul>
                        <li>
                            Resolution within 5 days from first notification provided if such notification is{' '}
                            <b>not during Office Hours</b> within 5 Business Days commencing on the next Business Day
                            following the first notification.
                        </li>
                        <li> Workaround or fix may or may not be included in next scheduled revision.</li>
                    </ul>
                </div>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>16.10</b> The targets for the delivery of these response and resolution times are 98% for any
                    incident. 98% resolution means that we will use our reasonable endeavour to resolve 98% of Severity
                    1, 2, 3 or 4 (as applicable) problems within the specified time scales set out above.
                </Typography>
            </Grid>
            <Grid
                item
                xs={mediaLessThan600px ? 12 : 8}
                className={styles.container__text}
                id="Exclusion_and_Limitation_of_Liability"
            >
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>17 Exclusion and limitation of liability</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>17.1</b> Subject to the provisions of clauses 17.2 to 17.5 inclusive, each party shall only be
                    liable for loss or damage directly arising out of or in connection with its own breach of this
                    Contract, negligence or wilful misconduct, provided that nothing in this Contract shall operate to
                    exclude or limit a Party’s liability:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    17.1.1 for losses suffered by the innocent party arising out of the other party’s (or its
                    personnel’s) fraud, fraudulent misrepresentation or wilful misconduct;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    17.1.2 for death or personal injury resulting from a party’s negligence or that of its personnel;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    17.1.3 any breach of any obligations implied by the Sale of Goods Act 1979 or the Supply of Goods
                    and Services Act 1982, or other Applicable Law; or
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    17.1.4 any other liability to the extent which it cannot be lawfully excluded or limited.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>17.2</b> Subject to clause 17.1, you shall not be entitled to any interest or any other
                    compensation whatsoever in respect of any sums held by us in accordance with this Contract prior to
                    Receipts being transferred to you for any period for which payment may be deducted, withheld,
                    deferred or not paid under clause 14.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>17.3</b> Each of the following shall apply in respect to our liability:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    17.3.1 We shall not be liable for any delay or failure to carry out any of our obligations under
                    this Contract if and to the extent that such failure is due to:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rulesBullets}
                >
                    (a) circumstances beyond our reasonable control;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rulesBullets}
                >
                    (b) any cessation or interruption of any part of the Services which are due to any act or omission
                    of a Card Scheme, or Financial Institution and is not caused by our breach of this Contract;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rulesBullets}
                >
                    (c) us complying with any Applicable Law;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rulesBullets}
                >
                    (d) your failure to provide complete and/or correct Data to us and/or your breach of this Contract
                    (provided that we may make reasonable efforts to recover any funds paid by us as a result of such
                    failure and may make a reasonable charge to you for doing so); or
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rulesBullets}
                >
                    (e) a suspension of Services by us under clause 18.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    17.3.2 The liability of the Company to you in relation to all Claims arising out of, or in
                    connection with the Services or this Contract shall be limited to an amount equivalent to the
                    average monthly Payments due to us in the period from the Commencement Date to the first event
                    giving rise to such Claim, multiplied by twelve (12).
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>17.4</b> The Company and any other party providing the Services:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    17.4.1 is providing its element of the Services as an independent contractor, and not as a partner
                    or agent of or joint venturer with the other parties;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    17.4.2 shall be only severally liable in respect of its own obligations under this Contract; and
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    17.4.3 shall not be liable in connection with the Services provided by the other parties, whether
                    jointly, jointly and severally or at all.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>17.5</b> The following additional exclusions and limitations apply in relation to the Services:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    17.5.1 We accept no responsibility, and shall not be liable for (a) the accuracy or reliability of
                    any Data you send to us; (b) our interpretation of that Data; or (c) the consequences or accuracy of
                    our interpretation of that Data or any subsequent interpretation or other assessment you undertake
                    in relation to that Data.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    17.5.2 We do not warrant that Data Reports are accurate, up-to-date, reliable or error-free.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    17.5.3 We accept no responsibility, and shall not be liable for any Third Party Product you use in
                    connection with the Services, and any reference by us to a Third Party Product (including in any
                    technical specification we provide to you) shall not constitute any recommendation or endorsement by
                    us of that Third Party Product, or any warranty or representation that such Third Party Product will
                    be suitable for your use or will deliver any specific result.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    17.5.4 We accept no responsibility for any losses that you may incur as a result of any Downtime in
                    provision of the Services caused by the failure, or omissions to act by Third Parties including, but
                    not limited to Customer Card issuers, Card issuers, Financial Institutions, Barclaycard, Smartpay,
                    or any other service providers in respect of processing Orders, Transaction and E- commerce
                    Transactions at anytime, particularly <b>outside of Office Hours</b>.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    17.5.5 You are responsible for all losses resulting from if you have acted fraudulently or you have
                    either intentionally or through negligence failed to comply with the provisions of this Contract
                    (including failing to notify us of the incorporate transaction).
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    17.5.6 For the avoidance of doubt, if you are a partnership, each of your partners shall be jointly
                    and severally liable with the other(s) to perform your obligations under this Contract.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    17.5.7 You acknowledge and agree that, given the nature of the Services, the availability to you of
                    suitable alternative payment methods for the Customers and your ability to choose other providers of
                    services similar to the Services before entering into this Contract, the limitations on liability
                    contained in this clause 17 are reasonable in all the circumstances and that the Payments have been
                    calculated taking into account such limitations (which would be higher but for such limitations) and
                    accordingly you have accepted the risk of any losses which you may suffer because of the limitation
                    on our liability under this clause 17.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    17.5.8 No party shall be liable to any other party for any loss of profits, loss of Data, loss of
                    customer business or goodwill, losses incurred by third parties or any indirect or consequential
                    loss or damage, howsoever arising. Subject to clauses 17.1 and 17.3, we shall have no liability to
                    you for any inaccuracy in the information we or any Financial Institution provide to any third
                    parties pursuant to clause 9.
                </Typography>
            </Grid>
            <Grid item xs={mediaLessThan600px ? 12 : 8} className={styles.container__text} id="Term_and_Termination">
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>18 Term and termination</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>18.1</b> This Contract shall come into force on the Commencement Date and, unless otherwise
                    terminated earlier in accordance with any provision of this Contract, continue thereafter until it
                    is terminated in accordance with clause 18.2.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>18.2</b> For the purposes of termination by notice under clause 18.1, you may terminate this
                    Contract:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    18.2.1 during the Initial Period, by giving at least sixty (60) Business Days’ written notice to the
                    Company, such notice to expire no later than the last day of the Initial Period;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    18.2.2 after the Initial Period, by giving at least sixty (60) Business Days’ written notice to the
                    Company, such notice to expire no earlier than the last day of the rolling twelve (12) month period;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    18.2.3 we may terminate this contract at any time by giving you thirty (30) Business Days’ prior
                    written notice.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>18.3</b> If having served a notice pursuant to clause 18.2.2 you wish the Contract to terminate
                    prior to the end of the rolling twelve (12) month period, you shall pay to the Company all Payments
                    that would otherwise be due up to the end of the Term, on receipt of which the Company will confirm
                    that the Contract has been terminated.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>18.4</b> You may terminate this Contract or any Service with immediate effect by giving written
                    notice to us if any of us:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    18.4.1 commits a material breach of this Contract which, if capable of remedy, is not remedied to
                    your reasonable satisfaction within twenty-one (21) Business Days of service of a notice requiring
                    such remedy;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    18.4.2 is insolvent;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    18.4.3 is the subject of a petition, order, or resolution or any step in connection with winding up
                    (whether solvent or insolvent).
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>18.5</b> We may terminate this Contract or any Service, or suspend the provision of any Service
                    with immediate effect by giving written notice if you:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    18.5.1 commit a material breach of this Contract which:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rulesBullets}
                >
                    (a) is not, in our reasonable opinion, capable of remedy; or
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rulesBullets}
                >
                    (b) if capable of remedy, is not remedied to our reasonable satisfaction within twenty-one (21) days
                    of service of the notice requiring such remedy;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    18.5.2 you commit a breach clause 7;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    18.5.3 you default in any of your payment obligations;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    18.5.4 are insolvent, or being an individual, you have a petition presented for your bankruptcy;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    18.5.5 are the subject of a petition, order, or resolution or any step in connection with winding up
                    (whether solvent or insolvent);
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    18.5.6 cease or threaten to cease to carry on all or a material part of your business, except for
                    the purpose of a bona-fide reconstruction, amalgamation, reorganization, merger or consolidation;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    18.5.7 begin negotiations or proceedings, or propose or agree to defer, reschedule or readjust your
                    debts;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    18.5.8 propose or make a general assignment of your debts or an arrangement or composition with or
                    for the benefit of some or all of your creditors in respect of all or all of a particular type of
                    your debts;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    18.5.9 agree to a moratorium, or a moratorium is agreed or declared in respect of all or a material
                    part of (or a particular type of) your debts;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    18.5.10 are the subject of a petition for an administration order or an application for an
                    administration order, or an administrator is appointed to you or notice of intention to appoint an
                    administrator to you is given, or any other step is taken by any person with a view to the
                    administration of you under the Insolvency Act 1986, or other Applicable Law, including the passing
                    of any resolution by your directors or shareholders approving the presentation of any such petition,
                    the making of any such application or appointment or the giving of any such notice;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    18.5.11 are the subject of any step to enforce security over, or a distress, execution or other
                    similar process is levied or served against, the whole or a substantial part of your assets or
                    undertaking, including the appointment of a receiver, administrative receiver, manager or similar
                    officer to enforce that security;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    18.5.12 suffer or are subject to any equivalent event, circumstance or procedure to those set out
                    above in this clause 18.5.2 to 18.5.11 (inclusive) in any other jurisdiction;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    18.5.13 act in a manner, or if anything happens to you or comes to our attention in relation to you
                    or arising from or incidental to your business or the conduct of your business (including trading
                    practices or any individual’s activity), that we in our reasonable discretion consider:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rulesBullets}
                >
                    (a) to be disreputable or capable of damaging the reputation of us or that of any Card Scheme or a
                    Financial Institution; or
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rulesBullets}
                >
                    (b) to be detrimental to our systems, business or that of any Card Scheme or Financial Institution;
                    or
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rulesBullets}
                >
                    (c) may or does give rise to fraud or any other criminal activity or suspicion of fraud or any other
                    criminal activity; or
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rulesBullets}
                >
                    (d) may or does give rise to increased risk of loss or liability to any of us; or
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rulesBullets}
                >
                    (e) may affect your ability or willingness to comply with all or any of your obligations or
                    liabilities under this Contract.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>18.6</b> We may terminate this Contract, or any Service, or suspend the provision of any Service
                    with immediate effect by giving written notice if:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    18.6.1 we or any Financial Institution becomes entitled to enforce any guarantee or security from or
                    in relation to you;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    18.6.2 we are required to do so by any Card Scheme or regulatory authority or Applicable Law;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    18.6.3 a Card Scheme, any Third Party or any Financial Institution ceases to provide us with any
                    Merchant or Acquiring Services necessary for us to provide a Service to you; or
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    18.6.4 any of the above provisions of clauses 18.5.2 to 18.5.9.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>18.7</b> You shall inform us upon becoming aware of any of the events set out in the following
                    clauses: clauses 18.6.2 to 18.6.11 (inclusive).
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>18.8</b> Where any party serves notice to terminate this Contract pursuant to clause 18.2, we
                    shall during the relevant period prior to termination provide reasonable assistance to you, or use
                    our reasonable endeavor to ensure that you are provided with reasonable assistance, for you to make
                    arrangements for the processing of the relevant transactions by another service provider. We may
                    charge you for providing such assistance and if so you shall be liable to pay us for any costs
                    reasonably incurred in so doing.
                </Typography>
            </Grid>
            <Grid
                item
                xs={mediaLessThan600px ? 12 : 8}
                className={styles.container__text}
                id="Assignment,_Sub-contracting_and_Novation"
            >
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>19 Assignment, sub-contracting and novation</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>19.1</b> The Contract is personal to you and you may not assign, novate or transfer it or any of
                    your rights or obligations under it.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>19.2</b> You shall be liable to us for the acts or omissions of any of your Personnel in the
                    course of or relating to the performance of your obligations under the Contract or arising out of or
                    in connection with any Transaction.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>19.3</b> Subject to Applicable Law, we shall be entitled at any time to assign or transfer the
                    Contract or the benefit of any or all of our rights under the Contract and/or to sub-contract our
                    obligations under the Contract without your consent. Upon request, you shall execute any documents
                    required to effect any such assignment, transfer or subcontract.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>19.4</b> We shall be entitled to novate any or all of our rights and obligations (as appropriate)
                    under the Contract to a Third Party at any time on giving you at least thirty (30) Business Days’
                    notice. If we do this you shall be entitled to terminate the Contract within thirty (30) Business
                    Days’ of you receiving the notice of the novation. You will be deemed to have accepted the novation
                    of the Contract thirty (30) Business Days from receipt of the notice.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>19.5</b> With effect from the date that we novate our obligations under the Contract to a Third
                    Party (the “Novation Date”), you shall release and discharge us from further performance of our
                    obligations under the Contract and from all claims and demands against us, whatsoever arising out of
                    or in respect of the Contract, whether prior to, on or subsequent to the Novation Date and the Third
                    Party shall perform, or procure the performance of, all such obligations under the Contract, and
                    shall accept all liabilities arising out of or in respect of the Contract, from the Novation Date.
                </Typography>
            </Grid>
            <Grid item xs={mediaLessThan600px ? 12 : 8} className={styles.container__text} id="Waiver">
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>20 Waiver</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>20.1</b> No failure or delay by a party in exercising any of its rights or remedies provided
                    under the Contract or under Applicable Law shall be construed as a waiver or release of that right
                    or any other right or remedy, nor shall it preclude or restrict the further exercise of that or any
                    other right or remedy. The parties agree and acknowledge that the doctrine of affirmation, by which
                    a party is deemed to have affirmed a decision to proceed with a contract notwithstanding the
                    enlivening of a right to terminate, shall have no application to the Contract.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>20.2</b> No single or partial exercise of any of a party’s rights or remedies under the Contract
                    or under Applicable Law shall preclude or restrict the further exercise of such right or remedy. A
                    waiver of any breach of any provisions of the Contract shall not constitute a waiver of any other
                    breach, and shall not affect the other provisions, of the Contract.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>20.3</b> Subject to clause 22.2, the rights and remedies of a party under the Contract are
                    cumulative and not exclusive of each other or of any rights or remedies provided by Applicable Law.
                </Typography>
            </Grid>
            <Grid item xs={mediaLessThan600px ? 12 : 8} className={styles.container__text} id="Notices">
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>21 Notices</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>21.1</b> Subject to clause 21.2, any notice to be given under or in connection with the Contract
                    shall be in writing and signed by or on behalf of the Party giving it and shall be served by
                    delivering it personally or sending it by post (or registered airmail in the case of an address for
                    service outside the United Kingdom) or by email or by facsimile to the postal address, email address
                    or fax number of the other Party as set out in this Contract or otherwise as notified by such Party
                    from time to time. For the avoidance of doubt, any notice delivered by email shall not need to be
                    signed.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>21.2</b> Unless otherwise agreed in writing, notice from you to us to terminate the Contract must
                    be delivered by post.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>21.3</b> Any notice given in accordance with clauses 21.1 or 21.2 shall be deemed to have been
                    received:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    21.3.1 if sent by email, on the day on which the communication is sent and no report of non-delivery
                    is received by the sender, provided that (i) any notice despatched after 17:00 hours on any Business
                    Day or at any time on a day which is not a Business Day shall be deemed to have been given at 09:00
                    on the next Business Day;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    21.3.2 if delivered personally, at the time of delivery;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    21.3.3 if sent by first class post within the United Kingdom, two (2) Business Days from the date of
                    posting;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    21.3.4 if sent by second class post within the United Kingdom, four (4) Business Days from the date
                    of posting;
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    21.3.5 in the case of registered airmail, seven (7) Business Days from the date of posting; and
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    21.3.6 in the case of facsimile, on the date it is transmitted subject to confirmation of
                    uninterrupted transmission by a transmission report, provided that (i) any notice despatched after
                    17:00 hours on any Business Day or at any time on a day which is not a Business Day shall be deemed
                    to have been given at 09:00 on the next Business Day.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>21.4</b> A party may specify (by giving notice to the other parties in accordance with this
                    clause 21) a particular individual or office holder to whom any notices to be served are to be
                    addressed, in which case a notice shall not be validly given unless so addressed.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>21.5</b> The provisions of this clause shall not apply to the service of any proceedings or other
                    documents in any legal action.
                </Typography>
            </Grid>
            <Grid item xs={mediaLessThan600px ? 12 : 8} className={styles.container__text} id="Entire_Agreement">
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>22 Entire agreement</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>21.1</b> The Contract constitutes the entire agreement and understanding between you and us in
                    respect of its subject matter and supersedes and invalidates all other prior representations,
                    arrangements, understandings and agreements relating to the same subject matter, (whether oral or in
                    writing, express or implied), other than any securities or written pledges, undertakings or
                    assurances which you may previously have given to us as a condition precedent or in anticipation of
                    the Contract. Each party acknowledges that in entering into this Contract it does not rely on any
                    statement, representation, warranty or understanding other than those expressly set out in this
                    Contract.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>21.2</b> No party shall have any claim for innocent or negligent misrepresentation based upon any
                    statement in this Contract.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>21.3</b> Nothing in this clause 22 shall operate to exclude any liability for fraud.
                </Typography>
            </Grid>
            <Grid item xs={mediaLessThan600px ? 12 : 8} className={styles.container__text} id="Severability">
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>23 Severability</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>23.1</b> If any provision of the Contract or any part of it is held by any court or
                    administrative body of competent jurisdiction to be illegal, invalid or unenforceable:
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    23.1.1 the legality, validity and enforceability of the remainder of the Contract shall not be
                    affected; and
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__subRules}
                >
                    23.1.2 if such provision would cease to be illegal, invalid or unenforceable if some part of that
                    provision were modified or deleted, the provision in question shall apply with the least such
                    modification or deletion as may be necessary to make the provision legal, valid and enforceable.
                </Typography>
            </Grid>
            <Grid item xs={mediaLessThan600px ? 12 : 8} className={styles.container__text} id="Miscellaneous">
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>24 Miscellaneous</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>24.1</b> Nothing in the Contract shall be construed as constituting a partnership, joint venture
                    or agency between or among the parties.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>24.2</b> This Contract is not intended to confer any benefit on any Third Party and no Person who
                    is not party to the Contract shall have any rights to enforce any provisions of the Contract.
                    Notwithstanding the foregoing sentence, the rights of the parties to terminate, rescind or agree any
                    variation, waiver or settlement under this Contract are not subject to the consent of any Third
                    Party.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>24.2</b> This Contract is in the English language. We are only obliged to communicate with you in
                    English. We may provide to you a foreign language translation of this Contract or any other
                    communication (at your cost), provided that such translation shall be for your information purposes
                    only and in the event of any inconsistency between the English version and the foreign language
                    version, the English version shall prevail.
                </Typography>
            </Grid>
            <Grid
                item
                xs={mediaLessThan600px ? 12 : 8}
                className={styles.container__text}
                id="Dispute_Resolution_Procedure"
            >
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>25 Dispute Resolution Procedure</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>25.1</b> Subject to the provisions of clause 27, if any dispute between you and us (each a
                    “Disputing Party”) arises out of or in connection with this Contract or its subject matter,
                    formation, validity or enforceability (including non-contractual claims) (each a “Dispute”) then,
                    except as expressly provided in this Contract, the Disputing Parties shall follow the dispute
                    resolution procedure set out in this clause.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>25.2</b> Either Disputing Party shall give to the other written notice of the Dispute, setting
                    out its nature and full particulars (“Dispute Notice”), together with any relevant supporting
                    documentation. Following service of the Dispute Notice, representatives of each of the Disputing
                    Parties shall attempt in good faith to resolve the Dispute.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>25.3</b> If the representatives of the Disputing Parties are for any reason unable to resolve the
                    Dispute with fourteen (14) Business Days of service of the Dispute Notice, either Disputing Party
                    shall be entitled to commence proceedings under clause 31.2.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>25.4</b> If the Dispute is resolved by the Representatives within fourteen (14) Business Days of
                    service of the Dispute Notice in accordance with clause.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>25.5</b> The settlement shall be recorded in writing and signed by each of the Disputing Parties
                    within seven (7) Business Days of the end of the period referred to in clause 25.2.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>25.6</b> Nothing in this clause 30 shall prevent either Disputing Party making any application
                    for injunctive relief that it considers necessary to protect its position.
                </Typography>
            </Grid>
            <Grid
                item
                xs={mediaLessThan600px ? 12 : 8}
                className={styles.container__text}
                id="Governing_law_and_jurisdiction"
            >
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>26 Governing law and jurisdiction</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>26.1</b> This Contract and any Dispute, shall be governed by and construed in accordance with
                    laws of the Island of Guernsey.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>26.2</b> Subject to the provisions of clause 25, the parties irrevocably agree, for our sole
                    benefit that, subject as provided below, the Guernsey Courts shall have exclusive jurisdiction over
                    any Dispute. Nothing in this clause shall limit our right to take proceedings against you in any
                    other court of competent jurisdiction, nor shall the taking of proceedings in any one or more
                    jurisdictions preclude the taking of proceedings by us in any other jurisdiction, whether
                    concurrently or not, to the extent permitted by the law of such other jurisdiction. You waive any
                    objection to any proceedings in such courts pursuant to this clause 26.2 on the grounds of venue or
                    on the grounds that proceedings have been brought in an inappropriate forum. Any proceedings brought
                    by you against us in respect of a Dispute must be brought in the Guernsey Courts.
                </Typography>
            </Grid>
            <Grid item xs={mediaLessThan600px ? 12 : 8} className={styles.container__text} id="Complaints">
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>27 Complaints</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>27.1</b> If you are not satisfied with our Services, you must initiate our complaints handling
                    procedure to resolve such matters. For more information about this process please telephone us or
                    visit such URL as we notify to you from time to time.
                </Typography>
            </Grid>
            <Grid
                item
                xs={mediaLessThan600px ? 12 : 8}
                className={styles.container__text}
                id="Interpretation_and_Construction"
            >
                <Typography
                    variant="h5"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__ruleTitle}
                >
                    <b>28 Interpretation and construction</b>
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>28.1</b> Except where the context otherwise requires, words denoting the singular include the
                    plural and vice versa, words denoting a gender include every gender and references to persons
                    include bodies corporate and unincorporated.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>28.2</b> A party or parties means a party or parties to this Contract.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>28.3</b> References to Recitals and Clauses are, unless the context otherwise requires,
                    references to recitals and clauses hereof hereto and references to sub-clauses are, unless otherwise
                    stated, references to the sub-clause of the Clause in which the reference appears.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>28.4</b> The Recitals form part of this Contract and shall have the same force and effect as if
                    they were expressly set out in the body of this Contract and any reference to this Contract shall
                    include the Recitals.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>28.5</b> Any reference to this Contract or to any Contract or document referred to in this
                    Contract shall be construed as reference to such Contract or document as amended, varied, modified,
                    supplemented, restated, novated or replaced from time to time.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>28.6</b> Any reference to any statute or statutory provision shall, unless the context otherwise
                    requires, be construed as a reference to such statute or statutory provision as the same may have
                    been or may from time to time be amended, modified, extended, consolidated, re- enacted or replaced.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>28.7</b> Clause headings are inserted for convenience only and shall not affect the construction
                    of this Contract.
                </Typography>
                <Typography
                    variant="body1"
                    align="left"
                    display="block"
                    paragraph
                    className={styles.container__text__rules}
                >
                    <b>28.8</b> In this Contract, unless the context otherwise requires, the following expressions shall
                    have the following meaning:
                </Typography>
                <TableContainer
                    component={Paper}
                    className={`${styles.container__text__subRules} ${styles.container__text__table}`}
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className={styles.container__text__subRules__cell}>
                                    Expression
                                </TableCell>
                                <TableCell align="left">Meaning</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {expressionMeaningRows.map((row) => (
                                <TableRow key={row.expression}>
                                    <TableCell>{row.expression}</TableCell>
                                    {row.child ? (
                                        isDatman ? (
                                            <TableCell>{row.meaning.datman}</TableCell>
                                        ) : (
                                            <TableCell>{row.meaning.omniPay}</TableCell>
                                        )
                                    ) : (
                                        <TableCell>{row.meaning}</TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}

export default TermsAndConditionsPageComponent;
