import React from 'react';
import PropTypes from 'prop-types';
import style from './audit-data.module.scss';
import { getComparator, stableSort, formatDateTimeByFormatString } from '../../../utils/helper';
import { AuthService } from 'services';
import { Redirect } from 'react-router-dom';
import { useGlobalStateContext } from 'contexts';
import { auditDataEnum } from 'utils/enums/auditEnum';
import TableHeader from '../../../components/payments/transaction-tables/table-header/table-header.container.js';
import { auditIncludedField, auditDocumentIncludedField, auditMerchantIncludedField } from 'utils/enums/auditEnum';
import {
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableContainer,
    Paper,
    CircularProgress,
    TableFooter,
    TablePagination,
    Box,
    makeStyles,
    Button,
    TextField,
    Collapse,
    Tooltip
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    tableCellSmallSize: {
        '& td': {
            minWidth: '230px',
            maxWidth: '230px'
        },
        '& th': {
            minWidth: '230px',
            maxWidth: '230px'
        }
    },
    noData: {
        textAlign: 'center',
        paddingBottom: '100px',
        paddingTop: '100px'
    },
    tableRow: {
        '&$selected, &$selected:hover': {
            backgroundColor: '#e4e1fe'
        }
    },
    headWidth: {
        minWidth: '210px'
    }
}));

function AuditDataComponent(props) {
    const {
        loading,
        error,
        page,
        rowsPerPage,
        rows,
        handleChangePage,
        handleChangeRowsPerPage,
        count,
        order,
        orderBy,
        setInputValue,
        onSearchMerchants,
        open,
        setOpen
    } = props;
    const classes = useStyles();
    const { getRoute } = useGlobalStateContext();
    return (
        <React.Fragment>
            {AuthService.isLoggedUser() ? (
                <div className={style.container}>
                    <form className={style.search_form} onSubmit={onSearchMerchants}>
                        <TextField
                            className={style.searchMerchants}
                            id="merchants-search"
                            size="small"
                            label="Search"
                            type="search"
                            variant="outlined"
                            autoComplete="off"
                            onChange={(e) => setInputValue(e.target.value)}
                        />
                        <Button
                            id="merchants-search-button"
                            className={style.searchButton}
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={onSearchMerchants}
                        >
                            Search
                        </Button>
                    </form>
                    <Paper className={style.TableContainer}>
                        {loading ? (
                            <Box textAlign="center" py={5}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <React.Fragment>
                                <TableContainer>
                                    <Table
                                        aria-label="simple table"
                                        aria-labelledby="tableTitle"
                                        size="small"
                                        stickyHeader
                                        component={Paper}
                                        sx={{ minWidth: 650 }}
                                        className={classes.tableCellSmallSize}
                                    >
                                        <TableHeader classes={classes} {...props} />
                                        <React.Fragment>
                                            {error ? (
                                                <div className={style.noneData}>
                                                    An error occurred, please try reloading your browser.
                                                </div>
                                            ) : !count || count === 0 ? (
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell
                                                            className={classes.noData}
                                                            colSpan={8}
                                                            align="center"
                                                        >
                                                            No Records found
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            ) : (
                                                <TableBody>
                                                    {stableSort(rows, getComparator(order, orderBy)).map(
                                                        (row, index) => {
                                                            return (
                                                                <React.Fragment>
                                                                    <TableRow
                                                                        classes={{ selected: classes.selected }}
                                                                        style={{
                                                                            backgroundColor:
                                                                                open === index
                                                                                    ? 'rgb(244, 244, 244)'
                                                                                    : ''
                                                                        }}
                                                                        onClick={(e) => {
                                                                            setOpen(open === index ? '' : index);
                                                                        }}
                                                                        key={row.id}
                                                                        hover
                                                                        className={style.rowstyle}
                                                                    >
                                                                        <TableCell className={style.tableDataCell}>
                                                                            {row.name}
                                                                        </TableCell>
                                                                        <TableCell className={style.tableDataCell}>
                                                                            {row.mid}
                                                                        </TableCell>
                                                                        <TableCell className={style.tableDataCell}>
                                                                            {row.userName}
                                                                        </TableCell>
                                                                        <TableCell>{row.ip_address}</TableCell>
                                                                        <TableCell>
                                                                            {formatDateTimeByFormatString(
                                                                                row.updated_date,
                                                                                'DD MMM HH:mm'
                                                                            )}
                                                                        </TableCell>
                                                                        <TableCell>{row.function_name}</TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell
                                                                            style={{ padding: 0, border: 0 }}
                                                                            colSpan={6}
                                                                        >
                                                                            <Collapse in={open === index} unmountOnExit>
                                                                                <Box sx={{ margin: 1 }}>
                                                                                    <Table
                                                                                        aria-label="simple table"
                                                                                        aria-labelledby="tableTitle"
                                                                                        size="small"
                                                                                        stickyHeader
                                                                                        sx={{ minWidth: 650 }}
                                                                                        className={
                                                                                            classes.tableCellSmallSize
                                                                                        }
                                                                                    >
                                                                                        <TableHead>
                                                                                            <TableRow>
                                                                                                <TableCell
                                                                                                    className={
                                                                                                        classes.tableCell
                                                                                                    }
                                                                                                >
                                                                                                    Field Modified
                                                                                                </TableCell>
                                                                                                <TableCell
                                                                                                    className={
                                                                                                        classes.tableCell
                                                                                                    }
                                                                                                >
                                                                                                    Old Value
                                                                                                </TableCell>
                                                                                                <TableCell
                                                                                                    className={
                                                                                                        classes.tableCell
                                                                                                    }
                                                                                                >
                                                                                                    New Value
                                                                                                </TableCell>
                                                                                                <TableCell
                                                                                                    className={
                                                                                                        classes.tableCell
                                                                                                    }
                                                                                                >
                                                                                                    Table Name
                                                                                                </TableCell>
                                                                                                <TableCell
                                                                                                    className={
                                                                                                        classes.tableCell
                                                                                                    }
                                                                                                >
                                                                                                    {' '}
                                                                                                </TableCell>
                                                                                                <TableCell
                                                                                                    className={
                                                                                                        classes.tableCell
                                                                                                    }
                                                                                                >
                                                                                                    {' '}
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        <TableBody>
                                                                                            {row.auditData.map(
                                                                                                (data) => {
                                                                                                    return (
                                                                                                        <TableRow
                                                                                                            key={
                                                                                                                data.id
                                                                                                            }
                                                                                                        >
                                                                                                            {auditIncludedField.includes(
                                                                                                                data.table_name
                                                                                                            ) &&
                                                                                                            (auditDocumentIncludedField.includes(
                                                                                                                data.field_modified
                                                                                                            ) ||
                                                                                                                auditMerchantIncludedField.includes(
                                                                                                                    data.field_modified
                                                                                                                )) ? (
                                                                                                                <React.Fragment>
                                                                                                                    <TableCell>
                                                                                                                        {
                                                                                                                            data.field_modified
                                                                                                                        }
                                                                                                                    </TableCell>
                                                                                                                    <TableCell
                                                                                                                        className={
                                                                                                                            style.toolTipCell
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {data.current_value ? (
                                                                                                                            <Tooltip
                                                                                                                                title={
                                                                                                                                    auditDataEnum[
                                                                                                                                        data
                                                                                                                                            .table_name
                                                                                                                                    ][
                                                                                                                                        data
                                                                                                                                            .field_modified
                                                                                                                                    ][
                                                                                                                                        data
                                                                                                                                            .current_value
                                                                                                                                    ]
                                                                                                                                }
                                                                                                                                placement="bottom-start"
                                                                                                                            >
                                                                                                                                <span>
                                                                                                                                    {
                                                                                                                                        auditDataEnum[
                                                                                                                                            data
                                                                                                                                                .table_name
                                                                                                                                        ][
                                                                                                                                            data
                                                                                                                                                .field_modified
                                                                                                                                        ][
                                                                                                                                            data
                                                                                                                                                .current_value
                                                                                                                                        ]
                                                                                                                                    }
                                                                                                                                </span>
                                                                                                                            </Tooltip>
                                                                                                                        ) : (
                                                                                                                            <span>
                                                                                                                                &mdash;&mdash;
                                                                                                                            </span>
                                                                                                                        )}
                                                                                                                    </TableCell>
                                                                                                                    <TableCell
                                                                                                                        className={
                                                                                                                            style.toolTipCell
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {data.new_value ? (
                                                                                                                            <Tooltip
                                                                                                                                title={
                                                                                                                                    auditDataEnum[
                                                                                                                                        data
                                                                                                                                            .table_name
                                                                                                                                    ][
                                                                                                                                        data
                                                                                                                                            .field_modified
                                                                                                                                    ][
                                                                                                                                        data
                                                                                                                                            .new_value
                                                                                                                                    ]
                                                                                                                                }
                                                                                                                                placement="bottom-start"
                                                                                                                            >
                                                                                                                                <span>
                                                                                                                                    {
                                                                                                                                        auditDataEnum[
                                                                                                                                            data
                                                                                                                                                .table_name
                                                                                                                                        ][
                                                                                                                                            data
                                                                                                                                                .field_modified
                                                                                                                                        ][
                                                                                                                                            data
                                                                                                                                                .new_value
                                                                                                                                        ]
                                                                                                                                    }
                                                                                                                                </span>
                                                                                                                            </Tooltip>
                                                                                                                        ) : (
                                                                                                                            <span>
                                                                                                                                &mdash;&mdash;
                                                                                                                            </span>
                                                                                                                        )}
                                                                                                                    </TableCell>

                                                                                                                    <TableCell>
                                                                                                                        {data.table_name ? (
                                                                                                                            <span>
                                                                                                                                {
                                                                                                                                    data.table_name
                                                                                                                                }
                                                                                                                            </span>
                                                                                                                        ) : (
                                                                                                                            <span>
                                                                                                                                &mdash;&mdash;
                                                                                                                            </span>
                                                                                                                        )}
                                                                                                                    </TableCell>
                                                                                                                    <TableCell>
                                                                                                                        {' '}
                                                                                                                    </TableCell>
                                                                                                                    <TableCell>
                                                                                                                        {' '}
                                                                                                                    </TableCell>
                                                                                                                </React.Fragment>
                                                                                                            ) : (
                                                                                                                <React.Fragment>
                                                                                                                    {' '}
                                                                                                                    <TableCell>
                                                                                                                        {
                                                                                                                            data.field_modified
                                                                                                                        }
                                                                                                                    </TableCell>
                                                                                                                    <TableCell
                                                                                                                        className={
                                                                                                                            style.toolTipCell
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {data.current_value ? (
                                                                                                                            <Tooltip
                                                                                                                                title={
                                                                                                                                    data.current_value
                                                                                                                                }
                                                                                                                                placement="bottom-start"
                                                                                                                            >
                                                                                                                                <span>
                                                                                                                                    {
                                                                                                                                        data.current_value
                                                                                                                                    }
                                                                                                                                </span>
                                                                                                                            </Tooltip>
                                                                                                                        ) : (
                                                                                                                            <span>
                                                                                                                                &mdash;&mdash;
                                                                                                                            </span>
                                                                                                                        )}
                                                                                                                    </TableCell>
                                                                                                                    <TableCell
                                                                                                                        className={
                                                                                                                            style.toolTipCell
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {data.new_value ? (
                                                                                                                            <Tooltip
                                                                                                                                title={
                                                                                                                                    data.new_value
                                                                                                                                }
                                                                                                                                placement="bottom-start"
                                                                                                                            >
                                                                                                                                <span>
                                                                                                                                    {
                                                                                                                                        data.new_value
                                                                                                                                    }
                                                                                                                                </span>
                                                                                                                            </Tooltip>
                                                                                                                        ) : (
                                                                                                                            <span>
                                                                                                                                &mdash;&mdash;
                                                                                                                            </span>
                                                                                                                        )}
                                                                                                                    </TableCell>
                                                                                                                    <TableCell>
                                                                                                                        {data.table_name ? (
                                                                                                                            <span>
                                                                                                                                {
                                                                                                                                    data.table_name
                                                                                                                                }
                                                                                                                            </span>
                                                                                                                        ) : (
                                                                                                                            <span>
                                                                                                                                &mdash;&mdash;
                                                                                                                            </span>
                                                                                                                        )}
                                                                                                                    </TableCell>
                                                                                                                    <TableCell>
                                                                                                                        {' '}
                                                                                                                    </TableCell>
                                                                                                                    <TableCell>
                                                                                                                        {' '}
                                                                                                                    </TableCell>
                                                                                                                </React.Fragment>
                                                                                                            )}
                                                                                                        </TableRow>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                </Box>
                                                                            </Collapse>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </React.Fragment>
                                                            );
                                                        }
                                                    )}
                                                </TableBody>
                                            )}
                                        </React.Fragment>
                                        <TableFooter>
                                            {(count || count) > 0 && (
                                                <TableRow>
                                                    <TablePagination
                                                        rowsPerPageOptions={[20, 50, 100]}
                                                        colSpan={8}
                                                        count={count}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        SelectProps={{
                                                            inputProps: { 'aria-label': 'rows per page' },
                                                            native: true
                                                        }}
                                                        onChangePage={handleChangePage}
                                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                                    />
                                                </TableRow>
                                            )}
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </React.Fragment>
                        )}
                    </Paper>
                </div>
            ) : (
                <Redirect to={getRoute(`/login`)} />
            )}
        </React.Fragment>
    );
}

AuditDataComponent.propTypes = {
    loading: PropTypes.bool,
    rows: PropTypes.array.isRequired,
    error: PropTypes.bool,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    count: PropTypes.number,
    setInputValue: PropTypes.func,
    onSearchMerchants: PropTypes.func,
    setOpen: PropTypes.func
};

export default AuditDataComponent;
