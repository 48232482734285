import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { getDateRangeOfWeek } from '../../utils/helper';
import PropTypes from 'prop-types';
import { useGlobalStateContext } from 'contexts';
import CalenderComponent from './calender-shuffle.component';
import { cleverTapClick } from '../../utils/helper';
const Calendar = (props) => {
    const {
        path,
        range,
        date,
        current,
        onChangeDateRange,
        locationInApp,
        daterange,
        offrange,
        setOffrange = () => {},
        transactionsListingOptions,
        setTransactionsListingOptions,
        loading,
        setStartDate,
        setEndDate,
        startDate,
        endDate,
        setDaysRange,
        onChangeDate
    } = props;
    // const [via, setVia] = useState(props.via);
    const { globalState } = useGlobalStateContext();

    const [stateUrls, setStateUrls] = useState({});
    const [showRangePicker, setShowRangePicker] = useState(false);
    useEffect(() => {
        const urls = {
            next: `/${path}/${range}/`,
            previous: `/${path}/${range}/`,
            range: date
        };
        if (path === 'payments' || path === 'gfo-payments' || path === 'list-chargebacks') {
            setShowRangePicker(true);
        }
        if (path === 'refund-list') {
            setShowRangePicker(range === 'day');
        }

        switch (range) {
            case 'day': {
                urls.range = moment(date).format('DD MMM YY');
                urls.previous += moment(date).subtract(1, 'day').format('YYYY-MM-DD');
                urls.next += moment(date).add(1, 'day').format('YYYY-MM-DD');
                if (path === 'payments' || path === 'pdq-transactions') {
                    setStartDate(moment(date).format('DD-MM-YYYY'));
                    setEndDate(moment(date).format('DD-MM-YYYY'));
                }
                break;
            }
            case 'week': {
                const isoWeek = moment(date, 'YYYY-MM-DD').isoWeek();
                const year = moment(date, 'YYYY-MM-DD').isoWeekYear();
                const dateRangeOfWeek = getDateRangeOfWeek(isoWeek, year);

                urls.range = dateRangeOfWeek;
                urls.previous += moment(date).subtract(1, 'week').format('YYYY-MM-DD');
                urls.next += moment(date).add(1, 'week').format('YYYY-MM-DD');
                if (path === 'payments' || path === 'pdq-transactions') {
                    setStartDate(moment(date).startOf('week').format('DD-MM-YYYY'));
                    setEndDate(moment(date).endOf('week').format('DD-MM-YYYY'));
                }
                break;
            }
            case 'month': {
                urls.range = moment(date).format('MMMM / YYYY');
                urls.previous += moment(date).subtract(1, 'month').format('YYYY-MM-DD');
                urls.next += moment(date).add(1, 'month').format('YYYY-MM-DD');
                if (path === 'payments' || path === 'pdq-transactions') {
                    setEndDate(moment(date).endOf('month').format('DD-MM-YYYY'));
                    setStartDate(moment(date).startOf('month').format('DD-MM-YYYY'));
                }
                break;
            }
            case 'year': {
                urls.range = moment(date).format('YYYY');
                urls.previous += moment(date).subtract(1, 'year').format('YYYY-MM-DD');
                urls.next += moment(date).add(1, 'year').format('YYYY-MM-DD');
                if (path !== 'merchant-charges') {
                    setEndDate(moment(date).endOf('month').format('DD-MM-YYYY'));
                    setStartDate(moment(date).startOf('month').format('DD-MM-YYYY'));
                }
                break;
            }

            default:
                break;
        }
        setStateUrls(urls);
        //eslint-disable-next-line
    }, [path, range, date, current]);

    const handleCleverClick = (value) => {
        let dateStart;
        let dateEnd;
        if (value === 'Next_nav_click') {
            switch (range) {
                case 'week':
                    dateStart = moment(date).startOf('week').add(1, 'week').format('DD-MM-YYYY');
                    dateEnd = moment(date).endOf('week').add(1, 'week').format('DD-MM-YYYY');
                    break;
                case 'month':
                    dateStart = moment(date).add(1, 'month').startOf('month').format('DD-MM-YYYY');
                    dateEnd = moment(date).add(1, 'month').endOf('month').format('DD-MM-YYYY');
                    break;
                default:
                    dateStart = moment(date).add(1, 'day').format('DD-MM-YYYY');
                    dateEnd = moment(date).add(1, 'day').format('DD-MM-YYYY');
            }
        } else {
            switch (range) {
                case 'week':
                    dateStart = moment(date).startOf('week').subtract(1, 'week').format('DD-MM-YYYY');
                    dateEnd = moment(date).endOf('week').subtract(1, 'week').format('DD-MM-YYYY');
                    break;
                case 'month':
                    dateStart = moment(date).subtract(1, 'month').startOf('month').format('DD-MM-YYYY');
                    dateEnd = moment(date).subtract(1, 'month').endOf('month').format('DD-MM-YYYY');
                    break;
                default:
                    dateStart = moment(date).subtract(1, 'day').format('DD-MM-YYYY');
                    dateEnd = moment(date).subtract(1, 'day').format('DD-MM-YYYY');
            }
        }
        const data = { date_start: dateStart, date_end: dateEnd };
        cleverTapClick(value, globalState, data);
    };
    return (
        <CalenderComponent
            {...props}
            next={stateUrls.next}
            previous={stateUrls.previous}
            range={stateUrls.range}
            onChangeDateRange={onChangeDateRange}
            daterange={daterange}
            path={path}
            showRangePicker={showRangePicker}
            setOffrange={setOffrange}
            offrange={offrange}
            locationInApp={locationInApp}
            transactionsListingOptions={transactionsListingOptions}
            setTransactionsListingOptions={setTransactionsListingOptions}
            loading={loading}
            startDate={startDate}
            endDate={endDate}
            handleCleverClick={handleCleverClick}
            setDaysRange={setDaysRange}
            onChangeDate={onChangeDate}
        />
    );
};

export default Calendar;

Calendar.propTypes = {
    onChangeDateRange: PropTypes.func,
    daterange: PropTypes.object,
    offrange: PropTypes.bool,
    setOffrange: PropTypes.func,
    locationInApp: PropTypes.string
};
