import { useAdminFunctionsContext } from 'contexts';
import React, { useEffect, useState } from 'react';
import { AdminService, TermsAndConditionsService } from 'services';
import { MerchantAccountStatus } from 'utils/enums/MerchantAccountStatus';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import MerchantDetailsComponent from './merchant-details.component';
import moment from 'moment';

function MerchantDetails(props) {
    const merchantId = props.match?.params?.merchantId;
    const { setAdminFunctions } = useAdminFunctionsContext();
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [channelFilter, setChannelFilter] = useState('All');
    const [daterange, setDateRange] = useState({ start: moment().subtract(1, 'days'), end: moment() });
    const [dates, setDates] = useState([]);
    const [offrange, setOffrange] = useState(true);
    const [activatedAt, setActivatedAt] = useState('');
    const [signedBy, setSignedBy] = useState('');
    const [loading, setLoading] = useState(false);
    const [transactionsListingOptions, setTransactionsListingOptions] = useState({
        via: 'month',
        date: moment().format('YYYY-MM-DD'),
        locationInApp: 'merchant-details'
    });
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const entity = 'merchant';
    const entityId = merchantId;

    const onChangeDateRange = (startDate, endDate) => {
        setDateRange({ start: startDate, end: endDate });
        setOffrange(true);

        const initialDate = moment(daterange.start).date(1);
        const finalDate = moment(daterange.end).date(1);
        let includedMonths = [];
        let date;

        while (initialDate.isBefore(finalDate)) {
            date = {
                month: initialDate.month() + 1,
                year: initialDate.year()
            };
            includedMonths.push(date);
            initialDate.add(1, 'month');
        }
        setDates(includedMonths);
    };

    useEffect(() => {
        const fetchTermsAndConditionsData = async () => {
            setIsLoading(true);

            const response = await TermsAndConditionsService.getSignedTermsAndConditionsData(entity, entityId);
            if (response.isSuccesfully) {
                setSignedBy(response.data.signedTermsAncConditionInfo.signedBy);
                setActivatedAt(response.data.signedTermsAncConditionInfo.activatedAt);
            }
            setIsLoading(false);
        };

        fetchTermsAndConditionsData();
        // eslint-disable-next-line
    }, [merchantId]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const merchantData = await AdminService.GetMerchantDataForAdmin(merchantId);
            if (merchantData.isSuccesfully) {
                switch (merchantData.data.status) {
                    case MerchantStatus.MERCHANT_PENDING:
                        merchantData.data.accountStatus = MerchantAccountStatus.PENDING;
                        break;
                    case MerchantStatus.ACTIVE:
                        merchantData.data.accountStatus = MerchantAccountStatus.ACTIVE;
                        break;
                    case MerchantStatus.CLOSED:
                        merchantData.data.accountStatus = MerchantAccountStatus.CLOSED;
                        break;
                    case MerchantStatus.REJECTED:
                        merchantData.data.accountStatus = MerchantAccountStatus.REJECTED;
                        break;
                    default:
                        merchantData.data.accountStatus = MerchantAccountStatus.PENDING;
                        break;
                }
                setAdminFunctions(merchantData.data);
                setError(false);
            } else {
                setError(true);
            }
            setIsLoading(false);
        };
        fetchData();
        // eslint-disable-next-line
    }, []);

    return (
        <MerchantDetailsComponent
            {...props}
            error={error}
            setError={setError}
            isLoading={isLoading}
            channelFilter={channelFilter}
            setChannelFilter={setChannelFilter}
            onChangeDateRange={onChangeDateRange}
            dates={dates}
            daterange={daterange}
            offrange={offrange}
            setOffrange={setOffrange}
            transactionsListingOptions={transactionsListingOptions}
            setTransactionsListingOptions={setTransactionsListingOptions}
            activatedAt={activatedAt}
            signedBy={signedBy}
            setLoading={setLoading}
            loading={loading}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
        />
    );
}

export default MerchantDetails;
