import React from 'react';
import { Typography } from '@material-ui/core';
import { Input, Label, FormItem } from 'components/onboarding/onboarding-ui-components';
import styles from './valid-email-input.module.scss';

const ValidEmailInputComponent = (props) => {
    const {
        value,
        onChange,
        disabled,
        id,
        labelText,
        labelId,
        emailInputElementRef,
        doValidation,
        showEmailValidationMessage,
        setShowEmailValidationMessage,
        emailValidationMessage,
        fullWidth,
        type,
        pattern,
        transalterEnabled,
        autoFocus,
        emptyField,
        isStyleRequired
    } = props;

    return (
        <FormItem isStyleRequired={isStyleRequired}>
            <Label text={labelText} id={labelId} transalterEnabled={transalterEnabled}></Label>
            <Input
                id={id}
                fullWidth={fullWidth}
                value={value}
                type={type}
                inputProps={{ ref: emailInputElementRef, maxLength: 128 }}
                pattern={pattern}
                onChange={(e) => {
                    doValidation(e.target.value);
                    onChange(e);
                    setShowEmailValidationMessage(false);
                }}
                disabled={disabled}
                autoFocus={autoFocus}
                emptyField={emptyField}
            />

            {showEmailValidationMessage && (
                <div className={styles.notificationMessageContainer}>
                    <Typography color="error" style={{ marginLeft: '10px', fontSize: '14px' }}>
                        {emailValidationMessage}
                    </Typography>
                </div>
            )}
        </FormItem>
    );
};

export default ValidEmailInputComponent;
