import React from 'react';
import { Paper, Typography, Grid, Divider, Button, TextField, Tooltip } from '@material-ui/core';
import style from './document-management.module.scss';
import { MerchantAccountDetails, DocumentReview } from 'components';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { LoadingScreen } from 'components';
import PropTypes from 'prop-types';
import { Alert as MaterialAlert } from '@material-ui/lab';

function DocumentManagementComponent(props) {
    const { isLoading, setDownload, download, pathMerchantId } = props;

    return (
        <Grid>
            <Paper className={style.documentManagementContainer}>
                <Grid className={style.pageTitle} container justify="space-between">
                    <Grid>
                        <Typography className={style.documentManagementHeader}>Document Management</Typography>
                    </Grid>
                    <Grid>
                        <GetAppOutlinedIcon style={{ cursor: 'pointer' }} onClick={() => setDownload(true)} />
                    </Grid>
                </Grid>
                <Divider />
                <Grid className={style.managementArea}>
                    <Grid className={style.merchantDetails}>
                        <MerchantAccountDetails />
                    </Grid>
                    <Divider />
                    <Grid>
                        <DocumentReview download={download} setDownload={setDownload} pathMerchantId={pathMerchantId} />
                    </Grid>
                </Grid>
            </Paper>

            <LoadingScreen open={isLoading} />
        </Grid>
    );
}

DocumentManagementComponent.propTypes = {
    disable: PropTypes.bool,
    setDisable: PropTypes.func,
    isModalOpen: PropTypes.bool,
    setIsModalOpen: PropTypes.func,
    rejectedDocuments: PropTypes.array,
    rejectionNote: PropTypes.func,
    rejectButton: PropTypes.func,
    isLoading: PropTypes.bool,
    rejectMessage: PropTypes.string,
    setRejectMessage: PropTypes.func,
    setRejectedDocuments: PropTypes.func,
    disableRejectDocument: PropTypes.bool,
    setDisableRejectDocument: PropTypes.func,
    isOpenApproveAccountModal: PropTypes.bool,
    setIsOpenApproveAccountModal: PropTypes.func
};
export default DocumentManagementComponent;
