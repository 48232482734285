import React from 'react';
import { AdyenMerchantList } from 'components';

export default function AdyenMerchantListComponent() {
    return (
        <div>
            <AdyenMerchantList />
        </div>
    );
}
