import { MerchantCountries } from './enums/MerchantCountries';
import CountryImages from '../assets/images/country-image';

export const getCurrencySymbol = (country) => {
    switch (country) {
        case MerchantCountries.UNITED_KINGDOM:
            return '\u00A3';
        case MerchantCountries.UNITED_STATES:
            return '\u0024';
        case MerchantCountries.CANADA:
            return '\u0024';
        case MerchantCountries.AUSTRALIA:
            return '\u0024';
        case MerchantCountries.NEW_ZEALAND:
            return '\u0024';
        case MerchantCountries.IRELAND:
            return '\u20AC';
        case MerchantCountries.MEXICO:
            return '\u0024';
        case MerchantCountries.ANGUILLA:
            return '\u0024';
        case MerchantCountries.INDIA:
            return '\u20b9';
        case MerchantCountries.NIGERIA:
            return '\u20A6';
        default:
            return '\u20AC';
    }
};

export const getCurrencyThreeAlpha = (country) => {
    switch (country) {
        case MerchantCountries.UNITED_KINGDOM:
            return 'GBP';
        case MerchantCountries.UNITED_STATES:
            return 'USD';
        case MerchantCountries.CANADA:
            return 'CAD';
        case MerchantCountries.AUSTRALIA:
            return 'AUD';
        case MerchantCountries.NEW_ZEALAND:
            return 'NZD';
        case MerchantCountries.IRELAND:
            return 'EUR';
        case MerchantCountries.MEXICO:
            return 'MEX';
        case MerchantCountries.ANGUILLA:
            return 'XCD';
        case MerchantCountries.INDIA:
            return 'INR';
        case MerchantCountries.NIGERIA:
            return 'NGN';
        default:
            return 'EUR';
    }
};

export const getCurrencyCode = (country) => {
    switch (country) {
        case MerchantCountries.UNITED_KINGDOM:
            return '826';
        case MerchantCountries.UNITED_STATES:
            return '840';
        case MerchantCountries.CANADA:
            return '124';
        case MerchantCountries.AUSTRALIA:
            return '036';
        case MerchantCountries.NEW_ZEALAND:
            return '554';
        case MerchantCountries.IRELAND:
            return '978';
        case MerchantCountries.NIGERIA:
            return '234';
        default:
            return '978';
    }
};

export const getCountryImage = (country) => {
    switch (country) {
        case MerchantCountries.UNITED_KINGDOM:
            return CountryImages.UNITED_KINGDOM;
        case MerchantCountries.UNITED_STATES:
            return CountryImages.UNITED_STATES;
        case MerchantCountries.CANADA:
            return CountryImages.CANADA;
        case MerchantCountries.AUSTRALIA:
            return CountryImages.AUSTRALIA;
        case MerchantCountries.NEW_ZEALAND:
            return CountryImages.NEW_ZEALAND;
        case MerchantCountries.IRELAND:
            return CountryImages.IRELAND;
        case MerchantCountries.MEXICO:
            return CountryImages.MEXICO;
        case MerchantCountries.ANGUILLA:
            return CountryImages.ANGUILLA;
        case MerchantCountries.INDIA:
            return CountryImages.INDIA;
        case MerchantCountries.NIGERIA:
            return CountryImages.NIGERIA;
        default:
            return CountryImages.UNITED_KINGDOM;
    }
};
