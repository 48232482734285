import { AuthAxios } from './interceptor.service.js';
import { SuccesResponse, ErrorResponse } from 'models';
import { CustomerServiceEndpoint } from 'utils/constants';

/**
 *
 * @param {number} entityId
 */

async function getHierarchy() {
    try {
        const requestResult = await AuthAxios.get(`${CustomerServiceEndpoint}/getHierarchy`);
        return new SuccesResponse(requestResult.data);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function assignEntityAndRoleToUser(data) {
    try {
        await AuthAxios.post(`${CustomerServiceEndpoint}/assignEntityAndRoleToUser`, data);
        return new SuccesResponse();
    } catch (error) {
        const message = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(message);
    }
}

export default {
    getHierarchy,
    assignEntityAndRoleToUser
};
