import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextareaAutosize } from '@material-ui/core';
import styles from './add-credit.module.scss';
import { Input, Label, FormItem } from 'components/onboarding/onboarding-ui-components';
import { useTheme, useMediaQuery, OutlinedInput } from '@material-ui/core';
import { SnackBarAndAlert } from 'components';
import NumberFormat from 'react-number-format';
import CloseIcon from '@material-ui/icons/Close';
import { useGlobalStateContext } from 'contexts';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
export default function AddCredit(props) {
    const {
        disabled,
        handleOnClose,
        open,
        grossAmount,
        feesAmount,
        netAmount,
        onSubmit,
        isCredited,
        successMessage,
        setOpen,
        refundReason,
        setRefundReason,
        firstName,
        lastName,
        setFirstName,
        setLastName,
        address,
        setAddress,
        setFeesAmount,
        onChange,
        isLoading,
        setIsCredited,
        isMaxAmount
    } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const { globalState } = useGlobalStateContext();
    const isMerchantAccountClosed = globalState.merchant?.status === MerchantStatus.CLOSED;

    return (
        <div className={styles.creditBtn}>
            <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
                disabled={isMerchantAccountClosed}
            >
                +Add Credit
            </Button>
            <Dialog open={open} fullScreen={fullScreen}>
                <DialogTitle>
                    <div className={styles.dialogTitle}>
                        Add Credit
                        <div className={styles.closeIcon}>
                            <CloseIcon onClick={handleOnClose} />
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent className={styles.popup}>
                    <FormItem>
                        <Label text="Gross Amount" id="gross-amount"></Label>
                        <Input disabled={true} id="gross-amount-input" fullWidth={true} value={grossAmount} />
                    </FormItem>

                    <FormItem>
                        <Label text="Fee" id="fee"></Label>

                        <Input
                            disabled={true}
                            id="id-input"
                            value={feesAmount}
                            onChange={(e) => setFeesAmount(e.target.value)}
                        />
                    </FormItem>

                    <FormItem>
                        <div className={styles.labelText}>
                            <Label text="Net Amount" id="netAmount"></Label>
                            <span style={{ color: 'red' }}>*</span>
                        </div>
                        <NumberFormat
                            id="amount_input"
                            customInput={OutlinedInput}
                            inputProps={{ inputMode: 'numeric', className: styles.myInput }}
                            pattern="\d*"
                            thousandSeparator={true}
                            autoComplete="off"
                            allowLeadingZeros={true}
                            allowNegative={false}
                            isNumericString={true}
                            decimalScale={2}
                            value={netAmount}
                            onValueChange={(e) => onChange(e)}
                            isAllowed={isMaxAmount}
                            labelWidth={0}
                            required
                            className={styles.cardInput}
                        />
                    </FormItem>
                    <FormItem>
                        <div className={styles.labelText}>
                            <Label text="Refund Reason" id="refund-reason"></Label>
                            <span style={{ color: 'red' }}>*</span>
                        </div>

                        <TextareaAutosize
                            column="12"
                            className={styles.requestFormInputRoot + ' ' + styles.requestFormInput}
                            rows="2"
                            disableUnderline
                            type="text"
                            required
                            autoComplete="off"
                            value={refundReason}
                            maxLength={150}
                            //inputProps={{ maxLength: '150', inputMode: 'text' }}
                            onChange={(e) => setRefundReason(e.target.value)}
                        />
                    </FormItem>
                    <FormItem>
                        <Label text="First Name" id="first-name-input"></Label>
                        <Input
                            id="first-name-input"
                            inputProps={{ maxLength: 25 }}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </FormItem>
                    <FormItem>
                        <Label text="Last Name" id="last-name-input"></Label>
                        <Input
                            id="last-name-input"
                            inputProps={{ maxLength: 24 }}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </FormItem>
                    <FormItem>
                        <Label text="Address" id="fee"></Label>
                        <TextareaAutosize
                            className={styles.requestFormInputRoot + ' ' + styles.requestFormInput}
                            rows="2"
                            disableUnderline
                            type="text"
                            required
                            autoComplete="off"
                            value={address}
                            maxLength={150}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </FormItem>
                </DialogContent>
                <div className={styles.btnContainer}>
                    <DialogActions>
                        <Grid item md={12}>
                            <Button
                                id="update-btn"
                                color="primary"
                                variant="contained"
                                className={styles.submitBtn}
                                onClick={onSubmit}
                                disabled={disabled || isLoading}
                            >
                                {isLoading ? 'Please Wait...' : 'Submit'}
                            </Button>
                        </Grid>
                    </DialogActions>
                </div>
            </Dialog>
            <SnackBarAndAlert
                open={isCredited}
                onClose={() => setIsCredited(false)}
                type={successMessage ? 'success' : 'error'}
            >
                {successMessage ? successMessage : 'Credit request has failed.'}
            </SnackBarAndAlert>
        </div>
    );
}

AddCredit.propTypes = {
    disabled: PropTypes.bool,
    open: PropTypes.bool,
    grossAmount: PropTypes.number,
    feesAmount: PropTypes.number,
    netAmount: PropTypes.number,
    setOpen: PropTypes.func,
    refundReason: PropTypes.string,
    setRefundReason: PropTypes.func,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    setFirstName: PropTypes.func,
    setLastName: PropTypes.func,
    address: PropTypes.string,
    setAddress: PropTypes.func,
    setFeesAmount: PropTypes.func,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    handleOnClose: PropTypes.func
};
