import React from 'react';
import PropTypes from 'prop-types';
import { TextField, makeStyles, createStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const styles = makeStyles((theme) =>
    createStyles({
        tag: {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& svg': {
                '& path': {
                    color: 'white'
                }
            }
        },
        popupIndicator: {
            color: theme.palette.primary.main,
            '& span': {
                '& svg': {
                    '& path': {
                        d:
                            "path('M17,13H13V17H11V13H7V11H11V7H13V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z')"
                    }
                }
            }
        },
        popupIndicatorOpen: {
            color: theme.palette.primary.main,
            '& span': {
                '& svg': {
                    '& path': {
                        d:
                            "path('M17,13H7V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z')"
                    }
                }
            }
        },
        clearIndicator: {
            color: theme.palette.primary.main
        },
        label: {
            color: theme.palette.primary.main
        }
    })
);

function MultiPickerInputComponent(props) {
    const classes = new styles();

    return (
        <Autocomplete
            multiple
            options={props.list}
            getOptionLabel={props.property}
            size="small"
            onChange={(event, value) => props.onChange(value)}
            value={props.value}
            classes={{
                tag: classes.tag,
                popupIndicator: classes.popupIndicator,
                popupIndicatorOpen: classes.popupIndicatorOpen,
                clearIndicator: classes.clearIndicator
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label={props.label}
                    classes={{
                        label: classes.label
                    }}
                />
            )}
        />
    );
}

MultiPickerInputComponent.propTypes = {
    list: PropTypes.array,
    property: PropTypes.func,
    onChange: PropTypes.func,
    label: PropTypes.string
};

export default MultiPickerInputComponent;
