import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TablePagination,
    TableRow,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
    Grid,
    Input,
    Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TableHeader from '../../../components/payments/transaction-tables/table-header/table-header.container.js';
import { ThirdPartyMerchantIdAndStoreId } from 'components';
import { Link } from 'react-router-dom';
import styles from './admin-pending-merchants.module.scss';
import { formatDateTimeByFormatString, stableSort, getComparator } from 'utils/helper';
import { MerchantStatusNames } from 'utils/enums/MerchantStatusNames';
import { CanonicalResellerStatusNames } from 'utils/enums/CanonicalResellerStatus';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { SnackBarAndAlert } from 'components';
import { useGlobalStateContext } from 'contexts';

const useStyles = makeStyles(() => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    tableCellSmallSize: {
        '& td': {
            padding: '6px 14px 6px 14px'
        },
        '& th': {
            padding: '6px 14px 6px 14px'
        }
    }
}));

export default function AdminPendingMerchantsComponent(props) {
    const { setRoute } = useGlobalStateContext();

    const {
        pendingMerchants,
        onGoToAccount,
        isLoading,
        onSearchMerchants,
        setInputValue,
        count,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        filter,
        setFilter,
        order,
        orderBy,
        showCanonicalResellersList,
        canonicalResellerList,
        handleCopyResellerId,
        closeCopyAlert,
        isCopyAlertOpen,
        checkResellerViewHandler
    } = props;
    const classes = useStyles();
    return (
        <React.Fragment>
            <Paper className={styles.searchPaper}>
                <form onSubmit={onSearchMerchants} className={styles.search_form}>
                    <TextField
                        className={styles.searchMerchants}
                        id="pending-merchants-search"
                        size="small"
                        label="Search"
                        type="search"
                        variant="outlined"
                        autoComplete="off"
                        autoFocus
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                    <Button
                        id="pending-merchants-search-button"
                        className={styles.searchButton}
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={onSearchMerchants}
                    >
                        Search
                    </Button>
                    {!showCanonicalResellersList && (
                        <FormControl variant="outlined" className={styles.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label" className={styles.inputlabel}>
                                Filter
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                label="Filter"
                                value={filter}
                                onChange={(e) => {
                                    setFilter(e.target.value);
                                }}
                                className={styles.selectInput}
                            >
                                <MenuItem value={'all'}>All</MenuItem>
                                <MenuItem value={'notsubmitted'}>Not Submitted</MenuItem>
                                <MenuItem value={'pending'}>Pending</MenuItem>
                                <MenuItem value={'pendingstripe'}>Pending Stripe</MenuItem>
                                <MenuItem value={'active'}>Active</MenuItem>
                                <MenuItem value={'closed'}>Closed</MenuItem>
                                <MenuItem value={'rejected'}>Rejected</MenuItem>
                                <MenuItem value={'watch list'}>Watch List</MenuItem>
                            </Select>
                        </FormControl>
                    )}

                    <FormControl style={{ marginLeft: '20px' }}>
                        <FormControlLabel
                            style={{ marginBottom: '0px' }}
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={showCanonicalResellersList}
                                    onChange={checkResellerViewHandler}
                                />
                            }
                            label="Reseller"
                            labelPlacement="end"
                        />
                    </FormControl>
                </form>
            </Paper>
            <div className={styles.container}>
                {isLoading ? (
                    <div className={styles.loading}>
                        <CircularProgress />
                    </div>
                ) : (
                    <TableContainer component={Paper}>
                        {pendingMerchants.length === 0 && canonicalResellerList.length === 0 ? (
                            <div className={styles.paymentsNone}>No results found</div>
                        ) : (
                            <Table
                                aria-label="simple table"
                                aria-labelledby="tableTitle"
                                size="small"
                                stickyHeader
                                className={classes.tableCellSmallSize}
                            >
                                <TableHeader classes={classes} {...props} />
                                {showCanonicalResellersList ? (
                                    <TableBody>
                                        {stableSort(canonicalResellerList, getComparator(order, orderBy)).map(
                                            (canonicalReseller) => (
                                                <TableRow key={canonicalReseller.id}>
                                                    <TableCell
                                                        onClick={() =>
                                                            setRoute(
                                                                `/canonical-reseller-merchant-management/${canonicalReseller.id}`
                                                            )
                                                        }
                                                        className={styles.resellerNameStyle}
                                                    >
                                                        <Tooltip
                                                            title={canonicalReseller.name}
                                                            className={styles.merchantNameHover}
                                                            placement="bottom-start"
                                                        >
                                                            <span>{canonicalReseller.name} </span>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell>
                                                        {CanonicalResellerStatusNames[canonicalReseller.status]}
                                                    </TableCell>
                                                    <TableCell className={styles.resellerIdColumn}>
                                                        <Grid container spacing={2} className={styles.idContainer}>
                                                            <Grid item xs={9}>
                                                                <Input
                                                                    inputProps={{ className: styles.textInput }}
                                                                    value={canonicalReseller.id}
                                                                    disableUnderline
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={3}
                                                                container
                                                                alignItems="center"
                                                                className={styles.copyIconContainer}
                                                            >
                                                                <FileCopyIcon
                                                                    className={styles.copyIcon}
                                                                    onClick={() =>
                                                                        handleCopyResellerId(canonicalReseller.id)
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell>
                                                        {formatDateTimeByFormatString(
                                                            canonicalReseller.createdDate,
                                                            'DD MMM HH:mm'
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                        <TableRow>
                                            <TableCell colSpan={8} />
                                        </TableRow>
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        {stableSort(pendingMerchants, getComparator(order, orderBy)).map(
                                            (pendingMerchant) => (
                                                <TableRow key={pendingMerchant.id}>
                                                    <TableCell className={styles.nameColumn}>
                                                        <Tooltip
                                                            title={pendingMerchant.name}
                                                            className={styles.merchantName}
                                                            placement="bottom-start"
                                                        >
                                                            <Link onClick={() => onGoToAccount(pendingMerchant.id)}>
                                                                <span>{pendingMerchant.name}</span>
                                                            </Link>
                                                        </Tooltip>
                                                    </TableCell>

                                                    <TableCell>{MerchantStatusNames[pendingMerchant.status]}</TableCell>
                                                    <TableCell className={styles.postcodeColumn}>
                                                        <span>{pendingMerchant.postCode}</span>
                                                    </TableCell>
                                                    <TableCell className={styles.idsColumn}>
                                                        <ThirdPartyMerchantIdAndStoreId
                                                            ids={{
                                                                merchantId: pendingMerchant.id,
                                                                thirdPartyCustomerId:
                                                                    pendingMerchant.thirdPartyCustomerId
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        {formatDateTimeByFormatString(
                                                            pendingMerchant.createdDate,
                                                            'DD MMM HH:mm'
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {formatDateTimeByFormatString(
                                                            pendingMerchant.completedDate,
                                                            'DD MMM HH:mm'
                                                        )}
                                                    </TableCell>
                                                    {/* <TableCell>
                                                        <Link onClick={() => onGoToAccount(pendingMerchant.id)}>
                                                            Account
                                                        </Link>
                                                    </TableCell> */}
                                                    <TableCell>
                                                        <a
                                                            href="https://ms.touch2success.com/manage/login.php?w=0"
                                                            rel="noopener noreferrer"
                                                            target="_blank"
                                                        >
                                                            MS
                                                        </a>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                        <TableRow>
                                            <TableCell colSpan={8} />
                                        </TableRow>
                                    </TableBody>
                                )}
                                <TableFooter>
                                    <TableRow>
                                        {count > 20 && (
                                            <TablePagination
                                                rowsPerPageOptions={[20, 50, 100]}
                                                colSpan={8}
                                                count={count}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: { 'aria-label': 'rows per page' },
                                                    native: true
                                                }}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                            />
                                        )}
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        )}
                    </TableContainer>
                )}
            </div>
            {isCopyAlertOpen && (
                <SnackBarAndAlert open={isCopyAlertOpen} onClose={closeCopyAlert} type={'success'}>
                    Successfully copied the reseller id.
                </SnackBarAndAlert>
            )}
        </React.Fragment>
    );
}

AdminPendingMerchantsComponent.propTypes = {
    pendingMerchants: PropTypes.array,
    onGoToAccount: PropTypes.func,
    isLoading: PropTypes.bool,
    onSearchMerchants: PropTypes.func,
    setInputValue: PropTypes.func,
    count: PropTypes.number,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    filter: PropTypes.string,
    setFilter: PropTypes.func
};
