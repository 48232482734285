import React, { useState } from 'react';
import PropTypes from 'prop-types';
const MerchantCreationContext = React.createContext();

function MerchantCreationProvider(props) {
    const initialState = {
        showMerchantCreationMessage: false
    };

    const [merchantCreationState, setMerchantCreationState] = useState(initialState);

    return (
        <MerchantCreationContext.Provider value={{ merchantCreationState, setMerchantCreationState }}>
            {props.children}
        </MerchantCreationContext.Provider>
    );
}

/**
 * @returns {{merchantCreationState: initialState, setMerchantCreationState: (state: initialState) => {}}}
 */
function useMerchantCreationContext() {
    const selectedElementContext = React.useContext(MerchantCreationContext);
    if (!selectedElementContext) {
        throw new Error('useMerchantCreationContext must be used within a MerchantCreationProvider');
    }
    return selectedElementContext;
}

export { MerchantCreationProvider, useMerchantCreationContext };

MerchantCreationProvider.propTypes = {
    children: PropTypes.any
};
