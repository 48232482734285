import { DatmanAxios } from './interceptor.service.js';
import { DatmanAPIEndpoint } from 'utils/constants';
import { SuccesResponse, ErrorResponse } from 'models';
import _ from 'lodash';
import moment from 'moment';

async function getInternalTransfersList({ day, month, year, via }) {
    try {
        const response = await DatmanAxios.get(`${DatmanAPIEndpoint}/portal/internal-transfer`);
        const succesResponseData = { data: {} };

        succesResponseData.data.internalTransfers = getFormattedInternalTransfersList(response.data.data);
        succesResponseData.data.internalTransfers = filterInternalTransferByTimeFrame(
            succesResponseData.data.internalTransfers,
            { day, month, year, via }
        );
        succesResponseData.data.totalPendingAmount = getTotalPendingInternalTransfersAmount(
            succesResponseData.data.internalTransfers
        );

        succesResponseData.data.total = getTotalInternalTransfersAmount(succesResponseData.data.internalTransfers);
        succesResponseData.data.transfer_type = response.data.data.transfer_type;

        return new SuccesResponse(succesResponseData, response.status);
    } catch (error) {
        const errMessage = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }

    function getFormattedInternalTransfersList(apiResponseData) {
        let internalTransfers = [];
        let transfer_type = apiResponseData.transfer_type;

        _.each(apiResponseData.transactions, function (transaction) {
            let internalTransfer = _.pick(transaction, 'amount', 'status', 'created_at', 'description');

            let supplierObj = _.find(
                apiResponseData.customer_info,
                (cust) => cust.id === (transfer_type === 'sender' ? transaction.recipient_id : transaction.customer_id)
            );

            internalTransfer.supplier = (supplierObj && supplierObj.business_name) || '';
            internalTransfer.id = transaction.ref;

            internalTransfers.push(internalTransfer);
        });

        return internalTransfers;
    }

    function getTotalPendingInternalTransfersAmount(internalTransfers) {
        let totalPendingAmount = _.reduce(
            internalTransfers,
            function (totalAmount, internalTransfer) {
                return internalTransfer.status === 'PENDING' ? totalAmount + internalTransfer.amount : totalAmount;
            },
            0
        );

        return totalPendingAmount;
    }

    function getTotalInternalTransfersAmount(internalTransfers) {
        let total = _.reduce(
            internalTransfers,
            function (totalAmount, internalTransfer) {
                return totalAmount + internalTransfer.amount;
            },
            0
        );

        return total;
    }

    function filterInternalTransferByTimeFrame(internalTransfers, { day, month, year, via }) {
        let startingTimestamp = moment(`${day}-${month}-${year}`, 'DD-MM-YYYY').startOf(via).valueOf();
        let endingTimestamp = moment(`${day}-${month}-${year}`, 'DD-MM-YYYY').endOf(via).valueOf();

        return _.filter(internalTransfers, function (internalTransfer) {
            let created_at = moment.utc(internalTransfer.created_at).valueOf();
            return created_at >= startingTimestamp && created_at <= endingTimestamp;
        });
    }
}

const getSuppliers = async () => {
    try {
        const response = await DatmanAxios.get(`${DatmanAPIEndpoint}/portal/internal-transfer/fee`);
        return new SuccesResponse(response.data.data.suppliersData);
    } catch (error) {
        const errMessage = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
};

async function submitInternalTransfer(internalTransferData) {
    try {
        await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/internal-transfer`, internalTransferData);
        return new SuccesResponse();
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function cancelInternalTransfer(internalTransferData) {
    try {
        await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/internal-transfer`, internalTransferData);
        return new SuccesResponse();
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}
async function refundInternalTransfer(internalTransferData) {
    try {
        await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/internal-transfer`, internalTransferData);
        return new SuccesResponse();
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

export default {
    getInternalTransfersList,
    submitInternalTransfer,
    getSuppliers,
    cancelInternalTransfer,
    refundInternalTransfer
};
