import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useGlobalStateContext } from 'contexts';
import { AuthService } from 'services';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { UNDERMAINTAINANCE } from 'utils/constants';

export default function NotLoggedRoute({ ...rest }) {
    const { globalState, getRoute } = useGlobalStateContext();
    const location = useLocation();
    if (UNDERMAINTAINANCE && globalState.customDomain) {
        return <Redirect to={getRoute(`/maintenance`)} />;
    }

    if (AuthService.isLoggedUser()) {
        const urlParams = parse(location.hash);
        if (globalState.user && globalState.user?.email === urlParams.email) {
            globalState.user.isEmailConfirmed =
                urlParams.email_verification && urlParams.email_verification === 'success' ? true : false;
            const currentUser = JSON.parse(localStorage.getItem('user'));
            currentUser.isEmailConfirmed = true;
            localStorage.setItem('user', JSON.stringify(currentUser));
        }
        if (localStorage.getItem('thirdPartyResellerUrl')) {
            return <Redirect to={getRoute(`/home?q=${localStorage.getItem('thirdPartyResellerUrl')}`)} />;
        }

        if (rest.location.search && rest.location.search?.includes('data=')) {
            //for linkQR page
            return <Redirect to={getRoute(`/home${rest.location.search}`)} />;
        }

        return <Redirect to={getRoute(`/home`)} />;
    }

    return <Route {...rest}></Route>;
}
