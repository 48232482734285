import React from 'react';

import OnboardingTitleComponent from './onboarding-title.component';

/**
 *
 * @param {{
 * title: string;
 * subTitle: string;
 * }} props
 */
function OnboardingTitle(props) {
    return <OnboardingTitleComponent {...props} />;
}

OnboardingTitle.propTypes = {};

export default OnboardingTitle;
