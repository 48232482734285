import { AuthAxios } from './interceptor.service.js';
import { SuccesResponse, ErrorResponse } from 'models';
import { DnaServiceEndpoint } from '../utils/constants';

const getDnaMerchantAgreement = async (merchantId) => {
    try {
        const response = await AuthAxios.post(`${DnaServiceEndpoint}/get-dna-merchant-agreement/${merchantId}`);
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};
const submitDnaOnboarding = async (merchantId, data) => {
    try {
        const response = await AuthAxios.post(`${DnaServiceEndpoint}/dna-onboarding/${merchantId}`, data);
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};
const getDnaAccount = async (data) => {
    try {
        const response = await AuthAxios.post(`${DnaServiceEndpoint}/get-dna-account`, data);
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getUniqueAgentEmails = async (data) => {
    try {
        const response = await AuthAxios.get(`${DnaServiceEndpoint}/get-agents-unique-accounts`);
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getMerchatDataPdq = async (merchantId) => {
    try {
        const response = await AuthAxios.get(`${DnaServiceEndpoint}/get-merchant-data/${merchantId}`);
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};
const dnaOnboardRequest = async (data) => {
    try {
        const response = await AuthAxios.post(`${DnaServiceEndpoint}/dna-onboard-request`, data);
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getPdqDevices = async (data) => {
    try {
        const response = await AuthAxios.post(`${DnaServiceEndpoint}/get-dna-terminals-info`, data);
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getError = (error) => {
    const message = error.response ? error.response.data : 'An error occurred';
    return new ErrorResponse(message);
};

const requestPDQRemoval = async (data) => {
    try {
        const response = await AuthAxios.post(`${DnaServiceEndpoint}/remove-pdq-request`, data);
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};
export default {
    getDnaMerchantAgreement,
    submitDnaOnboarding,
    getDnaAccount,
    getMerchatDataPdq,
    dnaOnboardRequest,
    getPdqDevices,
    getUniqueAgentEmails,
    requestPDQRemoval
};
