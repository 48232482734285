import { AuthAxios, DatmanAxios } from './interceptor.service.js';
import { NonResellerMerchantServiceEndpoint, DatmanAPIEndpoint } from 'utils/constants';
import { SuccesResponse, ErrorResponse } from 'models';
import * as Sentry from '@sentry/react';

async function checkFirstWithdrawal(merchantId) {
    try {
        await AuthAxios.get(`${NonResellerMerchantServiceEndpoint}/withdrawal/check-first/${merchantId}`);
        return new SuccesResponse();
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function submitWithdrawal(amount, requestedByUserId, merchantId, csrfToken) {
    try {
        const result = await DatmanAxios.post(
            `${DatmanAPIEndpoint}/portal/withdraw`,
            {
                amount,
                skipWithdrawalEmail: true
            },
            { headers: { 'X-CSRF-TOKEN': csrfToken } }
        );

        if (result.data.status !== 'fail') {
            await AuthAxios.post(`${NonResellerMerchantServiceEndpoint}/withdrawal/submit-withdrawal/${merchantId}`, {
                amount: amount,
                requestedByUserId: requestedByUserId,
                merchantId: merchantId
            });
        }

        return new SuccesResponse(result.data);
    } catch (err) {
        Sentry.withScope((scope) => {
            scope.setTag('withdrawal', 'error');
            scope.setUser({
                email: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).email : ''
            });
            Sentry.captureMessage(
                `withdrwal for user id ${requestedByUserId}, merchantId ${merchantId} and amount ${amount} has failed.`
            );
            Sentry.captureException(err);
        });
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function getCustomerConfig() {
    try {
        let requestResult = await DatmanAxios.get(`${DatmanAPIEndpoint}/portal/customer-config`);
        return new SuccesResponse(requestResult.data);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function reverseWithdrawal() {
    try {
        let response = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/reversewithdrawals`);
        return new SuccesResponse(response.data);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function getNextWithdrawalDay() {
    try {
        let response = await AuthAxios.get(`${NonResellerMerchantServiceEndpoint}/get-next-withdrawal-day`);
        return new SuccesResponse(response.data);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

export default {
    checkFirstWithdrawal,
    submitWithdrawal,
    getCustomerConfig,
    reverseWithdrawal,
    getNextWithdrawalDay
};
