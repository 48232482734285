import React from 'react';
import { Typography, OutlinedInput } from '@material-ui/core';
import { Label, FormItem } from 'components/onboarding/onboarding-ui-components';
import styles from './valid-url-input.module.scss';
import InputAdornment from '@material-ui/core/InputAdornment';

const ValidURLInputComponent = (props) => {
    const {
        value,
        onChange,
        disabled,
        id,
        labelText,
        labelId,
        doValidation,
        showUrlValidationMessage,
        setShowUrlValidationMessage
    } = props;

    return (
        <FormItem>
            <Label text={labelText} id={labelId}></Label>
            <OutlinedInput
                autoComplete="off"
                className={styles.customOutlineInput}
                startAdornment={
                    <InputAdornment position="start" style={{ marginRight: '0px' }}>
                        https://
                    </InputAdornment>
                }
                id={id}
                value={value?.toLowerCase().replace('https://', '')}
                inputProps={{ maxLength: 150 }}
                onChange={(e) => {
                    doValidation(e.target.value);
                    onChange(e.target.value ? `https://${e.target.value}` : '');
                    setShowUrlValidationMessage(false);
                }}
                disabled={disabled}
            />
            {showUrlValidationMessage && (
                <div className={styles.notificationMessageContainer}>
                    <Typography color="error" style={{ marginLeft: '10px', fontSize: '14px' }}>
                        Required format should be “https://www.sample.com"
                    </Typography>
                </div>
            )}
        </FormItem>
    );
};

export default ValidURLInputComponent;
