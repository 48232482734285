import React, { useEffect, useState } from 'react';
import {
    UpdateAccount,
    UpdateAccountMypay,
    CanonicalResellerUpdateAccount,
    UpdateAccountResellerMerchant
} from 'components';
import { useGlobalStateContext } from 'contexts';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { UserType } from 'utils/enums/UserType';

function UpdateAccountComponent() {
    const { globalState } = useGlobalStateContext();
    const [isOnboarded, setIsOnboarded] = useState(false);
    const isT2SMerchant = globalState.isT2SMerchant;
    const isCanonicalResellerUser = globalState.user?.UserType?.name === UserType.Reseller;

    useEffect(() => {
        const validMerchantStatuses = [
            MerchantStatus.ACTIVE,
            MerchantStatus.MERCHANT_PENDING,
            MerchantStatus.VERIFIED,
            MerchantStatus.WATCHLIST
        ];

        setIsOnboarded(validMerchantStatuses.includes(globalState.merchant?.status));
    }, [globalState.merchant]);

    return isCanonicalResellerUser ? (
        <CanonicalResellerUpdateAccount />
    ) : isOnboarded ? (
        ![1, 2].includes(globalState.merchant?.canonicalResellerId) && !isT2SMerchant ? ( //Other than Datman, MyPay merchants
            <UpdateAccountResellerMerchant />
        ) : isT2SMerchant ? (
            <UpdateAccount />
        ) : (
            <UpdateAccountMypay />
        )
    ) : (
        <React.Fragment></React.Fragment>
    );
}

export default UpdateAccountComponent;
