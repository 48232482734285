import { emailValidationPattern } from '../../utils/enums/EmailValidationPattern';
/**
 *
 * @param {string} currenValue
 */
function validatePhoneNumber(currenValue, previousValue) {
    if (!currenValue) return currenValue;

    return /^\+?\d*$/.test(currenValue) ? currenValue : previousValue;
}

function validateInternationPhoneNumber(phone) {
    if (!phone) return false;
    return /^\+(?:[0-9]){6,14}[0-9]$/.test(phone);
}

function isEmailValid(email) {
    const pattern = new RegExp(emailValidationPattern.regexString + '$', 'i');
    return pattern.test(email);
}

function isWebsiteUrlValid(url) {
    var pattern = new RegExp(
        /^((https):\/\/)?w{3}[.]{1}?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
    );

    return pattern.test(url);
}
function isDomainUrlValid(domain) {
    const pattern = new RegExp(
        /^((https):\/\/)?(w{3}[.]{1})?[a-zA-Z0-9-]+(\.[a-zA-Z0-9-/]{2,}){1,3}(#?\/?[a-zA-Z0-9#/&%_?=.^*@-]+)*\/?(\?[a-zA-Z0-9#/&%_?=.^*@-]+=[a-zA-Z0-9#/&%_?=.^*@-]+&?)?$/
    );
    return pattern.test(domain);
    // /^((https):\/\/)?(w{3}[.]{1})?[a-z0-9-]+(\.[a-z-/]{2,}){1,3}(#?\/?[a-zA-Z0-9#/&%_?=-]+)*\/?(\?[a-zA-Z0-9#/&%_?=-]+=[a-zA-Z0-9#/&%_?=-]+&?)?$/
}

export { validatePhoneNumber, isEmailValid, isWebsiteUrlValid, isDomainUrlValid, validateInternationPhoneNumber };
