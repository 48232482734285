import React from 'react';

import OnboardingFormsContainerComponent from './onboarding-forms-container.component';

/**
 *
 * @param {{
 * title: JSX.Element
 * content: JSX.Element
 * nextButton: JSX.Element
 * }} props
 */
function OnboardingFormsContainer(props) {
    return <OnboardingFormsContainerComponent {...props} />;
}

OnboardingFormsContainer.propTypes = {};

export default OnboardingFormsContainer;
