import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    Paper,
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableFooter,
    TablePagination,
    useMediaQuery,
    IconButton
} from '@material-ui/core';
import TableHeader from '../table-header/table-header.container.js';
import { formatCurrency, formatDateTimeByFormatString, getComparator, stableSort } from '../../../../utils/helper';
import styles from './gfo-payment-list-table.module.scss';
import TableFooterComponent from '../table-footer/table-footer.component';
import { useGlobalStateContext } from 'contexts/index.js';
import { getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry.js';
import { Roles } from 'utils/enums/Roles';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import _ from 'lodash';
import SettingsBackupRestoreSharpIcon from '@material-ui/icons/SettingsBackupRestoreSharp';
import TransactionRefund from '../../../transaction/transaction-refund/transaction-refund.container';
import FilterTabs from '../filter-tabs/filter-tabs.container';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        boxShadow: 'none'
    },
    container: {
        'overflow-y': 'visible'
    },
    table: {
        minWidth: '100%'
    },
    tableRow: {
        '&$selected, &$selected:hover': {
            backgroundColor: '#e4e1fe'
        }
    },
    selected: {},
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    checkbox: {
        color: '#8783b5'
    },
    'MuiIconButton-root': {
        padding: '0px 0px'
    }
}));

export default function GfoPaymentsListTableComponent(props) {
    const { setRoute, globalState } = useGlobalStateContext();
    const classes = useStyles();
    const {
        rows,
        order,
        orderBy,
        loading,
        error,
        formatRefundReasonAndAmountString,
        isRefundedTransaction,
        showTransactionRefund,
        payment,
        show,
        setShow,
        setPayment,
        setIsRefundHappen,
        noTabs,
        statusFilter,
        setStatusFilter,
        GfoPaymentsFilterTabs,
        page,
        setPage
    } = props;
    let hidePaymentActions = globalState.merchant?.userRole === Roles.User;

    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const mediaLessThan600px = useMediaQuery('(max-width:599px)');

    return (
        <div className={classes.root}>
            {!noTabs && (
                <FilterTabs
                    tabFilterValue={statusFilter}
                    setTabFilterValue={setStatusFilter}
                    tabList={GfoPaymentsFilterTabs}
                />
            )}
            <Paper className={classes.paper}>
                {loading ? (
                    <Box textAlign="center" py={5}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <div className={styles.paymentsNone}>An error occurred, please try reloading your browser.</div>
                ) : (
                    <React.Fragment>
                        {!rows || rows.length === 0 ? (
                            <div className={styles.paymentsNone}>No transactions.</div>
                        ) : (
                            <React.Fragment>
                                <TableContainer className={classes.container}>
                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size="small"
                                        stickyHeader
                                        aria-label="enhanced table"
                                    >
                                        <TableHeader classes={classes} {...props} />
                                        <TableBody>
                                            {stableSort(
                                                rowsPerPage > 0
                                                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : rows,
                                                getComparator(order, orderBy)
                                            ).map((row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                let transactionDetails = _.compact([
                                                    _.compact([row.firstname?.trim(), row.lastname?.trim()]).join(' '),
                                                    row.address?.trim()
                                                ]).join(' - ');

                                                return (
                                                    <React.Fragment>
                                                        <TableRow
                                                            classes={{ selected: classes.selected }}
                                                            className={classes.tableRow + ' ' + styles.tableRow}
                                                            hover
                                                            tabIndex={1}
                                                            key={row.id}
                                                        >
                                                            <TableCell
                                                                id={labelId}
                                                                align="left"
                                                                className={styles.amountColumn}
                                                                style={{ width: '5%' }}
                                                                onClick={() =>
                                                                    setRoute(`/payment-details/${row.id}`, {
                                                                        payment: row
                                                                    })
                                                                }
                                                            >
                                                                {row.refund === '' ? (
                                                                    <React.Fragment>
                                                                        {formatCurrency(
                                                                            row.total,
                                                                            getCurrencyThreeAlpha(
                                                                                globalState.merchant?.country
                                                                            )
                                                                        )}
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        <React.Fragment>
                                                                            {formatCurrency(
                                                                                row.total,
                                                                                getCurrencyThreeAlpha(
                                                                                    globalState.merchant?.country
                                                                                )
                                                                            )}
                                                                        </React.Fragment>
                                                                    </React.Fragment>
                                                                )}
                                                            </TableCell>
                                                            {/* <TableCell
                                                            onClick={() =>
                                                                setRoute(`/payment-details/${row.id}`, {
                                                                    payment: row
                                                                })
                                                            }
                                                        >
                                                            {row.description || '-'}
                                                        </TableCell> */}
                                                            {!mediaLessThan600px && (
                                                                <TableCell
                                                                    className={styles.nameAddressColumn}
                                                                    onClick={() =>
                                                                        setRoute(`/payment-details/${row.id}`, {
                                                                            payment: row
                                                                        })
                                                                    }
                                                                >
                                                                    {row.refund ? (
                                                                        <div className={styles.currencySpan}>
                                                                            {formatRefundReasonAndAmountString(
                                                                                row.refund
                                                                            )}
                                                                            {transactionDetails && (
                                                                                <>
                                                                                    <br></br>
                                                                                    <span>{transactionDetails}</span>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <div className={styles.currencySpan}>
                                                                            {transactionDetails ? (
                                                                                <span>{transactionDetails}</span>
                                                                            ) : (
                                                                                <span>&#8212;</span>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </TableCell>
                                                            )}
                                                            <TableCell
                                                                className={styles.dateColumn}
                                                                style={{ width: '15%' }}
                                                                onClick={() =>
                                                                    setRoute(`/payment-details/${row.id}`, {
                                                                        payment: row
                                                                    })
                                                                }
                                                            >
                                                                {formatDateTimeByFormatString(row.time, 'DD MMM HH:mm')}
                                                            </TableCell>
                                                            {!isRefundedTransaction &&
                                                                (hidePaymentActions ||
                                                                row.total <= 0 ||
                                                                row.payed <= 0 ||
                                                                !row.order_id ||
                                                                row.refund ? (
                                                                    <TableCell
                                                                        className={styles.actionsColumn}
                                                                        size="medium"
                                                                    />
                                                                ) : (
                                                                    <TableCell
                                                                        align="center"
                                                                        className={styles.actionsColumn}
                                                                    >
                                                                        <IconButton
                                                                            className={classes['MuiIconButton-root']}
                                                                            onClick={() => {
                                                                                showTransactionRefund(row);
                                                                            }}
                                                                            disabled={
                                                                                globalState.merchant.status ===
                                                                                MerchantStatus.WATCHLIST
                                                                            }
                                                                        >
                                                                            <SettingsBackupRestoreSharpIcon />
                                                                        </IconButton>
                                                                        {payment.id === row.id && (
                                                                            <TransactionRefund
                                                                                setShow={setShow}
                                                                                payment={row}
                                                                                show={show}
                                                                                setPayment={setPayment}
                                                                                setIsRefundHappen={setIsRefundHappen}
                                                                            />
                                                                        )}
                                                                    </TableCell>
                                                                ))}
                                                        </TableRow>
                                                        {mediaLessThan600px && (
                                                            <TableRow className={styles.mobileViewTransactionDetails}>
                                                                <TableCell
                                                                    colSpan={3}
                                                                    className={styles.nameAddressColumn}
                                                                    onClick={() =>
                                                                        setRoute(`/payment-details/${row.id}`, {
                                                                            payment: row
                                                                        })
                                                                    }
                                                                >
                                                                    {row.refund ? (
                                                                        <div className={styles.currencySpan}>
                                                                            {formatRefundReasonAndAmountString(
                                                                                row.refund
                                                                            )}
                                                                            {transactionDetails && (
                                                                                <>
                                                                                    <br></br>
                                                                                    <span>{transactionDetails}</span>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <div className={styles.currencySpan}>
                                                                            {transactionDetails ? (
                                                                                <span>{transactionDetails}</span>
                                                                            ) : (
                                                                                <span>&#8212;</span>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })}
                                            <TableRow>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                {rows.length > rowsPerPage && (
                                                    <TablePagination
                                                        rowsPerPageOptions={[20, 50, 100, { label: 'All', value: -1 }]}
                                                        // colSpan={6}
                                                        count={rows.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        SelectProps={{
                                                            inputProps: { 'aria-label': 'rows per page' },
                                                            native: true
                                                        }}
                                                        onChangePage={handleChangePage}
                                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                                    />
                                                )}
                                            </TableRow>
                                        </TableFooter>
                                    </Table>

                                    <TableFooterComponent
                                        total={props.total}
                                        resultsCount={rows.length}
                                        filter={props.statusFilter}
                                        path="gfo-payments"
                                        refund={props.refundValue}
                                    />
                                </TableContainer>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </Paper>
        </div>
    );
}

GfoPaymentsListTableComponent.propTypes = {
    rows: PropTypes.array.isRequired,
    statusFilter: PropTypes.string.isRequired,
    setStatusFilter: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    setPayment: PropTypes.func,
    formatRefundReasonAndAmountString: PropTypes.func,
    noTabs: PropTypes.bool
};
