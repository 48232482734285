import React, { useState, useEffect } from 'react';
import ProfileComponent from './profile.component';
import { useGlobalStateContext } from 'contexts';

function Profile() {
    const [user, setUser] = useState();
    const { globalState } = useGlobalStateContext();

    useEffect(() => {
        function fetchData() {
            const user = globalState.user;
            setUser(user);
        }
        fetchData();
    }, [globalState.user]);

    return <ProfileComponent user={user} />;
}

export default Profile;
