import React from 'react';

import MultiPickerInputComponent from './multi-picker-input.component';

/**
 *
 * @param {{
 * list: array;
 * property: function;
 * onChange: function;
 * label: string;
 * }} props
 */
function MultiPickerInput(props) {
    return <MultiPickerInputComponent {...props}></MultiPickerInputComponent>;
}

MultiPickerInput.propspropTypes = {};

export default MultiPickerInput;
