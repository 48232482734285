import { AuthAxios } from './interceptor.service.js';
import { SuccesResponse, ErrorResponse } from 'models';
const { ResellerServiceEndpoint } = require('utils/constants');

const getAllCanonicalResellers = async (resellerId, data) => {
    try {
        const response = await AuthAxios.post(
            `${ResellerServiceEndpoint}/get-all-canonical-resellers/${resellerId}`,
            data
        );

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const createNewCanonicalReseller = async (resellerId, data) => {
    try {
        const response = await AuthAxios.post(
            `${ResellerServiceEndpoint}/create-new-canonical-reseller/${resellerId}`,
            data
        );

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const updateCanonicalResellerData = async (canonicalResellerId, data) => {
    try {
        const response = await AuthAxios.post(
            `${ResellerServiceEndpoint}/update-canonical-reseller-data/${canonicalResellerId}`,
            data
        );

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getCanonicalResellerData = async (canonicalResellerId) => {
    try {
        const response = await AuthAxios.get(
            `${ResellerServiceEndpoint}/get-canonical-reseller-data/${canonicalResellerId}`
        );

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getCanonicalResellersAPI = async () => {
    try {
        const response = await AuthAxios.get(`${ResellerServiceEndpoint}/canonical-resellers`);
        return new SuccesResponse(response.data?.canonicalResellers || []);
    } catch (error) {
        return getError(error);
    }
};

const getCanonicalResellers = async () => {
    let canonicalResellers = sessionStorage.getItem('canonicalResellers');

    if (canonicalResellers) {
        canonicalResellers = JSON.parse(canonicalResellers);
    }

    if (!canonicalResellers?.length) {
        const response = await getCanonicalResellersAPI();
        if (response.isSuccesfully) {
            canonicalResellers = response.data;
            sessionStorage.setItem('canonicalResellers', JSON.stringify(canonicalResellers));
        } else {
            canonicalResellers = [];
        }
    }
    return canonicalResellers;
};

const pdqOrderDetails = async (merchantId) => {
    try {
        const response = await AuthAxios.get(`${ResellerServiceEndpoint}/get-pdq-terminal-ids/${merchantId}`);

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};
const getError = (error) => {
    const message = error.response ? error.response.data : 'An error occurred';
    return new ErrorResponse(message);
};

export default {
    getAllCanonicalResellers,
    createNewCanonicalReseller,
    updateCanonicalResellerData,
    getCanonicalResellerData,
    getCanonicalResellersAPI,
    getCanonicalResellers,
    pdqOrderDetails
};
