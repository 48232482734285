const expressionMeaningRows = [
    {
        expression: '“Contract Form”',
        meaning: 'means the form by which you have agreed to order the Services on the terms of these Conditions;'
    },
    {
        expression: '“Acquiring Services”',
        meaning:
            'means both (A) the processing by Streamline of E-commerce Transactions and (B) the receipt and disbursement of related funds;'
    },
    {
        expression: '“Applicable Law”',
        meaning:
            'means all laws or regulations (and including the requirements of any regulatory authority) applicable to a party or to any Transaction for the time being in force in any jurisdiction;'
    },
    {
        expression: '“Bank Account”',
        meaning:
            'means your business bank account from which Payments shall be requested by the Company via standing order (details of which are set out on the Contract Form) and Receipts shall be transferred to you;'
    },
    {
        expression: '“Business Day”',
        meaning:
            'means a day (other than a Saturday, Sunday or public holiday) when banks in London are open for business;'
    },
    {
        expression: '“Card”',
        meaning:
            'means a credit, debit, charge, purchase or other payment card under a Card Scheme whose payments Barclaycard and/or Smartpay is able to process (as notified by Barclaycard and/or Smartpay to the Company from time to time);'
    },
    {
        expression: '“Card Scheme”',
        meaning:
            'means schemes governing the issue and use of Cards listed as follows, or as may be approved and notified by Barclaycard and/or Smartpay to the Company in writing from time to time: Visa Europe, Visa Inc, MasterCard Worldwide, UK Maestro, International Maestro, American Express, China UnionPay, Diners Club International, Discover Financial Services, JCB and Laser Card;'
    },
    { expression: '“Cardholder”', meaning: 'means the Person who is the authorized user of a Card;' },
    {
        expression: '“Claim”',
        meaning:
            'means any action, proceedings, cost, claim, demand, charge, expense (including legal fees and expenses), assessment, loss, damages, whether arising in tort, contract, for breach of statutory duty or otherwise;'
    },
    { expression: '“Commencement Date”', meaning: 'means the date of that the Company signs the Contract Form;' },
    {
        expression: '“Commission”',
        meaning:
            'means the Company’s entitlement to a fee equivalent to 3.4 percent plus £0.20 of the value of each Order'
    },
    {
        expression: '“Company”',
        meaning:
            "means Datman Ltd, a company registered with company number 55524 whose registered office is at Nerine House, PO Box 434, St George's Place, St Peter Port, Guernsey, GY1 3ZG;"
    },
    {
        expression: '“Conditions”',
        meaning:
            'means the terms and conditions set out in this document as amended from time to time in accordance with clause 15;'
    },
    {
        expression: '“Confidential Information”',
        meaning:
            'means information that is designated as "confidential" or which by its nature is clearly confidential including any information relating to: this Contract; a Customer or a Customer’s use of a Card; any one of the Card Schemes; your and our respective technology, technical processes, procedures, business affairs, finance, security procedures; lists of suppliers and Customers and other proprietary knowledge, however any such information is conveyed or presented, including text, information, technical specifications, unpublished patent specifications, Data, drawings, plans, processes, photographs, databases, computer software in disk, cassette, tape or electronic form and items of computer hardware on any medium including all electronic, optical, magnetic and tangible media, oral descriptions, demonstrations or observations;'
    },
    {
        expression: '“Contract”',
        meaning:
            'means the contract between the Company and you for Acquiring Services, Merchant Services and DataAnalysis in accordance with these Conditions;'
    },
    {
        expression: '“Customer”',
        meaning:
            'means a Person who has made an Order with you via the internet and has initiated a Transaction in respect of that Order, including being a Cardholder;'
    },
    {
        expression: '“Data”',
        meaning:
            'means documents, data and records of any kind relating to Transactions (including, for the avoidance of doubt, data relating to Cards and Customers) and may include Transaction Personal Data and Sensitive Authentication Data;'
    },
    {
        expression: '“Data Controller”',
        meaning:
            'means the Company, or any Person who alone or jointly or in common with other Persons determines the purposes for which and the manner in which Personal Data are, or are to be, processed;'
    },
    {
        expression: '“Data Processor”',
        meaning:
            'means a Person (other than an employee of the Data Controller) who processes Personal Data on behalf of the Data Controller;'
    },
    { expression: '“Data Analysis”', meaning: 'means the analysis of Material in the form of Data Analysis Reports;' },
    {
        expression: '“Data Analysis Reports”',
        meaning: 'means detailed and focussed reports, spreadsheets, charts, or otherwise, of Material provided by you;'
    },
    {
        expression: '“Downtime”',
        meaning:
            'means the amount of time in any calendar month during which the Services are not available, excluding any unavailability due to scheduled maintenance;'
    },
    {
        expression: '“E-commerce Transactions”',
        meaning:
            'means Transactions that are sales in which payment for a Customer Order is initiated by EPOS via the internet, or otherwise;'
    },
    {
        expression: '“Financial Institution”',
        meaning:
            'means any Third Party credit or financial institution, which may be involved, or which we in our sole and absolute discretion involve, in the course of our provision of any of the Services;'
    },
    { expression: '“EPOS”', meaning: 'means the electronic point of sale by which Customers pay for their Orders;' },
    { expression: '“Initial Period”', meaning: 'means a period of 4 calendar months;' },
    {
        expression: '“Intellectual Property Rights”',
        meaning:
            'means all intellectual property rights wherever in the world arising, whether registered or unregistered (and including any application), including copyright, know-how, confidential information, trade secrets, business names and domain names, trade marks, service marks, trade names, patents, petty patents, utility models, design rights, database rights and any applications for the protection of registration or these rights (in the nature of unfair competition rights or rights to sue for passing off, or otherwise) and all renewals and extensions of the same existing in any part of the world whether now known or in the future created to which a party may be entitled;'
    },
    {
        expression: '“Material”',
        meaning:
            'means the static data generated by Third Party Products including, but not limited to Customer Order details, Personal Transaction Data, takeaway menu items, price lists and other information;'
    },
    {
        expression: '“Merchant Bank Account”',
        meaning:
            'means an account with a duly authorized credit institution maintained by the Company for the purpose of receiving Remittances generated by E-commerce Transactions;'
    },
    {
        expression: '“Merchant Data Account”',
        meaning: 'means an electronic management information account containing Data related to the Transactions;'
    },
    {
        expression: '“Merchant Services”',
        meaning: 'means the processing of E-commerce Transactions via the Merchant Bank Account by Streamline;'
    },
    { expression: '“Office Hours”', meaning: 'means 09:00 to 17:30 during Business Days;' },
    { expression: '“Orders”', meaning: 'means orders for food items and other takeaway goods by your Customers;' },
    {
        expression: '“Payments”',
        meaning:
            'means the sum of £15 per week (inclusive of VAT) for the Services or such other amount agreed by the Company and stated on the Contract Form;'
    },
    {
        expression: '“PCI SSC Standards”',
        meaning:
            'means the Payment Card Industry Data Security Standard, Payment Application Data Security Standard and the PIN Transaction Security Standard as updated from time to time and published by the PCI Security Standards Council at https://www.pcisecuritystandards.org'
    },
    {
        expression: '“PCI DSS”',
        meaning:
            'means the Payment Card Industry Data Security Standards published from time to time by the Payment Card Industry Security Standards Council and available at https://www.pcisecuritystandards.org'
    },
    {
        expression: '“Person”',
        meaning:
            'means any individual, company, body corporate, corporation sole or aggregate, government, state or agency of a state, firm, partnership, association, organization or trust (in each case, irrespective of the jurisdiction in or under the law of which it was incorporated or exists);'
    },
    {
        expression: '“Personal Data”',
        meaning:
            'means data which relates to a living individual who can be identified from those data or a combination of those data and other information in the possession of, or likely to come into the possession of, the Data Controller;'
    },
    {
        expression: '“Personnel”',
        meaning:
            'means employees, agents, consultants, contractors and sub-contractors and their employees, agents, consultants and sub-contractors;'
    },
    {
        expression: '“Receipts”',
        meaning:
            'means any payment made by the Company to you (less its Commission) per Transaction and/or E-commerce Transaction;'
    },
    {
        expression: '“Remittance”',
        meaning:
            'means any payment made by Barclaycard and/or Smartpay to the Company in the course of providing Acquiring Services;'
    },
    {
        expression: '“Remittance Date”',
        meaning: 'means the Business Day (notified to us by Streamline from time to time) on which Remittance occurs;'
    },
    {
        expression: '“Smartpay”',
        meaning:
            'means Barclaycard Smartpay, which is the supplier of online gateway services to the Company to manage and integrate the Website and Sensitive Authentication Data relating to Transactions and/or E-commerce transactions processed by Streamline and is PCI DSS Level 1 compliant to ensure the highest level of Customer Card and Data security;'
    },
    {
        expression: '“Sensitive Authentication Data”',
        meaning:
            'means security related information used to authenticate Cardholders and authorize Card transactions. Sensitive Authentication Data elements include magnetic stripe data (PAVE, CVV, CVC, CID) PINs, PIN blocks and the three or four digit number security code found either on the front or on the back of a card (e.g. MasterCard CVC2/Visa CVV2);'
    },
    {
        expression: '“Services”',
        meaning: 'means Data Analysis, Acquiring Services and Merchant Services in accordance with these Conditions;'
    },
    { expression: '“Term”', meaning: 'means the term of this Contract as provided for in clause 2;' },
    { expression: '“Third Party” ', meaning: 'means a Person who is not a party to this Contract;' },
    {
        expression: '“Third Party Product”',
        meaning: 'means a product (whether hardware, software or services) supplied to you by a Third Party;'
    },
    {
        expression: '“Transaction”',
        meaning:
            'means any payment by a Customer for goods purchased from you using a Card in relation to which the Company provides Merchant Services to you;'
    },
    {
        expression: '“Transaction Personal Data”',
        meaning:
            'means Personal Data which it is necessary to provide or to Process in connection with Transactions in the course of providing the Services;'
    },
    {
        expression: '“Uptime”',
        meaning:
            'means the amount of time in any calendar month that is not Downtime. When expressed as a percentage it means the amount of Uptime divided by the total time in any calendar month;'
    },
    {
        expression: '“Website”',
        child: true,
        meaning: {
            datman: 'means the Company website (domain name www.datman.je);',
            omniPay: 'means the Company website (domain name www.omni-pay.com);'
        }
    },
    {
        expression: '“Weekly Payment Date”',
        meaning:
            'the agreed day of every week during the Term on which the Payment is paid, which in the absence of express Contract shall be a Monday;'
    },
    {
        expression: '“Barclaycard”',
        meaning:
            'Means Barclaycard the trading name of Barclays Bank PLC , which is the supplier of the Acquiring Services to the Company;'
    },
    { expression: '“We/we”, “Us/us”,“Our/our”', meaning: 'means the Company;' },
    { expression: '“You/you”', meaning: 'means your name or your company name as specified on the Contract Form.' }
];

export default expressionMeaningRows;
