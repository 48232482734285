import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useGlobalStateContext } from 'contexts';
import { getCurrencySymbol } from 'utils/getCurrencyByCountry';
import { ResellerType } from 'utils/enums/ResellerType';

const ExportPDF = (props) => {
    const { globalState } = useGlobalStateContext();
    const isDatman = globalState.reseller?.name === ResellerType.DATMAN;
    const { merchant, logo, pdfTitle, data, address, merchantMobile, companyAddress, ownerName } = props;
    let resellerName = `${globalState?.reseller.name} LTD`;
    const tooltip = (
        <Tooltip id="tooltip">
            Click to <strong>download</strong> pdf.
        </Tooltip>
    );
    const date = moment(globalState.merchant.createdAt).format('Do of MMMM YYYY HH:mm');
    var companyPhoneNumber = `Phone Number : 03330165548`;
    let finalStatementOne = `We, ${
        merchant?.name
    } hereby wish to appoint you to provide the services as described and on the terms above and I acknowledge by signing this online document I am agreeing to your Terms and Conditions of ${
        globalState?.reseller?.name
    } (which can be found on www.datman.je or can be provided upon request). On ${date} by ${
        ownerName ? ownerName : '__'
    } submitted an online application from through our website www.datman.je and thereby agreed to our terms and conditions a full copy of which can be found on our website or is available upon request. We hereby acknowledge the same.`;

    const marginLeft = 13;
    let marginTop = 60;
    const vAlignRight = 280;
    let addressLine1 = address?.addressLine1 ? address?.addressLine1 : '';
    let city = address?.city ? address?.city : '';
    let addressLine2 = address?.addressLine2 ? address?.addressLine2 : '';
    let postCode = address?.postCode ? address?.postCode : '';
    const printAgreementTableData = [
        { headerColumn: 'Name', data: merchant.name },
        { headerColumn: 'Address', data: addressLine1 + ' ' + addressLine2 },
        { headerColumn: 'City', data: city },
        { headerColumn: 'Postcode', data: postCode },
        { headerColumn: 'Mobile', data: `${merchantMobile}` },
        {
            headerColumn: 'Rent',
            data: `${getCurrencySymbol(globalState.merchant.country)} ${data.contract_rent}`
        },
        { headerColumn: 'Contract Length', data: data.contract_length_print_agreement },
        { headerColumn: 'Notice Period', data: data.notice_period },
        { headerColumn: 'Extra Comments', data: data.extra_comments },
        { headerColumn: 'Description of Service Provided', data: data.services_description }
    ];
    let merchantName = merchant.name.length > 40 ? merchant.name.slice(0, 40) + '...' : merchant.name;
    addressLine1 = addressLine1.length > 40 ? addressLine1.slice(0, 40) + '...' : addressLine1;
    addressLine2 = addressLine2.length > 40 ? addressLine2?.slice(0, 40) + '...' : addressLine2;
    city = city?.length > 40 ? city?.slice(0, 40) + '...' : city;
    const exportPDF = async () => {
        const doc = new jsPDF('l');
        var companyName = doc.splitTextToSize(!isDatman ? 'OmniPay LTD' : 'Mypay LTD', 60);
        var companyAddressLine1 = doc.splitTextToSize(companyAddress, 60);
        doc.setFontSize(14); //Merchant name
        let content = {
            startY: marginTop + 26,
            body: printAgreementTableData,
            rowPageBreak: 'auto',
            bodyStyles: { valign: 'top' },
            columnStyles: {
                0: {
                    columnWidth: 30
                }
            }
        };

        doc.text(merchantName, marginLeft, 15, 'left');
        doc.text(addressLine1, marginLeft, 22, 'left');
        if (!addressLine2) {
            doc.text(city, marginLeft, 28, 'left');
            doc.text(postCode, marginLeft, 34, 'left');
        } else {
            doc.text(addressLine2, marginLeft, 28, 'left');
            doc.text(city, marginLeft, 34, 'left');
            doc.text(postCode, marginLeft, 40, 'left');
        }

        //Add Logo to right
        isDatman
            ? doc.addImage(logo, 'png', 120, 10, 40, 33, 'center', 'FAST')
            : doc.addImage(logo, 'png', 120, 10, 40, 14, 'center', 'FAST');

        doc.text(companyName, vAlignRight, 15, 'right');
        doc.text(companyAddressLine1, vAlignRight, 22, 'right');
        isDatman && doc.text(companyPhoneNumber, vAlignRight, 44, 'right');

        //Show transactions title
        doc.text(pdfTitle, 15, 70);
        doc.setFontSize(11);

        //Put table in pdf
        let startX = 15;
        let height = 182;
        doc.autoTable(content);
        doc.setFontSize(10.5);
        doc.text(finalStatementOne, startX, height, {
            maxWidth: 270
        });

        doc.text(resellerName, 15, 205);

        doc.save(`Contract_agreement_${globalState.merchant?.id}.pdf`);
    };
    return (
        <div>
            <OverlayTrigger placement="top" overlay={tooltip}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<GetAppIcon />}
                    onClick={() => {
                        exportPDF();
                    }}
                >
                    PDF
                </Button>
            </OverlayTrigger>
        </div>
    );
};

export default ExportPDF;
