import { AuthAxios } from './interceptor.service.js';
import { CustomerServiceEndpoint } from '../utils/constants';
import { SuccesResponse, ErrorResponse } from 'models';

const getClients = async (businessId) => {
    try {
        const requestResult = await AuthAxios.get(`${CustomerServiceEndpoint}/businesses/${businessId}/clients`);
        return new SuccesResponse(requestResult.data.clients);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
};

const getClient = async (businessId, clientId) => {
    try {
        const requestResult = await AuthAxios.get(
            `${CustomerServiceEndpoint}/businesses/${businessId}/clients/${clientId}`
        );
        return new SuccesResponse(requestResult.data);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
};

const createClient = async (businessId, client) => {
    try {
        const response = await AuthAxios.post(`${CustomerServiceEndpoint}/businesses/${businessId}/clients`, {
            client: client
        });
        return new SuccesResponse(response.data);
    } catch (error) {
        const message = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(message);
    }
};

const updateClient = async (client) => {
    try {
        const response = await AuthAxios.put(
            `${CustomerServiceEndpoint}/businesses/${client.businessId}/clients/${client.id}`,
            { client }
        );
        return new SuccesResponse(response.data);
    } catch (error) {
        const message = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(message);
    }
};

const deleteClient = async (businessId, clientId) => {
    try {
        const response = await AuthAxios.delete(
            `${CustomerServiceEndpoint}/businesses/${businessId}/clients/${clientId}`
        );

        return new SuccesResponse(response.data);
    } catch (error) {
        //
        const message = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(message);
    }
};

const checkClientNameIsUnique = async (businessId, clientName) => {
    try {
        const response = await AuthAxios.get(
            `${CustomerServiceEndpoint}/businesses/${businessId}/clients/is-unique?clientName=${clientName}`
        );
        return new SuccesResponse(response.data);
    } catch (error) {
        const message = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(message);
    }
};

export default {
    getClients,
    createClient,
    getClient,
    updateClient,
    deleteClient,
    checkClientNameIsUnique
};
