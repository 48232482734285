import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useGlobalStateContext } from 'contexts/global-state/global-state.context';

export const Context = React.createContext();

function loadLocaleData(locale) {
    switch (locale) {
        case 'es-mx':
            return import('../../compiled-lang/es-mx.json');
        case 'en-GB':
            return import('../../compiled-lang/en.json');
        default:
            return import('../../compiled-lang/en.json');
    }
}

const defaultLanguage = localStorage.getItem('selectedLanguage');
const local = defaultLanguage;

let lang = loadLocaleData(local);

function LanguageContext(props) {
    const { globalState } = useGlobalStateContext();

    const [locale, setLocale] = useState(local);
    const [messages, setMessages] = useState(lang);

    async function selectLanguage(e) {
        const newLocale = e?.target ? e.target.value : e;
        localStorage.setItem('selectedLanguage', newLocale);
        setLocale(newLocale);
        let messageFile = await loadLocaleData(newLocale);
        setMessages(messageFile);
    }

    useEffect(() => {
        let selectedLanguage = localStorage.getItem('selectedLanguage');
        selectedLanguage = selectedLanguage ? selectedLanguage : 'en-GB';
        selectLanguage(selectedLanguage);
    }, [globalState.merchant]);

    return (
        <Context.Provider value={{ locale, selectLanguage }}>
            <IntlProvider messages={messages} locale={locale} defaultLocale="en-GB">
                {props.children}
            </IntlProvider>
        </Context.Provider>
    );
}

export { LanguageContext };
