import { AuthAxios } from './interceptor.service.js';
import { SettingsServiceEndpoint } from '../utils/constants';
import { ErrorResponse, SuccesResponse } from 'models';

/**
 *
 * @param {String} postCode
 */
async function getPostCodeInformation(postCode) {
    try {
        const result = await AuthAxios.get(`${SettingsServiceEndpoint}/address?postCode=${postCode}`);

        return new SuccesResponse(result.data);
    } catch (err) {
        return new ErrorResponse('Post code validation error');
    }
}

export default {
    getPostCodeInformation
};
