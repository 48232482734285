import React from 'react';
import style from './missing-merchant-document.module.scss';
import {
    Paper,
    Typography,
    Grid,
    Divider,
    Button,
    Box,
    CircularProgress,
    NativeSelect,
    OutlinedInput
} from '@material-ui/core';
import { FormItem, Input, Label } from '../../onboarding/onboarding-ui-components';
import NumberFormat from 'react-number-format';
import { nationalities } from 'utils/enums/Nationality';
import { SnackBarAndAlert } from 'components';

function MissingDocument(props) {
    const {
        loading,
        merchantData,
        setMerchantData,
        monthInputElementRef,
        yearInputElementRef,
        dayChangeHandler,
        monthChangeHandler,
        yearChangeHandler,
        dobValidationFailed,
        dobInvalidFields,
        onSubmit,
        isDisabled,
        error,
        handleClose,
        documentToView
    } = props;

    return (
        <div>
            {loading ? (
                <Box textAlign="center" py={5}>
                    <CircularProgress />
                </Box>
            ) : error ? (
                <div className={style.refundsNone}>An error occurred, please try reloading your browser.</div>
            ) : (
                <React.Fragment>
                    <Paper className={style.documentManagementContainer}>
                        <Grid className={style.pageTitle} container justify="space-between">
                            <Grid>
                                <Typography className={style.documentManagementHeader}>
                                    Nationality and DOB Update
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid className={style.managementArea}>
                            <Grid className={style.merchantDetails}>
                                <Grid className={style.businessDetailContainer}>
                                    <FormItem>
                                        <Label text="Legal name" id="legal-name"></Label>
                                        <Input
                                            disabled
                                            placeholder="Legal name"
                                            id="legal-name-input"
                                            value={merchantData.legalName}
                                        />
                                    </FormItem>
                                    <FormItem>
                                        <Label text="Merchant Id" id="mechant-id"></Label>
                                        <Input disabled value={merchantData.MerchantId} />
                                    </FormItem>
                                    <FormItem>
                                        <Label text="Document Type" id="document-type"></Label>

                                        <Input
                                            disabled
                                            placeholder=""
                                            id="legal-name-input"
                                            value={
                                                documentToView[0]?.documentTypeName
                                                    ? documentToView[0]?.documentTypeName
                                                    : 'N/A'
                                            }
                                        />
                                    </FormItem>

                                    <FormItem>
                                        <Label text="Nationality" id="owner-label-nationality"></Label>
                                        <NativeSelect
                                            input={
                                                <Input
                                                    placeholder="Nationality"
                                                    id="owner-select-nationality"
                                                    value={merchantData?.OwnersDetail?.nationality}
                                                    onChange={(e) =>
                                                        setMerchantData({
                                                            ...merchantData,
                                                            OwnersDetail: {
                                                                ...merchantData.OwnersDetail,
                                                                nationality: e.target.value
                                                            }
                                                        })
                                                    }
                                                ></Input>
                                            }
                                            value={merchantData?.OwnersDetail?.nationality}
                                        >
                                            {nationalities.map((nationality) => (
                                                <option key={nationality} value={nationality}>
                                                    {nationality}
                                                </option>
                                            ))}
                                        </NativeSelect>
                                    </FormItem>
                                    <FormItem>
                                        <Label text="Date of birth" id="owner-label-dateofbirth"></Label>
                                        <div className={style.dateOfBirthWrapper}>
                                            <NumberFormat
                                                className={`${style.numberFormatInput} ${
                                                    dobValidationFailed || dobInvalidFields.includes('day')
                                                        ? style.containsErrorDOB
                                                        : ''
                                                }`}
                                                customInput={OutlinedInput}
                                                inputProps={{ inputMode: 'numeric' }}
                                                pattern="\d*"
                                                allowLeadingZeros={true}
                                                decimalSeparator={false}
                                                allowNegative={false}
                                                isNumericString={true}
                                                placeholder="DD"
                                                value={merchantData?.OwnersDetail?.birthDate?.day}
                                                id="date-of-birth-day-input"
                                                onValueChange={dayChangeHandler}
                                                aria-describedby="outlined-weight-helper-text"
                                                labelWidth={0}
                                                required
                                            />
                                            <NumberFormat
                                                className={`${style.numberFormatInput} ${
                                                    dobValidationFailed || dobInvalidFields.includes('month')
                                                        ? style.containsErrorDOB
                                                        : ''
                                                }`}
                                                customInput={OutlinedInput}
                                                inputProps={{ inputMode: 'numeric', ref: monthInputElementRef }}
                                                pattern="\d*"
                                                allowLeadingZeros={true}
                                                decimalSeparator={false}
                                                allowNegative={false}
                                                isNumericString={true}
                                                placeholder="MM"
                                                value={merchantData?.OwnersDetail?.birthDate?.month}
                                                id="date-of-birth-month-input"
                                                onValueChange={monthChangeHandler}
                                                aria-describedby="outlined-weight-helper-text"
                                                labelWidth={0}
                                                required
                                            />
                                            <NumberFormat
                                                className={`${style.numberFormatInput} ${
                                                    dobValidationFailed || dobInvalidFields.includes('year')
                                                        ? style.containsErrorDOB
                                                        : ''
                                                }`}
                                                customInput={OutlinedInput}
                                                inputProps={{
                                                    maxlength: '4',
                                                    inputMode: 'numeric',
                                                    ref: yearInputElementRef
                                                }}
                                                pattern="\d*"
                                                allowLeadingZeros={true}
                                                decimalSeparator={false}
                                                allowNegative={false}
                                                isNumericString={true}
                                                placeholder="YYYY"
                                                value={merchantData?.OwnersDetail?.birthDate?.year}
                                                id="date-of-birth-year-input"
                                                onValueChange={yearChangeHandler}
                                                aria-describedby="outlined-weight-helper-text"
                                                labelWidth={0}
                                                required
                                            />
                                        </div>
                                    </FormItem>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid>
                                {!loading && documentToView.length === 0 && (
                                    <div className={style.errorview}>
                                        <p
                                            className={
                                                style.dialogWrapper__dialogBody__imageWrapper__imageContainer__text
                                            }
                                        >
                                            Passport/Driving Licence not available
                                        </p>
                                        <p
                                            className={
                                                style.dialogWrapper__dialogBody__imageWrapper__imageContainer__loading
                                            }
                                        >
                                            Nothing to show
                                        </p>
                                    </div>
                                )}
                                {!loading && documentToView.extension !== 'pdf' && documentToView.length !== 0 && (
                                    <div
                                        className={
                                            style.dialogWrapper__dialogBody__imageWrapper__imageContainer__imageDiv
                                        }
                                    >
                                        <img
                                            src={documentToView[0]?.url}
                                            alt="document view"
                                            className={style.imageStyle}
                                            // width="600"
                                            // height="600"
                                        />
                                    </div>
                                )}
                                {!loading && documentToView.extension === 'pdf' && documentToView.length !== 0 && (
                                    <iframe
                                        src={documentToView[0]?.url}
                                        title="Web viewer"
                                        frameBorder="0"
                                        width="500"
                                        height="600"
                                        className={
                                            style.dialogWrapper__dialogBody__imageWrapper__imageContainer__iframe
                                        }
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid className={style.container}>
                        <Button
                            id="update-btn"
                            color="primary"
                            variant="contained"
                            size="large"
                            className={style.submitBtn}
                            disabled={isDisabled}
                            onClick={onSubmit}
                        >
                            Submit
                        </Button>
                    </Grid>
                    {error && (
                        <SnackBarAndAlert open={error} onClose={handleClose} type="error">
                            {error}
                        </SnackBarAndAlert>
                    )}
                    {error && (
                        <SnackBarAndAlert open={error} onClose={handleClose} type="success">
                            {error}
                        </SnackBarAndAlert>
                    )}
                </React.Fragment>
            )}
        </div>
    );
}
export default MissingDocument;
