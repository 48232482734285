import React, { useState } from 'react';
import InternalTransfersComponent from './internal-transfers.component';
import { useGlobalStateContext } from 'contexts';

const InternalTransfersList = (props) => {
    const { globalState } = useGlobalStateContext();
    const [show, setShow] = useState(false);
    const [totalPendingAmount, setTotalPendingAmount] = useState(0);
    const [enableWithdrawal, setEnableWithdrawal] = useState(false);
    const [loading, setLoading] = useState(false);

    return (
        <InternalTransfersComponent
            {...props}
            merchant={globalState.merchant}
            enableWithdrawal={enableWithdrawal}
            totalPendingAmount={totalPendingAmount}
            setTotalPendingAmount={setTotalPendingAmount}
            show={show}
            setShow={setShow}
            setEnableWithdrawal={setEnableWithdrawal}
            loading={loading}
            setLoading={setLoading}
        />
    );
};

export default InternalTransfersList;
