import { AuthAxios, DatmanAxios } from './interceptor.service.js';
import { SettingsServiceEndpoint, DatmanAPIEndpoint } from '../utils/constants';
import { SuccesResponse, ErrorResponse } from 'models';

async function getBankDetails(merchantId) {
    try {
        const requestResult = await AuthAxios.get(`${SettingsServiceEndpoint}/bank/${merchantId}`);

        return new SuccesResponse(requestResult.data);
    } catch (err) {
        const errMessage = err.response?.data ?? 'An error occurred';
        return new ErrorResponse(errMessage, err.response?.status);
    }
}

async function getNewBankUpdateRequestDetails(merchantId) {
    try {
        const requestResult = await AuthAxios.get(`${SettingsServiceEndpoint}/get-new-bank-details/${merchantId}`);

        return new SuccesResponse(requestResult.data);
    } catch (err) {
        const errMessage = err.response?.data ?? 'An error occurred';
        return new ErrorResponse(errMessage, err.response?.status);
    }
}

async function raiseNewBankDetailsUpdateRequest(merchantId, bankDetails, csrfToken) {
    try {
        const requestResult = await AuthAxios.post(
            `${SettingsServiceEndpoint}/update-bank-details-request/${merchantId}`,
            bankDetails,
            { headers: { 'X-CSRF-TOKEN': csrfToken } }
        );

        return new SuccesResponse(requestResult.data);
    } catch (err) {
        const errMessage = err.response?.data ?? 'An error occurred';
        return new ErrorResponse(errMessage, err.response?.status);
    }
}

async function rejectNewBankDetailsUpdateRequest(merchantId, data) {
    try {
        const requestResult = await AuthAxios.post(
            `${SettingsServiceEndpoint}/reject-update-bank-details-request/${merchantId}`,
            data
        );

        return new SuccesResponse(requestResult.data);
    } catch (err) {
        const errMessage = err.response?.data ?? 'An error occurred';
        return new ErrorResponse(errMessage, err.response?.status);
    }
}

async function getMaskedBankDetails(merchantId) {
    try {
        const requestResult = await AuthAxios.get(`${SettingsServiceEndpoint}/bank-masked/${merchantId}`);

        return new SuccesResponse(requestResult.data);
    } catch (err) {
        const errMessage = err.response?.data ?? 'An error occurred';
        return new ErrorResponse(errMessage, err.response?.status);
    }
}

async function getBankErrorList() {
    try {
        const requestResult = await DatmanAxios.get(`${DatmanAPIEndpoint}/portal/bank/errors`);

        return new SuccesResponse(requestResult.data);
    } catch (err) {
        const errMessage = err.response?.data ?? 'An error occurred';
        return new ErrorResponse(errMessage, err.response?.status);
    }
}

async function updateBankDetails(merchantId, bankDetails) {
    try {
        const response = await AuthAxios.post(`${SettingsServiceEndpoint}/bank/${merchantId}`, bankDetails);
        return new SuccesResponse(response.data);
    } catch (error) {
        const message = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(message);
    }
}

async function validateBankDetailsForUK(bankDetails) {
    try {
        const response = await AuthAxios.post(`${SettingsServiceEndpoint}/validate-bank-details`, bankDetails);

        return new SuccesResponse(response.data);
    } catch (err) {
        return new ErrorResponse();
    }
}

export default {
    getBankDetails,
    updateBankDetails,
    validateBankDetailsForUK,
    getMaskedBankDetails,
    getNewBankUpdateRequestDetails,
    raiseNewBankDetailsUpdateRequest,
    rejectNewBankDetailsUpdateRequest,
    getBankErrorList
};
