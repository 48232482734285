import React from 'react';
import { Grid, Box, Paper, Button } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import browserHistory from 'browserHistory';
import { PaymentDetailsContainer, DatmanResellerPaymentDetailsContainer } from 'components';
import WithdrawalContainer from '../withdrawal/withdrawal.container';
import style from './payment-details.module.scss';
import { merchantTypes } from 'utils/helper.js';
import { useGlobalStateContext } from 'contexts';

const PaymentDetailsComponent = (props) => {
    const { id: transactionId } = props.match.params;
    const { payment } = props.location.state;
    const { globalState } = useGlobalStateContext();
    const showMerchantName = merchantTypes.includes(globalState.merchant?.merchantType);

    return (
        <React.Fragment>
            <Grid container className={style.container}>
                <Grid item xs={12}>
                    <WithdrawalContainer />
                    <Box className={style.backbtnContainer} my={1} pt={5}>
                        <Button
                            color="primary"
                            variant="outlined"
                            size="small"
                            startIcon={<NavigateBeforeIcon />}
                            onClick={() => browserHistory.goBack()}
                            className={style.backbtn}
                        >
                            <span>Back</span>
                        </Button>
                    </Box>
                    <Paper className={style.paymentsContainer}>
                        {showMerchantName ? (
                            <DatmanResellerPaymentDetailsContainer transactionId={transactionId} payment={payment} />
                        ) : (
                            <PaymentDetailsContainer transactionId={transactionId} payment={payment} />
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default PaymentDetailsComponent;
