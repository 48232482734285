import React, { useState, useEffect, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import PostCodeSelectorComponent from './post-code-selector.component';
import { PostCodeService } from 'services';
import { isPostcodeValid } from 'utils/postcodeValidator';
import { debounce } from 'lodash';
import { useGlobalStateContext } from 'contexts';
import { MerchantCountries } from 'utils/enums/MerchantCountries';

/**
 *
 * @param {{
 * onPostCodeChange: (postCode) => {},
 * onAddressObjectChange: (addressObject) => ()
 * postCodeValue: string
 * }} props
 */

function PostCodeSelector(props) {
    const {
        postCodeValue,
        postCodeLookup,
        disabled,
        onPostCodeChange,
        emptyField,
        onAddressObjectChange = () => {},
        placeholder
    } = props;
    const { globalState } = useGlobalStateContext();

    const [isPostCodeLoading, setIsPostCodeLoading] = useState(false);
    const [listPostCodeAddresses, setListPostCodeAddresses] = useState([]);
    const [postCodeValueBuffer, setPostCodeValueBuffer] = useState('');
    const [postCodeOfSearchResults, setPostCodeOfSearchResults] = useState('');

    const GetPostCodeInformation = useCallback(debounce(fetchPostCode, 2000), []);

    async function fetchPostCode(postCodeValue) {
        setIsPostCodeLoading(true);
        const result = await PostCodeService.getPostCodeInformation(postCodeValue);
        if (result.isSuccesfully) {
            setListPostCodeAddresses(result.data.addresses);
            setPostCodeValueBuffer(postCodeValue);
            setPostCodeOfSearchResults(result.data.postcode);
        } else {
            setIsPostCodeLoading(false);
            setListPostCodeAddresses([]);
        }
    }

    useEffect(() => {
        if (isPostcodeValid(postCodeValue)) {
            GetPostCodeInformation(postCodeValue);
        }
        // eslint-disable-next-line
    }, [postCodeValue]);

    return (
        <PostCodeSelectorComponent
            emptyField={emptyField}
            onAddressObjectChange={onAddressObjectChange}
            onPostCodeChange={onPostCodeChange}
            postCodeValue={postCodeValue}
            isPostCodeLoading={isPostCodeLoading}
            listPostCodeAddresses={listPostCodeAddresses}
            setListPostCodeAddresses={setListPostCodeAddresses}
            setIsPostCodeLoading={setIsPostCodeLoading}
            postCodeValueBuffer={postCodeValueBuffer}
            postCodeOfSearchResults={postCodeOfSearchResults}
            showLookupView={
                [MerchantCountries.UNITED_KINGDOM].includes(globalState?.merchant?.country) || postCodeLookup
            }
            disabled={disabled}
            placeholder={placeholder}
        />
    );
}

PostCodeSelector.propTypes = {
    postCodeValue: PropTypes.string,
    postCodeValueBuffer: PropTypes.string,
    onPostCodeChange: PropTypes.func,
    onAddressObjectChange: PropTypes.func
};

export default PostCodeSelector;
