import React from 'react';
import PropTypes from 'prop-types';
import { NativeSelect, Grid } from '@material-ui/core';
import { FormItem, Label, Input } from '../../../onboarding/onboarding-ui-components';
import { useAdminFunctionsContext } from 'contexts';
import InvoiceToggleModesDialog from './invoice-toggle-mode-dialog/invoice-toggle-modes-dialog.container';
import { LoadingScreen } from 'components';

export default function InvoiceToggleModesComponent(props) {
    const { adminFunctions } = useAdminFunctionsContext();
    const {
        showInvoiceUpdateDialogBox,
        setShowInvoiceUpdateDialogBox,
        loading,
        setLoading,
        showInvoiceStatus,
        setShowInvoiceStatus,
        showInvoiceUpdate,
        setShowInvoiceUpdate
    } = props;

    return (
        <React.Fragment>
            <div>
                <FormItem>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={5}>
                            <Label text="Invoice" id="merchant-invoice"></Label>
                        </Grid>
                        <Grid item xs={5} md={4}>
                            <NativeSelect
                                input={
                                    <Input
                                        id="merchant-invoice-select"
                                        value={adminFunctions.isInvoiceEnabled === false ? 'false' : 'true'}
                                        onChange={(e) => {
                                            //
                                            // setTestModeReject(false);
                                            setShowInvoiceUpdateDialogBox(true);
                                            setShowInvoiceUpdate(true);
                                            if (e.target.value === 'true') {
                                                setShowInvoiceStatus(true);
                                            } else {
                                                setShowInvoiceStatus(false);
                                            }
                                        }}
                                    ></Input>
                                }
                            >
                                <option value={true}>Y</option>
                                <option value={false}>N</option>
                            </NativeSelect>
                        </Grid>
                    </Grid>
                </FormItem>
            </div>

            <InvoiceToggleModesDialog
                showInvoiceUpdateDialogBox={showInvoiceUpdateDialogBox}
                setShowInvoiceUpdateDialogBox={setShowInvoiceUpdateDialogBox}
                setLoading={setLoading}
                showInvoiceStatus={showInvoiceStatus}
                setShowInvoiceStatus={setShowInvoiceStatus}
                showInvoiceUpdate={showInvoiceUpdate}
                setShowInvoiceUpdate={setShowInvoiceUpdate}
            />
            <LoadingScreen open={loading}></LoadingScreen>
        </React.Fragment>
    );
}

InvoiceToggleModesComponent.propTypes = {
    onChange: PropTypes.func,
    showInvoiceUpdateDialogBox: PropTypes.bool,
    setShowInvoiceUpdateDialogBox: PropTypes.func,
    setLoading: PropTypes.func,
    showInvoiceStatus: PropTypes.bool,
    setShowInvoiceStatus: PropTypes.func,
    showInvoiceUpdate: PropTypes.bool,
    setShowInvoiceUpdate: PropTypes.func
};
