export const payoutStatus = {
    PENDING: 'PENDING',
    COMPLETE: 'COMPLETE',
    SENT: 'SENT',
    FAILED: 'FAILED',
    RESEND: 'RESEND',
    NOT_RECEIVED: 'NOT-RECEIVED',
    CANCEL: 'CANCEL'
};

export const payoutStatusToDate = {
    PENDING: 'date_pending',
    COMPLETE: 'date_complete',
    SENT: 'date_sent',
    FAILED: 'date_complete'
};
