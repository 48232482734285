import React from 'react';
import { RefundList } from 'components';
import { Grid, Box, Paper } from '@material-ui/core';
import { Calendar, PageHeader } from 'components';
import PropTypes from 'prop-types';
import style from './list-refund.scss';

const RefundComponent = (props) => {
    const { date: dateFromUrlParams, via: viaFromUrlParams } = props.match.params;
    const { dates, offrange, setOffrange } = props;
    const { pathname } = props.location;
    return (
        <Grid item xs={12}>
            <Box mt={3}>
                <Calendar
                    path="refund-list"
                    range={viaFromUrlParams}
                    current={pathname}
                    date={dateFromUrlParams}
                    offrange={offrange}
                    setOffrange={setOffrange}
                    via={viaFromUrlParams}
                />
            </Box>
            <Paper className={style.paymentsContainer}>
                <PageHeader
                    title={`List Refund`}
                    via={viaFromUrlParams}
                    path="refund-list"
                    date={dateFromUrlParams}
                    showFilterButton={false}
                    offrange={offrange}
                    setOffrange={setOffrange}
                />
                <RefundList date={dateFromUrlParams} via={viaFromUrlParams} path="refund-list" dates={dates} />
            </Paper>
        </Grid>
    );
};

export default RefundComponent;
RefundComponent.propTypes = {
    onChangeDateRange: PropTypes.func,
    dates: PropTypes.array,
    daterange: PropTypes.object,
    setClear: PropTypes.func,
    offrange: PropTypes.bool,
    setOffrange: PropTypes.func
};
