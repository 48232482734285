import React from 'react';
import PropTypes from 'prop-types';
import { CardMultiSelectDatman } from 'components/onboarding/onboarding-ui-components';
import styles from './manage-products-datman.module.scss';
import { Button, Grid, Typography } from '@material-ui/core';
import { useGlobalStateContext } from 'contexts';
import { LoadingScreen } from 'components';

export default function ManageProductsDatmanComponent(props) {
    const { setRoute } = useGlobalStateContext();
    const { products, selectedProducts, setSelectedProducts, onSubmit, isLoading } = props;

    return (
        <React.Fragment>
            {!isLoading && (
                <React.Fragment>
                    <Typography color="primary" className={styles.title}>
                        Products required
                    </Typography>
                    <Typography color="primary" className={styles.subTitle}>
                        Please select products you require.
                    </Typography>
                    <div className={styles.cardsContainer}>
                        <Grid container spacing={2} className={styles.cards}>
                            <Grid item xs={12}>
                                <CardMultiSelectDatman
                                    list={products}
                                    onChange={(value) => setSelectedProducts(value)}
                                    value={selectedProducts}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    id="submit-products-btn"
                                    className={styles.btn}
                                    color="primary"
                                    variant="contained"
                                    onClick={(e) => setRoute('/settings')}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    id="submit-products-btn"
                                    className={styles.btnsubmit}
                                    color="primary"
                                    variant="contained"
                                    onClick={() => onSubmit()}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </React.Fragment>
            )}
            <LoadingScreen open={isLoading} />
        </React.Fragment>
    );
}

ManageProductsDatmanComponent.propTypes = {
    products: PropTypes.array,
    selectedProducts: PropTypes.array,
    setSelectedProducts: PropTypes.func,
    onSubmit: PropTypes.func,
    isLoading: PropTypes.bool
};
