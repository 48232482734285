import { useGlobalStateContext } from 'contexts';
import React, { useEffect, useState } from 'react';
import { AdminService, CanonicalResellerService } from 'services';
import AdminPendingMerchantsComponent from './admin-pending-merchants.component';
import { useHistory } from 'react-router-dom';

export default function AdminPedingMerchants() {
    const { globalState, getRoute } = useGlobalStateContext();
    const [pendingMerchants, setPendingMerchants] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [count, setCount] = useState(0);
    // const [searchOnlyPendingMerchants, setSearchOnlyPendingMerchants] = useState(true);
    const [filter, setFilter] = useState('all');
    const [statusFilter, setStatusFilter] = useState([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('createdDate');
    const [showCanonicalResellersList, setShowCanonicalResellersList] = useState(false);
    const [canonicalResellerList, setCanonicalResellerList] = useState([]);
    const [isCopyAlertOpen, setIsCopyAlertOpen] = useState(false);
    const history = useHistory();

    const pendingMerchantsHeadCells = [
        { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
        { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
        { id: 'postcode', numeric: false, disablePadding: false, label: 'Postcode', hideSortingIcon: true },
        { id: 'store/merchant id', numeric: true, disablePadding: false, label: 'Customer', hideSortingIcon: true },
        { id: 'createdDate', numeric: false, disablePadding: false, label: 'Created' },
        { id: 'completedDate', numeric: false, disablePadding: false, label: 'Completed' },
        // { id: 'account', numeric: false, disablePadding: false, label: 'Account', hideSortingIcon: true },
        { id: 'ms', numeric: false, disablePadding: false, label: 'MS', hideSortingIcon: true }
    ];

    const canonicalResellersHeadCells = [
        { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
        { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
        {
            id: 'resellerId',
            numeric: true,
            disablePadding: false,
            label: 'Reseller Id',
            hideSortingIcon: true
        },
        { id: 'createdDate', numeric: false, disablePadding: false, label: 'Created' }
    ];
    const [headCells, setHeadCells] = useState([...pendingMerchantsHeadCells]);

    const checkResellerViewHandler = (event) => {
        setShowCanonicalResellersList(!showCanonicalResellersList);
        sessionStorage.setItem('showCanonicalResellersList', JSON.stringify(!showCanonicalResellersList));
    };
    useEffect(() => {
        const showCanonicalResellersList = JSON.parse(sessionStorage.getItem('showCanonicalResellersList'));
        setShowCanonicalResellersList(showCanonicalResellersList);
    }, []);
    useEffect(() => {
        return history.listen((location) => {
            let pathName = location.pathname;
            if (pathName.split('/').includes('canonical-reseller-merchant-management')) {
            } else {
                sessionStorage.removeItem('showCanonicalResellersList');
            }
        });
    }, [history]);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        if (!showCanonicalResellersList) {
            getPendingMerchants(newPage);
        } else {
            getCanonicalResellerList(newPage);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        if (!showCanonicalResellersList) {
            getPendingMerchants(0, parseInt(event.target.value, 10));
        } else {
            getCanonicalResellerList(0, parseInt(event.target.value, 10));
        }
    };

    useEffect(() => {
        switch (filter) {
            case 'notsubmitted':
                setStatusFilter([0]);

                break;
            case 'pending':
                setStatusFilter([1]);
                break;
            case 'active':
                setStatusFilter([3]);
                break;
            case 'closed':
                setStatusFilter([4]);
                break;
            case 'rejected':
                setStatusFilter([5]);
                break;
            case 'pendingstripe':
                setStatusFilter([6]);
                break;
            case 'watch list':
                setStatusFilter([12]);
                break;
            default:
                setStatusFilter([0, 1, 3, 4, 5, 6, 12]);
        }
    }, [filter]);

    const getPendingMerchants = async (newPage, newRowsPerPage) => {
        setIsLoading(true);
        const thePage = newPage ?? page;
        const theRowsPerPage = newRowsPerPage ?? rowsPerPage;
        const response = await AdminService.GetPendingMerchants(globalState.reseller?.id, {
            searchValue: inputValue.length > 2 ? inputValue.trim() : 'no',
            offset: thePage * theRowsPerPage,
            limit: theRowsPerPage,
            statusFilter: statusFilter
        });
        if (response.isSuccesfully) {
            setPendingMerchants(response.data.pendingMerchants);
            setCount(response.data.count);
        } else {
            setPendingMerchants([]);
            setCount(0);
        }

        setIsLoading(false);
    };

    const getCanonicalResellerList = async (newPage, newRowsPerPage) => {
        setIsLoading(true);
        const thePage = newPage ?? page;
        const theRowsPerPage = newRowsPerPage ?? rowsPerPage;
        const response = await CanonicalResellerService.getAllCanonicalResellers(globalState.reseller?.id, {
            searchValue: inputValue ? inputValue.trim() : '',
            offset: thePage * theRowsPerPage,
            limit: theRowsPerPage
        });

        if (response.isSuccesfully) {
            setCanonicalResellerList(response.data.canonicalResellers);
            setCount(response.data.count);
        } else {
            setCanonicalResellerList([]);
            setCount(0);
        }

        setIsLoading(false);
    };

    const onGoToAccount = (merchantId) => {
        let routeToGo = getRoute(`/admin-functions/${merchantId}`);
        window.open(routeToGo, '_blank');
    };

    const onSearchMerchants = (e) => {
        setPage(0);
        if (!showCanonicalResellersList) {
            getPendingMerchants(0);
        } else {
            getCanonicalResellerList(0);
        }
        e.preventDefault();
    };

    useEffect(() => {
        if (statusFilter && statusFilter.length) {
            setPage(0);
            if (!showCanonicalResellersList) {
                setHeadCells([...pendingMerchantsHeadCells]);
                setCanonicalResellerList([]);
                getPendingMerchants(0);
            } else {
                setHeadCells([...canonicalResellersHeadCells]);
                setPendingMerchants([]);
                getCanonicalResellerList(0);
            }
        }
        // eslint-disable-next-line
    }, [statusFilter, showCanonicalResellersList]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleCopyResellerId = (resellerId) => {
        navigator.clipboard.writeText(resellerId);
        setIsCopyAlertOpen(true);
    };

    const closeCopyAlert = () => {
        setIsCopyAlertOpen(false);
    };

    return (
        <AdminPendingMerchantsComponent
            pendingMerchants={pendingMerchants}
            onGoToAccount={onGoToAccount}
            isLoading={isLoading}
            onSearchMerchants={onSearchMerchants}
            setInputValue={setInputValue}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            filter={filter}
            setFilter={setFilter}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            handleRequestSort={handleRequestSort}
            setShowCanonicalResellersList={setShowCanonicalResellersList}
            showCanonicalResellersList={showCanonicalResellersList}
            canonicalResellerList={canonicalResellerList}
            handleCopyResellerId={handleCopyResellerId}
            closeCopyAlert={closeCopyAlert}
            isCopyAlertOpen={isCopyAlertOpen}
            checkResellerViewHandler={checkResellerViewHandler}
        />
    );
}
