import { AuthAxios } from './interceptor.service.js';
import { NonResellerMerchantServiceEndpoint } from '../utils/constants';
import { SuccesResponse, ErrorResponse } from 'models';

const getBusinessDetails = async (merchantId) => {
    try {
        const response = await AuthAxios.get(`${NonResellerMerchantServiceEndpoint}/businessDetails/${merchantId}`);

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const updateBusinessDetails = async (merchantId, data, csrfToken) => {
    try {
        const response = await AuthAxios.post(
            `${NonResellerMerchantServiceEndpoint}/businessDetails/${merchantId}`,
            data,
            { headers: { 'X-CSRF-TOKEN': csrfToken } }
        );

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getMerchantBusinessTypeAndBankStatus = async (merchantId) => {
    try {
        const response = await AuthAxios.get(
            `${NonResellerMerchantServiceEndpoint}/get-merchant-business-type-and-bank-status/${merchantId}`
        );

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getError = (error) => {
    const message = error.response ? error.response.data : 'An error occurred';
    return new ErrorResponse(message);
};

export default {
    getBusinessDetails,
    updateBusinessDetails,
    getMerchantBusinessTypeAndBankStatus
};
