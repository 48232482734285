import { ValidationMessage } from 'models';
import { BusinessService } from 'services';

/**
 *
 * @param {string} businessName
 */
export function validateBusinessName(businessName) {
    if (businessName.length > 250) {
        return new ValidationMessage('Business name should have less then 250 charsacters', false);
    }

    if (businessName.length < 3) {
        return new ValidationMessage('Business name should have at least 3 charsacters', false);
    }
    return new ValidationMessage('', true);
}

/**
 *
 * @param {string} businessName
 */
export async function validateBusinessName_Back(businessName) {
    const trimBusinessName = businessName.trim();
    const result = await BusinessService.checkBusinessName(trimBusinessName);
    if (result.data.isBusinessNameExist) {
        return new ValidationMessage('Business name alrady exist.', false);
    }
    return new ValidationMessage('', true);
}
