import { SuccesResponse, ErrorResponse } from 'models';
import { DatmanAxios, AuthAxios } from './interceptor.service.js';
import { DatmanAPIEndpoint, GetPaymentsEndpoint } from 'utils/constants';

async function getPayoutsList(payoutsRequest) {
    try {
        const response = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/withdrawalsV2`, payoutsRequest);
        // const { data, status } = response;

        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        const errMessage = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}
async function markPayoutNotReceived(data) {
    try {
        const requestResult = await DatmanAxios.put(`${DatmanAPIEndpoint}/portal/withdrawals/batched`, data);
        return new SuccesResponse(requestResult.data, requestResult.status);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function getStripePayoutsList({ merchantId }) {
    try {
        const response = await AuthAxios.get(`${GetPaymentsEndpoint}/fetch-stripe-payouts/${merchantId}`);
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        const errMessage = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function getStripePayoutTransactions({ merchantId, payoutId }) {
    try {
        const response = await AuthAxios.get(
            `${GetPaymentsEndpoint}/fetch-stripe-payout-transactions/${merchantId}/${payoutId}`
        );
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        const errMessage = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}
async function DeletePayoutTransaction(data) {
    try {
        const requestResult = await DatmanAxios.delete(`${DatmanAPIEndpoint}/portal/withdrawals/notBatched`, { data });
        return new SuccesResponse(requestResult.data);
    } catch (error) {
        const errMessage = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

const getAllPayouts = async (merchantId, data) => {
    try {
        const requestResult = await AuthAxios.post(`${GetPaymentsEndpoint}/get-payouts/${merchantId}`, data);
        return new SuccesResponse(requestResult.data);
    } catch (error) {
        const errMessage = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
};

export default {
    getPayoutsList,
    markPayoutNotReceived,
    getStripePayoutsList,
    getStripePayoutTransactions,
    getAllPayouts,
    DeletePayoutTransaction
};
