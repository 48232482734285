import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useGlobalStateContext } from 'contexts';
import { chatBotScript } from 'utils/chatbot-script';
import { ResellerType } from 'utils/enums/ResellerType';
import { CHATBOT_SCRIPT_URL, CHATBOT_SCRIPT_ID, CHATBOT_DATA_HANDLE_ATTRIBUTE } from 'utils/constants';
import qs from 'query-string';
function ChatBotProvider(props) {
    const { globalState } = useGlobalStateContext();

    useEffect(() => {
        if (globalState.reseller?.name === ResellerType.DATMAN) {
            chatBotScript(CHATBOT_SCRIPT_URL, CHATBOT_SCRIPT_ID, CHATBOT_DATA_HANDLE_ATTRIBUTE);
        }
    }, [globalState.reseller]);

    useEffect(() => {
        function autoFillChatBotForm() {
            if (window?.$zoho?.salesiq?.visitor) {
                if (globalState.user) {
                    window.$zoho.salesiq.visitor.name(
                        [globalState.user.firstName, globalState.user.lastName].join(' ')
                    );
                    window.$zoho.salesiq.visitor.email(globalState.user.email);
                    window.$zoho.salesiq.visitor.contactnumber(globalState.user.phoneNumber || '');
                } else if (window.$ZSIQWidget) {
                    window.$zoho.salesiq.reset();
                }
            }

            const queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true });
            if (queryString?.name) {
                window.$zoho.salesiq.visitor.name(queryString.name);
            }
            if (queryString?.email) {
                window.$zoho.salesiq.visitor.email(queryString.email);
            }
            if (queryString?.phoneNumber) {
                window.$zoho.salesiq.visitor.contactnumber(queryString.phoneNumber);
            }
        }
        if (globalState.reseller?.name === ResellerType.DATMAN) {
            try {
                setTimeout(() => {
                    autoFillChatBotForm();
                }, 1000);
            } catch (ex) {
                console.error('Chatbot configuration failed!!!');
            }
        }
    }, [globalState, globalState.user]);

    return <React.Fragment> {props.children}</React.Fragment>;
}

ChatBotProvider.propTypes = {
    children: PropTypes.any
};

export { ChatBotProvider };
