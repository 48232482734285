import React from 'react';
import { Redirect } from 'react-router-dom';
import { useGlobalStateContext } from 'contexts';
import NotLoggedRoute from './not-logged-route';
import { ResellerType } from 'utils/enums/ResellerType';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { UNDERMAINTAINANCE } from 'utils/constants';

//dummy
export default function MypayOnlyNotLoggedRoute({ ...rest }) {
    const { globalState, getRoute } = useGlobalStateContext();
    const isMypayReseller = globalState.reseller?.name === ResellerType.OMNIPAY;
    const location = useLocation();

    if (UNDERMAINTAINANCE && globalState.customDomain) {
        return <Redirect to={getRoute(`/maintenance`)} />;
    }

    if (!isMypayReseller) {
        const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
        if (!queryString?.q && !queryString?.email && !queryString?.phone) {
            return <Redirect to={getRoute(`/home`)} />;
        } else {
            return <NotLoggedRoute {...rest} />;
        }
    }

    return <NotLoggedRoute {...rest} />;
}
