import { DatmanAxios } from './interceptor.service.js';
import { SuccesResponse, ErrorResponse } from 'models';
import { DatmanAPIEndpoint } from 'utils/constants';

async function GetPdqTransactionsList(pdqTransactionsRequest) {
    const { year, month } = pdqTransactionsRequest;
    try {
        const response = await DatmanAxios.post(`${DatmanAPIEndpoint}/portal/pdq_transactions`, { year, month });
        return new SuccesResponse(response.data, response.status);
    } catch (error) {
        const errMessage = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

export default {
    GetPdqTransactionsList
};
