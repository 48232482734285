import React from 'react';
import PropTypes from 'prop-types';
import { CardMultiSelect } from 'components/onboarding/onboarding-ui-components';
import styles from './manage-products-omnipay.module.scss';
import { Button, Grid, Typography } from '@material-ui/core';
import { useGlobalStateContext } from 'contexts';
import { LoadingScreen } from 'components';
import DialogViewProducts from '../dialog-view-manage-products/dialog-view-manage-products.container';
import ProductAddRemoveDialogBox from '../product-add-remove-dialog-box/product-add-remove-dialog-box.container';
import { SnackBarAndAlert } from 'components';

export default function ManageProductsOmniPayComponent(props) {
    const { setRoute } = useGlobalStateContext();
    const {
        //products,
        selectedProducts,
        setSelectedProducts,
        onSubmit,
        isLoading,
        openDialog,
        setOpenDialog,
        isModalOpen,
        setIsModalOpen,
        productId,
        setProductId,
        approve,
        setApprove,
        selectedEntity,
        setSelectedEntity,
        errorMessage,
        setErrorMessage
    } = props;

    return (
        <React.Fragment>
            {!isLoading && (
                <React.Fragment>
                    {errorMessage && (
                        <SnackBarAndAlert open={errorMessage} onClose={() => setErrorMessage(null)} type="error">
                            {errorMessage ? <h3>{errorMessage}</h3> : ''}
                        </SnackBarAndAlert>
                    )}
                    <Typography color="primary" className={styles.title}>
                        Products Required
                    </Typography>
                    <Typography color="primary" className={styles.subTitle}>
                        Please select products you require.
                    </Typography>
                    <div className={styles.cardsContainer}>
                        <Grid container spacing={2} className={styles.cards}>
                            <Grid item xs={12}>
                                <CardMultiSelect
                                    list={selectedProducts}
                                    onChange={(value) => setSelectedProducts(value)}
                                    value={selectedProducts}
                                    setIsModalOpen={setIsModalOpen}
                                    setProductId={setProductId}
                                    setOpenDialog={setOpenDialog}
                                    setSelectedEntity={setSelectedEntity}
                                    manageMyProductPage
                                />
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Button
                                    id="submit-products-btn"
                                    className={styles.btn}
                                    color="primary"
                                    variant="contained"
                                    onClick={(e) => setRoute('/settings')}
                                >
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </React.Fragment>
            )}
            <LoadingScreen open={isLoading} />
            <ProductAddRemoveDialogBox
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                selectedEntity={selectedEntity}
                onSubmit={onSubmit}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
            />

            <DialogViewProducts
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                productId={productId}
                value={selectedProducts}
                approve={approve}
                setApprove={setApprove}
                setSelectedProducts={setSelectedProducts}
            />
        </React.Fragment>
    );
}

ManageProductsOmniPayComponent.propTypes = {
    products: PropTypes.array,
    selectedProducts: PropTypes.array,
    setSelectedProducts: PropTypes.func,
    onSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    openDialog: PropTypes.bool,
    setOpenDialog: PropTypes.func,
    productId: PropTypes.number,
    setProductId: PropTypes.func
};
