import React from 'react';
import DatePickerInputComponent from './date-picker-input.component';

/**
 *
 * @param {{
 * onChange: (event) => {},
 * value: date,
 * id: string
 * }} props
 */
function DatePickerInput(props) {
    return <DatePickerInputComponent {...props} />;
}

export default DatePickerInput;
