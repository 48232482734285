import React from 'react';
import styles from './input.module.scss';
import { InputBase } from '@material-ui/core';
import { fade, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const BootstrapInput = withStyles((theme) => ({
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #e8e9ec',
        fontSize: 16,
        width: '100%',
        textIndent: '10px',
        maxLength: 256,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main
        },
        '&:disabled': {
            backgroundColor: 'gainsboro'
        }
    }
}))(InputBase);

function InputComponent(props) {
    return (
        <React.Fragment>
            <BootstrapInput
                {...props.baseProps}
                autoComplete="new-password"
                className={`${props.baseProps.error ? styles.errorBorder : ''} ${styles.customInput} 
                ${props.optionalProps?.disableBorders ? styles.withoutBorders : ''} ${
                    props.baseProps?.emptyField ? styles.withoutShadow : ''
                }`}
            ></BootstrapInput>
            {props.error ? (
                <span style={{ color: '#f44336', fontSize: '12px' }} className="MuiFormHelperText-root">
                    {props.helpertext}
                </span>
            ) : (
                ''
            )}
        </React.Fragment>
    );
}

InputComponent.propTypes = {
    error: PropTypes.bool,
    border: PropTypes.bool,
    helpertext: PropTypes.string,
    optionalProps: PropTypes.object,
    baseProps: PropTypes.object
};

export default InputComponent;
