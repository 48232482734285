import React, { useState, useEffect } from 'react';
import TransactionRefundComponent from './transaction-refund.component';
import { ValidationMessage } from 'models';
import { validateRefundData /*, validateRefund*/ } from 'validators';
import { RefundService, AuthService /*, BusinessDetailsService*/ } from 'services';
import { useBalanceContext, useGlobalStateContext } from 'contexts';
import { ResellerType } from 'utils/enums/ResellerType';
import { encryptData } from 'utils/helper';
import { cleverTapClick } from 'utils/helper';
import { formTypes } from 'utils/enums/FormTypes';
import { ErrorCodes } from 'utils/enums/ErrorCode';

export default function TransactionRefund(props) {
    const { globalState } = useGlobalStateContext();
    const { refreshBalance } = useBalanceContext();
    const initialValidationMessage = new ValidationMessage();
    const { setShow, payment, setIsRefundHappen } = props;
    const { total, id: payment_id, order_id, payment_provider, TxnReference, original_total } = payment;
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(initialValidationMessage.message);
    const [successMessage, setSuccessMessage] = useState(null);
    const [refundAmount, setRefundAmount] = useState(props.payment.total);
    const [reason, setReason] = useState('');
    const [otherReason, setOtherReason] = useState('');
    const [isRefunded, setIsRefunded] = useState(null);
    const [loading, setLoading] = useState(false);
    const [disableSubmitButton, setDisableSubmitButton] = useState(true);
    const refundReason = ['Duplicate', 'Fraudulent', 'Requested by Customer', 'Charged twice', 'Other'];
    const [csrfToken, setCSRFToken] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [refundFailureMessage, setRefundFailureMessage] = useState('');

    useEffect(() => {
        const generateCSRFToken = async () => {
            setIsLoading(true);
            const response = await AuthService.generateCSRFToken(formTypes.REFUND_REQUEST, globalState.merchant?.id);
            if (response.isSuccesfully) {
                setCSRFToken(response.data.csrfToken);
            }
            setIsLoading(false);
        };
        generateCSRFToken();
        // eslint-disable-next-line
    }, []);

    const paymentActionValidation = () => {
        const validationResult = validateRefundData(refundAmount, original_total ? original_total : total);
        setErrorMessage(validationResult.message);
        setError(validationResult.isValid);
        return validationResult.isValid;
    };

    const handleOnClose = () => {
        props.setShow(false);
        setRefundAmount(payment.total);
        setReason('--Please select--');
        setIsRefunded(null);
        setRefundFailureMessage('');
        props.setPayment && props.setPayment({});
    };
    const handleOnSubmitRefund = async () => {
        // const merchantVerificationResponse = await BusinessDetailsService.getMerchantBusinessTypeAndBankStatus(
        //     globalState.merchant?.id
        // );

        // if (merchantVerificationResponse.isSuccesfully) {
        //     const cantWithDrawValidationResult = validateRefund(
        //         merchantVerificationResponse.data.isAccountVerified,
        //         merchantVerificationResponse.data.isBankAccountVerified,
        //         merchantVerificationResponse.data.allowWithdrawals
        //     );

        //     if (!cantWithDrawValidationResult.isValid) {
        //         setErrorMessage(cantWithDrawValidationResult.message);
        //         setError(true);
        //         return;
        //     }
        // } else {
        //     setIsRefunded(merchantVerificationResponse.isSuccesfully);
        //     setShow(false);
        //     setLoading(false);
        //     setError(false);
        //     return;
        // }

        if (paymentActionValidation()) {
            return;
        }
        const payload = {
            reason: reason === 'Other' ? reason + '(' + otherReason + ')' : reason,
            refund_amount: original_total ? original_total : refundAmount
        };
        cleverTapClick('Refund_btn_click', globalState, payload);
        let selectedReason = reason === 'Other' ? otherReason : reason;

        if (reason === 'Other' && otherReason === '') {
            setErrorMessage('Please provide reason');

            setError(true);
            return;
        }
        if (reason === '' || reason === '--Please Select--') {
            setErrorMessage('Please select a reason');
            setError(true);
            return;
        }

        let data = {
            amount: original_total ? original_total : refundAmount,
            order_id: order_id,
            payment_id: payment_id,
            reason: selectedReason
        };

        if (globalState.reseller?.name === ResellerType.OMNIPAY) {
            delete data['order_id'];
            data['payment_id'] = payment_id;
            data['payment_provider'] = payment_provider;
            data['TxnReference'] = encryptData(TxnReference);
        }

        setLoading(true);

        const response = await RefundService.TransactionRefund(data, csrfToken);

        if (response.isSuccesfully && response.data.outcome !== 'failed') {
            setIsRefunded(true);
            setSuccessMessage(response.data.message);
            setIsRefundHappen && setIsRefundHappen(true);
            refreshBalance();
        } else {
            const errorMessage =
                response.data.errorCode === ErrorCodes.CSRF_VALIDATION_FAILURE.errorCode
                    ? ErrorCodes.CSRF_VALIDATION_FAILURE.message
                    : ErrorCodes.REFUND_ERROR.message;
            setIsRefunded(false);
            setRefundFailureMessage(errorMessage);
        }

        setShow(false);
        setLoading(false);
        setError(false);
    };
    const onInput = (e) => {
        let { value } = e.target;
        e.target.value =
            value.indexOf('.') >= 0 ? value.substr(0, value.indexOf('.')) + value.substr(value.indexOf('.'), 3) : value;
    };

    useEffect(() => {
        setErrorMessage('');
        setError(false);
    }, [reason, refundAmount, otherReason]);
    useEffect(() => {
        if (reason === '') {
            setDisableSubmitButton(true);
        } else if (reason === 'Other' && otherReason === '') {
            setDisableSubmitButton(true);
        } else if (reason !== 'Other' && otherReason !== '') {
            setDisableSubmitButton(false);
        } else if (reason !== 'Other' && otherReason === '') {
            setDisableSubmitButton(false);
        } else if (!/\S/.test(otherReason)) {
            setDisableSubmitButton(true);
        } else {
            setDisableSubmitButton(false);
        }
    }, [reason, otherReason]);
    return (
        <TransactionRefundComponent
            {...props}
            handleOnSubmitRefund={handleOnSubmitRefund}
            error={error}
            errorMessage={errorMessage}
            refundAmount={refundAmount}
            reason={reason}
            setRefundAmount={setRefundAmount}
            setReason={setReason}
            setOtherReason={setOtherReason}
            otherReason={otherReason}
            isRefunded={isRefunded}
            loading={loading}
            handleOnClose={handleOnClose}
            successMessage={successMessage}
            onInput={onInput}
            disableSubmitButton={disableSubmitButton}
            refundReason={refundReason}
            isLoading={isLoading}
            refundFailureMessage={refundFailureMessage}
        />
    );
}
