import { AuthAxios } from './interceptor.service.js';
import { CustomerServiceEndpoint } from '../utils/constants';
import { SuccesResponse, ErrorResponse } from 'models';

async function getRoleFromRelationship(data) {
    try {
        const requestResult = await AuthAxios.patch(
            `${CustomerServiceEndpoint}/relationships/getRoleFromRelationship`,
            {
                data
            }
        );
        return new SuccesResponse(requestResult.data);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function deleteRelationship(relationId) {
    try {
        const requestResult = await AuthAxios.delete(`${CustomerServiceEndpoint}/relationships/${relationId}`);
        return new SuccesResponse(requestResult.data);
    } catch (err) {
        const errMessage = err.response ? err.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

async function assignUserToMerchant(data) {
    try {
        const result = await AuthAxios.post(`${CustomerServiceEndpoint}/relationships/assignUserToMerchant`, {
            data
        });
        return new SuccesResponse(result.data);
    } catch (error) {
        const errMessage = error.response ? error.response.data : 'An error occurred';
        return new ErrorResponse(errMessage);
    }
}

export default {
    getRoleFromRelationship,
    deleteRelationship,
    assignUserToMerchant
};
