import { AuthAxios, BaseAxios } from './interceptor.service.js';
import { DocumentServiceEndpoint } from '../utils/constants';
import { SuccesResponse, ErrorResponse } from 'models';
import { DocumentStatus } from 'utils/enums/DocumentStatus';
import { DocumentEventType } from 'utils/enums/DocumentEventType';
import JSZipUtils from 'jszip-utils';
import JSZip from 'jszip';
import { ErrorCodes } from 'utils/enums/ErrorCode';
import { DocumentFlag } from 'utils/enums/DocumentFlag.js';
import { AdminService, AuthService } from './index.js';
import { DocumentStatusToName } from 'utils/enums/DocumentReviewStatus.js';

const upload = async (
    entity,
    entityId,
    flag,
    docTypeId,
    files,
    fileProgress,
    fileDone,
    formType,
    csrfToken,
    resellerId,
    isAdmin,
    idNumber,
    cardNumber
) => {
    // map array to promises
    const promises = files.map((file) =>
        executeUploading(
            entity,
            entityId,
            file,
            fileProgress,
            flag,
            docTypeId,
            fileDone,
            formType,
            csrfToken,
            resellerId,
            isAdmin,
            idNumber,
            cardNumber
        )
    );
    // wait until all promises are resolved
    const allResponse = await Promise.all(promises);
    const hasErrorOnUpload = allResponse.find(
        (uploadedFile) => !uploadedFile || uploadedFile.status === DocumentStatus.ERROR || uploadedFile.csrfFailure
    );

    if (hasErrorOnUpload) {
        if (hasErrorOnUpload?.csrfFailure) {
            return hasErrorOnUpload;
        }
        return getError('Uploading error');
    } else {
        return new SuccesResponse();
    }
};

const uploadToBucket = async (entity, entityId, files, fileProgress, fileDone, formType, csrfToken) => {
    const promises = files.map((file) =>
        executeBucketUploading(entity, entityId, file, fileProgress, fileDone, formType, csrfToken)
    );
    const allResponse = await Promise.all(promises);
    const hasErrorOnUpload = allResponse.find(
        (uploadedFile) => !uploadedFile || uploadedFile.status === DocumentStatus.ERROR || uploadedFile.csrfFailure
    );
    if (hasErrorOnUpload) {
        if (hasErrorOnUpload?.csrfFailure) {
            return hasErrorOnUpload;
        }
        return getError('Uploading error');
    } else {
        return new SuccesResponse();
    }
};

const listObjects = async (entity, entityId, flag) => {
    try {
        const response = await AuthAxios.get(`${DocumentServiceEndpoint}/list-objects/${entity}/${entityId}/${flag}`);

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getObject = async (entity, entityId, documentId, filename) => {
    try {
        const response = await AuthAxios.post(
            `${DocumentServiceEndpoint}/presigned-url-get-object/${entity}/${entityId}/${documentId}`,
            {
                filename: filename,
                eventType: DocumentEventType.DOWNLOAD
            }
        );

        const res = await BaseAxios.get(response.data.presignedUrl, { responseType: 'blob' }).then((rsp) => {
            const url = window.URL.createObjectURL(new Blob([rsp.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${filename}`);
            document.body.appendChild(link);
            link.click();
        });

        return new SuccesResponse(res);
    } catch (error) {
        return getError(error);
    }
};

const getObjects = async (listOfParams) => {
    try {
        const listOfUrls = [];
        const zip = new JSZip();

        var count = 0;
        getFiles(listOfParams, listOfUrls).then(() => {
            listOfUrls.map((urlObj) => {
                JSZipUtils.getBinaryContent(urlObj.url, function (err, data) {
                    zip.file(urlObj.filename, data, { binary: true });
                    count++;
                    if (count === listOfUrls.length) {
                        zip.generateAsync({ type: 'blob' }).then(function (content) {
                            const url = window.URL.createObjectURL(new Blob([content]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', 'documents.zip');
                            document.body.appendChild(link);
                            link.click();
                        });
                    }
                });
                return null;
            });

            return new SuccesResponse();
        });
    } catch (error) {
        console.log(listOfParams);
        return getError(error);
    }
};

const getPresignedUrl = async (params, eventType) => {
    try {
        const { entity, entityId, documentId, filename } = params;
        const response = await AuthAxios.post(
            `${DocumentServiceEndpoint}/presigned-url-get-object/${entity}/${entityId}/${documentId}`,
            {
                filename: filename,
                eventType: eventType
            }
        );

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getFiles = async (listOfParams, listOfUrls) => {
    const filenames = [];
    return Promise.all(
        listOfParams.map(async (params) => {
            const { entity, entityId, documentId, filename } = params;
            const response = await AuthAxios.post(
                `${DocumentServiceEndpoint}/presigned-url-get-object/${entity}/${entityId}/${documentId}`,
                {
                    filename: filename,
                    eventType: DocumentEventType.DOWNLOAD
                }
            );
            const res = await BaseAxios.get(response.data.presignedUrl, { responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([res.data]));
            var count = 1;
            var newFilename = filename;
            const indexSeparator = filename.lastIndexOf('.');
            const filenameWithoutExt = filename.substring(0, indexSeparator);
            const ext = filename.substring(indexSeparator);
            while (filenames.includes(newFilename)) {
                newFilename = filenameWithoutExt + ' (' + count++ + ')' + ext;
            }
            const data = {
                url: url,
                filename: newFilename
            };
            filenames.push(newFilename);
            listOfUrls.push(data);
        })
    );
};

const removeObject = async (entity, entityId, documentId, filename, formType, csrfToken) => {
    try {
        const response = await AuthAxios.post(
            `${DocumentServiceEndpoint}/remove-object/${entity}/${entityId}/${documentId}`,
            {
                filename: filename,
                formType: formType
            },
            { headers: { 'X-CSRF-TOKEN': csrfToken } }
        );

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getError = (error) => {
    const message = error.response ? error.response.data : 'An error occurred';
    return new ErrorResponse(message);
};

async function executeUploading(
    entity,
    entityId,
    file,
    fileProgress,
    flag,
    docTypeId,
    fileDone,
    formType,
    csrfToken,
    isAdmin,
    resellerId,
    idNumber,
    cardNumber
) {
    let response;
    try {
        response = await AuthAxios.post(
            `${DocumentServiceEndpoint}/presigned-url-put-object/${entity}/${entityId}`,
            {
                filename: file.name,
                fileType: file.type,
                formType: formType
            },
            { headers: { 'X-CSRF-TOKEN': csrfToken } }
        );

        await BaseAxios.put(response.data.presignedUrl, file, {
            headers: {
                'Content-Type': file.type
            },
            onUploadProgress: (progress) => {
                fileProgress(response.data.documentId, file.name, file.size, progress);
            }
        });

        await AuthAxios.post(
            `${DocumentServiceEndpoint}/save/${entity}/${entityId}/${response.data.documentId}`,
            {
                filename: file.name,
                size: file.size,
                flag: flag,
                docTypeId: docTypeId,
                formType: formType,
                idNumber: idNumber,
                cardNumber: cardNumber
            },
            { headers: { 'X-CSRF-TOKEN': csrfToken } }
        )
            .then((res) => {
                if ((flag == DocumentFlag.ACCOUNT_VERIFY || flag == DocumentFlag.ONBOARDING) && !isAdmin) {
                    AdminService.saveUserKycLogs(entityId, {
                        resellerId: resellerId,
                        notes: '',
                        documentId: response.data.documentId,
                        documentStatus: DocumentStatusToName.NEED_APPROVAL,
                        docTypeId: docTypeId
                    });
                }
            })
            .catch((error) => {
                // not need to throw err from here
            });

        fileDone(response.data.documentId, { filename: file.name, status: DocumentStatus.SUCCESS });
        return { documentId: response.data.documentId, status: DocumentStatus.SUCCESS };
    } catch (error) {
        if (error.response.data.errorCode === ErrorCodes.CSRF_VALIDATION_FAILURE.errorCode) {
            return { ...getError(error), csrfFailure: true };
        }
        if (response) {
            fileDone(response.data.documentId, { status: DocumentStatus.ERROR });
            return { documentId: response.data.documentId, status: DocumentStatus.ERROR };
        }
    }
}
async function executeBucketUploading(entity, entityId, file, fileProgress, fileDone, formType, csrfToken) {
    let response;
    try {
        response = await AuthAxios.post(
            `${DocumentServiceEndpoint}/presigned-url-put-object/${entity}/${entityId}`,
            {
                filename: file.name,
                fileType: file.type,
                formType: formType
            },
            { headers: { 'X-CSRF-TOKEN': csrfToken } }
        );
        await BaseAxios.put(response.data.presignedUrl, file, {
            headers: {
                'Content-Type': file.type
            },
            onUploadProgress: (progress) => {
                fileProgress(response.data.documentId, file.name, file.size, progress);
            }
        });
        fileDone(response.data.documentId, { filename: file.name, status: DocumentStatus.SUCCESS });
        return { documentId: response.data.documentId, status: DocumentStatus.SUCCESS };
    } catch (error) {
        if (error.response.data.errorCode === ErrorCodes.CSRF_VALIDATION_FAILURE.errorCode) {
            return { ...getError(error), csrfFailure: true };
        }
        if (response) {
            fileDone(response.data.documentId, { status: DocumentStatus.ERROR });
            return { documentId: response.data.documentId, status: DocumentStatus.ERROR };
        }
    }
}

const removeBucketObject = async (entity, entityId, documentId, filename, formType, csrfToken) => {
    try {
        const response = await AuthAxios.post(
            `${DocumentServiceEndpoint}/remove-bucket-object/${entity}/${entityId}/${documentId}`,
            {
                filename: filename,
                formType: formType
            },
            { headers: { 'X-CSRF-TOKEN': csrfToken } }
        );

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const notifyAdminAboutDocumentUploading = async (merchantId, resellerId, isBankDetailsUpdated = false) => {
    try {
        const response = await AuthAxios.post(`${DocumentServiceEndpoint}/send-email-notification-to-admins`, {
            merchantId: merchantId,
            resellerId: resellerId,
            isBankDetailsUpdated: isBankDetailsUpdated
        });

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const getMerchantFilesUrls = async (listOfParams) => {
    let listOfUrls = [];

    for (const param of listOfParams) {
        const {
            entity,
            entityId,
            documentId,
            filename,
            documentTypeName,
            flag,
            createdAt,
            documentStatus,
            docTypeId,
            idNumber,
            cardNumber
        } = param;

        const response = await AuthAxios.post(
            `${DocumentServiceEndpoint}/presigned-url-get-object/${entity}/${entityId}/${documentId}`,
            {
                filename: filename,
                eventType: DocumentEventType.VIEWED
            }
        );
        const indexSeparator = filename.lastIndexOf('.');
        const ext = filename.substring(indexSeparator + 1);
        const urlObj = {
            entity: entity,
            entityId: entityId,
            url: response.data.presignedUrl,
            filename: filename,
            extension: ext.toLowerCase(),
            flag: flag,
            createdAt: createdAt,
            documentId: documentId,
            documentStatus: documentStatus,
            docTypeId: docTypeId,
            idNumber: idNumber,
            cardNumber: cardNumber,
            documentTypeName
        };
        listOfUrls.push(urlObj);
    }

    return listOfUrls;
};

const updateDocumentStatus = async (merchantId, documentID, data) => {
    try {
        await AuthAxios.post(`${DocumentServiceEndpoint}/update-document-status/${merchantId}/${documentID}`, data);
        return new SuccesResponse({});
    } catch (error) {
        return getError(error);
    }
};

const updateDocumentType = async (merchantId, documentID, data) => {
    try {
        await AuthAxios.post(`${DocumentServiceEndpoint}/update-document-type/${merchantId}/${documentID}`, data);
        return new SuccesResponse({});
    } catch (error) {
        return getError(error);
    }
};

const getQrCode = async (merchantId) => {
    try {
        const response = await AuthAxios.get(`${DocumentServiceEndpoint}/get-qr-code/${merchantId}`);
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const createQrCode = async (merchantId, resellerId, description, link, amount, qrName, typeOfQr, expiryDate) => {
    try {
        const response = await AuthAxios.post(
            `${DocumentServiceEndpoint}/qr-generate/${merchantId}`,
            resellerId,
            description,
            link,
            amount,
            qrName,
            typeOfQr,
            expiryDate
        );
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const fetchQrCodes = async (merchantId, data) => {
    try {
        const response = await AuthAxios.post(`${DocumentServiceEndpoint}/fetch-qr-codes/${merchantId}`, data);
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

const closeQrCode = async (merchantId, id, reason) => {
    try {
        const response = await AuthAxios.delete(`${DocumentServiceEndpoint}/close-qr-code/${id}/${merchantId}`, {
            data: reason
        });
        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};

export default {
    upload,
    listObjects,
    getObject,
    getObjects,
    removeObject,
    notifyAdminAboutDocumentUploading,
    getMerchantFilesUrls,
    updateDocumentStatus,
    getQrCode,
    updateDocumentType,
    getPresignedUrl,
    createQrCode,
    fetchQrCodes,
    closeQrCode,
    uploadToBucket,
    removeBucketObject
};
