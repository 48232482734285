import React from 'react';
import PropTypes from 'prop-types';

import { Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import styles from './dropdown.module.scss';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%'
    },
    noOptions: {
        color: 'red'
    },
    hideNoOptionsText: {
        display: 'none'
    },
    inputRoot: {
        padding: 0,
        backgroundColor: '#fff',
        '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
            padding: 0
        }
    },
    emptyFieldClass: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #ff2e58 !important'
        }
    }
}));

function SelectedDropDownComponent(props) {
    const classes = useStyles();
    const hideNoOptionsTextSelect = props.hideNoOptionsTextSelect || !props.noOptionsText;
    return (
        <Grid container>
            <Autocomplete
                noOptionsText={props.noOptionsText}
                freeSolo={props.freeSolo}
                disabled={props.disabled}
                value={props.value}
                inputValue={props.inputValue}
                id={props.id}
                options={props.options}
                loading={props.loading}
                onChange={props.onChange}
                onInputChange={props.onInputChange}
                getOptionSelected={props.getOptionSelected}
                getOptionLabel={(option) => `${props.getOptionLabel(option)}${props.listPrefix ?? ''}`}
                classes={{
                    root: classes.root,
                    noOptions: hideNoOptionsTextSelect ? classes.hideNoOptionsText : classes.noOptions,
                    inputRoot: `${classes.inputRoot} ${props.emptyField ? classes.emptyFieldClass : ''}`
                }}
                className={styles.inputAreas}
                renderInput={(params) => (
                    <TextField
                        style={{ height: 32 }}
                        {...params}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password'
                        }}
                        className={styles.inputAreas}
                        placeholder={props.placeholder}
                        variant="outlined"
                        onKeyDown={(e) => e.stopPropagation()}
                    />
                )}
                renderOption={(option, state) => {
                    if (props.customListRender) {
                        return props.customListRender(option, state);
                    }
                    return props.getOptionLabel(option);
                }}
            />
        </Grid>
    );
}

SelectedDropDownComponent.propTypes = {
    id: PropTypes.string,
    value: PropTypes.any,
    options: PropTypes.any,
    getOptionLabel: PropTypes.func,
    listPrefix: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    loading: PropTypes.bool,
    onInputChange: PropTypes.func,
    inputValue: PropTypes.any,
    freeSolo: PropTypes.bool,
    placeholder: PropTypes.string,
    customListRender: PropTypes.element,
    noOptionsText: PropTypes.string,
    hideNoOptionsTextSelect: PropTypes.bool
};

export default SelectedDropDownComponent;
