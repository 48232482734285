import React from 'react';
import { PrintAgreementContainer } from 'components';

const PrintAgreementPageComponent = (props) => {
    return (
        <React.Fragment>
            <PrintAgreementContainer />
        </React.Fragment>
    );
};

export default PrintAgreementPageComponent;
