import React, { useState, useEffect } from 'react';
import AdyenResponseComponent from './adyen-response.component';
import { AdyenService } from 'services';

const AdyenResponse = (props) => {
    const merchantId = props?.match?.params?.merchantId;

    const [adyenResponse, setAdyenResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getAdyenResponse = async () => {
            setIsLoading(true);
            const response = await AdyenService.getAdyenResponse(merchantId);
            setAdyenResponse(JSON.stringify(response.data, null, 2));
            setIsLoading(false);
        };

        getAdyenResponse();
    }, [merchantId]);

    return <AdyenResponseComponent adyenResponse={adyenResponse} isLoading={isLoading} />;
};

export default AdyenResponse;
