export function checkEmptyValue(field) {
    if (field) {
        return field;
    }
    return '';
}

export function checkEmptyArray(field) {
    if (field) {
        return field;
    }
    return [];
}

export function getArrayFromString(field) {
    return Array.from(String(field), String);
}

export const isvalidAbn = (abn) => {
    let abnValidationMessage = '';

    if (abn?.length == 0 || abn == null) {
        abnValidationMessage = 'Please fill in this field';
    } else if (!/^\d{11}$/.test(abn)) {
        abnValidationMessage = 'ABN must be exactly 11 digits';
    } else {
        const digits = abn.split('').map(Number);
        digits[0] -= 1;
        const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
        const sum = digits.reduce((acc, digit, index) => acc + digit * weights[index], 0);
        if (sum % 89 !== 0) {
            abnValidationMessage = 'Invalid ABN number';
        }
    }

    return abnValidationMessage;
};

export const validateEIN = (ein) => {
    let einValidationMessage = '';
    const cleanValue = ein?.replace(/\D/g, '');

    if (ein?.length == 0 || ein == null) {
        einValidationMessage = 'Please fill in this field';
    } else if (ein?.includes(' ')) {
        einValidationMessage = 'EIN should not contain spaces';
    } else if (!/^\d{9}$/.test(cleanValue)) {
        einValidationMessage = 'EIN must be exactly 9 digits';
    } else {
        einValidationMessage = '';
    }

    return einValidationMessage;
};

export const validateCardNumber = (number) => {
    const pattern = /^[a-zA-Z0-9]{0,10}$/;
    return pattern.test(number);
};
