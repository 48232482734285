import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PaymentsListTableComponent from './payments-list-table.component.jsx';
import { PaymentsFilterTabs } from '../../../../utils/enums/PaymentsFilterTabs';
import { useGlobalStateContext } from 'contexts';
import { useMediaQuery } from '@material-ui/core';
import { ResellerType } from 'utils/enums/ResellerType.js';
import { cleverTapClick, merchantTypes } from 'utils/helper.js';
import { VoidablePayments, VoidedPaymentsArray } from 'utils/enums/VoidedPayments.js';
import { REACT_APP_CARD_PAYMENT_ARCHIVE_DATE } from 'utils/constants';
import { Messages } from 'utils/enums/messages';

const PaymentsListTable = (props) => {
    const {
        payments,
        loading,
        channelFilter,
        error,
        noTabs,
        setIsRefundHappen,
        showAcquirer,
        pagePath,
        selectedAcquirer,
        onAcquirerChange,
        acquirerName,
        via,
        handleVoidTransaction,
        showCancelBtn,
        isDateRangeArchived
    } = props;
    const [rows, setRows] = useState([]);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('time');
    const [statusFilter, setStatusFilter] = useState(noTabs ? PaymentsFilterTabs.All : PaymentsFilterTabs.Successful);
    const [isRefundedTransaction, setIsRefundedTransaction] = useState(false);
    const [show, setShow] = useState(false);
    const [payment, setPayment] = useState({});
    const [total, setTotal] = useState();
    const { globalState, setRoute } = useGlobalStateContext();
    const [page, setPage] = useState(0);
    const [refundValue, setRefundValue] = useState();
    const [snackbar, setSnackbar] = useState({ type: '', message: '' });
    const [open, setOpen] = useState(false);
    const [paymentId, setPaymentId] = useState('');
    const mediaLessThan600px = useMediaQuery('(max-width:599px)');
    const isOmnipay = globalState.reseller?.name === ResellerType.OMNIPAY;
    const isDatman = globalState.reseller?.id === 2;
    const showMerchantName = merchantTypes.includes(globalState.merchant?.merchantType);

    const PaymentVisibilityMessage = Messages.PAYMENT_VISIBILITY(
        moment(REACT_APP_CARD_PAYMENT_ARCHIVE_DATE).format('MMMM D, YYYY')
    );

    useEffect(() => {
        let dataRows = payments;
        dataRows =
            channelFilter !== 'All'
                ? payments.filter(
                      (payment) => payment.channel.replace(/\s/g, '').toLowerCase() === channelFilter.toLowerCase()
                  )
                : payments;

        if (statusFilter === PaymentsFilterTabs.Successful) {
            isOmnipay
                ? (dataRows = dataRows.filter((payment) =>
                      payment.payment_provider === 'DNA'
                          ? Number(payment.total) >= 0 && payment.payment_status === 'OK'
                          : Number(payment.total) >= 0
                  ))
                : (dataRows = dataRows.filter(
                      (payment) =>
                          !((Number(payment.total) <= 0 && payment.refund) || payment.firstname.includes('Charge back'))
                  ));
        }
        if (statusFilter === PaymentsFilterTabs.Refunded) {
            dataRows = dataRows.filter(
                (payment) => (Number(payment.total) <= 0 && payment.refund) || payment.firstname.includes('Charge back')
            );
            setIsRefundedTransaction(true);
        } else {
            setIsRefundedTransaction(false);
        }
        if (pagePath === 'dashboard') {
            dataRows = dataRows.slice(0, 5);
        }
        setRows(dataRows);

        let totalPaymentValue = 0;
        let refund = 0;

        for (let i = 0; i < payments.length; i += 1) {
            if ((payments[i].refund && payments[i].total < 0) || payments[i].firstname.includes('Charge back')) {
                refund = parseFloat(refund) + parseFloat(payments[i].total);
            } else if (
                isOmnipay
                    ? payment.payment_provider === 'DNA'
                        ? payments[i].total > 0 && payments[i].payment_status === 'OK'
                        : payments[i].total > 0
                    : !(Number(payment.total) <= 0 && payment.refund)
            ) {
                totalPaymentValue = parseFloat(totalPaymentValue) + parseFloat(payments[i].total);
            }
        }

        setTotal(totalPaymentValue);
        setRefundValue(refund);
        setPage(0);
        // eslint-disable-next-line
    }, [payments, statusFilter, channelFilter]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const clearFilter = () => {
        onAcquirerChange(acquirerName);
        sessionStorage.setItem('selectedAcquirer', JSON.stringify([]));
    };

    const showTransactionRefund = (rowData) => {
        const data = {
            refund_amount: rowData.total,
            time_stamp: new Date()
        };
        cleverTapClick('Refund_list_click', globalState, data);
        setShow(true);
        setPayment(rowData);
    };
    const formatRefundReasonAndAmountString = (str) => {
        str = str.replace('&pound;', '');
        str = str.replace('\\n', '');
        str = str.replace('\\n', '');
        str = str.replace('<hr>', '');
        str = str.replace('because', ' - ');
        let arr = str.split('.');
        let str2 = `${arr[0]}.${arr[1] ? arr[1] : ''}`;
        return str2;
    };

    let headCells = [
        {
            id: 'total',
            numeric: true,
            disablePadding: false,
            label: isRefundedTransaction ? 'Refunded amount' : 'Amount'
        },
        {
            id: 'merchant_name',
            numeric: false,
            disablePadding: false,
            label: 'Merchant name',
            notShow: !showMerchantName
        },
        {
            id: 'transaction details',
            numeric: false,
            disablePadding: false,
            label: 'Transaction details',
            hideSortingIcon: true
        },
        {
            id: 'description',
            numeric: false,
            disablePadding: false,
            label: 'Description',
            hideSortingIcon: true,
            notShow: isDatman || mediaLessThan600px
        },
        {
            id: 'payment_provider',
            numeric: false,
            disablePadding: false,
            label: 'Acquirer ID',
            notShow: !showAcquirer
        },
        { id: 'time', numeric: false, disablePadding: false, label: 'Date' },
        { id: 'refund', numeric: false, disablePadding: false, label: 'Refund', hideSortingIcon: true },
        {
            id: 'payment_status',
            numeric: false,
            disablePadding: false,
            label: 'Status',
            hideSortingIcon: true,
            notShow: statusFilter !== PaymentsFilterTabs.All || !isOmnipay
        }
    ];
    if (isRefundedTransaction) {
        headCells = headCells.filter((headCellObj) => headCellObj.id !== 'refund');
    }

    if (globalState.merchant?.userRole === 'User') {
        headCells = headCells.filter((headCellObj) => headCellObj.id !== 'refund');
    }
    const handleClick = (value) => {
        setRoute(`/payment-details/${value.id}`, {
            payment: value
        });
        const data = {
            time_stamp: new Date(),
            transactin_id: value.id
        };
        cleverTapClick('Txn_click', globalState, data);
    };
    const voidableTransaction = (firstname, lastname) => {
        let name = `${firstname.toLowerCase()} ${lastname.toLowerCase()}`;
        return VoidablePayments.some((word) => name.includes(word));
    };
    const showCancelButton = (firstname, lastname) => {
        const foundInFirstName =
            firstname && firstname.trim().length > 0
                ? VoidedPaymentsArray.some((word) => firstname.toLowerCase().includes(word))
                : false;
        const foundInLastName =
            lastname && lastname.trim().length > 0
                ? VoidedPaymentsArray.some((word) => lastname.toLowerCase().includes(word))
                : false;
        return foundInFirstName || foundInLastName;
    };

    return (
        <PaymentsListTableComponent
            rows={rows}
            setStatusFilter={setStatusFilter}
            statusFilter={statusFilter}
            headCells={headCells}
            order={order}
            isRefundedTransaction={isRefundedTransaction}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            handleRequestSort={handleRequestSort}
            loading={loading}
            show={show}
            setShow={setShow}
            showTransactionRefund={showTransactionRefund}
            payment={payment}
            error={error}
            successfullTransactions={total}
            setPayment={setPayment}
            formatRefundReasonAndAmountString={formatRefundReasonAndAmountString}
            noTabs={noTabs}
            setIsRefundHappen={setIsRefundHappen}
            showAcquirer={showAcquirer}
            page={page}
            setPage={setPage}
            refundValue={refundValue}
            isOmnipay={isOmnipay}
            handleClick={handleClick}
            showMerchantName={showMerchantName}
            pagePath={pagePath}
            selectedAcquirer={selectedAcquirer}
            clearFilter={clearFilter}
            acquirerName={acquirerName}
            snackbar={snackbar}
            setSnackbar={setSnackbar}
            open={open}
            setOpen={setOpen}
            via={via}
            paymentId={paymentId}
            setPaymentId={setPaymentId}
            handleVoidTransaction={handleVoidTransaction}
            showCancelButton={showCancelButton}
            voidableTransaction={voidableTransaction}
            showCancelBtn={showCancelBtn}
            isDateRangeArchived={isDateRangeArchived}
            PaymentVisibilityMessage={PaymentVisibilityMessage}
            {...props}
        />
    );
};

export default PaymentsListTable;

PaymentsListTable.propTypes = {
    payments: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    channelFilter: PropTypes.string.isRequired,
    error: PropTypes.bool.isRequired
};
