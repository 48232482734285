import React from 'react';
import { Grid, Paper, Typography, Box } from '@material-ui/core';
import style from './bank-error-list.module.scss';
import BankErrorContainer from '../../components/admin/bank-error-list/bank-error-list.container';

const BankErrorListComponent = (props) => {
    return (
        <Grid item xs={12}>
            <Paper className={style.errorListContainer}>
                <Box className={style.divTitle}>
                    <Typography color="primary" component="span">
                        <span className={style.contractTitle}>Bank error list</span>
                    </Typography>
                </Box>
                <BankErrorContainer />
            </Paper>
        </Grid>
    );
};

export default BankErrorListComponent;
