import React, { useState, useCallback } from 'react';
import ValidURLInputComponent from './valid-url-input.component';
import { InputValidators } from 'validators';
import { debounce } from 'lodash';

const ValidURLInput = (props) => {
    const [showUrlValidationMessage, setShowUrlValidationMessage] = useState(false);

    const validation = (inputUrl) => {
        let isUrlValid;
        isUrlValid = InputValidators.isWebsiteUrlValid(inputUrl);
        if (inputUrl.includes('https://')) {
            setShowUrlValidationMessage(true);
            isUrlValid = false;
        }

        setShowUrlValidationMessage(!isUrlValid);
    };

    // trigger validation function when user stop typing
    const doValidation = useCallback(debounce(validation, 1000), []);

    return (
        <ValidURLInputComponent
            {...props}
            doValidation={doValidation}
            showUrlValidationMessage={showUrlValidationMessage}
            setShowUrlValidationMessage={setShowUrlValidationMessage}
        />
    );
};

export default ValidURLInput;
