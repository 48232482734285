export const formTypes = {
    ONBOARDING_NAME_AND_ADDRESS: 'ONBOARDING_NAME_AND_ADDRESS',
    ONBOARDING_TRADING_ADDRESS: 'ONBOARDING_TRADING_ADDRESS',
    ONBOARDING_BANK_DETAILS: 'ONBOARDING_BANK_DETAILS',
    ONBOARDING_OWNER_DETAILS: 'ONBOARDING_OWNER_DETAILS',
    ONBOARDING_UPLOAD_DOCUMENTS: 'ONBOARDING_UPLOAD_DOCUMENTS',
    SUBMIT_ONBOARDING: 'SUBMIT_ONBOARDING',
    UPDATE_BANK_INFO: 'UPDATE_BANK_INFO',
    UPDATE_ACCOUNT_INFO: 'UPDATE_ACCOUNT_INFO',
    ACCOUNT_DOCUMENT_UPLOAD: 'ACCOUNT_DOCUMENT_UPLOAD',
    BANK_DOCUMENT_UPLOAD: 'BANK_DOCUMENT_UPLOAD',
    CREATE_WITHDRAWAL_REQUEST: 'CREATE_WITHDRAWAL_REQUEST',
    REFUND_REQUEST: 'REFUND_REQUEST'
};
