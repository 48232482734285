import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';

export const translateText = (props) => {
    return <FormattedMessage id={props.id} defaultMessage={props.text} />;
};

export function FormatMessage(props) {
    return useIntl().formatMessage({ id: props.id, defaultMessage: props.text });
}
