import React from 'react';
import {
    AdminDashboardHome,
    AdminDashBoardNavigationHeader,
    AdminDashboardInfo,
    AdminHomeMenu,
    AdminDashboardTopBar
} from 'components';
import { Dashboard } from '@material-ui/icons';

import { Grid } from '@material-ui/core';
import style from './admin-dashboard.module.scss';

function DashboardComponent() {
    return (
        <div className={style.backgroundColor}>
            <Grid container className={style.container}>
                <Grid item xs={3}>
                    <Grid item xs={12} className={style.adminDashboardMenu}>
                        <AdminHomeMenu></AdminHomeMenu>
                    </Grid>
                </Grid>
                <Grid className={style.dashboardContentContainer} item xs={9}>
                    <Grid item xs={11}>
                        <AdminDashBoardNavigationHeader header_title="Dashboard">
                            <Dashboard />
                        </AdminDashBoardNavigationHeader>
                    </Grid>
                    <Grid item xs={11}>
                        <AdminDashboardTopBar />
                    </Grid>
                    <Grid item xs={11}>
                        <AdminDashboardHome />
                    </Grid>
                    <br></br>

                    <Grid item xs={11}>
                        <AdminDashboardInfo />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

DashboardComponent.propTypes = {};

export default DashboardComponent;
