import React, { useState, useCallback } from 'react';
import { BalanceService } from 'services';
import { debounce } from 'lodash';

const initialBalance = sessionStorage.getItem('balance');
const initialTransitBalance = sessionStorage.getItem('transitBalance');
const initialAvailableBalance = sessionStorage.getItem('availableBalance');

const BalanceContext = React.createContext();

function BalanceProvider(props) {
    const [balanceIsLoading, setBalanceIsLoading] = useState(false);
    const [balance, setBalance] = useState(initialBalance ?? 0);
    const [transitBalance, setTransitBalance] = useState(initialTransitBalance ?? 0);
    const [availableBalance, setAvailableBalance] = useState(initialAvailableBalance ?? 0);
    const [manualWithdrawalStatus, setManualWithdrawStatus] = useState(0);
    const getBalance = async () => {
        setBalanceIsLoading(true);
        await BalanceService.GetBalance().then((response) => {
            if (response.isSuccesfully) {
                setBalance(response.data.balance);
                setTransitBalance(response.data.balance_transit);
                setAvailableBalance(response.data.available_balance);
                setManualWithdrawStatus(response.data.manual_withdrawal_status);
                //session in last as long as the the tab opened and session is specific to that tab or page.
                //session storage gets cleared when closing the tab or refresh the tab
                sessionStorage.setItem('balance', response.data.balance);
                sessionStorage.setItem('transitBalance', response.data.balance_transit);
                sessionStorage.setItem('availableBalance', response.data.available_balance);
                sessionStorage.setItem('manual_withdrawal_status', response.data.manual_withdrawal_status);
            } else {
                setBalance(0);
                setTransitBalance(0);
                setAvailableBalance(0);
                sessionStorage.setItem('balance', 0);
                sessionStorage.setItem('transitBalance', 0);
                sessionStorage.setItem('availableBalance', 0);
                sessionStorage.setItem('manual_withdrawal_status', 0);
            }

            setBalanceIsLoading(false);
        });
    };

    const refreshBalance = useCallback(debounce(getBalance, 500), []);

    return (
        <BalanceContext.Provider
            value={{
                balance,
                setBalance,
                refreshBalance,
                balanceIsLoading,
                availableBalance,
                transitBalance,
                setTransitBalance,
                setAvailableBalance,
                manualWithdrawalStatus,
                setManualWithdrawStatus
            }}
        >
            {props.children}
        </BalanceContext.Provider>
    );
}

/**
 * @returns {{
 * balance: number,
 * refreshBalance: () => {}
 * setBalance: () => {}
 * balanceIsLoading: boolean
 * }}
 */
function useBalanceContext() {
    const balanceContext = React.useContext(BalanceContext);
    if (!balanceContext) {
        throw new Error('useBalanceContext must be used within a BalanceProvider');
    }
    return balanceContext;
}

export { BalanceProvider, useBalanceContext };
