import React from 'react';
import style from './form-item.module.scss';
import PropTypes from 'prop-types';

function FormItemComponent(props) {
    const { isStyleRequired, children } = props;
    return <div className={!isStyleRequired ? style.formItem : ''}>{children}</div>;
}

FormItemComponent.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default FormItemComponent;
