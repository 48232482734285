import React from 'react';
import PropTypes from 'prop-types';
import style from './missing-merchant.module.scss';
import { getComparator, stableSort } from '../../../utils/helper';
import { Link } from 'react-router-dom';
import TableHeader from '../../../components/payments/transaction-tables/table-header/table-header.container.js';
import {
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableContainer,
    Paper,
    CircularProgress,
    TableFooter,
    TablePagination,
    Box,
    makeStyles,
    FormControl,
    FormControlLabel,
    Checkbox,
    Button,
    TextField
} from '@material-ui/core';
import { MerchantStatusNames } from 'utils/enums/MerchantStatusNames';

const useStyles = makeStyles(() => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    tableCellSmallSize: {
        '& td': {
            padding: '6px 14px 6px 14px'
        },
        '& th': {
            padding: '6px 14px 6px 14px'
        }
    }
}));

function MissingMerchantComponent(props) {
    const {
        loading,
        error,
        page,
        rowsPerPage,
        rows,
        handleChangePage,
        handleChangeRowsPerPage,
        onGoToAccount,
        count,
        order,
        orderBy,
        showAdyen,
        setShowAdyen,
        setInputValue,
        onSearchMerchants
    } = props;
    const classes = useStyles();

    return (
        <React.Fragment className={style.top}>
            <form className={style.search_form} onSubmit={onSearchMerchants}>
                <TextField
                    className={style.searchMerchants}
                    id="pending-merchants-search"
                    size="small"
                    label="Search"
                    type="search"
                    variant="outlined"
                    autoComplete="off"
                    autoFocus
                    onChange={(e) => setInputValue(e.target.value)}
                />
                <Button
                    id="pending-merchants-search-button"
                    className={style.searchButton}
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={onSearchMerchants}
                >
                    Search
                </Button>
                <FormControl style={{ marginLeft: '20px' }}>
                    <FormControlLabel
                        style={{ marginBottom: '0px' }}
                        control={
                            <Checkbox color="primary" checked={showAdyen} onChange={(e) => setShowAdyen(!showAdyen)} />
                        }
                        label="Adyen"
                        labelPlacement="end"
                    />
                </FormControl>
            </form>

            <Paper className={style.paper}>
                {loading ? (
                    <Box textAlign="center" py={5}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <div className={style.refundsNone}>An error occurred, please try reloading your browser.</div>
                ) : (
                    <React.Fragment>
                        {!rows || rows.length === 0 ? (
                            <div className={style.refundsNone}>No Missing Merchants</div>
                        ) : (
                            <React.Fragment>
                                <TableContainer className={style.TableContainer}>
                                    <Table
                                        aria-label="simple table"
                                        aria-labelledby="tableTitle"
                                        size="small"
                                        stickyHeader
                                        component={Paper}
                                        sx={{ minWidth: 650 }}
                                    >
                                        <TableHeader classes={classes} {...props} />

                                        <TableBody>
                                            {stableSort(rows, getComparator(order, orderBy)).map((row) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell className={style.nameCell && style.tableDataCell}>
                                                            {row.MerchantName}
                                                        </TableCell>
                                                        <TableCell className={style.nameCell && style.tableDataCell}>
                                                            {row.MerchantId}
                                                        </TableCell>
                                                        <TableCell className={style.tableDataCell}>
                                                            {MerchantStatusNames[row.status]}
                                                        </TableCell>
                                                        <TableCell className={style.tableDataCell}>
                                                            {row.OwnersDetail.OwnerAddress?.postCode}
                                                        </TableCell>
                                                        <TableCell className={style.tableDataCell}>
                                                            {row.OwnersDetail.nationality}
                                                        </TableCell>
                                                        <TableCell className={style.tableDataCell}>
                                                            {row.OwnersDetail.birthDate}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Link onClick={() => onGoToAccount(row.MerchantId)}>
                                                                <span>Account</span>
                                                            </Link>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[20, 50, 100]}
                                                    colSpan={8}
                                                    count={count}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        inputProps: { 'aria-label': 'rows per page' },
                                                        native: true
                                                    }}
                                                    onChangePage={handleChangePage}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                                {/* {redirected && (
                                    <SnackBarAndAlert open={redirected} onClose={handleClose} type="success">
                                        Data has been Updated Successfully
                                    </SnackBarAndAlert>
                                )} */}
                                {/* 
                                {message && (
                                    <SnackBarAndAlert
                                        open={refundStatus === 'error'}
                                        onClose={() => setRefundStatus('')}
                                        type="error"
                                    >
                                        {message}
                                    </SnackBarAndAlert>
                                )} */}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </Paper>
        </React.Fragment>
    );
}

MissingMerchantComponent.propTypes = {
    loading: PropTypes.bool,
    rows: PropTypes.array,
    error: PropTypes.bool,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func
};

export default MissingMerchantComponent;
