import React from 'react';
import AcceptInvitationComponent from './accept-invitation.component';
import { useGlobalStateContext } from 'contexts';
import { AuthService, RelationshipService } from 'services';

function AcceptInvitation() {
    const { globalState, setRoute } = useGlobalStateContext();
    const params = new URLSearchParams(window.location.href);

    if (params.has('email') && params.has('merchantId')) {
        const invitedUserEmail = params.get('email');
        const merchantId = params.get('merchantId');

        if (AuthService.isLoggedUser()) {
            if (globalState.user.email === invitedUserEmail) {
                const requestData = {
                    merchantId: merchantId,
                    userEmail: invitedUserEmail
                };

                RelationshipService.assignUserToMerchant(requestData);
            } else {
                AuthService.logout();
                setRoute('/signup?&merchantId=' + merchantId);
            }
        } else {
            setRoute('/signup?&merchantId=' + merchantId);
        }
    } else {
        setRoute('/login');
    }

    return <AcceptInvitationComponent />;
}

export default AcceptInvitation;
