export const AcquirerNames = [
    { id: 1, name: 'Cashflows' },
    { id: 2, name: 'EVO' },
    { id: 3, name: 'First Data' },
    { id: 4, name: 'Worldpay' },
    { id: 5, name: 'Creodrax' },
    { id: 6, name: 'Paysafe' },
    { id: 7, name: 'Trust' },
    { id: 8, name: 'AIB' },
    { id: 9, name: 'Decta' },
    { id: 10, name: 'E-Merchant Pay' },
    { id: 11, name: 'Truevo' },
    { id: 12, name: 'Barclays' },
    { id: 12, name: 'CARDSTREAM-CH' }
];
