import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    Paper,
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    Grid,
    TableFooter,
    TablePagination,
    Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import TableFooterComponent from '../table-footer/table-footer.component';
import TransactionStatusLabel from '../transaction-status-label/transaction-status-label.container';
import TableHeader from '../table-header/table-header.container';
import PayoutsReferenceTable from './payouts-reference-table';
import { formatCurrency, formatDateTimeByFormatString, getComparator, stableSort } from '../../../../utils/helper';
import styles from './payouts-list-table.module.scss';
import { useGlobalStateContext } from 'contexts';
import { getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry';
import { AcquirersNameMap } from 'utils/enums/Acquirers';
import { AcquirersToAcquirerName } from 'utils/enums/Acquirers';
import { UserType } from 'utils/enums/UserType';
import { payoutStatus } from 'utils/enums/payoutStatusToDate';
import { ModalPayoutsMarkNotReceived, SnackBarAndAlert } from 'components';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        'border-top': '1px solid #ddd',
        'margin-top': '10px'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        boxShadow: 'none'
    },
    container: {
        maxHeight: '100%'
    },
    table: {
        minWidth: '100%'
    },
    tableRow: {
        '&$selected, &$selected:hover': {
            backgroundColor: '#e4e1fe'
        }
    },
    expandedRow: {
        backgroundColor: '#e4e1fe',
        cursor: 'pointer'
    },
    selected: {
        cursor: 'pointer',
        th: {
            cursor: 'pointer'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    referenceBatchId: {
        paddingRight: '10px',
        fontWeight: 'bold'
    },
    checkbox: {
        color: '#8783b5'
    }
}));

export default function PayoutsListTableComponent(props) {
    const { setRoute, globalState } = useGlobalStateContext();
    const classes = useStyles();
    const {
        rows,
        selected,
        order,
        orderBy,
        loading,
        error,
        totalPayouts,
        notInBatchPayouts,
        openedBatchTabs,
        openedNotBatchTabs,
        isTabOpen,
        date,
        page,
        setPage,
        open,
        setOpen,
        markPayout,
        setmarkPayout,
        errors,
        onmarkConfirm,
        isLoading,
        isValidMerchant,
        message,
        snackbar,
        handleClose
    } = props;

    const notInBatchPayoutEquivalentSortingKeyMap = {
        reference: 'time',
        amount: 'payed',
        status: 'withdraw_status'
    };

    const notInBatchOrderByKey = notInBatchPayoutEquivalentSortingKeyMap[orderBy] || orderBy;

    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const isAdminUserType =
        globalState.user?.UserType?.name === UserType.Admin || globalState.user?.UserType?.name === UserType.SuperAdmin;

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                {loading ? (
                    <Box textAlign="center" py={5}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <div className={styles.paymentsNone}>An error occurred, please try reloading your browser.</div>
                ) : (
                    <React.Fragment>
                        {(!rows || rows.length === 0) && (!notInBatchPayouts || notInBatchPayouts.length === 0) ? (
                            <div className={styles.paymentsNone}>There aren't any results for that query.</div>
                        ) : (
                            <React.Fragment>
                                <TableContainer className={classes.container}>
                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size="small"
                                        stickyHeader
                                        aria-label="enhanced table"
                                    >
                                        <TableHeader
                                            classes={classes}
                                            numSelected={selected.length}
                                            rowCount={rows.length}
                                            hideSelectAll={true}
                                            {...props}
                                        />
                                        <TableBody>
                                            {notInBatchPayouts &&
                                                stableSort(
                                                    rowsPerPage > 0
                                                        ? notInBatchPayouts.slice(
                                                              page * rowsPerPage,
                                                              page * rowsPerPage + rowsPerPage
                                                          )
                                                        : notInBatchPayouts,
                                                    getComparator(order, notInBatchOrderByKey)
                                                ).map((row, index) => {
                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <TableRow
                                                                classes={{ selected: classes.selected }}
                                                                className={`${classes.tableRow}
                                                                     ${styles.tableRow}`}
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                key={index}
                                                                onClick={() =>
                                                                    setRoute(`/payout-details/${index}`, {
                                                                        payouts: row,
                                                                        nextExpectedDate: row.expected_date,
                                                                        type: 'NOT-IN-BATCH',
                                                                        provider: row.provider,
                                                                        date: date
                                                                    })
                                                                }
                                                            >
                                                                <TableCell
                                                                    align="left"
                                                                    component="th"
                                                                    id={labelId}
                                                                    scope="row"
                                                                >
                                                                    <React.Fragment>
                                                                        {formatCurrency(
                                                                            row.total,
                                                                            getCurrencyThreeAlpha(
                                                                                globalState.merchant?.country
                                                                            )
                                                                        )}
                                                                    </React.Fragment>
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {!row.expected_date ? (
                                                                        <span>&mdash;</span>
                                                                    ) : (
                                                                        formatDateTimeByFormatString(
                                                                            row.expected_date,
                                                                            'DD MMM HH:mm'
                                                                        )
                                                                    )}
                                                                </TableCell>

                                                                <TableCell align="left">
                                                                    <TransactionStatusLabel status={'PENDING'} />
                                                                </TableCell>
                                                                {isAdminUserType && (
                                                                    <TableCell align="left">
                                                                        {AcquirersToAcquirerName[row.provider]}
                                                                    </TableCell>
                                                                )}
                                                            </TableRow>
                                                            {isTabOpen(openedNotBatchTabs, index) && (
                                                                <TableRow>
                                                                    <TableCell
                                                                        colSpan={3}
                                                                        align="left"
                                                                        className={styles.referenceColumn}
                                                                    >
                                                                        <Grid>
                                                                            <PayoutsReferenceTable
                                                                                rows={row.batch_items}
                                                                                type="NOT-IN-BATCH"
                                                                            />
                                                                        </Grid>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                })}
                                            {rows &&
                                                stableSort(
                                                    rowsPerPage > 0
                                                        ? page * rowsPerPage > notInBatchPayouts.length
                                                            ? rows.slice(
                                                                  page * rowsPerPage - notInBatchPayouts.length,
                                                                  page * rowsPerPage -
                                                                      notInBatchPayouts.length +
                                                                      rowsPerPage
                                                              )
                                                            : page * rowsPerPage + rowsPerPage >
                                                              notInBatchPayouts.length
                                                            ? rows.slice(
                                                                  0,
                                                                  page * rowsPerPage +
                                                                      rowsPerPage -
                                                                      notInBatchPayouts.length
                                                              )
                                                            : []
                                                        : rows,
                                                    getComparator(order, orderBy)
                                                ).map((row, index) => {
                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <TableRow
                                                                classes={{ selected: classes.selected }}
                                                                className={classes.tableRow}
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                key={row.batch_id}
                                                                onClick={() =>
                                                                    row.provider === AcquirersNameMap.STRIPE
                                                                        ? setRoute(`/stripe-payout-details/${index}`, {
                                                                              payouts: row
                                                                          })
                                                                        : setRoute(`/payout-details/${index}`, {
                                                                              payouts: row,
                                                                              nextExpectedDate: row.expected_date,
                                                                              type: 'IN-BATCH',
                                                                              provider: row.provider,
                                                                              date: date
                                                                          })
                                                                }
                                                            >
                                                                <TableCell
                                                                    component="th"
                                                                    id={labelId}
                                                                    scope="row"
                                                                    align="left"
                                                                >
                                                                    {formatCurrency(
                                                                        row.provider === AcquirersNameMap.STRIPE
                                                                            ? row.total / 100
                                                                            : row.total,
                                                                        getCurrencyThreeAlpha(
                                                                            globalState.merchant?.country
                                                                        )
                                                                    )}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {!row.expected_date ? (
                                                                        <span>&mdash;</span>
                                                                    ) : row.provider === AcquirersNameMap.STRIPE ? (
                                                                        formatDateTimeByFormatString(
                                                                            moment.unix(row.arrival_date),
                                                                            'DD MMM HH:mm'
                                                                        )
                                                                    ) : (
                                                                        formatDateTimeByFormatString(
                                                                            row.expected_date,
                                                                            'DD MMM HH:mm'
                                                                        )
                                                                    )}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {row.not_received ? (
                                                                        <React.Fragment>
                                                                            <TransactionStatusLabel
                                                                                status={'NOT RECEIVED'}
                                                                            />
                                                                            {/* {false &&
                                                                             !isReseller &&
                                                                            (isOwner || isAdmin) && ( //Not required in MVP, remove false to show it again
                                                                                    <div className={styles.buttonDiv}>
                                                                                        <Button
                                                                                            color="primary"
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                            fullWidth="true"
                                                                                        >
                                                                                            Resend
                                                                                        </Button>
                                                                                    </div>
                                                                                )} */}
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        <TransactionStatusLabel status={row.status} />
                                                                    )}
                                                                </TableCell>

                                                                {isAdminUserType && (
                                                                    <TableCell align="left">
                                                                        {AcquirersToAcquirerName[row.provider]}
                                                                    </TableCell>
                                                                )}
                                                                {isAdminUserType && isValidMerchant && (
                                                                    <TableCell
                                                                        align="left"
                                                                        onClick={(event) => {
                                                                            event.stopPropagation();
                                                                        }}
                                                                    >
                                                                        {isAdminUserType &&
                                                                        row.status === payoutStatus.SENT &&
                                                                        row.provider === AcquirersNameMap.DATMAN &&
                                                                        !row.not_received ? (
                                                                            <div>
                                                                                <Button
                                                                                    color="primary"
                                                                                    variant="contained"
                                                                                    size="medium"
                                                                                    className={styles.resendBtns}
                                                                                    onClick={(event) => {
                                                                                        setmarkPayout({
                                                                                            batch_id: row.batch_id,
                                                                                            status:
                                                                                                payoutStatus.NOT_RECEIVED
                                                                                        });
                                                                                        setOpen(true);
                                                                                    }}
                                                                                >
                                                                                    Not Received
                                                                                </Button>
                                                                            </div>
                                                                        ) : !!row.not_received ? (
                                                                            <div className={styles.buttons}>
                                                                                <Button
                                                                                    color="primary"
                                                                                    variant="contained"
                                                                                    size="medium"
                                                                                    onClick={() => {
                                                                                        setmarkPayout({
                                                                                            batch_id: row.batch_id,
                                                                                            status: payoutStatus.RESEND
                                                                                        });
                                                                                        setOpen(true);
                                                                                    }}
                                                                                    className={styles.resendBtns}
                                                                                >
                                                                                    Resend Payout
                                                                                </Button>
                                                                                <Button
                                                                                    color="primary"
                                                                                    variant="outlined"
                                                                                    size="medium"
                                                                                    //fullWidth="true"
                                                                                    onClick={() => {
                                                                                        setmarkPayout({
                                                                                            batch_id: row.batch_id,
                                                                                            status: payoutStatus.CANCEL
                                                                                        });
                                                                                        setOpen(true);
                                                                                    }}
                                                                                    className={styles.resendBtns}
                                                                                >
                                                                                    Cancel Payout
                                                                                </Button>
                                                                            </div>
                                                                        ) : (
                                                                            <span>&mdash;</span>
                                                                        )}
                                                                    </TableCell>
                                                                )}
                                                            </TableRow>
                                                            {row.batch_items?.length > 1 &&
                                                                isTabOpen(openedBatchTabs, index) && (
                                                                    <TableRow>
                                                                        <TableCell align="center" colSpan={3}>
                                                                            <Grid style={{ marginBottom: 25 }}>
                                                                                <Grid container direction="row">
                                                                                    {props.isAdminUserType ? (
                                                                                        <Typography
                                                                                            className={
                                                                                                classes.referenceBatchId
                                                                                            }
                                                                                        >
                                                                                            BatchId {row.batch_id}
                                                                                        </Typography>
                                                                                    ) : null}
                                                                                </Grid>
                                                                                <Grid>
                                                                                    <PayoutsReferenceTable
                                                                                        rows={row.batch_items}
                                                                                        type="IN-BATCH"
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )}
                                                        </React.Fragment>
                                                    );
                                                })}
                                            <TableRow>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                {rows.length > rowsPerPage && (
                                                    <TablePagination
                                                        rowsPerPageOptions={[20, 50, 100, { label: 'All', value: -1 }]}
                                                        colSpan={6}
                                                        count={notInBatchPayouts.length + rows.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        SelectProps={{
                                                            inputProps: { 'aria-label': 'rows per page' },
                                                            native: true
                                                        }}
                                                        onChangePage={handleChangePage}
                                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                                    />
                                                )}
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                                <TableFooterComponent
                                    total={totalPayouts()}
                                    resultsCount={rows.length + notInBatchPayouts.length}
                                />
                                <ModalPayoutsMarkNotReceived
                                    markPayout={markPayout}
                                    open={open}
                                    setmarkPayout={setmarkPayout}
                                    setOpen={setOpen}
                                    error={errors}
                                    onmarkConfirm={onmarkConfirm}
                                    isLoading={isLoading}
                                />
                            </React.Fragment>
                        )}
                        {message && (
                            <SnackBarAndAlert open={message} onClose={handleClose} type={snackbar.type}>
                                {snackbar.message}
                            </SnackBarAndAlert>
                        )}
                    </React.Fragment>
                )}
            </Paper>
        </div>
    );
}

PayoutsListTableComponent.propTypes = {
    rows: PropTypes.array.isRequired,
    selected: PropTypes.array.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    isAdminUserType: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    notInBatchPayouts: PropTypes.array.isRequired,
    openedBatchTabs: PropTypes.array,
    setOpenedBatchTabs: PropTypes.func,
    openedNotBatchTabs: PropTypes.array,
    setOpenedNotBatchTabs: PropTypes.func,
    handleOpenTableRow: PropTypes.func,
    isTabOpen: PropTypes.func,
    totalPayouts: PropTypes.func
};
