import { AuthAxios } from './interceptor.service.js';
import { SuccesResponse, ErrorResponse } from 'models';
import { AdminServiceEndpoint } from '../utils/constants';

const canonicalResellerMerchantRegistration = async (data) => {
    try {
        const response = await AuthAxios.post(
            `${AdminServiceEndpoint}/merchants/canonical-reseller-merchant-registration`,
            data
        );

        return new SuccesResponse(response.data);
    } catch (error) {
        return getError(error);
    }
};
const getError = (error) => {
    const message = error.response ? error.response.data : 'An error occurred';
    return new ErrorResponse(message);
};
export default {
    canonicalResellerMerchantRegistration
};
