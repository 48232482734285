import React, { useState } from 'react';
import WithdrawalComponent from './withdrawal.component';

function WithdrawalContainer() {
    const [show, setShow] = useState(false);

    return <WithdrawalComponent show={show} setShow={setShow} />;
}

export default WithdrawalContainer;
