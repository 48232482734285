import React, { useState, useCallback } from 'react';
import ValidDomainInputComponent from './valid-domain-input.component';
import { InputValidators } from 'validators';
import { debounce } from 'lodash';

const ValidDomainInput = (props) => {
    const [showDomainValidationMessage, setShowDomainValidationMessage] = useState(false);

    const validation = (inputDomain) => {
        let isDomainValid;
        isDomainValid = InputValidators.isDomainUrlValid(inputDomain);
        setShowDomainValidationMessage(!isDomainValid);
    };

    // trigger validation function when user stop typing
    const doValidation = useCallback(debounce(validation, 1000), []);

    return (
        <ValidDomainInputComponent
            {...props}
            doValidation={doValidation}
            showDomainValidationMessage={showDomainValidationMessage}
            setShowDomainValidationMessage={setShowDomainValidationMessage}
        />
    );
};

export default ValidDomainInput;
