import React from 'react';
import SelectedDropDownComponent from './dropdown.component';

/**
 *
 * @param {{
 * id: string
 * placeholder: string
 * value: any,
 * inputValue: any
 * options: any,
 * getOptionLabel: (option) => {}
 * onChange: (option) => {}
 * onInputChange (event, newValue) => {}
 * listPrefix: sstring
 * disabled: boolean
 * freeSolo: boolean
 * customListRender: (option, state) => {}
 * }} props
 */
function SelectedDropDown(props) {
    return <SelectedDropDownComponent {...props} />;
}

SelectedDropDown.propTypes = {};

export default SelectedDropDown;
