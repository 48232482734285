import React from 'react';
import PropTypes from 'prop-types';
import InvoiceToggleModesDialogComponent from './invoice-toggle-modes-dialog.component';
import { AdminService } from 'services';
import { useGlobalStateContext, useAdminFunctionsContext } from 'contexts';

export default function InvoiceToggleModesDialog(props) {
    const { globalState, setGlobalState } = useGlobalStateContext();
    const { adminFunctions, setAdminFunctions } = useAdminFunctionsContext();
    const {
        showInvoiceUpdateDialogBox,
        setShowInvoiceUpdateDialogBox,
        setLoading,
        showInvoiceStatus,
        showInvoiceUpdate
    } = props;

    const showInvoiceUpdateInitiated = async (showInvoiceUpdate) => {
        setShowInvoiceUpdateDialogBox(false);
        setLoading(true);
        setAdminFunctions({
            ...adminFunctions,
            isInvoiceEnabled: showInvoiceUpdate
        });
        const result = showInvoiceUpdate ? '1' : '0';
        const response = await AdminService.UpdateMerchantDataForAdmin(globalState.merchant?.id, {
            resellerId: globalState.reseller?.id,
            isInvoiceEnabled: result
        });
        if (response.isSuccesfully) {
            setShowInvoiceUpdateDialogBox(false);
            setGlobalState((globalState) => ({
                ...globalState,
                merchant: {
                    ...globalState.merchant,
                    isInvoiceEnabled: showInvoiceUpdate
                }
            }));
        }
        setLoading(false);
    };

    return (
        <InvoiceToggleModesDialogComponent
            showInvoiceUpdateDialogBox={showInvoiceUpdateDialogBox}
            setShowInvoiceUpdateDialogBox={setShowInvoiceUpdateDialogBox}
            showInvoiceStatus={showInvoiceStatus}
            showInvoiceUpdate={showInvoiceUpdate}
            showInvoiceUpdateInitiated={showInvoiceUpdateInitiated}
        />
    );
}

InvoiceToggleModesDialog.propTypes = {
    showInvoiceUpdateDialogBox: PropTypes.bool,
    setShowInvoiceUpdateDialogBox: PropTypes.func,
    setLoading: PropTypes.func,
    showInvoiceStatus: PropTypes.bool,
    showInvoiceUpdate: PropTypes.bool,
    showInvoiceUpdateInitiated: PropTypes.func
};
