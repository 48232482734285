import React from 'react';
import {
    Grid,
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    makeStyles,
    Paper,
    Box
} from '@material-ui/core';
import { Input, Label, FormItem, PostCodeSelector } from 'components/onboarding/onboarding-ui-components';
import { SnackBarAndAlert } from 'components';
import styles from './canonical-reseller-update-account.module.scss';
import { LoadingScreen, ValidURLInput } from 'components';
import { checkEmptyValue } from 'validators/onboarding/onboarding-check-value.validator';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
function CanonicalResellerUpdateAccountComponennt(props) {
    const {
        disabled,
        onSubmit,
        selectAddressData,
        showModal,
        modalMessage,
        isLoading,
        phoneNumberValidator,
        setShowModal,
        canonicalResellerDetails,
        setCanonicalResellerDetails,
        setPhoneCountryDialCode,
        snackbarDetails,
        setSnackbarDetails,
        setRoute
    } = props;

    const useStyles = makeStyles((theme) => ({
        disabledInput: {
            color: theme.palette.text.primary,
            backgroundColor: 'Gainsboro',
            border: '1px solid LightGray',
            borderRadius: '5px',
            fontWeight: '600',
            userSelect: 'none'
        }
    }));

    const classes = useStyles();

    return (
        <Paper className={styles.updateAccountMypayContainer}>
            <Box className={styles.pageTitle}>
                <Typography component="span" color="primary" className={styles.formTitle}>
                    Update Account Details
                </Typography>
            </Box>
            <Grid container>
                <Grid container className={styles.Container} justify="space-around">
                    <Grid item xs={10} sm={5}>
                        <FormItem>
                            <Label text="Company name" id="company-name"></Label>
                            <Input
                                placeholder="Company name"
                                inputProps={{ maxLength: 150 }}
                                id="company-name-input"
                                value={canonicalResellerDetails.companyName}
                                onChange={(e) => {
                                    setCanonicalResellerDetails({
                                        ...canonicalResellerDetails,
                                        companyName: e.target.value
                                    });
                                }}
                                disabled
                                classes={{ disabled: classes.disabledInput }}
                            />
                        </FormItem>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                        <FormItem>
                            <Label text="Owner name" id="owner-name"></Label>
                            <Input
                                placeholder="Owner name"
                                id="owner-name-input"
                                inputProps={{ maxLength: 150 }}
                                value={canonicalResellerDetails.primaryContactName}
                                onChange={(e) => {
                                    setCanonicalResellerDetails({
                                        ...canonicalResellerDetails,
                                        primaryContactName: e.target.value
                                    });
                                }}
                                disabled
                                classes={{ disabled: classes.disabledInput }}
                            />
                        </FormItem>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={styles.companyAddressContainer}>
                <Grid className={styles.subTitle}>
                    <Typography component="span" color="primary" className={styles.companyTitle}>
                        Company Address
                    </Typography>
                </Grid>
                <Grid container className={styles.Container} justify="space-around">
                    <Grid item xs={10} sm={5}>
                        <div className={styles.formContainer}>
                            <FormItem>
                                <Label text="Post Code" id="owner-post-code"></Label>
                                <PostCodeSelector
                                    postCodeValue={checkEmptyValue(canonicalResellerDetails.postCode)}
                                    postCodeLookup={true}
                                    onPostCodeChange={(postCode) => {
                                        setCanonicalResellerDetails({
                                            ...canonicalResellerDetails,
                                            postCode: postCode
                                        });
                                    }}
                                    onAddressObjectChange={(addressData) =>
                                        selectAddressData(
                                            addressData,
                                            setCanonicalResellerDetails,
                                            canonicalResellerDetails
                                        )
                                    }
                                />
                            </FormItem>
                            <FormItem>
                                <Label text="Address line 1" id="address-line-1"></Label>
                                <Input
                                    placeholder="Address line 1"
                                    id="address-input-1"
                                    value={canonicalResellerDetails.addressLine1}
                                    onChange={(e) => {
                                        setCanonicalResellerDetails({
                                            ...canonicalResellerDetails,
                                            addressLine1: e.target.value
                                        });
                                    }}
                                />
                            </FormItem>
                            <FormItem>
                                <Label text="Address line 2" id="address-line-2"></Label>
                                <Input
                                    placeholder="Address line 2"
                                    id="address-input-2"
                                    value={canonicalResellerDetails.addressLine2}
                                    onChange={(e) => {
                                        setCanonicalResellerDetails({
                                            ...canonicalResellerDetails,
                                            addressLine2: e.target.value
                                        });
                                    }}
                                />
                            </FormItem>
                            <FormItem>
                                <Label text="Town/City" id="city"></Label>
                                <Input
                                    placeholder="City"
                                    id="city-input"
                                    inputProps={{ maxLength: 150 }}
                                    value={canonicalResellerDetails.city}
                                    onChange={(e) => {
                                        setCanonicalResellerDetails({
                                            ...canonicalResellerDetails,
                                            city: e.target.value
                                        });
                                    }}
                                />
                            </FormItem>

                            <FormItem>
                                <Label text="Country" id="country"></Label>
                                <Input
                                    placeholder="Country"
                                    id="country-input"
                                    value={canonicalResellerDetails.country}
                                    onChange={(e) => {
                                        setCanonicalResellerDetails({
                                            ...canonicalResellerDetails,
                                            country: e.target.value
                                        });
                                    }}
                                />
                            </FormItem>
                        </div>
                    </Grid>
                    <Grid item xs={10} sm={5}>
                        <div className={styles.formContainer}>
                            <FormItem>
                                <Label text="Owner email" id="owner-email"></Label>
                                <Input
                                    placeholder="Email"
                                    id="owner-email-input"
                                    value={canonicalResellerDetails.primaryContactEmail}
                                    onChange={(e) => {
                                        setCanonicalResellerDetails({
                                            ...canonicalResellerDetails,
                                            primaryContactEmail: e.target.value.toLowerCase()
                                        });
                                    }}
                                    disabled
                                    classes={{ disabled: classes.disabledInput }}
                                />
                            </FormItem>
                            <FormItem>
                                <Label text="Telephone number" id="tele-phone-number"></Label>
                                <PhoneInput
                                    country={'gb'}
                                    id="telephone-number-input"
                                    placeholder="Phone number"
                                    value={checkEmptyValue(canonicalResellerDetails.telNumber)}
                                    alwaysDefaultMask={true}
                                    countryCodeEditable={false}
                                    onChange={(phone, data) => {
                                        setCanonicalResellerDetails({
                                            ...canonicalResellerDetails,
                                            telNumber: phoneNumberValidator(phone)
                                        });
                                        setPhoneCountryDialCode(data.dialCode);
                                    }}
                                    inputClass={styles.phoneInput}
                                    name="phone"
                                />
                            </FormItem>
                            <FormItem>
                                <ValidURLInput
                                    labelText="Business URL"
                                    labelId="business-label"
                                    id="business-website-url-input"
                                    value={checkEmptyValue(canonicalResellerDetails.websiteUrl)}
                                    onChange={(value) => {
                                        setCanonicalResellerDetails({
                                            ...canonicalResellerDetails,
                                            websiteUrl: value
                                        });
                                    }}
                                />
                            </FormItem>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <SnackBarAndAlert
                open={snackbarDetails.type}
                onClose={() => {
                    setSnackbarDetails({ type: '', message: '' });
                    setRoute('/home');
                }}
                type={snackbarDetails.type}
            >
                {snackbarDetails.message}
            </SnackBarAndAlert>

            <div className={styles.btnContainer}>
                <Button
                    disabled={disabled}
                    id="update-btn"
                    color="primary"
                    variant="contained"
                    className={styles.updateBtn}
                    onClick={onSubmit}
                >
                    Update
                </Button>
                <Dialog open={showModal}>
                    <DialogContent>{modalMessage}</DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setShowModal(false)}
                            id="cancelButtonCreateMerchant"
                            autoFocus
                            color="primary"
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <LoadingScreen open={isLoading}></LoadingScreen>
            </div>
        </Paper>
    );
}
CanonicalResellerUpdateAccountComponennt.propTypes = {
    phoneNumberValidator: PropTypes.func
};

export default CanonicalResellerUpdateAccountComponennt;
