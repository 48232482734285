import React from 'react';
import {
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TablePagination,
    TableRow,
    Grid,
    Divider,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Tooltip
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import TableHeader from '../payments/transaction-tables/table-header/table-header.container';
import LoadingScreen from 'components/loading-screen/loading-screen.container';
import styles from './canonical-reseller-merchant-management.module.scss';
import { stableSort, getComparator } from 'utils/helper';
import { MerchantStatusNames } from 'utils/enums/MerchantStatusNames';
import { formatDateTimeByFormatString } from 'utils/helper';
import CreateIcon from '@material-ui/icons/Create';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useGlobalStateContext } from 'contexts';

import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    }
}));

const CanonicalResellerMerchantManagementComponent = (props) => {
    const {
        merchantList,
        isLoading,
        order,
        orderBy,
        count,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        activatedAt,
        canonicalResellerDetails,
        signedBy,
        resellerNameDialogBox,
        setResellerNameDialogBox,
        updateResellerName,
        updatedResellerName,
        setUpdatedResellerName,
        pageLoading
    } = props;
    const classes = useStyles();
    const { getRoute } = useGlobalStateContext();

    return (
        <Paper className={styles.canonicalResellerManagementContainer}>
            <Grid className={styles.pageTitle}>
                <div className={styles.pageTitleContainer}>
                    <Typography className={styles.canonicalResellerManagementHeader}>Reseller Management</Typography>
                    <Link to={getRoute(`/admin-pending-merchants`)} className={styles.resellerButton}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            Back
                        </Button>
                    </Link>

                </div>
                <div className={styles.canonicalResellerContainer}>
                    <Typography className={styles.canonicalResellerName}>
                        {canonicalResellerDetails?.companyName}
                    </Typography>
                    {canonicalResellerDetails.companyName ? (
                        <Tooltip title="Edit" placement="top-start" arrow>
                            <CreateIcon
                                onClick={() => setResellerNameDialogBox(true)}
                                className={styles.canonicalResellerIcon}
                            />
                        </Tooltip>
                    ) : null}
                </div>
                <div className={styles.canonicalResellerContainer}>
                    <Typography className={styles.canonicalResellerName}>
                        {canonicalResellerDetails?.primaryContactEmail}
                    </Typography>
                </div>
                <Dialog
                    open={resellerNameDialogBox}
                    onClose={() => setResellerNameDialogBox(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    className={styles.updateResellerDialog}
                >
                    <DialogTitle id="alert-dialog-title">{'Update Reseller'}</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            label="Reseller Name"
                            id="fullWidth"
                            variant="outlined"
                            color="black"
                            value={updatedResellerName}
                            onChange={(e) => {
                                setUpdatedResellerName(e.target.value);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setUpdatedResellerName(canonicalResellerDetails?.companyName);
                                setResellerNameDialogBox(false);
                            }}
                            color="primary"
                            variant="outlined"
                            className={styles.cancelBtn}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={updateResellerName}
                            variant="contained"
                            color="primary"
                            className={styles.dilogbtn}
                            disabled={
                                canonicalResellerDetails?.companyName === updatedResellerName ||
                                updatedResellerName.trim() === '' ||
                                updatedResellerName?.trim().length < 3
                            }
                        >
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
            {signedBy ? (
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={12}
                    md={12}
                    className={styles.resellerManagemnt}
                >
                    <Grid item xs={5} md={5}>
                        <Typography>
                            <b>Terms & Conditions</b>&nbsp;&nbsp;Signed By :&nbsp;{signedBy}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <Typography>
                            Date & Time : &nbsp;{formatDateTimeByFormatString(activatedAt, 'DD MMM YY HH:mm')}
                        </Typography>
                    </Grid>
                </Grid>
            ) : null}
            <Divider />
            <div className={styles.container}>
                {isLoading ? (
                    <div className={styles.loading}>
                        <CircularProgress />
                    </div>
                ) : (
                    <TableContainer component={Paper}>
                        {merchantList.length === 0 ? (
                            <div className={styles.merchantListNone}>No merchants yet under this reseller.</div>
                        ) : (
                            <Table aria-label="simple table" aria-labelledby="tableTitle" size="small" stickyHeader>
                                <TableHeader classes={classes} {...props} />
                                <TableBody>
                                    {stableSort(merchantList, getComparator(order, orderBy)).map((merchant) => (
                                        <TableRow key={merchant.id}>
                                            <TableCell className={styles.merchantName}>
                                                <Tooltip
                                                    title={merchant.name}
                                                    className={styles.merchantName}
                                                    placement="bottom-start"
                                                >
                                                    <span>{merchant.name}</span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>{MerchantStatusNames[merchant.status]}</TableCell>
                                            <TableCell>{merchant.id}</TableCell>
                                            <TableCell>{merchant.acquirerBank}</TableCell>
                                            <TableCell>{merchant.resellerToOmniPayBill}</TableCell>
                                            <TableCell>{merchant.merchantToOmniPatBill}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        {count > 20 && (
                                            <TablePagination
                                                rowsPerPageOptions={[20, 50, 100]}
                                                count={count}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: { 'aria-label': 'rows per page' },
                                                    native: true
                                                }}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                            />
                                        )}
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        )}
                        <LoadingScreen open={pageLoading} />
                    </TableContainer>
                )}
            </div>
        </Paper>
    );
};

export default CanonicalResellerMerchantManagementComponent;
