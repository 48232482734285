import React from 'react';
import { Redirect } from 'react-router-dom';
import { useGlobalStateContext } from 'contexts';
import LoggedRoute from './logged-route';
import { ProductStatus } from 'utils/enums/ProductsRequired';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { UserType } from 'utils/enums/UserType';

const ProductApprovedRoute = ({ component: Component, productName, ...rest }) => {
    const { globalState } = useGlobalStateContext();
    const merchantStatus = globalState.merchant?.status;
    const user = globalState.user;
    const allowedUser = user?.UserType?.name === UserType.SuperAdmin || merchantStatus !== MerchantStatus.CLOSED;

    const isProductApproved = (productName) => {
        if (globalState.merchant && globalState.merchant.hasOwnProperty('merchantProductRequired')) {
            const { merchantProductRequired } = globalState.merchant;
            if (
                merchantProductRequired.find(
                    (eachProduct) =>
                        eachProduct.productName === productName &&
                        (eachProduct.status === ProductStatus.ACTIVE ||
                            eachProduct.status === ProductStatus.DELETION_PENDING)
                )
            ) {
                return true;
            }
        }
        return false;
    };

    return (
        <LoggedRoute
            {...rest}
            render={(props) =>
                isProductApproved(productName) && allowedUser ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={
                            globalState.customDomain === false
                                ? `/${globalState.reseller?.portalURL}/not-authorized`
                                : '/not-authorized'
                        }
                    />
                )
            }
        />
    );
};

export default ProductApprovedRoute;
