import React, { useEffect, useState } from 'react';
import SupportChangeUserAttributesComponent from './change-user-attributes.component';
import { UserService } from 'services';
import { AuthService } from 'services';

import { useGlobalStateContext } from 'contexts';

const SupportChangeUserAttributes = () => {
    const [emailToSearch, setEmailToSearch] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [newPhoneNumber, setNewPhoneNumber] = useState('');
    const [showUpdateAttributeView, setShowUpdateAttributeView] = useState(false);
    const [userToBeModified, setUserToBeModified] = useState({ firstName: '', lastName: '' });
    const [updateFirstName, setUpdateFirstName] = useState('');
    const [updateLastName, setUpdateLastName] = useState('');
    const [disabled, setDisabled] = useState(true);

    const [message, setMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const { setRoute } = useGlobalStateContext();

    const userIsLoggedIn = AuthService.isLoggedUser();

    useEffect(() => {
        if (!userIsLoggedIn) {
            setRoute('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userIsLoggedIn]);
    useEffect(() => {
        setDisabled(updateFirstName.length === 0 || updateLastName.length === 0);
    }, [updateFirstName, updateLastName]);

    const handleSearchUserByEmail = async () => {
        setShowUpdateAttributeView(false);
        setMessage('');

        let response = await UserService.getUserByEmail(emailToSearch);

        if (response.isSuccessfully && response.user) {
            setShowUpdateAttributeView(true);
            setUserToBeModified(response.user);
            setNewPhoneNumber(response.user.phoneNumber || '');
        } else {
            setIsSuccess(false);
            setMessage('There is no user with this Email.');
        }
    };

    const handleUpdateEmail = async () => {
        let userIsLoggedIn = await AuthService.isLoggedUser();
        if (userIsLoggedIn) {
            let response = await UserService.supportUpdateUserAttributes({
                email: emailToSearch,
                newEmail: newEmail
            });

            if (response.isSuccesfully && userIsLoggedIn) {
                setIsSuccess(true);
                setMessage('Successfully updated the email to: ' + newEmail);
                setEmailToSearch(newEmail);
            } else {
                setIsSuccess(false);
                setMessage("Couldn't update email. Error: " + (response.error || 'Unknown error occurred.'));
            }
        } else {
            setRoute('/');
        }
    };

    const handleUpdatePhoneNumber = async () => {
        let phoneNumberForCognito = '+' + newPhoneNumber;
        let response = await UserService.supportUpdateUserAttributes({
            email: emailToSearch,
            newPhoneNumber: phoneNumberForCognito
        });

        if (response.isSuccesfully) {
            setIsSuccess(true);
            setMessage('Successfully updated the phone number to: ' + phoneNumberForCognito);
        } else {
            setIsSuccess(false);
            setMessage("Couldn't update the phone number. Error: " + (response.error || 'Unknown error occurred.'));
        }
    };
    const handleUpdateUserName = async () => {
        let response = await UserService.supportUpdateUserAttributes({
            email: emailToSearch,
            newFirstName: updateFirstName,
            newLastName: updateLastName
        });

        if (response.isSuccesfully) {
            setIsSuccess(true);
            setMessage('Successfully updated the user name');
            setUserToBeModified((prevState) => ({
                ...prevState,
                firstName: updateFirstName,
                lastName: updateLastName
            }));
        } else {
            setIsSuccess(false);
            setMessage("Couldn't update the user name. Error: " + (response.error || 'Unknown error occurred.'));
        }
    };

    return (
        <SupportChangeUserAttributesComponent
            emailToSearch={emailToSearch}
            setEmailToSearch={setEmailToSearch}
            newEmail={newEmail}
            setNewEmail={setNewEmail}
            handleUpdateEmail={handleUpdateEmail}
            newPhoneNumber={newPhoneNumber}
            setNewPhoneNumber={setNewPhoneNumber}
            handleUpdatePhoneNumber={handleUpdatePhoneNumber}
            handleSearchUserByEmail={handleSearchUserByEmail}
            showUpdateAttributeView={showUpdateAttributeView}
            setShowUpdateAttributeView={setShowUpdateAttributeView}
            userToBeModified={userToBeModified}
            message={message}
            setMessage={setMessage}
            isSuccess={isSuccess}
            updateLastName={updateLastName}
            updateFirstName={updateFirstName}
            setUpdateLastName={setUpdateLastName}
            setUpdateFirstName={setUpdateFirstName}
            handleUpdateUserName={handleUpdateUserName}
            disabled={disabled}
        />
    );
};

export default SupportChangeUserAttributes;
